import React, { useState } from 'react'
import { Button, Slider } from 'antd'
import { useDebounce } from 'Hooks'

interface Props {
  onAddNewNode: any
  value: {
    left_branch: number
    right_branch: number
  }
  onUpdateNode: any
}

const ABTesting: React.FC<Props> = ({ onAddNewNode, value, onUpdateNode }) => {
  const [ABValues, setABValues] = useState<number[]>(
    value ? [value.left_branch, value.right_branch] : [50, 50]
  )

  const debounceAB = useDebounce(ABValues, 150)

  const onSubmit = (values: { left_branch: any; right_branch: any }) => {
    onAddNewNode && onAddNewNode('split', values)
    onUpdateNode && onUpdateNode(values)
  }

  const handleChange = () => {}

  return (
    <div className="ABTestingComponent">
      <div className="ABTestingContent">
        <div className="AB-wrapper">
          <span className="A-item">A</span>
          <span className="B-item">B</span>
        </div>
        <Slider
          // defaultValue={[50, 50]}
          defaultValue={ABValues[0]}
          tooltipVisible={false}
          step={5}
          min={0}
          max={100}
          onAfterChange={(A: number) => setABValues([A, 100 - A])}
        />
        <div className="AB-wrapper">
          <span className="A-item">{debounceAB[0]}%</span>
          <span className="B-item">{debounceAB[1]}%</span>
        </div>
      </div>
      <div
        className="form-item"
        style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
      >
        <Button
          onClick={() => onSubmit({ left_branch: ABValues[0], right_branch: ABValues[1] })}
          type="primary"
        >
          OK
        </Button>
      </div>
    </div>
  )
}

export default ABTesting
