import React, { useState, useEffect, useMemo } from 'react'
import { Typography, DatePicker, Button } from 'antd'
import { useParams } from 'react-router-dom'
import { CaretDownOutlined } from '@ant-design/icons'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import { useStoreActions, useStoreState } from 'easy-peasy'

import {
  getUserProfile,
  getValues,
  generatePieChart,
  generateCustomerEventStats,
  convertLabelStyleAndFunctional,
  CES_COLORS,
} from './data'

import BarLineChart from 'Components/Charts/MixCharts/BarLineChart'
import PurchasedCategory from './Components/PurchasedCategory'
import StyleAndFunctional from './Components/StyleAndFunctional'
import UserProfile from './Components/UserProfile'
import Segments from './Components/Segments'
import Values from './Components/Values'
import ProductSlider from './Components/ProductSlider'
import Activities from './Components/Activities'
import Interactions from './Components/Interactions'
import PlaceHolder from './PlaceHolder'

import './index.scss'
import { COLOR_PALLETTE } from 'Utils/constants'

const { Title } = Typography

const Customer360Analytic = (props) => {
  const params = useParams()
  const { getCustomerAnalytics, setCustomerAnalyticsStates } = useStoreActions(
    (actions) => actions.customerAnalytics
  )
  const customerAnalyticsData = useStoreState((state) => state.customerAnalytics.data)
  // const customerAnalyticsData = ThyCustomerSample
  const { pageTitle, charts } = props
  const { name } = customerAnalyticsData
  const [isExpanded, setIsExpanded] = useState({
    customerProfile: false,
    values: false,
    segments: false,
  })
  useEffect(() => {
    getCustomerAnalytics(params.id)
    return () => setCustomerAnalyticsStates({ key: 'data', value: {} })
  }, [params.id, getCustomerAnalytics, setCustomerAnalyticsStates])

  const profileData = useMemo(() => {
    return getUserProfile(customerAnalyticsData)
  }, [customerAnalyticsData])

  const valuesData = useMemo(() => {
    return getValues(customerAnalyticsData)
  }, [customerAnalyticsData])

  const purchasedCategories = useMemo(() => {
    return generatePieChart(customerAnalyticsData?.purchased_categories)
  }, [customerAnalyticsData])

  const stylesAndFunctional = useMemo(() => {
    return convertLabelStyleAndFunctional(
      generatePieChart(customerAnalyticsData?.styles_and_functional)
    )
  }, [customerAnalyticsData])

  const customerEventStats = useMemo(() => {
    return generateCustomerEventStats(customerAnalyticsData?.customer_event_stats)
  }, [customerAnalyticsData])

  const segments = useMemo(() => {
    if (!customerAnalyticsData?.segment) return []
    return customerAnalyticsData?.segment.split(',')
  }, [customerAnalyticsData])
  if (isEmpty(customerAnalyticsData)) return <PlaceHolder />

  return (
    <div className="Customer360Analytic">
      <div className="analytics-header">
        <div className="analytics-titles">
          <Title className="page-title font-semibold text-3xl my-7 text-primary_text" level={3}>
            {pageTitle}
          </Title>
          <Title className="page-sub-title" level={3}>
            {name}
          </Title>
        </div>
        {/* <div className="GeneralActionContainer">
          <Button type="secondary">Refresh</Button>
        </div> */}
      </div>

      <div className="analytics-container">
        <div className="analytics-grid">
          <div
            onClick={() =>
              setIsExpanded({ ...isExpanded, customerProfile: !isExpanded.customerProfile })
            }
            style={{ paddingBottom: 20, gridRow: 'span 2' }}
            className={classNames('box box1', { 'is-expanded': isExpanded.customerProfile })}
          >
            <Title level={5}>Customer profile</Title>
            <UserProfile data={profileData} />
            <CaretDownOutlined
              style={{
                position: 'absolute',
                bottom: 5,
                fontSize: 14,
                transform: isExpanded.customerProfile ? 'rotate(180deg)' : null,
              }}
            />
          </div>

          <div
            onClick={() => setIsExpanded({ ...isExpanded, values: !isExpanded.values })}
            className={classNames('box', { 'is-expanded': isExpanded.values })}
            style={{ paddingBottom: 20 }}
          >
            <Title level={5}>Values</Title>
            <Values data={valuesData} />
            <CaretDownOutlined
              style={{
                position: 'absolute',
                bottom: 5,
                fontSize: 14,
                transform: isExpanded.values ? 'rotate(180deg)' : null,
              }}
            />
          </div>
          <div className="box">
            <Title level={5}>Purchased category</Title>
            <PurchasedCategory data={purchasedCategories} />
          </div>
          <div className="box">
            <Title level={5}>Style & Functional</Title>
            <StyleAndFunctional data={stylesAndFunctional} />
          </div>
          <div className="box" style={{ gridColumn: 'span 3' }}>
            <Title level={5}>Purchase Statistic</Title>
            <BarLineChart data={customerEventStats} opts={{ colors: CES_COLORS }} />
          </div>
          <div className="box" style={{ gridRow: 'span 2' }}>
            <Title level={5}>Segments</Title>
            <Segments data={segments} />
          </div>
          <div className="box activities" style={{ gridColumn: 'span 2', gridRow: 'span 2' }}>
            <div className="title-wrapper">
              <Title level={5}>Activities</Title>
              <span level={5}>(Last 30 days)</span>
            </div>

            <Activities data={customerAnalyticsData?.customer_activities} />
          </div>
          <div className="box" style={{ gridRow: 'span 2' }}>
            <Title level={5}>Interactions</Title>
            <Interactions data={customerAnalyticsData} />
          </div>
        </div>
        <div className="box product-slider">
          <div className="title-wrapper">
            <Title level={5}>Recently viewed products</Title>
          </div>
          <ProductSlider data={customerAnalyticsData.recent_viewed_products} />
        </div>
        <div className="box product-slider">
          <div className="title-wrapper">
            <Title level={5}>Recommended products</Title>
          </div>
          <ProductSlider data={customerAnalyticsData.recommended_products} />
        </div>
        <div className="box product-slider">
          <div className="title-wrapper">
            <Title level={5}>Top viewed products (Top Overall) </Title>
          </div>
          <ProductSlider data={customerAnalyticsData.top_viewed_products} />
        </div>
        <div className="box product-slider">
          <div className="title-wrapper">
            <Title level={5}>Purchased products</Title>
          </div>
          <ProductSlider data={customerAnalyticsData.purchased_products} />
        </div>
      </div>
    </div>
  )
}

export default Customer360Analytic
