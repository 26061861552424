import React, { useMemo } from 'react'
import { capitalize, uniqBy } from 'lodash'
import { message, Skeleton } from 'antd'

import { CampaignPivotTypes, getBQColumns } from 'Services/Analytics/Pivot.service'
import { capitalizeFirstLetter } from 'Utils'
import PivotElementList from './Components/PivotElementList'
import Table from './Components/Table'
import { PivotTableContainerProps, SelectOptions, DRAG_TYPES } from './index.constants'

import './index.scss'
import { TEST_COLUMNS } from './constants'

const ProductPivotTable = ({
  isRanking,
  className = '',
  defaultMetrics = [],
  defaultDimensions = [],
  defaultColumns = [],
  object = 'campaign',
  objectId,
  dateRange = [],
  type = CampaignPivotTypes.ProductInsight,
  isCustomer,
  hideDimensionSelects,
  hideMetricSelects,
  hideColumnSelects,
  currentPage,
  dimension_segments,
  barcode,
}: PivotTableContainerProps) => {
  const [metricElements, setMetricElements] = React.useState(defaultMetrics)
  const [dimensionElements, setDimensionElements] = React.useState(defaultDimensions)
  const [columnElements, setColumnElements] = React.useState(defaultColumns)
  const [dimensionSelectableOptions, setDimensionSelectableOptions] = React.useState<
    SelectOptions[]
  >([])
  const [metricSelectableOptions, setMetricSelectableOptions] = React.useState<SelectOptions[]>([])
  const [columnSelectableOptions, setColumnSelectableOptions] = React.useState<SelectOptions[]>([])
  const [isLoading, setIsLoading] = React.useState(true)

  const computeDisabledOptions = (options) => {
    const newOptions = options.map((column) =>
      uniqBy([...metricElements, ...dimensionElements, ...columnElements], (e) => e.code)
        .map((dimension) => dimension.code)
        ?.includes(column.code)
        ? { ...column, disabled: true }
        : { ...column, disabled: false }
    )
    return newOptions
  }

  const computeSelectOptions = (data, type) => {
    switch (type) {
      case 'dimension':
        return data.map((obj) => {
          return {
            code: obj.field_name,
            name: obj.display_name,
            type: obj.data_type,
            display_name: obj.display_name,
          }
        })
      case 'metric':
        return data.map((obj) => {
          return {
            code: obj.field_name,
            name: obj.display_name,
            type: obj.data_type,
            aggregates: obj.aggregates,
            display_name: obj.display_name,
          }
        })
      case 'column':
        return data.map((obj) => {
          return {
            code: obj.field_name,
            name: obj.display_name,
            type: obj.data_type,
            display_name: obj.display_name,
          }
        })
    }
  }

  const fetchBQColumns = async () => {
    try {
      setIsLoading(true)
      const columns = await getBQColumns(type, currentPage, isRanking, barcode)
      setDimensionSelectableOptions(
        computeDisabledOptions(computeSelectOptions(columns.dimensions, 'dimension'))
      )
      setMetricSelectableOptions(
        computeDisabledOptions(computeSelectOptions(columns.metrics, 'metric'))
      )
      setColumnSelectableOptions(
        computeDisabledOptions(computeSelectOptions(columns.columns, 'column'))
      )
    } catch (err: any) {
      message.error(err.message)
    }
    setIsLoading(false)
  }

  const renderTable = useMemo(() => {
    return (
      <Table
        barcode={barcode}
        isRanking={isRanking}
        type={type}
        dimensions={dimensionElements}
        columns={columnElements}
        metrics={metricElements}
        object={object}
        objectId={objectId}
        dateRange={dateRange}
        isCustomer={isCustomer}
        currentPage={currentPage}
        dimension_segments={dimension_segments}
      ></Table>
    )
  }, [dimensionElements, metricElements, columnElements])

  React.useEffect(() => {
    if (
      !metricSelectableOptions.length ||
      !dimensionSelectableOptions.length ||
      !columnSelectableOptions.length
    )
      fetchBQColumns()
    if (
      metricSelectableOptions.length ||
      dimensionSelectableOptions.length ||
      columnSelectableOptions.length
    ) {
      setMetricSelectableOptions(computeDisabledOptions(metricSelectableOptions))
      setColumnSelectableOptions(computeDisabledOptions(columnSelectableOptions))
      setDimensionSelectableOptions(computeDisabledOptions(dimensionSelectableOptions))
    }
  }, [dimensionElements, metricElements, columnElements])

  return (
    <Skeleton paragraph={{ rows: 15 }} loading={isLoading} active>
      <div className={`PivotTableContainer ${className}`}>
        {!hideDimensionSelects && (
          <PivotElementList
            options={dimensionSelectableOptions}
            title="Dimensions"
            elements={dimensionElements}
            onChange={setDimensionElements}
          ></PivotElementList>
        )}
        {!hideMetricSelects && (
          <PivotElementList
            className="mt-2"
            options={metricSelectableOptions}
            title="Metrics"
            isMetric
            elements={metricElements}
            onChange={setMetricElements}
          ></PivotElementList>
        )}
        {!hideColumnSelects && (
          <PivotElementList
            className="mt-2"
            options={columnSelectableOptions}
            title="Columns"
            elements={columnElements}
            onChange={setColumnElements}
          ></PivotElementList>
        )}

        {renderTable}
      </div>
    </Skeleton>
  )
}

export default ProductPivotTable
