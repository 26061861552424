/// <reference lib="es2017.string" />

import React from 'react'
import './index.scss'
import StatisticIcon from 'Assets/images/NewIcons/statictis.png'
import OptionIcon from 'Assets/images/NewIcons/option.png'
import copyTextToClipboard from 'Utils/copy'
import { message } from 'antd'

export type ActionButtonProps = {
  type: 'copy' | 'option' | 'statistic' | 'edit' | 'delete' | 'new'
  clickValue?: any
  copyText?: string | undefined
  onClick?: () => void
  className: string
}

const ActionButton = (props: ActionButtonProps) => {
  const renderComponentContent = () => {
    switch (props.type) {
      case 'copy':
        return <p className="mb-0 font-semibold">Copy</p>
      case 'statistic':
        return <img src={StatisticIcon}></img>
      case 'option':
        return <img src={OptionIcon}></img>
      default:
        return <></>
    }
  }

  const handleClick = async () => {
    if (props.onClick) props.onClick()
    switch (props.type) {
      case 'copy':
        await copyTextToClipboard(props.copyText || '')
        message.success('Copied text to clipboard!')
        break
      case 'statistic':
        break
      case 'option':
        break
      default:
        return
    }
  }

  return (
    <div className={`ActionButtonComponent ${props.type} ${props.className}`} onClick={handleClick}>
      {renderComponentContent()}
    </div>
  )
}

ActionButton.defaultProps = {
  className: '',
  type: '',
}

export default ActionButton
