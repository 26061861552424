import React from 'react'
import { Typography, Tooltip, Badge } from 'antd'

import { capitalizeFirstLetter } from 'Utils'
import MemberShipBadge from 'Components/MemberShipBadge'
import { thousandsSeparator } from 'Utils/kFormatter'

const { Text } = Typography

export const CUSTOMER_LIST_COLUMNS = [
  'name',
  'customer_id',
  'tracking_id',
  'segments',
  'membership',
  'ltv',
  'life_cycle',
  'cluster',
  'trust_score',
  'buying_score',
  'add_to_cart_score',
  'churn_score',
  'high_ltv_score',
  'bill',
  'revenue',
  'last_source',
  'first_order',
  'last_order',
]
const computeColor = (input: number) => {
  if (input <= 0.5) {
    return '#F5222D'
  } else if (input > 0.5 && input <= 0.7) {
    return '#FAAD14'
  } else if (input > 0.7 && input <= 1) {
    return '#52C41A'
  }
}
export const COLUMNS = [
  ...CUSTOMER_LIST_COLUMNS.map((column, index, records) => {
    if (column === 'name') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        sorter: true,
        width: 200,
        align: 'left',
        render: (customer) =>
          customer ? (
            <Tooltip title={customer}>
              <Text className="customer-title" strong>
                {customer}
              </Text>
            </Tooltip>
          ) : (
            'N/A'
          ),
        fixed: 'left',
      }
    }
    if (column === 'customer_id') {
      return {
        title: 'Customer ID',
        dataIndex: column,
        sorter: true,
        width: 200,
        align: 'left',
      }
    }

    if (column === 'tracking_id') {
      return {
        title: 'Tracking ID',
        dataIndex: column,
        width: 150,
        render: (trackingId: string) => {
          return <span>{trackingId ? trackingId : 'N/A'}</span>
        },
      }
    }
    if (column === 'segments') {
      return {
        title: 'Segment',
        dataIndex: column,
        width: 277,
        render: (segments: string[]) => {
          return segments?.length > 3
            ? segments?.map((segment, index) => {
                if (index == segments.length - 2)
                  return (
                    <span>
                      {segment} + {segments.length - 3} others
                    </span>
                  )
                if (index == segments.length - 1) {
                  return
                }
                return <span>{segment}, </span>
              })
            : segments?.map((segment, index) => {
                if (index == segments.length - 1) return <span>{segment}</span>
                return <span>{segment}, </span>
              })
        },
      }
    }

    if (column === 'cluster') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        sorter: true,
        width: 150,
        render: (cluster: string) => {
          return <span>{cluster ? cluster : 'N/A'}</span>
        },
      }
    }

    if (column === 'ltv') {
      return {
        title: 'LTV',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (ltv: string) => {
          return <span>{ltv ? ltv : 'N/A'}</span>
        },
      }
    }

    if (column === 'life_cycle') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (life_cycle: string) => {
          return <span>{life_cycle ? life_cycle : 'N/A'}</span>
        },
      }
    }

    if (column === 'bill') {
      return {
        title: 'Bill',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (bill) => {
          return <span>{bill ? thousandsSeparator(bill) : 'N/A'}</span>
        },
      }
    }

    if (column === 'revenue') {
      return {
        title: 'Revenue',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (revenue) => {
          return <span>{revenue ? thousandsSeparator(revenue) : 'N/A'}</span>
        },
      }
    }

    if (column === 'last_source') {
      return {
        title: 'Last Source',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (last_source: string) => {
          return <span>{last_source ? last_source : 'N/A'}</span>
        },
      }
    }

    if (column === 'first_order') {
      return {
        title: 'First Order',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (first_order: string) => {
          return <span>{first_order ? first_order : 'N/A'}</span>
        },
      }
    }

    if (column === 'last_order') {
      return {
        title: 'Last Order',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (last_order: string) => {
          return <span>{last_order ? last_order : 'N/A'}</span>
        },
      }
    }

    if (column === 'membership') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (membership: string, record: any) => {
          if (membership)
            return (
              <div className="flex justify-center">
                <MemberShipBadge membership={membership} />
              </div>
            )
          else return 'N/A'
        },
      }
    }
    if (column === 'trust_score') {
      return {
        title: 'Trust score',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (value) => {
          return (
            <div className="flex justify-center">
              <Badge color={computeColor(value)} text={value ? value : '0'} />
            </div>
          )
        },
      }
    }
    if (column === 'buying_score') {
      return {
        title: 'Buying',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (value) => {
          return (
            <div className="flex justify-center">
              <Badge color={computeColor(value)} text={value ? value : '0'} />
            </div>
          )
        },
      }
    }
    if (column === 'add_to_cart_score') {
      return {
        title: 'Add to cart',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (value) => {
          return (
            <div className="flex justify-center">
              <Badge color={computeColor(value)} text={value ? value : '0'} />
            </div>
          )
        },
      }
    }
    if (column === 'churn_score') {
      return {
        title: 'Churn',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (value) => {
          return (
            <div className="flex justify-center">
              <Badge color={computeColor(value)} text={value ? value : '0'} />
            </div>
          )
        },
      }
    }
    if (column === 'high_ltv_score') {
      return {
        title: 'High LTV',
        dataIndex: column,
        width: 150,
        sorter: true,
        render: (value) => {
          return (
            <div className="flex justify-center">
              <Badge color={computeColor(value)} text={value ? value : '0'} />
            </div>
          )
        },
      }
    }
    return {
      title: capitalizeFirstLetter(column),
      dataIndex: column,
      width: 150,
      sorter: true,
    }
  }),
]

export const STATUS: { label: string; value: string }[] = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]
export const IS_ACTIVE = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]
export const CHANNELS = [
  { label: 'Published', value: 'sas' },
  { label: 'Draft', value: 'init' },
]
export const TYPES = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]
export const DATE = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]
