import React, { useState, useEffect } from 'react'
import { Select, Typography, InputNumber, Modal, TimePicker } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import { Key } from 'rc-select/lib/interface/generator'
// import { dimensions } from '../constants'

const { Title } = Typography
const { Option } = Select

interface Props {
  value: any
  visible: boolean
  toggleDimensionModal: any
  addSegment: any
  dimensions: any
  onDimensionModalCancel: any
}

type AttributeType = {
  type: string
  operator: '=' | '>' | '<' | '≥' | '≤'
  value: any
  in_last?: number
  time_format?: 'min' | 'hour' | 'day'
  time?: string
}

const UserAttributeModal: React.FC<Props> = ({
  value,
  visible,
  toggleDimensionModal,
  onDimensionModalCancel,
  addSegment,
  dimensions,
}) => {
  const [attributes, setAttributes] = useState<AttributeType>({
    type: 'item_in_cart',
    operator: '=',
    value: 1,
    in_last: 1,
    time_format: 'min',
    time: '12:00',
  })

  const selectedType =
    attributes && dimensions.find((item: { code: any }) => item.code === attributes.type)
  useEffect(() => {
    value
      ? setAttributes(value)
      : setAttributes({
          type: 'item_in_cart',
          operator: '=',
          value: 1,
          in_last: 1,
          time_format: 'min',
          time: '12:00',
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  useEffect(() => {
    if (!attributes) return

    //reset value if type doesn't have value
    if (!selectedType?.value) {
      setAttributes({
        ...attributes,
        value: (Number.isInteger(attributes.value) && attributes.value) || 1,
      })
    }

    //reset value if type doesn't have matched value
    else {
      setAttributes({ ...attributes, value: selectedType.value[0].code })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes?.type])

  const renderOptions = (optionType: string) => {
    return (
      attributes.type &&
      selectedType[optionType].map(
        (item: {
          code: Key | undefined
          name:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined
        }) => {
          return (
            <Option key={item.code || 0} value={item.code || ''}>
              {item.name}
            </Option>
          )
        }
      )
    )
  }

  const renderConfigContent = () => {
    if (!selectedType) return null
    return (
      <div className="CheckUserAttrForm">
        <div className="form-item type">
          <Title className="field-title" level={5}>
            Type
          </Title>
          <Select
            onChange={(v) => {
              setAttributes({ ...attributes, type: v })
            }}
            value={attributes.type}
            // name="type"
          >
            {dimensions.map(
              (item: {
                code: Key | undefined
                name:
                  | boolean
                  | React.ReactChild
                  | React.ReactFragment
                  | React.ReactPortal
                  | null
                  | undefined
              }) => {
                return (
                  <Option key={item.code || 0} value={item.code || ''}>
                    {item.name}
                  </Option>
                )
              }
            )}
          </Select>
        </div>
        <div className="form-item">
          <Title className="field-title" level={5}>
            Operator
          </Title>
          <Select
            onChange={(v) => setAttributes({ ...attributes, operator: v })}
            value={attributes.operator}
            // name="operator"
          >
            {renderOptions('operator')}
          </Select>
        </div>
        <div className="form-item">
          <Title className="field-title" level={5}>
            Value
          </Title>
          {selectedType?.value ? (
            <Select
              onChange={(v) => setAttributes({ ...attributes, value: v })}
              value={attributes.value}
              // name="value"
            >
              {renderOptions('value')}
            </Select>
          ) : (
            <InputNumber
              className="ant-input"
              onChange={(v) => setAttributes({ ...attributes, value: v })}
              value={attributes.value}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => {
                if (value) {
                  return value.replace(/\$\s?|(,*)/g, '')
                }
              }}
              name="value"
              min={0}
            ></InputNumber>
          )}
        </div>
        {selectedType?.in_period && (
          <div className="form-item">
            <Title className="field-title" level={5}>
              In last
            </Title>
            <div className="in-period">
              <InputNumber
                className="ant-input"
                onChange={(v) => setAttributes({ ...attributes, in_last: v })}
                value={attributes.in_last || 0}
                name="in_last"
                min={1}
                max={100}
              ></InputNumber>
              <Select
                onChange={(v) => setAttributes({ ...attributes, time_format: v })}
                value={attributes.time_format || 'min'}
                // name="time_format"
              >
                <Option value="min">mins</Option>
                <Option value="hour">hours</Option>
                <Option value="day">days</Option>
              </Select>
            </div>
          </div>
        )}
        {selectedType?.everyday_at && (
          <div className="form-item">
            <Title className="field-title" level={5}>
              Everyday at
            </Title>
            <div className="everyday-at">
              <TimePicker
                onChange={(moment, timeString) =>
                  setAttributes({ ...attributes, time: timeString })
                }
                value={moment(attributes.time, 'HH:mm')}
                showNow={false}
                format={'HH:mm'}
                minuteStep={5}
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  const onOk = () => {
    if (!selectedType.in_period) {
      delete attributes.in_last
      delete attributes.time_format
    }

    if (!selectedType.everyday_at) {
      delete attributes.time
    }

    addSegment({
      audience: attributes,
    })
  }

  return (
    <Modal
      title="Add user attribute"
      className="CheckUserAttrModal"
      closable
      onOk={() => onOk()}
      onCancel={() => onDimensionModalCancel()}
      visible={visible}
    >
      {renderConfigContent()}
    </Modal>
  )
}

export default connect(
  (state: any) => ({
    dimensions: state.segmentation.dimensions,
  }),
  (dispatch: any) => ({
    addSegment: dispatch.segmentation.addSegment,
    toggleDimensionModal: dispatch.segmentation.toggleDimensionModal,
    onDimensionModalCancel: dispatch.segmentation.onDimensionModalCancel,
  })
)(UserAttributeModal)
