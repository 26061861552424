import React, { useEffect, useMemo } from 'react'
import { Table, Empty } from 'antd'

import LoadingIcon from 'Components/LoadingIcon'
import useTable from './useTable'
import Actions from './Actions'
import { needStringifyArray } from 'Utils'
import { CustomerListContext } from 'Pages/Analytics/Customers/CustomerList'

const CustomTable = (props) => {
  const {
    columns: columnProps,
    fetchFrom,
    rowActions,
    queryObject,
    rowClassName,
    handleTableChange,
    defaultSort,
  } = props

  const { data, loading, total, setParams, params, setData }: any = useTable(fetchFrom)
  const CustomerListDownload = React.useContext(CustomerListContext)

  useEffect(() => {
    if (!queryObject || !Object.keys(queryObject).length) return
    setParams(
      needStringifyArray({
        order_by: defaultSort,
        per_page: 20,
        ...params,
        ...queryObject,
      })
    )
  }, [queryObject, CustomerListDownload?.isDownload])

  const renderActions = () => {
    if (!rowActions?.actions) return []
    return [
      {
        ...(rowActions.fixed && { fixed: rowActions.fixed }),
        dataIndex: 'action',
        key: 'x',
        width: 100,
        render: (text, record) => {
          return (
            rowActions?.actions && (
              <Actions actions={rowActions.actions} record={record} setRows={setData} rows={data} />
            )
          )
        },
      },
    ]
  }

  return (
    <Table
      className="TableComponent mt-4"
      dataSource={data}
      locale={{ emptyText: loading ? ' ' : <Empty></Empty> }}
      columns={[...columnProps, ...renderActions()]}
      pagination={{
        pageSizeOptions: ['5', '10', '20', '30', '50'],
        showSizeChanger: true,
        pageSize: params?.per_page || 10,
        total: parseInt(total) || undefined,
        current: params?.page || 1,
      }}
      loading={{
        spinning: loading,
        indicator: <LoadingIcon style={{ position: 'absolute', top: '80%', width: '50px' }} />,
      }}
      rowClassName={rowClassName}
      rowKey={fetchFrom.recordKey || 'id'}
      onChange={handleTableChange}
      scroll={{ x: 1000 }}
      onRow={(record, rowIndex) => ({
        onDoubleClick: () => rowActions?.onDoubleClick && rowActions.onDoubleClick(record),
        onClick: () => rowActions?.onRowClick && rowActions.onRowClick(record),
      })}
    />
  )
}

export default CustomTable
