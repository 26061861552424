import React from 'react'
import { number } from 'yup/lib/locale'
import MultiSelect from './MultiSelect'
import NumberInput from './Number'
import SingleSelect from './SingleSelect'

// for issue 672

export type props = {
  type: 'SINGLE_SELECT' | 'MULTI_SELECT' | 'NUMBER'
  value: string[] | string | number | undefined
  options?: { label: string; value: string }[] // for type single and multi select
  placeholder?: string
  onChange: (value: (string | number)[] | string | number) => void
}

const CommonInputs: React.FC<props> = ({ value, type, options, placeholder, onChange }) => {
  const renderInputs = () => {
    switch (type) {
      case 'SINGLE_SELECT':
        return (
          <SingleSelect
            placeholder={placeholder}
            value={value?.toString()}
            options={options}
            onChange={onChange}
          ></SingleSelect>
        )
      case 'MULTI_SELECT': {
        return (
          <MultiSelect
            placeholder={placeholder}
            value={Array.isArray(value) ? value : []}
            options={options}
            onChange={onChange}
          ></MultiSelect>
        )
      }
      case 'NUMBER': {
        return (
          <NumberInput
            placeholder={placeholder}
            value={value ? parseInt(value.toString()) : undefined}
            onChange={onChange}
          ></NumberInput>
        )
      }
    }
  }
  return <div className="CommonInputs">{renderInputs()}</div>
}

export default CommonInputs
