export const karibaEmailSchemas = [
  { name: 'subject', type: 'dynamic_content', label: 'Subject', isRequired: true },
  { name: 'utm_source', type: 'string', label: 'UTM Source', isRequired: true },
  { name: 'utm_medium', type: 'string', label: 'UTM Medium', isRequired: true },
  { name: 'utm_campaign', type: 'string', label: 'UTM Campaign', isRequired: true },
  {
    name: 'has_recommend_product',
    type: 'checkbox',
    label: 'Recommend Product',
    tooltip: 'Import recommended product into email content',
  },
  { name: 'image_item', type: 'image', label: 'Image (image_item)', isRequired: true },
  { name: 'image_item_url', type: 'string', label: 'Image (image_item_url)', isRequired: true },
  { name: 'image_item_1', type: 'image', label: 'Image (image_item_1)', isRequired: true },
  {
    name: 'image_item_1_url',
    type: 'string',
    label: 'Image 1 (image_item_1_url)',
    isRequired: true,
  },
  { name: 'image_item_2', type: 'image', label: 'Image (image_item_2)' },
  {
    name: 'image_item_2_url',
    type: 'string',
    label: 'Image 2 (image_item_2_url)',
    isRequired: true,
  },
  { name: 'email_type', type: 'hidden' },
  { name: 'email_service', type: 'hidden' },
  { name: 'template_id', type: 'hidden' },
]

export const karibaInitialValues = {
  subject: '',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  image_item: '',
  image_item_url: '',
  image_item_1: '',
  image_item_1_url: '',
  image_item_2: '',
  image_item_2_url: '',
  email_service: 'sendgrid',
  template_id: '',
}

export const sendgridEmailSchemas = [
  { name: 'subject', type: 'dynamic_content', label: 'Subject', isRequired: true },
  { name: 'utm_source', type: 'string', label: 'UTM Source', isRequired: true },
  { name: 'utm_medium', type: 'string', label: 'UTM Medium', isRequired: true },
  { name: 'utm_campaign', type: 'string', label: 'UTM Campaign', isRequired: true },
  { name: 'image_item', type: 'image', label: 'Image (image_item)' },
  { name: 'image_item_url', type: 'string', label: 'Image (image_item_url)' },

  { name: 'email_type', type: 'hidden' },
  { name: 'email_service', type: 'hidden' },
  { name: 'template_id', type: 'hidden' },
]

export const sendGridInitialValues = {
  subject: '',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  image_item: '',
  image_item_url: '',
  image_item_1: '',
  image_item_1_url: '',
  image_item_2: '',
  image_item_2_url: '',
  email_service: 'sendgrid',
  template_id: '',
}

export const sampleValues = {
  subject: 'Hello',
  utm_source: '123',
  utm_medium: '123',
  utm_campaign: '123',
  image_item:
    'https://storage.googleapis.com/dreamf-mcm-public-storage/product-images/71e7b348-8dbc-4646-98f9-e7693c7242c0',
  image_item_url: 'https://google.com',
  image_item_1:
    'https://storage.googleapis.com/dreamf-mcm-public-storage/product-images/021a08a3-1816-4bfe-9736-ff4a80c9508c',
  image_item_1_url: 'https://google.com',
  image_item_2:
    'https://storage.googleapis.com/dreamf-mcm-public-storage/product-images/e75dabe3-09cb-4df6-9c84-f379cb1d21f5',
  image_item_2_url: 'https://google.com',
  email_service: 'sendgrid',
  template_id: '',
}
