import MetricCard from 'Components/MetricCard'
import React, { useState } from 'react'

import { renderUnit } from './helpers'

export default function CustomerMetrics({ data }) {
  return (
    <div className="metric-page">
      <div className="metrics-wrapper">
        <MetricCard
          className="group"
          mode="small"
          title={data?.aov?.title}
          value={data?.aov?.value}
          unit={data?.aov?.unit}
          subvalue={data?.aov?.subvalue}
          unitDisplay={data?.aov?.unitDisplay}
        ></MetricCard>
        <MetricCard
          className="group"
          mode="small"
          style={{ minWidth: 130 }}
          title={data?.lastPurchased?.title}
          value={data?.lastPurchased?.value}
          unit={data?.lastPurchased?.unit}
          subvalue={data?.lastPurchased?.subvalue}
          unitDisplay={data?.lastPurchased?.unitDisplay}
        ></MetricCard>
        <MetricCard
          className="group"
          mode="small"
          title={data?.discount?.title}
          value={data?.discount?.value}
          unit={data?.discount?.unit}
          subvalue={data?.discount?.subvalue}
          unitDisplay={data?.discount?.unitDisplay}
        ></MetricCard>
      </div>
    </div>
  )
}
