import React from 'react'
import ChartLine from '../../Components/ChartLine'
import { getSaleChannelOverviewCharts } from 'Services/Analytics/saleChannel-analytics'
const ChartRow3 = () => {
  return (
    <div>
      <div className="p-4 mb-4 bg-white">
        <div className="pb-4 text-base font-semibold text-primary_text text-body">
          Net Sales by Hour
        </div>
        <ChartLine
          chartCode="net_sales_by_hour"
          handleDataFunction={getSaleChannelOverviewCharts}
        />
      </div>
    </div>
  )
}

export default ChartRow3
