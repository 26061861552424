import React from 'react'

// channels
import WebPush from 'Components/CJChannels/Webpush'
import SMS from 'Components/CJChannels/SMS'
import EmailTabs from 'Components/CJChannels/Email'
import Popup from 'Components/CJChannels/Popup'
import Kaipass from 'Components/CJChannels/Kaipass'
import { HaraSocialModalSF, HaraSocialModal } from 'Components/CJChannels/HaraSocial'

// others
import ABTesting from './ABTesting'
import Wait from './Wait'

// conditions
import UserAttribute from './UserAttribute'
import Reachability from './Reachability'
import SelectAudience from './SelectAudience'
import { CurrentType } from 'CustomerJourneyModule'

import './index.scss'
import AppPush from 'Components/CJChannels/AppPush'
import Entrance from './Entrance'

interface Props {
  current: CurrentType
  data: any
  isRunning: boolean
  value: any
  isPopoverVisible: boolean
  onAddNewNode: (type: string, value: any) => void
  onUpdateNode: (value: any) => void
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
}

const Config: React.FC<Props> = (props) => {
  const { data, onUpdateNode, onAddNewNode, setIsPopoverVisible } = props

  const handleEmailSubmit = async (values: any) => {
    onAddNewNode && (await onAddNewNode('email', values))
    onUpdateNode && (await onUpdateNode(values))
  }

  const renderConfig = (type: string) => {
    const values = props?.current.value

    switch (type) {
      // channels
      case 'web_push':
        return <WebPush {...props} />
      case 'sms':
        return <SMS {...props} />
      case 'email':
        return (
          <EmailTabs
            {...props}
            withModal
            values={values}
            onSubmit={handleEmailSubmit}
            showPreview
          />
        )
      case 'popup':
        return <Popup {...props} />
      case 'kaipass':
        return <Kaipass {...props} />

      case 'harasocial':
        return <HaraSocialModal {...props} setIsPopoverVisible={setIsPopoverVisible} />

      case 'hrs_store_farming':
        return <HaraSocialModalSF {...props} setIsPopoverVisible={setIsPopoverVisible} />
      case 'tch_app':
        return <AppPush {...props} setIsPopoverVisible={setIsPopoverVisible}></AppPush>

      // others
      case 'wait':
        return <Wait {...props} />
      case 'split':
        return <ABTesting {...props} />

      // conditions
      case 'check_user_attr':
        return <UserAttribute {...props} />
      case 'check_reachability':
        return <Reachability {...props} />
      case 'entrance':
        return <Entrance {...props} />
      case 'entrance_from_app':
        return <Entrance {...props} />
      case 'entrance_from_web':
        return <Entrance {...props} />
      case 'check_audience_segment':
        return <SelectAudience {...props} />
      default:
        return 'Not available at the moment'
    }
  }

  return <div className="NodeConfigComponent">{renderConfig(data)}</div>
}

export default Config
