import React, { useState, useEffect } from 'react'
import './index.scss'
import { useInView } from 'react-intersection-observer'
import { getCustomerOverviewKeyMetrics } from 'Services/Analytics/customer-analytics'
import { useHistory } from 'react-router-dom'

import KeyMetricsTemplate from '../../../Components/KeyMetrics.Template'

import ChartRow1 from './ChartRow1'
import ChartRow2 from './ChartRow2'
import PivotRow1 from './PivotRow_1'
import PivotRow2 from './PivotRow_2'
import PivotRow3 from './PivotRow_3'
import PivotRow4 from './PivotRow_4'
import ChartPivot from './Chart_Pivot'
import CustomizeSection from './CustomizeSection'

import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'
import { getProductOverviewKeyMetrics } from 'Services/Analytics/product-analytics'
import PivotTableTemplate from 'Pages/Analytics/Components/PivotTable.Template'
import { ProductPivotTypes } from 'Services/Analytics/Pivot.service'

const ProductOverviewBody = () => {
  const history = useHistory()
  const [isResetting, setIsResetting] = useState(false)
  const { ref: ref1, inView: ref1InView } = useInView({ triggerOnce: true })
  const { ref: ref2, inView: ref2InView } = useInView({ triggerOnce: true })
  const { ref: ref3, inView: ref3InView } = useInView({ triggerOnce: true })
  const { ref: ref4, inView: ref4InView } = useInView({ triggerOnce: true })
  const { ref: ref5, inView: ref5InView } = useInView({ triggerOnce: true })
  const { ref: ref6, inView: ref6InView } = useInView({ triggerOnce: true })
  const { ref: ref7, inView: ref7InView } = useInView({ triggerOnce: true })
  const { ref: ref8, inView: ref8InView } = useInView({ triggerOnce: true })
  const { ref: ref9, inView: ref9InView } = useInView({ triggerOnce: true })

  useEffect(() => {
    setIsResetting(true)

    setTimeout(() => {
      setIsResetting(false)
    }, 10)
  }, [history.location.search])

  if (isResetting) return <div className="w-full" style={{ height: 1000 }}></div>

  return (
    <div className="my-4">
      <div
        style={{ minHeight: 200 }}
        ref={ref1}
        className="bg-white p-4 product-analytics-overview-metrics"
      >
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Key Metric</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        {ref1InView && <KeyMetricsTemplate handleDataFunction={getProductOverviewKeyMetrics} />}
      </div>
      <div style={{ minHeight: 340 }} ref={ref2} className="bg-white grid grid-cols-3 gap-4 mt-4">
        {ref2InView && <ChartRow1 />}
      </div>
      <div ref={ref3} style={{ minHeight: 500 }} className="bg-white p-4 mt-4">
        {ref3InView && <PivotRow1 />}
      </div>
      <div ref={ref4} style={{ minHeight: 500 }} className="bg-white p-4 mt-4">
        {ref4InView && <PivotRow2 />}
      </div>
      <div ref={ref5} style={{ minHeight: 500 }} className="bg-white p-4 mt-4">
        {ref5InView && <PivotRow3 />}
      </div>
      <div ref={ref6} style={{ minHeight: 700 }} className="bg-white p-4 mt-4">
        {ref6InView && <PivotRow4 />}
      </div>
      <div style={{ minHeight: 600 }} ref={ref7} className="bg-white mt-4 grid grid-cols-3">
        {ref7InView && <ChartRow2 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref8} className="bg-white mt-4 grid grid-cols-3">
        {ref8InView && <ChartPivot />}
      </div>
      <div style={{ minHeight: 182 }} ref={ref9} className="bg-white p-4 mt-4 ">
        <div className="mb-4 font-semibold">Customize Section</div>
        {ref9InView && (
          <PivotTableTemplate
            type={ProductPivotTypes.ProductCustomizeSection}
            currentPage={window?.location?.href?.split('/')[4]?.split('?')[0]}
          />
        )}
      </div>
    </div>
  )
}

export default ProductOverviewBody
