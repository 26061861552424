import React from 'react'
import BarChartTemplate from '../../Components/BarChart.Template'
import { useHistory, useParams } from 'react-router-dom'

import { getProduct360Charts } from 'Services/Analytics/product-analytics'

const ChartRow1 = () => {
  const { id } = useParams()
  return (
    <>
      <div className="bg-white p-4 col-span-3 shadow-div_b">
        <div className="mb-4 font-semibold">Products sale by days</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ height: 260 }}>
          <BarChartTemplate
            chartCode="product_sale_frequency"
            handleDataFunction={getProduct360Charts}
            isStacked
            barcode={id}
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow1
