import { action, thunk, computed } from 'easy-peasy'
import axios from 'Utils/axios'
import { message } from 'antd'
import { pickBy, identity } from 'lodash'
import { FB_PLACEMENT_CODES } from 'Utils/constants'
const { success, error } = message

const initState = {
  name: '',
  objective: '',
  kpi: undefined,
  budget: undefined,
  marketingObjectives: [],
  campaignId: '',
  campaignCode: '',
  daily_budget: undefined,
  bid_amount: undefined,
  loading: false,
  actionLoading: false,
}
export default {
  ...initState,
  toggleLoading: action((state) => {
    state.loading = !state.loading
  }),
  toggleActionLoading: action((state) => {
    state.actionLoading = !state.actionLoading
  }),
  setSettingStates: action((state, { key, value }) => {
    state[key] = value
  }),
  resetState: action((state) => ({ ...initState })),
  fetchMarketingObjectives: thunk(async (actions, _, { getState }) => {
    actions.toggleLoading()
    try {
      const rs = await axios.get('/marketing-objectives/')
      if (rs.data) actions.setSettingStates({ key: 'marketingObjectives', value: rs.data })
    } catch (err) {
      // return Promise.reject(err.response.data.detail)
    } finally {
      actions.toggleLoading()
    }
  }),
  createCampaignSetting: thunk(async (actions, isFBCampaign, { getState }) => {
    const { name, objective, daily_budget, bid_amount, kpi } = getState()
    const FBParams = {
      name: name,
      mkt_obj: objective,
      daily_budget: daily_budget,
      bid_amount: bid_amount,
      kpi: kpi,
    }
    const communityParams = {
      name: name,
      mkt_obj: objective,
    }
    try {
      const resp = await axios.post('/campaigns/', isFBCampaign ? FBParams : communityParams)
      await actions.setSettingStates({ key: 'campaignId', value: resp.data.id })
      return { campaign_id: resp.data.id }
    } catch (err) {
      return Promise.reject({ errors: err.response })
    }
  }),
  deleteCampaign: thunk(async (actions, id) => {
    try {
      await axios.delete(`campaigns/${id}`)
      return { ok: true }
    } catch (err) {
      return Promise.reject({ errors: err.response.data })
    }
  }),
  updateCampaignSetting: thunk(async (actions, _, { getState, getStoreState }) => {
    const storeState = getStoreState()
    const isWebPushCampaign =
      storeState.campaign.placements.selectedPlacementCode.includes('web_push')
    try {
      const { name, objective, daily_budget, bid_amount, kpi, campaignId } = getState()
      const resp = await axios.put(
        `/campaigns/${campaignId}`,
        pickBy(
          {
            name: name,
            mkt_obj: objective,
            ...(!isWebPushCampaign
              ? { daily_budget: daily_budget, bid_amount: bid_amount, kpi: kpi }
              : null),
          },
          identity
        )
      )

      return { ok: true }
    } catch (err) {
      return Promise.reject({ errors: err.response })
    }
  }),
  setCampaignActions: thunk(async (actions, { id, action }) => {
    const resp = await axios.post(`campaigns/${id}/action?action=${action}`)

    if (resp.status === 200) {
      return { ok: true }
    }
    return error(resp.response.data.detail[0], 7)
  }),
}
