import React, { useRef, useEffect, useState, useMemo } from 'react'
import { Input } from 'antd'
import CodeEditor from '@uiw/react-textarea-code-editor'
import { useStoreState, useStoreActions } from 'easy-peasy'

import './index.scss'

const SQLInput = () => {
  const ref: any = useRef(null)
  const { custom_sql } = useStoreState((state: any) => state.customerSegments)
  const { setState } = useStoreActions((action: any) => action.customerSegments)
  const [rowCounts, setRowCounts] = useState(10)

  useEffect(() => {
    const codePreviewHeight: number =
      document.querySelector('.w-tc-editor-preview')?.clientHeight || 0
    if (Math.floor(codePreviewHeight / 22) != rowCounts) {
      setRowCounts(Math.floor(codePreviewHeight / 22))
    }
  }, [custom_sql])

  const generateRowCounts = useMemo(() => {
    let rows: any = []
    for (let i = 0; i < rowCounts; i++) {
      rows.push(<p>{i + 1}</p>)
    }

    return rows
  }, [rowCounts])

  return (
    <div className="SQLInput">
      <div className="flex h-full">
        <div className="input-rows">{generateRowCounts}</div>
        <CodeEditor
          ref={ref}
          value={custom_sql}
          language="sql"
          placeholder="Start compose your query here"
          onChange={(evn) => setState({ key: 'custom_sql', value: evn.target.value })}
          padding={12}
          style={{
            fontSize: 14,
            minHeight: 200,
            // maxHeight: 400,
            // overflow: 'scroll',
            fontFamily:
              'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
          }}
        />
      </div>
    </div>
  )
}

export default SQLInput
