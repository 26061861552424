import { Empty } from 'antd'
import LoadingIcon from 'Components/LoadingIcon'
import axios from 'axios'
import React from 'react'
import { Alert, Skeleton } from 'antd'
import DecorateTable from './tableDecorator'
import { getPivotTableContent, CampaignPivotTypes } from 'Services/Analytics/Pivot.service'
import { formatErrors, needStringifyArray } from 'Utils'
import { PivotTableProps } from '../index.constants'
import tableDecorator from './tableDecorator'
import OverlayLoading from 'Components/OverlayLoading'
import { useStoreState } from 'easy-peasy'

const PivotTable = ({
  currentPage,
  metrics,
  dimensions,
  columns,
  object,
  objectId,
  dateRange = [],
  type = CampaignPivotTypes.ProductInsight,
  isCustomer = false,
  dimension_segments,
  isRanking,
  barcode,
}: PivotTableProps) => {
  const [loading, setLoading] = React.useState(false)
  const [drawing, setDrawing] = React.useState(false)
  const [error, setError] = React.useState('')
  const [htmlString, setHTMLString] = React.useState('')
  const tableRef = React.useRef<HTMLDivElement>(null)
  const { dimensions: segmentDimensions } = useStoreState((state: any) => state.dimensions)
  // SIDE EFFECTS
  React.useEffect(() => {
    let isCancelled = false
    let cancelTokenSource = axios.CancelToken.source()
    if (!metrics.length || !dimensions?.length) return
    ;(async () => {
      try {
        setError('')
        setLoading(true)
        let html
        const formattedMetrics = metrics.map((metric) => {
          return { columns: metric.code, aggregate: metric.aggregate }
        })
        const formattedDimensions = dimensions.map((dim) => dim.code)
        const formattedColumns = columns?.map((col) => col.code)

        html = await getPivotTableContent({
          csv: false,
          barcode,
          currentPage,
          type,
          metrics: formattedMetrics,
          dimensions: formattedDimensions,
          columns: formattedColumns,
          dateRange,
          objectId,
          segmentDimensions: dimension_segments,
          isCustomer,
          cancelTokenSource,
          isRanking,
        })

        setHTMLString(html?.htmlString)
        setDrawing(true)
        // DECORATE table
        setTimeout(() => {
          tableDecorator(tableRef, dimensions.length, { sortable: false })
          setDrawing(false)
        }, 700)
      } catch (error: any) {
        if (error.message === 'cancelled') return
        setError(formatErrors(error?.response?.data))
      }
      setLoading(false)
    })()

    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [metrics, dimensions, columns, object, objectId, dateRange, segmentDimensions])

  // RENDER
  if (error) return <Alert message={error} type="error" />

  if (!htmlString && !loading) return <Empty></Empty>

  return (
    <div className="PivotTableComponent">
      {drawing && <OverlayLoading></OverlayLoading>}
      <Skeleton paragraph={{ rows: 10 }} loading={loading} active>
        <div ref={tableRef} dangerouslySetInnerHTML={{ __html: htmlString }}></div>
      </Skeleton>
    </div>
  )
}

export default PivotTable
