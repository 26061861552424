import React from 'react'

import Gender from './Gender'
import Age from './Age'
import Location from './Location'
import Interests from './Interests'
import './index.scss'

const FacebookCriteria = () => {
  return (
    <div id="FacebookCriteria">
      <div className="mb-5">
        <h3 className="mb-2 text-disabled_text font-semibold">Locations</h3>
        <Location />
      </div>
      <div className="mb-5">
        <h3 className="mb-2 text-disabled_text font-semibold">Interests</h3>
        <Interests />
      </div>
      <div className="flex justify-between gap-5">
        <div className="w-1/3 field-wrapper">
          <h3 className="mb-2 text-disabled_text font-semibold">Gender</h3>
          <div className="w-full bg-gray_1 field-item">
            <Gender />
          </div>
        </div>
        <div className="w-2/3 field-wrapper">
          <h3 className="mb-2 text-disabled_text font-semibold">Age</h3>
          <div className="w-full bg-gray_1 field-item">
            <Age />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FacebookCriteria
