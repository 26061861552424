import React, { useState } from 'react'
import { Button, Modal, Tooltip, Typography } from 'antd'
import {
  BarChartOutlined,
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  DashOutlined,
} from '@ant-design/icons'

const { Title } = Typography

const ClickAction = ({ action, disabled, record, setRows, rows }) => {
  const [isTriggering, setIsTriggering] = useState(false)

  const renderButton = (name, icon) => {
    switch (name.key) {
      case 'delete':
        return icon || <DeleteOutlined />
      case 'edit':
        return icon || <EditOutlined />
      case 'statistics':
        return icon || <BarChartOutlined />
      default:
        return <DashOutlined />
    }
  }

  const onClick = () => {
    if (action.name.key === 'delete') {
      Modal.confirm({
        title: (
          <div className="action-prompt-popup">
            <Title level={4}>Are you sure to delete {record.name}?</Title>
            <span className="prompt-description">{action.prompt?.description}</span>
          </div>
        ),
        onOk: async () => {
          try {
            await handleOnClick()
            setRows(rows.filter((row) => row.id !== record.id))
          } catch (err) {
            return false
          }
        },
        okText: 'Delete',
        okType: 'danger',
        centered: true,
      })

      return
    }

    handleOnClick()
  }

  const handleOnClick = async () => {
    try {
      setIsTriggering(!isTriggering)
      await action.onClick(record)
    } finally {
      setIsTriggering(false)
    }
  }
  const disabledButton = () => {
    if (typeof action.disabled === 'boolean') return action.disabled
    else if (typeof action.disabled === 'function') return action.disabled(record)
    return !action.availableFor ? false : !action.availableFor.includes(record.status)
  }
  return (
    <Tooltip title={action.name.label}>
      <Button disabled={disabledButton()} className={action.name.key} onClick={onClick}>
        {isTriggering ? <LoadingOutlined spin /> : renderButton(action.name, action.icon)}
      </Button>
    </Tooltip>
  )
}

export default ClickAction
