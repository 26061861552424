import React from 'react'
import { MobileOutlined } from '@ant-design/icons'
import { Email } from '@styled-icons/material-outlined/Email'
import { ZaloIcon } from 'Components/Icon'
import { Messenger } from '@styled-icons/boxicons-logos/Messenger'
import { GlobeOutline as Web } from '@styled-icons/evaicons-outline/GlobeOutline'
import { Radar } from '@styled-icons/boxicons-regular/Radar'
import { CoffeeTogo } from '@styled-icons/boxicons-regular/CoffeeTogo'
import { colors } from 'DesignSystem'

export const CHANNELS = [
  { value: 'zalo', label: 'Zalo', icon: <ZaloIcon size={16} /> },
  { value: 'messenger', label: 'Messenger', icon: <Messenger style={{ width: 20, height: 20 }} /> },
  { value: 'email', label: 'Email', icon: <Email style={{ width: 16, height: 16 }} /> },
  { value: 'website', label: 'Website', icon: <Web style={{ width: 16, height: 16 }} /> },
  { value: 'app', label: 'Application', icon: <Radar style={{ width: 16, height: 16 }} /> },
  { value: 'sms', label: 'SMS Call', icon: <MobileOutlined /> },
  {
    value: 'tch',
    label: 'The Coffee House',
    icon: <CoffeeTogo style={{ width: 16, height: 16 }} />,
  },
]

export const ACTIVITIES_CHANNELS = {
  pos: {
    label: 'POS',
    color: colors.systemColor.purple_6,
    background: colors.systemColor.purple_1,
  },
  web: {
    label: 'Web',
    color: colors.systemColor.greekBlue_6,
    background: colors.systemColor.greekBlue_1,
  },
  app: {
    label: 'App',
    color: colors.systemColor.gold_6,
    background: colors.systemColor.gold_1,
  },
  facebook: {
    label: 'Facebook',
    color: colors.systemColor.blue_6,
    background: colors.systemColor.blue_1,
  },
}
