import React from 'react'

const ProductTag = ({ mix_category_name, psi_status, sale_status }) => {
  const renderSaleStatus = (status) => {
    switch (status) {
      case 'Star':
        return <div className="product360-info-item-title-tag">⭐Star</div>
      case 'Dog':
        return <div className="product360-info-item-title-tag">🐶Dog</div>
      case 'Cow':
        return <div className="product360-info-item-title-tag">🐄Cow</div>
      case 'Question':
        return <div className="product360-info-item-title-tag">❓Question</div>
    }
  }
  return (
    <div className="product360-info-item-title-tags">
      <div className="product360-info-item-title-tag">{mix_category_name}</div>
      {renderSaleStatus(sale_status)}
      <div className="product360-info-item-title-tag">{psi_status}</div>
    </div>
  )
}

export default ProductTag
