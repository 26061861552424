import React from 'react'
import { useParams } from 'react-router-dom'
import SaleChannelPivotTableTemplate from 'Pages/Analytics/SaleAnalytics/Components/PivotTable.Template'
const PivotRow4 = () => {
  const { id } = useParams()
  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <div className="font-semibold">Top Sellers on channel</div>
        {/* <div className="flex text-brand_primary">
        <div className="mr-4">
          <RefreshIcon />
        </div>
        <div>
          <DownIcon />
        </div>
      </div> */}
      </div>
      <SaleChannelPivotTableTemplate
        analytic_type={'top_sellers_on_sale_channel'}
        isStore={false}
        location={'store360'}
        store_id={id}
      />
    </div>
  )
}

export default PivotRow4
