import React, { useState } from 'react'
import { Button, Modal, Tooltip, Typography } from 'antd'
import { PlayCircleFilled, PauseCircleFilled, LoadingOutlined } from '@ant-design/icons'

const { Title } = Typography

const ToggleAction = ({ action, record, setRows, rows }) => {
  const [isTriggering, setIsTriggering] = useState(false)
  const [currentStatus, setCurrentStatus] = useState(record.status)
  const [isPositive, setIsPositive] = useState(['running', 'active'].includes(record.status))

  const renderButton = () => {
    if (action.name[0].availableFor.includes(currentStatus))
      return action.name[0].icon || <PlayCircleFilled />

    if (action.name[1].availableFor.includes(currentStatus))
      return action.name[1].icon || <PauseCircleFilled />

    return <PlayCircleFilled />
  }

  const handleOnToggle = async () => {
    setIsTriggering(!isTriggering)
    setIsPositive(!isPositive)
    const resp = await action.onClick(record)
    if (resp) {
      setCurrentStatus(resp.data.status)
      setRows(rows.map((row) => (row.id === resp.data.id ? resp.data : row)))
    }
    setIsTriggering(false)
  }

  return (
    <Tooltip title={action.name.find((item) => item.availableFor.includes(currentStatus))?.label}>
      <Button
        className={isPositive ? 'active' : 'inactive'}
        disabled={!action.name.find((item) => item.availableFor.includes(currentStatus))}
        onClick={handleOnToggle}
      >
        {isTriggering ? <LoadingOutlined spin /> : renderButton()}
      </Button>
    </Tooltip>
  )
}

export default ToggleAction
