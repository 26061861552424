import React from 'react'

import PivotTable from 'Components/Charts/PivotTable'
import MultiDoughnutChartTemplate from 'Pages/Analytics/Components/MultiDoughnutChart.Template'
import { getCampaignOverviewCharts } from 'Services/Analytics/campaign-analytics'
import { BarLineChart } from 'Components/Charts/MixCharts'
import { CES_COLORS } from 'Containers/Customer360/data'

const Test = () => {
  return (
    <BarLineChart
      data={{
        labels: ['Aug 1', 'Aug 3', 'Aug 5', 'Aug 7', 'Aug 9', 'Aug 11', 'Aug 13'],
        bars: {
          revenue: {
            key: 'revenue',
            title: 'Doanh thu',
            values: [1633609, 993999, 864569, 894202],
          },
          cost: { key: 'cost', title: 'Tiền', values: [1533609, 923999, 814569, 891202] },
        },
        lines: {
          orders: { key: 'orders', title: 'Số đơn', values: [17, 18, 7, 29] },
          sessions: { key: 'sessions', title: 'Sessions', values: [10, 7, 20, 34] },
        },
      }}
      opts={{ colors: CES_COLORS }}
    ></BarLineChart>
  )
}

export default Test
