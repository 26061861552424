import React from 'react'
import { Skeleton, Tabs } from 'antd'
import { useHistory, Link } from 'react-router-dom'

import { createTypedHooks } from 'easy-peasy'
import { ModelTypes } from 'Models/index.types'
import queryString from 'query-string'
import { BreadCrumbType } from 'MBreadCrumb'

import './index.scss'
import { SS_TABS } from './Constants/SocialSelling.constants'
import { MANAGER_SF_TABS } from './Constants/ManagerStoreFarming.constants'
import { STAFF_SF_TABS } from './Constants/StaffStoreFarming.constants'
import { OUTER_SF_TABS } from './Constants/OuterStoreFarming.constants'
import { hasPermission } from 'Utils'

const { TabPane } = Tabs

type CustomTabsProps = {
  className?: string
  type: 'ss' | 'staff_sf' | 'manager_sf' | 'outer_sf'
  defaultTab?: string
  defeaultBreadCrumb?: BreadCrumbType[]
  permission?: boolean
  queryKey?: string
}

type CustomTabType = {
  label: string
  tabKey: string
  roles?: string[]
  breadCrumbs?: BreadCrumbType[]
  onTabClick: ({ tabKey, breadCrumbs }) => void
}

const CustomTabs: React.FC<CustomTabsProps> = React.memo(
  ({ type, defaultTab, defeaultBreadCrumb, className = '', queryKey = 'tab' }: CustomTabsProps) => {
    const history = useHistory()
    const typedHooks = createTypedHooks<ModelTypes>()
    const setBreadCrumb = typedHooks.useStoreActions((actions) => actions.breadcrumb.setBreadCrumb)
    const queryStr = queryString.parse(history.location.search)
    const [loading, setLoading] = React.useState(true)
    const curTab = queryStr[queryKey]
    // userInfo State
    const userInfo = typedHooks.useStoreState((state) => state.auth.userInfo)
    // tabs State
    const [tabs, setTabs] = React.useState(() => {
      switch (type) {
        case 'ss':
          return SS_TABS
        case 'staff_sf':
          return STAFF_SF_TABS
        case 'manager_sf':
          return MANAGER_SF_TABS
        case 'outer_sf':
          return OUTER_SF_TABS
      }
    })

    // currentTabKey State
    const [currentTabKey, setCurrentTabKey] = React.useState(() => {
      if (defaultTab) return defaultTab
      else if (curTab) return curTab
      return ''
    })

    React.useEffect(() => {
      setLoading(true)
      if (!userInfo.roles) return
      setTabs(tabs.filter((t) => hasPermission(userInfo.roles, t.roles)))
      setLoading(false)
    }, [userInfo])

    // Side Effect
    React.useEffect(() => {
      const defBC = defeaultBreadCrumb || tabs[0]?.breadCrumbs
      setBreadCrumb((defBC as BreadCrumbType[]) || [])
      const defTab = currentTabKey || tabs[0].key
      setCurrentTabKey(defTab)
      const qStr = queryString.parse(history.location.search)
      qStr[queryKey] = defTab
      history.push({ search: queryString.stringify(qStr) })
    }, [])

    const onChangeTab = (tabKey) => {
      const qStr = queryString.parse(history.location.search)
      setCurrentTabKey(tabKey as string)
      qStr[queryKey] = tabKey
      history.push({ search: queryString.stringify(qStr) })
    }

    const onTabClick = (tab) => {
      setBreadCrumb(tab.breadCrumbs)
    }

    const renderMeat = () => {
      return tabs?.map((item) => {
        if (loading) return <Skeleton key={item.key}></Skeleton>
        return (
          <TabPane
            key={item.key}
            tabKey={item.key}
            tab={
              <span className="text-lg font-semibold" onClick={() => onTabClick(item)}>
                {item.label}
              </span>
            }
          ></TabPane>
        )
      })
    }
    return (
      <Tabs
        className={`CustomTabsComponent ${className}`}
        activeKey={currentTabKey as string}
        onChange={onChangeTab}
      >
        {renderMeat()}
      </Tabs>
    )
  }
)

CustomTabs.defaultProps = {
  className: '',
  type: 'ss',
  permission: true,
}

export default CustomTabs
