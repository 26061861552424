import React from 'react'
import { CJ_NODE_HEIGHT, CJ_NODE_WIDTH } from './constants'
import classNames from 'classnames'
import { colors } from 'DesignSystem'

const SplitPath = ({
  x,
  y,
  dash,
  path,
  percentage,
  showText,
  bgColor,
  isSplitType,
  leftTitle,
  rightTitle,
  node_uuid,
  leftPathColor,
  rightPathColor,
}) => {
  return (
    <g x="500" y="500">
      <linearGradient id={node_uuid} className="yes_no_linear" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor={leftPathColor} />
        <stop offset={percentage} stopColor={leftPathColor} />
        <stop offset={percentage} stopColor={rightPathColor} />
        <stop offset="100%" stopColor={rightPathColor} />
      </linearGradient>
      {showText && (
        <text style={{ fill: leftPathColor, fontWeight: '700' }} x={x} y={y + CJ_NODE_HEIGHT + 25}>
          {leftTitle}
        </text>
      )}
      {showText && (
        <text
          style={{ fill: rightPathColor, fontWeight: '700' }}
          x={x + CJ_NODE_WIDTH - 25}
          y={y + CJ_NODE_HEIGHT + 25}
        >
          {rightTitle}
        </text>
      )}
      <path
        className={classNames(['node-line', { 'split-type': isSplitType }])}
        d={path}
        strokeWidth="3"
        strokeLinejoin="round"
        fill="transparent"
        // strokeDasharray={dash}
        stroke={isSplitType ? `url(#${node_uuid})` : bgColor}
      ></path>
    </g>
  )
}

SplitPath.defaultProps = {
  dash: 0,
  leftTitle: 'No',
  rightTitle: 'Yes',
  leftPathColor: colors.systemColor.cyan_5,
  rightPathColor: colors.systemColor.blue_6,
}

export default SplitPath
