import React from 'react'
import { CUSTOMER_DIMENSION, CUSTOMER_METRICS } from 'Pages/Analytics/constants'
import PivotTableTemplate from '../../../Components/PivotTable.Template'
import { CustomerPivotTypes, ProductPivotTypes } from 'Services/Analytics/Pivot.service'
import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'

const PivotRow_3 = () => {
  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <div className="font-semibold">Inventory</div>
        {/* <div className="flex text-brand_primary">
          <div className="mr-4">
            <RefreshIcon />
          </div>
          <div>
            <DownIcon />
          </div>
        </div> */}
      </div>
      <PivotTableTemplate
        isRanking
        hideDimensionSelects
        hideMetricSelects
        hideColumnSelects
        currentPage={window?.location?.href?.split('/')[4]?.split('?')[0]}
        type={ProductPivotTypes.ProductInventory}
        defaultDimensions={[
          { code: 'category', name: 'Category' },
          { code: 'sub_category', name: 'Sub Category' },
          { code: 'product_name', name: 'Product Name' },
          { code: 'bar_code', name: 'Barcode' },
        ]}
        defaultMetrics={[
          {
            code: 'sku',
            name: 'SKU',
          },
          {
            code: 'barcode',
            name: 'Barcode',
          },
          {
            code: 'image',
            name: 'Image',
          },
          {
            code: 'sum_quantity',
            name: 'Quantity',
          },
          {
            code: 'sum_revenue',
            name: 'Revenue',
          },
          {
            code: 'ratio_total_revenue',
            name: '% of Total Revenue',
          },
          {
            code: 'drr',
            name: 'Daily Run Rate',
          },
          {
            code: 'days_to_clear',
            name: 'Days to clear',
          },
        ]}
      />
    </div>
  )
}

export default PivotRow_3
