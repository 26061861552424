import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import { notification } from 'antd'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { formatErrors } from '../../Utils'
import LoginView from './Login.view'
import { loginSchema } from 'Validation'
import './index.scss'

const initialValues = {
  email: '',
  password: '',
}

export default function Login() {
  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const loginAction = useStoreActions((actions) => actions.auth.login)
  const authStore = useStoreState((state) => state.auth)

  const handleLogin = async (values) => {
    setIsSubmitting(true)
    try {
      const captcha_token = await executeRecaptcha('login_page')
      if (!captcha_token) return setServerError('reCaptcha went wrong')

      await loginAction({ ...values, captcha_token })
      history.push('/')
    } catch (err) {
      setServerError(formatErrors(err.response.data))
    }

    setIsSubmitting(false)
  }

  const loginHarawork = (values) => {}

  return (
    <div className="login-page">
      <Formik
        initialValues={initialValues}
        onSubmit={handleLogin}
        validationSchema={loginSchema}
        children={(props) => (
          <LoginView
            {...props}
            loginHarawork={loginHarawork}
            serverError={serverError}
            isValidating={false}
            isSubmitting={isSubmitting}
          />
        )}
      />
    </div>
  )
}
