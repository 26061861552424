import React, { useMemo } from 'react'
import { Divider, Skeleton, Alert, Tooltip } from 'antd'
import ReactPlaceholder from 'react-placeholder'

import BarChart from 'Components/Charts/NewBarChart'
import LoadingIcon from 'Components/LoadingIcon'
import kFormatter from 'Utils/kFormatter'
import PercentCustomerDoughnut from './PercentagePieChart'
import CustomerPerStoreItem from './CustomerPerStoreItem'
import { InsightsProps } from 'CustomerSegmentInsightsTypes'
import { BarChartPlaceholder } from 'Components/Placeholders/Charts'

const useInsights = (props: InsightsProps) => {
  const { isLoading, data, errors } = props

  const Reachable = useMemo(() => {
    if (errors.reachable) return <Alert type="error" message={errors.reachable} />
    return (
      <div className="insights-reachable">
        <h4 className="mb-4 font-semibold">Reachable Channels</h4>
        <div className="insights-reachable-box">
          {isLoading.is_reachable_loading ? (
            <Skeleton paragraph={{ rows: 0 }} active />
          ) : (
            data.reachable.map((channel, i) => (
              <div className="flex-1 item" key={i}>
                <span className="text-xl font-semibold block">{Math.ceil(channel.percent)}%</span>
                <span>{channel.channelName}</span>
              </div>
            ))
          )}
        </div>
      </div>
    )
  }, [isLoading.is_reachable_loading, data.reachable, errors.reachable])

  const Counts = useMemo(() => {
    const VALUE = [
      {
        label: 'Audience',
        desc: 'Number of people in this segment (Audience = Customer + Visitor)',
        value: data?.count_audience,
      },
      {
        label: 'Visitor',
        desc: 'Number of people who visit channel at least once but haven’t purchased yet',
        value: data?.count_visitor,
      },
      {
        label: 'Customer',
        desc: 'Number of people who has purchased product (Customer = Audience - Visitor)',
        value: data?.count_customer,
      },
    ]

    if (errors.count_customer) return <Alert type="error" message={errors.count_customer} />
    return (
      <div className="flex">
        {isLoading.is_count_customer_loading ? (
          <Skeleton paragraph={{ rows: 0 }} active />
        ) : (
          VALUE.map((item, i) => (
            <div
              key={i}
              className={`flex-1 item pl-2 ${i < VALUE.length - 1 && 'border-r border-divider'}`}
            >
              <Tooltip overlayClassName="ReachableChannelAVC" placement="top" title={item.desc}>
                <span className="text-secondary_text">{item.label}</span>
              </Tooltip>
              <span className="text-xl font-semibold block">{item.value.toLocaleString()}</span>
            </div>
          ))
        )}
      </div>
    )
  }, [
    isLoading.is_count_customer_loading,
    //data.count_audience,
    data.count_customer,
    //data.count_visitor,
    //errors.count_audience,
    //errors.total_audience,
    //errors.count_visitor,
    errors.count_customer,
  ])

  const StatsInsights = useMemo(() => {
    if (errors.stats) return <Alert type="error" message={errors.stats} />

    if (isLoading.is_stats_loading) return <Skeleton paragraph={{ rows: 2 }} active />

    return (
      <div>
        {data.stats.map((stat, i) => (
          <React.Fragment key={i}>
            <div className="flex justify-between">
              <h4 className="font-semibold">{stat.label}</h4>
              <span className="text-base ">
                {kFormatter(stat.value) == 'N/A' ? '0' : kFormatter(stat.value)}
              </span>
            </div>
            {i < data.stats.length - 1 && <Divider className="my-4" />}
          </React.Fragment>
        ))}
      </div>
    )
  }, [data.stats, errors.stats, isLoading.is_stats_loading])

  const TopPurchase = useMemo(() => {
    const category = data.top_purchase_category.sort((a, b) => b.value - a.value)

    const labels = category.map((item) => item.label)
    const chartData = category.map((cat, i) => {
      let percent = 100
      if (i !== 0) {
        percent = (cat.value / category[0].value) * 100
      }
      return {
        label: cat.label,
        values: { [cat.label]: percent },
      }
    })
    return (
      <div className="insight-top-purchase">
        <h4 className="font-semibold mb-4">Top purchase category</h4>
        <div style={{ width: '100%', height: 200 }}>
          <ReactPlaceholder
            className="w-full"
            showLoadingAnimation
            ready={!isLoading.is_top_purchase_category_loading}
            customPlaceholder={<BarChartPlaceholder isHorizontal />}
          >
            <BarChart
              data={chartData}
              labels={labels}
              hideLegends
              type={'horizontalBar'}
              isPercentage
              isStacked={false}
            />
          </ReactPlaceholder>
        </div>
      </div>
    )
  }, [
    data.top_purchase_category,
    isLoading.is_top_purchase_category_loading,
    errors.top_purchase_category,
  ])

  const PercentCustomer = useMemo(() => {
    if (errors.count_customer || errors.total_audience)
      return <Alert type="error" message={errors.count_customer || errors.total_audience} />
    return (
      <PercentCustomerDoughnut
        count={data.count_audience}
        total={data.total_audience}
        label="audience"
        isLoading={isLoading.is_count_customer_loading || isLoading.is_total_audience_loading}
      />
    )
  }, [
    data.count_audience,
    data.total_audience,
    isLoading.is_count_customer_loading,
    isLoading.is_total_audience_loading,
    errors.count_customer,
    errors.total_audience,
  ])

  const CustomerPerStore = useMemo(() => {
    if (errors.count_customer || errors.total_audience)
      return <Alert type="error" message={errors.count_customer || errors.total_audience} />
    return (
      <div>
        <h4 className="font-semibold mb-4">Customer No. in store</h4>
        <CustomerPerStoreItem
          isLoading={isLoading.is_count_customer_loading}
          data={data.count_customer_by_store}
          total={data.count_customer}
        />
      </div>
    )
  }, [
    isLoading.is_count_customer_loading,
    data.count_customer_by_store,
    data.count_customer,
    errors.count_customer,
    errors.total_audience,
  ])

  return {
    CustomerPerStore,
    PercentCustomer,
    Reachable,
    Counts,
    StatsInsights,
    TopPurchase,
  }
}

export default useInsights
