import axios from 'Utils/axios'
import { Criteria } from 'DimensionsFiltersTypes'
import { CancelTokenSource } from 'axios'

const CAMPAIGN_ENDPOINT = '/campaigns'

export async function getCampaignOverviewKeyMetrics({
  from_date,
  to_date,
  dimensions,
  cancelTokenSource,
}: {
  from_date?: string
  to_date?: string
  dimensions?: any
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`${CAMPAIGN_ENDPOINT}/analytics/overview/key-metrics`, {
    params: { from_date, to_date, dimensions: JSON.stringify(JSON.stringify(dimensions)) },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getCampaignOverviewCharts({
  from_date,
  to_date,
  type,
  dimensions = [],
  frequency = 'daily',
  cancelTokenSource,
}: {
  from_date?: string
  to_date?: string
  type?: string
  dimensions?: Criteria[]
  frequency?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`${CAMPAIGN_ENDPOINT}/analytics/overview/charts`, {
    params: {
      from_date,
      to_date,
      analytic_type: type,
      dimensions: JSON.stringify(JSON.stringify(dimensions)),
      frequency,
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getCampaignKeyMetrics({
  id,
  from_date,
  to_date,
  cancelTokenSource,
}: {
  id?: number
  from_date?: string
  to_date?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`${CAMPAIGN_ENDPOINT}/${id}/analytics/key-metrics`, {
    params: { from_date, to_date },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getCampaignCharts({
  id,
  from_date,
  to_date,
  type,
  dimensions = [],
  frequency = 'daily',
  cancelTokenSource,
}: {
  id?: number
  from_date?: string
  to_date?: string
  type?: string
  dimensions?: Criteria[]
  frequency?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`${CAMPAIGN_ENDPOINT}/${id}/analytics/charts`, {
    params: {
      from_date,
      to_date,
      analytic_type: type,
      dimensions: JSON.stringify(dimensions),
      frequency,
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
