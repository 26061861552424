import { useEffect } from 'react'
const useClickOutside = (ref, handler, dropdownRef) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements

      const disabledDOM = document.querySelectorAll('.disable-use-click-outside')

      if (
        !ref?.current ||
        ref?.current.contains(event.target) ||
        !dropdownRef?.current ||
        dropdownRef.current.contains(event.target) ||
        Array.prototype.slice.call(disabledDOM).filter((item) => item.contains(event.target)).length
      ) {
        return
      }

      ref.current.scrollTo(0, 0)
      return handler(false)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref])
}

export default useClickOutside
