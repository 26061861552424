import React, { useEffect } from 'react'
import { useStoreActions } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import queryString from 'query-string'
import { Tabs } from 'antd'

import Overview from './Overview'
import Listing from './Listing'

import '../index.scss'

const TABS = [
  { value: 'overview', label: 'Product Overview', component: Overview },
  { value: 'product_listing', label: 'Product Listing', component: Listing },
]

const ProductsAnalytics = () => {
  const history = useHistory()
  const queryObject = queryString.parse(history.location.search)
  const { setBreadCrumb } = useStoreActions((actions: any) => actions.breadcrumb)

  const onTabClick = (tab: string) => {
    history.push({ search: queryString.stringify({ ...queryObject, tab }) })
  }

  useEffect(() => {
    setBreadCrumb([{ title: 'Products', path: '/analytics/products' }])
  }, [setBreadCrumb])

  return (
    <div className="px-8 py-7 w-full">
      <span className="text-3xl font-semibold">Products Analytics</span>
      <div className="mt-8 w-full">
        <Tabs
          destroyInactiveTabPane
          activeKey={
            !TABS.some((tab) => tab.value === queryObject?.tab)
              ? 'overview'
              : queryObject?.tab?.toString()
          }
        >
          {TABS.map(({ component: Component, ...tab }) => (
            <Tabs.TabPane
              key={tab.value}
              tabKey={tab.value}
              tab={
                <span
                  className={clsx(
                    'font-semibold text-secondary_text',
                    tab.value === queryObject?.tab?.toString() && 'text-black'
                  )}
                  onClick={() => onTabClick(tab.value)}
                >
                  {tab.label}
                </span>
              }
            >
              <div className="my-4">
                <Component />
              </div>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  )
}

export default ProductsAnalytics
