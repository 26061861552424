import React, { useState, useEffect } from 'react'
import { useStoreActions } from 'easy-peasy'
import { useParams } from 'react-router-dom'

import { useHistory } from 'react-router-dom'

import Latency from '../Components/Latency'
import Customer360Body from './Body'

const Customer360 = () => {
  const { id } = useParams()
  const history = useHistory()

  const { setBreadCrumb } = useStoreActions((actions: any) => actions.breadcrumb)

  useEffect(() => {
    if (id) {
      setBreadCrumb([
        {
          title: 'Customers Analytics',
          path: `/analytics/customers?${history.location.search}`,
        },
        { title: 'Customer 360', path: `/analytics/customer360/${id}` },
      ])
      history.push({ search: '' })
    }
  }, [setBreadCrumb, id, history])

  if (!id) return null

  return (
    <div className="AnalyticsWrapper px-8 py-7 w-full">
      <span className="text-3xl font-semibold">Customer 360</span>
      <div className="mt-8">
        <Latency />
      </div>
      <div className="mt-4">
        <Customer360Body />
      </div>
    </div>
  )
}

export default Customer360
