import React, { useState } from 'react'
import { Typography, Select, Skeleton } from 'antd'
import KaipassPlaceholder from 'Assets/images/kaipass_placeholder.png'

import './index.scss'

const KaiPassNotiPreview = ({ initData, platforms }) => {
  const { title, image, content, btn_name } = initData

  return (
    <div className="KaipassNotiPreviewComponent">
      <div className={`KaipassNotiPreviewScreen`}>
        <div className="window-preview">
          <img alt="brand-logo" className="image" src={image || KaipassPlaceholder}></img>
          <div className="content-wrapper">
            <div className="content">
              <p className="title">{title}</p>
              <p className="description">{content}</p>
            </div>
          </div>
          <div className="action-buttons-wrapper">
            <div className="action-button">{btn_name}</div>
          </div>
        </div>
      </div>
      {/* <div className="select-preview-device">
        <Select value={device} onChange={(value) => onChangeDevice(value)}>
          {platforms.map((platform) => (
            <Option key={platform.value} value={platform.value}>
              {platform.label}
            </Option>
          ))}
        </Select>
      </div> */}
    </div>
  )
}

export default KaiPassNotiPreview
