import { action, thunk, Action, Thunk } from 'easy-peasy'

import { CustomerGroup, CampaignGroup, ChannelGroup } from 'DimensionsFiltersTypes'
import { AUDIENCE_SEGMENT_DIMENSIONS_WITH_TIME_RANGE } from 'Pages/Audiences/Detail/Components/Criteria/constants'
import { getDimensions } from 'Services/dimensions'
import {
  CAMPAIGN_GROUP,
  CHANNEL_GROUP,
} from 'Containers/DimensionFilters/DimensionGroups/constants'

export interface States {
  customerGroup: CustomerGroup[] | undefined
  channelGroup: ChannelGroup[] | undefined
  campaignGroup: CampaignGroup[] | undefined
}

export interface StoreProps extends States {
  setCustomerGroup: Action<StoreProps, CustomerGroup[]>
  setChannelGroup: Action<StoreProps, ChannelGroup[]>
  getDimensionGroups: Thunk<StoreProps, { [name: string]: { page?: string } }>
}

const initState = {
  customerGroup: [],
  channelGroup: [],
  campaignGroup: CAMPAIGN_GROUP,
}

const store: StoreProps = {
  ...initState,

  setCustomerGroup: action((state, data) => {
    state.customerGroup = data
  }),
  setChannelGroup: action((state, data) => {
    state.channelGroup = data
  }),
  getDimensionGroups: thunk(async (action, list) => {
    try {
      if (list.customer) {
        const customerResp = await getDimensions({
          group: 'customer',
          page: list?.customer?.page,
        })
        // console.log('customerResp ', customerResp)

        action.setCustomerGroup(customerResp.data)
      }

      if (list.saleChannel) {
        const saleChannelResponse = await getDimensions({
          group: 'sale_channel',
          page: list?.saleChannel?.page,
        })
        // console.log('saleChannelResponse ', saleChannelResponse)
        action.setChannelGroup(saleChannelResponse.data)
      }
      // const channelResp = await getDimensions({ group: 'channel' })
      // const campaignResp = await getDimensions({ group: 'campaign' })
    } catch (err) {
      throw err
    }
  }),
}

export default store
