import { colors } from 'DesignSystem'
import React from 'react'
import { Line } from 'react-chartjs-2'
import { buildDataset } from './chart.utils'
import Legends from '../Legends'
import { COLOR_PALLETTE } from 'Utils/constants'

export const COLORS = {
  revenue: colors.productColor.brand_primary,
  spend: colors.systemColor.blue_4,
  clicks: colors.systemColor.purple_4,
  transactions: colors.systemColor.cyan_4,
  views: colors.systemColor.orange_4,
}

const OPTIONS: any = {
  // responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      pointStyle: 'line',
    },
  },
  scales: {
    yAxes: {
      ticks: {
        count: 6,
      },
      // max: 100,
    },
    xAxes: {
      grid: {
        display: false,
      },
    },
  },
  animation: {
    duration: 750,
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
      position: 'bottom',
      align: 'start',
    },
  },
}

type AreaChartType = {
  data: {
    code: string
    labels: string[]
    data: { label: string; values: { [key: string]: number } }[]
  }
  fill?: boolean
  stack?: boolean
}

const AreaChart = ({ data, fill, stack }: AreaChartType) => {
  const datasets = buildDataset(data, fill, stack)
  const labels = data.data.map((item) => item.label)
  return (
    <div className="AreaChartComponent h-full">
      <div style={{ height: '80%' }} className="mb-2">
        <Line data={{ datasets, labels }} options={OPTIONS} />
        {/* <canvas style={{ width: '100%', height: '100%' }} ref={ref} className="chart"></canvas> */}
      </div>

      <Legends
        legends={
          data.labels?.map((legend, index) => ({
            label: legend,
            color: COLOR_PALLETTE[index],
            shape: 'rectangle',
          })) || []
        }
      ></Legends>
    </div>
  )
}

export default React.memo(AreaChart)
