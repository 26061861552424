import React, { useState } from 'react'
import { Formik } from 'formik'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import ForgotPasswordView from './ForgotPassword.view'
import { forgotPasswordSchema } from 'Validation'
import { formatErrors } from '../../Utils'
import './index.scss'

const { success } = message
const initialValues = {}

export default React.memo(function ForgotPassword(props) {
  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const forgotPasswordAction = useStoreActions((actions) => actions.auth.forgotPassword)

  const handleForgotPassword = async (values) => {
    setIsSubmitting(true)
    const captcha_token = await executeRecaptcha('login_page')

    if (!captcha_token) return setServerError('reCaptcha went wrong')

    const resp = await forgotPasswordAction({ ...values, captcha_token })

    if (resp.errors) {
      setIsSubmitting(false)
      setServerError(formatErrors(resp.errors.data))
      return
    }

    success('A reset link has been sent to your email', 10)
  }

  return (
    <div className="login-page">
      <Formik
        validationSchema={forgotPasswordSchema}
        initialValues={initialValues}
        onSubmit={handleForgotPassword}
        children={(props) => {
          return (
            <ForgotPasswordView
              serverError={serverError}
              isValidating={false}
              isSubmitting={isSubmitting}
              {...props}
            />
          )
        }}
      />
    </div>
  )
})
