import React from 'react'
import { Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import SaleChannelPivotTableTemplate from 'Pages/Analytics/SaleAnalytics/Components/PivotTable.Template'
const { TabPane } = Tabs
const PivotRow1 = () => {
  const { id } = useParams()
  return (
    <>
      <Tabs>
        <TabPane tab="Growth of Store" key="1">
          <div className="py-4 col-span-1">
            <SaleChannelPivotTableTemplate
              analytic_type={'growth_of_store'}
              isStore={false}
              location={'store360'}
              store_id={id}
            />
          </div>
        </TabPane>
        <TabPane tab="Sale of Store" key="2">
          <div className="py-4 col-span-1">
            <SaleChannelPivotTableTemplate
              analytic_type={'sale_of_store'}
              isStore={false}
              location={'store360'}
              store_id={id}
            />
          </div>
        </TabPane>
        <TabPane tab="Labor" key="3">
          <div className="py-4 col-span-1">
            <SaleChannelPivotTableTemplate
              analytic_type={'labor'}
              isStore={false}
              location={'store360'}
              store_id={id}
            />
          </div>
        </TabPane>
      </Tabs>
    </>
  )
}

export default PivotRow1
