import React from 'react'
import { UserCheck } from '@styled-icons/boxicons-regular/UserCheck'
import { User } from '@styled-icons/boxicons-regular/User'
import { Time } from '@styled-icons/boxicons-regular/Time'
import { WebAsset } from '@styled-icons/material/WebAsset'
import { Textsms } from '@styled-icons/material-outlined/Textsms'
import { CurrentLocation } from '@styled-icons/boxicons-regular/CurrentLocation'
import { EmailOutline } from '@styled-icons/evaicons-outline/EmailOutline'
import { CallSplit } from '@styled-icons/material-rounded/CallSplit'
import { Enter } from '@styled-icons/icomoon/Enter'
import { Popup } from '@styled-icons/entypo/Popup'
import { Notification } from '@styled-icons/entypo/Notification'

import cloneDeep from 'lodash/cloneDeep'

const CJ_NODE_COLOR_PALETTE = {
  default: '#e63946',
  conditions: '#00afb9',
  channels: '#f9c74f',
}

export const CJ_LINE_WIDTH = 60
export const CJ_NODE_WIDTH = 200
export const CJ_NODE_HEIGHT = 100
export const CJ_ROUND_NODE_HEIGHT = 41
export const CJ_NODE_MARGIN = 65
export const VIRTUAL_NODE = {
  nodeType: 'virtual',
  index: 0,
  name: 'Virtual',
  icon: <Enter />,
  maxChildren: 1,
  childNodes: [],
  node_attribute: true,
}

export const POPUP = {
  spingame: {
    nodeType: 'spingame',
    name: 'Spin Game',
    parentType: 'popup',
    description: 'Popup window to customers',
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    value: {},
  },
  randomvoucher: {
    nodeType: 'randomvoucher',
    name: 'Random Voucher',
    parentType: 'popup',
    description: 'Popup window to customers',
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    value: {},
  },
  codedefault: {
    nodeType: 'codedefault',
    name: 'Code Default',
    parentType: 'popup',
    description: 'Popup window to customers',
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    value: {},
  },
}

export const POPUPINIT = [
  {
    ...POPUP['spingame'],
    data: 'spingame',
  },
  {
    ...POPUP['randomvoucher'],
    data: 'randomvoucher',
  },
  {
    ...POPUP['codedefault'],
    data: 'codedefault',
  },
]

export const NODE_TYPES = {
  entrance: {
    nodeType: 'entrance',
    name: 'Entrance',
    icon: <Enter />,
    maxChildren: 1,
    node_attribute: true,
    childNodes: [{ ...VIRTUAL_NODE }],
    bgColor: CJ_NODE_COLOR_PALETTE.default,
    description: 'Destination to enable customer journey tree',
    // parentType: 'condition',
    value: {},
  },
  entrance_from_app: {
    nodeType: 'entrance_from_app',
    name: 'Entrance',
    icon: <Enter />,
    maxChildren: 1,
    node_attribute: true,
    childNodes: [{ ...VIRTUAL_NODE }],
    bgColor: CJ_NODE_COLOR_PALETTE.default,
    description: 'Destination to enable customer journey tree',
    // parentType: 'condition',
    value: {},
  },
  entrance_from_web: {
    nodeType: 'entrance_from_web',
    name: 'Entrance',
    icon: <Enter />,
    maxChildren: 1,
    node_attribute: true,
    childNodes: [{ ...VIRTUAL_NODE }],
    bgColor: CJ_NODE_COLOR_PALETTE.default,
    description: 'Destination to enable customer journey tree',
    // parentType: 'condition',
    value: {},
  },
  virtual: VIRTUAL_NODE,
  wait: {
    nodeType: 'wait',
    name: 'Wait',
    icon: <Time />,
    maxChildren: 1,
    node_attribute: true,
    childNodes: [cloneDeep(VIRTUAL_NODE)],
    description: 'Wait for a certain time to execute the next step.',
    value: {},
  },
  split: {
    nodeType: 'split',
    name: 'A/B Testing',
    icon: <CallSplit transform={`rotate(-180)`} />,
    maxChildren: 2,
    node_attribute: true,
    description: 'Split into two different directions A and B.',
    childNodes: [
      { ...VIRTUAL_NODE, node_attribute: false },
      { ...VIRTUAL_NODE, index: 1, node_attribute: true },
    ],
    value: {},
  },
  check_reachability: {
    nodeType: 'check_reachability',
    name: 'Check Reachability',
    icon: <CurrentLocation />,
    maxChildren: 2,
    parentType: 'condition',
    bgColor: CJ_NODE_COLOR_PALETTE.conditions,
    description: 'Check if customers are reachable on a specific channel',
    node_attribute: true,
    childNodes: [
      { ...VIRTUAL_NODE, node_attribute: false },
      { ...VIRTUAL_NODE, index: 1, node_attribute: true },
    ],
    value: {},
  },
  check_user_attr: {
    nodeType: 'check_user_attr',
    name: 'Check attribute',
    icon: <UserCheck />,
    maxChildren: 2,
    parentType: 'condition',
    node_attribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.conditions,
    childNodes: [
      { ...VIRTUAL_NODE, node_attribute: false },
      { ...VIRTUAL_NODE, index: 1, node_attribute: true },
    ],
    description: 'Check if customers meet certain conditions',
    value: {},
  },
  check_audience_segment: {
    nodeType: 'check_audience_segment',
    name: 'Select audience',
    icon: <User />,
    maxChildren: 1,
    parentType: 'condition',
    node_attribute: true,
    childNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.conditions,
    description: 'Target to a specific audience segment or all segments',
    value: {},
  },
  web_push: {
    nodeType: 'web_push',
    name: 'Web push',
    icon: <WebAsset />,
    maxChildren: 1,
    parentType: 'channel',
    node_attribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    description: 'Send web push notification to customers',
    childNodes: [cloneDeep(VIRTUAL_NODE)],
    value: {},
  },
  harasocial: {
    nodeType: 'harasocial',
    name: 'Harasocial',
    icon: <WebAsset />,
    maxChildren: 1,
    parentType: 'channel',
    node_attribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    description: 'Send message on messenger',
    childNodes: [cloneDeep(VIRTUAL_NODE)],
    value: {},
  },
  hrs_store_farming: {
    nodeType: 'hrs_store_farming',
    name: 'Harasocial - Store farming',
    icon: <WebAsset />,
    maxChildren: 1,
    parentType: 'channel',
    node_attribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    description: 'Assign task to employee',
    childNodes: [cloneDeep(VIRTUAL_NODE)],
    value: {},
  },
  kaipass: {
    nodeType: 'kaipass',
    name: 'Kaipass Notification',
    icon: <WebAsset />,
    maxChildren: 1,
    parentType: 'channel',
    node_attribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    description: 'Send Kaipass notification to customers',
    childNodes: [cloneDeep(VIRTUAL_NODE)],
    value: {},
  },
  sms: {
    nodeType: 'sms',
    name: 'SMS',
    icon: <Textsms />,
    maxChildren: 1,
    parentType: 'channel',
    node_attribute: true,
    childNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    description: 'Send SMS notification to customers',
    value: {},
  },
  email: {
    nodeType: 'email',
    name: 'E-mail',
    icon: <EmailOutline />,
    maxChildren: 1,
    parentType: 'channel',
    node_attribute: true,
    description: 'Send e-mail to customers',
    childNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    value: {},
  },
  popup: {
    key: 'popup',
    name: 'Popup',
    icon: <Popup />,
    data: [...POPUPINIT],
    // nodeType: 'popup',
    // name: 'Popup',
    // icon: <Popup />,
    // maxChildren: 1,
    // parentType: 'channel',
    // node_attribute: true,
    // description: 'Popup window to customers',
    // childNodes: [
    //   { ...POPUP['spingame'], data: 'spin_game' },
    //   { ...POPUP['randomvoucher'], data: 'random_voucher' },
    //   { ...POPUP['codedefault'], data: 'codede_fault' },
    // ],
    // bgColor: CJ_NODE_COLOR_PALETTE.channels,
    // value: {},
  },
  tch_app: {
    nodeType: 'tch_app',
    name: 'App Push',
    icon: <Notification />,
    maxChildren: 1,
    parentType: 'channel',
    node_attribute: true,
    description: 'Popup window to customers',
    childNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    value: {},
  },
}

export const DEFAULT_NODE = {
  ...cloneDeep(NODE_TYPES.entrance),
  childNodes: [
    {
      ...cloneDeep(NODE_TYPES.check_audience_segment),
      // childNodes: [
      //   // cloneDeep(NODE_TYPES.virtual),
      //   //   { ...cloneDeep(NODE_TYPES.check_reachability), childNodes: [cloneDeep(NODE_TYPES.wait)] },
      //   //   cloneDeep(NODE_TYPES.virtual),
      // ],
    },
  ],
}

export const CHANNELS = [
  { ...NODE_TYPES['web_push'], data: 'web_push' },
  // { ...NODE_TYPES['sms'], data: 'sms' },
  // { ...NODE_TYPES['email'], data: 'email' },
  { ...NODE_TYPES['popup'], data: 'popup' },
  // { ...NODE_TYPES['kaipass'], data: 'kaipass' },
  // { ...NODE_TYPES['harasocial'], data: 'harasocial' },
  // { ...NODE_TYPES['hrs_store_farming'], data: 'hrs_store_farming' },
  { ...NODE_TYPES['tch_app'], data: 'tch_app' },
]

export const CONDITIONS = [
  {
    ...NODE_TYPES['check_reachability'],
    data: 'check_reachability',
  },
  {
    ...NODE_TYPES['check_user_attr'],
    data: 'check_user_attr',
  },
]

export const INIT = [
  {
    key: 'channels',
    name: 'Channels',
    icon: CHANNELS[0].icon,
    data: CHANNELS,
  },
  {
    key: 'conditions',
    name: 'Conditions',
    icon: CONDITIONS[0].icon,
    data: CONDITIONS,
  },
  {
    ...NODE_TYPES['wait'],
    data: 'wait',
  },
  {
    ...NODE_TYPES['split'],
    data: 'split',
  },
]
