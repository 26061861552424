import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { States as CampaignStates } from 'Models/campaign'

import AudienceSelect from 'Components/AudienceSelect'

export default function Audience() {
  const campaignAudienceId = useStoreState(
    (state: { campaignModule: CampaignStates }) => state.campaignModule.audience_id
  )
  const setCampaignState = useStoreActions((action: any) => action.campaignModule.setState)

  return (
    <div className="AudienceStep">
      <AudienceSelect
        onSelectAudienceId={(id) => setCampaignState({ key: 'audience_id', value: id })}
        selectedAudienceId={campaignAudienceId || NaN}
      />
    </div>
  )
}
