import React from 'react'
import PreviewScreen from 'Components/PreviewChannels/Preview'

export default function Review() {
  return (
    <div className="ScheduleStep flex gap-2">
      <div className="w-1/2">
        <div className="section-wrapper">
          <span className="font-semibold text-lg">Audience</span>
        </div>
        <div className="section-wrapper">
          <span className="font-semibold text-lg">Schedule</span>
        </div>
        <div className="section-wrapper">
          <span className="font-semibold text-lg">Estimated Result</span>
        </div>
      </div>
      <div className="w-1/2 bg-gray_1 p-4">
        <PreviewScreen />
      </div>
    </div>
  )
}
