import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import './index.scss'
import { MetricTypeProps } from 'MetricCardModule'
import { formatNum } from 'Utils/kFormatter'
import { capitalizeFirstLetter } from 'Utils'
import { truncate } from 'Utils'

const MetricCard: React.FC<MetricTypeProps> = ({
  mode,
  unit,
  value,
  title,
  style,
  unitDisplay,
  subvalue,
  children,
  info,
  className,
}) => {
  const renderSubValue = () => {
    if (!subvalue) return
    return <span className="text-disabled text-sm">({subvalue})</span>
  }
  const renderUnitDisplay = () => {
    if (!unitDisplay || unit === 'percent') return
    return <span className="text-disabled text-sm">{unitDisplay}</span>
  }

  const renderValue = () => {
    if (!value) return 'N/A'
    if (new RegExp(/[a-z$&+,:;=?@#|'<>^*()%!-]/g).test(value.toString())) {
      return value
    }

    let suffix = ''
    if (unit === 'percent') suffix = '%'
    if (unit === 'usd_currency') suffix = 'USD'
    if (unit === 'vnd_currency') suffix = 'VNĐ'
    return `${formatNum(value.toString())} ${suffix}`
  }

  return (
    <div className={`metric-card ${className} --${mode}`} style={style}>
      <div className="title-wrapper">
        <span title={capitalizeFirstLetter(title)} className="title">
          {/* {truncate(capitalizeFirstLetter(title), 20)} */}
          {capitalizeFirstLetter(title)}
        </span>
        {mode !== 'small' && info && (
          <div className="icon ml-2">
            <InfoCircleOutlined />
          </div>
        )}
      </div>
      <span className="value">{renderValue()}</span>

      {children}
      {renderUnitDisplay()}
      {renderSubValue()}
    </div>
  )
}

MetricCard.defaultProps = {
  className: '',
  mode: 'medium',
  style: {},
  unit: 'percentage',
}

export default MetricCard
