import React from 'react'
import './index.scss'
const Policies = () => {
  return (
    <div id="PoliciesPage">
      <h1>CHÍNH SÁCH BẢO MẬT</h1>
      <div>
        <h2>1. Mục đích và phạm vi thu thập thông tin</h2>
        <div>
          <p>
            - Seedcom, trong khả năng của mình, sẽ nỗ lực tối đa để đảm bảo mật dữ liệu của Quý
            khách đưa lên website của các công ty con của Seedcom trong suốt thời gian sử dụng dịch
            vụ hợp lệ của Quý khách.
          </p>
          <p>
            - Seedcom không bán, chia sẻ hay trao đổi thông tin cá nhân của Quý khách thu thập trên
            website của các công ty con cho một bên thứ ba nào khác ngoài các bên nêu tại mục 2. Bản
            Điều khoản và Điều kiện này.
          </p>
          <p>
            - Những thông tin mà Seedcom có được liên quan đến Quý khách có thể bao gồm thông tin
            Quý khách đã cung cấp cho các công ty con ở bất kỳ giai đoạn nào trong suốt quá trình
            giao dịch với các công ty con (họ tên, địa chỉ thư điện tử (email), số điện thoại, địa
            chỉ, các giao dịch mua hàng trên các kênh bán hàng của công ty con, v.v.), thông tin
            được gửi bởi hoặc liên quan đến (các) thiết bị được sử dụng để truy cập vào website của
            các công ty con, thông tin từ dữ liệu tổng hợp về người dùng trên internet, thông tin có
            được từ những nguồn hợp pháp khác (“Thông Tin Khách Hàng”).
          </p>
          <p>
            - Quý khách cam kết bảo mật (không tiết lộ) thông tin liên quan đến tài khoản, ứng dụng,
            dịch vụ, các giao dịch giữa Quý khách và các công ty con, các thông tin mà các công ty
            con gửi cho Quý khách thông qua tài khoản hay bất kỳ phương tiện nào khác. Trong trường
            hợp Quý khách phát hiện hoặc nghi ngờ thông tin tài khoản bị mất cắp, bị lộ thông tin,
            Quý khách cần liên hệ ngay với công ty con để được hỗ trợ kịp thời và khắc phục các hậu
            quả có liên quan.
          </p>
        </div>
      </div>
      <div>
        <h2>2. Phạm vi sử dụng thông tin</h2>
        <h3>2.1.1 Đối với khách hàng các công ty con</h3>
        <p>
          Thông tin cá nhân thu thập được chỉ được Công ty sử dụng trong nội bộ và cho một hoặc tất
          cả các Mục Tiêu sau đây:
        </p>
        <ul>
          <li>Hỗ trợ Quý khách;</li>
          <li>Cung cấp thông tin liên quan đến sản phẩm, dịch vụ;</li>
          <li>
            Cung cấp sản phẩm, dịch vụ và thông tin qua trang website công ty con tăng trải nghiệm
            của khách hàng trên website các công ty con và cung cấp đến khách hàng những ưu đãi dành
            riêng cho khách hàng.
          </li>
          <li>
            Seedcom có thể sẽ gửi thông tin sản phẩm, dịch vụ mới, thông tin về các sự kiện sắp tới
            hoặc thông tin tuyển dụng nếu Quý khách đăng ký nhận email/sms thông báo qua website
            công ty con;
          </li>
          <li>Đánh giá và nâng cao chất lượng dịch vụ;</li>
        </ul>

        <h3>2.1.2 Đơn vị hỗ trợ dịch vụ</h3>
        <p>
          Trong quá trình thực hiện cung cấp dịch vụ cho khách hàng, Seedcom sẽ/có thể cần phải tiết
          lộ dữ liệu cá nhân của Quý khách cho các nhà cung cấp dịch vụ bên thứ ba như dịch vụ SMS,
          Email vì một hay nhiều Mục Tiêu nói trên. Các bên thứ ba như thế bao gồm, nhưng không giới
          hạn tại:
        </p>
        <ul>
          <li>Công ty con mà khách hàng sử dụng dịch vụ để tăng năng lực vận hành dịch vụ.</li>
          <li>
            Nhà thầu, đại lý, nhà cung cấp dịch vụ và các bên thứ ba khác mà Công ty thuê để hỗ trợ
            hoặc bổ sung cho hoạt động kinh doanh.
          </li>
          <li>
            Bên thứ ba mà Công ty tiết lộ thông tin vì một trong các Mục Tiêu và các bên thứ ba đó
            ngược lại họ sẽ thu thập và xử lý dữ liệu cá nhân của Quý khách vì một hoặc nhiều Mục
            Tiêu.
          </li>
        </ul>

        <h3>2.1.2 Đơn vị hỗ trợ dịch vụ</h3>
        <p>
          Trong trường hợp có yêu cầu bằng văn bản cho bên mua và/hoặc bên bán từ cơ quan có thẩm
          quyền như cơ quan tư pháp bao gồm: Viện kiểm sát, tòa án, cơ quan công an điều tra liên
          quan đến hành vi vi phạm pháp luật hoặc gian lận tài khoản, giao dịch nào đó của khách
          hàng, Seedcom có trách nhiệm hợp tác cung cấp thông tin cá nhân Quý khách.
        </p>
        <h3>2.1.3 Cơ quan thẩm quyền</h3>
        <p>
          Trong trường hợp có yêu cầu bằng văn bản cho bên mua và/hoặc bên bán từ cơ quan có thẩm
          quyền như cơ quan tư pháp bao gồm: Viện kiểm sát, tòa án, cơ quan công an điều tra liên
          quan đến hành vi vi phạm pháp luật hoặc gian lận tài khoản, giao dịch nào đó của khách
          hàng, Seedcom có trách nhiệm hợp tác cung cấp thông tin cá nhân Quý khách.
        </p>
        <h2>3. Thời gian lưu trữ thông tin</h2>
        <div>
          <p>
            - Đối với thông tin cá nhân, Seedcom chỉ xóa đi dữ liệu này nếu Quý khách có yêu cầu
            thông qua các công ty con.
          </p>
          <p>
            - Ngoài trường hợp trên, Thông tin Quý khách sẽ được bảo mật trên nền tảng dữ liệu của
            Seedcom và các công ty con.
          </p>
        </div>
        <h2>4. Những người hoặc tổ chức có thể được tiếp cận với thông tin cá nhân</h2>
        <p>
          Đối tượng được tiếp cận với thông tin cá nhân của Quý khách thuộc một trong những trường
          hợp nêu tại Mục 2. Bản Điều khoản và Điều kiện này.
        </p>
        <h2>
          5. Cơ chế tiếp nhận và giải quyết khiếu nại của người tiêu dùng liên quan đến việc thông
          tin cá nhân bị sử dụng sai mục đích hoặc phạm vi đã thông báo
        </h2>
        <p>
          Quý khách được đảm bảo rằng thông tin cung cấp cho Công ty sẽ được bảo mật, Seedcom cam
          kết không chia sẻ, bán hoặc cho thuê thông tin cá nhân của Quý khách cho bất kỳ người hoặc
          mục đích nào khác nêu tại Mục 2. Bản Điều khoản và Điều kiện này. Seedcom cam kết chỉ sử
          dụng các thông tin của Quý khách vào các trường hợp sau:
        </p>
        <ul>
          <li>Cho các Mục Tiêu quy định tại Mục 2.1.1 của Bản Điều khoản và Điều kiện này;</li>
          <li>Giải quyết các tranh chấp, khiếu nại;</li>
          <li>Khi cơ quan pháp luật có yêu cầu.</li>
        </ul>
        <p>
          Trong bất kỳ trường hợp có thắc mắc, góp ý nào liên quan đến chính sách bảo mật của
          Seedcom, và liên quan đến việc thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi đã
          thông báo vui lòng liên hệ qua email scdp@seedcom.vn.
        </p>
      </div>
    </div>
  )
}

export default Policies
