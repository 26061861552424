import { action, thunk } from 'easy-peasy'
import axios from 'Utils/axios'
import _ from 'lodash'

const initState = {
  genders: [],
  ages: [20, 30],
  interests: [],
  interestIds: [],
  cities: [],
  cityIds: [],
  name: '',
  description: '',
}

export default {
  ...initState,
  setCities: action((state, cities) => {
    state.cities = _.uniqBy(cities, 'value')
    state.cityIds = _.uniq(cities.map((city) => city.value))
  }),
  setInterests: action((state, interests) => {
    state.interests = interests
    state.interestIds = interests.map((interest) => interest.value)
  }),
  // setGenders: action((state, genders) => {
  //   state.genders = genders
  // }),
  // setAge: action((state, age) => {
  //   state.age_min = age[0]
  //   state.age_max = age[1]
  // }),
  resetState: action((state) => ({ ...initState })),
  setFBAudienceStates: action((state, { key, value }) => {
    state[key] = value
  }),
  fetchFBAudience: thunk(async (actions) => {
    const resp = await axios.get(
      `/audience-dimensions/?as_type=facebook&company=${process.env.REACT_APP_COMPANY_NAME}`
    )
    if (resp.status === 200) {
    }
  }),
  searchCities: thunk(async (actions, keyword, { getState }) => {
    const state = getState()
    const resp = await axios.get(`/audience-dimensions/search`, {
      params: {
        as_type: 'facebook',
        query:
          'location_types=[%22city%22]&country_code=VN&type=adgeolocation&fields=key,name&limit=100',
        keyword,
      },
    })

    if (resp.status == 200) {
      return { data: resp.data }
    }
    return { errors: resp.response }
  }),
  searchInterests: thunk(async (actions, keyword, { getState }) => {
    const state = getState()
    const resp = await axios.get(`/audience-dimensions/search`, {
      params: {
        as_type: 'facebook',
        query: 'type=adinterest&fields=id,name,audience_size&limit=100',
        keyword: keyword ? keyword : ' ',
      },
    })

    if (resp.status == 200) {
      return { data: resp.data }
    }
    return { errors: resp.response }
  }),
  createFBAudience: thunk(async (actions, _, { getState, getStoreState, getStoreActions }) => {
    const state = getState()
    const storeState = getStoreState()
    const storeActions = getStoreActions()
    const resp = await axios.post('/audience-segments/', {
      name: storeState.campaign.audience.name,
      description: storeState.campaign.audience.description,
      store_ids: JSON.stringify(storeState.campaign.audience.stores),
      dimensions: JSON.stringify({
        internal_data: {
          cities: state.cities,
          interests: state.interests,
        },
        geo_locations: {
          cities: state.cityIds.map((id) => ({
            key: id,
            radius: '40',
            distance_unit: 'kilometer',
          })),
        },
        genders: state.genders,
        age_min: state.ages[0],
        age_max: state.ages[1],
        interests: state.interestIds.map((id) => ({ id })),
      }),
      audience_type: storeState.campaign.audience.audienceType,
    })
    if (resp.status === 200) {
      await actions.resetState()
      await storeActions.campaign.audience.setAudienceStates({
        key: 'selectedAudienceId',
        value: resp.data.id,
      })
      return { ok: true }
    }
    return { errors: resp.response.data }
  }),
  updateFBAudience: thunk(async (actions, _, { getState, getStoreState, getStoreActions }) => {
    const state = getState()
    const storeState = getStoreState()
    const storeActions = getStoreActions()
    const resp = await axios.put(
      `/audience-segments/${storeState.campaign.audience.selectedAudienceId}`,
      {
        name: storeState.campaign.audience.name,
        description: storeState.campaign.audience.description,
        store_ids: JSON.stringify(storeState.campaign.audience.stores),
        dimensions: JSON.stringify({
          internal_data: {
            cities: state.cities,
            interests: state.interests,
          },
          geo_locations: {
            cities: state.cityIds.map((id) => ({
              key: id,
              radius: '40',
              distance_unit: 'kilometer',
            })),
          },
          genders: state.genders,
          age_min: state.ages[0],
          age_max: state.ages[1],
          interests: state.interestIds.map((id) => ({ id })),
        }),
        audience_type: storeState.campaign.audience.audienceType,
      }
    )
    if (resp.status === 200) {
      return { ok: true }
    }
    return { errors: resp.response.data }
  }),
}
