import React from 'react'
import { colors } from 'DesignSystem'
import { capitalize } from 'lodash'

export const ACTIVITIES_CHANNELS = {
  pos: {
    label: 'POS',
    color: colors.systemColor.purple_6,
    background: colors.systemColor.purple_1,
  },
  web: {
    label: 'Web',
    color: colors.systemColor.greekBlue_6,
    background: colors.systemColor.greekBlue_1,
  },
  app: {
    label: 'App',
    color: colors.systemColor.gold_6,
    background: colors.systemColor.gold_1,
  },
  facebook: {
    label: 'Facebook',
    color: colors.systemColor.blue_6,
    background: colors.systemColor.blue_1,
  },
}
const ChannelBadge = ({ code, className }: { code: string; className?: string }) => {
  return (
    <span
      style={{
        background: ACTIVITIES_CHANNELS[code]?.background || '#0000',
        color: ACTIVITIES_CHANNELS[code]?.color || '#FFFF',
      }}
      className={`py-1 px-2 font-semibold rounded-sm ${className}`}
    >
      {ACTIVITIES_CHANNELS[code]?.label || capitalize(code) || 'N/A'}
    </span>
  )
}

export default ChannelBadge
