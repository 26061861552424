const level = {
  lv_0: '0px',
  lv_1: '4px',
  lv_2: '8px',
  lv_3: '12px',
  lv_4: '16px',
  lv_5: '20px',
  lv_6: '24px',
  lv_7: '28px',
  lv_8: '32px',
  lv_9: '36px',
  lv_10: '40px',
  lv_11: '48px',
  lv_12: '56px',
  lv_13: '64px',
  lv_14: '80px',
  lv_15: '96px',
}

module.exports = level
