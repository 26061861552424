import React, { useState, useEffect } from 'react'
import { AutoComplete } from 'antd'

import { searchProducts } from 'Services/products'
import { useDebounce } from 'Hooks'
import ProductItem, { ProductType } from './ProductItem'
import LoadingIcon from 'Components/LoadingIcon'

const Product = ({ onAddProduct, onRemoveProduct, value }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchedProducts, setSearchedProducts] = useState([])
  const [isSearching, setIsSearching] = useState(false)

  const debounceSearch = useDebounce(searchTerm, 1000)

  useEffect(() => {
    if (!debounceSearch) return
    const handleSearchProducts = async () => {
      setIsSearching(true)
      try {
        const products = await searchProducts(debounceSearch)
        setSearchedProducts(products.data)
      } catch (err: any) {}
      setIsSearching(false)
    }

    handleSearchProducts()
  }, [debounceSearch])

  return (
    <div className="sf-product-field">
      <AutoComplete placeholder="Search product" onSearch={setSearchTerm}>
        {searchedProducts.length &&
          searchedProducts.map((product: ProductType, index) => {
            return (
              <AutoComplete.Option key={index} value={product.Title}>
                <ProductItem
                  key={product.ProductId}
                  onClick={(product) => {
                    onAddProduct(product)
                  }}
                  product={product}
                />
              </AutoComplete.Option>
            )
          })}
      </AutoComplete>
      {isSearching && (
        <div className="loading-icon">
          <LoadingIcon />
        </div>
      )}
      <div className="product-wrapper">
        {value.map((product, index) => (
          <div>
            <p className="text-secondary_text font-semibold mt-4 mb-1">Product {index + 1}</p>

            <ProductItem
              key={product.ProductId}
              onClick={() => {}}
              onDelete={() => onRemoveProduct(product)}
              product={product}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Product
