import React from 'react'
import { Divider } from 'antd'

import useCharts from './useCharts'
import useMetrics from './useMetrics'
import { InsightsProps } from 'CustomerSegmentInsightsTypes'

const InsightsView: React.FC<InsightsProps> = ({ isLoading, data, errors, onBarClick }) => {
  const {
    Revenue,
    AOV,
    ItemPerCustomer,
    BillPerCustomer,
    NewReturn,
    CLC,
    LTV,
    Cluster,
    SaleChannel,
    TopPurchase,
  } = useCharts({
    errors,
    data,
    isLoading,
    onBarClick,
  })
  const { PercentCustomer, Reachable, Counts, CustomerPerStore, StatsInsights } = useMetrics({
    errors,
    data,
    isLoading,
    onBarClick,
  })

  return (
    <div id="Insights">
      <div className="p-4 flex">
        <div className="w-1/3 mr-6">{PercentCustomer}</div>{' '}
        <div className="flex-1">
          <div>{Reachable}</div>
          <div className="mt-4">{Counts}</div>
        </div>
      </div>

      <Divider className="my-2" />

      <div className="p-4">
        <h3 className="mb-6 text-lg">Customer Insights</h3>

        {StatsInsights}
        <Divider className="my-4" />

        {CustomerPerStore}
        <Divider className="my-4" />

        <div style={{ minHeight: 100 }} className="grid grid-cols-2 gap-4">
          {Revenue}
          {AOV}
        </div>

        <Divider className="my-4" />

        <div style={{ minHeight: 100 }} className="grid grid-cols-2 gap-4">
          {ItemPerCustomer}
          {BillPerCustomer}
        </div>
        <Divider className="my-4" />

        <div style={{ minHeight: 100 }} className="grid grid-cols-2 gap-4">
          {NewReturn}
          {CLC}
        </div>
        <Divider className="my-4" />

        <div style={{ minHeight: 100 }} className="grid grid-cols-2 gap-4">
          {LTV}
          {Cluster}
        </div>
        <Divider className="my-4" />

        <div style={{ minHeight: 100 }} className="grid grid-cols-2 gap-4">
          {SaleChannel}
        </div>
        <Divider className="my-4" />

        {TopPurchase}
      </div>
    </div>
  )
}

export default InsightsView
