import linkClicks from './LinkClicks.schema'
import postEngagement from './PostEngagement.schema'
import HarasocialStoreFarming from './HarasocialStoreFarming.schema'
import kaipass from './Kaipass.schema'
import harasocial from './Harasocial.schema'
import webPush from './Webpush.schema'
import SMS from './SMS.schema'
import AppPush from './AppPush.schema'

const ChannelSchemas = {
  hrs_store_farming: HarasocialStoreFarming,
  link_clicks: linkClicks,
  post_engagement: postEngagement,
  kaipass: kaipass,
  harasocial: harasocial,
  web_push: webPush,
  sms: SMS,
  tch_app: AppPush,
}

export default ChannelSchemas
