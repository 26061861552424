import React, { useEffect, useState } from 'react'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { Tabs, Button, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

import PersonalPerformance from './PersonalPerformance'
import Dashboard from './Dashboard/index'
import CloseIcon from 'Assets/images/NewIcons/close.png'
import ActionButton from 'Components/ActionButton'
import Table from 'Components/Table2.0'
import { IS_ACTIVE, COLUMNS } from './constants'

import NewLink from './Components/NewLinkPane'
import EditLinkPane from './Components/EditLinkPane'

import './index.scss'
import CustomTabs from 'Components/CustomTabs'

const queryString = require('query-string')
const Title = Typography.Title

const SocialSelling = () => {
  const history = useHistory()
  const { outer_tab, show_pane } = queryString.parse(history.location.search)
  const queryObject = queryString.parse(history.location.search)
  const [searchTerm, setSearchTerm] = useState('')
  const [link, setLink] = useState({})

  const [filters, setFilters] = useState<any>({ order_by: '-updated_at' })

  const onCreateLink = () => {
    const params = queryString.stringify({ ...queryObject, show_pane: 'new' })
    history.push({ search: params })
  }

  const onClosePanel = () => {
    const params = queryString.stringify({ ...queryObject, show_pane: undefined })
    history.push({ search: params })
  }

  const onCreateLinkSuccess = (link) => {
    setLink(link)
    const params = queryString.stringify({ ...queryObject, show_pane: 'edit', link_id: link.id })
    history.push({ search: params })
  }

  const handleTableChange = (tablePagination, _, sorter) => {
    setFilters({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.order
        ? sorter.order === 'ascend'
          ? sorter.field
          : `-${sorter.field}`
        : null,
    })
  }

  return (
    <>
      <div className="w-full relative px-8">
        <Title className="page-title font-semibold text-3xl my-7 text-primary_text" level={3}>
          Social selling
        </Title>
        <div className="absolute right-6 top-6">
          <Button
            className={'create-button'}
            type={'primary'}
            icon={<PlusOutlined className="create-icon" />}
            onClick={onCreateLink}
          >
            Create Link
          </Button>
        </div>
        <CustomTabs className="mb-4" queryKey="outer_tab" type="ss"></CustomTabs>
        {/* outer_tab content */}
        {outer_tab === 'links' && (
          <Table
            dropdown={{
              fields: [
                {
                  label: 'Phần loại nội dung',
                  value: 'category',
                  options: IS_ACTIVE,
                  type: 'DROPDOWN',
                  onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
                },
              ],
              value: filters,
            }}
            search={{ keyword: searchTerm, onSearch: setSearchTerm }}
            handleTableChange={handleTableChange}
            fetchFrom={{ url: '/social-selling/', key: 'results' }}
            columns={COLUMNS}
            rowActions={{
              actions: [
                {
                  component: (record) => (
                    <ActionButton
                      copyText={(record as any).shorten_link}
                      type="copy"
                    ></ActionButton>
                  ),
                },
                { component: (record) => <ActionButton type="statistic"></ActionButton> },
                { component: (record) => <ActionButton type="option"></ActionButton> },
              ],
            }}
          />
        )}
        {outer_tab === 'performance' && <PersonalPerformance />}
        {outer_tab === 'dashboard' && <Dashboard />}
      </div>
      <div className={`side-panel ${show_pane ? '__show' : '__hide'}`}>
        <div onClick={onClosePanel} className="__close-btn">
          <img src={CloseIcon}></img>
        </div>
        {show_pane === 'new' && (
          <NewLink onCreateSuccess={onCreateLinkSuccess} onClosePanel={onClosePanel} />
        )}
        {show_pane === 'edit' && <EditLinkPane link={link as any} onClosePanel={onClosePanel} />}
      </div>
    </>
  )
}

export default SocialSelling
