import React, { useState, useEffect } from 'react'
import { Tabs, DatePicker } from 'antd'
import { useHistory } from 'react-router-dom'

import { DASHBOARD_TABS } from './constants'
import Overview from '../Components/Overview'
import Detail from '../Components/Detail'
import moment from 'moment'

import './index.scss'

const { TabPane } = Tabs
const { RangePicker } = DatePicker
const queryString = require('query-string')

const Dashboard = () => {
  const history = useHistory()
  const [dateFilter, setDateFilter] = useState([moment().subtract(1, 'months'), moment()])
  const { type } = queryString.parse(history.location.search)

  const queryObject = queryString.parse(history.location.search)

  const onChangeTab = (tab) => {
    const params = queryString.stringify({ ...queryObject, type: tab })
    history.push({ search: params })
  }

  useEffect(() => {
    if (!type) {
      const params = queryString.stringify({ ...queryObject, type: 'general' })
      history.push({ search: params })
    }
  }, [history, type, queryObject])

  return (
    <div className="Dashboard">
      <div className="bg-gray_1 relative flex items-center w-full header">
        <Tabs className="ant-tabs--white-mode" activeKey={type} onChange={onChangeTab}>
          {DASHBOARD_TABS?.map((option) => (
            <TabPane
              key={option.value}
              tab={<span className="text-base font-semibold">{option.label}</span>}
            ></TabPane>
          ))}
        </Tabs>
        <div className="absolute right-3">
          <RangePicker
            defaultValue={[moment(dateFilter[0]), moment(dateFilter[1])]}
            onChange={(date) => {
              // @ts-ignore: Object is possibly 'null'.
              setDateFilter(date)
            }}
          />
        </div>
      </div>
      {type === 'general' && (
        <Overview fromDate={dateFilter[0]} toDate={dateFilter[1]} type="dashboard" />
      )}
      {type === 'detail' && <Detail fromDate={dateFilter[0]} toDate={dateFilter[1]} />}
    </div>
  )
}

export default Dashboard
