import React from 'react'
import { Select, Input, Button } from 'antd'
import { Formik, Form } from 'formik'
const { Option } = Select

interface Props {
  onAddNewNode: any
  value: {
    wait_time: number
    time_format: 'min' | 'hour' | 'day' | 'month'
  }
  onUpdateNode: any
}

const Wait: React.FC<Props> = (props) => {
  const { onAddNewNode, value, onUpdateNode } = props
  const defaultValue = { wait_time: 5, time_format: 'min' }

  const onSubmit = (values: any) => {
    onAddNewNode && onAddNewNode('wait', values)
    onUpdateNode && onUpdateNode(values)
  }

  return (
    <div className="WaitComponent">
      <Formik
        initialValues={value ? value : defaultValue}
        onSubmit={(values) => onSubmit(values)}
        children={({ handleChange, setFieldValue, values }) => (
          <Form>
            <div className="form-item time-setter">
              <Input
                className="mr-2 w-1/2"
                onChange={handleChange}
                name="wait_time"
                value={values.wait_time}
              />
              <Select
                onChange={(value) => setFieldValue('time_format', value)}
                // name="time_format"
                className="time-setter-format w-1/2"
                value={values.time_format}
              >
                <Option value="min">mins</Option>
                <Option value="hour">hours</Option>
                <Option value="day">days</Option>
                <Option value="month">months</Option>
              </Select>
            </div>
            <div
              className="form-item"
              style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
            >
              <Button htmlType="submit" type="primary">
                OK
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  )
}

export default Wait
