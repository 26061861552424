import * as yup from 'yup'

const invalidEmail = 'Email is not valid'
const requiredEmail = 'Please input your email'
const passwordNotLongEnough = 'Your password must be between 8 and 30 characters'
const requiredPass = 'Please input your password'

const passwordValidation = yup.string().min(8, passwordNotLongEnough).max(30).required(requiredPass)
const emailValidation = yup.string().email(invalidEmail).required(requiredEmail)

const loginSchema = yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
})

const registerSchema = yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
})

const forgotPasswordSchema = yup.object().shape({
  email: emailValidation,
})

const resetPassword = yup.object().shape({
  password: passwordValidation,
  confirmationPassword: yup
    .string()
    .required()
    .label('Confirm password')
    .test('passwords-match', 'Passwords must match ya fool', function (value) {
      return this.parent.password === value
    }),
})

export { loginSchema, forgotPasswordSchema, resetPassword, registerSchema }
