import React from 'react'

import FiltersHeader from '../../Components/FiltersHeader'
import Body from './Body'

const Overview = () => {
  return (
    <div className="AnalyticsWrapper relative">
      <div>
        <FiltersHeader />
      </div>

      <Body />
    </div>
  )
}

export default Overview
