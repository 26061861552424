import React from 'react'
import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'

const Notfound = () => {
  const history = useHistory()
  return (
    <div className="NotfoundComponent">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => history.push('/')}>
            Back Home
          </Button>
        }
      />
      ,
    </div>
  )
}

export default Notfound
