import { COLOR_PALLETTE } from 'Utils/constants'

export const columns = [
  {
    title: 'Ngày',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'KH đã chăm sóc',
    dataIndex: 'customer_care',
    key: 'customer_care',
  },
  {
    title: 'KH chưa chăm sóc',
    dataIndex: 'customer_did_not_care',
    key: 'customer_did_not_care',
  },
  {
    title: 'Mức độ hoàn thành',
    dataIndex: 'progress',
    key: 'progress',
  },
  {
    title: 'Thời gian hoàn thành',
    dataIndex: 'time_complete',
    key: 'time_complete',
  },
  {
    title: 'KH tạo doanh thu',
    dataIndex: 'customer_revenue',
    key: 'customer_revenue',
  },
  {
    title: 'Tỷ lệ chuyển đổi',
    dataIndex: 'conversion',
    key: 'conversion',
  },
  {
    title: 'Giá trị đơn TB',
    dataIndex: 'avg_order_revenue',
    key: 'avg_order_revenue',
  },
  {
    title: 'Doanh thu',
    dataIndex: 'revenue',
    key: 'revenue',
  },
]

export const SAMPLE_DATA = {
  bars: {
    revenue: {
      key: 'revenue',
      title: 'Doanh thu',
      values: [300, 450, 200, 100, 600, 700, 800],
    },
  },
  lines: {
    progress: {
      key: 'progress',
      title: 'Mức độ hoàn thành công việc',
      values: [10, 12, 5, 2, 20, 22, 25],
    },
  },
  labels: ['July 1', 'July 2', 'July 3', 'July 4', 'July 5', 'July 6', 'July 7'],
}

export const CHART_OPTIONS = {
  colors: {
    revenue: COLOR_PALLETTE[1],
    progress: COLOR_PALLETTE[3],
  },
  classNames: 'p-2',
  title: { display: true, text: 'Thống kê tiến độ công việc' },
}
