import React, { useMemo } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import PivotTable from 'Components/Charts/PivotTable'
import { Element } from 'Components/Charts/PivotTable/index.constants'
import useFilters from './FiltersHeader/useFilters'

import {
  CampaignPivotTypes,
  CustomerPivotTypes,
  ProductPivotTypes,
} from 'Services/Analytics/Pivot.service'
import ProductPivotTable from 'Components/Charts/ProductPivotTable'

const PivotTableTemplate = ({
  barcode,
  isRanking,
  defaultDimensions = [],
  defaultMetrics = [],
  defaultColumns = [],
  campaignId,
  type = CampaignPivotTypes.ProductInsight,
  isCustomer = false,
  hideDimensionSelects,
  hideMetricSelects,
  hideColumnSelects,
  currentPage,
}: {
  barcode?: string
  isRanking?: boolean
  currentPage?: string | undefined
  campaignId?: number
  defaultDimensions?: Element[]
  defaultMetrics?: Element[]
  defaultColumns?: Element[]
  type?: CampaignPivotTypes | CustomerPivotTypes | ProductPivotTypes
  isCustomer?: boolean
  hideDimensionSelects?: boolean
  hideMetricSelects?: boolean
  hideColumnSelects?: boolean
}) => {
  const history = useHistory()
  const { filters: defaultFilters } = useFilters()
  const queryObject = queryString.parse(history.location.search)

  const renderPivotTable = useMemo(() => {
    return (
      <>
        {currentPage == 'products' || currentPage == 'product360' ? (
          <ProductPivotTable
            barcode={barcode}
            isRanking={isRanking}
            currentPage={currentPage}
            defaultColumns={defaultColumns}
            className="w-full"
            type={type}
            isCustomer={isCustomer}
            defaultDimensions={defaultDimensions}
            defaultMetrics={defaultMetrics}
            objectId={campaignId}
            dateRange={[
              queryObject?.from_date?.toString() || defaultFilters?.from_date,
              queryObject?.to_date?.toString() || defaultFilters?.to_date,
            ]}
            hideDimensionSelects={hideDimensionSelects}
            hideMetricSelects={hideMetricSelects}
            hideColumnSelects={hideColumnSelects}
            dimension_segments={queryObject?.dimensions || '[]'}
          />
        ) : (
          <PivotTable
            barcode={barcode}
            isRanking={isRanking}
            currentPage={currentPage}
            defaultColumns={defaultColumns}
            className="w-full"
            type={type}
            isCustomer={isCustomer}
            defaultDimensions={defaultDimensions}
            defaultMetrics={defaultMetrics}
            objectId={campaignId}
            dateRange={[
              queryObject?.from_date?.toString() || defaultFilters?.from_date,
              queryObject?.to_date?.toString() || defaultFilters?.to_date,
            ]}
            hideDimensionSelects={hideDimensionSelects}
            hideMetricSelects={hideMetricSelects}
            hideColumnSelects={hideColumnSelects}
            dimension_segments={queryObject?.dimensions || '[]'}
          />
        )}
      </>
    )
  }, [queryObject?.from_date, queryObject?.to_date, queryObject?.dimensions])

  return renderPivotTable
}

export default PivotTableTemplate
