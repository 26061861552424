import React from 'react'
import { Form as FormikForm, Formik } from 'formik'
import { Button, Alert, Typography, Select } from 'antd'

const { Title } = Typography
const { Option } = Select

class Form extends React.Component {
  state = {
    isSubmitting: false,
    isValidating: false,
    loadingCondition: false,
    serverError: null,
    values: { email: '' },
  }
  handleSubmit = () => {}
  handleFormSubmit = () => {}
  CustomError = (props) => {
    return <Alert message={props.children} type="error" className="field-error" showIcon />
  }

  renderCreativeContentSelect = () => {}

  render() {
    return (
      <div className="CreativeContentPage">
        <Formik
          initialValues={{}}
          onSubmit={this.handleFormSubmit}
          children={(props) => (
            <FormikForm
              id="creative_content_form"
              className="form-wrapper"
              onSubmit={this.handleSubmit}
            >
              <Title className="form-title" level={3}>
                Creative Content
              </Title>
              {this.serverError && (
                <Alert message={this.serverError} type="error" className="server-error" showIcon />
              )}
              <div className="form-item">
                <Title className="field-title">Email</Title>
                <Select>
                  <Option></Option>
                </Select>
                {/* <ErrorMessage component={(props) => <CustomError {...props} />} name="email" /> */}
              </div>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button"
                loading={this.state.loadingCondition}
              >
                Add new Field
              </Button>
            </FormikForm>
          )}
          // validationSchema={loginSchema}
        />
      </div>
    )
  }
}

export default Form
