const colors = require('./Colors')
const spacing = require('./Spacing')
const fontSize = require('./FontSize')
const fontHeight = require('./FontHeight')
const shadow = require('./Shadow')
const fontWeight = require('./FontWeight')
const rounded = require('./Rounded')

const THEMES = {
  light: {
    colors: colors,
  },
}

const getTheme = () => {
  return localStorage.getItem('kariba_theme') || 'light'
}

const getColors = () => {
  const theme = getTheme()

  const colors = THEMES[theme].colors
  if (!colors) return {}
  return colors
}

const getColor = (color) => {
  const theme = getTheme()
  const cls = getColors(theme)
  return cls[color]
}

const generatePseudoClass = () => {
  const STYLE = document.documentElement.style

  // Add pseudo color to root
  Object.keys(colors.systemColor).forEach((colorKey) => {
    STYLE.setProperty(`--${colorKey}`, colors.systemColor[colorKey])
  })
  Object.keys(colors.productColor).forEach((colorKey) => {
    STYLE.setProperty(`--${colorKey}`, colors.productColor[colorKey])
  })

  // Add pseudo spacing to root
  Object.keys(spacing).forEach((key) => {
    STYLE.setProperty(`--sp_${key}`, spacing[key])
  })
  // Add pseudo fontsize to root
  Object.keys(fontSize).forEach((key) => {
    STYLE.setProperty(`--fs_${key}`, fontSize[key])
  })

  // Add pseudo fontWeight to root
  Object.keys(fontHeight).forEach((key) => {
    STYLE.setProperty(`--fh_${key}`, fontHeight[key])
  })

  // Add pseudo shadow to root
  Object.keys(shadow).forEach((key) => {
    STYLE.setProperty(`--${key}`, shadow[key])
  })

  // Add pseudo font-weight to root
  Object.keys(fontWeight).forEach((key) => {
    STYLE.setProperty(`--fw_${key}`, fontWeight[key])
  })

  // Add pseudo rounded to root
  Object.keys(rounded).forEach((key) => {
    STYLE.setProperty(`--br_${key}`, rounded[key])
  })
}

module.exports = {
  shadow,
  colors,
  rounded,
  spacing,
  fontSize,
  fontWeight,
  fontHeight,
  generatePseudoClass,
}
