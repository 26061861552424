import React, { useState } from 'react'
import { Select } from 'antd'

import MacOS from './MacOS'
import Window from './Window'
import Android from './Android'

import './index.scss'

const { Option } = Select

const platforms = [
  { label: 'Window', value: 'window' },
  { label: 'Mac OS', value: 'mac_os' },
  { label: 'Android', value: 'android' },
]

const WebPushPreview = ({ initData }) => {
  const [device, setDevice] = useState(platforms[0].value)

  return (
    <div className="WebPushPreviewComponent">
      <div className={`WebPushPreviewScreen`}>
        {device === 'mac_os' && <MacOS data={initData} />}
        {device === 'window' && <Window data={initData} />}
        {device === 'android' && <Android data={initData} />}
      </div>
      <div className="select-preview-device">
        <Select value={device} onChange={(value) => setDevice(value)}>
          {platforms.map((platform) => (
            <Option key={platform.value} value={platform.value}>
              {platform.label}
            </Option>
          ))}
        </Select>
        {/* <Title level={5}>Send to test device</Title> */}
      </div>
    </div>
  )
}

export default WebPushPreview
