import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { isEmpty } from 'lodash'
import { COLOR_PALLETTE } from 'Utils/constants'
import kFormatter from 'Utils/kFormatter'
import { truncate } from 'Utils'
import { colors } from 'DesignSystem'
import { none } from 'DesignSystem/Shadow'

const drawBarChart = ({ ctx, data, labels = [], type, isStacked = false, max, isPercentage }) => {
  Chart.register(ChartDataLabels)
  const formattedData = data[0].values
    ? data
    : data.map((item) => ({ ...item, values: { count: item.value } }))

  const renderDatasets = () => {
    if (type === 'horizontalBar' && !isStacked) {
      return [
        {
          data: formattedData.map((data) => data.values[Object.keys(data.values)[0]]),
          barThickness: 'flex',
          minBarLength: 20,
          backgroundColor: COLOR_PALLETTE,
        },
      ]
    }

    return labels.map((label, index) => {
      const computedData = formattedData.map((item) => item.values[label])
      return {
        label,
        data: computedData,
        barPercentage: 1,
        barThickness: 'flex',
        backgroundColor: COLOR_PALLETTE[index],
        minBarLength: 5,
      }
    })
  }

  const options: any = {
    type: 'bar',
    data: {
      labels: formattedData.map((item) => truncate(item.label, 20)),
      datasets: renderDatasets(),
    },
    options: {
      indexAxis: type === 'horizontalBar' ? 'y' : 'x',
      maintainAspectRatio: false,
      // responsive: true,
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            //@ts-ignore
            title: function (item) {
              let itemData = formattedData[item[0]?.dataIndex]
              return itemData.label
            },
          },
        },
        datalabels: {
          display: false,
        },
        legend: {
          display: false,
          position: 'bottom',
          align: 'start',
        },
      },
      scales: {
        x: {
          stacked: isStacked,
          max: max === 'auto' ? undefined : max,
          grid: {
            display: false,
          },
          ticks: {
            count: 6,
            ...(type === 'horizontalBar' && {
              callback: function (value, index, values) {
                if (isPercentage) return `${value}%`
                return kFormatter(value)
              },
            }),
          },
        },

        y: {
          stacked: isStacked,
          ticks: {
            count: 6,
            ...(type !== 'horizontalBar' && {
              callback: function (value, index, values) {
                if (isPercentage) return `${value}%`
                return kFormatter(value)
              },
            }),
          },
        },
      },
    },
  }

  new Chart(ctx, options)
}

export default drawBarChart
