import React, { useState } from 'react'
import { Skeleton, Typography, Checkbox, Button } from 'antd'
import { capitalizeFirstLetter } from 'Utils'
import { connect } from 'react-redux'
import './index.scss'
const { Title } = Typography

const Filters = (props) => {
  const { filterList, setFilters, filters, setForceRefresh, setIsFiltersVisible, onChange } = props
  return (
    <div className="AudienceFilterComponent p-4 pt-2">
      <Skeleton loading={!filterList} active={!filterList || !filterList.length}>
        {filterList &&
          Object.entries(filterList).map((filter) => (
            <div className="select-filter filter" key={filter[0]}>
              <Title className="filter-title" level={5}>
                {capitalizeFirstLetter(filter[0])}
              </Title>
              <Checkbox.Group
                onChange={(value) => {
                  setFilters({ ...filters, [filter[0]]: value })
                }}
                mode="multiple"
                value={filters[filter[0]]}
              >
                {filter[1].map((option) => (
                  <Checkbox key={option} value={option}>
                    {option}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </div>
          ))}
        <Button
          type="primary"
          size="small"
          onClick={() => {
            onChange(filters)
            setForceRefresh(true)
            setIsFiltersVisible(false)
          }}
        >
          Apply
        </Button>
      </Skeleton>
    </div>
  )
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    setForceRefresh: dispatch.audienceAnalytics.setForceRefresh,
  })
)(Filters)
