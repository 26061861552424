import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Skeleton } from 'antd'

import { getCustomer360Info } from 'Services/Analytics/customer-analytics'
import { CHANNELS } from './constants'

import HeaderInfo from './Header.Info'
import HeaderSegments from './Header.Segments'

export type Customer360InfoType = {
  name: string
  membership: string
  ltv: string
  cluster: string
  life_cycle: string
  reachable_channels: string[]
  location_n_channel_preference: {
    last_store: string
    channel_preference: string
  }
  info: {
    customer_id: string
    birthday: string
  }
  history_purchased: {
    first_order: string
    last_order: string
    last_city: string
  }
  segments: string[]
  score: { value: number; label: string }[]
}

const Header = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showSeeAll, setShowSeeAll] = useState(false)
  const segmentRef = React.useRef<HTMLDivElement>(null)

  const { id } = useParams()
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<Customer360InfoType | undefined>(undefined)

  useEffect(() => {
    const segmentHeight: any = segmentRef?.current?.clientHeight
    if (segmentHeight > 38) {
      setShowSeeAll(true)
    } else {
      setShowSeeAll(false)
    }
  })

  useEffect(() => {
    const handleData = async () => {
      try {
        setError('')
        const resp = await getCustomer360Info({ id })
        setData(resp.data)
      } catch (err: any) {
        setError('Not found')
      }
      setIsLoading(false)
    }
    handleData()
  }, [id])

  if (error) return <Alert message={error} type="error" />

  return (
    <Skeleton paragraph={{ rows: 6 }} loading={isLoading} active>
      <div className="Customer360Header">
        <HeaderInfo data={data} />
        <HeaderSegments data={data} />
      </div>
    </Skeleton>
  )
}

export default Header
