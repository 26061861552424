import axios from '../Utils/axios'

const PRODUCTS = '/products'

export async function uploadProductImage({ image, config = {} }) {
  const formData = new FormData()
  formData.append('images', image)
  const resp = await axios.post(
    `${PRODUCTS}/images/upload?company=${process.env.REACT_APP_COMPANY_NAME}`,
    formData,
    config
  )

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function searchProducts(keyword) {
  const resp = await axios.get(`${PRODUCTS}/search`, {
    params: { company: process.env.REACT_APP_COMPANY_NAME, keyword, limit: 50 },
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
