import React from 'react'

import BarChartTemplate from '../../../Components/BarChart.Template'
import MultiDoughnutChartTemplate from '../../../Components/MultiDoughnutChart.Template'

import { getCustomerOverviewCharts } from 'Services/Analytics/customer-analytics'

import { getProduct360Test, getProductOverviewCharts } from 'Services/Analytics/product-analytics'

import DoughnutChartTemplate from '../../../Components/DoughnutChart.Template'
import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'

const ChartRow2 = () => {
  return (
    <>
      <div className="p-4 col-span-3 shadow-div_rb">
        <div className="flex justify-between items-center">
          <div className="font-semibold">Customer Insight</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">Refresh all</div>
            <div>Download all</div>
          </div> */}
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Customer Lifetime Value (LTV)</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="ltv"
            handleDataFunction={getProductOverviewCharts}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Cluster</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="cluster"
            handleDataFunction={getProductOverviewCharts}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Membership</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="membership"
            handleDataFunction={getProductOverviewCharts}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Customer Life Cycle (CLC)</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="clc"
            handleDataFunction={getProductOverviewCharts}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Purchased Category </div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden doughnutChart">
          <DoughnutChartTemplate
            chartCode="purchased_category"
            handleDataFunction={getProductOverviewCharts}
            unit="Categories"
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Categories</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ height: 260 }}>
          <BarChartTemplate
            chartCode="top_category"
            hideLegends
            isHorizontal
            max={'auto'}
            isPercentage={false}
            handleDataFunction={getProductOverviewCharts}
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow2
