import React from 'react'
import { action, thunk } from 'easy-peasy'
import moment from 'moment'
import axios from 'Utils/axios'
import { needStringifyArray, capitalizeFirstLetter } from 'Utils'
import { getBQColumns } from 'Services/Analytics/Pivot.service'

const initState = {
  node_id: undefined,
  selected_node_statistic: ['type', null],
  isFetchingAnalytics: false,
  campaign_id: undefined,
  campaign_metrics: [],
  campaign_charts: [],
  collapsed: true,
  loading: ['key', false],
  bqColumns: [],
}

export default {
  ...initState,
  setCampaignAnalyticsStates: action((state, { key, value }) => {
    state[key] = value
  }),
  setBQColumns: action((state, columns) => {
    state.bqColumns = columns
  }),
  fetchBQColumns: thunk(async (actions, { type, isCustomer }) => {
    const columns = await getBQColumns(type, isCustomer)
    columns.forEach(function (obj) {
      obj.code = obj.field_name
      obj.name = capitalizeFirstLetter(obj.field_name)
      obj.type = obj.data_type
      delete obj.field_name
      delete obj.data_type
    })

    const sortedColumns = columns.sort(function (a, b) {
      if (a.code < b.code) {
        return -1
      }
      if (a.code > b.code) {
        return 1
      }
      return 0
    })

    actions.setBQColumns(sortedColumns)
  }),
  pushAnalytics: action((state, { key, value }) => {
    state[key].push(value)
  }),
  resetStates: action((state) => ({ ...initState })),
  getCampaignAnalytics: thunk(
    async (actions, { id, code, type, filter, is_key_metric }, { getState }) => {
      const state = getState()

      const analyticsResp = await axios.get(`campaigns/${id}/analytics`, {
        params: {
          code,
          is_key_metric: is_key_metric || undefined,
          ...needStringifyArray(filter),
        },
      })

      if (analyticsResp.response) {
        return { error: analyticsResp.response.data }
      }

      actions.pushAnalytics({
        key: type,
        value: {
          key: code,
          label: code.replace(/_/g, ' '),
          value:
            type == 'campaign_charts'
              ? analyticsResp.data.results
              : Object.entries(analyticsResp.data.results[0])[0][1] || 0,
        },
      })

      return type == 'campaign_charts'
        ? analyticsResp.data.results
        : Object.entries(analyticsResp.data.results[0])[0][1] || 0
    }
  ),
}
