export const STAFF_SF_TABS = [
  {
    label: 'Tổng quan',
    key: 'overview',
    breadCrumbs: [{ title: 'Store Farming', path: '/store-farming' }],
    roles: ['admin', 'asm', 'sm', 'rd'],
  },
  {
    label: 'Bảng xếp hạng',
    key: 'leader_board',
    breadCrumbs: [{ title: 'Store Farming', path: '/store-farming' }],
    roles: ['admin', 'asm', 'sm', 'rd'],
  },
]
