import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import ChartRow1 from './ChartRow_1'
import ChartRow2 from './ChartRow_2'
import ChartRow3 from './ChartRow_3'
import { getCustomerOverviewKeyMetrics } from 'Services/Analytics/customer-analytics'
import ChartRow4 from './ChartRow_4'
import ChartRow5 from './ChartRow_5'
import ChartRow6 from './ChartRow_6'
import FiltersHeader from 'Pages/Analytics/Components/FiltersHeader'
import PivotRow1 from './PivotRow_1'
import PivotRow2 from './PivotRow_2'
import PivotRow3 from './PivotRow_3'
import PivotRow4 from './PivotRow_4'
import PivotTableTemplate from 'Pages/Analytics/Components/PivotTable.Template'
import { ProductPivotTypes } from 'Services/Analytics/Pivot.service'
import Info from './Info'
import {
  getStore360KeyMetrics,
  getStore360DetailInfos,
} from 'Services/Analytics/saleChannel-analytics'
import KeyMetricsTemplate from 'Pages/Analytics/SaleAnalytics/Components/KeyMetrics.Template'
// import FunnelChart from 'Components/Charts/FunnelChart'

const Store360Body = () => {
  const { id } = useParams()
  const history = useHistory()
  const [isResetting, setIsResetting] = useState(false)
  const { ref: ref1, inView: ref1InView } = useInView({ triggerOnce: true })
  const { ref: ref2, inView: ref2InView } = useInView({ triggerOnce: true })
  const { ref: ref3, inView: ref3InView } = useInView({ triggerOnce: true })
  const { ref: ref4, inView: ref4InView } = useInView({ triggerOnce: true })
  const { ref: ref5, inView: ref5InView } = useInView({ triggerOnce: true })
  const { ref: ref6, inView: ref6InView } = useInView({ triggerOnce: true })
  const { ref: ref7, inView: ref7InView } = useInView({ triggerOnce: true })
  const { ref: ref8, inView: ref8InView } = useInView({ triggerOnce: true })
  const { ref: ref9, inView: ref9InView } = useInView({ triggerOnce: true })
  const { ref: ref10, inView: ref10InView } = useInView({ triggerOnce: true })
  const { ref: ref11, inView: ref11InView } = useInView({ triggerOnce: true })
  const { ref: ref12, inView: ref12InView } = useInView({ triggerOnce: true })

  useEffect(() => {
    setIsResetting(true)

    setTimeout(() => {
      setIsResetting(false)
    }, 10)
  }, [history.location.search])

  if (isResetting) return <div className="w-full" style={{ height: 1000 }}></div>
  return (
    <div className="Store360-body-wrapper">
      <FiltersHeader hideDimensions />
      <div className="mt-4 mb-4 bg-white">
        <Info handleDataFunction={getStore360DetailInfos} />
      </div>
      <div
        style={{ minHeight: 200 }}
        ref={ref1}
        className="p-4 bg-white customers-analytics-overview-metrics"
      >
        <div className="mt-4 mb-4 font-semibold">Key Metrics</div>
        <KeyMetricsTemplate handleDataFunction={getStore360KeyMetrics}></KeyMetricsTemplate>
      </div>
      <div style={{ minHeight: 300 }} ref={ref2} className="grid grid-cols-2 gap-4 mt-4">
        {ref2InView && <ChartRow1 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref3} className="grid grid-cols-2 gap-4 mt-4">
        {ref3InView && <ChartRow2 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref4} className="grid grid-cols-2 gap-4 mt-4 ">
        {ref4InView && <ChartRow3 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref5} className="grid grid-cols-2 gap-4 mt-4">
        {ref5InView && <ChartRow4 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref6} className="grid grid-cols-3 mt-4 bg-white">
        {ref6InView && <ChartRow5 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref7} className="grid grid-cols-3 mt-4 bg-white">
        {ref7InView && <ChartRow6 />}
      </div>
      <div ref={ref8} style={{ minHeight: 500 }} className="p-4 mt-4 bg-white">
        {ref8InView && <PivotRow1 />}
      </div>
      <div ref={ref9} style={{ minHeight: 500 }} className="p-4 mt-4 bg-white">
        {ref9InView && <PivotRow2 />}
      </div>
      <div ref={ref10} style={{ minHeight: 500 }} className="p-4 mt-4 bg-white">
        {ref10InView && <PivotRow3 />}
      </div>
      <div ref={ref11} style={{ minHeight: 500 }} className="p-4 mt-4 bg-white">
        {ref11InView && <PivotRow4 />}
      </div>

      {/* <div style={{ minHeight: 182 }} ref={ref12} className="p-4 mt-4 bg-white ">
        <div className="mb-4 font-semibold">Customize Section</div>
        {ref12InView && (
          <PivotTableTemplate
            type={ProductPivotTypes.ProductCustomizeSection}
            currentPage={window?.location?.href?.split('/')[4]?.split('?')[0]}
          />
        )}
      </div> */}
    </div>
  )
}

export default Store360Body
