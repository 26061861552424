import React, { useState, useEffect, useRef } from 'react'
import { Checkbox, Dropdown, Input, message, Select, Empty } from 'antd'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import { useStoreState } from 'easy-peasy'
import { PRODUCTS } from '../../constants'
import ProductItem from './ProductItem'
import { searchText } from 'Utils'
import './index.scss'
import { ProductProps } from 'ProductModule'
import { sendProductToCustomer } from 'Pages/Harasiocial/CustomerPortrait/Services'
const { Option } = Select

const RecommendedProducts = ({ data }) => {
  const refInput = useRef<any>(null)
  const { products: productsProp, colors, categories, sizes } = data

  const [isSearchExpanded, setIsSearchExpanded] = useState(false)
  const customerId = useStoreState((state) => state.harasocial.currentCustomerId)

  const [products, setProducts] = useState([])
  const [searchStr, setSearchStr] = useState('')
  const [selectedColor, setSelectedColor] = useState('all')
  const [selectedSize, setSelectedSize] = useState('all')
  const [selectedCate, setSelectedCate] = useState('all')

  useEffect(() => {
    if (isSearchExpanded) {
      return refInput?.current?.focus()
      /* eslint-enable */
    } else {
      if (searchStr !== '') setSearchStr('')
    }
  }, [isSearchExpanded])

  useEffect(() => {
    const ps = productsProp
      .filter((p) => {
        if (!searchStr) return true
        else if (searchText(p.title, searchStr)) return true
      })
      .filter((p) => {
        if (selectedColor === 'all') return true
        if (p.color === selectedColor) return true
      })
      .filter((p) => {
        if (selectedSize === 'all') return true
        if (p.size === selectedSize) return true
      })
    setProducts(ps.splice(0, 3))
  }, [productsProp, selectedColor, selectedSize, selectedCate, searchStr])

  const handleOnSendProduct = async (product) => {
    sendProductToCustomer(product.productId, customerId)
  }

  const renderHeader = () => {
    if (isSearchExpanded)
      return (
        <div className="flex items-center border-none">
          <Input
            ref={refInput}
            className="border-none"
            prefix={<SearchOutlined />}
            onChange={(e) => setSearchStr(e.target.value)}
          />
          <span
            className="text-brand_primary pl-3 cursor-pointer"
            onClick={() => setIsSearchExpanded(!isSearchExpanded)}
          >
            Hủy
          </span>
        </div>
      )

    return (
      <div className="flex justify-between">
        <h3 className="font-semibold text-black">Sản phẩm gợi ý</h3>
        <span className="cursor-pointer" onClick={() => setIsSearchExpanded(!isSearchExpanded)}>
          <SearchOutlined />
        </span>
      </div>
    )
  }

  const renderFilter = () => {
    return (
      <>
        <Select className="w-full" defaultValue="all" onChange={setSelectedCate}>
          <Option value={'all'}>Loại</Option>
          {categories.map((cat) => (
            <Option key={cat} value={cat}>
              {cat}
            </Option>
          ))}
        </Select>
        <Select className="w-full" defaultValue="all" onChange={setSelectedColor}>
          <Option value={'all'}>Màu sắc</Option>
          {colors.map((color) => (
            <Option key={color} value={color}>
              {color}
            </Option>
          ))}
        </Select>
        <Select className="w-full" defaultValue="all" onChange={setSelectedSize}>
          <Option value={'all'}>Kích thước</Option>
          {sizes.map((size) => (
            <Option key={size} value={size}>
              {size}
            </Option>
          ))}
        </Select>
      </>
    )
  }

  return (
    <div id="RecommendedProducts">
      {renderHeader()}
      <div className="filter flex py-2">{renderFilter()}</div>
      <div className="product-list">
        {products.length ? (
          products
            .slice(0, 3)
            .map((product: ProductProps) => (
              <ProductItem key={product.productId} product={product} onSend={handleOnSendProduct} />
            ))
        ) : (
          <Empty description="Không tìm thấy sản phẩm bạn cần tìm"></Empty>
        )}
      </div>
    </div>
  )
}

RecommendedProducts.defaultProps = {
  data: {},
}

export default RecommendedProducts
