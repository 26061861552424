import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Dimension as DimensionType } from 'DimensionsFiltersTypes'
import { Dimension as AudienceDimensionType } from 'CustomerSegmentTypes'

import DimensionFilter from 'Containers/DimensionFilters'
import { getDimensions, getDimensionsSegment } from 'Services/dimensions'

interface props {
  groupIndex?: number
  dimension?: AudienceDimensionType
}

const Dimension: React.FC<props> = ({
  groupIndex = 0,
  dimension = { segments: [], condition: '' },
}) => {
  const { onDimensionGroupChange } = useStoreActions((action: any) => action.customerSegments)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const { predefined_segment } = useStoreState((state) => state.customerSegments)
  const [options, setOptions] = useState()
  const convertOptions = (data) => {
    return data?.map((item) => {
      return { label: item.name, value: item.code }
    })
  }
  const getOptions = async () => {
    try {
      const customerResp = await getDimensionsSegment({
        group: 'segment',
        page: 'segment',
        order_by: 'name',
      })
      setOptions(convertOptions(customerResp?.data[0]?.dimensions[0]?.value))
    } catch (err) {
      throw err
    }
  }
  useEffect(() => {
    getOptions()
  }, [])

  return (
    <div className="flex flex-wrap gap-4">
      <div
        className="p-2 border border-border rounded w-full"
        style={{
          pointerEvents: `${predefined_segment ? 'none' : 'auto'}`,
          backgroundColor: `${predefined_segment ? '#f5f5f5' : '#ffffff'}`,
        }}
      >
        <DimensionFilter
          segmentOptions={options}
          activeDimension={dimension?.segments[0]?.type === 'SEGMENT' ? 'segment' : ''}
          syncedConditions
          emptyText={<span className="text-brand_primary cursor-pointer">Add Condition</span>}
          list={{ customer: { page: 'segment' } }}
          onSaveDimensions={(dimensions: DimensionType[]) => {
            onDimensionGroupChange({
              groupIndex,
              group: {
                dimension: {
                  segments: dimensions.map((dimension) => dimension.segments[0]),
                  condition: dimensions[1]?.condition || 'AND',
                },
                condition: 'AND',
              },
            })
          }}
          dimensions={dimension.segments.map((item) => ({
            segments: [item],
            condition: dimension.condition,
          }))}
          isPopupVisible={isPopoverVisible}
          setIsPopupVisible={setIsPopoverVisible}
        />
      </div>
    </div>
  )
}

export default Dimension
