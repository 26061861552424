import React from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography } from 'antd'
import { LockOutlined } from '@ant-design/icons'

import logo from 'Assets/images/logo.svg'

const { Password } = Input
const { Title } = Typography

export default React.memo(function ResetPasswordView(props) {
  const { handleChange, values, isSubmitting, isValidating, handleSubmit, serverError } = props
  const loadingCondition = isSubmitting || isValidating

  const CustomError = (props) => {
    return <Alert message={props.children} type="error" className="field-error" showIcon />
  }

  return (
    <Form id="reset_form" className="reset-form-wrapper" onSubmit={handleSubmit}>
      <div className="form-logo">
        <img src={logo} alt="form logo" width={150} />
      </div>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="form-item">
        <Title className="field-title">Password</Title>
        <Field
          as={Password}
          name="password"
          placeholder="Your password"
          className="password-field"
          size="large"
          prefix={<LockOutlined style={{ marginRight: 5 }} />}
          value={values.password}
          onChange={handleChange}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="password" />
      </div>
      <div className="form-item">
        <Title className="field-title">Confirmation Password</Title>
        <Field
          as={Password}
          name="confirmationPassword"
          placeholder="Your confirmation password"
          className="password-field"
          size="large"
          prefix={<LockOutlined style={{ marginRight: 5 }} />}
          value={values.confirmationPassword}
        />
        <ErrorMessage
          component={(props) => <CustomError {...props} />}
          name="confirmationPassword"
        />
      </div>
      <Button type="primary" htmlType="submit" className="submit-button" loading={loadingCondition}>
        Submit
      </Button>
    </Form>
  )
})
