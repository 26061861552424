import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import BarChartTemplate from 'Pages/Analytics/SaleAnalytics/Components/BarChart.Template'
import { getStore360Charts } from 'Services/Analytics/saleChannel-analytics'
import MultiDoughnutChartTemplate from 'Pages/Analytics/SaleAnalytics/Components/MultiDoughnutChart.Template'
import DoughnutChartTemplate from 'Pages/Analytics/SaleAnalytics/Components/DoughnutChart.Template'

const ChartRow6 = () => {
  const { id } = useParams()
  return (
    <>
      <div className="p-4 col-span-3 shadow-div_rb">
        <div className="flex justify-between items-center">
          <div className="font-semibold">Customer Insight</div>
          <div className="flex text-brand_primary ">
            <div
              className="mr-4 cursor-pointer"
              onClick={() => window.open('/analytics/customers?tab=customer_listing', '_self')}
            >
              View Customer List
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Customer Lifetime Value (LTV)</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="ltv"
            handleDataFunction={getStore360Charts}
            store_id={id}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Cluster</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="cluster"
            handleDataFunction={getStore360Charts}
            store_id={id}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Membership</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="membership"
            handleDataFunction={getStore360Charts}
            store_id={id}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Customer Life Cycle (CLC)</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="clc"
            handleDataFunction={getStore360Charts}
            store_id={id}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Purchased Category </div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden doughnutChart">
          <DoughnutChartTemplate
            chartCode="purchased_category"
            handleDataFunction={getStore360Charts}
            store_id={id}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">{`Style & Functional`}</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ height: 260 }}>
          <BarChartTemplate
            store_id={id}
            chartCode="top_category"
            hideLegends
            isHorizontal
            max={'auto'}
            isPercentage={false}
            handleDataFunction={getStore360Charts}
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow6
