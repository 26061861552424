import React from 'react'
import { Form } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'

import ImageField from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/Image'
import FormItem from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/FormItem'
import ProductField from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/StoreFarmingForm/Product'
import HarasocialPreview from 'Components/PreviewChannels/Preview/Harasocial'

import './index.scss'

const HaraSocialPlacement = ({ value }) => {
  const { content } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((action: any) => action.campaignModule)

  const onFormChange = (name, value) => {
    setState({ key: 'content', value: { ...content, [name]: value } })
  }

  return (
    <div className="flex gap-2 HarasocialPlacement">
      <Form
        className="sf-form"
        layout="vertical"
        name="ContentForm"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item key={'content'} label={'Content'}>
          <FormItem
            name={'content'}
            placeholder={'Input content'}
            type={'string'}
            value={content && content['content']}
            onChange={onFormChange}
          />
        </Form.Item>
        <Form.Item key="image" label="Image">
          <ImageField
            onChange={(image) => onFormChange('image', image)}
            value={
              Array.isArray(content?.image)
                ? content?.image
                : content?.image
                ? [content?.image]
                : []
            }
          />
        </Form.Item>

        <Form.Item key="products" label="Product" hasFeedback>
          <ProductField
            onAddProduct={(product) => {
              onFormChange('products', [...content.products, product])
            }}
            onRemoveProduct={(product) => {
              onFormChange(
                'products',
                content.products.filter((item) => item.ProductId !== product.ProductId)
              )
            }}
            value={content?.products || []}
          />
        </Form.Item>

        {[
          { name: 'utm_source', label: 'UTM Source' },
          { name: 'utm_medium', label: 'UTM Medium' },
          { name: 'utm_campaign', label: 'UTM Campaign' },
        ].map((field) => (
          <Form.Item key={field.name} label={field.label}>
            <FormItem
              name={field.name}
              placeholder={`Input ${field.label}`}
              type={'string'}
              value={content && content[field.name]}
              onChange={onFormChange}
            />
          </Form.Item>
        ))}
      </Form>

      <div className="w-full" id="ContentPreview">
        <div className="flex justify-between items-center">
          <span className="font-semibold text-lg">Preview</span>
        </div>
        <div className="preview-screen">
          <HarasocialPreview data={value} />
        </div>
      </div>
    </div>
  )
}

export default HaraSocialPlacement
