import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import CheckPermission from 'Components/CheckPermission'
import List from './List'
import Analytics from './CJAnalytics'
import CJLayout from 'Layouts/CustomerJourney'

const allowRoles = ['owner']

const CJRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />
}

const CJDetail = ({ match }) => {
  return <CJLayout id={match.params.id} />
}
const CJNew = ({ match }) => {
  return <CJLayout />
}

const CJList = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
}
const CJAnalytics = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={Analytics} {...props} />
}

export { CJList, CJAnalytics, CJNew, CJRoute, CJDetail }
