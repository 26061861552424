import React from 'react'
import { Tooltip, Typography } from 'antd'
import moment from 'moment'
import { capitalizeFirstLetter } from 'Utils'
import { systemColor } from 'DesignSystem/Colors'

const { Text } = Typography

const STATUS_COLORS = {
  running: 'green',
  init: 'greekBlue',
  waiting: 'yellow',
  paused: 'platinum',
  completed: 'cyan',
  stopped: 'red',
}

const computeTagColor = (tag) => {
  switch (tag) {
    case 'waiting':
      return 'yellow'
    case 'init':
      return 'light-gray'
    case 'paused':
      return 'brown'
    case 'completed':
      return 'blue'
    case 'running':
      return 'green'
    default:
      return 'gray'
  }
}

export const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    width: 200,
    align: 'left',
    render: (name) =>
      name ? (
        <Tooltip title={name}>
          <Text
            // ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
            className="campaign-title"
            strong
          >
            {name}
          </Text>
        </Tooltip>
      ) : (
        'N/A'
      ),
  },

  {
    title: 'Status',
    dataIndex: 'status',
    width: 50,
    sorter: true,
    render: (status) => (
      <span
        className="font-semibold rounded-sm text-sm py-1 px-2"
        style={{
          width: 'fit-content',
          display: 'inline-block',
          textAlign: 'left',
          color: `${
            STATUS_COLORS[status] === systemColor.geekblue_6
              ? systemColor.geekblue_6
              : systemColor[STATUS_COLORS[status] + '_6']
          }`,
          backgroundColor: `${
            STATUS_COLORS[status] === systemColor.geekblue_1
              ? systemColor.greekBlue_1
              : systemColor[STATUS_COLORS[status] + '_1']
          }`,
        }}
      >
        {capitalizeFirstLetter(status)}
      </span>
      // <span
      //   className={`font-semibold rounded-sm text-sm py-1 px-2 bg-${STATUS_COLORS[status]}_1 text-${STATUS_COLORS[status]}_6`}
      // >
      //   {capitalizeFirstLetter(status)}
      // </span>
    ),
  },
  {
    title: 'Revenue',
    dataIndex: ['analytic_key_metrics', 'revenue'],
    render: (value) => (value ? value?.toLocaleString() : 'N/A'),
  },
  {
    title: 'AOV',
    dataIndex: ['analytic_key_metrics', 'aov'],
    render: (value) => (value ? value?.toLocaleString() : 'N/A'),
  },
  {
    title: 'Transactions',
    dataIndex: ['analytic_key_metrics', 'transactions'],
    render: (value) => (value ? value?.toLocaleString() : 'N/A'),
  },
  {
    title: 'Clicks',
    dataIndex: ['analytic_key_metrics', 'clicks'],
    render: (value) => (value ? value?.toLocaleString() : 'N/A'),
  },
  {
    title: 'CR',
    dataIndex: ['analytic_key_metrics', 'cr'],
    render: (value) => (value ? value?.toLocaleString() : 'N/A'),
  },
  {
    title: 'Updated on',
    dataIndex: 'updated_at',
    sorter: true,
    render: (text) => (text ? moment(text).format('DD-MM-YYYY') : 'N/A'),
    width: 150,
  },
]
