import React, { SVGProps } from 'react'

import { ReactComponent as CopyIcon } from 'Assets/images/icons/copy.svg'
import { ReactComponent as CancelXIcon } from 'Assets/images/icons/cancel-x.svg'
import { ReactComponent as LoadingBar } from 'Assets/images/icons/loading-bar.svg'

export type IconName = 'copy' | 'cancel-x' | 'loading-bar'

export const iconsRegistry: Record<IconName, React.ComponentType<SVGProps<SVGSVGElement>>> = {
  copy: CopyIcon,
  'cancel-x': CancelXIcon,
  'loading-bar': LoadingBar,
}

export interface IconProps extends SVGProps<SVGSVGElement> {
  name: IconName
}

const Icon = ({ name, color = '#666666', viewBox = '0 0 24 24', ...rest }: IconProps) => {
  const Component = iconsRegistry[name]

  return <Component role="img" aria-label={name} color={color} viewBox={viewBox} {...rest} />
}

export default Icon
