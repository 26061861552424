export const roundPathCorners = (pathString, option = {}) => {
  let radius = option.radius || 10,
    useFractionalRadius = option.useFractionalRadius || false,
    exceptCommands = option.exceptCommands || []
  const moveTowardsLength = (movingPoint, targetPoint, amount) => {
    let width = targetPoint.x - movingPoint.x
    let height = targetPoint.y - movingPoint.y

    let distance = Math.sqrt(width * width + height * height)

    return moveTowardsFractional(movingPoint, targetPoint, Math.min(1, amount / distance))
  }
  const moveTowardsFractional = (movingPoint, targetPoint, fraction) => {
    return {
      x: movingPoint.x + (targetPoint.x - movingPoint.x) * fraction,
      y: movingPoint.y + (targetPoint.y - movingPoint.y) * fraction,
    }
  }

  // Adjusts the ending position of a command
  const adjustCommand = (cmd, newPoint) => {
    if (cmd.length > 2) {
      cmd[cmd.length - 2] = newPoint.x
      cmd[cmd.length - 1] = newPoint.y
    }
  }

  // Gives an {x, y} object for a command's ending position
  const pointForCommand = (cmd) => {
    return {
      x: parseFloat(cmd[cmd.length - 2]),
      y: parseFloat(cmd[cmd.length - 1]),
    }
  }

  // Split apart the path, handing concatonated letters and numbers
  let pathParts = pathString.split(/[,\s]/).reduce((parts, part) => {
    let match = part.match('([a-zA-Z])(.+)')
    if (match) {
      parts.push(match[1])
      parts.push(match[2])
    } else {
      parts.push(part)
    }

    return parts
  }, [])

  // Group the commands with their arguments for easier handling
  let commands = pathParts.reduce((commands, part) => {
    if (parseFloat(part) == part && commands.length) {
      commands[commands.length - 1].push(part)
    } else {
      commands.push([part])
    }

    return commands
  }, [])

  // The resulting commands, also grouped
  let resultCommands = []

  if (commands.length > 1) {
    let startPoint = pointForCommand(commands[0])

    // Handle the close path case with a "virtual" closing line
    let virtualCloseLine = null
    if (commands[commands.length - 1][0] == 'Z' && commands[0].length > 2) {
      virtualCloseLine = ['L', startPoint.x, startPoint.y]
      commands[commands.length - 1] = virtualCloseLine
    }

    // We always use the first command (but it may be mutated)
    resultCommands.push(commands[0])

    for (let cmdIndex = 1; cmdIndex < commands.length; cmdIndex++) {
      let prevCmd = resultCommands[resultCommands.length - 1]

      let curCmd = commands[cmdIndex]
      if (exceptCommands.includes(cmdIndex)) {
        resultCommands.push(curCmd)
        continue
      }
      // Handle closing case
      let nextCmd = curCmd == virtualCloseLine ? commands[1] : commands[cmdIndex + 1]
      // Nasty logic to decide if this path is a candidite.
      if (
        nextCmd &&
        prevCmd &&
        prevCmd.length > 2 &&
        curCmd[0] == 'L' &&
        nextCmd.length > 2 &&
        nextCmd[0] == 'L'
      ) {
        // Calc the points we're dealing with
        let prevPoint = pointForCommand(prevCmd)
        let curPoint = pointForCommand(curCmd)
        let nextPoint = pointForCommand(nextCmd)

        // The start and end of the cuve are just our point moved towards the previous and next points, respectivly
        let curveStart, curveEnd

        if (useFractionalRadius) {
          curveStart = moveTowardsFractional(curPoint, prevCmd.origPoint || prevPoint, radius)
          curveEnd = moveTowardsFractional(curPoint, nextCmd.origPoint || nextPoint, radius)
        } else {
          curveStart = moveTowardsLength(curPoint, prevPoint, radius)
          curveEnd = moveTowardsLength(curPoint, nextPoint, radius)
        }

        // Adjust the current command and add it
        adjustCommand(curCmd, curveStart)
        curCmd.origPoint = curPoint
        resultCommands.push(curCmd)

        // The curve control points are halfway between the start/end of the curve and
        // the original point
        let startControl = moveTowardsFractional(curveStart, curPoint, 0.5)
        let endControl = moveTowardsFractional(curPoint, curveEnd, 0.5)

        // Create the curve
        let curveCmd = [
          'C',
          startControl.x,
          startControl.y,
          endControl.x,
          endControl.y,
          curveEnd.x,
          curveEnd.y,
        ]
        // Save the original point for fractional calculations
        curveCmd.origPoint = curPoint
        resultCommands.push(curveCmd)
      } else {
        // Pass through commands that don't qualify
        resultCommands.push(curCmd)
      }
    }

    // Fix up the starting point and restore the close path if the path was orignally closed
    if (virtualCloseLine) {
      let newStartPoint = pointForCommand(resultCommands[resultCommands.length - 1])
      resultCommands.push(['Z'])
      adjustCommand(resultCommands[0], newStartPoint)
    }
  } else {
    resultCommands = commands
  }

  return resultCommands.reduce((str, c) => {
    return str + c.join(' ') + ' '
  }, '')
}
export default roundPathCorners
