import React, { useEffect, Suspense } from 'react'
import { Typography, DatePicker } from 'antd'
import classNames from 'classnames'

import KeyMetrics from '../../Components/Charts/KeyMetrics'

import './index.scss'
const ChartLayout = React.lazy(() => import('./ChartLayout.tsx'))
const { Title } = Typography

const AnalyticsContainer = (props) => {
  const { data, forceRefresh, fetchAnalytics, filters, fields } = props

  useEffect(() => {
    if (forceRefresh) {
      fetchAnalytics(filters)
    }
  }, [forceRefresh])

  useEffect(() => {
    fetchAnalytics(filters)
  }, [])

  return (
    <div className="AnalyticsContainer">
      <div className="analytics-container">
        <div className="analytics-grid">
          <div className={classNames('box box1 isExpanded')}>
            <Title level={5}>Key metrics</Title>
            <KeyMetrics fields={fields?.metrics} data={data?.metrics} />
          </div>

          <Suspense fallback="loading...">
            <ChartLayout data={data} />
          </Suspense>
        </div>
      </div>
    </div>
  )
}

export default AnalyticsContainer
