import React from 'react'
import { Select } from 'antd'
import { AggregateSelectProps, SQL_AGGREGATE } from '../index.constants'
const AggregateSelect = ({
  className,
  placeholder,
  defaultValue,
  onChange,
  aggregateList,
}: AggregateSelectProps) => {
  const handleAggregateChange = (value) => {
    const tempArr: any = []
    value.map((v) => {
      tempArr.push(v?.name)
    })
    return tempArr
  }
  return (
    <Select
      className={`AggregateSelectComponent ${className}`}
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
    >
      {aggregateList &&
        handleAggregateChange(aggregateList)?.map((agg) => (
          <Select.Option key={agg} value={agg}>
            {agg}
          </Select.Option>
        ))}
    </Select>
  )
}

export default AggregateSelect
