import { useState, useEffect } from 'react'

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(() => value)
      }, delay)
      return () => clearTimeout(handler)
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

export default useDebounce
