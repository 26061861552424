export const haraSocialVariables = ['{{first_name}}', '{{last_name}}']

export const haraSocialInitialValues = {
  content: null,
  type: null,
  image: null,
  product_ids: [],
}

export const haraSocialSchemas = [
  {
    name: 'type',
    type: 'select',
    label: 'Assets',
    isRequired: true,
    options: [
      { name: 'Image', code: 'image' },
      { name: 'Product', code: 'product' },
      { name: 'Text', code: 'text' },
    ],
  },
]
