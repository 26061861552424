import React from 'react'
import loadingIcon from 'Assets/images/icons/kariba_loading_icon.svg'
import className from 'classnames'
import './index.scss'

const LoadingIcon = ({ style, stretch, size }) => {
  return (
    <img
      className={className(`LoadingIconComponent ${size}`, { stretch })}
      src={loadingIcon}
      style={style}
    />
  )
}

LoadingIcon.defaultProps = {
  stretch: false,
  style: {},
  size: 'small',
}

export const LoadingWithOverlay = ({ stretch, loadingProps }) => {
  return (
    <div className={className('LoadingWithOverlayComponent', { stretch })}>
      <LoadingIcon style={loadingProps.style} size={loadingProps.size} stretch></LoadingIcon>
    </div>
  )
}

LoadingWithOverlay.defaultProps = {
  stretch: false,
  loadingProps: {},
}

export default LoadingIcon
