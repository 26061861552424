import moment from 'moment'

import { STEPS, STEPS_WITH_ID } from './constants'
import { getCampaignChannels } from 'Services/campaigns'

export const formatCampaignErrors = (errors) => {
  if (!errors) return 'Something went wrong'

  return Array.isArray(errors.detail)
    ? errors.detail.reduce(
        (acc, currentValue, index, arr) =>
          `${acc} ${currentValue.msg}${index < arr.length - 1 ? ',' : ''}`,
        ''
      )
    : errors.detail || 'Something went wrong'
}

export const isNextStepDisabled = (currentStep, states, id) => {
  switch (currentStep) {
    case 0:
      return states.selected_placement?.code ? false : true
    case id ? '' : 1:
      return states.audience_id ? false : true
    case id ? 1 : 2:
      return !Object.keys(states.content || [])?.length || !states.isContentValidated ? true : false

    default:
      return currentStep === (id ? STEPS_WITH_ID.length - 1 : STEPS.length - 1)
  }
}

export const setCampaignDetail = async ({ data, handler }) => {
  const channels = await getCampaignChannels()
  const selected_channel = channels.data.find((channels) =>
    channels?.placement_arr.some(
      (placement) => placement.id === data.product_creative_content_objs[0]?.placement_id
    )
  )
  handler({ key: 'name', value: data.name })
  handler({ key: 'id', value: data.id })
  handler({ key: 'description', value: data.description })
  handler({ key: 'bid_amount', value: data.bid_amount })
  handler({ key: 'daily_budget', value: data.daily_budget })
  handler({ key: 'kpi', value: data.kpi })
  handler({ key: 'campaign_tag_objs', value: data.campaign_tag_objs })

  if (selected_channel) {
    handler({
      key: 'selected_channel',
      value: selected_channel,
    })
    handler({
      key: 'placements',
      value: selected_channel.placement_arr,
    })
    handler({
      key: 'selected_placement',
      value: selected_channel.placement_arr.find(
        (placement) => placement?.id === data.product_creative_content_objs[0]?.placement_id
      ),
    })
  }
  handler({ key: 'content', value: data.product_creative_content_objs[0]?.content })
  handler({ key: 'content_id', value: data.product_creative_content_objs[0]?.id })
  handler({ key: 'audience_id', value: data.audience_segment_obj?.id })

  if (data.schedule_obj) {
    data.schedule_obj.start_date &&
      handler({
        key: 'start_date',
        value: moment(data.schedule_obj.start_date).format('DD-MM-YYYY'),
      })
    data.schedule_obj.start_time &&
      handler({
        key: 'start_time',
        value: moment(data.schedule_obj.start_time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
      })
    data.schedule_obj.start_at &&
      handler({
        key: 'start_at',
        value: moment(data.schedule_obj.start_at, 'HH:mm:ss').format('HH:mm'),
      })
    data.schedule_obj.end_date &&
      handler({ key: 'end_date', value: moment(data.schedule_obj.end_date).format('DD-MM-YYYY') })
    data.schedule_obj.end_time &&
      handler({ key: 'end_time', value: moment(data.schedule_obj.end_time).format('HH:mm') })
    handler({ key: 'mode', value: data.schedule_obj.mode })
    handler({ key: 'days', value: data.schedule_obj.days })
  }
}
