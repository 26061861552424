import React from 'react'
import { divide, startCase } from 'lodash'
import { COLOR_PALLETTE, LABEL_CHART } from 'Utils/constants'
import {
  ComposedChart,
  YAxis,
  XAxis,
  Tooltip,
  ReferenceLine,
  Bar,
  Line,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import kFormatter from 'Utils/kFormatter'

const BarLineReCharts = ({ rawData }) => {
  const isProduct = window?.location?.href?.split('/')[4]?.split('?')[0] === 'products'
  const isStore360 = window?.location?.href?.split('/')[4]?.split('?')[0] === 'store360'

  const computeData = (data: {
    code: string
    bars: {
      [code: string]: { key: string; values: number[] }
    }
    lines: {
      [code: string]: { key: string; values: number[] }
    }
    labels: string[]
  }): { [code: string]: number | string }[] => {
    const labels = data.labels

    const getObjectValuesFromIndex = (object, index) => {
      const ObjectArr = Object.keys(object).map((key) => ({ [key]: object[key].values[index] }))

      return ObjectArr.reduce((acc, curr) => ({ ...acc, ...curr }), {})
    }

    return labels.map((label, index) => ({
      label: startCase(label.toString().replace(/_/g, ' ')) || '',
      ...getObjectValuesFromIndex(data.bars, index),
      ...getObjectValuesFromIndex(data.lines, index),
    }))
  }

  const computeColor = (index) => {
    const allKeys = Object.keys(rawData.bars).concat(Object.keys(rawData.lines))
    const colors = allKeys.map((key, index) => COLOR_PALLETTE[index])

    return colors[index]
  }

  const computeRightTicks = () => {
    const { bars, lines } = rawData

    const barKeys = Object.keys(bars)
    const lineKeys = Object.keys(lines)

    const getMaxValue = (arr) => arr.reduce((acc, curr) => Math.max(acc, curr), 0)
    const getMinValue = (arr) => arr.reduce((acc, curr) => Math.min(acc, curr), 0)

    const barMinValue = getMinValue(barKeys.map((key) => getMinValue(bars[key].values)))
    const lineMaxValue = getMaxValue(lineKeys.map((key) => getMaxValue(lines[key].values)))

    let ticks: number[] = []

    if (barMinValue >= 0) {
      return undefined
    }

    if (barMinValue < 0 || lineMaxValue < 0) {
      for (let i = -1; i < 4; i += 1) {
        ticks.push((lineMaxValue / 4) * i)
      }
    }

    return ticks
  }

  const computeChartItems = () => {
    const { bars, lines } = rawData

    const barKeys = Object.keys(bars)
    const barChartItems = barKeys.map((key, index) => (
      <Bar yAxisId="left" dataKey={key} barSize={20} fill={computeColor(index)} />
    ))
    const lineKeys = Object.keys(lines)
    const lineChartItems = lineKeys.map((key, index) => (
      <Line
        yAxisId="right"
        dataKey={key}
        type="monotone"
        stroke={computeColor(index + barKeys.length)}
      />
    ))

    return [...barChartItems, ...lineChartItems]
  }

  const formatNumber = (number) => {
    if (number > 100) {
      return kFormatter(number)
    } else {
      return number.toLocaleString()
    }
  }
  const formatNumberShort = (number) => {
    return number.toLocaleString()
  }

  const CustomTooltip = (props) => {
    if (props.active) {
      return (
        <div
          style={{ minWidth: 76, minHeight: 40 }}
          className="relative text-white bg-primary_text min-w-[76px] min-h-[40px] px-2 py-1 text-center text-sm rounded "
        >
          <div className="font-bold">{props.label}</div>
          <div>
            {props?.payload.map((item) => {
              return (
                <div>
                  {item.name ? item.name : LABEL_CHART[rawData.code]} : {formatNumber(item.value)}
                </div>
              )
            })}
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }

  return (
    <ResponsiveContainer>
      <ComposedChart data={computeData(rawData)} margin={{ left: 30 }}>
        <XAxis tick={{ fontSize: 12 }} dataKey="label" />
        <ReferenceLine yAxisId="left" y={0} stroke="var(--border)" />
        <YAxis
          tickFormatter={isProduct || isStore360 ? formatNumber : formatNumberShort}
          tick={{ fontSize: 12, fill: 'var(--primary_text)' }}
          stroke="var(--border)"
          yAxisId="left"
          type="number"
        />
        <YAxis
          tickFormatter={isProduct || isStore360 ? formatNumber : formatNumberShort}
          tick={{ fontSize: 12, fill: 'var(--primary_text)' }}
          stroke="var(--border)"
          yAxisId="right"
          type="number"
          orientation="right"
          ticks={computeRightTicks() || undefined}
          axisLine={true}
        />
        <Tooltip formatter={formatNumber} content={<CustomTooltip />} />
        <CartesianGrid vertical={false} />

        {computeChartItems()}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default BarLineReCharts
