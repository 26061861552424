const FB = {
  init: () => {
    return new Promise((resolve, reject) => {
      if (typeof window.FB !== 'undefined') resolve()
      else {
        window.fbAsyncInit = () => {
          window.FB.init({
            appId: process.env.REACT_APP_FACEBOOK_APP_ID,
            cookie: true,
            xfbml: true,
            version: 'v2.5',
          })
          resolve()
        }
        ;(function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0]
          if (d.getElementById(id)) return
          js = d.createElement(s)
          js.id = id
          js.src = '//connect.facebook.net/en_US/sdk.js'
          fjs.parentNode.insertBefore(js, fjs)
        })(document, 'script', 'facebook-jssdk')
      }
    })
  },
  checkLoginState: () => {
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus((response) => {
        response.status === 'connected' ? resolve(response) : reject(response)
      })
    })
  },
  isLoggedin: () => {
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus((response) => {
        response.status === 'connected' ? resolve(true) : resolve(false)
      })
    })
  },
  login: () => {
    return new Promise((resolve, reject) => {
      window.FB.login(
        (response) => {
          response.status === 'connected' ? resolve(response) : reject(response)
        },
        { scope: 'public_profile,ads_management,ads_read,pages_show_list,pages_manage_metadata' }
      )
    })
  },
  logout: () => {
    return new Promise((resolve, reject) => {
      window.FB.logout((response) => {
        response.authResponse ? resolve(false) : resolve(true)
      })
    })
  },
  fetch: () => {
    return new Promise((resolve, reject) => {
      window.FB.api('/me', { fields: 'first_name, last_name, gender' }, (response) =>
        response.error ? reject(response) : resolve(response)
      )
    })
  },
}

export default FB
