import React, { useState, useEffect } from 'react'
import { Tabs, Table, Input } from 'antd'

import FieldWrapper from '../FieldWrapper'
import SearchInput from 'Components/ExternalComponents/SearchInput'
// import { products } from 'Components/CJPopover/NodeConfigs/Channels/HaraSocial/index.constants'
import { searchProducts as searchProductsApi } from 'Services/products'
import { useDebounce } from 'Hooks'

const { TabPane } = Tabs

type Product = {
  ProductId: string
  Title: string
  Price: string
  OriginalPrice: string
  Slug: string
  VariantId: string
  CategoryId: string
  Sku: string
  Color: string
  Size: string
  CategoryType: string
  Image: string
  ImageWithPerson: string
}

const columns = [
  {
    title: 'Image',
    dataIndex: 'Image',
    render: (img: string) => <img style={{ height: 60, width: 'auto' }} src={img} alt="" />,
  },
  {
    title: 'Sku',
    dataIndex: 'Sku',
  },
  {
    title: 'Title',
    dataIndex: 'Title',
  },
  {
    title: 'Price',
    dataIndex: 'Price',
    render: (price: string) => <span>{parseInt(price).toLocaleString()}đ</span>,
  },
]

interface Props {
  name: string
  values: any
  setFieldValue: (name: string, values: React.Key[]) => void
}

const ProductSelects: React.FC<Props> = (props) => {
  const { name, values, setFieldValue } = props

  const [products, setProducts] = useState<Product[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [selectedProductIds, setSelectedProductIds] = useState<React.Key[]>(values[name])
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])

  const debounceSearch = useDebounce(searchText, 1000)

  useEffect(() => {
    async function searchProducts() {
      try {
        const res = await searchProductsApi(debounceSearch)
        setProducts(res.data)
        setSelectedProducts(
          values[name] ? res.data.filter((product) => values[name].includes(product.ProductId)) : []
        )
      } catch (err: any) {}
    }
    searchProducts()
  }, [debounceSearch, name, values])

  const rowSelection = {
    selectedRowKeys: selectedProductIds,
    onChange: (selectedRowKeys: React.Key[], products: Product[]) => {
      setSelectedProductIds(selectedRowKeys)
      setSelectedProducts(products)
      setFieldValue(props.name, selectedRowKeys)
    },
  }

  return (
    <FieldWrapper {...props}>
      {(fieldProps: any) => {
        return (
          <Tabs defaultActiveKey="1">
            <TabPane tab="All" key="1">
              <SearchInput
                className="mb-4"
                onChange={(e) => setSearchText(e.target.value)}
                onClear={() => setSearchText('')}
                onSubmit={() => {}}
                value={searchText}
                placeholder="Search title"
              />

              <Table
                columns={columns}
                dataSource={products.filter(
                  (d) =>
                    searchText === '' ||
                    d.Title.toLowerCase().includes(searchText.toLocaleLowerCase())
                )}
                rowKey="ProductId"
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                pagination={{ pageSize: 5 }}
              />
            </TabPane>
            <TabPane tab="Selected" key="2">
              <Table
                columns={columns}
                dataSource={selectedProducts}
                rowKey="ProductId"
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                pagination={{ pageSize: 5 }}
              />
            </TabPane>
          </Tabs>
        )
      }}
    </FieldWrapper>
  )
}

export default ProductSelects
