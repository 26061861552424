import React, { useEffect } from 'react'
import { Button } from 'antd'
import { MetricPopoverModalProps } from '../index.constants'
import AggregateSelect from './AggregateSelect'
import ColumnSelect from './ColumnSelect'

const MetricPopoverModal = ({
  defaultColumnValue,
  defaultAggregateValue,
  className = '',
  onChange,
  onAdd = () => {},
  index,
  options,
}: MetricPopoverModalProps) => {
  const [column, setColumn] = React.useState(defaultColumnValue)
  const [aggregate, setAggregate] = React.useState(defaultAggregateValue)
  const [aggregateList, setAggregateList] = React.useState([])

  const handleSave = () => {
    if (index === null || index === undefined) onAdd(column, aggregate)
    else onChange(column, aggregate, index as number)
    setColumn(null)
    setAggregate(null)
  }

  const getAggregateList = (list, code) => {
    return list.find((e) => e.code === code)
  }
  useEffect(() => {
    setAggregateList(getAggregateList(options, column)?.aggregates)
  }, [column])
  return (
    <div className={`MetricPopoverModalComponent ${className}`}>
      <ColumnSelect
        options={options}
        className="w-full"
        placeholder="Metric"
        defaultValue={defaultColumnValue}
        onChange={setColumn}
      ></ColumnSelect>
      <AggregateSelect
        className="mt-4"
        placeholder="Aggregate Function"
        defaultValue={defaultAggregateValue}
        onChange={setAggregate}
        aggregateList={aggregateList}
      ></AggregateSelect>
      <div className="footer mt-5 flex justify-end">
        <Button type="primary" size="small" disabled={!column || !aggregate} onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default MetricPopoverModal
