import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { ANALYTIC_METRICS_CODES, ANALYTIC_CHARTS_CODES } from 'Utils/constants'
import CJRightSideBarView from './CJRightSideBar.view'

import './index.scss'

const CJRightSideBar = (props) => {
  const { CJAnalyticsStates, setCJAnalyticsStates, getNodeAnalytics, getFilters } = props

  const [AnalyticFilters, setAnalyticsFilters] = useState()

  const { node_id } = CJAnalyticsStates

  const getCharts = async (filter) => {
    setCJAnalyticsStates({ key: 'node_analytics_charts', value: [] })
    for (const chart of ANALYTIC_CHARTS_CODES) {
      getNodeAnalytics({ code: chart, type: 'node_analytics_charts', filter })
    }
  }

  const getMetrics = async (filter) => {
    setCJAnalyticsStates({ key: 'loading', value: ['node_analytics_metrics', true] })
    setCJAnalyticsStates({ key: 'node_analytics_metrics', value: [] })
    for (const metric of ANALYTIC_METRICS_CODES) {
      getNodeAnalytics({ code: metric, type: 'node_analytics_metrics', filter })
    }
    setCJAnalyticsStates({ key: 'loading', value: ['node_analytics_metrics', false] })
  }

  const generateActionType = (statType) => {
    switch (statType) {
      case 'reached':
        return 'enter'
      case 'failed':
        return 'not_pass'
      case 'passed':
        return 'pass'
      default:
        return
    }
  }

  const fetchAnalytics = (filter) => {
    setAnalyticsFilters(filter)
    getCharts(filter)
    getMetrics(filter)
    getFilters()
  }

  const isFirstRun = useRef(true)
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    fetchAnalytics(AnalyticFilters)
  }, [node_id])

  return (
    <CJRightSideBarView
      fetchAnalytics={fetchAnalytics}
      generateActionType={generateActionType}
      {...props}
    />
  )
}

export default connect(
  (state) => ({
    CJAnalyticsStates: state.CJAnalytics,
    audienceStates: state.campaign.audience,
    filterList: state.audienceAnalytics.filterList,
  }),
  (dispatch) => ({
    getNodeAnalytics: dispatch.CJAnalytics.getNodeAnalytics,
    setCJAnalyticsStates: dispatch.CJAnalytics.setCJAnalyticsStates,
    resetCJAnalyticsStates: dispatch.CJAnalytics.resetStates,
    getFilters: dispatch.audienceAnalytics.getFilters,
  })
)(CJRightSideBar)
