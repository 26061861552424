import React, { useState, useEffect } from 'react'
import { Button, Divider, Typography, message, Skeleton, Avatar, Spin } from 'antd'
import { MergeCellsOutlined, DisconnectOutlined, LoadingOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import './index.scss'
import { facebook } from 'Utils'
import axios from 'Utils/axios'
import AdsAccount from './AdsAccount'

const { Title } = Typography

const AgencyConnection = ({ type, Icon }) => {
  const [connected, setConnected] = useState(null)
  const [shouldCheckConnection, setShouldCheckConnection] = useState(true)

  const [adsAccounts, setAdAccounts] = useState([])

  useEffect(() => {
    if (shouldCheckConnection) {
      setShouldCheckConnection(false)
      setTimeout(() => {
        if (type === 'facebook')
          facebook.isLoggedin().then((logged) => {
            setConnected(logged)
            refreshAdsAccount()
          })
      }, 500)
    }
  }, [shouldCheckConnection])

  const switchAdsAccount = (ad_id) => {
    setAdAccounts(
      adsAccounts.map((ads) => {
        if (ads.account_id === ad_id) ads.is_selected = true
        else ads.is_selected = false
        return ads
      })
    )
  }

  const refreshAdsAccount = () => {
    // setShouldCheckConnection(true)
    axios.get('/agents/connection-profiles/facebook/ad-accounts/all').then((rs) => {
      if (rs.data && rs.data.data) setAdAccounts(rs.data.data)
      else setAdAccounts([])
    })
  }

  const onConnect = () => {
    if (type === 'facebook')
      facebook.login().then(({ authResponse }) => {
        axios
          .post('/agents/connection-profiles/facebook/', {
            access_token: authResponse.accessToken,
            user_id: authResponse.userID,
            expires_in: authResponse.expiresIn,
            signed_request: authResponse.signedRequest,
            graph_domain: authResponse.graphDomain,
            data_access_expiration_time: authResponse.data_access_expiration_time,
          })
          .then((rs) => {
            refreshAdsAccount()
            setConnected(true)
          })
      })
    else message.error('Provider configuration not present!')
  }

  const onDisconnect = () => {
    facebook.logout().then((success) => {
      axios.delete('/agents/connection-profiles/facebook/')
      setAdAccounts([])
      setConnected(false)
    })
  }

  return (
    <div className="AgencyConnection form-item">
      {/* <Skeleton loading={adsAccounts.length <= 0 && type === 'facebook'} paragraph={{ rows: 4 }}> */}
      <div className="icon-wrapper">
        <Icon className={`icon ${type}`} />
      </div>
      <div className="info-wrapper">
        {(adsAccounts.length > 0 && type === 'facebook') || type === 'google' ? (
          <>
            <Title className={`title ${type}`} level={4}>
              {type}
            </Title>
            {adsAccounts.map((acc, index) => (
              <AdsAccount
                key={index}
                switchAdsAccount={switchAdsAccount}
                index={index}
                adsAccount={acc}
              ></AdsAccount>
            ))}
          </>
        ) : (
          <span style={{ color: type === 'facebook' ? '#1890ff' : '#f5222d' }}>No Ads Account</span>
        )}
      </div>
      <div className="status-wrapper">
        <span
          className={classNames(['status', { active: connected, 'not-connected': !connected }])}
        >
          {connected ? 'Connected' : 'Not Connected'}
        </span>
      </div>
      {/* </Skeleton> */}
      <div className="actions-wrapper">
        {connected === null && type === 'facebook' ? (
          <Spin />
        ) : connected ? (
          <DisconnectOutlined
            className="action-icon connect"
            onClick={onDisconnect}
          ></DisconnectOutlined>
        ) : (
          <MergeCellsOutlined
            className="action-icon connect"
            onClick={onConnect}
          ></MergeCellsOutlined>
        )}
      </div>
    </div>
  )
}

export default AgencyConnection
