module.exports = {
  xs: '10px',
  sm: '12px',
  base: '14px',
  lg: '16px',
  xl: '18px',
  '2xl': '20px',
  '3xl': '24px',
  '4xl': '28px',
  '5xl': '36px',
  '6xl': '44px',
  '7xl': '52px',
  '8xl': '96px',
}
