import React, { useEffect } from 'react'
import { InputNumber } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'

export default function Schedule() {
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const { kpi, daily_budget, bid_amount } = useStoreState((state: any) => state.campaignModule)

  const INPUTS = [
    { label: 'Bid Amount', value: bid_amount, key: 'bid_amount' },
    { label: 'KPI (CPR per day)', value: kpi, key: 'kpi' },
    { label: 'Daily_budget', value: daily_budget, key: 'daily_budget' },
  ]

  return (
    <div className="Budget">
      <div className="flex justify-between mt-6 gap-4">
        {INPUTS.map((input) => (
          <div key={input.key} className="w-full">
            <h4 className="mb-2 font-semibold text-secondary_text">{input.label}</h4>
            <InputNumber
              value={input.value}
              onChange={(value) => {
                setState({ key: input.key, value: value })
              }}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              className="w-full"
              placeholder="Input here"
            />
          </div>
        ))}
      </div>
    </div>
  )
}
