import moment from 'moment'

const REFERENCE = moment()
const TODAY = REFERENCE.clone().startOf('day')
const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day')
const A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day')

const isToday = (momentDate) => {
  return momentDate.isSame(TODAY, 'd')
}
const isYesterday = (momentDate) => {
  return momentDate.isSame(YESTERDAY, 'd')
}
const isWithinAWeek = (momentDate) => {
  return momentDate.isAfter(A_WEEK_OLD)
}
const isTwoWeeksOrMore = (momentDate) => {
  return !isWithinAWeek(momentDate)
}
const isWhithinAYear = (momentDate) => {
  return momentDate.isSame(moment(), 'year')
}

const minusHour = (datetime) => {
  const duration = moment.duration(moment().diff(datetime))
  return Math.round(duration.asHours())
}

export const humanDate = (datetime) => {
  const momentDate = moment(datetime)
  if (isToday(momentDate)) return minusHour(momentDate) + ' giờ trước'
  else if (isYesterday(momentDate)) return 'Hôm qua'
  else if (isWithinAWeek(momentDate)) return 'Tuần trước'
  else if (isWhithinAYear(momentDate)) return momentDate.format('DD/MM')
  return momentDate.format('DD/MM/YYYY')
}
