import * as yup from 'yup'

export const fbPostSchema = yup.object().shape({
  // image_url: yup.string().required('Image is required'),
  link_to_page: yup.string().required('Link is required').nullable(true),
  primary_text: yup.string().required('Primary Text is required').nullable(true),
  utm_campaign: yup.string().required('UTM Campaign budget is required').nullable(true),
  utm_medium: yup.string().required('UTM Medium is required').nullable(true),
  utm_source: yup.string().required('UTM Source is required'),
  // page_id: yup.string().required('Page is required').nullable(true),
})

export const fbClickLinksSchema = yup.object().shape({
  // image_url: yup.string().required('Image is required'),
  link_to_page: yup.string().required('Link is required').nullable(true),
  // call_to_action: yup.string().required('Message is required').nullable(true),
  utm_campaign: yup.string().required('UTM Campaign budget is required').nullable(true),
  utm_medium: yup.string().required('UTM Medium is required').nullable(true),
  utm_source: yup.string().required('UTM Source is required'),
  // page_id: yup.string().required('Page is required').nullable(true),
})

export const webPushSchema = yup.object().shape({
  image: yup.string().required('Image is required'),
  title: yup.string().required('Title is required'),
  body: yup.string().required('body is required'),
  // link: yup.string().required('Link is required'),
  utm_campaign: yup.string().required('UTM Campaign budget is required'),
  utm_medium: yup.string().required('UTM Medium is required'),
  utm_source: yup.string().required('UTM Source is required'),
  // page_id: yup.string().required('Page is required').nullable(true),
})

export const kaipassSchema = yup.object().shape({
  image: yup.string().required('Image is required'),
  title: yup.string().required('Title is required'),
  content: yup.string().required('Content is required'),
  btn_name: yup.string().required('Button name is required'),
  // link: yup.string().required('Link is required'),
  utm_campaign: yup.string().required('UTM Campaign budget is required'),
  utm_medium: yup.string().required('UTM Medium is required'),
  utm_source: yup.string().required('UTM Source is required'),
  // page_id: yup.string().required('Page is required').nullable(true),
})
