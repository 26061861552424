import { action, thunk, computed } from 'easy-peasy'
import axios from 'Utils/axios'
import { FB_PLACEMENT_CODES } from 'Utils/constants'
import { findObjectsFromKeys } from 'Utils'
const initState = {
  channels: [],
  placement: null,
  selectedPlacementIds: [],
  selectedPlacementCode: [],
  data: [],
  loading: false,
}

export default {
  ...initState,
  resetState: action((state) => ({ ...initState })),
  setPlacement: action((state, placement) => {
    state.placement = placement
  }),
  addPlacements: action((state, { placementIds }) => {
    state.selectedPlacementIds = placementIds
    state.selectedPlacementCode = findObjectsFromKeys(placementIds, state.data, 'id').map(
      (plm) => plm.code
    )
  }),
  isFBCampaign: computed((state) =>
    state.selectedPlacementCode
      ? FB_PLACEMENT_CODES.includes(state?.selectedPlacementCode[0])
      : false
  ),
  toggleLoading: action((state) => {
    state.loading = !state.loading
  }),
  setChannels: action((state, { channels }) => (state.channels = channels)),
  setPlacements: action((state, { placements }) => {
    state.data = placements
  }),
  fetchChannels: thunk(async (actions, payload) => {
    const resp = await axios.get('/channels/channels_placements')
    if (resp.status === 200)
      actions.setChannels({
        channels: resp.data,
      })
  }),
  fetchPlacements: thunk(async (actions, payload) => {
    actions.toggleLoading()
    const resp = await axios.get('/placements/', { params: { per_page: 20 } })
    if (resp.status === 200)
      actions.setPlacements({
        placements: resp.data.results,
      })
    actions.toggleLoading()
  }),
}
