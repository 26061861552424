import React, { useRef, useMemo } from 'react'
import { Skeleton } from 'antd'
import { TextBlock, RectShape, RoundShape } from 'react-placeholder/lib/placeholders'
import 'react-placeholder/lib/reactPlaceholder.css'

import { colors } from 'DesignSystem'
import './index.scss'

export const CardPlaceholder = ({ className }: { className?: string }) => {
  return (
    <div className={`flex flex-nowrap ${className}`}>
      <RectShape
        className="m-0 mr-2 shrink-0"
        color={colors.systemColor.gray_4}
        style={{
          width: 48,
          height: 48,
        }}
      />
      <div className="shrink-0">
        <RectShape
          className="mb-1"
          color={colors.systemColor.gray_4}
          style={{
            width: 180,
            height: 20,
          }}
        />
        <RectShape
          color={colors.systemColor.gray_4}
          style={{
            width: 150,
            height: 20,
          }}
        />
      </div>
    </div>
  )
}

const CardListPlaceholder = () => {
  const renderPlaceholder = useMemo(() => {
    const rects: React.ReactNode[] = []

    for (let i = 0; i < 5; i++) {
      rects.push(<CardPlaceholder key={i} className="mb-2" />)
    }

    return <>{rects.map((rect) => rect)}</>
  }, [])

  return <div className={`w-full show-loading-animation`}>{renderPlaceholder}</div>
}

export default CardListPlaceholder
