import { action, thunk } from 'easy-peasy'
import axios from 'Utils/axios'
import { attributes_schema } from 'Components/CJPopover/NodeConfigs/constants'

const SEGMENTATION = [
  {
    segments: [],
    condition: null,
  },
]

const initState = {
  dimensionModalVisible: false,
  // 👇 should load from API
  dimensions: [],
  data: SEGMENTATION,
  currentGroup: 0,
  isFetching: false,
}

export default {
  ...initState,
  addSegment: action((state, { audience }) => {
    state.data[state.currentGroup].segments.push(audience)
    state.dimensionModalVisible = false
  }),
  setState: action((state, { key, value }) => {
    state[key] = value
  }),
  setCurrentGroup: action((state, { index }) => {
    state.currentGroup = index
  }),
  delSegment: action((state, { groupIndex, index }) => {
    state.data[groupIndex].segments.splice(index, 1)
  }),
  addSegmentGroup: action((state, { groupCondition }) => {
    state.data.push({
      segments: [],
      condition: groupCondition,
    })
  }),
  switchAudienceGroupCondition: action((state, { groupIndex, groupCondition }) => {
    state.data[groupIndex].condition = groupCondition
  }),
  delSegmentGroup: action((state, { index }) => {
    state.data.splice(index, 1)
    state.data[0].condition = null
  }),
  toggleDimensionModal: action((state, type) => {
    state.dimensionModalVisible = !state.dimensionModalVisible
  }),
  onDimensionModalCancel: action((state, type) => {
    state.dimensionModalVisible = false
  }),
  fetchDimensions: thunk(async (actions, { type }, { getState, getStoreState }) => {
    let resp

    if (type === 'check_user_attr') {
      resp = await axios.get('/customer-journeys/nodes/condition')
    }

    if (type === 'kariba') {
      resp = await axios.get('/audience-dimensions/', {
        params: {
          as_type: type,
          company: process.env.REACT_APP_COMPANY_NAME,
        },
      })
    }

    if (resp.status === 200) {
      actions.setState({ key: 'dimensions', value: resp.data })
      return
    }

    return { errors: resp.response.data }
  }),
  resetState: action((state) => ({ ...initState })),
  resetDimension: action((state) => {
    state.data = SEGMENTATION
  }),
}
