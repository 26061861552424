import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { getCustomer360Products } from 'Services/Analytics/customer-analytics'
import { getProduct360ProductsList } from 'Services/Analytics/product-analytics'

import ProductListTemplate from 'Pages/Analytics/Components/ProductList.Template'
import ProductListCombosTemplate from 'Pages/Analytics/Components/ProductList.Combos.Template'

const ListRow = () => {
  const { id } = useParams()
  return (
    <>
      <div className="bg-white p-4 col-span-1 overflow-hidden" style={{ height: 400 }}>
        <div className="mb-4 font-semibold">Frequently bought together</div>
        <div className="overflow-scroll" style={{ height: '90%' }}>
          <ProductListTemplate
            code="bought_together"
            handleDataFunction={getProduct360ProductsList}
            barcode={id}
          />
        </div>
      </div>

      <div className="bg-white p-4 col-span-1 overflow-hidden" style={{ height: 400 }}>
        <div className="mb-4 font-semibold">Similar Products</div>
        <div className="overflow-scroll" style={{ height: '90%' }}>
          <ProductListTemplate
            code="similar_products"
            handleDataFunction={getProduct360ProductsList}
            barcode={id}
          />
        </div>
      </div>

      <div className="bg-white p-4 col-span-1 overflow-hidden" style={{ height: 400 }}>
        <div className="mb-4 font-semibold">Combos having this product</div>
        <div className="overflow-scroll" style={{ height: '90%' }}>
          <ProductListCombosTemplate
            code="combos"
            handleDataFunction={getProduct360ProductsList}
            barcode={id}
          />
        </div>
      </div>
    </>
  )
}

export default ListRow
