import React from 'react'
import ChartLine from '../../Components/ChartLine'
import { getSaleChannelOverviewCharts } from 'Services/Analytics/saleChannel-analytics'

const ChartRow5 = () => {
  return (
    <>
      <div className="bg-white p-4 col-span-1">
        <div className="mb-4 font-semibold"> AOV</div>
        <div className="w-full overflow-auto">
          <ChartLine chartCode="aov" handleDataFunction={getSaleChannelOverviewCharts} />
        </div>
      </div>
      <div className="bg-white p-4 col-span-1">
        <div className="mb-4 font-semibold"> {'Item/Order (Avg)'}</div>
        <div className="w-full overflow-auto">
          <ChartLine chartCode="item_per_order" handleDataFunction={getSaleChannelOverviewCharts} />
        </div>
      </div>
    </>
  )
}

export default ChartRow5
