import React from 'react'

const ToastPreview = ({ initData, id }) => {
  const { image_url, title, description } = initData
  return (
    <div id={id} className="WelcomePushToastComponent" style={styles.ToastPreview}>
      {image_url && <img alt="brand-logo" style={styles.icon} src={image_url}></img>}
      <div style={styles.contentWrapper}>
        <p style={styles.title}>{title}</p>
        <p style={styles.content}>{description}</p>
      </div>
    </div>
  )
}

const styles = {
  ToastPreview: {
    fontFamily: 'Helvetica',
    minWidth: '250px',
    maxWidth: '350px',
    backgroundColor: 'rgba(255,255,255, 0.5)',
    backdropFilter: 'blur(10px)',
    borderRadius: '12px',
    border: '1px solid white',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    minHeight: '50px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 'fit-content',
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
  },

  icon: {
    width: '60px',
    height: '60px',
    objectFit: 'cover',
  },

  contentWrapper: {
    marginLeft: '10px',
    color: '#464646',
    // wordBreak: 'break-all',
  },

  title: {
    fontSize: '16px',
    fontWeight: 'bolder',
    margin: '0',
    marginBottom: '5px',
    textAlign: 'left',
  },

  subtitle: {
    fontSize: '12px',
    margin: '0',
    color: '#888888',
    textDecoration: 'underline',
    marginBottom: '5px',
    textAlign: 'left',
  },

  content: {
    fontSize: '12px',
    margin: '0px',
    textAlign: 'left',
  },
}

export default ToastPreview
