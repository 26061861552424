import React from 'react'
import { thousandsSeparator } from 'Utils/kFormatter'
import ChannelBadge from 'Components/ChannelBadge'
import { Empty } from 'antd'
import './index.scss'

export type ProductItemType = {
  // TCH
  price?: string
  original_price?: string
  color?: string
  base_price?: string
  category_name?: string
  description?: string
  product_name?: string
  slug?: string
  image: string
  val?: string
  quantity?: string
  channel?: string
  drr?: number
  inventory_days?: number
  product_variant_name?: string
}

const isProduct360 = window?.location?.href?.split('/')[4]?.split('?')[0] === 'product360'

const ProductItem = ({ product }: { product: ProductItemType }) => {
  return (
    <a href={product.slug} className="flex w-full text-primary_text" target="_self">
      <div className="mr-4" style={{ maxWidth: 48, height: 48 }}>
        {product.image != null ? (
          <img src={product.image} />
        ) : (
          <Empty
            className="mr-4 ProductItem-Empty"
            imageStyle={{ maxWidth: 48, height: 48 }}
          ></Empty>
        )}
      </div>
      <div>
        <div>{product.product_name || product.product_variant_name}</div>

        <div className="text-sm text-secondary_text">
          {product.channel && <ChannelBadge code={product.channel} />}

          {product.quantity && <span>Inventory: {product.quantity} • </span>}
          {isProduct360 && <span>DRR: {product.drr?.toFixed(0)} • </span>}
          {/* {isProduct360 && <span>Inventory Days: {product.inventory_days?.toFixed(0)}</span>} */}
        </div>

        <div className="flex">
          <div className="flex items-center">
            <span className="font-semibold mr-1 whitespace-nowrap">
              {thousandsSeparator(parseInt(product.price || ''))} đ
            </span>
            <span className="text-sm text-secondary_text line-through">
              {product?.original_price
                ? `${thousandsSeparator(parseInt(product?.original_price || ''))} đ`
                : null}
            </span>
          </div>
          <span className="mx-2 border-r border-secondary_text"></span>
          <div className="text-secondary_text text-sm">
            {product.color && <span>{product.color} - </span>}
            {product.val && <span>Size: {product.val}</span>}
          </div>
        </div>
      </div>
    </a>
  )
}

export default ProductItem
