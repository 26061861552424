import { Button } from 'antd'
import React, { useState } from 'react'
import './index.scss'
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
// import { COLOR_LINE_CHART, DATA_LINE_CHART, FormatDataLineChart } from '../../Constant'
import kFormatter from 'Utils/kFormatter'
type ChartLineType = {
  lineLabels: { title: string; color: string }[]
  dataSource: any[]
  type?: string
}

const ChartLine: React.FC<ChartLineType> = ({ lineLabels, dataSource, type }) => {
  const [position, setPosition] = useState<any>()
  const [positionInLine, setPositionInLine] = useState<any>()

  const getValueOfItem = (stroke: string, activePayload: any) => {
    return activePayload?.filter((item: any) => {
      if (item.stroke == stroke) {
        return item.points
      }
    })
  }

  const onMouseMove = (e: any) => {
    setPosition({
      x: e.chartX,
      y: e.chartY,
      activeLabel: e.activeLabel,
      activePayload: e.activePayload,
    })
  }

  const onMouseMoveLine = (e) => {
    const getPoint = getValueOfItem(e.stroke, position?.activePayload)
    if (getPoint) {
      const point = { ...position, points: getPoint }
      setPositionInLine(point)
    }
  }

  const renderLine = () => {
    return lineLabels.map((item, index) => {
      return (
        <Line
          key={index}
          onMouseMove={onMouseMoveLine}
          strokeWidth="3"
          type="linear"
          dataKey={item.title}
          stroke={item.color}
        />
      )
    })
  }

  const renderLegend = (props) => {
    const { payload } = props
    return (
      <div className="flex flex-wrap items-center mt-5 ">
        {payload.map((entry, index) => (
          <div className="mr-2" key={`item-${index}`}>
            <div className="flex items-center">
              <div>
                <hr style={{ width: 20, borderWidth: '2px', borderColor: entry.color }} />
              </div>
              <div className="ml-1 ">{entry.value}</div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const CustomTooltip = (props) => {
    if (props.active) {
      const { coordinate } = props
      return (
        <div className="relative text-white bg-primary_text min-w-[76px] min-h-[40px] px-2 py-1 text-center text-sm rounded-sm ">
          <div className="font-bold">{positionInLine?.activeLabel}</div>
          <div>
            {`${positionInLine?.points[0]['dataKey']} : ${positionInLine?.points[0]['value']}`}
          </div>
          {/* <div>Coordinate: {'x=' + coordinate.x + ' y=' + coordinate.y}</div>
          <div>X: {props?.position.x}</div>
          <div>Y: {props?.position.y}</div> */}
        </div>
      )
    } else {
      return <></>
    }
  }

  const formatTick = (value) => {
    switch (type) {
      case 'discount_ratio':
      case 'gross_profit_ratio':
        return `${value}%`
      default:
        return kFormatter(parseFloat(value))
    }
  }

  const renderLineChart = (
    <LineChart onMouseMove={onMouseMove} data={dataSource}>
      {renderLine()}
      <CartesianGrid stroke="#ccc" />
      <XAxis dataKey="name" />
      <YAxis tickFormatter={formatTick} />
      <Tooltip
        // position={{ x: positionInLine?.x - 40, y: positionInLine?.y + 5 }}
        content={<CustomTooltip />}
      />
      <Legend content={renderLegend} />
    </LineChart>
  )

  return (
    <div className=" LineChartReChartCustom">
      <ResponsiveContainer width="100%" height={300}>
        {renderLineChart}
      </ResponsiveContainer>
    </div>
  )
}

export default ChartLine
