import { action, thunk, Action, Thunk } from 'easy-peasy'

export interface States {
  isMenuCollapsed: boolean
}

export interface StoreProps extends States {
  setMenuCollapse: Action<StoreProps>
}

const initState = {
  isMenuCollapsed: false,
}

const store: StoreProps = {
  ...initState,
  setMenuCollapse: action((state) => {
    state.isMenuCollapsed = !state.isMenuCollapsed
  }),
}

export default store
