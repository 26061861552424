import { useState, useEffect } from 'react'
import { isNonNullExpression } from 'typescript'
import { createAxios } from 'Utils/axios'
import { searchText } from 'Utils'
import { useStoreState } from 'Hooks'
import queryString from 'query-string'

type MessageType = { content: string; id: string }
const useHooks = (selectedCampaign, selectedScenario) => {
  const customerId = useStoreState((state) => state.harasocial.currentCustomerId)
  const [campaigns, setCampaigns] = useState([])
  const [scenarios, setScenarios] = useState([])
  const [messages, setMessages] = useState<MessageType[] | null>(null)
  const [fullMessages, setFullMessages] = useState<MessageType[] | null>(null)
  const [searchStr, setSearchStr] = useState('')

  const [error, setError] = useState([])

  useEffect(() => {
    ;(async () => {
      const axios = createAxios({ response: { toCamel: true } })
      try {
        const resp = await axios.get('/store-farming/template_messages/filter')
        setCampaigns(resp.data.campaigns)
        setScenarios(resp.data.scenarios)
      } catch (error: any) {
        setError(error.response.detail)
      }
    })()
  }, [])

  useEffect(() => {
    if (!customerId) return
    ;(async () => {
      const axios = createAxios({ response: { toCamel: true } })
      try {
        const params = queryString.stringify(
          { ps_id: customerId, campaign_id: selectedCampaign, scenario_id: selectedScenario },
          { skipEmptyString: true }
        )
        const resp = await axios.get(`/store-farming/template_messages/rendered?${params}`)
        setFullMessages([...resp.data.templateMessages])
        setMessages(resp.data.templateMessages.splice(0, 3))
      } catch (error: any) {
        setError(error.response.detail)
      }
    })()
  }, [customerId, selectedCampaign, selectedScenario])

  useEffect(() => {
    if (!fullMessages) return
    const mess = fullMessages.filter((p) => {
      if (!searchStr) return true
      else if (searchText(p.content, searchStr)) return true
    })
    setMessages(mess.splice(0, 3))
  }, [searchStr])

  return { messages, campaigns, scenarios, searchStr, error, setSearchStr }
}

export default useHooks
