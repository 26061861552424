import React, { useEffect, useState, useCallback } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import axios from 'axios'
import { getDimensionCounts, getAnalyticByDimension } from 'Services/dimensions'
import { getStores } from 'Services/customer-segments'
import { formatErrors } from 'Utils'
import InsightsView from './index.view'
import { INIT_DATA, INIT_ERRORS, INIT_LOADING, ANALYTIC_TYPES } from './constants'
import './index.scss'
import { formatValueType } from '../../helpers'
import { useParams } from 'react-router-dom'
import { Data, IsLoadingData } from 'CustomerSegmentInsightsTypes'
import { Segment } from 'DimensionsFiltersTypes'
import { Empty } from 'antd'

let cancelTokenSource = axios.CancelToken.source()
export interface InsightsProps {
  showInsight: boolean
  isDone: boolean
  setIsDone: (v: boolean) => void
}

const Insights: React.FC<InsightsProps> = ({ isDone, setIsDone, showInsight }) => {
  const {
    predictive_segment,
    predefined_segment,
    criteria_groups,
    need_insights_refresh,
    store_ids,
    segment_temporary_id,
  } = useStoreState((state: any) => state.customerSegments)
  const { addDimensionsGroup, onDimensionGroupChange, setState } = useStoreActions(
    (actions: any) => actions.customerSegments
  )
  const { id } = useParams()
  const [data, setData] = useState<Data>(INIT_DATA)
  const [isLoading, setIsLoading] = useState<IsLoadingData>(INIT_LOADING)
  const [errors, setErrors] = useState(INIT_ERRORS)

  const temp_criteria_groups = criteria_groups.map((group) => {
    return {
      condition: group.condition,
      dimension: {
        condition: group.dimension.condition,
        segments: group.dimension.segments.map((segment) => {
          return {
            ...segment,
            time_operator: segment?.time_operator?.name || segment?.time_operator,
            time_value: formatValueType(segment.time_value),
          }
        }),
      },
    }
  })

  const critGroupsContainAudience = criteria_groups?.find(
    (item) =>
      item?.dimension?.segments[0]?.audience_Type === 'audience' ||
      item?.dimension?.segments[0]?.audience_Type === 'customer' ||
      item?.dimension?.segments[0]?.audience_Type === 'visitor'
  )

  const PAYLOAD = {
    store_ids: JSON.stringify(store_ids),
    predictive_segment: predictive_segment || null,
    predefined_segment: predefined_segment || null,
    dimensions: criteria_groups[0]?.dimension?.segments.length
      ? JSON.stringify(temp_criteria_groups)
      : !predictive_segment && !predefined_segment
      ? '[]'
      : null,
    segment_temporary_id: segment_temporary_id?.split('_')[0] + '_' + Date.now(),
    segmentation_type: critGroupsContainAudience?.dimension?.segments[0]?.audience_Type || null,
  }

  const onBarClick = useCallback(
    ({ type, operator, value }: Segment) => {
      const onAddSegment = ({ type, operator, value, groupIndex }) => {
        if (Array.isArray(value)) {
          value.forEach((item) =>
            onDimensionGroupChange({
              groupIndex,
              group: {
                dimension: { segments: [{ type, operator, value: item }], condition: 'AND' },
                condition: 'AND',
              },
            })
          )
          return
        }

        onDimensionGroupChange({
          groupIndex,
          group: {
            dimension: { segments: [{ type, operator, value }], condition: 'AND' },
            condition: 'AND',
          },
        })
        return
      }

      if (!criteria_groups[0]?.dimension?.segments.length) {
        onAddSegment({ type, operator, value, groupIndex: 0 })
        return
      }

      addDimensionsGroup({ condition: 'AND' })

      onAddSegment({ type, operator, value, groupIndex: criteria_groups.length })
    },
    [criteria_groups, onDimensionGroupChange, addDimensionsGroup]
  )

  const getCountCustomer = async ({ cancelTokenSource }) => {
    setIsLoading((prev) => ({
      ...prev,
      is_count_audience_loading: true,
      is_count_visitor_loading: true,
      is_count_customer_loading: true,
      is_total_audience_loading: true,
    }))

    function computeTreeCustomerPerStores(data, countCustomerByStore) {
      const children = data.map((d) => {
        if (d?.store_code) {
          const count = countCustomerByStore.find(
            (c) => parseInt(c.last_store_id) === parseInt(d.key)
          )
          return {
            title: d.title,
            counter: count?.counter || 0,
          }
        } else {
          const { children, count } = computeTreeCustomerPerStores(d.children, countCustomerByStore)
          return {
            title: d.title,
            children,
            counter: count,
          }
        }
      })
      const count = children.map((child) => child.counter).reduce((a, b) => a + b, 0)
      return { children, count }
    }

    try {
      const [resCountCustomer, resStore] = await Promise.all([
        getDimensionCounts({ data: PAYLOAD, cancelTokenSource }),
        getStores(),
      ])
      const tree_store = resStore.data.tree_store
      const countCustomerByStore = resCountCustomer?.counter_by_store || []
      const { children: count_customer_by_store } = computeTreeCustomerPerStores(
        tree_store,
        countCustomerByStore
      )

      setData((prev) => ({
        ...prev,
        count_customer: resCountCustomer?.customer || 0,
        count_customer_by_store: count_customer_by_store || [],
        total_audience: resCountCustomer?.total_audience || 0,
        count_audience: resCountCustomer?.audience || 0,
        count_visitor: resCountCustomer?.visitor || 0,
      }))
    } catch (err: any) {
      if (err?.message === 'cancel request') return
      setErrors((prev) => ({
        ...prev,
        count_audience: formatErrors(err?.response?.data),
      }))
    }
    setIsLoading((prev) => ({
      ...prev,
      is_count_audience_loading: false,
      is_count_customer_loading: false,
      is_count_visitor_loading: false,
      is_total_audience_loading: false,
    }))
    setIsDone(false)
  }

  const onFetchData = () => {
    setErrors(INIT_ERRORS)

    // cancel prev request if not finished
    cancelTokenSource.cancel('cancel request')
    //generate new cancel token
    cancelTokenSource = axios.CancelToken.source()

    //!data.total_audience && getTotalCustomer({ cancelTokenSource })
    getCountCustomer({ cancelTokenSource })

    ANALYTIC_TYPES.forEach(async (type, index) => {
      setIsLoading((prev) => ({ ...prev, [`is_${type}_loading`]: true }))
      try {
        const res = await getAnalyticByDimension({
          data: PAYLOAD,
          analytic_type: type,
          cancelTokenSource,
        })
        setData((prev) => ({ ...prev, [type]: res }))
      } catch (err: any) {
        // no error warning with canceled requests
        if (err?.message === 'cancel request') return

        setErrors((prev) => ({
          ...prev,
          [type]: formatErrors(err?.response?.data),
        }))
      }
      setIsLoading((prev) => ({ ...prev, [`is_${type}_loading`]: false }))
    })

    setState({ key: 'need_insights_refresh', value: false })
    setIsDone(false)
  }

  // const isConditionEmpty =
  //   PAYLOAD?.dimensions == '[]' &&
  //   PAYLOAD?.predefined_segment == null &&
  //   PAYLOAD?.predictive_segment == null &&
  //   PAYLOAD?.store_ids == '[]' &&
  useEffect(() => {
    if (isDone || id) {
      onFetchData()
    }
  }, [
    // need_insights_refresh,
    // segment_temporary_id,
    // PAYLOAD?.dimensions,
    // PAYLOAD?.predefined_segment,
    // PAYLOAD?.predictive_segment,
    // PAYLOAD?.store_ids,
    isDone,
    showInsight,
  ])

  return (
    <>
      {!id ? (
        !showInsight ? (
          <Empty></Empty>
        ) : (
          <InsightsView isLoading={isLoading} data={data} errors={errors} onBarClick={onBarClick} />
        )
      ) : (
        <InsightsView isLoading={isLoading} data={data} errors={errors} onBarClick={onBarClick} />
      )}
    </>
  )
}

export default Insights
