const kaipass = [
  {
    name: 'title',
    type: 'dynamic_content',
    label: 'Title',
    isRequired: true,
  },
  {
    name: 'content',
    type: 'dynamic_content',
    label: 'Content',
    isRequired: true,
  },
  {
    name: 'image',
    type: 'image',
    label: 'Image',
    isRequired: true,
  },
  {
    name: 'route',
    type: 'select',
    label: 'Route',
    options: [
      {
        code: 'news',
        name: 'News',
      },
      {
        code: 'brand-detail',
        name: 'Brand Detail',
      },
      {
        code: 'reward-detail',
        name: 'Reward Detail',
      },
      {
        code: 'web',
        name: 'Web',
      },
    ],
    isRequired: true,
  },
  {
    name: 'btn_name',
    type: 'string',
    label: 'Button Name',
    isRequired: true,
  },
  {
    name: 'url',
    type: 'string',
    label: 'Link',
    isRequired: true,
  },
  {
    name: 'utm_source',
    type: 'string',
    label: 'UTM Source',
    isRequired: true,
  },
  {
    name: 'utm_medium',
    type: 'string',
    label: 'UTM Medium',
    isRequired: true,
  },
  {
    name: 'utm_campaign',
    type: 'string',
    label: 'UTM Campaign',
    isRequired: true,
  },
]

export default kaipass
