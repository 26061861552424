// @ts-nocheck
import queryString from 'query-string'

import { COLOR_PALLETTE } from './constants'
import Fb from './Fb'
import camelCase from 'lodash/camelCase'
import isObject from 'lodash/isObject'
import transform from 'lodash/transform'
import cloneDeep from 'lodash/cloneDeep'

export { roundPathCorners } from './roundPathCorners.js'

export const convertObjectToQueryString = (obj) => {
  let str = []
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  return str.join('&')
}

export const convertQueryStringToObject = (params) => {
  return queryString.parse(params)
}

export const formatErrors = (errors) => {
  if (!errors) return 'Something went wrong'
  return Array.isArray(errors.detail)
    ? Object.assign(...errors.detail.map((v) => ({ [v['loc'][1]]: v.msg })))
    : errors.detail || errors.message || 'Something went wrong'
}

export const formatYupErrors = (error) => {
  return Object.assign(...error.inner.map((v) => ({ [v['path']]: v.message })))
}

export const generateColor = (char) => {
  const index = char.charCodeAt(0) % COLOR_PALLETTE.length
  return COLOR_PALLETTE[index]
}

export const cleanStorage = () => {
  localStorage.clear()
  sessionStorage.clear()
}

export const closeTabBrowserPrompt = () => {
  window.onbeforeunload = confirmExit
  function confirmExit() {
    return 'You have attempted to leave this page. Are you sure?'
  }
}

// convert selected array of keys to corresponding array of objects
export const findObjectsFromKeys = (keys, objects, keyNameInObj) => {
  return objects.filter((obj) => keys.indexOf(obj[keyNameInObj]) !== -1)
}

const checkConnectionFacebook = () => {
  window.FB.getLoginStatus(function (response) {
    if (response.status === 'connected') {
      // TODO
    }
  })
}

// check if 2 arrays has common values
export const hasCommonValues = (arr1, arr2) => {
  return arr1.filter((e) => arr2.includes(e)).length > 0
}

export const checkAssociationConnection = (type) => {
  if (type === 'google') return true
  else if (type === 'facebook') return checkConnectionFacebook()
}

export const computeInitValue = (schema = [], values = {}) => {
  const computeDefaultValue = (type) => {
    switch (type) {
      case 'string':
        return ''
      case 'rich_text':
        return ''
      case 'text_area':
        return ''
      case 'select':
        return ''
      case 'multi_select':
        return []
      case 'image':
        return ''
      case 'slide':
        return ''
      case 'range':
        return [10, 20]
      default:
        break
    }
  }
  return schema.reduce((obj, field) => {
    obj[field.name] = values[field.name] || computeDefaultValue(field.type)
    return obj
  }, {})
}

export const facebook = Fb

export const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const customerIdFilterTemplate = (customerIds) => ({
  clause: 'WHERE',
  comparator: customerIds,
  expressionType: 'SIMPLE',
  filterOptionName: 'filter_jchdtufm6_8kverrrpalu',
  fromFormData: true,
  operator: 'in',
  sqlExpression: null,
  subject: 'CustomerID',
})

const generatePoints = (nodeA, nodeB) => {
  let width = Math.abs(nodeA.x - nodeB.x)
  let height = Math.abs(nodeA.y - nodeB.y)
  let point_1 = { x: nodeA.x, y: nodeA.y + height / 2 }
  let point_2 = { x: nodeB.x, y: nodeA.y + height / 2 }
  return [nodeA, point_1, point_2, nodeB]
}

export const generateLineCommand = (nodes = []) => {
  return nodes.reduce((command, point, index) => {
    if (!index) return ''
    let originPoint = nodes[0]
    let points = generatePoints(originPoint, point, index === 2)
    command += points.reduce((cmd, p, i) => {
      if (i === 0) return `M${originPoint.x} ${originPoint.y} `
      return (cmd += `L${p.x} ${p.y} `)
    }, '')
    return command
  }, '')
}

let _generatedUIDs = {}
export const getUUId = () => {
  while (true) {
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
    if (!_generatedUIDs.hasOwnProperty(uuid)) {
      _generatedUIDs[uuid] = true
      return uuid
    }
  }
}

export const swapContentNode = (nodeA, nodeB) => {
  Object.keys(nodeB).forEach((key) => {
    nodeA[key] = nodeB[key]
  })
}

export const formatCJData = (obj, keysMap) => {
  return transform(obj, function (result, value, key) {
    // transform to a new object

    var currentKey = keysMap[key] || key // if the key is in keysMap use the replacement, if not use the original key
    result[currentKey] = isObject(value) ? formatCJData(value, keysMap) : value // if the key is an object run it through the inner function - replaceKeys
  })
}

export const simpleStringify = (object) => {
  var simpleObject = {}
  for (var prop in object) {
    if (!object.hasOwnProperty(prop)) {
      continue
    }
    if (typeof object[prop] == 'object') {
      continue
    }
    if (typeof object[prop] == 'function') {
      continue
    }
    simpleObject[prop] = object[prop]
  }
  return JSON.stringify(simpleObject) // returns cleaned up JSON
}

export const capitalizeFirstLetter = (string) => {
  let result = string
  result = result.replace(/_/g, ' ')
  result = result.charAt(0).toUpperCase() + result.slice(1)
  return result
}

export const hexToRgbA = (hex, opacity) => {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`
  }
  throw new Error('Bad Hex')
}

export const money = (amount, x = 3, n = 3) => {
  try {
    return Number(amount).toLocaleString('it-IT', {
      style: 'currency',
      currency: 'VND',
    })
  } catch (error) {
    return ''
  }
}

// Return new object with stringified array ?array=['item1,'item2']
export const needStringifyArray = (params) => {
  let newParams = {}
  for (let prop in params) {
    newParams[prop] = params[prop]
    if (Array.isArray(newParams[prop]) && !newParams[prop].length) {
      delete newParams[prop]
    }

    if (Array.isArray(newParams[prop])) {
      newParams[prop] = JSON.stringify(newParams[prop])
    }
  }
  return newParams
}

export const toCamel = (o) => {
  let newO, origKey, newKey, value
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        value = toCamel(value)
      }
      return value
    })
  } else {
    newO = {}
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = camelCase(origKey)
        value = o[origKey]
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = toCamel(value)
        }
        newO[newKey] = value
      }
    }
  }
  return newO
}

export const decodeJWT = (token) => {
  let rs = {}
  rs.raw = token
  rs.header = JSON.parse(window.atob(token.split('.')[0]))
  rs.payload = JSON.parse(window.atob(token.split('.')[1]))
  return rs
}

export const getHaravanLoginUrl = (source = new Date().getTime().toString()) => {
  const HARVAN_AUTH_RESPONSE_MODE = 'form_post'
  const HARVAN_AUTH_RESPONSE_TYPE = 'code id_token'
  const HARVAN_AUTH_SCOPE = 'openid profile phone email org userinfo'
  const HARVAN_AUTH_LOGIN_ROUTE = '/agents/login/haravan'
  const {
    REACT_APP_HARAVAN_LOGIN_URL,
    REACT_APP_HARASOCIAL_APP_ID,
    REACT_APP_API_HOST,
    REACT_APP_API_VERSION,
  } = process.env

  const haravanLoginURL = `${REACT_APP_HARAVAN_LOGIN_URL}?\
      response_mode=${encodeURIComponent(HARVAN_AUTH_RESPONSE_MODE)}&\
      response_type=${encodeURIComponent(HARVAN_AUTH_RESPONSE_TYPE)}&\
      scope=${encodeURIComponent(HARVAN_AUTH_SCOPE)}&\
      client_id=${REACT_APP_HARASOCIAL_APP_ID}&\
      redirect_uri=${REACT_APP_API_HOST}${REACT_APP_API_VERSION}${HARVAN_AUTH_LOGIN_ROUTE}&\
      nonce=${source}
    `.replace(/ /g, '')

  return haravanLoginURL
}

export const searchText = (text = '', query = '') => {
  return (
    text
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .search(
        query
          .toString()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
      ) !== -1
  )
}

export const numberWithCommas = (x) => {
  if (!x || isNaN(x)) return 'N/A'
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const shortName = (name) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

  let initials = [...name.matchAll(rgx)] || []

  return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()
}

export const hasPermission = (userRoles: any, moduleRoles: any) => {
  if (moduleRoles.includes('all')) return true
  return userRoles.some((userRole) => moduleRoles.includes(userRole.code))
}

export const filterQuery = (obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => v))

export const swapElement = (arr: any[], indexFrom: number, indexTo: number) => {
  const swappedItems = cloneDeep(([arr[indexTo], arr[indexFrom]] = [arr[indexFrom], arr[indexTo]]))
  arr.forEach((aV, aVIndex) => {
    if (swappedItems.indexOf(aV) === -1) swappedItems[aVIndex] = aV
  })
  return swappedItems.filter((sI) => sI != null)
}

export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + '...' : str
}
