export const MANAGER_SF_TABS = [
  {
    label: 'Tổng quan',
    key: 'overview',
    breadCrumbs: [{ title: 'Store Farming', path: '/store-farming' }],
    roles: ['admin', 'asm', 'sm', 'rd'],
  },
  {
    label: 'Chi tiết',
    key: 'detail',
    breadCrumbs: [{ title: 'Store Farming', path: '/store-farming' }],
    roles: ['admin', 'asm', 'sm', 'rd'],
  },
]
