import React from 'react'
import { connect } from 'react-redux'
import { Tag } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import classNames from 'classnames'

import './index.scss'

const SegmentTag = (props) => {
  const { data, groupIndex, index, delSegment, dimensions } = props

  const renderSegmentOperator = (type, operator) => {
    const segmentType = dimensions.find((item) => item.code === type)
    const opera = segmentType.operator.find((item) => item.code === operator)
    if (!opera) return ''
    return opera.name
  }

  const renderSegmentType = (type) => {
    return dimensions.find((item) => item.code === type).name
  }

  const renderSegmentValue = (type, dimValue) => {
    if (typeof dimValue === 'number') return Number(dimValue).toLocaleString()

    const segmentType = dimensions.find((item) => item.code === type)
    const value =
      segmentType.values?.find((item) => item.code === dimValue) ||
      segmentType.value?.find((item) => item.code === dimValue)

    return value?.name
  }

  const renderInLast = (data) => {
    if (!data.in_last) return ''
    return `in last ${data.in_last} ${data.time_format}`
  }

  const renderEverydayAt = (data) => {
    if (!data.time) return ''
    return `every day at ${data.time}`
  }

  const renderTag = (data) => {
    let result = `${renderSegmentType(data.type)} ${renderSegmentOperator(
      data.type,
      data.operator
    )} ${renderSegmentValue(data.type, data.value)} ${renderInLast(data)} ${renderEverydayAt(data)}`

    let newResult = result

    if (result.includes('= true')) {
      newResult = result.replace('= true', '')
    }

    if (result.includes('= false')) {
      newResult = result.replace('= false', '').replace(/is/i, 'Is not ')
    }

    return newResult
  }

  return (
    <Tag
      visible={true}
      closeIcon={<CloseOutlined />}
      className={classNames('SegmentTagComponent', { exclude: data.operator == 'NOT_EQUAL' })}
      closable
      onClose={() => delSegment({ groupIndex, index })}
    >
      {renderTag(data)}
    </Tag>
  )
}

export default connect(
  (state) => ({
    dimensions: state.segmentation.dimensions,
  }),
  (dispatch) => ({
    delSegment: dispatch.segmentation.delSegment,
  })
)(SegmentTag)
