import React, { useState, useEffect, useContext, createContext } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import moment from 'moment'
import { message } from 'antd'

import { formatErrors } from 'Utils'
import { getAudienceSegmentFilters } from 'Services/customer-segments'
import { DATE_FORMAT } from '../../constants'
import CustomerListView from './index.view'
export const CustomerListContext = createContext({
  isDownload: false,
  downloadOff: () => {},
  downloadOn: () => {},
})

export type Filters = {
  clc: string
  ltv: string
  cluster: string
  membership: string
  // from_date: string
  // to_date: string
  query: string
  page: number
  per_page?: number
  order_by?: string
}

const CustomerList = () => {
  const history = useHistory()
  const queryObject = queryString.parse(history.location.search)
  const [isDownload, setIsDownload] = useState(false)
  const downloadOff = () => {
    setIsDownload(false)
  }
  const downloadOn = () => {
    setIsDownload(true)
  }
  const CustomerListValues = {
    isDownload,
    downloadOff,
    downloadOn,
  }

  const [filterOptions, setFilterOptions] = useState({
    ltv: [],
    cluster: [],
    membership: [],
    clc: [],
  })

  const [filters, setFilters] = useState<Filters>({
    ltv: queryObject?.ltv?.toString() || '[]',
    cluster: queryObject?.cluster?.toString() || '[]',
    membership: queryObject?.membership?.toString() || '[]',
    clc: queryObject?.clc?.toString() || '[]',
    query: queryObject?.query?.toString() || '',
    page: parseInt(queryObject?.page?.toString() || '1'),
    per_page: parseInt(queryObject?.per_page?.toString() || '20'),
    order_by: queryObject?.order_by?.toString() || 'ltv',
    // from_date:
    //   queryObject?.from_date?.toString() || moment().subtract(7, 'days').format(DATE_FORMAT),
    // to_date: queryObject?.to_date?.toString() || moment().format(DATE_FORMAT),
  })

  useEffect(() => {
    setFilters({ ...filters, per_page: isDownload ? 1000 : 20 })
  }, [isDownload])

  const handleStatistics = async (record) => {
    window.open(`/analytics/customer360/${record.customer_id}`, '_self')
  }

  const handleTableChange = (tablePagination, _, sorter) => {
    setFilters({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.order
        ? sorter.order === 'ascend'
          ? sorter.field
          : `-${sorter.field}`
        : null,
    })
  }

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const resp = await getAudienceSegmentFilters()
        setFilterOptions(resp.data)
      } catch (err: any) {
        message.error(formatErrors(err.errors))
      }
    }
    fetchFilters()
  }, [])

  return (
    <CustomerListContext.Provider value={CustomerListValues}>
      <div className="customerList">
        <CustomerListView
          LTVList={filterOptions.ltv.map((ltv: any) => ({
            label: ltv.toString(),
            value: ltv.toString(),
          }))}
          clusterList={filterOptions.cluster.map((ltv) => ({ label: ltv, value: ltv }))}
          membershipList={filterOptions.membership.map((ltv) => ({ label: ltv, value: ltv }))}
          clcList={filterOptions.clc.map((ltv) => ({ label: ltv, value: ltv }))}
          setFilters={setFilters}
          filters={filters}
          handleTableChange={handleTableChange}
          handleStatistics={handleStatistics}
        />
      </div>
    </CustomerListContext.Provider>
  )
}

export default CustomerList
