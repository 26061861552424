import React from 'react'
import { Select } from 'antd'

import './index.scss'

const { Option } = Select

const OPTIONS = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'yearly', label: 'Yearly' },
]

type props = {
  value: string
  onChange: (value: string) => void
  isDisabled?: boolean
}

const Frequency: React.FC<props> = ({ value, onChange, isDisabled }) => {
  return (
    <Select
      disabled={isDisabled}
      className="w-full h-full rounded-md text-primary_text frequency-select"
      value={value || undefined}
      defaultValue={OPTIONS[0].value}
      onChange={(v) => onChange(v)}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {OPTIONS.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  )
}

export default Frequency
