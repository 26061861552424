import React, { useState, useEffect, useRef } from 'react'
import { Select, Checkbox, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useClickOutside, useKeyPress } from 'Hooks'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import './index.scss'

interface props {
  value: string[]
  onChange: (value: string[]) => void
  options?: { label: string; value: string }[]
  placeholder?: string
}

const MultiSelect: React.FC<props> = ({ value = [], options, placeholder, onChange }) => {
  const [isVisible, setIsVisible] = useState(false)
  const dropdownRef = useRef(null)
  const ref = useRef(null)
  const renderOptions = options?.filter((op) => op.value != null)
  useClickOutside(ref, setIsVisible, dropdownRef)
  useKeyPress('Enter', (isKeyPressed: boolean) => setIsVisible(!isKeyPressed))
  useKeyPress('Escape', (isKeyPressed: boolean) => setIsVisible(!isKeyPressed))

  const computeLabel = () => {
    const chosenLabels = value.map((item) => options?.find((op) => op.value === item)?.label)
    return (
      <div className="truncate w-full">
        {chosenLabels?.length ? (
          chosenLabels?.map((item, index) => {
            if (index > 0) {
              item = ', ' + item
            }
            return item
          })
        ) : (
          <span className="text-disabled_text">{placeholder}</span>
        )}
      </div>
    )
  }

  const MENU = (
    <div
      ref={dropdownRef}
      style={{ minHeight: 'fit-content', width: 208 }}
      className="CommonInputs-multi-select-dropdown flex flex-col bg-gray_1 p-2 customer-segments-filter-dropdown"
    >
      <Checkbox.Group
        options={renderOptions}
        value={value}
        style={{ display: 'flex', flexDirection: 'column' }}
        onChange={(values: CheckboxValueType[]) => {
          onChange(values.map((value) => value.toString()))
        }}
      />
    </div>
  )

  return (
    <Dropdown
      getPopupContainer={(trigger: any) => trigger.parentNode}
      visible={isVisible}
      trigger={['click']}
      overlay={MENU}
      className="w-full"
    >
      <div
        ref={ref}
        className="py-2 px-3 rounded cursor-pointer flex justify-between items-center border border-gray_5"
        style={{ height: 40, maxWidth: 210 }}
        onClick={() => setIsVisible(true)}
      >
        <span className="flex w-4/5">
          <span className="w-full">{computeLabel()}</span>
        </span>
        <DownOutlined className="ml-2 text-xs" />
      </div>
    </Dropdown>
  )
}

export default MultiSelect
