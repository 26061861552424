import React, { useEffect, useState } from 'react'
import { Button, Tabs, Modal, Input, Tree, Table, Tooltip } from 'antd'
import { Play as PlayIcon } from '@styled-icons/bootstrap/Play'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { SideBar } from '@styled-icons/remix-line/SideBar'
import TableHeader from 'Components/Table2.0/Components/Header'
import LoadingIcon from 'Components/LoadingIcon'
import { truncate } from 'Utils'

import SQLInput from './SQLInput'
import Result from './Result'
import {
  CATEGORIES,
  DETAIL_COLUMNS,
  detail_dataSource,
  PREVIEW_COLUMNS,
  preview_dataSource,
  SCHEMA_COLUMNS,
  schema_dataSource,
} from './constants'

const CustomSQL = ({ onSelectScreen }: { onSelectScreen?: (v: string) => void }) => {
  const { custom_sql } = useStoreState((state: any) => state.customerSegments)

  const [isRunQuery, setIsRunQuery] = useState(false)
  const [isShowPreview, setIsShowPreview] = useState(false)
  const [tabname, setTabname] = useState('')
  const [activeTab, setActiveTab] = useState('result')
  const [width, setWidth] = useState(1560)

  return (
    <div className="w-full px-8 py-3">
      {onSelectScreen && (
        <Button
          className="bg-white font-semibold px-2 flex items-center"
          onClick={() => {
            onSelectScreen('BLANK')
          }}
        >
          <SideBar style={{ width: 18, height: 18, marginRight: 10 }} />
          Use condition
        </Button>
      )}
      <div className="mt-3 grid grid-cols-4 bg-white w-full">
        <div className="col-span-1 border-r p-4">
          <h3 className="font-semibold text-lg mb-4">Tables</h3>
          {/* <div>List</div> */}
          <Input
            style={{ maxWidth: 274, height: 32, borderRadius: 'var(--br_DEFAULT)' }}
            prefix={<SearchOutlined />}
            placeholder="Search"
            className="search-bar mb-2"
            value={''}
            //onChange={(e) => search?.onSearch(e.target.value)}
          />
          <div className="bg-white left-corner-radius  relative CustomSQL-tree-wrapper">
            {/* <Tree
              treeData={CATEGORIES}
              titleRender={(item) => {
                return (
                  <div>
                    <Tooltip title={item.title} placement="right">
                      {window.screen.width < 1440 ? truncate(item.title, 20) : item.title}
                    </Tooltip>
                  </div>
                )
              }}
              onSelect={(checkedKeys) => {
                //setParams({ ...params, category: checkedKeys })
                //console.log('checked key: ', checkedKeys)
                setIsShowPreview(true)
                setTabname(checkedKeys[0]?.toString())
                setActiveTab('preview')
              }}
            /> */}
            Coming soon ...
          </div>
        </div>
        <div className="col-span-3">
          <div className="p-2 border-b w-full flex justify-end">
            <Button
              style={{ width: 40, height: 40 }}
              className="font-semibold p-0 mr-2"
              onClick={() =>
                Modal.info({
                  okText: 'Done',
                  style: { width: 500 },
                  className: 'custom-sql-guideline',
                  title: 'Query Guideline',
                  content: (
                    <div>
                      <p>
                        - Nếu segment sử dụng để gửi <span>web push</span> thì cần return column{' '}
                        <span>customer_id</span>,<span>browser_token</span>.
                      </p>
                      <p>
                        - Nếu segment sử dụng để gửi <span>app push</span> thì cần return column{' '}
                        <span>customer_id</span>,<span>app_user_id</span>.
                      </p>
                      <p>
                        - Nếu segment sử dụng để gửi <span>email</span> thì cần return column{' '}
                        <span>customer_id</span>, <span>email</span>.
                      </p>
                      <p>
                        - Recommended Table: mapping từ
                        Thecoffeehouse:kariba_production.audience_360
                      </p>
                      <p>
                        - Các câu query mà không return kết quả là do câu query không hợp lệ hoặc
                        chưa return đủ các column yêu cầu. Column{' '}
                        <span>customer_id là yêu cầu bắt buộc đối với câu query</span>
                      </p>
                    </div>
                  ),
                  onOk() {},
                })
              }
            >
              <InfoCircleOutlined style={{ fontSize: 16 }} />
            </Button>
            <Button
              loading={isRunQuery}
              disabled={!custom_sql}
              style={{ height: 40 }}
              className="font-semibold p-2"
              onClick={() => {
                setIsRunQuery(true)
                setActiveTab('result')
                setIsShowPreview(false)
              }}
            >
              <PlayIcon className="mr-2" style={{ width: 22, height: 22 }} />
              Run
            </Button>
          </div>
          <div className="border-b">
            <SQLInput />
          </div>

          <div style={{ minHeight: 300 }} className="mt-2 px-4">
            <Tabs
              defaultActiveKey="result"
              activeKey={activeTab}
              onChange={(key) => setActiveTab(key)}
            >
              {/* <Tabs.TabPane tab="Schema" key="schema">
                <Table
                  pagination={{
                    pageSizeOptions: ['5', '10', '20', '30', '50'],
                    showSizeChanger: true,
                    pageSize: 20,
                    //total: parseInt(total) || undefined,
                    current: 1,
                  }}
                  columns={SCHEMA_COLUMNS}
                  dataSource={schema_dataSource}
                  //onChange={onTableChange}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Detail" key="detail">
                <Table
                  pagination={{
                    pageSizeOptions: ['5', '10', '20', '30', '50'],
                    showSizeChanger: true,
                    pageSize: 20,
                    //total: parseInt(total) || undefined,
                    current: 1,
                  }}
                  columns={DETAIL_COLUMNS}
                  dataSource={detail_dataSource}
                  //onChange={onTableChange}
                />
              </Tabs.TabPane>
              {isShowPreview ? (
                <Tabs.TabPane tab={`Preview "${tabname?.toLocaleLowerCase()}"`} key="preview">
                  <Table
                    pagination={{
                      pageSizeOptions: ['5', '10', '20', '30', '50'],
                      showSizeChanger: true,
                      pageSize: 20,
                      //total: parseInt(total) || undefined,
                      current: 1,
                    }}
                    columns={PREVIEW_COLUMNS}
                    dataSource={preview_dataSource}
                    //onChange={onTableChange}
                  />
                </Tabs.TabPane>
              ) : ( */}
              <Tabs.TabPane tab="Result" key="result">
                <Result isRunQuery={isRunQuery} setIsRunQuery={setIsRunQuery} />
              </Tabs.TabPane>
              {/* )} */}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomSQL
