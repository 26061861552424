import React, { useRef, useEffect } from 'react'

import Legends from '../Legends'
import { COLOR_PALLETTE } from 'Utils/constants'
import drawBarChart from './draw'

const BarChart = ({
  isStacked,
  data,
  type = 'bar',
  hideLegends,
  max,
  isPercentage,
  labels = [],
}: {
  labels: any
  isStacked?: boolean
  data: { label: string; values: { [key: string]: number | string } }[]
  type?: string
  hideLegends?: boolean
  max?: number | 'auto'
  isPercentage?: boolean
}) => {
  const ref: any = useRef(null)

  useEffect(() => {
    if (!ref.current || !data || !labels?.length) return
    var ctx = ref.current.getContext('2d')
    drawBarChart({ ctx, data, labels, type, isStacked, max, isPercentage })
    return () => {
      ref.current = undefined
    }
  }, [data, isStacked, type, max, isPercentage])

  if (!data) return null

  return (
    <div className="BarChartComponent w-full h-full">
      <div style={{ height: hideLegends ? '100%' : '80%' }} className="mb-2">
        <canvas style={{ width: '100%', height: '100%' }} ref={ref} className="chart"></canvas>
      </div>
      {!hideLegends && (
        <Legends
          legends={labels?.map((legend, index) => ({
            label: legend,
            color: COLOR_PALLETTE[index],
            shape: 'rectangle',
          }))}
        ></Legends>
      )}
    </div>
  )
}

export default BarChart
