import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { COLOR_PALLETTE } from 'Utils/constants'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'

export const drawStacked = (ctx, data, type) => {
  Chart.register(ChartDataLabels)
  return new Chart(ctx, {
    type: type,
    data: {
      labels: data[0].values.map((value) => value.x).sort(),
      datasets: [
        data.map((data, index) => {
          return {
            label: data.key,
            data: sortBy(data.values, 'x', 'asc').map((value) => value.y),
            barPercentage: 1.1,
            barThickness: 'flex',
            backgroundColor: COLOR_PALLETTE[index],
          }
        }),
      ][0],
    },
    options: {
      maintainAspectRatio: false,
      // responsive: true,
      legend: {
        // display: false,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            // display: false,
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            // display: false,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  })
}

export const drawDefault = (ctx, data, type) => {
  const sortedData = orderBy(data, '[Object.keys(data)[1]]', 'desc')
  return new Chart(ctx, {
    type: type,
    data: {
      // check if value is multiple or single
      datasets: Array.isArray(sortedData[0][Object.keys(sortedData[0])[1]])
        ? // multiple bars
          sortedData[0][Object.keys(sortedData[0])[1]].map((data, index) => ({
            data: sortedData.map((data) => data[Object.keys(data)[1]][index]),
            categoryPercentage: 0.6,
            barThickness: 'flex',
            backgroundColor: COLOR_PALLETTE[index],
            minBarLength: 5,
          }))
        : // single bar
          [
            {
              data: sortedData.map((data) => data[Object.keys(data)[1]]),
              barPercentage: 1,
              barThickness: 'flex',
              backgroundColor: COLOR_PALLETTE,
              minBarLength: 5,
            },
          ],

      labels: sortedData.map((data) => data[Object.keys(data)[0]]),
    },
    options: {
      maintainAspectRatio: false,
      // responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            // display: false,
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
            },
            gridLines: {
              display: type === 'horizontalBar',
            },
          },
        ],
        yAxes: [
          {
            // display: false,
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: type === 'bar',
            },
          },
        ],
      },
    },
  })
}
