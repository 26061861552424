import React from 'react'
import { dataOptions, MOY, DOW, DOM } from './constant'
import { Card, Button, TimePicker } from 'antd'
import moment from 'moment'
import { ReactComponent as BtnDelete } from 'Assets/images/icons/IconDel.svg'
import { ReactComponent as TimeIcon } from 'Assets/images/icons/timeIcon.svg'
import { RightOutlined, PlusOutlined } from '@ant-design/icons'

type TimeDate = { fromTime: string; toTime: string }

type valueTimeDate = { key: string; data: TimeDate[] }
type valueInputDate = { key: string; data: any[] }

export type props = {
  data: valueTimeDate | valueInputDate
  keyValue: string

  handleChooseDate: ({ value, keyItem }: { value?: string; keyItem?: string }) => void
  isActive: (value: string) => true | false
  handleOnChangeFromDate: (dateString: string, index: number) => void
  handleOnChangeToDate: (dateString: string, index: number) => void
  handleRemove: (index: number) => void
  handleAddTime: () => void
}
const FrequencyBody: React.FC<props> = ({
  data,
  keyValue,
  handleChooseDate,
  isActive,
  handleOnChangeFromDate,
  handleOnChangeToDate,
  handleRemove,
  handleAddTime,
}) => {
  const renderMonthOfYear = (item, key) => {
    return (
      <div className="frequency--key flex flex-wrap p-2 items-center">
        {item.map((item, index) => (
          <div
            key={item.key}
            onClick={() => handleChooseDate({ value: item.key, keyItem: key })}
            style={{ width: 122, height: 24 }}
            className={`mt-3 mb-3 cursor-pointer text-center frequency--key__span ${
              isActive(item.key) && 'active'
            }`}
          >
            <span style={{ width: 44, height: 22, lineHeight: '22px' }} className=" px-2 py-1 ">
              {item.key}
            </span>
          </div>
        ))}
      </div>
    )
  }
  const renderDaysOfMonth = (item, key) => {
    return (
      <div className="py-2">
        <div className="flex flex-wrap items-center">
          {item.map((item, index) => (
            <div
              key={index}
              className={`day--item mt-2 mb-2 text-center cursor-pointer ${
                isActive(item.key) && 'active'
              }`}
              style={{
                width: 42,
                height: 24,
                lineHeight: '24px',
                marginRight: 2.638,
                marginLeft: 2.638,
              }}
              onClick={() => handleChooseDate({ value: (index + 1).toString(), keyItem: key })}
            >
              <span className="">{index + 1}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }
  const renderHourOfDay = (key) => {
    const format = 'HH:mm'

    return (
      <div className="px-2">
        {data.data.map((item, index) => (
          <div key={index} className="flex justify-between mt-2">
            <div className="mr-2">
              <TimePicker
                style={{ width: 164, height: 30 }}
                value={item.fromTime ? moment(`${item.fromTime}`, format) : undefined}
                onChange={(date, dateString) => handleOnChangeFromDate(dateString, index)}
                format={format}
                popupClassName="TimeInputs-hour-input disable-use-click-outside"
                suffixIcon={<TimeIcon />}
                placeholder="From"
              />
            </div>
            <div className="mr-2">
              <TimePicker
                style={{ width: 164, height: 30 }}
                value={item.toTime ? moment(`${item.toTime}`, format) : undefined}
                onChange={(date, dateString) => handleOnChangeToDate(dateString, index)}
                format={format}
                popupClassName="TimeInputs-hour-input disable-use-click-outside"
                suffixIcon={<TimeIcon />}
                placeholder="to"
              />
            </div>
            <div className="border " style={{ borderRadius: 4, padding: 6 }}>
              <BtnDelete className="cursor-pointer" onClick={() => handleRemove(index)} />
            </div>
          </div>
        ))}
        <div
          className="flex"
          style={{ marginTop: 'var(--sp_lv_3)', marginBottom: 'var(--sp_lv_3)' }}
        >
          <div
            style={{ fontWeight: 600, color: 'var(--brand_primary)' }}
            className="flex m-auto items-center cursor-pointer"
            onClick={handleAddTime}
          >
            <div style={{ marginRight: 'var(--sp_lv_7)' }}>
              <PlusOutlined />
            </div>
            <span>Add Time</span>
          </div>
        </div>
      </div>
    )
  }
  const renderElement = (key) => {
    switch (key) {
      case 'MOY':
        return renderMonthOfYear(MOY, key)
      case 'DOW':
        return renderMonthOfYear(DOW, key)
      case 'DOM':
        return renderDaysOfMonth(DOM, key)
      case 'HOD':
        return renderHourOfDay(key)
    }
  }
  return <div>{renderElement(keyValue)}</div>
}

export default FrequencyBody
