import React from 'react'
import BarChart from '../../Components/BarChart'
import { getSaleChannelOverviewCharts } from 'Services/Analytics/saleChannel-analytics'
const ChartRow2 = () => {
  return (
    <div>
      <div className="p-4 mb-4 bg-white">
        <div className="pb-4 text-base font-semibold text-primary_text text-body">
          Total Customer by Sale Channel
        </div>
        <BarChart
          chartCode="total_customer_by_sale_channel"
          handleDataFunction={getSaleChannelOverviewCharts}
        />
      </div>
    </div>
  )
}

export default ChartRow2
