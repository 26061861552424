import React, { useState, useEffect, useRef } from 'react'
import { Select, Input, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { SelectType } from './index'

export type props<T> = {
  options: {
    value: T
    label: string
    tooltip?: string
    sub_options: { value: T; label: string }[]
  }[]
  onChange: ({ option, sub_option }: { option: T; sub_option: string }) => void
  value: SelectType<string | number>
}

const DependentSelect: React.FC<props<string | number>> = ({ options, onChange, value }) => {
  const firstRun = useRef(true)
  const [option, setOption] = useState(value?.option || options[0].value || '')
  const [sub_option, setSubOption] = useState(value?.sub_option || '')

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }
    onChange({ option, sub_option })
  }, [option, sub_option])

  const sub_options_selects = options?.find((opt) => opt.value === option)?.sub_options

  return (
    <div className="flex gap-2 mb-2 items-center" style={{ height: 40 }}>
      <div className="flex">
        {options.map((item) => (
          <div
            style={{ height: 40 }}
            onClick={() => {
              setOption(item.value)
              setSubOption('')
            }}
            className={`${item.value === option ? 'toggle-selected' : ''} toggle`}
            key={item.value}
          >
            {item.label}
            {item?.tooltip && (
              <Tooltip title={item.tooltip}>
                <QuestionCircleOutlined className="ml-1 text-gray_7" />
              </Tooltip>
            )}
          </div>
        ))}
      </div>
      {option ? (
        sub_options_selects ? (
          <Select
            placeholder="Select"
            value={sub_option || undefined}
            onChange={setSubOption}
            options={sub_options_selects}
          />
        ) : (
          <Input
            placeholder="Enter..."
            className="dependent-select__input"
            value={sub_option}
            onChange={(e) => setSubOption(e.target.value)}
          />
        )
      ) : null}
    </div>
  )
}

export default DependentSelect
