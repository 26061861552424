import React, { useContext, createContext } from 'react'
import { CUSTOMER_DIMENSION, CUSTOMER_METRICS } from 'Pages/Analytics/constants'
import PivotTableTemplate from '../../../Components/PivotTable.Template'
import { CustomerPivotTypes } from 'Services/Analytics/Pivot.service'
import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'
import { Loading } from '@haravan/social-app-sdk/src/actions'
import { Spin } from 'antd'
export const PivotDownloadContext = createContext({
  toggleDownload: { isDownload: false, code: '' },
  enableDownload: () => {},
  disableDownload: () => {},
})

const PivotRow1 = () => {
  const currentPage = window?.location?.href?.split('/')[4]?.split('?')[0]
  const [toggleDownload, setToggleDownload] = React.useState({ isDownload: false, code: '' })
  const disableDownload = () => {
    setToggleDownload({ isDownload: false, code: '' })
  }
  const enableDownload = () => {
    setToggleDownload({ isDownload: true, code: '' })
  }
  const DownloadValues = {
    toggleDownload,
    enableDownload,
    disableDownload,
  }
  return (
    <PivotDownloadContext.Provider value={DownloadValues}>
      <>
        <div className="flex justify-between">
          <div className="mb-4 font-semibold">Product Insight</div>
          <div
            className="text-brand_primary cursor-pointer"
            onClick={() => {
              setToggleDownload({ isDownload: true, code: 'LTV,CLC' })
            }}
          >
            {toggleDownload?.isDownload == true ? <Spin></Spin> : 'Download All'}
          </div>
        </div>
        <div className="py-4 col-span-1">
          <div className="flex justify-between">
            <div className="mb-4 font-semibold">Popular Purchased Category and Product by LTV</div>
            {/* <div className="flex text-brand_primary">
              <div className="mr-4">
                <RefreshIcon />
              </div>
              <div>
                <DownIcon
                  className="cursor-pointer"
                  onClick={() => {
                    setToggleDownload(true)
                  }}
                />
              </div>
            </div> */}
          </div>
          <PivotTableTemplate
            hideColumnSelects
            currentPage={currentPage}
            type={CustomerPivotTypes.ProductInsight}
            isCustomer
            defaultDimensions={[
              { code: 'ltv' },
              { code: 'category' },
              { code: 'sub_category' },
              { code: 'product_name' },
              { code: 'sku' },
              { code: 'membership' },
              { code: 'image' },
            ]}
            defaultMetrics={CUSTOMER_METRICS}
          />
        </div>
        <div className="py-4 col-span-1">
          <div className="flex justify-between">
            <div className="mb-4 font-semibold">Popular Purchased Category and Product by CLC</div>
            <div className="flex text-brand_primary">
              {/* <div className="mr-4">
                <RefreshIcon />
              </div>
              <div>
                <DownIcon
                  className="cursor-pointer"
                  onClick={() => {
                    setToggleDownload(true)
                  }}
                />
              </div> */}
            </div>
          </div>
          <PivotTableTemplate
            hideColumnSelects
            currentPage={currentPage}
            isCustomer
            type={CustomerPivotTypes.ProductInsight}
            defaultDimensions={[{ code: 'life_cycle' }, ...CUSTOMER_DIMENSION]}
            defaultMetrics={CUSTOMER_METRICS}
          />
        </div>
      </>
    </PivotDownloadContext.Provider>
  )
}

export default PivotRow1
