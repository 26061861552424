import React from 'react'
import { Slider } from 'antd'

import FieldWrapper from '../FieldWrapper'

const RangeField = (props) => {
  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <Slider
          {...props}
          onAfterChange={(value) => {
            props.onChange(props.name, value)
          }}
        ></Slider>
      )}
    </FieldWrapper>
  )
}
export default RangeField
