import React from 'react'
import { BarChartOutlined } from '@ant-design/icons'

import { COLUMNS } from './constants'
import Table from 'Components/Table2.0'
import { RecordType } from 'Components/Table2.0/index.d'
import { Filters } from './TableCustomerListing'
import { useParams } from 'react-router-dom'

export type props = {
  LTVList: { label: string; value: string }[]
  clusterList: { label: string; value: string }[]
  //   userDestination: { label: string; value: string }[]
  membershipList: { label: string; value: string }[]
  setFilters: (filters: React.SetStateAction<Filters>) => void
  filters: Filters
  handleTableChange: (tablePagination: any, _: any, sorter: any) => void
  handleStatistics: (record: RecordType) => void
}

const CustomerListView: React.FC<props> = ({
  LTVList,
  clusterList,
  membershipList,
  //   userDestination,
  setFilters,
  filters,
  handleTableChange,
  handleStatistics,
}) => {
  const { id } = useParams()

  return (
    <Table
      dropdown={{
        fields: [
          {
            label: 'User Destination',
            value: 'ltv',
            options: LTVList,
            type: 'DROPDOWN',
            onClick: ({ key, value }) =>
              setFilters({ ...filters, page: 1, [key]: JSON.stringify(value) }),
          },
          {
            label: 'LTV',
            value: 'ltv',
            options: LTVList,
            type: 'DROPDOWN',
            onClick: ({ key, value }) =>
              setFilters({ ...filters, page: 1, [key]: JSON.stringify(value) }),
          },
          {
            label: 'Cluster',
            value: 'cluster',
            options: clusterList,
            type: 'DROPDOWN',
            onClick: ({ key, value }) =>
              setFilters({ ...filters, page: 1, [key]: JSON.stringify(value) }),
          },
          {
            label: 'Membership',
            value: 'membership',
            options: membershipList,
            type: 'DROPDOWN',
            onClick: ({ key, value }) =>
              setFilters({ ...filters, page: 1, [key]: JSON.stringify(value) }),
          },
        ],
        value: {
          ...filters,
          ltv: JSON.parse(filters.ltv),
          membership: JSON.parse(filters.membership),
          cluster: JSON.parse(filters.cluster),
        },
      }}
      fetchFrom={{ url: `/audience-segments/${id}/customer-profiles`, key: 'results' }}
      search={{
        keyword: filters.query,
        onSearch: (query) => {
          setFilters((prev) => ({ ...prev, query, page: 1 }))
        },
      }}
      columns={COLUMNS}
      handleTableChange={handleTableChange}
      rowActions={{
        fixed: 'right',
        actions: [
          {
            icon: <BarChartOutlined />,
            attribute: { type: 'highlight', label: 'Analytics' },
            onClick: handleStatistics,
          },
        ],
      }}
    />
  )
}

export default CustomerListView
