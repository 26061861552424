import { action, thunk } from 'easy-peasy'
import moment from 'moment'
import axios from 'Utils/axios'
import { message } from 'antd'
const initState = {
  isInit: false,
  date: [moment().startOf('month'), moment().endOf('month')],
  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
  loading: false,
  start_at: '17:00:00',
  mode: 'oneoff',
}
export default {
  ...initState,
  setDate: action((state, { date }) => {
    state.date = date
  }),
  setStartAt: action((state, { start_at }) => {
    state.start_at = start_at
  }),
  setMode: action((state, { mode }) => {
    state.mode = mode
  }),
  setDaysOfWeek: action((state, { daysOfWeek }) => {
    state.daysOfWeek = daysOfWeek
  }),
  toggleLoading: action((state) => {
    state.loading = !state.loading
  }),
  setIsInit: action((state) => {
    state.isInit = !state.isInit
    // state.startNow ? (state.date = [moment(), null]) : (state.date = null)
  }),
  checkIfCurrentTime: action((state, { startTime }) => {
    startTime == moment() ? (state.startNow = true) : (state.startNow = false)
  }),
  setSchedule: thunk(async (actions, { published }, { getState, getStoreState }) => {
    const state = getState()
    const storeState = getStoreState()
    const { campaignId } = storeState.campaign.setting
    try {
      await axios.post(`/campaigns/${campaignId}/schedule/`, {
        ...(state.mode === 'recurring'
          ? {
              is_now: state.startNow,
              days_of_week: state.daysOfWeek,
              start_at: state.start_at,
              end_time: state.date && moment(state.date[1]).format('DD-MM-YYYY HH:mm:ss'),
            }
          : {}),
        is_now: false,
        published: true,
        start_time: state.date && moment(state.date[0]).format('DD-MM-YYYY HH:mm:ss'),
        mode: state.mode,
      })
      return { ok: true }
    } catch (err) {
      return { errors: err.response.data.detail }
    }
  }),
  updateSchedule: thunk(async (actions, _, { getState, getStoreState }) => {
    const state = getState()
    const storeState = getStoreState()
    const { campaignId } = storeState.campaign.setting
    try {
      await axios.put(`/campaigns/${campaignId}/schedule/`, {
        ...(state.mode === 'recurring'
          ? {
              is_now: state.startNow,
              days_of_week: state.daysOfWeek,
              start_at: state.start_at,
              end_time: state.date && moment(state.date[1]).format('DD-MM-YYYY HH:mm:ss'),
            }
          : {}),

        start_time: state.date && moment(state.date[0]).format('DD-MM-YYYY HH:mm:ss'),
        mode: state.mode,
      })
      return { ok: true }
    } catch (err) {
      return Promise.reject({ errors: err.response.data.detail })
    }
  }),
  setPublish: thunk(async (actions, _, { getState, getStoreState }) => {
    return new Promise((resolve, reject) => {
      const storeState = getStoreState()
      const { campaignId, objective } = storeState.campaign.setting
      const { selectedAudienceId } = storeState.campaign.audience
      const { productType } = storeState.campaign.products
      axios
        .post(`/campaigns/${campaignId}/publish`, {
          mkt_obj: objective.code,
          audience_segment_id: selectedAudienceId,
          mkt_obj_attr: productType,
        })
        .then((resp) => {
          resolve({ ok: true })
        })
        .catch((err) => reject({ errors: err.response.data.detail.error_user_msg }))
    })
  }),
  resetState: action((state) => ({ ...initState })),
}
