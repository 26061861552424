import React from 'react'
import { InputNumber } from 'antd'
import './index.scss'

interface props {
  value: number | undefined
  onChange: (value: number) => void
  placeholder?: string
}
const NumberInput: React.FC<props> = ({ value, onChange, placeholder }) => {
  return (
    <InputNumber
      className="input-wrapper w-full"
      style={{ height: 40, lineHeight: '40px', opacity: '1 !important' }}
      min={0}
      max={Number.MAX_VALUE}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value: any) => value?.replace(/\$\s?|(,*)/g, '') || ''}
    />
  )
}

export default NumberInput
