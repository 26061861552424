import React, { useRef, useEffect, useState } from 'react'
import Slider from 'react-slick'
import { Empty } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { money } from 'Utils'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.scss'
const settings = {
  slidesToShow: 6,
  slidesToScroll: 1,
  infinite: true,
  responsive: [
    {
      breakpoint: 1620,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1350,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
  nextArrow: <RightOutlined />,
  prevArrow: <LeftOutlined />,
}

const ProductItem = ({ product }) => {
  const [isHover, setIsHover] = useState(false)
  const [isDiscount, setIsDiscount] = useState(false)
  let image = isHover ? product.ImageWithPerson : product.Image
  useEffect(() => {
    if (product.OriginalPrice !== product.Price) setIsDiscount(true)
  }, [product])
  return (
    <div className="ProductItemComponent">
      <img
        alt={product.Title}
        src={image}
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
      ></img>
      <div
        className="info-wrapper"
        onClick={() => {
          window.open(product.Slug, '_self').focus()
        }}
      >
        <span className="title">{product.Title}</span>
        <span className="sku">&nbsp;{product.Sku}</span>
        <span className="price">{money(product.OriginalPrice)}</span>
      </div>
    </div>
  )
}

const ProductSlider = ({ data }) => {
  const slickRef = useRef(null)

  if (!data || !data.length) return <Empty description={false}></Empty>
  return (
    <div className="ProductSliderComponent">
      <div className="product-list" ref={slickRef} {...settings}>
        {data.map((product, index) => (
          <ProductItem key={index} product={product}></ProductItem>
        ))}
      </div>
    </div>
  )
}

export default ProductSlider
