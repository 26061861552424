import React, { useState, useEffect } from 'react'

import { Select, Input } from 'antd'
const DependentSelects = ({ data, labels, onOptionChange, onSubOptionChange, values }) => {
  const renderSubOptions = (sub_options) => {
    if (!sub_options)
      return (
        <div>
          <h5>{labels.subOptionSelects[0]}</h5>
          <Input
            value={values.subOptionSelects}
            onChange={(e) => onSubOptionChange({ type: 'url', value: e.target.value })}
            style={{ width: '100%' }}
          />
        </div>
      )

    return (
      <div>
        <h5>{labels.subOptionSelects[0]}</h5>
        <Select
          value={values.subOptionSelects}
          onChange={(value) => onSubOptionChange({ type: 'subtype', value })}
          style={{ width: '100%' }}
        >
          {sub_options.map((i) => (
            <Select.Option value={i.code} key={i.code}>
              {i.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    )
  }

  if (!data) return null
  return (
    <div style={{ width: '100%' }}>
      <h5>{labels.optionSelect}</h5>
      <Select
        value={values.optionSelect}
        onChange={(e) => {
          onOptionChange(e)
        }}
        style={{ width: '100%', marginBottom: 10 }}
      >
        {data.map((item) => (
          <Select.Option key={item.type} value={item.type}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
      {renderSubOptions(data.find((item) => item.type === values.optionSelect)?.sub_options)}
    </div>
  )
}

export default DependentSelects
