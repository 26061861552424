import React, { useState } from 'react'

import ChromeIcon from 'Assets/images/chrome_icon.ico'
import { brandVars } from 'Utils/brandVars'
import './index.scss'

const WebPushPreview = ({ data }) => {
  const { url, title, image, body, actions, logo } = data
  const vars = brandVars()
  return (
    <div className="android-preview">
      <div className="header">
        <img alt="brand-logo" className="browser" src={ChromeIcon}></img>
        <span>GOOGLE CHROME</span>
      </div>
      <div className="content-wrapper">
        <div className="content">
          <p className="title">{title}</p>
          <p className="subtitle">{vars.url}</p>
          <p className="description">{body}</p>
        </div>
        <img alt="brand-logo" className="image" src={logo || ChromeIcon}></img>
      </div>

      <img alt="brand-logo" className="image" src={image || vars.logo}></img>
      {actions?.length ? (
        <div className="action-buttons-wrapper">
          {actions?.map((action) => (
            <div key={action.id} className="action-button">
              {action.title}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default WebPushPreview
