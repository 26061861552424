import React, { useState } from 'react'

import Header from 'Components/CJHeader'
import Body from './Body'
import { INIT } from 'Containers/CustomerJourneyDesign/constants'

import { CurrentType, DataType } from 'CustomerJourneyModule'

import './index.scss'

interface props {
  isRunning: boolean
  isPopoverVisible: boolean
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  currentConfig: any
  onAddNewNode: any
  parentNode: DataType
  node_attribute: boolean
  value: any
  onUpdateNode: any
}

const CJPopover: React.FC<props> = (props) => {
  const { currentConfig } = props
  const [current, setCurrent] = useState<CurrentType>(() =>
    currentConfig
      ? currentConfig
      : {
          icon: null,
          name: 'New',
          data: INIT,
          previous: null,
        }
  )

  return (
    <>
      <Header {...props} setCurrent={setCurrent} current={current} />
      <Body {...props} current={current} setCurrent={setCurrent} data={current.data} />
    </>
  )
}

export default CJPopover
