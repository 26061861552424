import React from 'react'
import { EditOutlined, Html5Outlined } from '@ant-design/icons'
import { Card, Image, message } from 'antd'
import placeholder from 'Assets/images/image_placeholder.png'
import { getEmailTemplate } from '../index.services'

const Meta = Card.Meta

export type EmailCardProps = {
  id: string
  image: string
  title: string
  emailType?: String
  className?: string
  onClick?: (email: EmailCardProps | null) => void
}

const EmailCard: React.FC<EmailCardProps> = (props: EmailCardProps) => {
  const { id, title, image, onClick } = props

  const openHTMLTemplate = async () => {
    if (id === 'string') return
    message.loading({ content: 'Loading email template...', key: 'loading-template' })
    try {
      const html = await getEmailTemplate(id)
      let wnd = window.open('about:blank', '', '_self')
      return wnd?.document.write(html)
      /* eslint-enable */
    } catch (error: any) {
      message.error({ content: 'Load email template failed', key: 'loading-template' })
    }
  }

  return (
    <Card
      hoverable
      className={`EmailCardComponent ${props.className}`}
      actions={
        id === 'string'
          ? [<EditOutlined key="edit" onClick={() => onClick && onClick(props)} />]
          : [
              <Html5Outlined key="html" onClick={openHTMLTemplate} />,
              <EditOutlined key="edit" onClick={() => onClick && onClick(props)} />,
            ]
      }
      cover={
        <Image
          alt={title}
          src={image}
          placeholder={<Image preview={false} src={placeholder} width={240} height={220} />}
        />
      }
    >
      <Meta title={title} />
    </Card>
  )
}

export default EmailCard
