import React, { useMemo } from 'react'
import { capitalize, uniqBy } from 'lodash'
import { message, Skeleton } from 'antd'

import { CampaignPivotTypes, getBQColumns } from 'Services/Analytics/Pivot.service'
import { capitalizeFirstLetter } from 'Utils'
import PivotElementList from './Components/PivotElementList'
import Table from './Components/Table'
import { PivotTableContainerProps, SelectOptions, DRAG_TYPES } from './index.constants'

import './index.scss'

const SaleChannelPivotTable = ({
  className = '',
  cate_id,
  analytic_type,
  isStore,
  store_id,
  location,
}: PivotTableContainerProps) => {
  const renderTable = useMemo(() => {
    return (
      <Table
        store_id={store_id}
        location={location}
        isStore={isStore}
        cate_id={cate_id}
        analytic_type={analytic_type}
      ></Table>
    )
  }, [cate_id])

  return (
    // <Skeleton paragraph={{ rows: 15 }} loading={isLoading} active>
    <div className={`PivotTableContainer ${className}`}>{renderTable}</div>
    // </Skeleton>
  )
}

export default SaleChannelPivotTable
