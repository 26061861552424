import React, { useMemo, useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { Button } from 'antd'
import { Database } from '@styled-icons/heroicons-outline/Database'

import Source from '../Components/Source'
import Stores, { StoreNode } from '../Components/Stores'
import { useParams } from 'react-router-dom'

// kariba
import Criteria from '../Components/Criteria'
import PredictiveSegment from '../Components/PredictiveSegment'
import Insights from '../Components/Insights'
import PredefinedSegment from '../Components/PredefinedSegment'

// facebook
import FacebookTargeting from '../Components/Facebook/Targeting'
import FacebookInsights from '../Components/Facebook/FacebookInsights'

import './index.scss'

const SegmentBody = ({ onSelectScreen }: { onSelectScreen?: (v: string) => void }) => {
  const { source, store_ids, predefined_segment, dimension_groups, criteria_groups } =
    useStoreState((state: any) => state.customerSegments)
  const { setState } = useStoreActions((action: any) => action.customerSegments)
  const [storeIds, setStoreIds] = useState<any>([])
  const [isDone, setIsDone] = useState(false)
  const [showInsight, setShowInsight] = useState(false)
  const { id } = useParams()

  const renderSourceInsights = useMemo(() => {
    if (source === 'kariba')
      return <Insights showInsight={showInsight} isDone={isDone} setIsDone={setIsDone} />

    return <FacebookInsights />
  }, [source, isDone])

  const handleStoreChange = ({ checkedNodes }: { checkedNodes: StoreNode[] }) => {
    setState({
      key: 'store_ids',
      value: checkedNodes.filter((id) => id.storeKey).map((childStore) => childStore.storeKey),
    })
    setStoreIds(checkedNodes.filter((id) => id.key).map((childStore) => childStore.key))
    setState({
      key: 'need_insights_refresh',
      value: true,
    })
  }

  return (
    <div className="w-full py-3 px-8 audience-segment-default-screen">
      {onSelectScreen && (
        <Button
          className="bg-white mb-3 font-semibold px-2 flex items-center"
          onClick={() => {
            onSelectScreen('CUSTOM_SQL')
          }}
        >
          <Database style={{ width: 18, height: 18, marginRight: 10 }} />
          Use SQL
        </Button>
      )}
      <div className="w-full grid grid-cols-3">
        <div>
          <div
            style={{ height: '60vh', overflowY: 'scroll' }}
            className="w-full segment-form mr-4 p-4 bg-white col-span-1 flex flex-col"
          >
            <h2 className="mb-2 text-lg font-semibold">Segment</h2>
            <div className="w-full  mb-4">
              <h3 className="mb-2 text-secondary_text font-semibold">User Destination</h3>
              <Source />
            </div>

            <div className="w-full  mb-4">
              <h3 className="mb-2 text-secondary_text font-semibold">Stores</h3>
              <Stores value={storeIds} onChange={handleStoreChange} />
            </div>

            {source === 'kariba' && (
              <>
                {/* <div className="mb-6">
            <h3 className="mb-2 text-secondary_text font-semibold">Predictive segments</h3>
            <PredictiveSegment />
          </div> */}
                <div className="mb-4">
                  <h3 className="mb-2 text-secondary_text font-semibold">Predefined segment</h3>
                  <PredefinedSegment />
                </div>
                <div>
                  <h3 className="mb-2 text-secondary_text font-semibold">Condition</h3>
                  <Criteria />
                </div>
              </>
            )}

            {source === 'facebook' && <FacebookTargeting />}
          </div>
          <div className="Segment-apply-btn-wrapper bg-white">
            <Button
              className="Segment-apply-btn"
              disabled={
                id
                  ? false
                  : !(
                      storeIds.length > 0 ||
                      predefined_segment ||
                      criteria_groups[0]?.dimension?.segments.length > 0
                    )
              }
              style={{ alignSelf: 'flex-end' }}
              onClick={() => {
                setIsDone(true)
                setShowInsight(true)
              }}
            >
              Apply
            </Button>
          </div>
        </div>

        <div className="insights-wrapper bg-white col-span-2 ml-4">
          <h1 className="text-primary_text text-lg p-4 font-semibold">Audience Insights</h1>
          {renderSourceInsights}
        </div>
      </div>
    </div>
  )
}

export default SegmentBody
