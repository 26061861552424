export const OUTER_SF_TABS = [
  {
    label: 'Chiến dịch',
    key: 'campaign',
    breadCrumbs: [{ title: 'Store Farming', path: '/store-farming' }],
    roles: ['admin', 'asm', 'sm', 'rd'],
  },
  {
    label: 'Thống kê',
    key: 'analytic',
    breadCrumbs: [{ title: 'Store Farming', path: '/store-farming' }],
    roles: ['all'],
  },
  {
    label: 'Quản lý',
    key: 'manage',
    breadCrumbs: [{ title: 'Store Farming', path: '/store-farming' }],
    roles: ['admin', 'asm', 'sm', 'rd'],
  },
]
