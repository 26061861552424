import { action, thunk } from 'easy-peasy'
import axios from 'Utils/axios'

const initState = {
  data: [1, 2, 3, 4, 5],
  errors: [],
  loading: false,
  selectedProducts: [],
  productType: 'slide',
  status: 'wait',
}

export default {
  ...initState,
  toggleLoading: action((state, boolean) => {
    state.loading = boolean
  }),
  setProducts: action((state, payload) => {
    state.data = payload
  }),
  fetchProducts: thunk(async (actions, { keyword }) => {
    const resp = await axios.get(`/products/search/`, {
      params: { keyword, company: process.env.REACT_APP_COMPANY_NAME },
    })
    if (resp.status === 200) actions.setProducts(resp.data)
    else actions.setProducts([])
  }),
  setSelectedProducts: action((state, products) => {
    state.selectedProducts = products
  }),
  setProductType: action((state, type) => {
    state.productType = type
  }),
  resetState: action((state) => ({ ...initState })),
}
