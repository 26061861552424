import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import HarasocialLayout from './Layout'

type DashboardRoute = {
  Component: React.FC
  path: string
  exact?: boolean
}

const DashboardRoute = ({ Component, exact, path }: DashboardRoute) => {
  // const token = localStorage.getItem('Authorization')
  // if (!token) return <Redirect to="/login" />

  return (
    <Route
      exact={exact}
      path={path}
      render={(routeProps) => (
        <HarasocialLayout>
          <Component {...routeProps} />
        </HarasocialLayout>
      )}
    />
  )
}

export default DashboardRoute
