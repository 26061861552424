import React, { useState, useEffect } from 'react'
import { createApp, actions } from '@haravan/social-app-sdk'
import { Layout } from 'antd'
import { createTypedHooks } from 'easy-peasy'

import { useHistory } from 'react-router-dom'

import { ModelTypes } from 'Models/index.types'
import LoadingIcon from 'Components/LoadingIcon'
import SyncHarawork from './SyncHarawork'

type HarasocialProduct = {
  title: string
  subtitle: string
  imageUrl: string
  productUrl: string
}

const { Content } = Layout

const typedHooks = createTypedHooks<ModelTypes>()

const HARASOCIAL_APP = createApp({
  apiKey: process.env.REACT_APP_HARASOCIAL_APP_ID || '',
  targetOrigin: process.env.REACT_APP_HARASOCIAL_TARGET_ORIGIN || '',
})

const HarasocialLayout = (props) => {
  const initApp = typedHooks.useStoreActions((actions) => actions.harasocial.initHarasocialApp)
  const setToken = typedHooks.useStoreActions((actions) => actions.harasocial.setHarasocialToken)
  const setCurrentCustomerId = typedHooks.useStoreActions(
    (actions) => actions.harasocial.setCurrentCustomerId
  )
  const history = useHistory()

  const [initHarasocial, setInitHarasocial] = useState(false)

  // const { isAuthenticated } = useLayout()
  const initActions = typedHooks.useStoreActions(
    (actions) => actions.harasocial.initHarasocialAction
  )
  const action = actions.MessageComposer.Action
  const messageComposer = actions.MessageComposer.create(HARASOCIAL_APP)

  const setHarasocialToken = async () => {
    const sessionToken = await actions.SessionToken.getSessionToken(HARASOCIAL_APP)
    setToken(sessionToken)
  }

  const harasocialToast = (toastOptions) => {
    const toastError = actions.Toast.create(HARASOCIAL_APP, toastOptions)
    toastError.dispatch(actions.Toast.Action.SHOW)
  }

  const harasocialText = (text: string) => {
    messageComposer.dispatch(action.TEXT, text)
  }

  const harasocialImage = (imageUrl: string) => {
    messageComposer.dispatch(action.IMAGE, { imageUrl: imageUrl })
  }

  const harasocialProduct = (product: HarasocialProduct) => {
    messageComposer.dispatch(action.PRODUCT, product)
  }

  useEffect(() => {
    // TODO:  participantId is required for chat func - set to model later
    let url = new URL((window as any).location)
    let participantId = url.searchParams.get('participant_id')
    setCurrentCustomerId(participantId)

    initApp(HARASOCIAL_APP)
    initActions({
      sendToast: harasocialToast,
      sendText: harasocialText,
      sendImage: harasocialImage,
      sendProduct: harasocialProduct,
    })
    setHarasocialToken()
    setInitHarasocial(true)
  }, [])

  const token = localStorage.getItem('Authorization')
  if (!token) return <SyncHarawork></SyncHarawork>

  return (
    <Layout className="HarasocialLayout">
      <Content>
        {initHarasocial ? (
          React.cloneElement(props.children, {
            harasocialToast,
            harasocialText,
            harasocialImage,
            harasocialProduct,
          })
        ) : (
          <div className="flex content-center	items-center justify-center p-5 h-screen w-screen">
            <LoadingIcon style={{ top: 'unset', width: 65 }}></LoadingIcon>
          </div>
        )}
      </Content>
    </Layout>
  )
}

export default HarasocialLayout
