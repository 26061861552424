import React from 'react'
import moment from 'moment'

import { capitalizeFirstLetter } from 'Utils'

export const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,

    align: 'left',
    render: (audience) => <div className="audience-title font-bold">{audience}</div>,
  },
  {
    title: 'Type',
    dataIndex: 'audience_type',
    width: 100,
    sorter: true,
    render: (tag) => capitalizeFirstLetter(tag),
  },
  {
    title: 'Last updated',
    dataIndex: 'updated_at',
    sorter: true,
    render: (text) => moment(text).format('DD-MM-YYYY'),
    width: 150,
  },
  {
    title: 'Used in',
    dataIndex: 'used_in',
    align: 'left',
    width: 200,
    render: (used_in) => (
      <div className="text-brand_primary font-semibold flex flex-col my-2">
        <span
          className={`${
            !used_in?.active_campaign?.length ? 'text-disabled_text pointer-events-none' : ''
          }`}
        >
          {used_in?.active_campaign?.length || 0} active campaigns
        </span>
        <span
          className={`${
            !used_in?.pass_campaign?.length ? 'text-disabled_text pointer-events-none' : ''
          }`}
        >
          {used_in?.pass_campaign?.length || 0} past campaigns
        </span>
      </div>
    ),
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
    align: 'left',
    width: 150,
    render: (frequency) => <span className="text-base">{frequency || 0} times/ week</span>,
  },
]
