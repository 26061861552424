import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import {
  getProduct360FunnelData,
  getProduct360KeyMetrics,
  getProduct360Metrics,
} from 'Services/Analytics/product-analytics'

import Info from './Info'
import KeyMetricsTemplate from '../../Components/KeyMetrics.Template'

import ChartRow1 from './ChartRow_1'
import ChartRow2 from './ChartRow_2'
import ListRow from './ListRow'
import MixRow from './MixRow'
import ChartRow3 from './ChartRow3'
import FiltersHeader from '../FiltersHeader'
import { getCustomerOverviewKeyMetrics } from 'Services/Analytics/customer-analytics'
import KeyMetrics from './KeyMetrics'
import FunnelChart from 'Components/Charts/FunnelChart'
import FunnelChartTemplate from 'Pages/Analytics/Components/FunnelChartTemplate'
// import FunnelChart from 'Components/Charts/FunnelChart'

const Product360Body = () => {
  const { id } = useParams()
  const history = useHistory()
  const [isResetting, setIsResetting] = useState(false)
  const { ref: ref1, inView: ref1InView } = useInView({ triggerOnce: true })
  const { ref: ref2, inView: ref2InView } = useInView({ triggerOnce: true })
  const { ref: ref3, inView: ref3InView } = useInView({ triggerOnce: true })
  const { ref: ref4, inView: ref4InView } = useInView({ triggerOnce: true })
  const { ref: ref5, inView: ref5InView } = useInView({ triggerOnce: true })
  const { ref: ref6, inView: ref6InView } = useInView({ triggerOnce: true })
  const { ref: ref7, inView: ref7InView } = useInView({ triggerOnce: true })

  useEffect(() => {
    setIsResetting(true)

    setTimeout(() => {
      setIsResetting(false)
    }, 10)
  }, [history.location.search])

  if (isResetting) return <div className="w-full" style={{ height: 1000 }}></div>
  return (
    <div className="Product360-body-wrapper">
      <FiltersHeader />
      <div className="bg-white mb-4">
        <Info />
      </div>
      <div
        style={{ minHeight: 200 }}
        ref={ref1}
        className="bg-white p-4 customers-analytics-overview-metrics"
      >
        <div className="mb-4 font-semibold">Key Metrics</div>
        {ref1InView && <KeyMetrics></KeyMetrics>}
      </div>
      <div style={{ minHeight: 300 }} ref={ref2} className="grid grid-cols-3 gap-4 mt-4">
        {ref2InView && <ChartRow1 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref3} className="grid grid-cols-3 gap-4 mt-4">
        {ref3InView && <ChartRow2 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref4} className="grid grid-cols-3 gap-4 mt-4">
        {ref4InView && <ListRow />}
      </div>
      <div style={{ minHeight: 600 }} ref={ref5} className="bg-white mt-4 grid grid-cols-3">
        {ref5InView && <ChartRow3 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref6} className="bg-white mt-4 grid grid-cols-3">
        {ref6InView && <MixRow />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref7} className="bg-white mt-4">
        {ref7InView && (
          <div className="bg-white p-4 col-span-3 shadow-div_b">
            <div className="mb-4 font-semibold">Audience Funnel in Online Order</div>
            <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
              <FunnelChartTemplate
                barcode={id}
                handleDataFunction={getProduct360FunnelData}
              ></FunnelChartTemplate>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Product360Body
