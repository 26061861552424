import React, { useMemo } from 'react'
import { DoubleLeftOutlined, DoubleRightOutlined, CloseOutlined } from '@ant-design/icons'
import { Divider, Layout, Typography, Tabs } from 'antd'
import moment from 'moment'
import { ANALYTIC_METRICS_CODES, ANALYTIC_CHARTS_CODES } from 'Utils/constants'

import { capitalizeFirstLetter } from 'Utils'
import { STATISTIC_DESCRIPTIONS } from './constants'
import { flattenNodes } from 'Models/customer-journey'
import KeyMetrics from 'Components/Charts/KeyMetrics'
import Table from 'Components/Table'
import AnalyticsContainer from 'Containers/Analytics/'
import { CUSTOMER_360_COLUMNS } from 'Utils/constants'

const { Title } = Typography
const { Sider } = Layout
const { TabPane } = Tabs

const CJRightSideBarView = ({
  setCJAnalyticsStates,
  CJAnalyticsStates,
  generateActionType,
  fetchAnalytics,
  filterList,
}) => {
  const {
    collapsed,
    selected_node_statistic,
    node_id,
    loading,
    node_analytics_metrics,
    node_analytics_charts,
  } = CJAnalyticsStates

  const renderComlumns = CUSTOMER_360_COLUMNS.map((column) => ({
    title: capitalizeFirstLetter(column),
    dataIndex: column,
    width: 10,
    sorter: true,
  }))

  const fetch = {
    url: `customer-journeys/nodes/${node_id}/users?type_action=${generateActionType(
      selected_node_statistic[0].label
    )}`,
    key: 'results',
  }

  const renderCustomersList = useMemo(() => {
    return <Table hideRowSelection hideSearchBar fetchFrom={fetch} tableColumns={renderComlumns} />
  }, [renderComlumns, fetch])

  return (
    <div className="CJRightSideBarComponent">
      <span
        className="sidebar-close-btn"
        onClick={() => {
          setCJAnalyticsStates({ key: 'collapsed', value: true })
          setCJAnalyticsStates({ key: 'node_id', value: '' })
        }}
      >
        <CloseOutlined />
      </span>
      <Sider collapsed={collapsed} className="sidebar-wrapper">
        <>
          {collapsed && (
            <>
              <div className={`node-statistic ${selected_node_statistic[0].label}`}>
                <KeyMetrics
                  data={selected_node_statistic}
                  fields={selected_node_statistic.map((item) => item.key)}
                  descriptions={STATISTIC_DESCRIPTIONS}
                />
              </div>
              <Divider></Divider>
            </>
          )}
          <Title className="node-name">
            {flattenNodes.find((node) => node.node_uuid == node_id).name}
          </Title>
        </>
        <div className="sidebar-content">
          <div style={{ display: !collapsed ? 'none' : 'block' }}>
            <KeyMetrics data={node_analytics_metrics} fields={ANALYTIC_METRICS_CODES} />
          </div>

          <div style={{ width: '100%', display: collapsed ? 'none' : 'block' }}>
            <Tabs defaultActiveKey="analytics">
              <TabPane tab="Analytics" key="analytics">
                <AnalyticsContainer
                  fetchAnalytics={fetchAnalytics}
                  filterList={filterList}
                  fields={{
                    metrics: ANALYTIC_METRICS_CODES,
                    charts: ANALYTIC_CHARTS_CODES,
                  }}
                  data={{ metrics: node_analytics_metrics, charts: node_analytics_charts }}
                  loading={loading}
                  defaultFilter={{
                    from_date: moment().subtract(1, 'years').format('YYYY-MM-DD'),
                    to_date: moment().format('YYYY-MM-DD'),
                  }}
                />
              </TabPane>
              <TabPane tab="Customers list" key="users-list">
                {renderCustomersList}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Sider>
      <span
        onClick={() => {
          setCJAnalyticsStates({ key: 'collapsed', value: !collapsed })
        }}
        className="sidebar-toggle-btn"
      >
        {collapsed ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
      </span>
    </div>
  )
}

export default CJRightSideBarView
