import React, { useCallback, useEffect, useState } from 'react'
import { Tree, Table, Alert, Button, message, Empty, Skeleton } from 'antd'
import TableHeader from 'Components/Table2.0/Components/Header'
import useTable from 'Components/Table2.0/Components/useTable'
import DatePicker from 'Components/DatePicker'
import { ReactComponent as IconListView } from 'Assets/images/icons/listView.svg'
import { ReactComponent as IconSort } from 'Assets/images/icons/sorter.svg'
import moment from 'moment'
import './index.scss'
import { getTreeCate } from 'Services/Analytics/saleChannel-analytics'
import SaleChannelPivotTableTemplate from '../../Components/PivotTable.Template'
import { debounce } from 'lodash'

const StoreListing = () => {
  const [view, setChangeView] = useState('grid')
  const [date, setDate] = useState<any>({
    from_date: moment().subtract(3, 'year').format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
  })
  const [treeData, setTreeData] = useState<any>([])
  const [cateId, setCateId] = useState<any>('5eaff5f2a1e2f55c6e32888a')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [keyword, setKeyword] = useState<string>('')
  const [toggle, setToggle] = useState<boolean>(true)

  const { params, setParams, data, loading, errors, total } = useTable({
    url: 'products/analytics',
    key: 'results',
  })

  const handleCateData = async (v) => {
    setIsLoading(true)
    try {
      const resp = await getTreeCate({ keyword: v })
      setTreeData(resp.data?.children)
      resp.data?.children && setIsLoading(false)
    } catch (err: any) {
      message.error('Something went wrong')
    }
  }
  useEffect(() => {
    handleCateData('')
  }, [])

  const debounceDropDown = useCallback(
    debounce((nextValue) => {
      handleCateData(nextValue)
    }, 1000),
    []
  )

  return (
    <div className="StoreListing">
      {errors && <Alert message={errors} type="error" />}
      <div className=" mb-4 flex justify-between items-center">
        <div className="StoreListing-Header">
          <TableHeader
            search={{
              keyword: keyword,
              onSearch: (v) => {
                //setParams({ ...params, query: v })
                setKeyword(v)
                debounceDropDown(v)
              },
            }}
          />
        </div>
        <div className="flex items-center">
          <div className={`iconProductListing ${view == 'list' && 'active'}`}>
            <div onClick={() => setChangeView('list')} className="p-2 rounded ml-2">
              <IconListView />
            </div>
          </div>
          <div className="StoreListing-DatePicker">
            <DatePicker
              onChange={({ from_date, to_date }) => {
                setDate({ from_date: from_date, to_date: to_date })
              }}
              value={{ from_date: date?.from_date, to_date: date?.to_date }}
            />
          </div>
        </div>
      </div>
      <div className="bg-white SaleChannelListing">
        <div>
          <div className="flex mr-1 rounded ">
            <div style={{ backgroundColor: '#f5f5f5' }}>
              <div
                className="bg-white cursor-pointer justify-center items-center"
                style={{
                  display: toggle ? 'none' : 'flex',
                  width: 24,
                  height: 40,
                  fontSize: 18,
                  transform: 'translateX(-33px)',
                  borderRadius: '0 4px 4px 0',
                  top: 255,
                  position: 'absolute',
                  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
                }}
                onClick={() => setToggle(true)}
              >
                {`>`}
              </div>
            </div>
            <div
              className="relative bg-white left-corner-radius"
              style={{ width: 304, display: toggle ? '' : 'none' }}
            >
              <div
                style={{ height: 57, background: 'var(--table_header)' }}
                className="flex p-4 text-sm border-b top-left-corner-radius text-secondary_text justify-between"
              >
                <div className="flex">
                  Category
                  <IconSort className="ml-2 cursor-pointer"></IconSort>
                </div>
                <div
                  style={{ cursor: 'pointer', fontSize: 18 }}
                  onClick={() => setToggle(false)}
                >{`<`}</div>
              </div>

              {treeData && (
                <div style={{ padding: isLoading ? 16 : 0, overflow: 'auto' }}>
                  <Skeleton loading={isLoading} active>
                    <Tree
                      treeData={treeData}
                      defaultExpandAll={true}
                      onSelect={(checkedKeys) => {
                        setCateId(checkedKeys[0])
                      }}
                    />
                  </Skeleton>
                </div>
              )}
            </div>

            <div className="flex items-center justify-between mb-4">
              {/* <div className="flex text-brand_primary">
          <div className="mr-4">
            <RefreshIcon />
          </div>
          <div>
            <DownIcon />
          </div>
        </div> */}
            </div>
            <SaleChannelPivotTableTemplate cate_id={cateId} isStore={true} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default StoreListing
