import React from 'react'
import { Select, Typography, Button } from 'antd'
import { Formik, Form } from 'formik'

const { Title } = Typography
const { Option } = Select

interface Props {
  onAddNewNode: any
  value: { channel: string }
  onUpdateNode: any
}

const Entrance: React.FC<Props> = ({ onAddNewNode, value, onUpdateNode }) => {
  const defaultValue = { channel: 'web_push' }

  const onSubmit = (values: { channel: string }) => {
    onUpdateNode && onUpdateNode(values)
  }

  return (
    <div className="ReachabilityComponent">
      <Formik
        initialValues={value ? value : defaultValue}
        onSubmit={(values) => onSubmit(values)}
        children={({ handleChange, setFieldValue, values }) => (
          <Form>
            <div className="form-item">
              <Select value={values.channel} onChange={(value) => setFieldValue('channel', value)}>
                <Option value="entrance_from_app">App Push</Option>
                <Option value="entrance_from_web">Web push</Option>
              </Select>
            </div>

            <div
              className="form-item"
              style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
            >
              <Button htmlType="submit" type="primary">
                OK
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  )
}

export default Entrance
