import React, { useState, useEffect } from 'react'
import { Empty, message, Skeleton } from 'antd'

import { createTypedHooks } from 'easy-peasy'
import { ModelTypes } from 'Models/index.types'

import { createAxios } from 'Utils/axios'
import { ProductProps } from 'ProductModule'
import TargetingItem from './TargetingItem'
import { formatErrors } from 'Utils'

type TargetingType = {
  objective: string
  action: {
    id: number
    status: boolean
    title: string
  }
  products: ProductProps[]
  links: string[]
  images: string[]
  message: string
  expandAll?: boolean
}

const typedHooks = createTypedHooks<ModelTypes>()

export default function CustomerTargeting({ expandAll = false }) {
  const customerId = typedHooks.useStoreState((state) => state.harasocial.currentCustomerId)
  const [isLoading, setIsLoading] = useState(true)
  const [tasks, setTasks] = useState<any>(null)

  useEffect(() => {
    ;(async () => {
      const axios = createAxios({ response: { toCamel: true } })
      try {
        const resp = await axios.get('/store-farming/customer-care-goal', {
          params: { ps_id: customerId },
        })
        setTasks(resp.data)
        setIsLoading(false)
      } catch (error: any) {
        message.error(formatErrors(error?.response?.data?.detail))
        setIsLoading(false)
      }
    })()
  }, [])

  const handleTaskMark = (taskId, status) => {
    const task = tasks.find((tk) => tk.taskId === taskId)
    if (status) task.statusTask = 'done'
    else task.statusTask = 'doing'
    setTasks([...tasks])
  }

  if (isLoading) return <Skeleton />
  else if (!tasks || !tasks.length)
    return <Empty description="Không có task nào cho khách hàng này"></Empty>
  return (
    <div className="CustomerTargetingList">
      {tasks.map((task) => (
        <TargetingItem
          key={task.taskId}
          expanded={expandAll}
          task={task}
          onTaskMark={handleTaskMark}
        ></TargetingItem>
      ))}
    </div>
  )
}
