import { pickBy, isEmpty } from 'lodash'
import { COLOR_PALLETTE } from 'Utils/constants'
import range from 'lodash/range'
import moment from 'moment'
const userProfileFields = [
  'customer_id',
  'tracking_id',
  'name',
  'address',
  'phone',
  'email',
  'birthday',
  'age',
  'first_order',
  'last_order',
  'last_city',
  'membership',
  'first_category',
  'first_sub_category',
  'channel_preference',
  'channel_bought',
  'customer_type',
  'customer_ids_same_device',
  'google_client_ids',
  'last_tracking_ids',
  'tracking_id',
  // 'purchased_categories',
  'last_tracking_ids',
  'customer_type',
]

const valuesFields = [
  ['ltv', 'LTV'],
  ['aov', 'AOV'],
  ['revenue', 'revenue'],
  ['bill', 'bill'],
  ['item', 'item'],
  ['avg_price', 'avg_price'],
  ['discount', 'discount'],
  ['cadence', 'cadence'],
  ['recency', 'recency'],
  ['life_cycle', 'life_cycle'],
  ['cluster', 'cluster'],
  ['order_range', 'order_range'],
  ['item_range', 'item_range'],
  ['last_source', 'last_source'],
  ['discount_rate', 'discount_rate'],
  ['spending_range_preference', 'spending_range_preference'],
  ['timeofday_preference', 'timeofday_preference'],
  ['weekday_preference', 'weekday_preference'],
  ['aov_range_preference', 'aov_range_preference'],
  ['price_range_preference', 'price_range_preference'],
  ['discount_range_preference', 'discount_range_preference'],
]

// CES: Customer Event Stats
const CES_LINES = ['revenue_online', 'revenue_offline']
const CES_BARS = ['item_online', 'item_offline']
const CES_LABELS = 'date_time'

export const CES_COLORS = {
  item_online: COLOR_PALLETTE[1],
  item_offline: COLOR_PALLETTE[2],
  revenue_online: COLOR_PALLETTE[3],
  revenue_offline: COLOR_PALLETTE[4],
}
// End CES

export const getUserProfile = (rawData) => {
  return pickBy(rawData, (value, key) => userProfileFields.includes(key))
}

export const getValues = (rawData) => {
  return valuesFields.reduce((accum, val) => {
    accum[val[1]] = rawData[val[0]]
    return accum
  }, {})
}

let sample = [
  {
    date_time: '2020-07',
    revenue_online: 627272.7272727272,
    revenue_offline: 0.0,
    item_online: 2,
    item_offline: 0,
    bill_online: 1,
    bill_offline: 0,
  },
  {
    date_time: '2020-08',
    revenue_online: 100000,
    revenue_offline: 3.0,
    item_online: 3,
    item_offline: 1,
    bill_online: 2,
    bill_offline: 1,
  },
]
const buildDataset = (accum, dataset, type, val) => {
  dataset.forEach((key) => {
    accum[type][key] = {
      key: key,
      title: key,
      values: accum[type][key] ? accum[type][key].values.concat(val[key]) : [val[key]],
    }
  })
}

const last12MonthsFromNow = () => {
  return range(1, 13).map((index) =>
    moment()
      .subtract(12 - index, 'months')
      .format('YYYY-MM')
  )
}

export const generateCustomerEventStats = (rawData = []) => {
  const last12Months = last12MonthsFromNow()
  const result = last12Months.map((month) => {
    let data = rawData.find((d) => d.date_time === month)
    return (
      data ?? {
        date_time: month,
        revenue_online: 0.0,
        revenue_offline: 0.0,
        item_online: 0,
        item_offline: 0,
        bill_online: 0,
        bill_offline: 0,
      }
    )
  })
  return result.reduce(
    (accum, val) => {
      buildDataset(accum, CES_LINES, 'lines', val)
      buildDataset(accum, CES_BARS, 'bars', val)
      accum.labels = accum.labels.concat(val[CES_LABELS])
      return accum
    },
    { lines: [], bars: [], labels: [] }
  )
}

export const generatePieChart = (rawData) => {
  const formatLabel = (rawLabel) => {
    const labelArr = rawLabel.split(/(?=[A-Z])/)
    return labelArr.join(' ')
  }

  if (!rawData) return []
  return rawData.split(',').map((item) => ({
    label: formatLabel(item.match(/.+?(?=:)/g)[0]),
    value: item.match(/[^:]*$/g)[0],
  }))
}

export const convertLabelStyleAndFunctional = (data) => {
  return data.map((d) => {
    let index = 0
    d.label = d.label.replace(/(_)/g, (char) => {
      let c
      if (index) c = ' to '
      else c = ' from '
      index++
      return c
    })
    return d
  })
}
