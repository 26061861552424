import React, { useState, useRef, useEffect } from 'react'
import { Dropdown, DatePicker as DatePickerComponent } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import moment from 'moment'
import clsx from 'clsx'

import { useClickOutside, useKeyPress } from 'Hooks'
import { renderRangeOptions } from './constants'

type props = {
  value: { from_date: string; to_date: string }
  onChange: ({ from_date, to_date }: { from_date?: string; to_date?: string }) => void
  format?: string
  displayFormat?: string
}

const DatePicker: React.FC<props> = ({
  value,
  onChange,
  format = 'YYYY-MM-DD',
  displayFormat = 'DD-MM-YYYY',
}) => {
  const DATES = {
    from_date: value.from_date ? moment(value.from_date, format) : moment().subtract(7, 'days'),
    to_date: value.to_date ? moment(value.to_date, format) : moment(),
  }

  const dropdownRef = useRef(null)
  const ref = useRef(null)

  const [isVisible, setIsVisible] = useState(false)

  useClickOutside(ref, setIsVisible, dropdownRef)
  useKeyPress('Enter', (isKeyPressed: boolean) => setIsVisible(!isKeyPressed))
  useKeyPress('Escape', (isKeyPressed: boolean) => setIsVisible(!isKeyPressed))

  const disabledDateToDate = (valuePicker) => {
    return (
      (valuePicker && valuePicker < moment(value.from_date, format)) ||
      (valuePicker && valuePicker > Date.now())
    )
  }

  const disabledDateFromDate = (valuePicker) => {
    return valuePicker && valuePicker > moment(value.to_date, format)
  }

  const DROPDOWN = (
    <div
      ref={dropdownRef}
      className="flex flex-col bg-gray_1 p-2 shadow-bot_1"
      style={{ minWidth: 300 }}
    >
      <div className="flex gap-2">
        <DatePickerComponent
          onChange={(date, dateString) =>
            onChange({ ...value, from_date: moment(dateString, displayFormat).format(format) })
          }
          format={displayFormat}
          allowClear={false}
          value={DATES.from_date}
          dropdownClassName="disable-use-click-outside"
          placeholder="From date"
          disabledDate={disabledDateFromDate}
          getPopupContainer={(trigger: any) => trigger.parentNode}
        />
        <DatePickerComponent
          onChange={(date, dateString) =>
            onChange({ ...value, to_date: moment(dateString, displayFormat).format(format) })
          }
          allowClear={false}
          value={DATES.to_date}
          format={displayFormat}
          dropdownClassName="disable-use-click-outside"
          placeholder="To date"
          disabledDate={disabledDateToDate}
          getPopupContainer={(trigger: any) => trigger.parentNode}
        />
      </div>
      <div className="pt-2">
        {renderRangeOptions(format).map((option) => (
          <div
            key={option.label}
            onClick={() => {
              onChange({ from_date: option.value.from_date, to_date: option.value.to_date })
              setIsVisible(false)
            }}
            className={clsx(
              'hover:bg-hover_overlay hover:text-brand_primary py-1 px-3 mb-1 cursor-pointer rounded',
              option.value.from_date === value.from_date &&
                option.value.to_date === value.to_date &&
                'font-semibold text-brand_primary'
            )}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <div
      ref={ref}
      className="bg-gray_1 border-gray_5 transition hover:border-brand_primary border cursor-pointer relative"
      style={{ minWidth: 250, height: 40, borderRadius: 4 }}
    >
      <Dropdown
        visible={isVisible}
        trigger={['click']}
        overlay={DROPDOWN}
        getPopupContainer={(trigger: any) => trigger.parentNode}
      >
        <div
          onClick={() => setIsVisible(!isVisible)}
          className="py-2 px-4 flex justify-between items-center"
        >
          <span className="flex text-primary_text">
            {DATES.from_date.format('MMM D, YYYY')} - {DATES.to_date.format('MMM D, YYYY')}
          </span>
          <DownOutlined />
        </div>
      </Dropdown>
    </div>
  )
}

export default DatePicker
