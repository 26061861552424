import React from 'react'
import { Tooltip } from 'antd'

import './index.scss'

const PROGRESS_BAR_SAMPLE = [
  {
    value: '10',
    unit: 'percent',
    title: 'KH tạo ra doanh thu',
    state: 1,
  },
  {
    value: '25',
    unit: 'percent',
    title: 'KH đã chăm sóc',
    state: 2,
  },
  {
    value: '60',
    unit: 'percent',
    title: 'KH được phân bổ',
    state: 3,
  },
]

const ProgressContainer = ({ data }) => (
  <div className="ProgressContainer">
    <div className="progress-bar">
      <span className="progress-bar__title text-sm">KH tạo ra doanh thu</span>
      <div className="progress-bar__bar-wrapper">
        <span className="progress-bar__bar-wrapper__bar" style={{ width: `${data[0]?.value}%` }}>
          <span className="progress-bar__bar-wrapper__bar__value">{data[0]?.value}</span>
        </span>
      </div>
    </div>
    <div className="progress-bar">
      <span className="progress-bar__title text-sm">KH đã chăm sóc</span>
      <div className="progress-bar__bar-wrapper --state-2">
        <span
          className="progress-bar__bar-wrapper__bar --state-2"
          style={{ width: `${data[1]?.value}%` }}
        >
          <span className="progress-bar__bar-wrapper__bar__value">{data[1]?.value}</span>
        </span>
      </div>
    </div>
    <div className="progress-bar">
      <span className="progress-bar__title text-sm">KH được phân bổ</span>
      <Tooltip
        title={
          <>
            <p>KH được phân bổ: {data[2]?.value}</p>
            <p>KH được chưa chăm sóc: {data[2]?.subvalue}</p>
          </>
        }
        overlayStyle={{ whiteSpace: 'pre-line' }}
      >
        <div className="progress-bar__bar-wrapper --state-3">
          <span
            className="progress-bar__bar-wrapper__bar --state-3"
            style={{ width: `${data[2]?.value / data[2]?.subvalue}%` }}
          ></span>
          <span className="progress-bar__bar-wrapper__value --state-1">{data[2]?.subvalue}</span>
        </div>
      </Tooltip>
    </div>
  </div>
)

const TasksReport = ({ className, data }) => {
  return (
    <div className={`TasksReportComponent ${className}`}>
      <ProgressContainer data={data}></ProgressContainer>
    </div>
  )
}

TasksReport.defaultProps = {
  className: '',
}

export default TasksReport
