import React from 'react'
import { connect } from 'react-redux'
import imagePlaceholder from 'Assets/images/image_placeholder.png'
import { template } from './JunoTemplateString'
interface Props {
  previewData: {
    image_item: string
    image_item_1: string
    image_item_2: string
    image_item_url: string
    image_item_1_url: string
    image_item_2_url: string
  }
}
const JunoTemplate: React.FC<Props> = ({ previewData }) => {
  const {
    image_item,
    image_item_1,
    image_item_2,
    image_item_url,
    image_item_1_url,
    image_item_2_url,
  } = previewData

  const itemImages = [
    {
      image: image_item,
      url: image_item_url,
    },
    {
      image: image_item_1,
      url: image_item_1_url,
    },
    {
      image: image_item_2,
      url: image_item_2_url,
    },
  ]

  return (
    <div
      className="JunoTemplateComponent ml-6"
      dangerouslySetInnerHTML={{ __html: template(itemImages, imagePlaceholder) }}
    ></div>
  )
}

export default connect(
  (state: any) => ({
    previewData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
  })
)(JunoTemplate)
