import React from 'react'
import { Tag, Tooltip } from 'antd'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'

import './index.scss'

const StatusTag = ({ status, description, loading, onClick, color }) => {
  // if (loading)
  //   return (

  //   )
  return (
    <Tooltip placement="right" title={description}>
      <Tag
        disabled={loading}
        onClick={onClick}
        style={{ textAlign: 'center' }}
        className={classNames('StatusTagComponent p-2', { [color]: color, clickable: onClick })}
        key={status}
      >
        {loading ? (
          <LoadingOutlined
            className="status-tag-loading-icon"
            style={{ fontSize: 12, padding: '0 10px' }}
            spin
          />
        ) : (
          status.toUpperCase()
        )}
      </Tag>
    </Tooltip>
  )
}

export default StatusTag
