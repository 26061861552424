import React from 'react'
import { CUSTOMER_DIMENSION, CUSTOMER_METRICS } from 'Pages/Analytics/constants'
import PivotTableTemplate from '../../../Components/PivotTable.Template'
import { CustomerPivotTypes, ProductPivotTypes } from 'Services/Analytics/Pivot.service'
import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'

const PivotRow_4 = () => {
  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <div className="font-semibold">Product Sale</div>
        {/* <div className="flex text-brand_primary">
          <div className="mr-4">
            <RefreshIcon />
          </div>
          <div>
            <DownIcon />
          </div>
        </div> */}
      </div>
      <PivotTableTemplate
        currentPage={window?.location?.href?.split('/')[4]?.split('?')[0]}
        type={ProductPivotTypes.ProductSales}
        defaultDimensions={[
          { code: 'category' },
          { code: 'sub_category' },
          { code: 'product_name' },
          { code: 'sku' },
          { code: 'image' },
        ]}
        defaultMetrics={[
          { code: 'revenue', aggregate: 'sum' },
          { code: 'original_id', aggregate: 'count' },
        ]}
        defaultColumns={[{ code: 'status' }]}
      />
    </div>
  )
}

export default PivotRow_4
