import React, { useMemo } from 'react'
import { useStoreState } from 'easy-peasy'
import { StoreProps } from 'Models/dimensions'
import { Segment } from 'DimensionsFiltersTypes'

// import { flattenChannelChildren, flattenCustomerSegments } from '../helpers'
import { flattenChannelChildren, flattenCustomerSegments } from '../helpers'

export type props = {
  segment: Segment
  className?: string
}

const OPERATORS = {
  EQUAL: '=',
  IN_THE_FOLLOWING: 'in the following',
}

const DimensionInterfaceLabel: React.FC<props> = ({ segment, className }) => {
  const { type, operator, value, time_operator, time_value, name } = segment
  const { customerGroup, channelGroup, campaignGroup } = useStoreState(
    (state: { dimensions: StoreProps }) => state.dimensions
  )

  const renderSegmentLabel = useMemo(() => {
    if (type === 'SALE_CHANNEL') {
      // const computeChannelLabel = (value: string) => {
      //   const arr = value.split(',')
      //   const channelChildren = channelGroup ? flattenChannelChildren(channelGroup) : []
      //   const labels = arr.map(
      //     (item) => ` ${channelChildren?.find((campaignItem) => campaignItem.key === item)?.title}`
      //   )

      //   if (labels.length > 2) {
      //     const temp = labels.slice(0, 2)
      //     return `${temp.toString()} + ${(labels.length - 2).toString()} others`
      //   }
      //   return labels.toString()
      // }
      let channelLabelValue = segment?.value.split(',')
      let channelLabel = ''
      if (channelLabelValue.length > 2) {
        const temp = channelLabelValue?.slice(0, 2)
        channelLabel = `${temp.toString()} + ${(channelLabelValue?.length - 2).toString()} others`
      }

      return `Channel ${OPERATORS[operator]} ${
        channelLabelValue?.length <= 2 ? channelLabelValue?.toString() : channelLabel
      }`
    }

    // if (type === 'CAMPAIGN') {
    //   const computeCampaignLabel = (value: string) => {
    //     const arr = value.split(',')
    //     const labels = arr.map(
    //       (item) => ` ${campaignGroup?.find((campaignItem) => campaignItem.value === item)?.label}`
    //     )

    //     if (labels.length > 2) {
    //       const temp = labels.slice(0, 2)
    //       return `${temp.toString()} + ${(labels.length - 2).toString()} others`
    //     }

    //     return labels.toString()
    //   }
    //   return `Campaign ${OPERATORS[operator]} ${computeCampaignLabel(value.toString())}`
    // }

    if (type === 'SEGMENT') {
      const segments = customerGroup?.length ? flattenCustomerSegments(customerGroup) : []
      const currentSegment = segments.find((segment) => segment.code === type)

      const valueLabel = name?.toString()?.includes(',')
        ? name
            ?.toString()
            ?.split(',')
            ?.map(
              (item) =>
                currentSegment?.values.find((v) => v.code === item)?.name ||
                Number(item).toLocaleString()
            )
            .join(', ')
        : currentSegment?.values.find((v) => v.code === name)?.name ||
          name ||
          Number(name).toLocaleString()

      return ` ${valueLabel || name}`
    }

    const segments = customerGroup?.length ? flattenCustomerSegments(customerGroup) : []
    const currentSegment = segments.find((segment) => segment.code === type)

    const operatorLabel =
      currentSegment?.operator.find((opr) => opr.code === operator)?.name || operator

    const valueLabel = value?.toString()?.includes(',')
      ? value
          ?.toString()
          ?.split(',')
          ?.map(
            (item) =>
              currentSegment?.values.find((v) => v.code === item)?.name ||
              Number(item).toLocaleString()
          )
          .join(', ')
      : currentSegment?.values.find((v) => v.code === value)?.name ||
        value ||
        Number(value).toLocaleString()

    const fortmatValueLabel = value?.toString()?.split(',').join(', ')

    const timeOperatorLabel =
      currentSegment?.time_operator?.find((opr) => opr.code === time_operator?.name)?.name ||
      time_operator
    return `${currentSegment?.name || type} ${operatorLabel}  ${
      segment?.type == 'GENDER' || segment?.type == 'PHONE' ? valueLabel : fortmatValueLabel
    } ${timeOperatorLabel || ''}  ${time_value || ''}`
  }, [customerGroup, channelGroup, campaignGroup, type, operator, value, time_operator, time_value])

  return <div className={className}>{renderSegmentLabel}</div>
}

export default DimensionInterfaceLabel
