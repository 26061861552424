import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { getAudienceSegmentFilters } from 'Services/customer-segments'
import { formatErrors } from 'Utils'
import { message } from 'antd'
import CustomerListView from './index.view'
export type Filters = {
  ltv: string
  cluster: string
  membership: string
  // from_date: string
  // to_date: string
  query: string
  page: number
  per_page?: number
  order_by?: string
  user_destination?: string
}
const TableCustomerListing = () => {
  const history = useHistory()
  const queryObject = queryString.parse(history.location.search)

  const [filterOptions, setFilterOptions] = useState({
    ltv: [],
    cluster: [],
    membership: [],
  })

  const [filters, setFilters] = useState<Filters>({
    ltv: queryObject?.ltv?.toString() || '[]',
    cluster: queryObject?.cluster?.toString() || '[]',
    membership: queryObject?.membership?.toString() || '[]',
    query: queryObject?.query?.toString() || '',
    page: parseInt(queryObject?.page?.toString() || '1'),
    per_page: parseInt(queryObject?.per_page?.toString() || '20'),
    order_by: queryObject?.order_by?.toString() || 'ltv',
    // from_date:
    //   queryObject?.from_date?.toString() || moment().subtract(7, 'days').format(DATE_FORMAT),
    // to_date: queryObject?.to_date?.toString() || moment().format(DATE_FORMAT),
  })

  const handleStatistics = async (record) => {
    window.open(`/analytics/customer360/${record.customer_id}`, '_self')
  }
  const handleTableChange = (tablePagination, _, sorter) => {
    setFilters({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.order
        ? sorter.order === 'ascend'
          ? sorter.field
          : `-${sorter.field}`
        : null,
    })
  }
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const resp = await getAudienceSegmentFilters()
        setFilterOptions(resp.data)
      } catch (err: any) {
        message.error(formatErrors(err.errors))
      }
    }
    fetchFilters()
  }, [])
  return (
    <div className="customerList">
      <CustomerListView
        LTVList={filterOptions.ltv.map((ltv: any) => ({
          label: ltv.toString(),
          value: ltv.toString(),
        }))}
        clusterList={filterOptions.cluster.map((ltv) => ({ label: ltv, value: ltv }))}
        membershipList={filterOptions.membership.map((ltv) => ({ label: ltv, value: ltv }))}
        setFilters={setFilters}
        filters={filters}
        handleTableChange={handleTableChange}
        handleStatistics={handleStatistics}
      />
    </div>
  )
}
export default TableCustomerListing
