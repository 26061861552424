import React, { useEffect, useMemo, useState } from 'react'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Card, Empty, Skeleton } from 'antd'
import axios from 'axios'
import './index.scss'
import { FiltersType } from 'AnalyticsTypes'
import { ReactComponent as Map } from 'Assets/images/icons/map.svg'
import { ReactComponent as Location } from 'Assets/images/icons/location.svg'
import useFilters from 'Pages/Analytics/Components/FiltersHeader/useFilters'

const Store360Info = ({
  handleDataFunction,
}: {
  handleDataFunction: (filters: FiltersType) => Promise<any>
}) => {
  const history = useHistory()
  const { id } = useParams()
  const { filters: defaultFilters } = useFilters()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [data, setData] = useState<any>()

  const queryObject = queryString.parse(history.location.search)

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()

    const handleInfo = async () => {
      setIsLoading(true)
      try {
        setError('')
        const resp = await handleDataFunction({
          store_id: id,
          from_date: queryObject?.from_date?.toString() || defaultFilters.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters.to_date,
          dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
          cancelTokenSource,
        })
        setData(resp.data)
      } catch (err: any) {
        if (err.message === 'cancelled') {
          return
        }

        setError(err?.response?.data?.detail || 'Some thing went wrong')
      }
      setIsLoading(false)
    }

    handleInfo()
    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [id, queryObject.from_date, queryObject.to_date, handleDataFunction, queryObject.dimensions])

  const openSearch = (keyword) => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(keyword)}`,
      '_self'
    )
  }

  const renderCard = useMemo(() => {
    if (!data) return <Empty />
    return (
      <Card style={{ minHeight: 160 }} className="Store360-body-info">
        <div className="Store360-body-info-upper flex justify-between pb-4">
          <div className="Store360-body-info-left">
            <div className="Store360-title font-semibold text-2xl mb-2">{data?.store_name}</div>
            <div className="Store360-location">
              <div className="flex items-center mb-2">
                <Location />{' '}
                <div className="Store360-text-fade ml-2 ">
                  {data?.region} • {data?.district} • {data?.city}
                </div>
              </div>
              <div className="flex items-center">
                <Map />{' '}
                <a
                  className="underline ml-2"
                  onClick={() =>
                    openSearch(
                      `${data?.address}, ${data?.ward}, ${data?.district}, ${data?.province}`
                    )
                  }
                >
                  {data?.address}, {data?.ward}, {data?.district}, {data?.province}
                </a>
              </div>
            </div>
          </div>
          <div className="Store360-body-info-right text-base">
            <div className="flex">
              <div className="mr-2">Store Management Name: </div>
              <div className="Store360-text-fade">{data?.manager_name}</div>
            </div>
            <div className="flex">
              <div className="mr-2">No.of Employee:</div>
              <div className="Store360-text-fade">{data?.no_of_employee}</div>
            </div>
          </div>
          <div
            className="Store360-body-info-ranking flex flex-col items-center p-4 rounded"
            style={{ backgroundColor: '#E6F7FF' }}
          >
            <div
              className="Store360-body-ranking-value font-semibold text-xl"
              style={{ color: '#096DD9' }}
            >
              #{data?.net_revenue_ranking}
            </div>
            <div className="Store360-body-ranking-label text-sm Store360-text-fade">by Revenue</div>
          </div>
        </div>
        <div
          className="Store360-body-info-lower flex justify-between gap-0 p-2 rounded"
          style={{
            backgroundColor: '#FAFAFA',
          }}
        >
          {data?.ranks?.map((item, index) => {
            return (
              <div
                className="Store360-ranking-item flex items-center flex-col p-4 justify-between"
                style={{
                  backgroundColor: '#FAFAFA',
                  width: '-webkit-fill-available',
                  borderRight: '1px solid #E3E9ED',
                }}
              >
                <div className="Store360-ranking-item-name text-sm Store360-text-fade">
                  {item.display_name}
                </div>
                <div className="Store360-ranking-item-value text-2xl font-semibold pt-2">
                  # {item.value}
                </div>
              </div>
            )
          })}
        </div>
      </Card>
    )
  }, [data])
  if (error) return <Alert message={error} type="error" />

  return (
    <Skeleton loading={isLoading} active className="p-4">
      <div>{renderCard}</div>
    </Skeleton>
  )
}

export default Store360Info
