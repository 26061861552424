import moment from 'moment'

// export const DATE_FORMAT = 'DD-MM-YYYY'

export const renderRangeOptions = (format: string) => [
  {
    value: { from_date: moment().format(format), to_date: moment().format(format) },
    label: 'Today',
  },
  {
    value: {
      from_date: moment().subtract(7, 'days').format(format),
      to_date: moment().format(format),
    },
    label: 'This Week',
  },
  {
    value: {
      from_date: moment().subtract(14, 'days').format(format),
      to_date: moment().subtract(7, 'days').format(format),
    },
    label: 'Last Week',
  },
  {
    value: {
      from_date: moment().subtract(1, 'months').format(format),
      to_date: moment().subtract(0, 'months').format(format),
    },
    label: 'Last Month',
  },
  {
    value: {
      from_date: moment().subtract(3, 'months').format(format),
      to_date: moment().subtract(0, 'months').format(format),
    },
    label: 'Last 3 Months',
  },
  {
    value: {
      from_date: moment().subtract(1, 'year').format(format),
      to_date: moment().format(format),
    },
    label: 'This Year',
  },
  // {
  //   value: {
  //     from_date: moment().subtract(1, 'year').format(format),
  //     to_date: moment().format(format),
  //   },
  //   label: 'Lifetime',
  // },
]
