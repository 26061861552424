import React from 'react'
import { Typography, Tooltip, Skeleton } from 'antd'
import numeral from 'numeral'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { capitalizeFirstLetter } from 'Utils'
import kFormatter from 'Utils/kFormatter'

// import '../index.scss'

const Text = Typography.Text
const MetricItem = ({ loading, title, data, format, description }) => {
  return (
    <div className="KeyMetricItemComponent">
      <Skeleton loading={loading} paragraph={{ rows: 1 }} active>
        <Text className="item-title" level={3}>
          {capitalizeFirstLetter(title)}
          {description && (
            <Tooltip title={description}>
              <QuestionCircleOutlined />
            </Tooltip>
          )}
        </Text>
        <Tooltip title={numeral(data).format(format)} className="item-data" level={3}>
          {kFormatter(data)}
        </Tooltip>
      </Skeleton>
    </div>
  )
}
export default MetricItem
