import React from 'react'
import { useState, useEffect } from 'react'
import { AutoComplete, Spin } from 'antd'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'

import { useHistory } from 'react-router-dom'
import axios from 'Utils/axios'

import { useDebounce } from 'Hooks'
import './index.scss'

const { Option } = AutoComplete

const SearchCustomers = () => {
  const history = useHistory()
  const [results, setResults] = useState([])
  const [input, setInput] = useState()
  const [isSearching, setIsSearching] = useState(false)

  const debounceSearch = useDebounce(input, 1000)

  const onSearch = (value) => {
    setInput(value)
  }

  const onSelect = (value, option) => {
    history.push(`/customers/${option.key}/customer360`)
  }

  const handleSearch = async (input) => {
    setIsSearching(true)
    try {
      const options = await axios.get(`/customer-profiles/`, {
        params: {
          page: 1,
          per_page: 20,
          query: input,
        },
      })
      setResults(options.data?.results?.length ? options.data.results : undefined)
    } catch (err) {}
    setIsSearching(false)
  }

  useEffect(() => {
    handleSearch(input)
  }, [debounceSearch])

  return (
    <div className="SearchCustomersComponent">
      <AutoComplete
        placeholder="Search customers..."
        onSearch={onSearch}
        onSelect={onSelect}
        notFoundContent={!results ? 'No customer found' : undefined}
      >
        {/* <Input.Search /> */}
        {results?.map((customer, index) => (
          <Option key={customer.customer_id} value={customer.name}>
            {customer.name}
          </Option>
        ))}
      </AutoComplete>
      {isSearching ? (
        <Spin indicator={<LoadingOutlined />} className="searching-icon" />
      ) : (
        <SearchOutlined className="searching-icon" />
      )}
    </div>
  )
}

export default SearchCustomers
