import React, { useRef, useEffect } from 'react'
import { Table } from 'antd'
const TableKeyMetric = ({ dataSource, columns }) => {
  const tableChart = useRef(null)
  useEffect(() => {
    if (!tableChart.current) return
    // console.log('tableChart.current', tableChart?.current)
    const elWrapTable = document.querySelector('.Wrap_Table')
    const elHeader = elWrapTable && elWrapTable.querySelector('thead tr th')
    if (elHeader) {
      elHeader.classList.add('border-r')
    }
    const elBody = document.querySelectorAll('.Wrap_Table tbody tr ')
    elBody.forEach((item, index) => {
      const el = item.querySelector('td')
      if (el) {
        el.classList.add('border-r')
        el.classList.add('font-semibold')
        el.classList.add('text-secondary_text')
      }
    })
  }, [tableChart])

  return (
    <div ref={tableChart}>
      <Table
        className="Wrap_Table"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        // size="large"
        scroll={{ x: 'max-content' }}
      />
    </div>
  )
}

export default TableKeyMetric
