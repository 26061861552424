import React, { useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import './index.scss'
type ChartBarType = {
  lineLabels: { title: string; color: string }[]
  dataSource: any[]
}
const BarChartTemplate: React.FC<ChartBarType> = ({ lineLabels, dataSource }) => {
  const [valueBar, setValueBar] = useState<any>()
  const onMouseMove = (e: any) => {
    setValueBar(e.tooltipPayload)
  }
  const onMouseLeave = (e: any) => {
    // console.log('onMouseLeave', e)
  }
  const onMouseOut = (e: any) => {
    // console.log('onMouseOut', e)
  }
  const renderBarChart = () => {
    return lineLabels.map((item, index) => {
      return (
        <Bar
          key={index}
          onMouseOut={onMouseOut}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
          dataKey={item.title}
          fill={item.color}
        />
      )
    })
  }
  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && valueBar) {
      const [value] = valueBar
      const { name } = value
      return (
        <div className="custom-tooltip min-w-[100px] min-h-[44px] bg-primary_text text-white p-2 text-sm">
          <div className="text-sm font-semibold label">{label}</div>
          <div>
            {name} : <span className="font-semibold">{value?.value}</span>
          </div>
        </div>
      )
    } else {
      setValueBar(null)
    }

    return null
  }
  return (
    <div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={dataSource}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          //   barCategoryGap={30}
          //   barGap={5}
        >
          <CartesianGrid />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Legend align="left" />
          {renderBarChart()}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default BarChartTemplate
