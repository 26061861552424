import React from 'react'
import moment from 'moment'

import { Customer360InfoType } from './index'
import MemberShipBadge from 'Components/MemberShipBadge'
import { CHANNELS } from './constants'
import Cores from 'Components/Cores'
import Slider from 'react-slick'
const HeaderInfo = ({ data }: { data: Customer360InfoType | undefined }) => {
  const REACHABLE_CHANNELS = data?.reachable_channels.map((code) => {
    return (
      CHANNELS.find((channel) => channel.value === code) || {
        label: code,
        value: code,
        icon: undefined,
      }
    )
  })

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: true,
  }

  return (
    <div>
      <div className="pb-4 pt-2 border-b flex justify-between">
        <div>
          <div className=" mb-2 flex items-center">
            <span className="text-2xl font-semibold">{data?.name}</span>
            <span className="ml-2">
              <MemberShipBadge membership={data?.membership || 'N/A'} />
            </span>
          </div>

          <div className="text-base text-secondary_text">
            LTV {data?.ltv || 'N/A'} • Life Cycle: {data?.life_cycle || 'N/A'}
            {data?.cluster && ` • ${data?.cluster}`}
          </div>
        </div>
        <div className="flex">
          <Slider {...settings}>
            {data?.score?.map((score) => {
              return <Cores value={score?.value} label={score.label}></Cores>
            })}
          </Slider>
        </div>
      </div>

      <div className="py-4 flex gap-6 border-b">
        <div className="font-semibold">Reachable Channels</div>
        <div className="flex items-center flex-wrap gap-4">
          {REACHABLE_CHANNELS?.map((channel) => (
            <div key={channel?.value} className="flex items-center">
              <div className="mr-2 flex items-center">{channel?.icon}</div>
              <span> {channel?.label}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="py-4 grid grid-cols-3 gap-6 border-b">
        <div className="col-span-1">
          <div className="font-semibold mb-2">Location & Channel Preference</div>
          <div>
            <div>
              Channel Preference:{' '}
              <span className="text-secondary_text">
                {data?.location_n_channel_preference?.channel_preference || 'N/A'}
              </span>
            </div>
            <div>
              Last Store:{' '}
              <span className="text-secondary_text">
                {data?.location_n_channel_preference?.last_store || 'N/A'}
              </span>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="font-semibold mb-2">Info</div>
          <div>
            <div>
              Customer ID:{' '}
              <span className="text-secondary_text">{data?.info?.customer_id || 'N/A'}</span>
            </div>
            {data?.info?.birthday && (
              <div>
                Birthday:{' '}
                <span className="text-secondary_text whitespace-nowrap">
                  {moment(data?.info?.birthday).format('DD/MM/YYYY') || 'N/A'} ({' '}
                  {moment().diff(moment(data?.info?.birthday), 'years')} yrs old )
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-1">
          <div className="font-semibold mb-2">History Purchased</div>
          <div>
            <div>
              First Order:{' '}
              <span className="text-secondary_text">
                {moment(data?.history_purchased?.first_order).format('DD/MM/YYYY') || 'N/A'}
              </span>
            </div>
            <div>
              Last Order:{' '}
              <span className="text-secondary_text">
                {moment(data?.history_purchased?.last_order).format('DD/MM/YYYY') || 'N/A'}
              </span>
            </div>
            <div>
              Last City:{' '}
              <span className="text-secondary_text">
                {data?.history_purchased?.last_city || 'N/A'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderInfo
