import React from 'react'
import { Empty, Skeleton } from 'antd'
import classNames from 'classnames'
import kFormatter from 'Utils/kFormatter'

const computeItemPercent = (number, max) => {
  return (number / max) * 100
}

export default function LeaderBoard({ data, myRanking }) {
  const renderMeat = () => {
    if (!data) return <Empty description={false}></Empty>
    return data.map((item, index) => (
      <div
        key={index}
        className={classNames('rank-item', {
          'my-position': myRanking.employee_code === item.employee_code,
        })}
      >
        <div className="flex items-center" style={{ width: '80%' }}>
          <div className="rank-number font-semibold">{item.rank}</div>
          <div className="w-full">
            <div
              style={{
                width: `${computeItemPercent(item.revenue, data[0].revenue)}%`,
              }}
            >
              <div className="progress-bar"></div>
            </div>

            <span className="text-default">{item.employee}</span>
          </div>
        </div>
        <div style={{ width: '30%', textAlign: 'right' }}>
          <p className="m-0 font-semibold">{kFormatter(item.revenue)}</p>
          <p className="m-0 text-disabled text-sm">{item.orders} đơn hàng</p>
        </div>
      </div>
    ))
  }
  return (
    <div className="LeaderBoardComponent py-1 px-2">
      <div className="rank-wrapper">{renderMeat()}</div>
      {/* <div
        className="show-more"
        onClick={() => setShowHowMany(showHowMany < RANKS.length ? RANKS.length : 3)}
      >
        {showHowMany < RANKS.length ? 'Show more' : 'Show less'}
      </div> */}
    </div>
  )
}
