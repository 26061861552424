import moment from 'moment'

// export const DATE_FORMAT = 'DD-MM-YYYY'
const handleMTD = (format: string) => {
  const arrDate = format.split('-')
  const formatDate = `${arrDate[0]}-${arrDate[1]}-01`
  return moment().format(formatDate)
}

export const renderRangeOptions = (format: string) => [
  {
    value: { from_date: moment().format(format), to_date: moment().format(format) },
    label: 'Today',
  },
  {
    value: {
      from_date: moment().subtract(7, 'days').format(format),
      to_date: moment().format(format),
    },
    label: 'This Week',
  },
  {
    value: {
      from_date: moment().subtract(14, 'days').format(format),
      to_date: moment().subtract(7, 'days').format(format),
    },
    label: 'Last Week',
  },
  {
    value: {
      from_date: handleMTD(format),
      to_date: moment().format(format),
    },
    label: 'Month-to-date',
  },
  {
    value: {
      from_date: moment().subtract(2, 'months').format(format),
      to_date: moment().subtract(1, 'months').format(format),
    },
    label: 'Last Month',
  },
  {
    value: {
      from_date: moment().subtract(1, 'year').format(format),
      to_date: moment().format(format),
    },
    label: 'This Year',
  },
]
