import React from 'react'
import { Tooltip } from 'antd'
import { LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { CurrentType } from 'CustomerJourneyModule'

import './index.scss'

interface props {
  current: CurrentType
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const Header: React.FC<props> = ({ current, setCurrent, setIsPopoverVisible }) => {
  const onGoBack = (prev: React.SetStateAction<CurrentType>) => {
    setIsPopoverVisible(true)
    setCurrent(prev)
  }

  const { name, previous, description } = current
  return (
    <div className="CJPopoverHeader">
      <div className="back-btn mr-2">
        {previous && <LeftOutlined onClick={() => onGoBack(previous)} />}
      </div>

      <div className="title">{name}</div>

      <div className="icon">
        {description && (
          <Tooltip title={description}>
            <QuestionCircleOutlined />
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default Header
