import React from 'react'
import { Table, Input, Button, Modal } from 'antd'
import { EditFilled, DeleteFilled } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { useDataApi } from 'Hooks'

export default React.memo(() => {
  const history = useHistory()

  const { getAudience } = useStoreActions((actions) => actions.campaign.audience)
  const { resetAllStates } = useStoreActions((actions) => actions.campaign.progress)

  const { data, loading, pagination, setPagination, doFetch, setSearchTerm } = useDataApi({
    url: '/agents/connection-profiles/facebook/ad-accounts',
    key: 'results',
  })

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sortOrder: pagination.order_by === 'product.code' ? `${pagination.order}` : null,
      width: 30,
      sorter: true,
    },
    {
      title: 'Type',
      dataIndex: 'audience_type',
      sortOrder: pagination.order_by === 'product.code' ? `${pagination.order}` : null,
      width: 20,
      sorter: true,
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'x',
      width: 40,
      render: (text, record) => actionColumn(record),
    },
  ]

  const actionColumn = (record) => {
    return (
      <div className="table-actions">
        <Button className="action-item update" type="link" onClick={() => handleEdit(record)}>
          <EditFilled />
        </Button>
        <Button
          className="action-item delete"
          type="link"
          danger
          disabled
          onClick={(e) => {
            e.stopPropagation()
            Modal.confirm({
              title: `Are you sure want to delete audience "${record.name}"`,
              onOk: () => handleDelete(record),
            })
          }}
        >
          <DeleteFilled />
        </Button>
      </div>
    )
  }

  const handleDelete = (record) => {
    // const resp = await deleteCampaign(record.id)
    // if (resp.ok) doFetch({ order_by: '', order: '' })
  }

  const handleCreate = async () => {
    await resetAllStates()
    history.push('/customers/segments/new')
  }

  const handleTableChange = (tablePagination, filters, sorter) => {
    doFetch({
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.field ? sorter.field : '',
      order: sorter.order ? sorter.order : '',
      ...filters,
    })
  }

  const handleEdit = async (record) => {
    await resetAllStates()
    await getAudience(record.id)
    history.push(`/customers/segments/${record.id}`)
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <div className="title-container">
        <Input.Search
          placeholder="Search..."
          className="search-bar"
          defaultValue={keyword}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    )
  }

  return (
    <div id="manage_label_table">
      <Table
        title={renderTitle}
        className="users-table"
        dataSource={data}
        columns={columns}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          onChange: (current) => setPagination({ current }),
        }}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        scroll={{ x: 1000 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleEdit(record),
          }
        }}
      />
      {/* <DeleteModal {...deleteModal} setDeleteModal={setDeleteModal} doFetch={doFetch} /> */}
    </div>
  )
})
