import React, { useState, useEffect } from 'react'
import { Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { COLUMNS } from './columns'
import { connect } from 'react-redux'
import Table from 'Components/Table2.0'
import AudienceFilter from 'Components/AudienceFilter/WithPopover'
import { needStringifyArray } from 'Utils'
import { useStoreActions } from 'easy-peasy'
import { BarChartOutlined } from '@ant-design/icons'
import '../index.scss'

const Customer360Table = ({ audienceAnalyticsStates, getFilters }) => {
  const history = useHistory()
  const [filters, setFilters] = useState({ name: '' })
  const [searchTerm, setSearchTerm] = useState('')
  const { setBreadCrumb } = useStoreActions((actions) => actions.breadcrumb)

  useEffect(() => {
    setBreadCrumb([{ title: 'Customers', path: '/customers/360' }])
  }, [])

  const handleC360Click = async (record) => {
    history.push(`/customers/${record.customer_id}/customer360`)
  }

  const handleStatistics = async (record) => {
    history.push(`/customers/${record.customer_id}/customer360`)
  }

  const handleTableChange = (tablePagination, _, sorter) => {
    setFilters({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.order
        ? sorter.order === 'ascend'
          ? sorter.field
          : `-${sorter.field}`
        : null,
    })
  }

  return (
    <div id="CustomerListTable" className="w-full px-8 py-6">
      <Table
        title={'Customers'}
        hideRowSelection
        search={{ keyword: searchTerm, onSearch: setSearchTerm }}
        fetchFrom={{
          url: `/customer-profiles/`,
          key: 'results',
          recordKey: 'customer_id',
        }}
        filters={{
          fields: [],
          value: filters,
        }}
        primaryButton={() => (
          <AudienceFilter
            title="Customer Filter"
            filters={filters}
            onChange={setFilters}
          ></AudienceFilter>
        )}
        columns={COLUMNS}
        handleTableChange={handleTableChange}
        rowActions={{
          onDoubleClick: handleC360Click,
          actions: [
            {
              icon: <BarChartOutlined />,
              attribute: { type: 'highlight', label: 'Performance' },
              onClick: handleStatistics,
            },
          ],
        }}
      />
    </div>
  )
}

export default connect(
  (state) => ({
    audienceAnalyticsStates: state.audienceAnalytics,
  }),
  (dispatch) => ({
    getFilters: dispatch.audienceAnalytics.getFilters,
  })
)(Customer360Table)
