import { action, thunk, Action, Thunk } from 'easy-peasy'
import { City, Interests } from 'CustomerSegmentTypes'
import { Dimension, Criteria } from 'DimensionsFiltersTypes'
export type Condition = 'AND' | 'OR' | 'EXCLUDE' | ''
export type Status = 'draft' | 'published' | ''
export interface Segment {
  type?: string
  operator?: string
  value?: string
}

export interface RequestBody {
  name: string
  audience_type: string
  store_ids: string
  description?: string
  predictive_segment: string | null
  dimensions: string
}

export interface States {
  id: number | undefined
  dimension_groups: any[]
  name: string
  source: string
  predefined_segment: string
  store_ids: number[]
  description?: string
  predictive_segment: string
  status: Status
  criteria_groups: Criteria[]
  cities: City[]
  interests: Interests[]
  genders: number[]
  age_min: number
  age_max: number
  need_insights_refresh: Boolean
  custom_sql?: string
}

export interface StoreProps extends States {
  setState: Action<StoreProps, { key: 'string'; value: any }>
  resetState: Action<StoreProps, null>
  setCriteriaGroupsDefault: Action<StoreProps, null>
  addDimensionsGroup: Action<StoreProps, { condition: 'AND' | 'OR' }>
  removeDimensionsGroup: Action<StoreProps, { groupIndex: number }>
  changeDimensionGroupCondition: Action<StoreProps, { groupIndex: number; condition: Condition }>
  onDimensionGroupChange: Action<StoreProps, { groupIndex: number; group: Criteria }>
}

const CRITERIA_GROUP: Criteria = {
  condition: '',
  dimension: {
    condition: 'AND',
    segments: [],
  },
}

const status: Status = 'draft'

const initState = {
  id: undefined,
  status: status,
  source: 'kariba',
  predictive_segment: '',
  predefined_segment: '',
  name: '',
  description: '',
  store_ids: [],
  criteria_groups: [CRITERIA_GROUP],
  dimension_groups: [],
  cities: [],
  genders: [],
  age_min: 18,
  age_max: 35,
  interests: [],
  need_insights_refresh: true,
  segment_temporary_id: '',
}

const store: StoreProps = {
  ...initState,

  setState: action((state, { key, value }) => {
    state[key] = value
  }),

  resetState: action((state) => ({ ...initState })),

  setCriteriaGroupsDefault: action((state) => {
    state.need_insights_refresh = true
    state.criteria_groups = [CRITERIA_GROUP]
  }),

  addDimensionsGroup: action((state, { condition = 'OR' }) => {
    state.need_insights_refresh = true
    state.criteria_groups = [
      ...state.criteria_groups,
      {
        dimension: {
          segments: [],
          condition: 'AND',
        },
        condition: condition,
      },
    ]
  }),

  removeDimensionsGroup: action((state, { groupIndex }) => {
    state.need_insights_refresh = true
    state.criteria_groups = state.criteria_groups.filter((g, index) => index !== groupIndex)
  }),

  changeDimensionGroupCondition: action((state, { groupIndex, condition }) => {
    state.need_insights_refresh = true
    state.criteria_groups[groupIndex].condition = condition
  }),

  onDimensionGroupChange: action((state, { groupIndex, group }) => {
    state.need_insights_refresh = true
    state.criteria_groups[groupIndex] = group
    // state.criteria_groups[groupIndex].dimension.condition = 'AND'
  }),
}

export default store
