import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import Icon from '../Icon'
import './index.scss'

export interface SearchInputProps {
  onClear: () => void
  value: string
  onChange: React.EventHandler<React.ChangeEvent<HTMLInputElement>>
  onSubmit?: () => void
  style?: React.CSSProperties
  className?: string
  placeholder?: string
  name?: string
}

export default function SearchInput({
  onChange,
  onClear,
  onSubmit = () => {},
  placeholder = 'Search',
  name = 'search',
  value,
  className,
  style = {},
}: SearchInputProps) {
  return (
    <div className={`${className} search-input-wrapper`} style={style}>
      <SearchOutlined className="search-icon" name="search" onClick={() => onSubmit()} />
      <input
        className="search-input"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmit()
          }
        }}
        onBlur={() => onSubmit()}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
      />
      {value && <Icon className="clear-icon" name="cancel-x" onClick={() => onClear()} />}
    </div>
  )
}
