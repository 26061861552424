import React from 'react'
import Cup from 'Assets/images/icons/cup.png'
import kFormatter from 'Utils/kFormatter'

export default function CurrentRanking({ myRanking, topRanking }) {
  return (
    <div className="CurrentRanking">
      <div className="cup-wrapper mr-2">
        <img src={Cup} alt="cup icon"></img>
      </div>
      {myRanking.rank === 1 ? (
        <span>
          Chúc mừng bạn đang xếp top <span className="font-semibold">1</span>
        </span>
      ) : (
        <span>
          Bạn đang đứng thứ <span className="font-semibold">{myRanking.rank}</span> trong bảng xếp
          hạng. Cần thêm{' '}
          <span className="font-semibold">
            {kFormatter(topRanking.revenue - myRanking.revenue)}
          </span>{' '}
          doanh thu để xếp vị trí thứ 1
        </span>
      )}
    </div>
  )
}
