import React from 'react'
import { QuestionCircleOutlined, StopOutlined, CheckOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import classNames from 'classnames'

const renderPrefix = (type) => {
  switch (type) {
    case 'reached':
      return <span style={{ fontWeight: 700 }}>Reached: </span>
    case 'passed':
      return <CheckOutlined />
    case 'failed':
      return <StopOutlined />
    case 'waiting':
      return <span style={{ fontWeight: 700 }}>Waiting: </span>
  }
}

const NodeStats = ({
  type,
  value,
  setCJAnalyticsStates,
  node_uuid,
  CJStates,
  CJAnalyticsStates,
}) => {
  if (!['running'].includes(CJStates.status)) return null

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        setCJAnalyticsStates({
          key: 'selected_node_statistic',
          value: [{ key: type, label: type, value: value }],
        })
        setCJAnalyticsStates({
          key: 'node_id',
          value: node_uuid,
        })
      }}
      className={classNames(`node-stats ${type}`, { disabled: CJAnalyticsStates.loading[1] })}
    >
      {renderPrefix(type)}
      {value?.toLocaleString()}
    </div>
  )
}

export default connect(
  (state) => ({
    CJStates: state.customerJourney,
    CJAnalyticsStates: state.CJAnalytics,
  }),
  (dispatch) => ({
    setCJStates: dispatch.customerJourney.setCJStates,
    setCJAnalyticsStates: dispatch.CJAnalytics.setCJAnalyticsStates,
  })
)(NodeStats)
