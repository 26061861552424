import React, { useCallback, useMemo } from 'react'
import { useStoreState } from 'easy-peasy'
import { Dimension } from 'DimensionsFiltersTypes'

import { flattenCustomerSegments } from '../helpers'

export type props = {
  group: string
  dimensions: Dimension[]
}

const DimensionsGroupsCounter: React.FC<props> = ({ group, dimensions }) => {
  const { customerGroup } = useStoreState((state: any) => state.dimensions)

  const renderDimensionCounter = useMemo(() => {
    if (group === 'customer') {
      const segments = flattenCustomerSegments(customerGroup)
      const counts: any = []
      dimensions.forEach((item) => {
        const type = item?.segments[0]?.type
        if (segments.some((segment) => segment.code === type)) counts.push(type)
      })

      return counts?.length || 0
    }

    if (group === 'channel') {
      const valueNumber = dimensions[0]?.segments[0].value.split(',')

      return valueNumber?.length || 0
    }

    // if (group === 'campaign') {
    //   const campaignDimension = dimensions.find(
    //     (item) => item?.dimension?.segments[0]?.type === 'CAMPAIGN'
    //   )
    //   const checkedKeys = campaignDimension?.dimension?.segments[0].value?.split(',')

    //   return checkedKeys?.length || 0
    // }

    return 0
  }, [group, dimensions, customerGroup])

  if (!renderDimensionCounter) return null

  return (
    <span
      style={{ width: 20, height: 20 }}
      className="flex items-center justify-center text-white rounded-full bg-brand_primary"
    >
      {renderDimensionCounter}
    </span>
  )
}

export default DimensionsGroupsCounter
