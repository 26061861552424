import React, { useState, useEffect } from 'react'
import { Button, Divider, Typography, Skeleton } from 'antd'
import { FacebookFilled, GoogleCircleFilled } from '@ant-design/icons'
import classNames from 'classnames'
import './index.scss'
import { facebook } from 'Utils'
import axios from 'Utils/axios'
import AgencyConnection from 'Components/AgencyConnection'
import ActionTrigger from 'Components/ActionTrigger'

const { Title } = Typography

const SOCIAL_CONNECTIONS = [
  { type: 'facebook', Icon: FacebookFilled },
  { type: 'google', Icon: GoogleCircleFilled },
]

const SettingAccount = () => {
  return (
    <div className="SettingAccountPage px-8">
      <Title className="page-title font-semibold text-3xl my-7 text-primary_text" level={2}>
        Account Setting
      </Title>
      <div className="settings-wrapper">
        {/* <div className="setting-section rounded form-wrapper bg-white p-6">
          <Title className="form-title mb-3" level={4}>
            Ads Agency
          </Title>
          <div className="">
            {SOCIAL_CONNECTIONS.map((sc, idx) => (
              <AgencyConnection key={idx} type={sc.type} Icon={sc.Icon}></AgencyConnection>
            ))}
          </div>
        </div> */}
        <div className="setting-section rounded form-wrapper bg-white p-6">
          <Title className="form-title  mb-3" level={4}>
            Welcome push
          </Title>
          <ActionTrigger />
        </div>
      </div>
    </div>
  )
}
export default SettingAccount
