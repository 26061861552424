import React, { useState, useEffect } from 'react'
import { DatePicker, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import LeaderBoards from './Components/LeadBoards'
import SFGeneral from './Components/Overview'
import Detail from './Components/Detail'
import moment from 'moment'
import './index.scss'
import CustomTabs from 'Components/CustomTabs'
import Campaign from './Components/Campaign'

const { RangePicker } = DatePicker
const { Title } = Typography
const queryString = require('query-string')

const StoreFarming = () => {
  const history = useHistory()
  const [dateFilter, setDateFilter] = useState([moment().subtract(1, 'months'), moment()])

  const { outer_tab, inner_tab } = queryString.parse(history.location.search)

  const renderInnerTabs = () => {
    switch (outer_tab) {
      case 'analytic':
        return (
          <CustomTabs
            key="staff_sf"
            className="ant-tabs--white-mode"
            queryKey="inner_tab"
            type="staff_sf"
            defaultTab="overview"
          ></CustomTabs>
        )
      case 'manage':
        return (
          <CustomTabs
            key="manager_sf"
            className="ant-tabs--white-mode"
            queryKey="inner_tab"
            type="manager_sf"
            defaultTab="overview"
          ></CustomTabs>
        )
      default:
        return <></>
    }
  }

  const renderMeat = () => {
    switch (outer_tab) {
      case 'campaign':
        return <Campaign></Campaign>
      case 'analytic':
        if (inner_tab === 'overview')
          return <SFGeneral fromDate={dateFilter[0]} toDate={dateFilter[1]} />
        else if (inner_tab === 'leader_board')
          return <LeaderBoards fromDate={dateFilter[0]} toDate={dateFilter[1]} />
      case 'manage':
        if (inner_tab === 'overview')
          return <SFGeneral fromDate={dateFilter[0]} toDate={dateFilter[1]} />
        else if (inner_tab === 'detail')
          return <Detail fromDate={dateFilter[0]} toDate={dateFilter[1]} />
      default:
        return <></>
    }
  }

  const renderFilter = () => {
    if (outer_tab === 'campaign') return <></>
    return (
      <div className="filter-wrapper w-full flex bg-gray_1 justify-between items-center">
        {renderInnerTabs()}
        <RangePicker
          className="sf-range-picker"
          defaultValue={[moment(dateFilter[0]), moment(dateFilter[1])]}
          onChange={(date) => {
            // @ts-ignore: Object is possibly 'null'.
            setDateFilter(date)
          }}
        ></RangePicker>
      </div>
    )
  }

  return (
    <div className="StoreFarmingPage w-full px-8">
      <Title className="page-title font-semibold text-3xl my-7 text-primary_text" level={3}>
        Store Farming
      </Title>
      <CustomTabs className="mb-6" type="outer_sf" queryKey="outer_tab"></CustomTabs>
      {renderFilter()}
      {renderMeat()}
    </div>
  )
}

export default StoreFarming
