import React, { useCallback, useEffect, useState } from 'react'
import { message, Tree, Tabs, Skeleton, Select } from 'antd'
import { ReactComponent as IconSort } from 'Assets/images/icons/sorter.svg'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { useHistory, useParams } from 'react-router-dom'
import SaleChannelPivotTableTemplate from 'Pages/Analytics/SaleAnalytics/Components/PivotTable.Template'
import { getCategoryList, getPivotCateList } from 'Services/Analytics/saleChannel-analytics'
import { debounce } from 'lodash'
import axios from 'axios'
const { Option } = Select
const PivotRow2 = () => {
  const { id } = useParams()
  const [categoryList, setCategoryList] = useState()
  const [cateId, setCateId] = useState<any>('root')
  const [keyword, setKeyword] = useState<string>('')
  const [searchList, setSearchList] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const handleCateData = async () => {
    // setIsLoading(true)
    try {
      const resp = await getCategoryList({ category_id: cateId })
      setCategoryList(resp.data.children)
      // setIsLoading(false)
      //setBrandFilterList(tempData)
    } catch (err: any) {
      message.error('Something went wrong')
    }
  }
  let cancelToken
  const handleSearch = async (v) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    setLoading(true)
    setSearchList([])
    try {
      const resp = await getPivotCateList({
        keyword: v,
        cancelTokenSource: cancelToken,
      })
      setLoading(false)
      setSearchList(resp.data)
    } catch (err: any) {
      // message.error('Something went wrong')
    }
  }

  const debounceDropDown = useCallback(
    debounce((nextValue) => {
      handleSearch(nextValue)
    }, 1000),
    []
  )
  useEffect(() => {
    handleCateData()
  }, [])
  useEffect(() => {
    keyword != '' && keyword.length > 2 && debounceDropDown(keyword)
  }, [keyword])

  const options = searchList?.map((item) => {
    return <Option value={item?.key}>{item?.title}</Option>
  })
  return (
    <div className="bg-white SaleChannelListing">
      <div className="flex items-center justify-between" style={{ height: 72 }}>
        <div className="pl-4 font-semibold">Product x Sale Channels</div>
        <Select
          // disabled={loading}
          // autoClearSearchValue={false}
          // suffix={<SearchOutlined />}
          loading={loading}
          // defaultActiveFirstOption={false}
          // onChange={() => {}}
          filterOption={false}
          notFoundContent={null}
          showSearch
          placeholder="Search"
          className="search-bar"
          defaultValue={keyword.toString()}
          searchValue={keyword}
          onSearch={(e) => {
            setKeyword(e)
          }}
          onSelect={(e) => setCateId(e)}
          dropdownStyle={{ padding: 8 }}
          dropdownRender={(menu) => (
            <Skeleton loading={loading} active>
              {menu}
            </Skeleton>
          )}
        >
          {options}
        </Select>
      </div>
      <div>
        <div className="flex mr-1 rounded ">
          <div className="relative bg-white left-corner-radius" style={{ width: 304 }}>
            <div
              style={{ height: 57, background: 'var(--table_header)' }}
              className="flex p-4 text-sm border-b top-left-corner-radius text-secondary_text"
            >
              Category
              <IconSort className="ml-2 cursor-pointer"></IconSort>
            </div>

            <Tree
              treeData={categoryList}
              onSelect={(checkedKeys) => {
                //setParams({ ...params, category_id: checkedKeys[0] })
                //   setCateId(checkedKeys[0])
                //   setCateIdChanged('changed')
                //   setKeyword('')
                //   setTest(true)
                setCateId(checkedKeys[0])
              }}
            />
          </div>
          <div className="flex items-center justify-between mb-4">
            {/* <div className="flex text-brand_primary">
          <div className="mr-4">
            <RefreshIcon />
          </div>
          <div>
            <DownIcon />
          </div>
        </div> */}
          </div>
          <SaleChannelPivotTableTemplate
            cate_id={cateId}
            analytic_type={'product_x_sale_channel'}
            isStore={false}
            location={'store360'}
            store_id={id}
          />
        </div>
      </div>
    </div>
  )
}

export default PivotRow2
