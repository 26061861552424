import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import { getAudienceListing, getAudienceSegment } from 'Services/customer-segments'
import { Button, message } from 'antd'
import TableCustomerListing from './TableCustomerListing'
import { RiAddFill } from 'react-icons/ri'

const CustomerListing = () => {
  const { id } = useParams()
  const history = useHistory()

  const { setBreadCrumb } = useStoreActions((actions: any) => actions.breadcrumb)

  // const [customerListing, setCustomerListing] = useState('')

  useEffect(() => {
    setBreadCrumb([{ title: 'Return User', path: '/analytics/customer-list' }])
  }, [setBreadCrumb])

  const handleCreate = () => {
    window.open(`../../campaigns/new?id=${id}`, '_self')
  }
  const [segmentName, setSegmentName] = useState('')

  useEffect(() => {
    const handleSegmentName = async () => {
      try {
        const resp = await getAudienceSegment(id)
        setSegmentName(resp.data.name)
      } catch (err: any) {
        message.error('Something went wrong')
      }
    }
    if (id) {
      setBreadCrumb([{ title: segmentName, path: `/analytics/customer-list/${id}` }])
    }
    handleSegmentName()
  }, [setBreadCrumb, id, history.location.search])
  return (
    <div className="w-full px-8 AnalyticsWrapper py-7">
      <div className="flex items-center justify-between pb-6">
        <div className="text-3xl font-semibold">{segmentName}</div>
        <Button onClick={() => handleCreate()} type="primary">
          <div className="flex items-center justify-between">
            <RiAddFill size={20} />
            <div className="ml-2">New campaign</div>
          </div>
        </Button>
      </div>

      {/* <Body /> */}
      <div>
        <TableCustomerListing />
      </div>
    </div>
  )
}

export default CustomerListing
