import React, { useState, useEffect, useRef } from 'react'

import { Empty, Select, Input, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import useHooks from './Hooks'
import axios from 'Utils/axios'
import { useStoreState } from 'Hooks'

const Option = Select.Option

const MessageTemplates = ({ sendBtn = false }) => {
  const refInput = useRef<any>(null)
  const [isSearchExpanded, setIsSearchExpanded] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState<any>('')
  const [selectedScenario, setSelectedScenario] = useState<any>('')

  const customerId = useStoreState((state) => state.harasocial.currentCustomerId)
  const { messages, campaigns, scenarios, searchStr, setSearchStr } = useHooks(
    selectedCampaign,
    selectedScenario
  )

  useEffect(() => {
    if (isSearchExpanded) {
      return refInput?.current?.focus()
      /* eslint-enable */
    } else {
      if (searchStr !== '') setSearchStr('')
    }
  }, [isSearchExpanded])

  const handleSendMessage = async (message) => {
    message.loading('Sending...', 1)
    try {
      await axios.post(
        `/store-farming/send-message/send-to-customer?ps_id=${customerId}&message_id=${message.id}`
      )
    } catch (error: any) {
      message.error('Không thể gửi tin nhắn cho khách hàng!')
    }
  }

  const renderHeader = () => {
    if (isSearchExpanded)
      return (
        <div className="flex items-center border-none">
          <Input
            ref={refInput}
            className="border-none"
            prefix={<SearchOutlined />}
            onChange={(e) => setSearchStr(e.target.value)}
          />
          <span
            className="text-brand_primary pl-3 cursor-pointer"
            onClick={() => setIsSearchExpanded(!isSearchExpanded)}
          >
            Hủy
          </span>
        </div>
      )

    return (
      <div className="flex justify-between">
        <h3 className="font-semibold text-black">Tin nhắn mẫu</h3>
        <span className="cursor-pointer" onClick={() => setIsSearchExpanded(!isSearchExpanded)}>
          <SearchOutlined />
        </span>
      </div>
    )
  }

  const handleCampaignChange = (value) => {
    setSearchStr('')
    setSelectedCampaign(value)
  }

  const handleScenarioChange = (value) => {
    setSearchStr('')
    setSelectedScenario(value)
  }

  const renderFilter = () => {
    return (
      <>
        <Select className="w-full" placeholder="Chiến dịch" onChange={handleCampaignChange}>
          <Option value={''}>All</Option>
          {campaigns.map((cam) => (
            <Option key={cam} value={cam}>
              {cam}
            </Option>
          ))}
        </Select>
        <Select className="w-full" placeholder="Kịch bản" onChange={handleScenarioChange}>
          <Option value={''}>All</Option>
          {scenarios.map((sce) => (
            <Option key={sce} value={sce}>
              {sce}
            </Option>
          ))}
        </Select>
      </>
    )
  }

  const renderSendButton = () => {
    if (!sendBtn) return <></>
    return (
      <span
        className="text-brand_primary cursor-pointer"
        onClick={() => handleSendMessage(message)}
      >
        Gửi
      </span>
    )
  }

  const renderMessages = () => {
    if (!messages || !messages.length) return <Empty description="Không tìm thấy message."></Empty>
    return messages.map((message) => (
      <div className="message-item flex items-center justify-between" key={message.id}>
        <span className="mr-2">{message.content}</span>
        {renderSendButton()}
      </div>
    ))
  }

  return (
    <div className="MessageTemplatesComponent">
      {renderHeader()}
      <div className="filter py-2">{renderFilter()}</div>
      <div className="message-list">{renderMessages()}</div>
    </div>
  )
}

export default MessageTemplates
