import React, { useState, useMemo } from 'react'
import { Modal, Button } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { LeftOutlined } from '@ant-design/icons'

import CustomerSegmentHeader from 'Pages/Audiences/Detail/Header'
import CustomerSegmentDefault from 'Pages/Audiences/Detail/Default'
import CustomSQL from 'Pages/Audiences/Detail/CustomSQL'
import CustomerSegmentTemplate from 'Pages/Audiences/Detail/Template'

import { States as CSStates } from 'Models/customer-segments'

export default function useAudienceScreen({ modalScreen, setModalScreen, onSelectAudienceId }) {
  const setCustomerSegmentState = useStoreActions((action: any) => action.customerSegments.setState)
  const { id: audienceSegmentId, name: audienceName } = useStoreState(
    (state: { customerSegments: CSStates }) => state.customerSegments
  )

  setCustomerSegmentState({ key: 'need_insights_refresh', value: true })

  const Screen = useMemo(() => {
    if (!modalScreen) return null

    if (modalScreen === 'TEMPLATE')
      return (
        <CustomerSegmentTemplate
          onSelectScreen={(key) => {
            setModalScreen(key ? 'BLANK' : '')
            setCustomerSegmentState({ key: 'need_insights_refresh', value: true })
          }}
        />
      )

    if (modalScreen === 'SELECT') {
      return (
        <div>
          <div className="bg-white pt-4 pb-6 px-8">
            <div className="w-full flex justify-between gap-1">
              <div
                className="flex font-semibold items-center cursor-pointer"
                onClick={() => {
                  setModalScreen('')
                }}
              >
                <LeftOutlined />
                <span className="pl-4">Choose Existing Segment</span>
              </div>
              <div className="flex">
                <Button
                  size="large"
                  className="font-semibold mr-4"
                  onClick={() => setModalScreen('')}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  type="primary"
                  className="font-semibold"
                  onClick={() => {
                    setModalScreen('')
                    onSelectAudienceId(audienceSegmentId)
                  }}
                >
                  Choose Segment
                </Button>
              </div>
            </div>
            <div className="text-3xl text-gray_13 font-semibold mt-10">{audienceName}</div>
          </div>
          <CustomerSegmentDefault />
        </div>
      )
    }

    const CreateScreen = ({ children }) => {
      return (
        <div className="relative">
          <div className="sticky top-0 z-50">
            <div
              className="flex font-semibold items-center px-8  cursor-pointer bg-white"
              onClick={() => {
                setModalScreen('')
              }}
            >
              <LeftOutlined className="mt-4" />
              <span className="pl-4 mt-4">Create Custom SQL Segment</span>
            </div>
            <CustomerSegmentHeader
              actionCallback={() => {
                setModalScreen('')
              }}
              showButtons={['publish']}
            />
          </div>

          {children}
        </div>
      )
    }

    if (modalScreen === 'CUSTOM_SQL')
      return (
        <CreateScreen>
          <CustomSQL
            onSelectScreen={(value) => {
              setModalScreen(value)
            }}
          />
        </CreateScreen>
      )

    return (
      <CreateScreen>
        <CustomerSegmentDefault />
      </CreateScreen>
    )
  }, [
    modalScreen,
    setCustomerSegmentState,
    audienceSegmentId,
    onSelectAudienceId,
    setModalScreen,
    // audienceName,
  ])

  return Screen
}
