import Group from './Group'
import React from 'react'
import { Skeleton } from 'antd'
import { connect } from 'react-redux'

import './index.scss'

class Segmentation extends React.PureComponent {
  renderGroups = () => {
    return this.props.segmentation.map((g, i) => (
      <Group
        dimensions={this.props.dimensions}
        index={i}
        key={i}
        items={g.segments}
        groupCondition={g.condition}
      ></Group>
    ))
  }

  renderModal = (Modal) => {
    if (!this.props.isVisible) return null
    return <Modal visible={this.props.visible} />
  }

  render() {
    return (
      <Skeleton paragraph={{ width: '100%', rows: 0 }} loading={this.props.isFetching} active>
        <div className="SegmentationComponent">
          {this.renderModal(this.props.dimensionModal)}
          {this.renderGroups()}
        </div>
      </Skeleton>
    )
  }
}

export default connect(
  (state) => ({
    visible: state.segmentation.dimensionModalVisible,
    isFetching: state.segmentation.isFetching,
  }),
  (dispatch) => ({})
)(Segmentation)
