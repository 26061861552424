import { createStore } from 'easy-peasy'
import reducers from './Models'

// Todo: change all model to typescript
// import { Model } from 'Models/index.d'
// const store = createStore<Model>(reducers)
// END

const store = createStore(reducers)

export default store
