import { action, computed, thunk, debug } from 'easy-peasy'
import axios from 'Utils/axios'

import { findObjectsFromKeys } from 'Utils'

const initState = {
  options: [],
  previewData: {},
}
export default {
  ...initState,
  setState: action((state, { key, value }) => {
    state[key] = value
  }),
  setIsSubmitted: action((state, payload) => {
    state.isSubmitted = payload
  }),
  setOptions: action((state, payload) => {
    state.options = payload
  }),
  setPreviewData: action((state, payload) => {
    if (!!payload.key) {
      state.previewData = { ...state.previewData, [payload.key]: payload.value }
    }
  }),
  clearPreviewData: action((state) => {
    state.previewData = {}
  }),
  getState: computed(
    [
      (state) => {},
      (state, storeState) => {
        return {
          channels: storeState.campaign.placements.channels,
          selectedProducts: storeState.campaign.products.selectedProducts,
          selectedPlacementIds: storeState.campaign.placements.selectedPlacementIds,
          placements: storeState.campaign.placements.data,
        }
      },
    ],
    (filters, data) =>
      (currentState = {}, product = null, placement = null, id = null, isInitial = true) => {
        const { selectedProducts, channels, placements, selectedPlacementIds } = data
        if (isInitial) {
          const selectedProduct = selectedProducts[0] ? selectedProducts[0].ProductId : null
          const selectedPlacement = findObjectsFromKeys(selectedPlacementIds, placements, 'id')[0]
          return {
            selectedProduct,
            selectedPlacement,
            creativeContentId: null,
          }
        } else {
          if (!!product) {
            const selectedProduct = selectedProducts.find((p) => p.ProductId === product)
            return {
              ...currentState,
              selectedProduct: selectedProduct,
            }
          }
          if (!!placement) {
            const selectedPlacement = channels
              .map((channel) => channel.placement_arr)
              .flat()
              .find((p) => p.code === placement)
            return {
              ...currentState,
              selectedPlacement: selectedPlacement,
            }
          }
          if (!!id) {
            return {
              ...currentState,
              creativeContentId: id,
            }
          }
        }
      }
  ),
  uploadImage: thunk(async (actions, { image, company, key, config }) => {
    const formData = new FormData()
    formData.append('images', image)
    const resp = await axios.post(`/products/images/upload?company=${company}`, formData, config)

    try {
      actions.setPreviewData({
        key,
        value: resp.data && resp.data[0].url,
      })

      return resp.data[0].url
    } catch (err) {
      return { errors: 'Can not upload image, something went wrong' }
    }
  }),
  resetState: action((state) => ({ ...initState })),
}
