import React, { useState, useEffect } from 'react'

import CustomerTargeting from './Components/Customer/Targeting'
import CustomerInfo from './Components/Customer/Info'
import CustomerMetrics from './Components/Customer/Metrics'
import CustomerInteractions from './Components/Customer/Interactions'

import RecommendedProducts from './Components/Recommendations/RecommendedProducts'
import MessageTemplates from './Components/Recommendations/MessageTemplates'
import RecommendedProductTabs from './Components/Recommendations/ProductTabs'

import './index.scss'
import './Components/Customer/index.scss'
import './Components/Recommendations/index.scss'

import { createTypedHooks } from 'easy-peasy'
import { ModelTypes } from 'Models/index.types'
import { Skeleton } from 'antd'
import useHook from './hooks'
const typedHooks = createTypedHooks<ModelTypes>()

const CustomerPortrait = () => {
  const customerId = typedHooks.useStoreState((state) => state.harasocial.currentCustomerId)

  const { error, customer, recommendProductData } = useHook(customerId)

  return (
    <div id="CustomerPortrait" className="px-3 py-2">
      <div className="section-wrapper">{customer ? <CustomerTargeting /> : <Skeleton />}</div>
      <div className="section-wrapper">
        {customer ? (
          <CustomerInfo
            name={customer.name}
            age={customer.age}
            gender={customer.gender}
            location={customer.last_city}
            segment={customer.segment.split(',')}
            ltv={customer.ltv}
            membership={customer.membership}
          />
        ) : (
          <Skeleton />
        )}
      </div>
      <div className="section-wrapper __background __padding">
        {/* // Todo: Comment coz don't have data yet */}
        {/* <div className="pb-2">
          <h3 className="font-semibold text-black mb-2">Lịch sử tương tác</h3>
          {customer ? <CustomerInteractions data={customer?.historyInteraction} /> : <Skeleton />}
        </div> */}
        <div>
          <h3 className="font-semibold text-black mb-2">Lịch sử mua hàng</h3>
          {customer ? <CustomerMetrics data={customer.purchasedHistory} /> : <Skeleton />}
        </div>
      </div>
      <div className="section-wrapper __background">
        {customer ? (
          <RecommendedProductTabs
            topViewedProducts={customer.topViewedProducts}
            recentViewedProducts={customer.recentViewedProducts}
            purchasedProducts={customer.purchasedProducts}
          />
        ) : (
          <Skeleton />
        )}
      </div>
      <div className="section-wrapper __background __padding">
        {recommendProductData ? <RecommendedProducts data={recommendProductData} /> : <Skeleton />}
      </div>
      <div className="section-wrapper __background __padding">
        <MessageTemplates />
      </div>
    </div>
  )
}

export default CustomerPortrait
