import React, { useCallback } from 'react'
import { Tooltip } from 'antd'
import './index.scss'
import { MIN_WIDTH } from './constants'
import Bar from './Bar'

export interface StackBarProps {
  data?:
    | {
        label: string
        percent: number
      }[]
    | null
  title?: string
  isLoading: boolean
  showLegend?: boolean
  className?: string
  displayPercent?: boolean
  valueOverflow?: boolean
  computeRest?: boolean
  onBarClick?: (bar: { label: string; percent: number }) => void
}

const StackBar: React.FC<StackBarProps> = ({
  title = '--',
  data = [],
  showLegend = true,
  isLoading = false,
  onBarClick,
  displayPercent,
  valueOverflow = false,
  computeRest = false,
  className = '',
}) => {
  React.useEffect(() => {
    if (computeRest && data && data[0]) data.push({ label: '', percent: 100 - data[0].percent })
  }, [data])

  if (!data) return <></>

  const renderLegendItem = () => {
    if (!data[0]?.label) return
    return data.map((item, i) => (
      <Tooltip
        key={i}
        className={`legend-${i}`}
        title={`${item.percent ? item.percent : '0'}${displayPercent ? '%' : ''}`}
      >
        <li>{item.label}</li>
      </Tooltip>
    ))
  }

  const renderBar = () => {
    if (isLoading) return <div className="skeleton"></div>
    if (!data.length) return <></>

    return data.map((item, i) => (
      <Bar key={i} index={i} item={item} displayPercent={displayPercent} onBarClick={onBarClick} />
    ))
  }

  return (
    <div className={`stack-bar ${className}`}>
      <h1 className="title font-semibold  mb-2">{title}</h1>
      <div className="chart flex">{renderBar()}</div>
      {showLegend && <ul className="legend m-0 mt-4">{renderLegendItem()}</ul>}
    </div>
  )
}

export default StackBar
