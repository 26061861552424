import React, { useState, useEffect } from 'react'
import { Checkbox, Empty, message, Collapse } from 'antd'

import ProductItem from '../Recommendations/ProductItem'
import LinkItem from '../Recommendations/LinkItem'
import { getActions } from 'Services/store-farming'

import { createTypedHooks } from 'easy-peasy'
import { ModelTypes } from 'Models/index.types'
import axios from 'Utils/axios'
import copyTextToClipboard from 'Utils/copy'
import { ProductProps } from 'ProductModule'
import { formatErrors } from 'Utils'

const typedHooks = createTypedHooks<ModelTypes>()

type TargetingItemProps = {
  task: any
  onTaskMark?: (task: any, status: any) => void
  mode?: string
  expanded?: boolean
}

const TargetingItem = ({ task, onTaskMark, mode, expanded }: TargetingItemProps) => {
  const customerId = typedHooks.useStoreState((state) => state.harasocial.currentCustomerId)
  const harasocialActions = typedHooks.useStoreState((state) => state.harasocial.harasocialActions)
  const [actions, setActions] = useState<any>([])
  const handleActionCheck = (status) => {
    if (!onTaskMark) return
    message.loading('Updating...', 0.5)
    axios
      .put(`/store-farming/tasks/${task.taskId}/${status ? 'mark-done' : 'unmark-done'}`)
      .then((rs) => {
        if (onTaskMark) onTaskMark(task.taskId, status)
      })
      .catch((err) => {
        message.error(formatErrors(err.response.data))
      })
  }

  const renderTaskAction = (actionId) => {
    if (!actionId) return
    // const resp = getActions()

    return actions.find((action) => action.id === actionId)?.name
  }

  const handleEdit = () => {
    harasocialActions.sendText(task.message)
    task.products.forEach((p) => {
      harasocialActions.sendProduct({
        title: p.title,
        subtitle: p.price,
        imageUrl: p.image,
        buttonUrl: p.slug,
      })
    })

    task.link.forEach((l) => {
      harasocialActions.sendText(l)
    })
  }

  const hanldeCopy = async () => {
    await copyTextToClipboard(task.message)
    message.success('Copied message to clipboard')
  }

  const handleSend = async () => {
    message.loading('Sending...', 1)
    try {
      await axios.post(
        `/store-farming/customer-care-goal/send-to-customer?ps_id=${customerId}&task_id=${task.taskId}`
      )
    } catch (error: any) {
      message.error('Không thể gửi tin nhắn cho khách hàng!')
    }
  }

  const renderLinks = () => {
    if (!task.link) return
    return task.link.map((link, index) => <LinkItem key={index} className="mt-1" url={link} />)
  }

  const renderProducts = () => {
    if (!task.products) return
    return task.products.map((product: ProductProps) => (
      <ProductItem
        key={product.productId}
        product={product}
        opts={{ sendBtn: false }}
        className="border shadow-none mt-2"
      ></ProductItem>
    ))
  }

  const renderImages = () => {
    if (!task.image) return
    return task.image.map((img_src, index) => <img className="image" key={index} src={img_src} />)
  }

  useEffect(() => {
    const handleFetchActions = async () => {
      const resp = await getActions()
      setActions(resp.data)
    }
    handleFetchActions()
  }, [])

  const computeDefaultExpand = () => {
    if (expanded) return task.taskId || 'DEFAULT_KEY'
    else if (task?.statusTask === 'doing') return task?.taskId
    else return ''
  }

  return (
    <Collapse
      defaultActiveKey={[computeDefaultExpand()]}
      className="TargetingItemComponent rounded shadow-bot_1 mb-3"
      expandIconPosition="right"
    >
      <Collapse.Panel
        key={task.taskId || 'DEFAULT_KEY'}
        className="header"
        header={
          <div className="p-3">
            <Checkbox
              className="non-padding mr-2"
              checked={task.statusTask !== 'doing'}
              onChange={(event) => handleActionCheck(event.target.checked)}
            ></Checkbox>
            <span className="font-bold" role="img" aria-label="img">
              🎯 Mục tiêu: {task.target}
            </span>
          </div>
        }
      >
        <div className="p-3 body mt-2 bg-background_opaque">
          <div>
            <h3 className="font-semibold mb-1">Action</h3>
            <div className="pb-3">
              <span className="checkbox-label">
                {task.action || renderTaskAction(task.actionId)}
              </span>
            </div>
            <div className="divider"></div>
          </div>
          <div className="mt-3">
            <h3 className="font-semibold mb-1">Kịch bản</h3>
            <div className="p-2 bg-gray_1 rounded-md">
              <span>{task.message}</span>
              <div className="image-list flex mt-2">{renderImages()}</div>
              <div className="link-list mt-2">{renderProducts()}</div>
              <div className="link-list mt-2">{renderLinks()}</div>
              <div className="pt-2">
                <span className="action-btn font-medium" onClick={hanldeCopy}>
                  Sao chép
                </span>
                <span className="action-btn font-medium" onClick={handleEdit}>
                  Sửa
                </span>
                <span className="action-btn font-medium" onClick={handleSend}>
                  Gửi
                </span>
              </div>
            </div>
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  )
}

export default TargetingItem
