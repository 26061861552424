export const dataOptions = [
  { title: 'Month of Year', key: 'MOY' },
  { title: 'Day of Month', key: 'DOM' },
  { title: 'Day of Week', key: 'DOW' },
  { title: 'Hour of Day', key: 'HOD' },
]

export const MOY = [
  { key: 'Jan' },
  { key: 'Feb' },
  { key: 'Mar' },
  { key: 'Apr' },
  { key: 'May' },
  { key: 'Jun' },
  { key: 'Jul' },
  { key: 'Aug' },
  { key: 'Sep' },
  { key: 'Oct' },
  { key: 'Nov' },
  { key: 'Dec' },
]

export const DOW = [
  { key: 'Mon' },
  { key: 'Tue' },
  { key: 'Wed' },
  { key: 'Thu' },
  { key: 'Fri' },
  { key: 'Sat' },
  { key: 'Sun' },
]

var now = new Date()
const getDaysInMonth = (month, year) => {
  var date = new Date(year, month, 1)
  var days = []
  while (date.getMonth() === month) {
    days.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }
  return days
}

export const DOM = getDaysInMonth(now.getMonth(), now.getFullYear()).map((item, index) => ({
  key: index + 1,
}))
