import React from 'react'
import { action, thunk } from 'easy-peasy'
import moment from 'moment'
import axios from 'Utils/axios'
import { needStringifyArray } from 'Utils'

const initState = {
  node_id: undefined,
  selected_node_statistic: ['type', null],
  isFetchingAnalytics: false,
  cj_id: undefined,
  cj_metrics: [],
  cj_charts: [],
  node_analytics_charts: [],
  node_analytics_metrics: [],
  collapsed: true,
  loading: ['key', false],
}

export default {
  ...initState,
  setCJAnalyticsStates: action((state, { key, value }) => {
    state[key] = value
  }),
  pushAnalytics: action((state, { key, value }) => {
    state[key].push(value)
  }),
  resetStates: action((state) => ({ ...initState })),

  getNodeAnalytics: thunk(async (actions, { code, type, filter }, { getState }) => {
    const state = getState()

    const analyticsResp = await axios.get(`customer-journeys/nodes/${state.node_id}/analytics`, {
      params: {
        code,
        ...needStringifyArray(filter),
      },
    })

    if (analyticsResp.response) {
      return { error: analyticsResp.response.data }
    }

    let data

    if (type === 'node_analytics_charts') {
      data = analyticsResp.data.results || []
    }

    if (type === 'node_analytics_metrics') {
      data = analyticsResp.data.results ? Object.entries(analyticsResp.data.results[0])[0][1] : null
    }

    actions.pushAnalytics({
      key: type,
      value: {
        key: code,
        label: code.replace(/_/g, ' '),
        value: data,
      },
    })
    return state[type]
  }),
  getCJAnalytics: thunk(
    async (actions, { id, code, type, filter, is_key_metric }, { getState }) => {
      const state = getState()
      //
      const analyticsResp = await axios.get(`customer-journeys/${id}/analytics`, {
        params: {
          code,
          is_key_metric: is_key_metric || undefined,
          ...needStringifyArray(filter),
        },
      })

      if (analyticsResp.response) {
        return { error: analyticsResp.response.data }
      }

      let data

      if (type === 'cj_charts') {
        data = analyticsResp.data.results || []
      }

      if (type === 'cj_metrics') {
        data = analyticsResp.data.results
          ? Object.entries(analyticsResp.data.results[0])[0][1]
          : null
      }

      actions.pushAnalytics({
        key: type,
        value: {
          key: code,
          label: code.replace(/_/g, ' '),
          value: data,
        },
      })

      // return data
    }
  ),
}
