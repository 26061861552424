import React from 'react'
import { InteractionItemProps } from './types'
import moment from 'moment'
import { Popover } from 'antd'
import ProductItem from 'Pages/Harasiocial/CustomerPortrait/Components/Recommendations/ProductItem'
import { humanDate } from 'Utils/DateTime'
export const FIXED_CONTENT = {
  pageview: { prefix: '', suffix: '' },
  click: { prefix: '', suffix: '' },
  add_to_cart: { prefix: 'Thêm vào giỏ hàng sản phẩm', suffix: '' },
  remove_from_cart: { prefix: '', suffix: '' },
  remove_from_cart_product: { prefix: 'Xóa khỏi giỏ hàng sản phẩm', suffix: '' },
  rating: { prefix: '', suffix: '' },
  checkout: { prefix: '', suffix: '' },
  checkout_product: { prefix: 'Mua sản phẩm', suffix: '' },
  order: { prefix: 'Đặt hàng sản phẩm', suffix: '' },
  user_info: { prefix: '', suffix: '' },
  view: { prefix: 'Xem sản phẩm', suffix: '' },
  subcribed: { prefix: '', suffix: '' },
}

const InteractionItem = ({
  time,
  type,
  target,
  targetValue,
  targetId, // Id of product, collection or url of page
  referer,
}: InteractionItemProps) => {
  const renderTarget = () => {
    if (target !== 'product' || !targetValue.title)
      return (
        <span className="content__product font-semibold cursor-pointer">
          {targetValue.title || 'Untitled'}
        </span>
      )
    return (
      <Popover
        overlayClassName="no-padding non-arrow"
        placement="topLeft"
        trigger="click"
        content={
          <div>
            {
              <ProductItem
                layout="horizontal"
                product={{
                  ...targetValue,
                  title: targetValue.title || 'Untitled',
                }}
                opts={{ sendBtn: false }}
              />
            }
          </div>
        }
      >
        <span className="content__product font-semibold cursor-pointer">
          {targetValue.title || 'Untitled'}
        </span>
      </Popover>
    )
    return <></>
  }
  return (
    <div className="InteractionItemComponent">
      <div className="verticle-line-wrapper">
        <span className="line flex item-center"></span>
        <span className="circle"></span>
      </div>
      <div className="content-wrapper ml-3 pb-5">
        <span className="timestamp text-secondary_text text-base">{humanDate(time)}</span>
        <span className="content text-base whitespace-nowrap overflow-ellipsis overflow-hidden">
          {FIXED_CONTENT[type].prefix}
          {renderTarget()}
          {FIXED_CONTENT[type].suffix}
        </span>
        <span className="channel text-t-disabled text-base capitalize">{referer}</span>
      </div>
    </div>
  )
}
export default InteractionItem
