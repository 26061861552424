import React from 'react'
import { Typography } from 'antd'
import moment from 'moment'
import { capitalizeFirstLetter } from 'Utils'

const { Text } = Typography

export const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    align: 'left',
    render: (audience) => {
      if (audience)
        return (
          <Text className="audience-title font-bold" strong>
            {audience}
          </Text>
        )
      else return 'N/A'
    },
    fixed: 'left',
  },
  {
    title: 'Type',
    dataIndex: 'audience_type',
    width: 100,
    sorter: true,
    render: (tag) => (tag ? capitalizeFirstLetter(tag) : 'N/A'),
  },
  {
    title: 'Last updated',
    dataIndex: 'updated_at',
    sorter: true,
    render: (text) => (text ? moment(text).format('DD-MM-YYYY') : 'N/A'),
    width: 150,
  },
  {
    title: 'Created by',
    dataIndex: 'created_by_detail',
    width: 200,
    render: (creator) =>
      creator ? `${creator?.first_name || ''} ${creator?.last_name || ''}` : 'N/A',
  },
  {
    title: 'Used in',
    dataIndex: 'used_in_detail',
    align: 'left',
    width: 300,
    render: (used_in_detail) =>
      used_in_detail ? (
        <div className="text-brand_primary font-semibold flex flex-col my-2">
          <span
            className={`${
              !used_in_detail?.active_campaign?.length
                ? 'text-disabled_text pointer-events-none'
                : ''
            }`}
          >
            {used_in_detail?.active_campaign?.length || 0} active campaigns
          </span>
          <span
            className={`${
              !used_in_detail?.pass_campaign?.length ? 'text-disabled_text pointer-events-none' : ''
            }`}
          >
            {used_in_detail?.pass_campaign?.length || 0} past campaigns
          </span>
        </div>
      ) : (
        'N/A'
      ),
  },
]

export const SOURCE = [
  { label: 'Kariba', value: 'kariba' },
  // { label: 'Facebook', value: 'facebook' },
]

export const USED_IN = [
  { label: 'Active campaign', value: 'active' },
  { label: 'Inactive campaign', value: 'inactive' },
  { label: 'Not in use', value: 'not_in_use' },
]
