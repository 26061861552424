import React from 'react'

import MenuOutlined from '@ant-design/icons/MenuOutlined'
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled'
import ColumnSelect from './ColumnSelect'

import MetricElement from './MetricElement'
import { colors } from 'DesignSystem'
const PivotElement = ({
  code,
  index,
  isMetric = false,
  className,
  aggregate,
  onChange, // function
  onRemove,
  onMetricChange,
  options,
}) => {
  const handleMetricChange = (column, aggregate) => {
    onMetricChange(column, aggregate, index as number)
  }

  const renderMeat = () => {
    if (isMetric)
      return (
        <MetricElement
          options={options}
          index={index}
          column={code}
          aggregate={aggregate}
          onChange={handleMetricChange}
        ></MetricElement>
      )
    return (
      <ColumnSelect
        options={options}
        className="no-border"
        defaultValue={code}
        onChange={(value) => onChange(value, index)}
      ></ColumnSelect>
    )
  }

  return (
    <div>
      <div className={`PivotElement border rounded ${className}`}>
        <div className="flex justify-center items-center relative">
          <MenuOutlined className="mr-2"></MenuOutlined>
          <div className="name">{renderMeat()}</div>
        </div>
        <CloseCircleFilled
          className="ml-2"
          style={{ color: colors.productColor.disabled_text }}
          onClick={() => onRemove(index)}
        />
      </div>
    </div>
  )
}

export default PivotElement
