import React, { useState, useEffect } from 'react'
import { Tooltip, Popover } from 'antd'
import { connect } from 'react-redux'

import { FilterFilled } from '@ant-design/icons'
import Filters from './index.js'
import './index.scss'

const WithPopover = ({ filterList, getFilters, onChange, title }) => {
  const [filters, setFilters] = useState({})
  const [isFiltersVisible, setIsFiltersVisible] = useState(false)

  useEffect(() => {
    getFilters()
  }, [])
  return (
    <div className="WithPopover-AudienceFilterComponent">
      <Tooltip title="Filter">
        <Popover
          content={
            <Filters
              isFiltersVisible={isFiltersVisible}
              setIsFiltersVisible={setIsFiltersVisible}
              filterList={filterList}
              filters={filters}
              onChange={onChange}
              setFilters={(filters) => {
                setFilters(filters)
              }}
            ></Filters>
          }
          visible={isFiltersVisible}
          title={title}
          placement="bottomLeft"
          trigger="click"
          arrowPointAtCenter
        >
          <span onClick={() => setIsFiltersVisible(!isFiltersVisible)} className="btn filter">
            <FilterFilled />
          </span>
        </Popover>
      </Tooltip>
    </div>
  )
}

export default connect(
  (state) => ({
    // filters: state.audienceAnalytics.filters,
    filterList: state.audienceAnalytics.filterList,
    audienceStates: state.campaign.audience,
  }),
  (dispatch) => ({
    getFilters: dispatch.audienceAnalytics.getFilters,
  })
)(WithPopover)
