import React, { useState } from 'react'
import { Formik } from 'formik'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'

import ActivateAccountView from './ActivateAccount.view'
import { forgotPasswordSchema } from 'Validation'
import { formatErrors } from '../../Utils'
import './index.scss'

const { success } = message
const initialValues = {}

export default React.memo(function ActivateAccount(props) {
  const history = useHistory()
  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const forgotPasswordAction = useStoreActions((actions) => actions.auth.forgotPassword)

  const handleForgotPassword = async (values) => {
    setIsSubmitting(true)
    const resp = await forgotPasswordAction(values)

    if (resp.errors) {
      setIsSubmitting(false)
      setServerError(formatErrors(resp.errors.data))
      return
    }

    success('A reset link has been sent to your email', 10)
  }

  return (
    <div className="activate-page">
      <Formik
        validationSchema={forgotPasswordSchema}
        initialValues={initialValues}
        onSubmit={handleForgotPassword}
        children={(props) => {
          return (
            <ActivateAccountView
              serverError={serverError}
              isValidating={false}
              isSubmitting={isSubmitting}
              {...props}
            />
          )
        }}
      />
    </div>
  )
})
