import LoadingIcon from 'Components/LoadingIcon'
import React from 'react'
import './index.scss'
const OverlayLoading = () => {
  return (
    <div className="OverlayLoadingComponent">
      <LoadingIcon></LoadingIcon>
    </div>
  )
}
export default OverlayLoading
