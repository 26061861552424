import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Skeleton } from 'antd'
import axios from 'axios'

import MetricCard from 'Components/MetricCard'
import { FiltersType } from 'AnalyticsTypes'
import { getProduct360Metrics } from 'Services/Analytics/product-analytics'

const KeyMetrics = () => {
  const history = useHistory()
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')

  const [metrics, setMetrics] = useState<
    {
      value: number
      unit: string
      title: string
      subvalue?: string | null
      display_name?: string
    }[]
  >([])

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()

    const handleKeyMetrics = async () => {
      setIsLoading(true)
      try {
        setError('')
        const resp = await getProduct360Metrics(id)

        setMetrics(resp.data.data)
      } catch (err: any) {
        if (err.message === 'cancelled') {
          return
        }

        setError(err?.response?.data?.detail || 'Some thing went wrong')
      }
      setIsLoading(false)
    }

    handleKeyMetrics()
    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [id])

  if (error) return <Alert message={error} type="error" />

  return (
    <Skeleton loading={isLoading} active>
      <div className="metric-cards-wrapper">
        {metrics.map((m, index) => (
          <MetricCard
            key={index}
            className={`box-${index + 1}`}
            title={m.display_name ? m.display_name : m.title}
            unit={m.unit}
            value={m.value}
          ></MetricCard>
        ))}
      </div>
    </Skeleton>
  )
}

export default KeyMetrics
