import { useMemo } from 'react'
import TextArea from 'antd/lib/input/TextArea'
import React, { useState } from 'react'
import FieldWrapper from '../FieldWrapper'

const TextAreaField = (props) => {
  const { name, onChange, values, autoSize, prefix } = props

  const textArea = useMemo(
    () => (
      <TextArea
        name={name}
        autoSize={autoSize}
        value={values[name]}
        onChange={(e) => {
          onChange(name, e.target.value)
          props.setFieldValue(name, e.target.value)
        }}
      ></TextArea>
    ),
    [values[name]]
  )

  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <>
          <h2 className="prefix">{prefix}</h2>
          {textArea}
        </>
      )}
    </FieldWrapper>
  )
}

TextAreaField.defaultProps = {
  autoSize: {
    minRows: 3,
  },
}

export default TextAreaField
