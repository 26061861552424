import React, { useRef, useEffect, useState } from 'react'

import { Skeleton, Empty } from 'antd'

import axios from 'Utils/axios'
import { drawStacked, drawDefault } from './drawBarCharts'

const HorizontalBarChart = ({ isStacked, chartId, data, type }) => {
  const horizontalBarChart = useRef(null)
  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!horizontalBarChart.current || !data) return
    var ctx = horizontalBarChart.current.getContext('2d')
    isStacked ? drawStacked(ctx, data, type) : drawDefault(ctx, data, type)
  }, [data])

  useEffect(() => {
    isStacked &&
      axios
        .get(
          `${process.env.REACT_APP_SUPERSET_PIVOT_TABLE_URL}/superset/explore_json_public/?slice_id=${chartId}`
        )
        .then((rs) => {
          let data = rs?.data?.data
          if (!data) return
          var ctx = horizontalBarChart.current.getContext('2d')
          return isStacked ? drawStacked(ctx, data) : drawDefault(ctx, data)
        })
        .catch((err) => {})
  }, [])

  if (!data || !data.length) return <Empty description={false}></Empty>
  return (
    <div className="HorizontalBarChartComponent">
      <Skeleton active loading={!data} paragraph={{ rows: 5 }}>
        <canvas
          style={{ width: '100%', height: '100%' }}
          ref={horizontalBarChart}
          className="chart"
        ></canvas>
      </Skeleton>
    </div>
  )
}

export default HorizontalBarChart
