import * as yup from 'yup'

export const campaignFacebookSchema = yup.object().shape({
  name: yup.string().required('name is required').max(255, 'Campaign name is too long'),
  daily_budget: yup.number().required('Daily Budget is required').nullable(true),
  bid_amount: yup.number().required('Bid Amount is required').nullable(true),
  objective: yup.string().required('Objective is required'),
  selectedPlacementCode: yup.array().required('Placement is required'),
})
export const campaignCommunitySchema = yup.object().shape({
  name: yup.string().required('Name is required').max(255, 'Campaign name is too long'),
  objective: yup.string().required('objective is required'),
  selectedPlacementCode: yup.array().required('Placement is required'),
})

export const campaignAudienceSchema = yup.object().shape({
  name: yup.string().required('Name is required').max(255, 'Audience name is too long'),
  // stores: yup.string().required('store is required'),
  segmentation: yup.array().required().min(1),
})

export const campaignFBAudienceSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  // stores: yup.string().required('store is required'),
  genders: yup.array().required().min(1),
  cities: yup.array().required().min(1),
  // interests: yup.array().required().min(1),
})
