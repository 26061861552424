import React from 'react'
import Slider from 'react-slick'
import { RightOutlined } from '@ant-design/icons'

import './index.scss'

const PrevArrow = (props) => {
  const { onClick, currentSlide } = props

  return (
    <div
      className={`overlay pointer-events-none absolute z-20 left-0   ${
        currentSlide === 0 ? 'hidden' : ''
      }`}
      style={{
        width: 50,
        height: '100%',
      }}
    >
      <div
        className={`flex items-center pointer-events-auto -left-4 justify-center absolute top-1/2  transform -translate-y-1/2 shadow-md rounded-full cursor-pointer bg-gray_1 z-10`}
        style={{ width: 40, height: 40 }}
        onClick={onClick}
      >
        <RightOutlined style={{ transform: 'rotate(180deg)' }} />
      </div>
    </div>
  )
}

const NextArrow = (props) => {
  const { onClick, slideCount, currentSlide } = props

  return (
    <div
      className={`overlay pointer-events-none absolute right-0 top-0 z-20 ${
        slideCount <= currentSlide + 1 ? 'hidden' : ''
      }`}
      style={{
        width: 50,
        height: '100%',
      }}
    >
      <div
        className={`flex items-center  pointer-events-auto -right-4 justify-center transform absolute top-1/2 -translate-y-1/2 shadow-md rounded-full cursor-pointer bg-gray_1 z-10}`}
        style={{ width: 40, height: 40 }}
        onClick={onClick}
      >
        <RightOutlined />
      </div>
    </div>
  )
}
// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   nextArrow: <NextArrow />,
//   prevArrow: <PrevArrow />,
// }

const HaraSocial = ({ data }) => {
  return (
    <div className="harasocial-preview w-full">
      {'content' in data && <div className="harasocial-preview__content">{data.content}</div>}
      {'image' in data && <img className="harasocial-preview__image" src={data.image} alt="" />}
      {'products' in data && (
        <div className="harasocial-preview__products">
          <Slider dots={false} nextArrow={<NextArrow />} prevArrow={<PrevArrow />}>
            {data.products.map((product, i) => (
              <div key={i} className="harasocial-preview__product">
                <div className="harasocial-preview__product__image">
                  <img src={product.Image} alt="" />
                </div>
                <div className="title p-4">
                  <div className="font-semibold	">{product.Title}</div>
                  <div className="text-gray_8	">
                    {product.Price.toLocaleString()} - {product.Color} / {product.Size}
                  </div>
                </div>

                <button>xem chi tiết</button>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  )
}

export default HaraSocial
