import React, { useState, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Empty, Timeline, Skeleton, Alert } from 'antd'

import { getCustomer360Activities } from 'Services/Analytics/customer-analytics'
import ChannelBadge from 'Components/ChannelBadge'

import './index.scss'

type ActivityType = {
  type: string
  time: string
  event_value: string
  channel: string
}

const Activities = () => {
  const { id } = useParams()
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<ActivityType[] | undefined>(undefined)

  useEffect(() => {
    const handleData = async () => {
      try {
        setError('')
        const resp = await getCustomer360Activities({ id })
        setData(resp.data)
      } catch (err: any) {
        setError(err?.response?.data?.detail || 'Something went wrong')
      }
      setIsLoading(false)
    }
    handleData()
  }, [id])

  const renderActivities = useMemo(() => {
    const ActivityItem = ({ activity }: { activity: ActivityType }) => {
      let dateTime = moment(activity.time).format('DD/MM/YYYY - hh:mm a')

      // const activityType =
      //   FIXED_CONTENT[activity?.type?.toString().toLowerCase().replace(/ /g, '_')]

      return (
        <Timeline.Item className="ActivityItemComponent">
          <span className="timestamp column">{dateTime}</span>
          <span className="activity column">
            {activity?.type} <span className="font-semibold">{activity.event_value}</span>
          </span>
          <span className="channel column mt-1">
            <ChannelBadge code={activity?.channel} /> • Customer
          </span>
        </Timeline.Item>
      )
    }

    if (!data?.length) return <Empty />

    return data.map((item, index) => <ActivityItem activity={item} key={index}></ActivityItem>)
  }, [data])

  if (error) return <Alert message={error} type="error" />

  return (
    <Skeleton loading={isLoading} active>
      <Timeline style={{ overflow: 'scroll' }} className="activities-container">
        {renderActivities}
      </Timeline>
    </Skeleton>
  )
}

export default Activities
