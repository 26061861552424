import React from 'react'
import { Tooltip, Typography } from 'antd'
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
const { Title } = Typography

const CommonTitle = (props) => {
  const { isRequired, label, tooltip } = props
  const labelWithTooltip = () => {
    if (!tooltip) return
    return (
      <Tooltip title={tooltip}>
        <QuestionCircleOutlined />
      </Tooltip>
    )
  }
  return (
    <Title className="CommonTitleComponent">
      {label} {isRequired && <span className="compulsory-field-symbol">*</span>}
      {labelWithTooltip()}
    </Title>
  )
}

export default CommonTitle
