import axios from '../Utils/axios'

const STORE_FARMING_ENDPOINT = '/store-farming'

export async function getScenarios() {
  const resp = await axios.get(`${STORE_FARMING_ENDPOINT}/scenarios`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getTemplateMessages() {
  const resp = await axios.get(`${STORE_FARMING_ENDPOINT}/template_messages`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
export async function getMessageParams() {
  const resp = await axios.get(`${STORE_FARMING_ENDPOINT}/template_messages/parameter`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getActions() {
  const resp = await axios.get(`${STORE_FARMING_ENDPOINT}/actions`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getActionDetail(id) {
  const resp = await axios.get(`${STORE_FARMING_ENDPOINT}/actions/${id}`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
