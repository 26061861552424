import React, { useEffect, useState } from 'react'
import Interaction from 'Assets/images/history-interaction.png'
import InteractionItem from './InteractionItem'
import { InteractionItemProps } from './types'
import { createAxios } from 'Utils/axios'

import { Empty, message } from 'antd'
import { LoadingWithOverlay } from 'Components/LoadingIcon'

import { createTypedHooks } from 'easy-peasy'
import { ModelTypes } from 'Models/index.types'
import { formatErrors } from 'Utils'
import './index.scss'
const typedHooks = createTypedHooks<ModelTypes>()

const HarasocialInteraction = () => {
  const [interactions, setInteraction] = useState<InteractionItemProps[] | null>(null)
  const customerId = typedHooks.useStoreState((state) => state.harasocial.currentCustomerId)

  useEffect(() => {
    ;(async () => {
      try {
        const axios = createAxios({ response: { toCamel: true } })
        const resp = await axios.get('/store-farming/history-interaction', {
          params: { ps_id: customerId, limit: 30 },
        })
        setInteraction(resp.data.historyInteraction)
      } catch (error: any) {
        message.error(formatErrors(error.response))
        setInteraction([])
      }
    })()
  }, [])

  const renderInteractionList = () => {
    if (!interactions || !interactions.length)
      return <Empty description="Không có dữ liệu!"></Empty>
    return interactions.map((inter, index) => {
      return (
        <InteractionItem
          key={index}
          type={inter.type}
          time={inter.time}
          target={inter.target}
          targetValue={inter.targetValue}
          referer={inter.referer}
        ></InteractionItem>
      )
    })
  }

  if (interactions === null)
    return <LoadingWithOverlay stretch loadingProps={{ width: 40 }}></LoadingWithOverlay>
  return (
    <div className="HarasocialInteractionPage bg-gray_1 px-3 py-2">{renderInteractionList()}</div>
  )
}

export default HarasocialInteraction
