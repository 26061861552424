import { action, thunk } from 'easy-peasy'
import axios from 'Utils/axios'

const initState = {
  cartItems: [],
  currentStep: 0,
  errorStep: null,
  cartModalVisible: false,
  highestStep: 0,
}
export default {
  ...initState,
  setState: action((state, { key, value }) => {
    state[key] = value
  }),
  reset: action((state, { key, value }) => {
    state = { ...initState }
  }),
  toggleCartModal: action((state, payload) => {
    state.cartModalVisible = !state.cartModalVisible
  }),
  setStep: action((state, step) => {
    state.currentStep = step
  }),
  setErrorStep: action((state, { errorStep }) => {
    state.errorStep = errorStep
  }),
  setHighestStep: action((state, step) => {
    state.highestStep = step
  }),
  resetState: action((state) => ({ ...initState })),
  resetAllStates: thunk(async (actions, _, { getStoreActions }) => {
    const storeActions = getStoreActions()
    sessionStorage.clear()
    console.clear()
    await storeActions.campaign.progress.resetState()
    await storeActions.campaign.setting.resetState()
    await storeActions.campaign.placements.resetState()
    await storeActions.campaign.audience.resetState()
    await storeActions.fbAudience.resetState()
    await storeActions.campaign.products.resetState()
    await storeActions.campaign.creativeContents.resetState()
    await storeActions.campaign.schedule.resetState()
    await storeActions.segmentation.resetState()
  }),
}
