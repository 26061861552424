import React, { useEffect, useLayoutEffect } from 'react'
import { useStoreActions } from 'easy-peasy'
import New from './New'
import Edit from './Edit'
import './index.scss'
import { getUUId } from 'Utils'

const CustomerSegmentDetail = ({ children }) => {
  const { resetState, setState } = useStoreActions((action: any) => action.customerSegments)

  useEffect(() => {
    return () => resetState()
  }, [resetState])

  useLayoutEffect(() => {
    setState({
      key: 'segment_temporary_id',
      value: getUUId() + '_' + Date.now(),
    })
  }, [])

  return <div className="CustomerSegmentPage w-full">{children}</div>
}

export const NewSegment = () => {
  return (
    <CustomerSegmentDetail>
      <New />
    </CustomerSegmentDetail>
  )
}
export const EditSegment = () => {
  return (
    <CustomerSegmentDetail>
      <Edit />
    </CustomerSegmentDetail>
  )
}
