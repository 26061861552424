import PartitionOutlined from '@ant-design/icons/PartitionOutlined'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { GrAnalytics } from 'react-icons/gr'
import { RiTrophyLine, RiLightbulbFlashLine } from 'react-icons/ri'
const Menu = [
  {
    id: 'analytics',
    name: 'Analytics',
    icon: GrAnalytics,
    onlySuperadmin: false,
    allowRoles: ['all'],
    subMenus: [
      {
        id: 'analytics-customers',
        name: 'Customers',
        onlySuperadmin: false,
        allowRoles: ['all'],
        isHasSubMenu: false,
        route: '/analytics/customers',
      },
      {
        id: 'analytics-products',
        name: 'Products',
        onlySuperadmin: false,
        allowRoles: ['all'],
        isHasSubMenu: false,
        route: '/analytics/products',
      },
      {
        id: 'analytics-marketing-campaigns',
        name: 'Marketing Campaigns',
        onlySuperadmin: false,
        allowRoles: ['all'],
        isHasSubMenu: false,
        route: '/analytics/marketing-campaigns',
      },
      // {
      //   id: 'analytics-sale-campaigns',
      //   name: 'Sale Channel',
      //   onlySuperadmin: false,
      //   allowRoles: ['all'],
      //   isHasSubMenu: false,
      //   route: '/analytics/sale-campaigns',
      // },
    ],
    route: '/analytics',
  },
  {
    id: 'segmentation',
    name: 'Segmentation',
    icon: HiOutlineUserGroup,
    onlySuperadmin: false,
    allowRoles: ['all'],
    isHasSubMenu: false,
    route: '/',
    subMenus: [
      {
        id: 'segments',
        name: 'Customer Segments',
        onlySuperadmin: false,
        allowRoles: ['all'],
        isHasSubMenu: false,
        route: '/customers/segments',
      },
    ],
  },
  {
    id: 'campaigns',
    name: 'Campaigns',
    icon: RiTrophyLine,
    onlySuperadmin: false,
    allowRoles: ['all'],
    subMenus: [
      {
        id: 'manual-campaigns',
        name: 'Manual Campaigns',
        onlySuperadmin: false,
        allowRoles: ['all'],
        isHasSubMenu: false,
        route: '/campaigns',
      },
      {
        id: 'customer_journey',
        name: 'Customer Journeys',
        icon: PartitionOutlined,
        onlySuperadmin: false,
        allowRoles: ['all'],
        subMenus: [],
        route: '/customer-journeys',
      },
      // {
      //   id: 'social-selling',
      //   name: 'Social Selling',
      //   onlySuperadmin: false,
      //   allowRoles: ['all'],
      //   isHasSubMenu: false,
      //   route: '/social-selling',
      // },
      // {
      //   id: 'store-farming',
      //   name: 'Store Farming',
      //   onlySuperadmin: false,
      //   allowRoles: ['all'],
      //   isHasSubMenu: false,
      //   route: '/store-farming',
      // },
    ],
  },

  // {
  //   id: 'experiments',
  //   name: 'Experiments',
  //   icon: RiLightbulbFlashLine,
  //   onlySuperadmin: false,
  //   allowRoles: ['all'],
  //   subMenus: [],
  //   route: '/',
  // },
]

export default Menu
