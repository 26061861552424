import axios from 'Utils/axios'
import { Criteria } from 'DimensionsFiltersTypes'
import { CancelTokenSource } from 'axios'

const ENDPOINT = '/customer-profiles'

export async function getCustomerProfile(id: string) {
  const resp = await axios.get(`customer-profiles/${id}`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getCustomerOverviewKeyMetrics({
  from_date,
  to_date,
  dimensions = [],
  cancelTokenSource,
}: {
  from_date?: string
  to_date?: string
  dimensions?: Criteria[]
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`${ENDPOINT}/analytics/overview/key-metrics`, {
    params: { from_date, to_date, dimensions: JSON.stringify(dimensions) },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getCustomerOverviewCharts({
  from_date,
  to_date,
  type,
  dimensions = [],
  frequency = 'daily',
  cancelTokenSource,
}: {
  from_date?: string
  to_date?: string
  type?: string
  dimensions?: Criteria[]
  frequency?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`${ENDPOINT}/analytics/overview/charts`, {
    params: {
      from_date,
      to_date,
      analytic_type: type,
      frequency,
      dimensions: JSON.stringify(dimensions),
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getCustomer360KeyMetrics({
  id,
  cancelTokenSource,
}: {
  id?: number
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`${ENDPOINT}/${id}/analytics/key-metrics`, {
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getCustomer360Info({ id }: { id: number }) {
  const resp = await axios.get(`${ENDPOINT}/${id}/info`, {})

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getCustomer360Charts({
  id,
  type,
  frequency,
  cancelTokenSource,
}: {
  id?: number
  type?: string
  frequency?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`${ENDPOINT}/${id}/analytics/charts`, {
    params: { analytic_type: type, frequency },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getCustomer360Products({
  id,
  type,
  cancelTokenSource,
}: {
  id?: number
  type?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`${ENDPOINT}/${id}/products`, {
    params: { product_type: type },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getCustomer360Activities({ id }: { id?: number }) {
  const resp = await axios.get(`${ENDPOINT}/${id}/activities`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
