import axios from 'Utils/axios'

const SEGMENT_ENDPOINT = '/audience-segments'
const DIMENSION_ENDPOINT = '/audience-dimensions'

export async function getCreators() {
  const resp = await axios.get(`${SEGMENT_ENDPOINT}/infos/creators`)

  // error handling
  if (resp.response) {
    throw resp.response
  }

  return { data: resp.data }
}

export async function getAudienceSegmentFilters() {
  const resp = await axios.get(`${SEGMENT_ENDPOINT}/analytics/filters`)

  // error handling
  if (resp.response) {
    throw resp.response
  }

  return { data: resp.data }
}

export async function getPredefinedSegment() {
  const resp = await axios.get(`${DIMENSION_ENDPOINT}/predefined_segment`)

  // error handling
  if (resp.response) {
    throw resp.response
  }

  return { data: resp.data }
}

export async function getStores() {
  const resp = await axios.get(`/stores/`, {
    params: { company: process.env.REACT_APP_COMPANY_NAME },
  })

  // error handling
  if (resp.response) {
    throw resp.response
  }

  return { data: resp.data }
}

export async function getAudienceSegments({ params }) {
  const resp = await axios.get(`${SEGMENT_ENDPOINT}/`, {
    params,
  })

  // error handling
  if (resp.response) {
    throw resp.response
  }

  return { data: resp.data.results }
}

export async function getAudienceSegment(id) {
  const resp = await axios.get(`${SEGMENT_ENDPOINT}/${id}`)

  // error handling
  if (resp.response) {
    throw resp.response
  }

  return { data: resp.data }
}

export async function delAudienceSegment(id) {
  const resp = await axios.delete(`${SEGMENT_ENDPOINT}/${id}`)

  // error handling
  if (resp.response) {
    throw resp.response
  }

  return { data: resp.data }
}

export async function createAudienceSegment({ body }) {
  const resp = await axios.post(`${SEGMENT_ENDPOINT}/`, {
    ...body,
  })

  // error handling
  if (resp.response) {
    throw resp.response
  }

  return { data: resp.data }
}

export async function updateAudienceSegment({ body, id, action }) {
  const resp = await axios.put(`${SEGMENT_ENDPOINT}/${id}/action?action=${action}`, {
    ...body,
  })

  // error handling
  if (resp.response) {
    throw resp.response
  }

  return { data: resp.data }
}

export async function getAudienceListing(id) {
  const resp = await axios.get(`${SEGMENT_ENDPOINT}/${id}/customer-profiles`)
  // error handling
  if (resp.response) {
    throw resp.response
  }

  return { data: resp.data }
}
