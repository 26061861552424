import React, { useState, useEffect, Fragment } from 'react'
import { Popover, Modal } from 'antd'
import _ from 'lodash'
import { useStoreActions } from 'easy-peasy'

import VirtualNode from './VirtualNode'
import CJPopover from 'Components/CJPopover'
import {
  NODE_TYPES,
  CJ_NODE_WIDTH,
  CJ_NODE_HEIGHT,
} from 'Containers/CustomerJourneyDesign/constants'
import SquareNode from './SquareNode'
import RoundNode from './RoundNode'
import NodeMenu from './NodeMenu'
import './index.scss'

const CJNode = (props) => {
  const {
    bgColor,
    value,
    level,
    nodeType,
    computeNodesTree,
    childNodes,
    node,
    status,
    x: left,
    y: top,
    changeTool,
    deleteNode,
    isRunning,
  } = props
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const [isAddPopoverVisible, setIsAddPopoverVisible] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [currentConfig, setCurrentConfig] = useState({
    ...NODE_TYPES[nodeType],
    data: nodeType,
    value: value,
  })

  const { setCJStates } = useStoreActions((actions) => actions.customerJourney)

  // hide popovers
  let CJContainer = document.querySelector('.cj-layout-content')
  const hidePopovers = (e) => {
    const targetNode =
      e.target.attributes.type?.value == nodeType && e.target.attributes.level.value == level
    // !targetNode && isMenuVisible && setIsMenuVisible(false)
    !targetNode && isPopoverVisible && setIsPopoverVisible(false)
    !targetNode && isAddPopoverVisible && setIsAddPopoverVisible(false)
  }

  CJContainer.addEventListener('click', hidePopovers, { once: true })
  // right click
  CJContainer.addEventListener('contextmenu', hidePopovers)

  CJContainer.addEventListener(
    'mousedown',
    () => {
      changeTool('auto')
    },
    { once: true }
  )

  useEffect(() => {
    setCurrentConfig({ ...NODE_TYPES[nodeType], data: nodeType, value: value })
  }, [nodeType])

  useEffect(() => {
    if (Array.isArray(value)) {
      currentConfig.value && value.splice(0, value.length, currentConfig.value)
    }
    value && typeof value === 'object' && Object.assign(value, currentConfig.value)
  }, [currentConfig])

  useEffect(() => {
    changeTool('none')
    setIsMenuVisible(false)
  }, [isPopoverVisible, isAddPopoverVisible])

  const onAddNewNode = (type, value) => {
    setCJStates({ key: 'isCJModified', value: true })
    computeNodesTree(node, type, value)
    setIsPopoverVisible(false)
  }

  const onUpdateNode = async (updatedValue) => {
    setCJStates({ key: 'isCJModified', value: true })
    setCurrentConfig({ ...currentConfig, value: updatedValue })
    setIsPopoverVisible(false)
  }

  const renderNode = () => {
    if (['wait', 'split'].includes(nodeType)) return <RoundNode {...props} bgColor={bgColor} />
    else if (nodeType === 'virtual') return false
    return <SquareNode nodeValue={currentConfig.value} {...props} bgColor={bgColor} />
  }

  const nodeMenuClick = (menuKey) => {
    if (menuKey === 'delete') {
      Modal.confirm({
        title: (
          <span>
            Delete step <span className="node-name">{NODE_TYPES[node.nodeType].name}</span> ?
          </span>
        ),
        content: 'Items below this step will be deleted as well!',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        className: 'node-prompt',
        onOk() {
          deleteNode(node)
        },
        onCancel() {},
      })
    }
  }
  let centerPoint
  let menuRadius
  if (['wait', 'split'].includes(node.nodeType)) {
    centerPoint = { x: 86, y: 25 }
    menuRadius = 60
  }
  return (
    <g
      onContextMenu={(e) => {
        e.preventDefault()
        setIsMenuVisible(!isMenuVisible)
        setIsPopoverVisible(false)
      }}
      transform={`translate(${left}, ${top})`}
      overflow="visible"
    >
      {isMenuVisible && (
        <foreignObject x={CJ_NODE_WIDTH} width={1} height={1}>
          <NodeMenu
            onClick={nodeMenuClick}
            isMenuVisible={isMenuVisible}
            node={node}
            centerPoint={centerPoint}
            menuRadius={menuRadius}
          />
        </foreignObject>
      )}
      <Popover
        placement="right"
        visible={isPopoverVisible}
        overlayClassName="CJPopover"
        trigger="click"
        onVisibleChange={(visible) => setIsPopoverVisible(!isPopoverVisible)}
        content={
          <CJPopover
            isRunning={isRunning}
            // status={status}
            isPopoverVisible={isPopoverVisible}
            setIsPopoverVisible={setIsPopoverVisible}
            currentConfig={currentConfig}
            value={value}
            onUpdateNode={onUpdateNode}
          />
        }
      >
        <g>{renderNode()}</g>
      </Popover>
      {nodeType === 'virtual' && (
        <VirtualNode
          setIsAddPopoverVisible={setIsAddPopoverVisible}
          isAddPopoverVisible={isAddPopoverVisible}
          onAddNewNode={onAddNewNode}
          {...props}
          bgColor={bgColor}
          isRunning={isRunning}
        ></VirtualNode>
      )}
    </g>
  )
}

CJNode.defaultProps = {
  top: 0,
  left: 0,
  radius: 7,
  bgColor: 'FF7376',
}

export default CJNode
