import React from 'react'
import { Checkbox } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
const options = [
  { label: 'Male', value: 0 },
  { label: 'Female', value: 1 },
]

const Gender = () => {
  const { setState } = useStoreActions((actions: any) => actions.customerSegments)
  const { genders } = useStoreState((state: any) => state.customerSegments)
  return (
    <Checkbox.Group
      value={genders}
      onChange={(value) => {
        setState({ key: 'need_insights_refresh', value: true })
        setState({ key: 'genders', value })
      }}
      options={options}
    />
  )
}

export default Gender
