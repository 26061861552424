import React from 'react'
import { action, thunk } from 'easy-peasy'
import moment from 'moment'
import axios from 'Utils/axios'
import { message } from 'antd'
import { needStringifyArray } from 'Utils'

const initState = {
  metrics: [],
  charts: [],
  forceRefresh: false,
  loading: ['key', false],
  filterList: null,
}

export default {
  ...initState,
  setAudienceAnalyticsStates: action((state, { key, value }) => {
    state[key] = value
  }),
  pushAnalytics: action((state, { key, value }) => {
    state[key].push(value)
  }),
  setForceRefresh: action((state, force) => {
    if (force) {
      state.charts = []
      state.metrics = []
    }
    state.forceRefresh = force
  }),
  resetStates: action((state) => ({ ...initState })),
  getFilters: thunk(async (actions, _) => {
    const resp = await axios.get(`audience-segments/analytics/filters`)

    if (resp.errors) return { error: resp.response.data }

    return actions.setAudienceAnalyticsStates({ key: 'filterList', value: resp.data })
  }),
  getAudienceAnalytics: thunk(
    async (actions, { code, type, filter, id, force }, { getState, getStoreState }) => {
      const { forceRefresh } = getState()
      const analyticsResp = await axios.get(`audience-segments/${id}/analytics`, {
        params: {
          code,
          force: forceRefresh,
          ...needStringifyArray(filter),
        },
      })

      if (analyticsResp.response) {
        return { error: analyticsResp.response.data }
      }
      if (analyticsResp.data.results)
        actions.pushAnalytics({
          key: type,
          value: {
            key: code,
            label: code.replace(/_/g, ' '),
            value:
              type === 'charts'
                ? analyticsResp.data.results
                : Object.entries(analyticsResp.data.results[0])[0][1] || 0,
          },
        })

      return { ok: true }
    }
  ),
}
