import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { formatErrors } from 'Utils'
import { getPredefinedSegment } from 'Services/customer-segments'

const PredefinedSegment = () => {
  const { predefined_segment, predictive_segment } = useStoreState(
    (state) => state.customerSegments
  )
  const { setState, setCriteriaGroupsDefault } = useStoreActions(
    (action: any) => action.customerSegments
  )
  const [data, setData] = useState<{ code: string; name: string; type: string }[]>([])
  const [errors, setErrors] = useState('')

  const fetchData = async () => {
    try {
      const resp = await getPredefinedSegment()
      setErrors('')
      setData(resp.data)
    } catch (err: any) {
      setErrors(formatErrors(err.response?.data) || 'Something went wrong')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Select
      allowClear
      value={predefined_segment || undefined}
      disabled={predictive_segment}
      placeholder="Select"
      className="w-full segment-predefine-select "
      onChange={(value) => {
        if (value) {
          setCriteriaGroupsDefault()
          setState({
            key: 'store_ids',
            value: [],
          })
        }
        setState({ key: 'predefined_segment', value })
        setState({ key: 'need_insights_refresh', value: true })
      }}
    >
      {data.map((segment) => (
        <Select.Option key={segment.code} value={segment.code}>
          <div className="truncate">{`${segment.name}`}</div>
        </Select.Option>
      ))}
      {errors && <div className="text-negative">{errors}</div>}
    </Select>
  )
}

export default PredefinedSegment
