import React, { useState } from 'react'
import { Select } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'

let options: { key: number; value: number }[] = []

for (let i = 0; i <= 80; i++) {
  options.push({ key: i + 1, value: i + 1 })
}

const Gender = () => {
  const { setState } = useStoreActions((actions: any) => actions.customerSegments)
  const { age_min, age_max } = useStoreState((state: any) => state.customerSegments)

  return (
    <div className="flex items-center w-full">
      <span>From</span>
      <span className="mx-2 w-1/2">
        <Select
          onChange={(value) => {
            setState({ key: 'need_insights_refresh', value: true })
            setState({ key: 'age_min', value })
          }}
          showSearch
          value={age_min}
          options={options}
        />
      </span>
      <span>To</span>
      <span className="mx-2 w-1/2">
        <Select
          onChange={(value) => {
            setState({ key: 'need_insights_refresh', value: true })
            setState({ key: 'age_max', value })
          }}
          showSearch
          value={age_max}
          options={options}
        />
      </span>
    </div>
  )
}

export default Gender
