import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { message } from 'antd'

import { getCreators } from 'Services/customer-segments'
import SegmentListView from './index.view'

export type Filters = {
  used_in: string
  type: string
  created_by: string
  query: string
  page: number
  per_page?: number
  order_by?: string
}

const SegmentList = () => {
  const history = useHistory()
  const queryObject = queryString.parse(history.location.search)

  const [filters, setFilters] = useState<Filters>({
    type: queryObject?.type?.toString() || '[]',
    used_in: queryObject?.used_in?.toString() || '[]',
    created_by: queryObject?.created_by?.toString() || '[]',
    query: queryObject?.query?.toString() || '',
    page: parseInt(queryObject?.page?.toString() || '1'),
    per_page: parseInt(queryObject?.per_page?.toString() || '20'),
  })

  const [creators, setCreators] = useState<{ value: string; label: string }[]>([])

  const handleStatistics = async (record) => {
    window.open(`/analytics/segment360/${record.id}?${history.location.search}`, '_self')
  }

  const handleCustomerListing = async (record) => {
    window.open(`/analytics/customer-list/${record.id}`, '_self')
  }

  const handleTableChange = (tablePagination, _, sorter) => {
    setFilters({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.order
        ? sorter.order === 'ascend'
          ? sorter.field
          : `-${sorter.field}`
        : null,
    })
  }

  // useEffect(() => {
  //   history.push({ search: queryString.stringify({ ...queryObject, ...filters }) })
  // }, [filters, history])

  useEffect(() => {
    const handleFilterList = async () => {
      try {
        const fetchSegmentCreators = async () => {
          try {
            const resp = await getCreators()
            setCreators(
              resp.data.map((creator) => ({
                label: `${creator.first_name} ${creator.last_name}`,
                value: creator.id.toString(),
              }))
            )
          } catch (err: any) {
            message.error(err.message)
          }
        }
        fetchSegmentCreators()
      } catch (err) {}
    }
    handleFilterList()
  }, [])

  return (
    <div className="segmentList">
      <SegmentListView
        createdByList={creators}
        setFilters={setFilters}
        filters={filters}
        handleTableChange={handleTableChange}
        handleStatistics={handleStatistics}
        handleCustomerListing={handleCustomerListing}
      />
    </div>
  )
}

export default SegmentList
