import React from 'react'
import moment from 'moment'
import { Modal, DatePicker } from 'antd'

import StatusTag from 'Components/StatusTag'

const { RangePicker } = DatePicker

const CJInfos = ({ isModalVisible, setIsModalVisible, CJStates, setCJStates }) => {
  const computeTagColor = (tag) => {
    switch (tag) {
      case 'waiting':
        return 'yellow'
      case 'init':
        return 'light-gray'
      case 'paused':
        return 'brown'
      case 'completed':
        return 'blue'
      case 'running':
        return 'green'
      default:
        return 'gray'
    }
  }

  return (
    <Modal
      visible={isModalVisible}
      className="cj-layout-info"
      footer=""
      onCancel={() => setIsModalVisible(false)}
    >
      <div className="cj-info-wrapper">
        <div className="cj-info-item">
          <h3 className="cj-info-item__label">Status</h3>
          {CJStates.status ? (
            <StatusTag color={computeTagColor(CJStates.status)} status={CJStates.status} />
          ) : (
            <span>Not available</span>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default CJInfos
