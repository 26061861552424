const SMS = [
  {
    name: 'message_content',
    type: 'dynamic_content',
    label: 'Content',
    isRequired: true,
  },
]

export default SMS
