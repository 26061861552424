import { StackBarData } from 'CustomerSegmentInsightsTypes'

export const ANALYTIC_TYPES = [
  'reachable',
  'revenue',
  'CLC',
  'new_return',
  'LTV',
  'cluster',
  'sale_channel',
  'AOV',
  'item_per_customer',
  'bill_per_customer',
  'stats',
  'top_purchase_category',
]
export const INIT_STACK_BAR = [{ label: '', percent: 0 }] as StackBarData[]

export const INIT_DATA = {
  total_audience: 0,
  count_customer: 0,
  count_audience: 0,
  count_visitor: 0,
  count_customer_by_store: [],
  top_purchase_category: [],
  reachable: [],
  revenue: INIT_STACK_BAR,
  AOV: INIT_STACK_BAR,
  CLC: INIT_STACK_BAR,
  new_return: INIT_STACK_BAR,
  LTV: INIT_STACK_BAR,
  cluster: INIT_STACK_BAR,
  sale_channel: INIT_STACK_BAR,
  item_per_customer: INIT_STACK_BAR,
  bill_per_customer: INIT_STACK_BAR,
  stats: [],
}

export const INIT_ERRORS = {
  total_audience: '',
  count_customer: '',
  count_audience: '',
  count_visitor: '',
  reachable: '',
  revenue: '',
  AOV: '',
  CLC: '',
  new_return: '',
  LTV: '',
  cluster: '',
  sale_channel: '',
  item_per_customer: '',
  bill_per_customer: '',
  stats: '',
  top_purchase_category: '',
}

export const INIT_LOADING = {
  is_total_audience_loading: true,
  is_count_customer_loading: true,
  is_count_audience_loading: true,
  is_count_visitor_loading: true,
  is_reachable_loading: true,
  is_revenue_loading: true,
  is_AOV_loading: true,
  is_CLC_loading: true,
  is_new_return_loading: true,
  is_LTV_loading: true,
  is_cluster_loading: true,
  is_sale_channel_loading: true,
  is_item_per_customer_loading: true,
  is_bill_per_customer_loading: true,
  is_stats_loading: true,
  is_top_purchase_category_loading: true,
}
