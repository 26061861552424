import React, { useMemo } from 'react'
import { capitalize, uniqBy } from 'lodash'
import { message, Skeleton } from 'antd'

import { CampaignPivotTypes, getBQColumns } from 'Services/Analytics/Pivot.service'
import { capitalizeFirstLetter } from 'Utils'
import PivotElementList from './Components/PivotElementList'
import Table from './Components/Table'
import { PivotTableContainerProps, SelectOptions, DRAG_TYPES } from './index.constants'

import './index.scss'

const PivotTable = ({
  isRanking,
  className = '',
  defaultMetrics = [],
  defaultDimensions = [],
  defaultColumns = [],
  object = 'campaign',
  objectId,
  dateRange = [],
  type = CampaignPivotTypes.ProductInsight,
  isCustomer,
  hideDimensionSelects,
  hideMetricSelects,
  hideColumnSelects,
  currentPage,
  dimension_segments,
  barcode,
}: PivotTableContainerProps) => {
  const [metricElements, setMetricElements] = React.useState(defaultMetrics)
  const [dimensionElements, setDimensionElements] = React.useState(defaultDimensions)
  const [columnElements, setColumnElements] = React.useState(defaultColumns)
  const [selectableOptions, setSelectableOptions] = React.useState<SelectOptions[]>([])
  const [isLoading, setIsLoading] = React.useState(true)

  const computeDisabledOptions = (options) => {
    const newOptions = options.map((column) =>
      uniqBy([...metricElements, ...dimensionElements, ...columnElements], (e) => e.code)
        .map((dimension) => dimension.code)
        ?.includes(column.code)
        ? { ...column, disabled: true }
        : { ...column, disabled: false }
    )

    return newOptions
  }

  const fetchBQColumns = async () => {
    try {
      setIsLoading(true)
      const columns = await getBQColumns(type, currentPage, isRanking, barcode)
      columns.forEach(function (obj) {
        obj.code = obj.field_name
        obj.name = capitalizeFirstLetter(obj.field_name)
        obj.type = obj.data_type
        delete obj.field_name
        delete obj.data_type
      })

      const sortedColumns = columns.sort(function (a, b) {
        if (a.code < b.code) {
          return -1
        }
        if (a.code > b.code) {
          return 1
        }
        return 0
      })

      setSelectableOptions(computeDisabledOptions(sortedColumns))
    } catch (err: any) {
      message.error(err.message)
    }
    setIsLoading(false)
  }

  const renderTable = useMemo(() => {
    return (
      <Table
        barcode={barcode}
        isRanking={isRanking}
        type={type}
        dimensions={dimensionElements}
        columns={columnElements}
        metrics={metricElements}
        object={object}
        objectId={objectId}
        dateRange={dateRange}
        isCustomer={isCustomer}
        currentPage={currentPage}
        dimension_segments={dimension_segments}
      ></Table>
    )
  }, [dimensionElements, metricElements, columnElements])

  React.useEffect(() => {
    if (!selectableOptions.length) fetchBQColumns()
    if (selectableOptions.length) {
      setSelectableOptions(computeDisabledOptions(selectableOptions))
    }
  }, [dimensionElements, metricElements, columnElements])

  return (
    <Skeleton paragraph={{ rows: 15 }} loading={isLoading} active>
      <div className={`PivotTableContainer ${className}`}>
        {!hideDimensionSelects && (
          <PivotElementList
            options={selectableOptions}
            title="Dimensions"
            elements={dimensionElements}
            onChange={setDimensionElements}
          ></PivotElementList>
        )}
        {!hideMetricSelects && (
          <PivotElementList
            className="mt-2"
            options={selectableOptions}
            title="Metrics"
            isMetric
            elements={metricElements}
            onChange={setMetricElements}
          ></PivotElementList>
        )}
        {!hideColumnSelects && (
          <PivotElementList
            className="mt-2"
            options={selectableOptions}
            title="Columns"
            elements={columnElements}
            onChange={setColumnElements}
          ></PivotElementList>
        )}

        {renderTable}
      </div>
    </Skeleton>
  )
}

export default PivotTable
