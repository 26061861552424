export type KaipassSchema = {
  id: number
  created_at: string
  updated_at: string
  name: string
  code: string
  groups: {
    title: string
    fields: string[]
  }[]
  schemas: {
    name: string
    type: string
    label: string
    isRequired: boolean
    options?: {
      name: string
      code: string
    }[]
  }[]
}

export type ValuesKaipass = {
  title: string
  content: string
  image: string
  route: string
  btn_name: string
  url: string
  utm_source: string
  utm_medium: string
  utm_campaign: string
}

export const KAIPASS_SCHEMA: KaipassSchema = {
  id: 4,
  created_at: '2021-04-06T01:56:16.472958',
  updated_at: '2021-04-06T15:32:13.744847',
  name: 'Kaipass Notification',
  code: 'kaipass',
  groups: [
    {
      title: 'Action',
      fields: ['route', 'btnName', 'url', 'utm_source', 'utm_medium', 'utm_campaign'],
    },
  ],
  schemas: [
    {
      name: 'title',
      type: 'string',
      label: 'Title',
      isRequired: true,
    },
    {
      name: 'content',
      type: 'text_area',
      label: 'Content',
      isRequired: true,
    },
    {
      name: 'image',
      type: 'image',
      label: 'Image',
      isRequired: true,
    },
    {
      name: 'route',
      type: 'select',
      label: 'Route',
      isRequired: true,
      options: [
        { name: 'News', code: 'news' },
        { name: 'Brand Detail', code: 'brand-detail' },
        { name: 'Reward Detail', code: 'reward-detail' },
        { name: 'Web', code: 'web' },
      ],
    },
    {
      name: 'btn_name',
      type: 'string',
      label: 'Button Name',
      isRequired: true,
    },
    {
      name: 'url',
      type: 'string',
      label: 'Link',
      isRequired: true,
    },
    {
      name: 'utm_source',
      type: 'string',
      label: 'UTM Source',
      isRequired: true,
    },
    {
      name: 'utm_medium',
      type: 'string',
      label: 'UTM Medium',
      isRequired: true,
    },
    {
      name: 'utm_campaign',
      type: 'string',
      label: 'UTM Campaign',
      isRequired: true,
    },
  ],
}

export const INIT_VALUES: ValuesKaipass = {
  title: 'string',
  content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  image: '',
  route: 'news',
  btn_name: 'string',
  url: 'string',
  utm_source: 'string',
  utm_medium: 'string',
  utm_campaign: 'string',
}
