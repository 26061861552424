import React, { useMemo } from 'react'
import { Divider, Skeleton, Alert, Tooltip } from 'antd'
import ReactPlaceholder from 'react-placeholder'

import BarChart from 'Components/Charts/NewBarChart'
import StackBar from 'Pages/Audiences/Detail/Components/Insights/StackBar'

import { InsightsProps } from 'CustomerSegmentInsightsTypes'
import { BarChartPlaceholder } from 'Components/Placeholders/Charts'

const useInsights = (props: InsightsProps) => {
  const { isLoading, data, errors, onBarClick } = props

  const StackBarTemplate = ({ data, title, isLoading, clickable }) => {
    return (
      <StackBar
        displayPercent
        title={title}
        data={data}
        showLegend={true}
        isLoading={isLoading}
        onBarClick={(bar) => {
          clickable &&
            onBarClick &&
            onBarClick({
              type: data[0]?.dimension_type,
              operator: 'EQUAL',
              value: bar.label === 'Unknown' ? 'null' : bar.label,
            })
        }}
      />
    )
  }

  const TopPurchase = useMemo(() => {
    const category = data.top_purchase_category.sort((a, b) => b.value - a.value)

    const labels = category.map((item) => item.label)
    const chartData = category.map((cat, i) => {
      let percent = 100
      if (i !== 0) {
        percent = (cat.value / category[0].value) * 100
      }
      return {
        label: cat.label,
        values: { [cat.label]: percent },
      }
    })
    if (errors.top_purchase_category)
      return (
        <div>
          <h4 className="font-semibold">Top purchase category</h4>
          <Alert type="error" message={errors.top_purchase_category} />
        </div>
      )
    return (
      <div className="insight-top-purchase">
        <h4 className="font-semibold mb-4">Top purchase category</h4>
        <div style={{ width: '100%', height: 200 }}>
          <ReactPlaceholder
            className="w-full"
            showLoadingAnimation
            ready={!isLoading.is_top_purchase_category_loading}
            customPlaceholder={<BarChartPlaceholder isHorizontal />}
          >
            <BarChart
              data={chartData}
              labels={labels}
              hideLegends
              type={'horizontalBar'}
              isPercentage
              isStacked={false}
            />
          </ReactPlaceholder>
        </div>
      </div>
    )
  }, [
    data.top_purchase_category,
    isLoading.is_top_purchase_category_loading,
    errors.top_purchase_category,
  ])

  const Revenue = useMemo(() => {
    if (errors.revenue)
      return (
        <div>
          <h4 className="font-semibold">Total Revenue</h4>
          <Alert type="error" message={errors.revenue} />
        </div>
      )
    return (
      <StackBar
        displayPercent={true}
        title={'Total Revenue'}
        data={data.revenue}
        showLegend={true}
        isLoading={isLoading.is_revenue_loading}
      />
    )
  }, [data.revenue, isLoading.is_revenue_loading, errors.revenue])

  const AOV = useMemo(() => {
    if (errors.AOV)
      return (
        <div>
          <h4 className="font-semibold">AOV</h4>
          <Alert type="error" message={errors.AOV} />
        </div>
      )
    return (
      <StackBarTemplate
        data={data.AOV}
        title="AOV"
        isLoading={isLoading.is_AOV_loading}
        clickable
      />
    )
  }, [data.AOV, errors.AOV, isLoading.is_AOV_loading, onBarClick])

  const ItemPerCustomer = useMemo(() => {
    if (errors.item_per_customer)
      return (
        <div>
          <h4 className="font-semibold">Item per customer</h4>
          <Alert type="error" message={errors.item_per_customer} />
        </div>
      )
    return (
      <StackBarTemplate
        data={data.item_per_customer}
        title={'Item per customer'}
        isLoading={isLoading.is_item_per_customer_loading}
        clickable
      />
    )
  }, [
    data.item_per_customer,
    isLoading.is_item_per_customer_loading,
    errors.item_per_customer,
    onBarClick,
  ])

  const BillPerCustomer = useMemo(() => {
    if (errors.bill_per_customer)
      return (
        <div>
          <h4 className="font-semibold">Bill per customer</h4>
          <Alert type="error" message={errors.bill_per_customer} />
        </div>
      )
    return (
      <StackBarTemplate
        clickable
        data={data.bill_per_customer}
        title={'Bill per customer'}
        isLoading={isLoading.is_bill_per_customer_loading}
      />
    )
  }, [
    data.bill_per_customer,
    isLoading.is_bill_per_customer_loading,
    errors.bill_per_customer,
    onBarClick,
  ])
  const NewReturn = useMemo(() => {
    if (errors.new_return)
      return (
        <div>
          <h4 className="font-semibold">New - Return</h4>
          <Alert type="error" message={errors.new_return} />
        </div>
      )
    return (
      <StackBarTemplate
        clickable
        data={data.new_return}
        title={'New - Return'}
        isLoading={isLoading.is_new_return_loading}
      />
    )
  }, [data.new_return, isLoading.is_new_return_loading, errors.new_return, onBarClick])
  const CLC = useMemo(() => {
    if (errors.CLC)
      return (
        <div>
          <h4 className="font-semibold">CLC</h4>
          <Alert type="error" message={errors.CLC} />
        </div>
      )
    return (
      <StackBarTemplate
        clickable
        data={data.CLC}
        title={'CLC'}
        isLoading={isLoading.is_CLC_loading}
      />
    )
  }, [data.CLC, isLoading.is_CLC_loading, errors.CLC, onBarClick])
  const LTV = useMemo(() => {
    if (errors.LTV)
      return (
        <div>
          <h4 className="font-semibold">LTV</h4>
          <Alert type="error" message={errors.LTV} />
        </div>
      )
    return (
      <StackBarTemplate
        clickable
        data={data.LTV}
        title={'LTV'}
        isLoading={isLoading.is_LTV_loading}
      />
    )
  }, [data.LTV, isLoading.is_LTV_loading, errors.LTV, onBarClick])
  const Cluster = useMemo(() => {
    if (errors.cluster)
      return (
        <div>
          <h4 className="font-semibold">Cluster</h4>
          <Alert type="error" message={errors.cluster} />
        </div>
      )
    return (
      <StackBarTemplate
        clickable
        data={data.cluster}
        title={'Cluster'}
        isLoading={isLoading.is_cluster_loading}
      />
    )
  }, [data.cluster, isLoading.is_cluster_loading, errors.cluster, onBarClick])
  const SaleChannel = useMemo(() => {
    if (errors.sale_channel)
      return (
        <div>
          <h4 className="font-semibold">Sale channel</h4>
          <Alert type="error" message={errors.sale_channel} />
        </div>
      )
    return (
      <StackBarTemplate
        clickable
        data={data.sale_channel}
        title={'Sale Channel'}
        isLoading={isLoading.is_sale_channel_loading}
      />
    )
  }, [data.sale_channel, isLoading.is_sale_channel_loading, errors.sale_channel, onBarClick])

  return {
    Revenue,
    AOV,
    ItemPerCustomer,
    BillPerCustomer,
    NewReturn,
    CLC,
    LTV,
    Cluster,
    SaleChannel,
    TopPurchase,
  }
}

export default useInsights
