import { Empty } from 'antd'
import LoadingIcon from 'Components/LoadingIcon'
import axios from 'axios'
import React, { useEffect } from 'react'
import { Alert, Skeleton } from 'antd'
import DecorateTable from './tableDecorator'
import {
  getStoreListingPivotTableContent,
  getSaleOverviewPivotTableContent,
  getStore360PivotTableContent,
} from 'Services/Analytics/Pivot.service'
import { formatErrors, needStringifyArray } from 'Utils'
import { PivotTableProps } from '../index.constants'
import tableDecorator from './tableDecorator'
import OverlayLoading from 'Components/OverlayLoading'
import { useStoreState } from 'easy-peasy'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

const PivotTable = ({ store_id, location, cate_id, analytic_type, isStore }: PivotTableProps) => {
  const [loading, setLoading] = React.useState(false)
  const [drawing, setDrawing] = React.useState(false)
  const [error, setError] = React.useState('')
  const [htmlString, setHTMLString] = React.useState('')
  const tableRef = React.useRef<HTMLDivElement>(null)

  const history = useHistory()
  const queryObject = queryString.parse(history.location.search)

  // SIDE EFFECTS

  // let cancelToken
  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        setError('')
        setLoading(true)
        let html

        html = isStore
          ? await getStoreListingPivotTableContent({
              category_id: cate_id,
              cancelTokenSource,
            })
          : location == 'store360'
          ? await getStore360PivotTableContent({
              category_id: cate_id,
              analytic_type,
              store_id,
              cancelTokenSource: cancelTokenSource,
            })
          : await getSaleOverviewPivotTableContent({
              category_id: cate_id,
              analytic_type,
              dimensions: queryObject?.dimensions?.toString() || '[]',
              cancelTokenSource: cancelTokenSource,
            })
        setHTMLString(html?.data?.htmlString || html?.data?.data)
        setDrawing(true)
        // DECORATE table
        setTimeout(() => {
          tableDecorator(tableRef, { sortable: false })
          setDrawing(false)
        }, 700)
      } catch (error: any) {
        if (error.message === 'cancelled') return
        setError(formatErrors(error?.response?.data))
      }
      setLoading(false)
    }
    fetchData()

    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [cate_id])

  // RENDER
  if (error) return <Alert message={error} type="error" />

  if (!htmlString && !loading) return <Empty></Empty>

  return (
    <div className="PivotTableComponent">
      {drawing && <OverlayLoading></OverlayLoading>}
      <Skeleton paragraph={{ rows: 10 }} loading={loading} active>
        <div ref={tableRef} dangerouslySetInnerHTML={{ __html: htmlString }}></div>
      </Skeleton>
    </div>
  )
}

export default PivotTable
