import React from 'react'

import PivotTable from 'Components/Charts/PivotTable'
import AreaChart from 'Components/Charts/AreaChart'
import string from './htmtString'
import './index.scss'
import tableDecorator from 'Components/Charts/PivotTable/Components/tableDecorator'
const Test = () => {
  const tableRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    tableDecorator(tableRef, 3)
  }, [])
  return (
    <div style={{ paddingTop: 150 }}>
      <div className="PivotTableContainer">
        <div className="PivotTableComponent">
          <div ref={tableRef} dangerouslySetInnerHTML={{ __html: string }}></div>
        </div>
      </div>
    </div>
  )
}

export default Test
