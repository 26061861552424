import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import { createAxios } from 'Utils/axios'
import { formatErrors } from 'Utils'

const useHook = (customerId) => {
  const [customer, setCustomer] = useState<any>(null)
  const [recommendProductData, setRecommendProductData] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  useEffect(() => {
    ;(async () => {
      const axios = createAxios({ response: { toCamel: true } })
      if (!customerId) return message.error('ps_id not found!')
      try {
        const resp = await axios('/customer-profiles/store-farming/', {
          params: { ps_id: customerId },
        })
        setCustomer(resp.data)
      } catch (error: any) {
        setError(error.response)
        message.error(formatErrors(error?.response?.data))
      }
    })()
  }, [customerId])

  useEffect(() => {
    ;(async () => {
      const axios = createAxios({ response: { toCamel: true } })
      if (!customerId) return message.error('ps_id not found!')
      try {
        const resp = await axios('/customer-profiles/store-farming/recommended-products', {
          params: { ps_id: customerId },
        })
        setRecommendProductData(resp.data)
      } catch (error: any) {
        setError(error.response)
        message.error(formatErrors(error.response.data))
      }
    })()
  }, [customerId])

  return { customer, error, setCustomer, recommendProductData }
}

export default useHook
