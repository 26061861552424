import React from 'react'

import { Popover } from 'antd'
import ColumnSelect from './ColumnSelect'
import PlusCircleFilled from '@ant-design/icons/PlusCircleFilled'
import { ListManager } from 'react-beautiful-dnd-grid'

import { PivotElementListProps, Element } from '../index.constants'
import { useStoreState } from 'easy-peasy'
import MetricPopoverModal from './MetricPopoverModal'

import { cloneDeep } from 'lodash'
import PivotElement from './PivotElement'
const PivotElementList = ({
  title,
  isMetric,
  elements,
  onChange,
  className = '',
  options,
}: PivotElementListProps) => {
  const [visible, setVisible] = React.useState(false)

  const moveElement = (dragIndex, dropIndex) => {
    if (dragIndex === dropIndex) return

    const tmpEles = cloneDeep(elements)
    const newElements = tmpEles.map((ele, index) => {
      if (index === dropIndex) {
        return tmpEles[dragIndex]
      }

      if (dropIndex > dragIndex) {
        if (index >= dragIndex && index < dropIndex) {
          return tmpEles[index + 1]
        }
      }

      if (dropIndex < dragIndex) {
        if (index <= dragIndex && index > dropIndex) {
          return tmpEles[index - 1]
        }
      }

      return ele
    })
    onChange(newElements)
  }

  const toggleVisible = () => {
    setVisible(!visible)
  }

  const addElement = (value) => {
    setVisible(true)
    const newElement = options.find((f) => f.code === value)
    if (newElement) onChange([...elements, newElement])
    setVisible(false)
  }

  const addMetric = (cloumn, aggregate) => {
    setVisible(true)
    let newElement = options.find((f) => f.code === cloumn)
    if (!newElement) return
    newElement['aggregate'] = aggregate
    if (newElement) onChange([...elements, newElement])
    setVisible(false)
  }

  const handleChange = (value, index: number) => {
    setVisible(true)
    const newElement = options.find((f) => f.code === value)

    const newElements = elements.map((el, i) => {
      if (index === i) return { code: newElement?.code }
      return el
    })

    onChange(newElements as Element[])
    setVisible(false)
  }

  const handleMetricChange = (column, aggregate, index) => {
    const newElements = elements.map((el, i) => {
      if (index === i) return { code: column, aggregate }
      return el
    })
    onChange(newElements as Element[])
  }

  const removeElement = (index) => {
    elements.splice(index, 1)
    onChange([...elements])
  }

  return (
    <div className={`PivotElementList ${className}`}>
      <div className="east-container relative">
        <span>{title}</span>
        <Popover
          visible={visible}
          placement="bottom"
          trigger="click"
          destroyTooltipOnHide={true}
          onVisibleChange={toggleVisible}
          content={
            isMetric ? (
              <MetricPopoverModal
                options={options}
                defaultColumnValue={null}
                onAdd={addMetric}
                onChange={handleMetricChange}
              ></MetricPopoverModal>
            ) : (
              <ColumnSelect
                options={options}
                defaultValue={null}
                autoFocus={true}
                onChange={addElement}
              ></ColumnSelect>
            )
          }
        >
          <PlusCircleFilled style={{ color: 'var(--brand_primary)' }} onClick={toggleVisible} />
        </Popover>
      </div>
      <div className="flex flex-wrap">
        <ListManager
          items={elements}
          direction="horizontal"
          maxItems={1}
          render={(el) => (
            <PivotElement
              options={options}
              className="mr-2 mb-2"
              key={`${el.code}`}
              index={elements.findIndex((f) => f.code === el.code)}
              code={el.code}
              aggregate={el.aggregate}
              isMetric={isMetric}
              onRemove={removeElement}
              onChange={handleChange}
              onMetricChange={handleMetricChange}
            ></PivotElement>
          )}
          onDragEnd={moveElement}
        />
      </div>
    </div>
  )
}

export default PivotElementList
