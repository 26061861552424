import React from 'react'
import { Card } from 'antd'
import icon_fb from 'Assets/images/icon-fb.png'

const { Meta } = Card

const LinkClicks = ({ image_url, link_to_page, headline, description, callToAction }) => {
  return (
    <Card
      className="preview-info"
      bordered={false}
      tabBarExtraContent={null}
      cover={
        <img
          alt="example"
          src={image_url || 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'}
        />
      }
      actions={[
        // <span><LikeOutlined key="like"/> Like</span>,
        <span className="action-items">
          <span
            className="item like-action"
            style={{
              background: `url(${icon_fb}) no-repeat`,
            }}
          ></span>
          &nbsp;
          <strong className="item-text">Like</strong>
        </span>,
        <span className="action-items">
          <span
            className="item comment-action"
            style={{
              background: `url(${icon_fb}) no-repeat`,
            }}
          ></span>
          &nbsp;
          <strong className="item-text">Comment</strong>
        </span>,
        <span className="action-items">
          <span
            className="item share-action"
            style={{
              background: `url(${icon_fb}) no-repeat`,
            }}
          ></span>
          &nbsp;
          <strong className="item-text">Share</strong>
        </span>,
      ]}
    >
      <Meta
        description={
          <>
            <div className="preview-description__linkClick">
              <div>
                {!!link_to_page && link_to_page.toUpperCase()}

                <h2>{headline}</h2>

                <span>{description}</span>
              </div>

              <button>{!!callToAction && callToAction.toUpperCase()}</button>
            </div>
            <div className="horizon-line_bg">
              <br></br>
              <div className="horizon-line_content"></div>
            </div>
          </>
        }
      />
    </Card>
  )
}

export default LinkClicks
