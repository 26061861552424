import React from 'react'
import ProductListing from './ProductListing'

import ProductItem from './ProductListing/ProductItem'

const Listing = () => {
  return (
    <div>
      <div>
        <ProductListing />
      </div>
    </div>
  )
}

export default Listing
