import React, { useEffect, useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { FacebookDimensions } from 'CustomerSegmentTypes'
import { States } from 'Models/customer-segments'
import { formatCitiesForAPIs, formatInterestsForAPIs } from '../../helpers'
import { getDimensionCounts } from 'Services/dimensions'
import PercentagePieChart from '../Insights/PercentagePieChart'
import LoadingIcon from 'Components/LoadingIcon'
import { formatErrors } from 'Utils'
import { Alert } from 'antd'

const FacebookInsights = () => {
  const { age_min, age_max, cities, interests, genders, need_insights_refresh } = useStoreState(
    (state: { customerSegments: States }) => state.customerSegments
  )
  const { setState } = useStoreActions((actions: any) => actions.customerSegments)
  const [count, setCount] = useState<number>(0)
  const [errors, setErrors] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const fbDimensions: FacebookDimensions = {
    age_min,
    age_max,
    genders,
    geo_locations: { cities: formatCitiesForAPIs(cities) },
    interests: formatInterestsForAPIs(interests),
    internal_data: { cities, interests },
  }

  useEffect(() => {
    const fetchCount = async () => {
      if (!need_insights_refresh) return

      setIsLoading(true)
      try {
        const resp = await getDimensionCounts({
          data: { dimensions: JSON.stringify(fbDimensions) },
        })
        setCount(resp?.data?.count || 0)
        setErrors('')
      } catch (err: any) {
        setErrors(formatErrors(err?.response?.data))
      }
      setIsLoading(false)
      setState({ key: 'need_insights_refresh', value: false })
    }
    fetchCount()
  }, [need_insights_refresh])

  // const RenderOverlay = () => (
  //   <div className="insights-overlay 	pt-40">
  //     <button
  //       className="reload-button"
  //       onClick={() => {
  //         fetchCount()
  //       }}
  //     >
  //       <PlayIcon />
  //     </button>
  //   </div>
  // )

  if (isLoading)
    return (
      <div style={{ height: 100 }} className="flex w-full justify-center p-5">
        <LoadingIcon style={{ width: 40 }} />
      </div>
    )
  return (
    <div id="FacebookInsights">
      {errors && (
        <div className="pb-1">
          <Alert type="error" message={errors} />
        </div>
      )}

      <PercentagePieChart label="audience" isLoading={isLoading} total={count} count={count} />
      <div className="insights-reachable mt-4">
        <p className="text-lg mb-2">Reachable via</p>
        <div className="insights-reachable-box">
          <div className="flex-1">
            <h1 className="text-xl font-semibold">100%</h1>
            <h2>Facebook</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FacebookInsights
