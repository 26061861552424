import React from 'react'
import { Typography } from 'antd'

import List from './List'
import { NewSegment, EditSegment } from './Detail'

import CheckPermission from 'Components/CheckPermission'
import './index.scss'

const allowRoles = ['owner']

const AudienceList = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
}

const AudienceNew = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={NewSegment} {...props} />
}
const AudienceDetails = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={EditSegment} {...props} />
}

export { AudienceNew, AudienceList, AudienceDetails }
