const kFormatter = (num: number): string => {
  const thousandsSeparator = (num: number): string =>
    num
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  switch (true) {
    case num === 0:
      return '0'
    case !num:
      return 'N/A'
    case num >= 1.0e9:
      return (num / 1.0e9).toFixed(1) + 'B'
    case num >= 1.0e6:
      return (num / 1.0e6).toFixed(1) + 'M'
    case num >= 1.0e3:
      return thousandsSeparator(num)
    default:
      return num.toFixed(1).toString()
  }
}

export const thousandsSeparator = (num: number): string =>
  num
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const formatNum = (num: string): string => {
  var formatNumber = parseFloat(num)

  if (formatNumber > 1000) {
    return kFormatter(formatNumber)
  }
  // if is float
  if (formatNumber % 1 === 0) {
    return formatNumber.toString()
  }

  return formatNumber.toFixed(2).toString()
}

export default kFormatter
