import React from 'react'
import { Select } from 'antd'
import { ColumnSelectProps } from '../index.constants'
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled'
import { useStoreState } from 'easy-peasy'

const ColumnSelect = ({
  defaultValue,
  placeholder = 'Select a column',
  onChange,
  className = '',
  autoFocus = false,
  options,
}: ColumnSelectProps) => {
  const searchRef = React.useRef<any>(null)

  React.useEffect(() => {
    if (autoFocus && searchRef.current) searchRef.current.focus()
  }, [])

  const renderOptions = () => {
    if (!options) return <></>
    return options.map((f) => (
      <Select.Option disabled={f.disabled} key={f.name} value={f.code}>
        {f.name}
      </Select.Option>
    ))
  }
  const handleChange = (value) => {
    onChange(value as string)
  }

  return (
    <Select
      style={{ minWidth: 152, width: '100%' }}
      ref={searchRef}
      autoFocus={autoFocus}
      showSearch={true}
      className={`${className}`}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={handleChange}
      // loading={!options.length}
      removeIcon={<CloseCircleFilled></CloseCircleFilled>}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
    >
      {renderOptions()}
    </Select>
  )
}

export default ColumnSelect
