import React, { useState, useEffect, useMemo } from 'react'
import { Tabs, Skeleton, Alert, Empty } from 'antd'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'

import { capitalizeFirstLetter } from 'Utils'
import { getCustomer360Charts } from 'Services/Analytics/customer-analytics'

const Interactions = () => {
  const { id } = useParams()
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<{ label: string; values: object }[] | undefined>(undefined)

  useEffect(() => {
    const handleData = async () => {
      try {
        setError('')
        const resp = await getCustomer360Charts({ id, type: 'interactions' })
        setData(resp.data.data.data)
        // setData(CONTENT_2)
      } catch (err: any) {
        setError(err?.response?.data?.detail || 'Something went wrong')
      }
      setIsLoading(false)
    }
    handleData()
  }, [id])

  const renderInteractions = useMemo(() => {
    if (!data?.length) return <Empty />

    return data.map((tab) => {
      return (
        <Tabs.TabPane
          key={tab.label}
          tabKey={tab.label}
          tab={
            <span className={clsx('font-semibold text-secondary_text text-black')}>
              {capitalizeFirstLetter(tab.label)}
            </span>
          }
        >
          <div className="my-4 overflow-scroll" style={{ height: 250 }}>
            {Object.keys(tab.values).map((key) => (
              <div key={key} className="w-full flex justify-between mb-2">
                <span>{capitalizeFirstLetter(key)}</span>
                <span className="font-semibold text-right mr-1">{tab.values[key] || 'N/A'}</span>
              </div>
            ))}
          </div>
        </Tabs.TabPane>
      )
    })
  }, [data])

  if (error) return <Alert message={error} type="error" />

  return (
    <Skeleton loading={isLoading} active>
      <div className="w-full">
        <Tabs destroyInactiveTabPane>{renderInteractions}</Tabs>
      </div>
    </Skeleton>
  )
}

export default Interactions
