import React from 'react'
import { Form, Field } from 'formik'
import { Typography, Select, Input, Button, Divider } from 'antd'
import { connect } from 'react-redux'

import Preview from 'Components/CreativeContentsPreview'
import StatusTag from 'Components/StatusTag'
import CreativeContent from 'Containers/CreativeContent'
import CustomError from 'Components/FormError'
const { Title, Text } = Typography
const { Option } = Select

const ActionTriggerView = (props) => {
  const {
    welcomePushSchema,
    welcomePushValidation,
    previewData,
    actionTriggerStates,
    handleToggleActivate,
    isStatusLoading,
    setPreviewData,
  } = props
  const { id, status } = actionTriggerStates

  const computeTagColor = (tag) => {
    switch (tag) {
      case 'active':
        return 'green'
      case 'inactive':
        return 'light-gray'
      default:
        return 'gray'
    }
  }

  return (
    <Form>
      <div className="form-item flex">
        <StatusTag
          color={computeTagColor(status)}
          onClick={handleToggleActivate}
          loading={isStatusLoading}
          status={status ?? 'Not Available'}
        />
      </div>
      <Divider />
      <div className="form-item content-wrapper">
        <CreativeContent
          onSubmit={null}
          submitText="Save"
          initValues={previewData}
          validationSchema={welcomePushValidation}
          onChange={(key, value) => setPreviewData({ key, value })}
          fields={welcomePushSchema}
        />
        <Preview id="welcompush_preview_template" isPreview={true} type={'toast'}></Preview>
      </div>
      <div
        className="form-item"
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 25 }}
      >
        <Button htmlType="submit" type="primary">
          {id ? 'Update' : 'Create'}
        </Button>
      </div>
    </Form>
  )
}

export default connect(
  (state) => ({
    previewData: state.campaign.creativeContents.previewData,
  }),
  (dispatch) => ({
    setPreviewData: dispatch.campaign.creativeContents.setPreviewData,
  })
)(ActionTriggerView)
