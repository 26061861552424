import React from 'react'
export const MENU_DATA = [
  {
    title: 'Brand',
    value: 'brand',
  },
  {
    title: 'Product Category',
    value: 'product_category',
  },
  {
    title: 'Sale Channel',
    value: 'sale_channel',
  },
  {
    title: 'Supplier',
    value: 'supplier',
  },
]

export enum MENU_KEY {
  SALE_CHANNEL = 'sale_channel',
}
export const OPTIONS_SELECT_FILTER = [
  {
    label: 'Kingfoodmart',
    value: 'kingfoodmart',
  },
  {
    label: 'Kingfoodmart Mini',
    value: 'kingfoodmart_mini',
  },
]
export const DATA_TEST = [
  {
    key: '3679',
    title: 'Offline',
    children: [
      {
        key: '11917',
        title: 'Bán lẻ Miền Nam',
        children: [
          {
            key: '4444',
            title: 'Chuỗi cửa hàng bán lẻ Miền Bắc',
            children: [
              {
                key: '44441',
                store_code: 'HCMBTH001',
                title: 'Kingfoodmart Nguyễn Thị Thập Mini',
                brand: 'Kingfood Market',
                city: 'Hồ Chí Minh',
                district: 'Quận Bình Thạnh',
                address: '54 Vũ Huy Tấn',
                fb_location_id: 'None',
                fb_location_name: 'None',
              },
              {
                key: '44442',
                store_code: 'HCMBTH001',
                title: 'Kingfoodmart Lê Văn Việt Mini',
                brand: 'Kingfood Market',
                city: 'Hồ Chí Minh',
                district: 'Quận Bình Thạnh',
                address: '54 Vũ Huy Tấn',
                fb_location_id: 'None',
                fb_location_name: 'None',
              },
              {
                key: '44443',
                store_code: 'HCMBTH001',
                title: 'Kingfoodmart Nguyễn Thị Thập',
                brand: 'Kingfood Market',
                city: 'Hồ Chí Minh',
                district: 'Quận Bình Thạnh',
                address: '54 Vũ Huy Tấn',
                fb_location_id: 'None',
                fb_location_name: 'None',
              },
            ],
          },
        ],
      },
      {
        key: '11918',
        title: 'Bán lẻ Miền Bắc',
        children: [
          {
            key: '5550',
            title: 'Chuỗi cửa hàng bán lẻ Miền Bắc',
            children: [
              {
                key: '55501',
                store_code: 'HCMBTH001',
                title: 'Kingfoodmart Nguyễn Thị Thập Mini',
                brand: 'Kingfood Market',
                city: 'Hồ Chí Minh',
                district: 'Quận Bình Thạnh',
                address: '54 Vũ Huy Tấn',
                fb_location_id: 'None',
                fb_location_name: 'None',
              },
              {
                key: '55502',
                store_code: 'HCMBTH001',
                title: 'Kingfoodmart Lê Văn Việt Mini',
                brand: 'Kingfood Market',
                city: 'Hồ Chí Minh',
                district: 'Quận Bình Thạnh',
                address: '54 Vũ Huy Tấn',
                fb_location_id: 'None',
                fb_location_name: 'None',
              },
              {
                key: '55503',
                store_code: 'HCMBTH001',
                title: 'Kingfoodmart Nguyễn Thị Thập',
                brand: 'Kingfood Market',
                city: 'Hồ Chí Minh',
                district: 'Quận Bình Thạnh',
                address: '54 Vũ Huy Tấn',
                fb_location_id: 'None',
                fb_location_name: 'None',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: '3678',
    title: 'Online',
    children: [
      {
        key: '36781',
        store_code: 'HCMBTH001',
        title: 'WebSite',
        brand: 'Kingfood Market',
        city: 'Hồ Chí Minh',
        district: 'Quận Bình Thạnh',
        address: '54 Vũ Huy Tấn',
        fb_location_id: 'None',
        fb_location_name: 'None',
      },
      {
        key: '36782',
        store_code: 'HCMBTH001',
        title: 'Application',
        brand: 'Kingfood Market',
        city: 'Hồ Chí Minh',
        district: 'Quận Bình Thạnh',
        address: '54 Vũ Huy Tấn',
        fb_location_id: 'None',
        fb_location_name: 'None',
      },
      {
        key: '36783',
        title: 'Facebook Fanpage',
        children: [
          {
            key: '367821',
            store_code: 'HCMBTH001',
            title: 'Messenger',
            brand: 'Kingfood Market',
            city: 'Hồ Chí Minh',
            district: 'Quận Bình Thạnh',
            address: '54 Vũ Huy Tấn',
            fb_location_id: 'None',
            fb_location_name: 'None',
          },
          {
            key: '367822',
            store_code: 'HCMBTH001',
            title: 'FaceBook Live',
            brand: 'Kingfood Market',
            city: 'Hồ Chí Minh',
            district: 'Quận Bình Thạnh',
            address: '54 Vũ Huy Tấn',
            fb_location_id: 'None',
            fb_location_name: 'None',
          },
        ],
      },
    ],
  },
]
export const DATA_NEW = [
  {
    key: 'Chat',
    title: 'Chat',
    children: [],
  },
  {
    key: 'Website',
    title: 'Website',
    children: [],
  },
  {
    key: 'Mobile App',
    title: 'Mobile App',
    children: [],
  },
  {
    key: 'Advertising',
    title: 'Advertising',
    children: [],
  },
  {
    key: 'Social Page',
    title: 'Social Page',
    children: [],
  },
  {
    key: 'Contact Center',
    title: 'Contact Center',
    children: [],
  },
  {
    key: 'Physical Store',
    title: 'Physical Store',
    children: [],
  },
  {
    key: 'Online Marketplace',
    title: 'Online Marketplace',
    children: [],
  },
  {
    key: 'Affiliate - Social Commerce',
    title: 'Affiliate - Social Commerce',
    children: [],
  },
]
