import React from 'react'
import { hexToRgbA } from 'Utils'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import NodeStats from './NodeStats'

const RoundNode = (props) => {
  const { childNodes, width, height, description, bgColor, name, icon, value, reached } = props
  return (
    <foreignObject
      className="NodeComponent Round"
      height={50}
      width={50}
      x={75}
      y={25}
      overflow="visible"
      style={{ filter: `drop-shadow(1px 5px 8px ${hexToRgbA(bgColor, 0.2)}` }}
    >
      <div className="node-content">
        <NodeStats {...props} type="reached" value={reached} />
        <div className="icon-wrapper" style={{ width: height / 2, height: height / 2 }}>
          <div style={{ color: bgColor }} className="icon">
            {icon}
          </div>

          {/* {process.env.NODE_ENV === 'development' && node_uuid?.slice(-4)} */}
        </div>
        <div style={{ color: bgColor, width: '100px' }} className="text">
          {name} {value?.wait_time} {value?.time_format}{' '}
          <Tooltip title={description}>
            <div style={{ color: bgColor }} className="node-info-icon">
              <QuestionCircleOutlined />
            </div>
          </Tooltip>
        </div>
      </div>
    </foreignObject>
  )
}

export default RoundNode
