import React from 'react'
import Tag from './Tag'
import { PlusCircleFilled, DeleteFilled } from '@ant-design/icons'
import { Typography, Tabs, Popover } from 'antd'
import classNames from 'classnames'
import { connect } from 'react-redux'

const { TabPane } = Tabs

class Group extends React.Component {
  renderItems = (dimensions) => {
    return this.props.items.map((item, i) => (
      <Tag
        dimensions={dimensions}
        key={i}
        groupIndex={this.props.index}
        index={i}
        data={item}
      ></Tag>
    ))
  }

  render() {
    const {
      type,
      dimensions,
      toggleDimensionModal,
      index,
      setCurrentGroup,
      delSegmentGroup,
      switchAudienceGroupCondition,
      segmentation,
    } = this.props

    const isExclude = type === 'exclude'
    return (
      <div className={classNames(['SegmentGroupComponent', { exclude: isExclude }])}>
        {segmentation[index].condition != null && (
          <Tabs
            defaultActiveKey={segmentation[index].condition}
            onChange={(condition) =>
              switchAudienceGroupCondition({ groupIndex: index, groupCondition: condition })
            }
          >
            {/* <TabPane tab="And" key="AND" /> */}
            <TabPane tab="Or" key="OR" />
          </Tabs>
        )}
        <div className="tags-wrapper">
          <div className="tags">
            {this.renderItems(dimensions)}
            <PlusCircleFilled
              className="add-audience-btn"
              onClick={() => {
                setCurrentGroup({ index })
                toggleDimensionModal()
              }}
            />
          </div>

          <DeleteFilled
            className={classNames([
              'del-group-btn',
              { disabled: !index && segmentation.length == 1 },
            ])}
            onClick={() => {
              delSegmentGroup({ index })
            }}
          />
        </div>
      </div>
    )
  }
}

Group.defaultProps = {
  items: [],
}

export default connect(
  (state) => ({
    segmentation: state.segmentation.data,
  }),
  (dispatch) => ({
    setCurrentGroup: dispatch.segmentation.setCurrentGroup,
    delSegmentGroup: dispatch.segmentation.delSegmentGroup,
    toggleDimensionModal: dispatch.segmentation.toggleDimensionModal,
    switchAudienceGroupCondition: dispatch.segmentation.switchAudienceGroupCondition,
  })
)(Group)
