import React from 'react'
import { Form, Input, Button, Checkbox, Radio } from 'antd'
import './index.scss'

type HRWJobType = {
  jobtitle: string
  department_name: string
}

type HRWUser = {
  employeeId: string
  fullName: string
  phone: string
  jobs: HRWJobType[]
}

type HRWUserItemProps = {
  user: HRWUser
  selectedHRWUserId: string | null
  onCardSelect: (hrwId: string) => void
}

const DepartmentItem = ({ job }) => (
  <div className="DepartmentItemComponent p-1">
    <p className="full-name text-gray_13text-lg mb-0">{job.department_name}</p>
    <p className="full-name text-base mb-0">{job.jobtitle}</p>
  </div>
)

const renderJobs = (jobs) => {
  if (!jobs.length) return <p className="mt-1">No job found!</p>
  return jobs.map((job) => <DepartmentItem job={job}></DepartmentItem>)
}

const HRWUserItem = ({ user, selectedHRWUserId, onCardSelect }: HRWUserItemProps) => {
  return (
    <div className="HRWUserItemComponent p-2 mb-2" onClick={() => onCardSelect(user.employeeId)}>
      <div className="info-wrapper">
        <div className="field-wrapper field-wrapper--name-phone">
          <p className="full-name text-gray_13text-2xl mb-0">Họ và tên: {user.fullName}</p>
          <p className="full-name text-lg mb-1">Số điện thoại: {user.phone}</p>
        </div>
        <div className="field-wrapper field-wrapper--department-jobtitle">
          {renderJobs(user.jobs)}
        </div>
      </div>
      <Radio checked={selectedHRWUserId === user.employeeId}></Radio>
    </div>
  )
}
export default HRWUserItem
