import React, { useRef, useMemo, useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import { RectShape } from 'react-placeholder/lib/placeholders'
import 'react-placeholder/lib/reactPlaceholder.css'

import { colors } from 'DesignSystem'
import './index.scss'

function randomNumber(min, max) {
  const r = Math.random() * (max - min) + min
  return Math.floor(r)
}

export const BarChartPlaceholder = ({ isHorizontal }: { isHorizontal?: boolean }) => {
  const ref: any = useRef(null)
  const [width, setWidth] = useState(0)

  const renderPlaceholder = useMemo(() => {
    if (!width) return null

    const colWidth = 30
    const rects: React.ReactNode[] = []
    const height = ref?.current?.clientHeight

    if (isHorizontal) {
      for (let i = 0; i < height; i += colWidth + 15) {
        rects.push(
          <RectShape
            className="m-0 mb-2"
            key={i}
            color={colors.systemColor.gray_4}
            style={{
              height: colWidth,
              width: `${randomNumber(width - 100, width)}px`,
            }}
          />
        )
      }
    }

    if (!isHorizontal) {
      for (let i = 0; i < width; i += colWidth + 10) {
        rects.push(
          <RectShape
            className="m-0 mr-2"
            key={i}
            color={colors.systemColor.gray_4}
            style={{
              width: colWidth,
              height: `${randomNumber(height - 200, height - 50)}px`,
            }}
          />
        )
      }
    }

    return <>{rects.map((rect) => rect)}</>
  }, [width])

  useEffect(() => {
    setWidth(ref?.current?.offsetWidth)
  }, [ref])

  return (
    <div
      ref={(el) => {
        ref.current = el
      }}
      className={`${
        isHorizontal ? 'flex-col justify-end' : 'flex justify-center'
      } w-full h-full items-end show-loading-animation`}
    >
      {renderPlaceholder}
    </div>
  )
}

export const PieChartPlaceholder = ({ isMulti }: { isMulti?: boolean }) => {
  const ref: any = useRef(null)
  const [width, setWidth] = useState(0)

  const RoundShape = ({ width }) => {
    return (
      <div
        className="rounded-full round-shape box-border"
        color={colors.systemColor.gray_4}
        style={{
          width: width,
          height: width,
          border: `30px solid ${colors.systemColor.gray_4}`,
        }}
      />
    )
  }

  const computeLines = (count, width) => {
    const lines: React.ReactNode[] = []
    for (let i = 0; i < count; i++) {
      lines.push(
        <RectShape
          key={i}
          className="m-0 mb-2"
          color={colors.systemColor.gray_4}
          style={{
            height: 20,
            width: `${width / (i + 1.2)}px`,
          }}
        />
      )
    }
    return lines
  }

  const renderPlaceholder = useMemo(() => {
    if (!width) return null
    if (!isMulti) {
      return (
        <>
          <div className="flex gap-2 justify-center ">
            <RoundShape width={width / 1.8} />
          </div>
          <div className="mt-4">{computeLines(3, width)}</div>
        </>
      )
    }

    return (
      <>
        <div className="flex gap-2  justify-between ">
          <RoundShape width={width / 2.2} />
          <RoundShape width={width / 2.2} />
        </div>
        <div className="mt-4">{computeLines(2, width)}</div>
      </>
    )
  }, [width])

  useEffect(() => {
    setWidth(ref?.current?.offsetWidth)
  }, [ref])

  return (
    <div ref={ref} className="w-full h-full show-loading-animation">
      {renderPlaceholder}
    </div>
  )
}
