import React, { useEffect } from 'react'
import { SettingFilled, DeleteFilled, CopyFilled, SwapOutlined } from '@ant-design/icons'

import { CJ_NODE_WIDTH, CJ_NODE_HEIGHT } from 'Containers/CustomerJourneyDesign/constants'
import MenuItem from './MenuItem'
import './index.scss'

const menus = [
  {
    key: 'settings',
    name: 'Settings',
    icon: <SettingFilled />,
    notAvailable: ['virtual'],
  },
  {
    key: 'change',
    name: 'Change',
    icon: <SwapOutlined />,
    notAvailable: ['entrance', 'check_audience_segment', 'virtual'],
  },
  {
    key: 'duplicate',
    name: 'Duplicate',
    icon: <CopyFilled />,
    notAvailable: ['entrance', 'check_audience_segment', 'virtual'],
  },
  {
    key: 'delete',
    name: 'Delete',
    icon: <DeleteFilled />,
    notAvailable: ['entrance', 'check_audience_segment', 'virtual'],
  },
]

const NodeMenu = ({ node, menuRadius, onClick, center, centerPoint: cp }) => {
  const RADIUS = menuRadius || 50
  const ITEM_RADIUS = 30

  // menu items that available for this node
  const availableItems = menus.filter((menu) => !menu.notAvailable.includes(node.nodeType))
  let centerPoint = cp || {
    x: 0,
    y: 0,
  }
  if (center) centerPoint = { x: CJ_NODE_WIDTH / 2, y: CJ_NODE_HEIGHT / 2 }
  return (
    <div
      style={{
        position: 'absolute',
        right: centerPoint.x,
        top: centerPoint.y,
      }}
    >
      {availableItems.map((menu, index) => {
        const degree = index * -50 - 180 / availableItems.length
        const x = RADIUS * Math.cos(degree * (Math.PI / 180))
        const y = RADIUS * Math.sin(degree * (Math.PI / 180))

        let itemStyle = {
          position: 'absolute',
          opacity: 0,
          pointerEvents: 'none',
        }

        // check if menu item is available for node

        return menu.notAvailable.includes(node.nodeType) ? null : (
          <MenuItem
            x={x}
            y={y}
            width={ITEM_RADIUS}
            height={ITEM_RADIUS}
            itemStyle={itemStyle}
            menu={menu}
            key={menu.key}
            onClick={() => onClick(menu.key)}
          />
        )
      })}
    </div>
  )
}

export default NodeMenu
