import React, { useEffect, useState } from 'react'
import { Typography, Divider, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { QuestionCircleOutlined } from '@ant-design/icons'
import NodeStats from './NodeStats'
import { hexToRgbA } from 'Utils'

const { Title, Text } = Typography

const SquareNode = (props) => {
  const {
    width,
    height,
    bgColor,
    name,
    icon,
    nodeType,
    nodeValue,
    CJStates,
    description,
    stay_in,
    failed,
    passed,
    reached,
  } = props

  const [nodeStatus, setNodeStatus] = useState({})

  useEffect(() => {
    if (nodeType == 'check_audience_segment') {
      nodeValue.audience_segment_id
        ? setNodeStatus({ segment: 'CUSTOM' })
        : setNodeStatus({ segment: 'ALL' })
    }
  }, [nodeValue])

  return (
    <foreignObject
      style={{ filter: `drop-shadow(1px 5px 8px ${hexToRgbA(bgColor, 0.3)}` }}
      className="NodeComponent Square lighting-off"
      width={width}
      height={height}
    >
      <div className="node-content">
        <div className="node-header">
          <NodeStats {...props} type="reached" value={reached} />
          <Tooltip title={description}>
            <div style={{ color: bgColor }} className="node-info-icon">
              <QuestionCircleOutlined />
            </div>
          </Tooltip>
        </div>
        <div className="node-body">
          <span style={{ color: bgColor }} className="icon">
            {icon}
          </span>
          <span
            className="name"
            style={{
              fontWeight: 700,
              color: bgColor,
              fontSize: Object.keys(nodeStatus)?.length || name?.length > 10 ? '18px' : '24px',
            }}
          >
            {name}
          </span>
          {/* {process.env.NODE_ENV === 'development' && node_uuid?.slice(-4)} */}
        </div>

        <div className="node-footer">
          <Divider className="mb-1 mt-0" />
          {!['running'].includes(CJStates.status) && (
            <div className="status">
              {Object.keys(nodeStatus).map((item) => {
                return (
                  <>
                    <span className="status-key">{item}: </span>
                    <span className="status-value">{nodeStatus[item].toLowerCase()}</span>
                  </>
                )
              })}
            </div>
          )}
          <div className="node-footer-stats">
            <NodeStats {...props} type="failed" value={failed} />
            <NodeStats {...props} type="passed" value={passed} />
          </div>
        </div>
      </div>
    </foreignObject>
  )
}

export default connect(
  (state) => ({
    CJStates: state.customerJourney,
  }),
  (dispatch) => ({
    setCJStates: dispatch.customerJourney.setCJStates,
  })
)(SquareNode)
