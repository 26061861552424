import React from 'react'
import { Breadcrumb } from 'antd'
import HomeOutlined from '@ant-design/icons/HomeOutlined'
import { useStoreState } from 'easy-peasy'
import { BreadCrumbType, BreadCrumbsProps } from 'MBreadCrumb'

const BreadCrumbItem: React.FC<BreadCrumbType> = ({ title, path, isLastItem }) => {
  if (!isLastItem) return <Breadcrumb.Item href={path}>{title}</Breadcrumb.Item>
  return <Breadcrumb.Item>{title}</Breadcrumb.Item>
}

const renderBreadCrumbItems = ({ items }: BreadCrumbsProps) => {
  return items.map((item, index) => (
    <BreadCrumbItem
      key={index}
      isLastItem={index + 1 === items.length}
      title={item.title}
      path={item.path}
    />
  ))
}

const BreadCrumb = () => {
  const { items }: BreadCrumbsProps = useStoreState((state) => state.breadcrumb)
  return (
    <div className="BreadCrumbComponent">
      <Breadcrumb>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        {renderBreadCrumbItems({ items })}
      </Breadcrumb>
    </div>
  )
}

export default BreadCrumb
