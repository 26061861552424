import React from 'react'

import { TableProps } from './index.d'

import Table from './Components/Table'
import Header from './Components/Header'

import './index.scss'

const TableComponent: React.FC<TableProps> = ({
  search,
  datePicker,
  rowActions,
  fetchFrom,
  columns,
  primaryButton,
  dropdown,
  title,
  className,
  rowClassName,
  handleTableChange,
  defaultSort,
}) => {
  return (
    <div className={`w-full relative TableComponent ${className}`}>
      <Header
        search={search}
        datePicker={datePicker}
        primaryButton={primaryButton}
        dropdown={dropdown}
        title={title}
      />
      <Table
        defaultSort={defaultSort}
        columns={columns}
        queryObject={{ ...dropdown?.value, query: search?.keyword || '' } || {}}
        fetchFrom={fetchFrom}
        rowActions={rowActions}
        rowClassName={rowClassName}
        handleTableChange={handleTableChange}
      />
    </div>
  )
}

export default TableComponent
