import React from 'react'
import { useStoreActions } from 'easy-peasy'
import { Select } from 'antd'

import { ReactComponent as BtnDelete } from 'Assets/images/icons/IconDel.svg'
import { CriteriaGroup as CriteriaGroupProps } from 'CustomerSegmentTypes'
import Dimension from './Dimension'
import './index.scss'
export interface props {
  index: number
  group: CriteriaGroupProps
}

const CriteriaGroup: React.FC<props> = ({ index, group }) => {
  const { changeDimensionGroupCondition, removeDimensionsGroup } = useStoreActions(
    (action: any) => action.customerSegments
  )

  return (
    <div className="bg-gray_1 relative shadow-sm criteria-group rounded">
      {index > 0 && (
        <div className="flex justify-between items-center">
          <Select
            className="rounded mb-2 group-condition-select font-semibold"
            value={group.condition}
            onChange={(value) =>
              changeDimensionGroupCondition({ groupIndex: index, condition: value })
            }
          >
            <Select.Option value="AND">AND</Select.Option>
            <Select.Option value="OR">OR</Select.Option>
            {/* <Select.Option className="exclude" value="EXCLUDE">
            Exclude
          </Select.Option> */}
          </Select>

          <div
            className="cursor-pointer"
            onClick={() => removeDimensionsGroup({ groupIndex: index })}
          >
            <BtnDelete />
          </div>
        </div>
      )}

      <Dimension groupIndex={index} dimension={group.dimension} />
    </div>
  )
}

export default CriteriaGroup
