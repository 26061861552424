import MemberShipBadge from 'Components/MemberShipBadge'
import React, { useState } from 'react'
import { renderGenderLabel } from './helpers'

const CUSTOMER_INFO = {
  name: 'Phạm Phương Linh',
  gender: 'f',
  age: 20,
  location: 'TP.HCM',
}

const TAGS = ['E-Clearance Hunter', 'Churn']

type CustomerInfoProps = {
  name: string
  age: number
  gender: string
  location: string
  segment: string[]
  ltv: string
  membership: string
}

export default function CustomerInfo(props: CustomerInfoProps) {
  return (
    <div id="CustomerInfo">
      <div className="flex justify-between">
        <h2>{props.name || 'Unknow'}</h2>
        <MemberShipBadge ltv={props.ltv} membership={props.membership}></MemberShipBadge>
      </div>
      <div className="info-detail">
        <span>
          {props.gender || 'Unknow'} <span className="bullet-point">&#9679;</span>
        </span>
        <span>
          {props.age ? `${props.age} tuổi` : 'Unknow'}
          <span className="bullet-point">&#9679;</span>
        </span>
        <span>{props.location || 'Unknow'}</span>
      </div>
      <div className="tag-wrapper flex flex-wrap gap-lv_1 mt-1">
        {props.segment.map((tag, index) => (
          <div key={index} className="tag inline-block whitespace-nowrap ">
            {tag}
          </div>
        ))}
      </div>
    </div>
  )
}
