import React from 'react'
import ChartLine from '../../Components/ChartLine'
import { getCustomerOverviewCharts } from 'Services/Analytics/customer-analytics'
import { getSaleChannelOverviewCharts } from 'Services/Analytics/saleChannel-analytics'
import BarChartTemplate from '../../Components/BarChart.Template'

const ChartRow7 = () => {
  return (
    <>
      <div className="bg-white p-4 col-span-1">
        <div className="mb-4 font-semibold">Customer</div>
        <div className="w-full overflow-auto">
          <ChartLine
            chartCode="nunique_customer"
            handleDataFunction={getSaleChannelOverviewCharts}
          />
        </div>
      </div>
      <div className="bg-white p-4 col-span-1">
        <div className="mb-4 font-semibold">Customer Type by Sale Channel</div>
        <div className="w-full overflow-auto h-full">
          <BarChartTemplate
            chartCode="customer_type_by_sale_channel"
            handleDataFunction={getSaleChannelOverviewCharts}
            isStacked
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow7
