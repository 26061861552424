import React, { useMemo } from 'react'
import { RightOutlined } from '@ant-design/icons'
import { CustomerItem } from 'DimensionsFiltersTypes'

export type props = {
  segment: CustomerItem
  onClick?: () => void
  disableClick?: boolean
}

const DimensionsItem: React.FC<props> = ({ segment, onClick, disableClick }) => {
  return (
    <div
      className="segment-item"
      style={{ pointerEvents: disableClick ? 'none' : 'unset' }}
      onClick={onClick}
    >
      {segment?.name} {segment.nestedGroups && <RightOutlined />}
    </div>
  )
}

export default DimensionsItem
