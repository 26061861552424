import useClickOutside from './useClickOutside'
import useDataApi from './useDataApi'
import useFetchTables from './useFetchTables'
import useDebounce from './useDebounce'
import useKeyPress from './useKeyPress'

import { ModelTypes } from 'Models/index.types'
import { createTypedHooks } from 'easy-peasy'

const typedHooks = createTypedHooks<ModelTypes>()
const { useStoreActions, useStoreState } = typedHooks

export {
  useDataApi,
  useClickOutside,
  useDebounce,
  useFetchTables,
  useStoreActions,
  useStoreState,
  useKeyPress,
}
