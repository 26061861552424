const string = `<table border="1" class="dataframe">
<thead>
  <tr style="text-align: right;">
    <th class="sticky" style="top: -0.25px; left: unset; z-index: 12;" />
    <th class="sticky" style="top: -0.25px; left: unset; z-index: 12;" />
    <th class="sticky" style="top: -0.25px; left: unset; z-index: 12;" />
    <th class="sticky" outerhtml="" style="top: -0.25px; left: unset; z-index: 12;" />
    <th class="sticky" style="top: -0.25px; left: unset; z-index: 12;" />
    <th class="sticky" style="top: -0.25px; left: unset; z-index: 10;">
      purchased_item
    </th>
    <th class="sticky" style="top: -0.25px; left: unset; z-index: 10;">
      revenue
    </th>
  </tr>
  <tr>
    <th class="sticky" style="top: 33.25px; left: unset; z-index: 12;">
      ltv
    </th>
    <th class="sticky" style="top: 33.25px; left: unset; z-index: 12;">
      cat
    </th>
    <th class="sticky" style="top: 33.25px; left: unset; z-index: 12;">
      sub_cat
    </th>
    <th class="sticky" style="top: 34.25px;left: unset;z-index: 12;">
      sku_name
    </th>
    <th class="sticky" style="top: 33.25px; left: unset; z-index: 12;">
      sku
    </th>
    <th class="sticky" style="top: 33.25px; left: unset; z-index: 10;" />
    <th class="sticky" style="top: 33.25px; left: unset; z-index: 10;" />
  </tr>
</thead>
<tbody>
  <tr>
    <th rowspan="241" valign="top">
      1
    </th>
    <th rowspan="116" valign="top">
      Giày
    </th>
    <th rowspan="8" valign="top">
      Dép Guốc
    </th>
    <th>Dép lào thời trang Juno màu xanh DK003</th>
    <th>DK003</th>
    <td>4</td>
    <td>40,000</td>
  </tr>
  <tr>
    <th>Dép Tông DK041</th>
    <th>DK041</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK079</th>
    <th>DK079</th>
    <td>1</td>
    <td>54,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK082</th>
    <th>DK082</th>
    <td>3</td>
    <td>117,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK083</th>
    <th>DK083</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK084</th>
    <th>DK084</th>
    <td>2</td>
    <td>117,727</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno hoạ tiết sọc ngang DK006</th>
    <th>DK006</th>
    <td>1</td>
    <td>45,455</td>
  </tr>
  <tr>
    <th>Sản phẩm quà tặng QT015</th>
    <th>DK015</th>
    <td>17</td>
    <td>175,455</td>
  </tr>
  <tr>
    <th>Giày Boot</th>
    <th>Giày boot dây kéo khoá tròn kim loại</th>
    <th>BO07016</th>
    <td>3</td>
    <td>817,273</td>
  </tr>
  <tr>
    <th rowspan="9" valign="top">
      Giày Búp Bê
    </th>
    <th>Giày búp bê gắn khóa trang trí</th>
    <th>BB03065</th>
    <td>1</td>
    <td>183,136</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày búp bê mũi nhọn gắn khóa trang trí
    </th>
    <th>BB03057</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>BB03064</th>
    <td>5</td>
    <td>210,955</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn khoét eo BB03021</th>
    <th>BB03021</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày búp bê mũi nhọn khóa trang trí
    </th>
    <th>BB03058</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>BB03062</th>
    <td>2</td>
    <td>366,273</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn quai chéo</th>
    <th>BB03070</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Giày mules gót ngọc trai</th>
    <th>BB03060</th>
    <td>2</td>
    <td>270,909</td>
  </tr>
  <tr>
    <th>Waffle-Y-Cute</th>
    <th>BB03071</th>
    <td>1</td>
    <td>229,500</td>
  </tr>
  <tr>
    <th rowspan="38" valign="top">
      Giày Cao Gót
    </th>
    <th>Giày cao gót gót bọc trong suốt</th>
    <th>CG07098</th>
    <td>6</td>
    <td>905,455</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh mũi nhọn</th>
    <th>CG07092</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh quai chéo</th>
    <th>CG09119</th>
    <td>9</td>
    <td>948,182</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh quai cổ chân</th>
    <th>CG09120</th>
    <td>34</td>
    <td>4,470,000</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh trang trí đính đá</th>
    <th>CG09141</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót khóa gài</th>
    <th>CG09144</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót khóa trang trí họa tiết mê cung</th>
    <th>CG09143</th>
    <td>2</td>
    <td>270,000</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót chuyển màu</th>
    <th>CG07101</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót khắc trụ</th>
    <th>CG05104</th>
    <td>1</td>
    <td>248,182</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày cao gót mũi nhọn gót thanh
    </th>
    <th>CG05089</th>
    <td>3</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>CG09125</th>
    <td>10</td>
    <td>900,000</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày cao gót mũi nhọn gót trụ
    </th>
    <th>CG05092</th>
    <td>10</td>
    <td>677,273</td>
  </tr>
  <tr>
    <th>CG05112</th>
    <td>4</td>
    <td>421,909</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót xi kim loại</th>
    <th>CG09129</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót phối hai tone màu</th>
    <th>CG05100</th>
    <td>6</td>
    <td>723,636</td>
  </tr>
  <tr>
    <th>Giày cao gót pump khóa trang trí</th>
    <th>CG09124</th>
    <td>5</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi nhọn gót thanh</th>
    <th>CG09140</th>
    <td>5</td>
    <td>439,364</td>
  </tr>
  <tr>
    <th>Giày cao gót quai ngang khoá trang trí</th>
    <th>CG07104</th>
    <td>1</td>
    <td>229,500</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày cao gót slingback gót thanh
    </th>
    <th>CG07091</th>
    <td>2</td>
    <td>270,909</td>
  </tr>
  <tr>
    <th>CG09142</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gắn khóa trang trí</th>
    <th>CG05106</th>
    <td>1</td>
    <td>210,955</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày cao gót slingback mũi nhọn gót thanh
    </th>
    <th>CG07089</th>
    <td>6</td>
    <td>677,273</td>
  </tr>
  <tr>
    <th>CG07099</th>
    <td>2</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>CG09122</th>
    <td>2</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi vuông gót trụ</th>
    <th>CG07096</th>
    <td>25</td>
    <td>2,525,455</td>
  </tr>
  <tr>
    <th>Giày Cao Gót Mũi Nhọn</th>
    <th>CG07086</th>
    <td>4</td>
    <td>605,045</td>
  </tr>
  <tr>
    <th>Giày Pump gót sơn ánh nhũ</th>
    <th>CG09132</th>
    <td>2</td>
    <td>452,727</td>
  </tr>
  <tr>
    <th>Giày cao gót 5cm thân lượn sóng CG05072</th>
    <th>CG05072</th>
    <td>2</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Giày cao gót 9cm mũi nhọn da bóng CG09104</th>
    <th>CG09104</th>
    <td>4</td>
    <td>561,818</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót lượn</th>
    <th>CG07083</th>
    <td>1</td>
    <td>208,636</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót thanh</th>
    <th>CG07080</th>
    <td>2</td>
    <td>199,091</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót trụ</th>
    <th>CG05099</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn phối si tương phản</th>
    <th>CG09137</th>
    <td>2</td>
    <td>452,727</td>
  </tr>
  <tr>
    <th>Giày cao gót quai hậu xé dán</th>
    <th>CG07095</th>
    <td>8</td>
    <td>1,266,364</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback khóa trang trí lục giác j</th>
    <th>CG07093</th>
    <td>3</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi nhọn gót thanh</th>
    <th>CG09112</th>
    <td>46</td>
    <td>5,689,091</td>
  </tr>
  <tr>
    <th>Giày mules bít mũi khóa trang trí đan lát</th>
    <th>CG07106</th>
    <td>5</td>
    <td>459,000</td>
  </tr>
  <tr>
    <th>Giày mules mũi nhọn gót trụ</th>
    <th>CG05097</th>
    <td>1</td>
    <td>210,955</td>
  </tr>
  <tr>
    <th rowspan="45" valign="top">
      Giày Sandal
    </th>
    <th>Dép 3 dây quai ngang</th>
    <th>SD01100</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Dép bệt quai xoắn</th>
    <th>SD01104</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Dép bệt quai đắp chéo phối pvc</th>
    <th>SD01102</th>
    <td>4</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Dép quai ngang mũi vuông</th>
    <th>SD01095</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Dép quai ngang phối ánh kim</th>
    <th>SD01109</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Giày sandal bệt mũi vuông</th>
    <th>SD01105</th>
    <td>5</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Giày sandal bệt xỏ ngón quai chéo</th>
    <th>SD01106</th>
    <td>1</td>
    <td>199,091</td>
  </tr>
  <tr>
    <th>Giày sandal bệt đế giả gỗ</th>
    <th>SD01098</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Giày sandal dây bất đối xứng</th>
    <th>SD11010</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày sandal dây hậu đan chéo</th>
    <th>SD07053</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Giày sandal dây mảnh</th>
    <th>SD07063</th>
    <td>1</td>
    <td>229,500</td>
  </tr>
  <tr>
    <th>Giày sandal gót hình học</th>
    <th>SD09074</th>
    <td>2</td>
    <td>299,091</td>
  </tr>
  <tr>
    <th>Giày sandal gót sơn chuyển màu</th>
    <th>SD09094</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal gót sơn ombre</th>
    <th>SD07040</th>
    <td>2</td>
    <td>270,909</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh quai mảnh bất đối xứng</th>
    <th>SD09097</th>
    <td>1</td>
    <td>300,431</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh đế đúp</th>
    <th>SD09078</th>
    <td>24</td>
    <td>1,171,818</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong có màu</th>
    <th>SD07052</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông mũi vuông</th>
    <th>SD05055</th>
    <td>21</td>
    <td>2,438,182</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông quai bất đối xứng</th>
    <th>SD05066</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông quai mặt bất đối xứng</th>
    <th>SD05044</th>
    <td>4</td>
    <td>524,545</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông viền kim loại, quai mảnh</th>
    <th>SD05063</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông đính hạt trang trí</th>
    <th>SD09080</th>
    <td>10</td>
    <td>990,909</td>
  </tr>
  <tr>
    <th>Giày sandal kim tuyến gót thô</th>
    <th>SD11012</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông gót vuông</th>
    <th>SD07039</th>
    <td>16</td>
    <td>1,490,000</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông in họa tiết vệt hoa</th>
    <th>SD09089</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông khóa trang trí</th>
    <th>SD03040</th>
    <td>19</td>
    <td>2,031,818</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông phối si vân</th>
    <th>SD07060</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Giày sandal phối dây vải sọc</th>
    <th>SD07051</th>
    <td>4</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Giày sandal quai mũi ngang giả xé dán</th>
    <th>SD07050</th>
    <td>6</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khóa trang trí</th>
    <th>SD05064</th>
    <td>1</td>
    <td>234,687</td>
  </tr>
  <tr>
    <th>Giày sandal t-strap khóa trang trí</th>
    <th>SD01084</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày sandal thể thao 2 quai</th>
    <th>SD03039</th>
    <td>2</td>
    <td>210,955</td>
  </tr>
  <tr>
    <th>Giày sandal thể thao dây chéo</th>
    <th>SD03042</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao</th>
    <th>SD05058</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao gắn khóa trang trí</th>
    <th>SD05061</th>
    <td>16</td>
    <td>3,375,273</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao quai chéo</th>
    <th>SD05059</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao quai mảnh</th>
    <th>SD05060</th>
    <td>1</td>
    <td>210,955</td>
  </tr>
  <tr>
    <th>Giày sandal đế xuồng đính hạt trang trí</th>
    <th>SD09079</th>
    <td>6</td>
    <td>406,364</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp gót thanh</th>
    <th>SD09093</th>
    <td>1</td>
    <td>326,842</td>
  </tr>
  <tr>
    <th>Giày xăng đan gót nhọn 11cm quai bản to SD11006</th>
    <th>SD11006</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Giày xăng đan gót vuông 5cm quai ngang SD05003</th>
    <th>SD05003</th>
    <td>3</td>
    <td>352,455</td>
  </tr>
  <tr>
    <th>Giày xăng đan gót vuông 5cm quai nhung SD05037</th>
    <th>SD05037</th>
    <td>2</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Giày xăng đan quai ngang đế thể thao SD05028</th>
    <th>SD05028</th>
    <td>4</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Giày xăng đan đế bệt quai ngang SD01081</th>
    <th>SD01081</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Jumping Snowflakes</th>
    <th>SD03043</th>
    <td>1</td>
    <td>257,318</td>
  </tr>
  <tr>
    <th rowspan="13" valign="top">
      Giày Thể Thao
    </th>
    <th>Comfy Easy Lazy</th>
    <th>TT03031</th>
    <td>3</td>
    <td>467,903</td>
  </tr>
  <tr>
    <th>Comfy Moon Walk</th>
    <th>TT03029</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày Thể Thao Classic</th>
    <th>TT03028</th>
    <td>2</td>
    <td>275,864</td>
  </tr>
  <tr>
    <th>Giày Thể Thao Classic Stick with me</th>
    <th>TT03032</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày thể thao đế chunky</th>
    <th>TT07001</th>
    <td>3</td>
    <td>196,364</td>
  </tr>
  <tr>
    <th>Juno Active Starter 1</th>
    <th>TT03021</th>
    <td>86</td>
    <td>17,656,364</td>
  </tr>
  <tr>
    <th>Juno active starter 3</th>
    <th>TT03023</th>
    <td>1</td>
    <td>257,318</td>
  </tr>
  <tr>
    <th>Juno active starter 4</th>
    <th>TT03024</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Sneakers Ocean Wave</th>
    <th>TT05007</th>
    <td>1</td>
    <td>368,591</td>
  </tr>
  <tr>
    <th>Sneakers Rush Crush</th>
    <th>TT03034</th>
    <td>5</td>
    <td>231,818</td>
  </tr>
  <tr>
    <th>Trendy Elevated Flow</th>
    <th>TT05005</th>
    <td>3</td>
    <td>368,591</td>
  </tr>
  <tr>
    <th>Trendy Illuminated Chunky</th>
    <th>TT07002</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>juno active starter 5</th>
    <th>TT03026</th>
    <td>256</td>
    <td>42,332,727</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày Đế Xuồng
    </th>
    <th rowspan="2" valign="top">
      Giày đế xuồng giả gỗ
    </th>
    <th>DX07019</th>
    <td>5</td>
    <td>541,818</td>
  </tr>
  <tr>
    <th>DX07020</th>
    <td>20</td>
    <td>2,031,818</td>
  </tr>
  <tr>
    <th rowspan="69" valign="top">
      Túi xách
    </th>
    <th rowspan="8" valign="top">
      Balo
    </th>
    <th>Balo buckle phối si vân</th>
    <th>BL111</th>
    <td>1</td>
    <td>368,591</td>
  </tr>
  <tr>
    <th>Balo chần bông 3 tông màu</th>
    <th>BL110</th>
    <td>4</td>
    <td>905,455</td>
  </tr>
  <tr>
    <th>Balo chữ nhật nắp gập</th>
    <th>BL089</th>
    <td>1</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Balo gắn charm pixel</th>
    <th>BL116</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Balo khoá scrunchies</th>
    <th>BL120</th>
    <td>4</td>
    <td>441,006</td>
  </tr>
  <tr>
    <th>Balo phom đứng hoạ tiết 3D BL063</th>
    <th>BL063</th>
    <td>9</td>
    <td>526,364</td>
  </tr>
  <tr>
    <th>Balo tay cầm nhún</th>
    <th>BL119</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Balo top handle</th>
    <th>BL117</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th rowspan="5" valign="top">
      Túi xách lớn
    </th>
    <th>Túi Tote laser cut</th>
    <th>TXL055</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách lớn top handle</th>
    <th>TXL060</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote gắn charm pixel</th>
    <th>TXL059</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote móc trang trí</th>
    <th>TXL047</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote tối giản</th>
    <th>TXL058</th>
    <td>7</td>
    <td>368,591</td>
  </tr>
  <tr>
    <th rowspan="47" valign="top">
      Túi xách nhỏ
    </th>
    <th>TXN và Clutch 2in1 trang trí khóa kim loại</th>
    <th>TXN482</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ Minnie 2 nắp có hoa văn</th>
    <th>TXN447</th>
    <td>1</td>
    <td>454,545</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ camera cầm tay đính charm</th>
    <th>TXN400</th>
    <td>1</td>
    <td>271,818</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ cut-out khoá trang trí</th>
    <th>TXN427</th>
    <td>1</td>
    <td>350,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ có khóa trang trí trong suốt</th>
    <th>TXN409</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng accordion</th>
    <th>TXN419</th>
    <td>2</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng hộp top-handle</th>
    <th>TXN470</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp chần bông trang trí xích</th>
    <th>TXN452</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp mini đeo chéo</th>
    <th>TXN390</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn charn pixel</th>
    <th>TXN477</th>
    <td>1</td>
    <td>350,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn khóa trang trí</th>
    <th>TXN432</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp top-handle</th>
    <th>TXN466</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá scrunchies</th>
    <th>TXN495</th>
    <td>2</td>
    <td>411,818</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khóa trang trí</th>
    <th>TXN370</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ laser cut nhỏ</th>
    <th>TXN426</th>
    <td>1</td>
    <td>363,636</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ mini circle charm</th>
    <th>TXN496</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ mini tay cầm scrunchies</th>
    <th>TXN494</th>
    <td>2</td>
    <td>303,682</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ multi-bag nắp tuck-in</th>
    <th>TXN460</th>
    <td>2</td>
    <td>909,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nhỏ nắp tuck-in</th>
    <th>TXN461</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp dập nổi</th>
    <th>TXN425</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gấp nếp</th>
    <th>TXN440</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập 2 tone màu</th>
    <th>TXN369</th>
    <td>7</td>
    <td>812,727</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập dập nổi 3d</th>
    <th>TXN313</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối hiệu ứng giả đan lát</th>
    <th>TXN472</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ phối khoá cùng màu
    </th>
    <th>TXN387</th>
    <td>5</td>
    <td>0</td>
  </tr>
  <tr>
    <th>TXN388</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm cách điệu</th>
    <th>TXN377</th>
    <td>1</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm nhún</th>
    <th>TXN492</th>
    <td>3</td>
    <td>350,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm rời</th>
    <th>TXN486</th>
    <td>4</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ the maze</th>
    <th>TXN483</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ thân xếp khối</th>
    <th>TXN442</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle dáng hộp</th>
    <th>TXN491</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle khóa kim loại raw</th>
    <th>TXN487</th>
    <td>4</td>
    <td>433,636</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tope handle dập nổi viền</th>
    <th>TXN465</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí khóa xoay</th>
    <th>TXN468</th>
    <td>1</td>
    <td>350,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí viền</th>
    <th>TXN406</th>
    <td>5</td>
    <td>723,636</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí xích kim loại</th>
    <th>TXN309</th>
    <td>1</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ túi đeo chéo gắn charm trang trí</th>
    <th>TXN401</th>
    <td>1</td>
    <td>362,727</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ và clutch 2in1</th>
    <th>TXN485</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chiếu hiệu ứng đan lát</th>
    <th>TXN394</th>
    <td>5</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo khóa bấm</th>
    <th>TXN414</th>
    <td>2</td>
    <td>322,227</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo khóa gài</th>
    <th>TXN413</th>
    <td>26</td>
    <td>4,160,909</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo nắp viền dây kim loại</th>
    <th>TXN488</th>
    <td>6</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo phối pvc màu</th>
    <th>TXN407</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ đeo chéo tối giản
    </th>
    <th>TXN404</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>TXN450</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đép chéo khóa gài</th>
    <th>TXN389</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th rowspan="9" valign="top">
      Túi xách trung
    </th>
    <th>Túi dập hiệu ứng Princess Diamond</th>
    <th>TXT226</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách trung buckle flap</th>
    <th>TXT243</th>
    <td>1</td>
    <td>368,591</td>
  </tr>
  <tr>
    <th>Túi xách trung buckled tote</th>
    <th>TXT242</th>
    <td>1</td>
    <td>368,591</td>
  </tr>
  <tr>
    <th>Túi xách trung thân xếp khối</th>
    <th>TXT234</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách trung tote charm trang trí phối 2 màu</th>
    <th>TXT220</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách trung tote trung nắp cách điệu</th>
    <th>TXT235</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách trung trang trí khóa kim loại raw</th>
    <th>TXT245</th>
    <td>2</td>
    <td>492,585</td>
  </tr>
  <tr>
    <th>Túi xách trung tối giản</th>
    <th>TXT201</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi đeo vai gắn charm túi mini</th>
    <th>TXT247</th>
    <td>2</td>
    <td>414,955</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Ví
    </th>
    <th rowspan="3" valign="top">
      Ví
    </th>
    <th>Ví cầm tay có ngăn điện thoại</th>
    <th>VI137</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Ví kèm dây đeo hiệu ứng đan</th>
    <th>VI162</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Ví phối màu color-block</th>
    <th>VI155</th>
    <td>1</td>
    <td>200,000</td>
  </tr>
  <tr>
    <th rowspan="53" valign="top">
      Áo
    </th>
    <th rowspan="24" valign="top">
      Áo Kiểu
    </th>
    <th>Áo Kiểu AKI003</th>
    <th>AKI003</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI004</th>
    <th>AKI004</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI010</th>
    <th>AKI010</th>
    <td>3</td>
    <td>351,818</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI013</th>
    <th>AKI013</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI015</th>
    <th>AKI015</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI016</th>
    <th>AKI016</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI017</th>
    <th>AKI017</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI019</th>
    <th>AKI019</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI021</th>
    <th>AKI021</th>
    <td>3</td>
    <td>351,818</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI022</th>
    <th>AKI022</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI023</th>
    <th>AKI023</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI025</th>
    <th>AKI025</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAA12009054HH</th>
    <th>BAA12009054HH</th>
    <td>1</td>
    <td>45,455</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAC12012037</th>
    <th>BAC12012037</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAC12101045</th>
    <th>BAC12101045</th>
    <td>4</td>
    <td>360,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMC12101053</th>
    <th>CMC12101053</th>
    <td>6</td>
    <td>540,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMS12009040NM</th>
    <th>CMS12009040NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMS12011015GC</th>
    <th>CMS12011015GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDO12009069</th>
    <th>TDO12009069</th>
    <td>3</td>
    <td>270,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDO12009070</th>
    <th>TDO12009070</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDO12010005GC</th>
    <th>TDO12010005GC</th>
    <td>3</td>
    <td>270,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDO12010018GC</th>
    <th>TDO12010018GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDO12010052NM</th>
    <th>TDO12010052NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDS12010043</th>
    <th>TDS12010043</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th rowspan="10" valign="top">
      Áo Sơ Mi
    </th>
    <th>Áo Sơ Mi ASM008</th>
    <th>ASM008</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM009</th>
    <th>ASM009</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ATH005</th>
    <th>ATH005</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAS12012006NM</th>
    <th>BAS12012006NM</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMC12012016</th>
    <th>CMC12012016</th>
    <td>7</td>
    <td>630,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12012038</th>
    <th>CMS12012038</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12101003</th>
    <th>CMS12101003</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12101014</th>
    <th>CMS12101014</th>
    <td>3</td>
    <td>270,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi SMB2001017</th>
    <th>SMB2001017</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi TDS12101024</th>
    <th>TDS12101024</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th rowspan="19" valign="top">
      Áo Thun
    </th>
    <th>Áo Thun ATH001</th>
    <th>ATH001</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo Thun ATH003</th>
    <th>ATH003</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo Thun ATH004</th>
    <th>ATH004</th>
    <td>2</td>
    <td>117,273</td>
  </tr>
  <tr>
    <th>Áo Thun ATH006</th>
    <th>ATH006</th>
    <td>13</td>
    <td>1,055,455</td>
  </tr>
  <tr>
    <th>Áo Thun ATH007</th>
    <th>ATH007</th>
    <td>5</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo Thun ATH008</th>
    <th>ATH008</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo Thun BAA12012022</th>
    <th>BAA12012022</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAA12012023</th>
    <th>BAA12012023</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101006</th>
    <th>BAC12101006</th>
    <td>3</td>
    <td>270,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101021</th>
    <th>BAC12101021</th>
    <td>3</td>
    <td>234,545</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101022</th>
    <th>BAC12101022</th>
    <td>9</td>
    <td>117,273</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101023</th>
    <th>BAC12101023</th>
    <td>4</td>
    <td>234,545</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101063</th>
    <th>BAC12101063</th>
    <td>8</td>
    <td>630,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101064</th>
    <th>BAC12101064</th>
    <td>4</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAS12101048</th>
    <th>BAS12101048</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo Thun BAS12101049</th>
    <th>BAS12101049</th>
    <td>2</td>
    <td>234,545</td>
  </tr>
  <tr>
    <th>Áo Thun CMC12010031HH</th>
    <th>CMC12010031HH</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo thun "Hành khỏe đẹp"</th>
    <th>ATH011</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo thun "Top giàu sang"</th>
    <th>ATH010</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th rowspan="521" valign="top">
      2
    </th>
    <th rowspan="297" valign="top">
      Giày
    </th>
    <th rowspan="64" valign="top">
      Dép Guốc
    </th>
    <th>Dép lào thời trang Juno hoạ tiết cây kem DK011</th>
    <th>DK011</th>
    <td>29</td>
    <td>954,545</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu trắng DK005</th>
    <th>DK005</th>
    <td>25</td>
    <td>863,636</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu xanh DK003</th>
    <th>DK003</th>
    <td>13</td>
    <td>449,091</td>
  </tr>
  <tr>
    <th>Dép DE001</th>
    <th>DE001</th>
    <td>1</td>
    <td>109,091</td>
  </tr>
  <tr>
    <th>Dép DE002</th>
    <th>DE002</th>
    <td>3</td>
    <td>218,182</td>
  </tr>
  <tr>
    <th>Dép DE003</th>
    <th>DE003</th>
    <td>6</td>
    <td>545,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK025</th>
    <th>DK025</th>
    <td>2</td>
    <td>86,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK030</th>
    <th>DK030</th>
    <td>5</td>
    <td>136,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK032</th>
    <th>DK032</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Dép Tông DK033</th>
    <th>DK033</th>
    <td>3</td>
    <td>190,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK034</th>
    <th>DK034</th>
    <td>5</td>
    <td>254,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK035</th>
    <th>DK035</th>
    <td>4</td>
    <td>248,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK036</th>
    <th>DK036</th>
    <td>21</td>
    <td>1,124,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK037</th>
    <th>DK037</th>
    <td>4</td>
    <td>251,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK038</th>
    <th>DK038</th>
    <td>10</td>
    <td>387,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK039</th>
    <th>DK039</th>
    <td>2</td>
    <td>127,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK040</th>
    <th>DK040</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK041</th>
    <th>DK041</th>
    <td>6</td>
    <td>315,000</td>
  </tr>
  <tr>
    <th>Dép Tông DK042</th>
    <th>DK042</th>
    <td>3</td>
    <td>187,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK043</th>
    <th>DK043</th>
    <td>4</td>
    <td>166,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK044</th>
    <th>DK044</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK045</th>
    <th>DK045</th>
    <td>11</td>
    <td>460,000</td>
  </tr>
  <tr>
    <th>Dép Tông DK046</th>
    <th>DK046</th>
    <td>4</td>
    <td>223,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK047</th>
    <th>DK047</th>
    <td>6</td>
    <td>318,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK048</th>
    <th>DK048</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Dép Tông DK049</th>
    <th>DK049</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK050</th>
    <th>DK050</th>
    <td>3</td>
    <td>181,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK051</th>
    <th>DK051</th>
    <td>6</td>
    <td>311,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK052</th>
    <th>DK052</th>
    <td>3</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK053</th>
    <th>DK053</th>
    <td>14</td>
    <td>701,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK054</th>
    <th>DK054</th>
    <td>1</td>
    <td>60,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK056</th>
    <th>DK056</th>
    <td>6</td>
    <td>218,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK058</th>
    <th>DK058</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK059</th>
    <th>DK059</th>
    <td>16</td>
    <td>559,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK060</th>
    <th>DK060</th>
    <td>4</td>
    <td>102,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK061</th>
    <th>DK061</th>
    <td>3</td>
    <td>159,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK062</th>
    <th>DK062</th>
    <td>5</td>
    <td>318,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK063</th>
    <th>DK063</th>
    <td>7</td>
    <td>435,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK064</th>
    <th>DK064</th>
    <td>12</td>
    <td>715,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK065</th>
    <th>DK065</th>
    <td>8</td>
    <td>391,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK066</th>
    <th>DK066</th>
    <td>2</td>
    <td>124,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK067</th>
    <th>DK067</th>
    <td>22</td>
    <td>1,032,000</td>
  </tr>
  <tr>
    <th>Dép Tông DK068</th>
    <th>DK068</th>
    <td>10</td>
    <td>607,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK069</th>
    <th>DK069</th>
    <td>13</td>
    <td>677,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK070</th>
    <th>DK070</th>
    <td>10</td>
    <td>525,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK071</th>
    <th>DK071</th>
    <td>4</td>
    <td>187,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK072</th>
    <th>DK072</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK073</th>
    <th>DK073</th>
    <td>12</td>
    <td>700,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK074</th>
    <th>DK074</th>
    <td>18</td>
    <td>1,044,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK075</th>
    <th>DK075</th>
    <td>49</td>
    <td>2,384,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK076</th>
    <th>DK076</th>
    <td>38</td>
    <td>2,176,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK077</th>
    <th>DK077</th>
    <td>40</td>
    <td>2,421,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK078</th>
    <th>DK078</th>
    <td>26</td>
    <td>1,603,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK079</th>
    <th>DK079</th>
    <td>36</td>
    <td>2,047,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK080</th>
    <th>DK080</th>
    <td>26</td>
    <td>1,501,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK081</th>
    <th>DK081</th>
    <td>32</td>
    <td>1,918,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK082</th>
    <th>DK082</th>
    <td>78</td>
    <td>4,627,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK083</th>
    <th>DK083</th>
    <td>37</td>
    <td>2,175,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK084</th>
    <th>DK084</th>
    <td>43</td>
    <td>2,492,000</td>
  </tr>
  <tr>
    <th>Dép lào Juno họa tiết vui nhộn DK018</th>
    <th>DK018</th>
    <td>2</td>
    <td>88,636</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno hoạ tiết sọc ngang DK006</th>
    <th>DK006</th>
    <td>3</td>
    <td>40,909</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno hoạ tiết trái thơm DK009</th>
    <th>DK009</th>
    <td>9</td>
    <td>272,727</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu đỏ DK002</th>
    <th>DK002</th>
    <td>9</td>
    <td>270,455</td>
  </tr>
  <tr>
    <th>Sản phẩm quà tặng QT015</th>
    <th>DK015</th>
    <td>34</td>
    <td>398,182</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày Boot
    </th>
    <th>Giày boot gót vuông viền cổ chân</th>
    <th>BO09015</th>
    <td>28</td>
    <td>5,804,682</td>
  </tr>
  <tr>
    <th>Giày boot dây kéo khoá tròn kim loại</th>
    <th>BO07016</th>
    <td>52</td>
    <td>12,568,273</td>
  </tr>
  <tr>
    <th rowspan="19" valign="top">
      Giày Búp Bê
    </th>
    <th>Giày búp bê dây mảnh đắp chéo phối vải lưới</th>
    <th>BB03068</th>
    <td>8</td>
    <td>1,054,773</td>
  </tr>
  <tr>
    <th>Giày búp bê gắn khóa trang trí</th>
    <th>BB03065</th>
    <td>40</td>
    <td>5,127,818</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn gắn khóa trang trí</th>
    <th>BB03064</th>
    <td>49</td>
    <td>5,243,818</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn khoét eo BB03015</th>
    <th>BB03015</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn khoét eo BB03021</th>
    <th>BB03021</th>
    <td>62</td>
    <td>7,718,273</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày búp bê mũi nhọn khóa trang trí
    </th>
    <th>BB03046</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>BB03058</th>
    <td>130</td>
    <td>20,275,182</td>
  </tr>
  <tr>
    <th>BB03062</th>
    <td>92</td>
    <td>13,828,591</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn quai chéo</th>
    <th>BB03070</th>
    <td>40</td>
    <td>6,997,636</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn quai hậu đắp chéo</th>
    <th>BB01139</th>
    <td>50</td>
    <td>6,973,455</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn trang trí dây kéo</th>
    <th>BB03048</th>
    <td>5</td>
    <td>874,751</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn đính khóa trang trí</th>
    <th>BB03066</th>
    <td>65</td>
    <td>10,932,273</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi vuông</th>
    <th>BB03047</th>
    <td>2</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày mules dây xích trang trí</th>
    <th>BB03063</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày mules gót ngọc trai</th>
    <th>BB03060</th>
    <td>2</td>
    <td>270,909</td>
  </tr>
  <tr>
    <th>Giày mules gót thấp in họa tiết graffiti</th>
    <th>BB03067</th>
    <td>60</td>
    <td>7,645,864</td>
  </tr>
  <tr>
    <th>Giày mules mũi nhọn khóa trang trí</th>
    <th>BB03059</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày mules quai khoá trang trí</th>
    <th>BB03069</th>
    <td>56</td>
    <td>10,125,818</td>
  </tr>
  <tr>
    <th>Waffle-Y-Cute</th>
    <th>BB03071</th>
    <td>37</td>
    <td>4,819,500</td>
  </tr>
  <tr>
    <th rowspan="77" valign="top">
      Giày Cao Gót
    </th>
    <th>Giày cao gót dây quai hậu</th>
    <th>CG09136</th>
    <td>5</td>
    <td>1,140,136</td>
  </tr>
  <tr>
    <th>Giày cao gót dây thắt nút</th>
    <th>CG07103</th>
    <td>29</td>
    <td>5,695,773</td>
  </tr>
  <tr>
    <th>Giày cao gót gót bọc trong suốt</th>
    <th>CG07098</th>
    <td>33</td>
    <td>5,665,364</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh mũi nhọn</th>
    <th>CG07092</th>
    <td>29</td>
    <td>3,375,273</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh phối si giả satin</th>
    <th>CG09138</th>
    <td>10</td>
    <td>1,748,500</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh quai chéo</th>
    <th>CG09119</th>
    <td>16</td>
    <td>541,818</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh quai cổ chân</th>
    <th>CG09120</th>
    <td>56</td>
    <td>6,232,727</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh trang trí đính đá</th>
    <th>CG09141</th>
    <td>9</td>
    <td>1,836,000</td>
  </tr>
  <tr>
    <th>Giày cao gót gót trụ khóa trang trí</th>
    <th>CG05109</th>
    <td>31</td>
    <td>6,798,136</td>
  </tr>
  <tr>
    <th>Giày cao gót gót trụ trang trí đính đá</th>
    <th>CG05110</th>
    <td>24</td>
    <td>5,062,909</td>
  </tr>
  <tr>
    <th>Giày cao gót gắn khoá trang trí</th>
    <th>CG09135</th>
    <td>27</td>
    <td>4,430,045</td>
  </tr>
  <tr>
    <th>Giày cao gót hậu rút nhún</th>
    <th>CG05103</th>
    <td>46</td>
    <td>8,138,909</td>
  </tr>
  <tr>
    <th>Giày cao gót khóa gài</th>
    <th>CG09144</th>
    <td>32</td>
    <td>5,737,500</td>
  </tr>
  <tr>
    <th>Giày cao gót khóa trang trí họa tiết mê cung</th>
    <th>CG09143</th>
    <td>22</td>
    <td>3,672,000</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót chuyển màu</th>
    <th>CG07101</th>
    <td>37</td>
    <td>5,854,636</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót hình thang</th>
    <th>CG11007</th>
    <td>16</td>
    <td>2,531,455</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót khắc trụ</th>
    <th>CG05104</th>
    <td>55</td>
    <td>9,703,909</td>
  </tr>
  <tr>
    <th rowspan="5" valign="top">
      Giày cao gót mũi nhọn gót thanh
    </th>
    <th>CG05089</th>
    <td>5</td>
    <td>451,818</td>
  </tr>
  <tr>
    <th>CG05107</th>
    <td>62</td>
    <td>8,197,182</td>
  </tr>
  <tr>
    <th>CG09125</th>
    <td>30</td>
    <td>4,401,000</td>
  </tr>
  <tr>
    <th>CG09130</th>
    <td>10</td>
    <td>1,476,682</td>
  </tr>
  <tr>
    <th>CG09131</th>
    <td>13</td>
    <td>1,687,636</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót thanh ánh nhũ</th>
    <th>CG09126</th>
    <td>23</td>
    <td>3,847,682</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày cao gót mũi nhọn gót trụ
    </th>
    <th>CG05092</th>
    <td>4</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>CG05112</th>
    <td>39</td>
    <td>5,906,727</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót xi kim loại</th>
    <th>CG09129</th>
    <td>37</td>
    <td>7,344,000</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gắn dây xích mảnh</th>
    <th>CG09145</th>
    <td>21</td>
    <td>3,672,000</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn slingback</th>
    <th>CG09127</th>
    <td>6</td>
    <td>1,377,000</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn đế đúp</th>
    <th>CG11008</th>
    <td>38</td>
    <td>7,116,273</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi tròn nơ trang trí</th>
    <th>CG05111</th>
    <td>11</td>
    <td>2,109,545</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi vuông gắn khóa trang trí</th>
    <th>CG07102</th>
    <td>13</td>
    <td>1,687,636</td>
  </tr>
  <tr>
    <th>Giày cao gót phối hai tone màu</th>
    <th>CG05100</th>
    <td>81</td>
    <td>11,276,455</td>
  </tr>
  <tr>
    <th>Giày cao gót pump gót thanh</th>
    <th>CG09128</th>
    <td>2</td>
    <td>459,000</td>
  </tr>
  <tr>
    <th>Giày cao gót pump gắn khóa trang trí</th>
    <th>CG05105</th>
    <td>29</td>
    <td>4,580,909</td>
  </tr>
  <tr>
    <th>Giày cao gót pump khóa trang trí</th>
    <th>CG09124</th>
    <td>111</td>
    <td>16,821,091</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi nhọn</th>
    <th>CG09146</th>
    <td>3</td>
    <td>632,864</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi nhọn gót thanh</th>
    <th>CG09140</th>
    <td>133</td>
    <td>20,481,455</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi vuông</th>
    <th>CG05096</th>
    <td>36</td>
    <td>4,244,591</td>
  </tr>
  <tr>
    <th>Giày cao gót pump quai xích trang trí cổ chân</th>
    <th>CG09133</th>
    <td>30</td>
    <td>6,196,500</td>
  </tr>
  <tr>
    <th>Giày cao gót pump đính khóa trang trí</th>
    <th>CG09134</th>
    <td>6</td>
    <td>1,054,773</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày cao gót quai ngang khoá trang trí
    </th>
    <th>CG05108</th>
    <td>23</td>
    <td>3,797,182</td>
  </tr>
  <tr>
    <th>CG07104</th>
    <td>18</td>
    <td>3,562,091</td>
  </tr>
  <tr>
    <th>Giày cao gót quai ngang khóa trang trí</th>
    <th>CG05101</th>
    <td>2</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày cao gót si kim tuyến</th>
    <th>CG07100</th>
    <td>8</td>
    <td>1,054,773</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót 2 màu</th>
    <th>CG07105</th>
    <td>25</td>
    <td>3,300,773</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót chuyển màu</th>
    <th>CG05094</th>
    <td>19</td>
    <td>2,531,455</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót phối kim loại</th>
    <th>CG07097</th>
    <td>4</td>
    <td>452,727</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót thanh</th>
    <th>CG09142</th>
    <td>16</td>
    <td>3,088,818</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót thanh đính khóa chữ j</th>
    <th>CG09121</th>
    <td>12</td>
    <td>2,021,864</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gắn khóa trang trí</th>
    <th>CG05106</th>
    <td>34</td>
    <td>6,750,545</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback in họa tiết vệt hoa</th>
    <th>CG09139</th>
    <td>9</td>
    <td>1,687,636</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày cao gót slingback mũi nhọn gót thanh
    </th>
    <th>CG07089</th>
    <td>5</td>
    <td>270,909</td>
  </tr>
  <tr>
    <th>CG07099</th>
    <td>17</td>
    <td>2,489,227</td>
  </tr>
  <tr>
    <th>CG09122</th>
    <td>29</td>
    <td>3,710,909</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi vuông gót trụ</th>
    <th>CG07096</th>
    <td>119</td>
    <td>16,907,545</td>
  </tr>
  <tr>
    <th>Giày Cao Gót Mũi Nhọn</th>
    <th>CG07086</th>
    <td>106</td>
    <td>19,268,727</td>
  </tr>
  <tr>
    <th>Giày Pump gót sơn ánh nhũ</th>
    <th>CG09132</th>
    <td>32</td>
    <td>6,031,636</td>
  </tr>
  <tr>
    <th>Giày cao gót 5cm mũi nhọn chất liệu êm ái CG05054</th>
    <th>CG05054</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót 5cm mũi nhọn phối màu CG05075</th>
    <th>CG05075</th>
    <td>28</td>
    <td>4,059,727</td>
  </tr>
  <tr>
    <th>Giày cao gót 5cm mũi tròn phối nơ CG05059</th>
    <th>CG05059</th>
    <td>33</td>
    <td>5,153,909</td>
  </tr>
  <tr>
    <th>Giày cao gót 9cm mũi nhọn da bóng CG09104</th>
    <th>CG09104</th>
    <td>117</td>
    <td>17,715,182</td>
  </tr>
  <tr>
    <th>Giày cao gót gót trụ quai chéo gắn khóa</th>
    <th>CG05114</th>
    <td>43</td>
    <td>7,805,318</td>
  </tr>
  <tr>
    <th>Giày cao gót họa tiết monogram</th>
    <th>CG11006</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót kim tuyến khoá trang trí</th>
    <th>CG09118</th>
    <td>1</td>
    <td>271,818</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn</th>
    <th>CG09116</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót lượn</th>
    <th>CG07083</th>
    <td>51</td>
    <td>5,875,455</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót thanh</th>
    <th>CG07080</th>
    <td>54</td>
    <td>8,279,364</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót trụ</th>
    <th>CG05099</th>
    <td>11</td>
    <td>1,447,273</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn khóa trang trí</th>
    <th>CG05081</th>
    <td>2</td>
    <td>208,636</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn phối si tương phản</th>
    <th>CG09137</th>
    <td>46</td>
    <td>6,700,909</td>
  </tr>
  <tr>
    <th>Giày cao gót quai hậu xé dán</th>
    <th>CG07095</th>
    <td>33</td>
    <td>2,624,545</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback khóa trang trí lục giác j</th>
    <th>CG07093</th>
    <td>19</td>
    <td>1,990,000</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi nhọn</th>
    <th>CG07094</th>
    <td>23</td>
    <td>2,713,636</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi nhọn gót thanh</th>
    <th>CG09112</th>
    <td>48</td>
    <td>5,466,364</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback phối si dập ly</th>
    <th>CG05102</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày mules bít mũi khóa trang trí đan lát</th>
    <th>CG07106</th>
    <td>33</td>
    <td>4,590,000</td>
  </tr>
  <tr>
    <th>Giày mules mũi nhọn gót trụ</th>
    <th>CG05097</th>
    <td>74</td>
    <td>14,125,682</td>
  </tr>
  <tr>
    <th rowspan="110" valign="top">
      Giày Sandal
    </th>
    <th>Dép 3 dây quai ngang</th>
    <th>SD01100</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Dép bệt mũi vuông khóa trang trí</th>
    <th>SD01107</th>
    <td>60</td>
    <td>9,242,273</td>
  </tr>
  <tr>
    <th>Dép bệt quai trang trí họa tiết mê cung</th>
    <th>SD03044</th>
    <td>44</td>
    <td>8,340,000</td>
  </tr>
  <tr>
    <th>Dép bệt quai xoắn</th>
    <th>SD01104</th>
    <td>10</td>
    <td>1,630,409</td>
  </tr>
  <tr>
    <th>Dép bệt quai đắp chéo phối pvc</th>
    <th>SD01102</th>
    <td>15</td>
    <td>1,092,136</td>
  </tr>
  <tr>
    <th>Dép bệt xỏ ngón trang trí khóa pu</th>
    <th>SD01103</th>
    <td>15</td>
    <td>2,112,045</td>
  </tr>
  <tr>
    <th>Dép bệt đan khoen trang trí</th>
    <th>SD01111</th>
    <td>38</td>
    <td>6,776,045</td>
  </tr>
  <tr>
    <th>Dép mũi vuông khóa trang trí</th>
    <th>SD01101</th>
    <td>14</td>
    <td>2,231,364</td>
  </tr>
  <tr>
    <th>Dép quai ngang gót sơn giả gỗ</th>
    <th>SD01096</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Dép quai ngang mũi vuông</th>
    <th>SD01095</th>
    <td>91</td>
    <td>13,063,636</td>
  </tr>
  <tr>
    <th>Dép quai ngang phối ánh kim</th>
    <th>SD01109</th>
    <td>73</td>
    <td>11,400,682</td>
  </tr>
  <tr>
    <th>Frozen Walk</th>
    <th>SD07066</th>
    <td>10</td>
    <td>2,434,091</td>
  </tr>
  <tr>
    <th>Giày mules mũi vuông gót phối kim loại</th>
    <th>SD07067</th>
    <td>52</td>
    <td>8,438,182</td>
  </tr>
  <tr>
    <th>Giày sandal bệt gắn khóa trang trí</th>
    <th>SD01097</th>
    <td>1</td>
    <td>183,136</td>
  </tr>
  <tr>
    <th>Giày sandal bệt mũi vuông</th>
    <th>SD01105</th>
    <td>40</td>
    <td>5,673,273</td>
  </tr>
  <tr>
    <th>Giày sandal bệt quai chéo</th>
    <th>SD01110</th>
    <td>19</td>
    <td>2,930,182</td>
  </tr>
  <tr>
    <th>Giày sandal bệt quai ngang khóa trang trí</th>
    <th>SD01108</th>
    <td>33</td>
    <td>5,376,591</td>
  </tr>
  <tr>
    <th>Giày sandal bệt xỏ ngón</th>
    <th>SD01099</th>
    <td>1</td>
    <td>183,136</td>
  </tr>
  <tr>
    <th>Giày sandal bệt xỏ ngón quai chéo</th>
    <th>SD01106</th>
    <td>60</td>
    <td>9,546,364</td>
  </tr>
  <tr>
    <th>Giày sandal bệt đế giả gỗ</th>
    <th>SD01098</th>
    <td>25</td>
    <td>4,364,091</td>
  </tr>
  <tr>
    <th>Giày sandal dây bất đối xứng</th>
    <th>SD11010</th>
    <td>14</td>
    <td>1,266,364</td>
  </tr>
  <tr>
    <th>Giày sandal dây hậu đan chéo</th>
    <th>SD07053</th>
    <td>17</td>
    <td>2,351,818</td>
  </tr>
  <tr>
    <th>Giày sandal dây mảnh</th>
    <th>SD07063</th>
    <td>35</td>
    <td>6,196,500</td>
  </tr>
  <tr>
    <th>Giày sandal dây mảnh gót thanh</th>
    <th>SD09092</th>
    <td>13</td>
    <td>2,320,500</td>
  </tr>
  <tr>
    <th>Giày sandal gót chữ a</th>
    <th>SD07064</th>
    <td>29</td>
    <td>4,131,000</td>
  </tr>
  <tr>
    <th>Giày sandal gót cách điệu 2 màu</th>
    <th>SD07065</th>
    <td>18</td>
    <td>2,338,227</td>
  </tr>
  <tr>
    <th>Giày sandal gót hình học</th>
    <th>SD09074</th>
    <td>17</td>
    <td>3,523,636</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal gót sơn chuyển màu
    </th>
    <th>SD09094</th>
    <td>11</td>
    <td>1,606,500</td>
  </tr>
  <tr>
    <th>SD11013</th>
    <td>2</td>
    <td>229,500</td>
  </tr>
  <tr>
    <th>Giày sandal gót sơn ombre</th>
    <th>SD07040</th>
    <td>35</td>
    <td>2,732,727</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh gắn xích trang trí</th>
    <th>SD09085</th>
    <td>21</td>
    <td>4,420,636</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh phối si dập ly</th>
    <th>SD11011</th>
    <td>1</td>
    <td>257,318</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh quai mảnh bất đối xứng</th>
    <th>SD09097</th>
    <td>11</td>
    <td>1,898,591</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh đế đúp</th>
    <th>SD09078</th>
    <td>29</td>
    <td>1,897,273</td>
  </tr>
  <tr>
    <th>Giày sandal gót thô hoa trang trí</th>
    <th>SD09076</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong có màu</th>
    <th>SD07052</th>
    <td>2</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong quai ngang</th>
    <th>SD09082</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong sơn ombre</th>
    <th>SD09090</th>
    <td>12</td>
    <td>2,590,955</td>
  </tr>
  <tr>
    <th>Giày sandal gót tròn</th>
    <th>SD07031</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal gót trụ phối trung tì kim tuyến</th>
    <th>SD09088</th>
    <td>3</td>
    <td>514,636</td>
  </tr>
  <tr>
    <th>Giày sandal gót trụ điêu khắc</th>
    <th>SD07055</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông</th>
    <th>SD09070</th>
    <td>5</td>
    <td>961,182</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông mũi vuông</th>
    <th>SD05055</th>
    <td>14</td>
    <td>1,760,909</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông chuyển màu</th>
    <th>SD09071</th>
    <td>20</td>
    <td>3,762,409</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông phối color block</th>
    <th>SD05056</th>
    <td>2</td>
    <td>346,409</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông quai bất đối xứng</th>
    <th>SD05066</th>
    <td>35</td>
    <td>5,099,000</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông quai mặt bất đối xứng</th>
    <th>SD05044</th>
    <td>171</td>
    <td>27,172,682</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal gót vuông quai ngang
    </th>
    <th>SD07046</th>
    <td>4</td>
    <td>905,455</td>
  </tr>
  <tr>
    <th>SD09084</th>
    <td>3</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông sơn lăn giả gỗ</th>
    <th>SD05062</th>
    <td>34</td>
    <td>5,584,827</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông viền kim loại</th>
    <th>SD07048</th>
    <td>2</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông viền kim loại, quai mảnh</th>
    <th>SD05063</th>
    <td>11</td>
    <td>843,818</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông đính hoa trang trí</th>
    <th>SD05052</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông đính hạt trang trí</th>
    <th>SD09080</th>
    <td>22</td>
    <td>2,802,727</td>
  </tr>
  <tr>
    <th>Giày sandal gót ánh kim</th>
    <th>SD11007</th>
    <td>4</td>
    <td>1,035,682</td>
  </tr>
  <tr>
    <th>Giày sandal gót đá 4 màu</th>
    <th>SD05057</th>
    <td>3</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Giày sandal họa tiết hoa khô</th>
    <th>SD09077</th>
    <td>19</td>
    <td>1,809,091</td>
  </tr>
  <tr>
    <th>Giày sandal hở gót quai ngang</th>
    <th>SD01091</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal hở mũi có dây cổ chân</th>
    <th>SD01094</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Giày sandal khóa gài</th>
    <th>SD07068</th>
    <td>7</td>
    <td>229,500</td>
  </tr>
  <tr>
    <th>Giày sandal kim tuyến gót thô</th>
    <th>SD11012</th>
    <td>18</td>
    <td>3,941,682</td>
  </tr>
  <tr>
    <th>Giày sandal mũi tròn quai mảnh bất đối xứng</th>
    <th>SD03045</th>
    <td>38</td>
    <td>6,117,682</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông dây mảnh</th>
    <th>SD07036</th>
    <td>2</td>
    <td>270,909</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal mũi vuông gót thanh
    </th>
    <th>SD09086</th>
    <td>4</td>
    <td>421,909</td>
  </tr>
  <tr>
    <th>SD09091</th>
    <td>1</td>
    <td>210,955</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal mũi vuông gót vuông
    </th>
    <th>SD07039</th>
    <td>32</td>
    <td>2,483,636</td>
  </tr>
  <tr>
    <th>SD09083</th>
    <td>13</td>
    <td>2,109,545</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông gót vuông ánh kim loại</th>
    <th>SD07045</th>
    <td>5</td>
    <td>753,682</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông in họa tiết vệt hoa</th>
    <th>SD09089</th>
    <td>5</td>
    <td>1,054,773</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal mũi vuông khóa trang trí
    </th>
    <th>SD03040</th>
    <td>26</td>
    <td>1,760,909</td>
  </tr>
  <tr>
    <th>SD07044</th>
    <td>33</td>
    <td>6,539,591</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông phối si vân</th>
    <th>SD07060</th>
    <td>29</td>
    <td>4,792,500</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông quai bất đối xứng</th>
    <th>SD07062</th>
    <td>13</td>
    <td>2,742,409</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông quai ngang</th>
    <th>SD07047</th>
    <td>3</td>
    <td>561,818</td>
  </tr>
  <tr>
    <th>Giày sandal phối chuyển màu</th>
    <th>SD05045</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày sandal phối dây kim tuyến</th>
    <th>SD07059</th>
    <td>3</td>
    <td>486,818</td>
  </tr>
  <tr>
    <th>Giày sandal phối dây vải sọc</th>
    <th>SD07051</th>
    <td>98</td>
    <td>13,930,000</td>
  </tr>
  <tr>
    <th>Giày sandal phối pvc gót trong</th>
    <th>SD07056</th>
    <td>24</td>
    <td>4,851,955</td>
  </tr>
  <tr>
    <th>Giày sandal phối si kim tuyến</th>
    <th>SD09087</th>
    <td>4</td>
    <td>632,864</td>
  </tr>
  <tr>
    <th>Giày sandal quai 3 dây xé dán</th>
    <th>SD05065</th>
    <td>31</td>
    <td>5,484,818</td>
  </tr>
  <tr>
    <th>Giày sandal quai mũi ngang giả xé dán</th>
    <th>SD07050</th>
    <td>17</td>
    <td>2,732,727</td>
  </tr>
  <tr>
    <th>Giày sandal quai mũi đan khoen trang trí</th>
    <th>SD05067</th>
    <td>27</td>
    <td>5,484,818</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang gót trong sơn ánh nhũ</th>
    <th>SD09096</th>
    <td>12</td>
    <td>2,109,545</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khoá trang trí</th>
    <th>SD07061</th>
    <td>37</td>
    <td>7,524,909</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khoá xé dán</th>
    <th>SD03041</th>
    <td>58</td>
    <td>11,103,773</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khóa trang trí</th>
    <th>SD05064</th>
    <td>53</td>
    <td>8,367,136</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang phối hai chất liệu</th>
    <th>SD07049</th>
    <td>2</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Giày sandal quai thắt nút</th>
    <th>SD07058</th>
    <td>2</td>
    <td>482,773</td>
  </tr>
  <tr>
    <th>Giày sandal thể thao 2 quai</th>
    <th>SD03039</th>
    <td>18</td>
    <td>3,481,591</td>
  </tr>
  <tr>
    <th>Giày sandal thể thao dây chéo</th>
    <th>SD03042</th>
    <td>29</td>
    <td>6,106,727</td>
  </tr>
  <tr>
    <th>Giày sandal xuồng quai mũi ngang phối pvc</th>
    <th>SD07054</th>
    <td>6</td>
    <td>905,455</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao</th>
    <th>SD05058</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao gắn khóa trang trí</th>
    <th>SD05061</th>
    <td>12</td>
    <td>2,007,500</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao quai chéo</th>
    <th>SD05059</th>
    <td>50</td>
    <td>9,203,864</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao quai mảnh</th>
    <th>SD05060</th>
    <td>47</td>
    <td>9,282,000</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao đan khoen trang trí</th>
    <th>SD05068</th>
    <td>48</td>
    <td>9,071,045</td>
  </tr>
  <tr>
    <th>Giày sandal đế xuồng đính hạt trang trí</th>
    <th>SD09079</th>
    <td>21</td>
    <td>2,302,727</td>
  </tr>
  <tr>
    <th>Giày sandal đế xuồng, quai chéo đối xứng</th>
    <th>SD07057</th>
    <td>9</td>
    <td>1,687,636</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp</th>
    <th>SD09073</th>
    <td>7</td>
    <td>403,364</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp bất đối xứng</th>
    <th>SD09081</th>
    <td>3</td>
    <td>455,864</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp gót thanh</th>
    <th>SD09093</th>
    <td>16</td>
    <td>1,836,000</td>
  </tr>
  <tr>
    <th>Giày xăng đan gót nhọn 11cm quai bản to SD11006</th>
    <th>SD11006</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày xăng đan gót vuông 5cm quai ngang SD05003</th>
    <th>SD05003</th>
    <td>228</td>
    <td>32,691,230</td>
  </tr>
  <tr>
    <th>Giày xăng đan quai ngang trang trí khoá kim loại SD03012</th>
    <th>SD03012</th>
    <td>22</td>
    <td>2,731,455</td>
  </tr>
  <tr>
    <th>Giày xăng đan quai ngang đế thể thao SD05028</th>
    <th>SD05028</th>
    <td>56</td>
    <td>11,549,182</td>
  </tr>
  <tr>
    <th>Giày xăng đan đế bệt quai mảnh SD01078</th>
    <th>SD01078</th>
    <td>2</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày xăng đan đế bệt quai ngang SD01081</th>
    <th>SD01081</th>
    <td>35</td>
    <td>5,336,000</td>
  </tr>
  <tr>
    <th>Jumping Snowflakes</th>
    <th>SD03043</th>
    <td>66</td>
    <td>10,035,409</td>
  </tr>
  <tr>
    <th>Lady-like-ice</th>
    <th>SD09095</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Xăng đan LẠC QUAN SD07027 - BST Bước Tới Vì Sao</th>
    <th>SD07027</th>
    <td>21</td>
    <td>4,138,909</td>
  </tr>
  <tr>
    <th rowspan="22" valign="top">
      Giày Thể Thao
    </th>
    <th>Classic Side by Side</th>
    <th>TT03030</th>
    <td>79</td>
    <td>17,840,766</td>
  </tr>
  <tr>
    <th>Comfy Easy Lazy</th>
    <th>TT03031</th>
    <td>51</td>
    <td>9,816,485</td>
  </tr>
  <tr>
    <th>Comfy Moon Walk</th>
    <th>TT03029</th>
    <td>114</td>
    <td>27,808,954</td>
  </tr>
  <tr>
    <th>Giày Thể Thao Classic</th>
    <th>TT03028</th>
    <td>12</td>
    <td>1,424,755</td>
  </tr>
  <tr>
    <th>Giày Thể Thao Classic Stick with me</th>
    <th>TT03032</th>
    <td>25</td>
    <td>6,137,682</td>
  </tr>
  <tr>
    <th>Giày thể thao classic TT05004</th>
    <th>TT05004</th>
    <td>8</td>
    <td>2,255,591</td>
  </tr>
  <tr>
    <th>Giày thể thao phong cách oxford</th>
    <th>TT03027</th>
    <td>9</td>
    <td>220,227</td>
  </tr>
  <tr>
    <th>Giày thể thao đế cao</th>
    <th>TT05003</th>
    <td>19</td>
    <td>3,036,818</td>
  </tr>
  <tr>
    <th>Giày thể thao đế chunky</th>
    <th>TT07001</th>
    <td>28</td>
    <td>9,951,955</td>
  </tr>
  <tr>
    <th>Juno Active Starter 1</th>
    <th>TT03021</th>
    <td>152</td>
    <td>29,287,045</td>
  </tr>
  <tr>
    <th>Juno Sneakers Soft Model TT03020 - Knit Basic</th>
    <th>TT03020</th>
    <td>34</td>
    <td>6,445,091</td>
  </tr>
  <tr>
    <th>Juno active starter 3</th>
    <th>TT03023</th>
    <td>29</td>
    <td>3,345,136</td>
  </tr>
  <tr>
    <th>Juno active starter 4</th>
    <th>TT03024</th>
    <td>33</td>
    <td>2,719,091</td>
  </tr>
  <tr>
    <th>Juno active starter 6</th>
    <th>TT05002</th>
    <td>11</td>
    <td>3,500,455</td>
  </tr>
  <tr>
    <th>Sneaker jelly</th>
    <th>TT03033</th>
    <td>80</td>
    <td>20,341,111</td>
  </tr>
  <tr>
    <th>Sneakers Ocean Wave</th>
    <th>TT05007</th>
    <td>62</td>
    <td>18,798,136</td>
  </tr>
  <tr>
    <th>Sneakers Rush Crush</th>
    <th>TT03034</th>
    <td>104</td>
    <td>17,300,829</td>
  </tr>
  <tr>
    <th>Trendy Bridging Chunky</th>
    <th>TT07003</th>
    <td>39</td>
    <td>5,528,864</td>
  </tr>
  <tr>
    <th>Trendy Elevated Flow</th>
    <th>TT05005</th>
    <td>51</td>
    <td>10,320,545</td>
  </tr>
  <tr>
    <th>Trendy Illuminated Chunky</th>
    <th>TT07002</th>
    <td>42</td>
    <td>13,235,803</td>
  </tr>
  <tr>
    <th>Trendy Levitated Chunky</th>
    <th>TT05006</th>
    <td>82</td>
    <td>25,909,591</td>
  </tr>
  <tr>
    <th>juno active starter 5</th>
    <th>TT03026</th>
    <td>101</td>
    <td>15,033,455</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày Đế Xuồng
    </th>
    <th rowspan="2" valign="top">
      Giày đế xuồng giả gỗ
    </th>
    <th>DX07019</th>
    <td>11</td>
    <td>1,354,545</td>
  </tr>
  <tr>
    <th>DX07020</th>
    <td>22</td>
    <td>1,836,409</td>
  </tr>
  <tr>
    <th>Giày đế xuồng quai mảnh</th>
    <th>DX07018</th>
    <td>11</td>
    <td>1,648,955</td>
  </tr>
  <tr>
    <th rowspan="148" valign="top">
      Túi xách
    </th>
    <th rowspan="12" valign="top">
      Balo
    </th>
    <th>Balo 2 ngăn có túi nhỏ trang trí phía trước</th>
    <th>BL109</th>
    <td>8</td>
    <td>1,933,364</td>
  </tr>
  <tr>
    <th>Balo buckle phối si vân</th>
    <th>BL111</th>
    <td>10</td>
    <td>1,474,364</td>
  </tr>
  <tr>
    <th>Balo gắn charm pixel</th>
    <th>BL116</th>
    <td>37</td>
    <td>10,851,409</td>
  </tr>
  <tr>
    <th>Balo khoá scrunchies</th>
    <th>BL120</th>
    <td>35</td>
    <td>9,513,818</td>
  </tr>
  <tr>
    <th>Balo nhiều ngăn</th>
    <th>BL118</th>
    <td>4</td>
    <td>1,659,818</td>
  </tr>
  <tr>
    <th>Balo phom đứng hoạ tiết 3D BL063</th>
    <th>BL063</th>
    <td>59</td>
    <td>3,241,091</td>
  </tr>
  <tr>
    <th>Balo phong cách minimal</th>
    <th>BL114</th>
    <td>17</td>
    <td>1,611,136</td>
  </tr>
  <tr>
    <th>Balo size mini năng động</th>
    <th>BL104</th>
    <td>28</td>
    <td>6,848,682</td>
  </tr>
  <tr>
    <th>Balo tay cầm nhún</th>
    <th>BL119</th>
    <td>115</td>
    <td>21,363,818</td>
  </tr>
  <tr>
    <th>Balo top handle</th>
    <th>BL117</th>
    <td>61</td>
    <td>14,375,045</td>
  </tr>
  <tr>
    <th>Balo tròn khóa bấm</th>
    <th>BL112</th>
    <td>15</td>
    <td>3,558,591</td>
  </tr>
  <tr>
    <th>Frost Bites Crumbly</th>
    <th>BL115</th>
    <td>4</td>
    <td>0</td>
  </tr>
  <tr>
    <th rowspan="7" valign="top">
      Túi xách lớn
    </th>
    <th>Túi Tote laser cut</th>
    <th>TXL055</th>
    <td>3</td>
    <td>368,591</td>
  </tr>
  <tr>
    <th>Túi cỡ lớn hoạ tiết 3D TXL025</th>
    <th>TXL025</th>
    <td>36</td>
    <td>6,365,000</td>
  </tr>
  <tr>
    <th>Túi xách lớn 2 ngăn</th>
    <th>TXL057</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách lớn top handle</th>
    <th>TXL060</th>
    <td>24</td>
    <td>8,109,000</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote gắn charm pixel</th>
    <th>TXL059</th>
    <td>12</td>
    <td>1,474,364</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote móc trang trí</th>
    <th>TXL047</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote tối giản</th>
    <th>TXL058</th>
    <td>47</td>
    <td>13,269,273</td>
  </tr>
  <tr>
    <th rowspan="103" valign="top">
      Túi xách nhỏ
    </th>
    <th>Freezing Fluffy</th>
    <th>TXN474</th>
    <td>7</td>
    <td>383,791</td>
  </tr>
  <tr>
    <th>Handy Sweetest Clutch</th>
    <th>TXN476</th>
    <td>13</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Summer Cool Dessert</th>
    <th>TXN475</th>
    <td>4</td>
    <td>1,474,364</td>
  </tr>
  <tr>
    <th>TXN và Clutch 2in1 trang trí khóa kim loại</th>
    <th>TXN482</th>
    <td>10</td>
    <td>2,206,909</td>
  </tr>
  <tr>
    <th>Túi Mickey cơ bản</th>
    <th>TXN381</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi Mini Duffle gắn charm pixel</th>
    <th>TXN478</th>
    <td>18</td>
    <td>5,800,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ 1 quai phối xích trong suốt</th>
    <th>TXN408</th>
    <td>8</td>
    <td>644,455</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ Minnie 2 nắp có hoa văn</th>
    <th>TXN447</th>
    <td>8</td>
    <td>2,100,273</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ Minnie chần bông có gắn nút</th>
    <th>TXN446</th>
    <td>1</td>
    <td>350,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ buckle flap</th>
    <th>TXN480</th>
    <td>26</td>
    <td>7,701,000</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ buckled trapeze</th>
    <th>TXN397</th>
    <td>10</td>
    <td>1,822,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ camera cầm tay đính charm</th>
    <th>TXN400</th>
    <td>32</td>
    <td>7,149,227</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ chân bông 3 tông màu</th>
    <th>TXN430</th>
    <td>1</td>
    <td>303,682</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ chần bông dạng hộp 3 tông màu</th>
    <th>TXN429</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ chần bông nắp gập</th>
    <th>TXN296</th>
    <td>14</td>
    <td>644,455</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ clutch chần bông</th>
    <th>TXN379</th>
    <td>8</td>
    <td>679,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ cut-out khoá trang trí</th>
    <th>TXN427</th>
    <td>4</td>
    <td>350,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ cạnh hông xếp nhiều ngăn</th>
    <th>TXN423</th>
    <td>6</td>
    <td>1,050,136</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng accordion</th>
    <th>TXN419</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng hộp dây xích cầm tay</th>
    <th>TXN405</th>
    <td>20</td>
    <td>3,544,500</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng hộp top-handle</th>
    <th>TXN470</th>
    <td>34</td>
    <td>7,882,318</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng vuông TXN219</th>
    <th>TXN219</th>
    <td>1</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dây đan đeo vai khóa xoay</th>
    <th>TXN395</th>
    <td>10</td>
    <td>1,611,136</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dây đeo đầu chữ T</th>
    <th>TXN424</th>
    <td>2</td>
    <td>303,682</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp</th>
    <th>TXN467</th>
    <td>56</td>
    <td>15,402,000</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp chần bông trang trí xích</th>
    <th>TXN452</th>
    <td>6</td>
    <td>1,853,818</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp mini đeo chéo</th>
    <th>TXN390</th>
    <td>2</td>
    <td>275,864</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ dập hiệu ứng princess diamond
    </th>
    <th>TXN416</th>
    <td>11</td>
    <td>3,544,500</td>
  </tr>
  <tr>
    <th>TXN417</th>
    <td>51</td>
    <td>16,102,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dập nổi họa tiết đối xứng</th>
    <th>TXN443</th>
    <td>11</td>
    <td>2,813,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn charn pixel</th>
    <th>TXN477</th>
    <td>29</td>
    <td>6,300,818</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn dây xích trang trí</th>
    <th>TXN431</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn khóa trang trí</th>
    <th>TXN432</th>
    <td>13</td>
    <td>2,800,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hobo trang trí khóa kim loại</th>
    <th>TXN457</th>
    <td>13</td>
    <td>3,340,500</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp top-handle</th>
    <th>TXN466</th>
    <td>8</td>
    <td>1,933,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp trang trí hiệu ứng dập nổi</th>
    <th>TXN463</th>
    <td>8</td>
    <td>2,800,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp trang trí khóa kim loại</th>
    <th>TXN455</th>
    <td>4</td>
    <td>322,227</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp trang trí khóa xoay</th>
    <th>TXN469</th>
    <td>10</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá logo trang trí</th>
    <th>TXN435</th>
    <td>6</td>
    <td>2,100,273</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá marble</th>
    <th>TXN493</th>
    <td>18</td>
    <td>4,383,318</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá scrunchies</th>
    <th>TXN495</th>
    <td>19</td>
    <td>5,950,773</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá xoắn trang trí</th>
    <th>TXN433</th>
    <td>11</td>
    <td>2,900,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khóa trang trí in họa tiết graffiti</th>
    <th>TXN436</th>
    <td>8</td>
    <td>700,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khóa trượt</th>
    <th>TXN415</th>
    <td>2</td>
    <td>644,455</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ laser cut nhỏ</th>
    <th>TXN426</th>
    <td>4</td>
    <td>966,682</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ mini circle charm</th>
    <th>TXN496</th>
    <td>59</td>
    <td>10,178,500</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ mini tay cầm scrunchies</th>
    <th>TXN494</th>
    <td>30</td>
    <td>3,644,182</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ minnie hình hộp</th>
    <th>TXN448</th>
    <td>13</td>
    <td>966,682</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ multi-bag nắp tuck-in</th>
    <th>TXN460</th>
    <td>18</td>
    <td>4,188,955</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nhỏ nắp tuck-in</th>
    <th>TXN461</th>
    <td>16</td>
    <td>2,443,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp cách điệu trang trí khóa kim loại</th>
    <th>TXN481</th>
    <td>17</td>
    <td>4,833,409</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp dập nổi</th>
    <th>TXN425</th>
    <td>8</td>
    <td>970,818</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập 2 tone màu</th>
    <th>TXN369</th>
    <td>8</td>
    <td>541,818</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập dập nổi 3d</th>
    <th>TXN313</th>
    <td>22</td>
    <td>5,800,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp lượn</th>
    <th>TXN501</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp pixel-cut</th>
    <th>TXN479</th>
    <td>13</td>
    <td>4,188,955</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp xéo đường chỉ nổi</th>
    <th>TXN428</th>
    <td>8</td>
    <td>1,652,545</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp đắp lớp</th>
    <th>TXN439</th>
    <td>4</td>
    <td>727,273</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phom dài tay cầm kim loại TXN250</th>
    <th>TXN250</th>
    <td>2</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phun hiệu ứng loang màu</th>
    <th>TXN459</th>
    <td>19</td>
    <td>6,603,455</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối 2 chất liệu khóa trang trí đính đá Baguette</th>
    <th>TXN392</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối 2 chất liệu khóa trang trí đính đá Princess</th>
    <th>TXN391</th>
    <td>6</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối 2 màu</th>
    <th>TXN294</th>
    <td>2</td>
    <td>644,455</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối animal print</th>
    <th>TXN353</th>
    <td>7</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối color block</th>
    <th>TXN386</th>
    <td>56</td>
    <td>2,255,591</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối hiệu ứng giả đan lát</th>
    <th>TXN472</th>
    <td>35</td>
    <td>9,344,591</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối khoá cùng màu</th>
    <th>TXN387</th>
    <td>16</td>
    <td>1,026,455</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối màu color-block</th>
    <th>TXN458</th>
    <td>11</td>
    <td>2,489,273</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối màu cách điệu</th>
    <th>TXN454</th>
    <td>12</td>
    <td>1,050,136</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm cách điệu</th>
    <th>TXN384</th>
    <td>11</td>
    <td>2,450,318</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm kim loại</th>
    <th>TXN308</th>
    <td>2</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm kim loại chữ d</th>
    <th>TXN374</th>
    <td>9</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm nhún</th>
    <th>TXN492</th>
    <td>68</td>
    <td>10,151,318</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm rời</th>
    <th>TXN486</th>
    <td>36</td>
    <td>9,942,630</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm trong suốt</th>
    <th>TXN410</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ the maze</th>
    <th>TXN483</th>
    <td>12</td>
    <td>3,544,500</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ thân chữ a</th>
    <th>TXN453</th>
    <td>3</td>
    <td>700,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ thân xếp khối</th>
    <th>TXN442</th>
    <td>19</td>
    <td>5,250,682</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle</th>
    <th>TXN403</th>
    <td>16</td>
    <td>5,600,727</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle dáng hộp</th>
    <th>TXN491</th>
    <td>13</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle khóa kim loại raw</th>
    <th>TXN487</th>
    <td>20</td>
    <td>4,815,002</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tope handle dập nổi viền</th>
    <th>TXN465</th>
    <td>10</td>
    <td>2,900,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tote nhỏ nắp cách điệu</th>
    <th>TXN451</th>
    <td>3</td>
    <td>350,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí hiệu ứng dập nổi</th>
    <th>TXN464</th>
    <td>15</td>
    <td>3,500,455</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí khóa the maze</th>
    <th>TXN484</th>
    <td>12</td>
    <td>3,150,409</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí khóa xoay</th>
    <th>TXN468</th>
    <td>57</td>
    <td>15,816,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí logo cách điệu</th>
    <th>TXN471</th>
    <td>25</td>
    <td>7,733,455</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí viền</th>
    <th>TXN406</th>
    <td>9</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ túi đeo chéo gắn charm trang trí</th>
    <th>TXN401</th>
    <td>7</td>
    <td>966,682</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ viền kim tuyến trên nắp</th>
    <th>TXN438</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ và clutch 2in1</th>
    <th>TXN485</th>
    <td>10</td>
    <td>1,801,227</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đan cạnh viền</th>
    <th>TXN490</th>
    <td>14</td>
    <td>4,188,955</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chiếu hiệu ứng đan lát</th>
    <th>TXN394</th>
    <td>7</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo khóa bấm</th>
    <th>TXN414</th>
    <td>42</td>
    <td>12,392,773</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo khóa gài</th>
    <th>TXN413</th>
    <td>44</td>
    <td>1,990,000</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo nắp gập</th>
    <th>TXN420</th>
    <td>12</td>
    <td>3,050,409</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo nắp viền dây kim loại</th>
    <th>TXN488</th>
    <td>12</td>
    <td>3,150,409</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ đeo chéo tối giản
    </th>
    <th>TXN404</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>TXN450</th>
    <td>49</td>
    <td>11,901,545</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai có 2 thiết kế dây đeo</th>
    <th>TXN441</th>
    <td>15</td>
    <td>3,850,500</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai hiệu ứng đẹp mắt</th>
    <th>TXN393</th>
    <td>4</td>
    <td>961,727</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai khóa mây</th>
    <th>TXN489</th>
    <td>40</td>
    <td>11,098,478</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai phun hiệu ứng loang màu</th>
    <th>TXN473</th>
    <td>3</td>
    <td>966,682</td>
  </tr>
  <tr>
    <th rowspan="26" valign="top">
      Túi xách trung
    </th>
    <th>Túi dập hiệu ứng Princess Diamond</th>
    <th>TXT226</th>
    <td>7</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách trung buckle flap</th>
    <th>TXT243</th>
    <td>17</td>
    <td>1,474,364</td>
  </tr>
  <tr>
    <th>Túi xách trung buckled tote</th>
    <th>TXT242</th>
    <td>32</td>
    <td>11,426,318</td>
  </tr>
  <tr>
    <th>Túi xách trung double handle</th>
    <th>TXT221</th>
    <td>9</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách trung dập hiệu ứng lập thể</th>
    <th>TXT238</th>
    <td>3</td>
    <td>1,189,227</td>
  </tr>
  <tr>
    <th>Túi xách trung dập sọc nổi</th>
    <th>TXT230</th>
    <td>25</td>
    <td>4,550,591</td>
  </tr>
  <tr>
    <th>Túi xách trung gắn khóa trang trí</th>
    <th>TXT231</th>
    <td>1</td>
    <td>350,045</td>
  </tr>
  <tr>
    <th>Túi xách trung hộp trang trí nút kim loại</th>
    <th>TXT233</th>
    <td>13</td>
    <td>4,550,591</td>
  </tr>
  <tr>
    <th>Túi xách trung khoá xoắn trang trí</th>
    <th>TXT232</th>
    <td>13</td>
    <td>2,450,318</td>
  </tr>
  <tr>
    <th>Túi xách trung multi-bag</th>
    <th>TXT237</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách trung nắp dập nổi</th>
    <th>TXT228</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách trung phun hiệu ứng loang màu</th>
    <th>TXT240</th>
    <td>10</td>
    <td>3,131,227</td>
  </tr>
  <tr>
    <th>Túi xách trung satchel dáng hộp</th>
    <th>TXT241</th>
    <td>10</td>
    <td>3,964,091</td>
  </tr>
  <tr>
    <th>Túi xách trung the maze</th>
    <th>TXT244</th>
    <td>18</td>
    <td>5,549,727</td>
  </tr>
  <tr>
    <th>Túi xách trung thân xếp khối</th>
    <th>TXT234</th>
    <td>6</td>
    <td>2,211,545</td>
  </tr>
  <tr>
    <th>Túi xách trung top handle</th>
    <th>TXT223</th>
    <td>13</td>
    <td>4,593,182</td>
  </tr>
  <tr>
    <th>Túi xách trung top handle nắp viền dây kim loại</th>
    <th>TXT246</th>
    <td>21</td>
    <td>6,224,318</td>
  </tr>
  <tr>
    <th>Túi xách trung tote charm trang trí phối 2 màu</th>
    <th>TXT220</th>
    <td>51</td>
    <td>16,723,636</td>
  </tr>
  <tr>
    <th>Túi xách trung tote double bag</th>
    <th>TXT222</th>
    <td>4</td>
    <td>368,591</td>
  </tr>
  <tr>
    <th>Túi xách trung tote trung nắp cách điệu</th>
    <th>TXT235</th>
    <td>10</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách trung tote trung phối khác chất liệu</th>
    <th>TXT236</th>
    <td>10</td>
    <td>737,182</td>
  </tr>
  <tr>
    <th>Túi xách trung trang trí khóa kim loại raw</th>
    <th>TXT245</th>
    <td>50</td>
    <td>7,287,334</td>
  </tr>
  <tr>
    <th>Túi xách trung treo charm trang trí</th>
    <th>TXT239</th>
    <td>23</td>
    <td>6,650,864</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách trung tối giản
    </th>
    <th>TXT201</th>
    <td>35</td>
    <td>9,829,911</td>
  </tr>
  <tr>
    <th>TXT204</th>
    <td>16</td>
    <td>1,400,182</td>
  </tr>
  <tr>
    <th>Túi đeo vai gắn charm túi mini</th>
    <th>TXT247</th>
    <td>43</td>
    <td>8,714,045</td>
  </tr>
  <tr>
    <th rowspan="20" valign="top">
      Ví
    </th>
    <th rowspan="20" valign="top">
      Ví
    </th>
    <th>Clutch dập hiệu ứng Princess Diamond</th>
    <th>VI141</th>
    <td>32</td>
    <td>6,947,591</td>
  </tr>
  <tr>
    <th>Ví 2 thân logo cách điệu</th>
    <th>VI156</th>
    <td>12</td>
    <td>2,186,939</td>
  </tr>
  <tr>
    <th>Ví chữ nhật kèm dây đeo</th>
    <th>VI147</th>
    <td>17</td>
    <td>2,071,727</td>
  </tr>
  <tr>
    <th>Ví cầm tay có ngăn điện thoại</th>
    <th>VI137</th>
    <td>42</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Ví cầm tay</th>
    <th>VI127</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Ví cầm tay hoạ tiết vân chìm VI052</th>
    <th>VI052</th>
    <td>12</td>
    <td>180,818</td>
  </tr>
  <tr>
    <th>Ví dài nhiều ngăn</th>
    <th>VI158</th>
    <td>23</td>
    <td>1,265,727</td>
  </tr>
  <tr>
    <th>Ví dài nắp pixel-cut</th>
    <th>VI157</th>
    <td>8</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Ví dây kéo kim loại nhiều ngăn</th>
    <th>VI149</th>
    <td>36</td>
    <td>5,127,818</td>
  </tr>
  <tr>
    <th>Ví khoá trang trí cùng màu</th>
    <th>VI136</th>
    <td>34</td>
    <td>4,117,091</td>
  </tr>
  <tr>
    <th>Ví kèm dây đeo hiệu ứng đan</th>
    <th>VI162</th>
    <td>46</td>
    <td>4,389,818</td>
  </tr>
  <tr>
    <th>Ví nắp dập nổi</th>
    <th>VI145</th>
    <td>5</td>
    <td>1,044,955</td>
  </tr>
  <tr>
    <th>Ví nắp gập khóa bấm</th>
    <th>VI153</th>
    <td>28</td>
    <td>1,103,455</td>
  </tr>
  <tr>
    <th>Ví phối hiệu ứng</th>
    <th>VI138</th>
    <td>5</td>
    <td>229,500</td>
  </tr>
  <tr>
    <th>Ví phối màu color-block</th>
    <th>VI155</th>
    <td>23</td>
    <td>4,245,727</td>
  </tr>
  <tr>
    <th>Ví the maze</th>
    <th>VI159</th>
    <td>10</td>
    <td>2,573,182</td>
  </tr>
  <tr>
    <th>Ví trang trí khóa kim loại</th>
    <th>VI154</th>
    <td>15</td>
    <td>257,318</td>
  </tr>
  <tr>
    <th>Ví trang trí khóa kim loại raw</th>
    <th>VI161</th>
    <td>66</td>
    <td>7,957,375</td>
  </tr>
  <tr>
    <th>Ví trang trí khóa the maze</th>
    <th>VI160</th>
    <td>22</td>
    <td>3,874,273</td>
  </tr>
  <tr>
    <th>Ví trang trí nút kim loại</th>
    <th>VI150</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th rowspan="56" valign="top">
      Áo
    </th>
    <th rowspan="20" valign="top">
      Áo Kiểu
    </th>
    <th>Áo Kiểu AKI004</th>
    <th>AKI004</th>
    <td>20</td>
    <td>3,256,364</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI006</th>
    <th>AKI006</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI010</th>
    <th>AKI010</th>
    <td>14</td>
    <td>1,401,409</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI013</th>
    <th>AKI013</th>
    <td>7</td>
    <td>1,266,364</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI015</th>
    <th>AKI015</th>
    <td>15</td>
    <td>2,532,727</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI016</th>
    <th>AKI016</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI018</th>
    <th>AKI018</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI020</th>
    <th>AKI020</th>
    <td>5</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI021</th>
    <th>AKI021</th>
    <td>4</td>
    <td>496,364</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI022</th>
    <th>AKI022</th>
    <td>5</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI023</th>
    <th>AKI023</th>
    <td>5</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI025</th>
    <th>AKI025</th>
    <td>4</td>
    <td>723,636</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI026</th>
    <th>AKI026</th>
    <td>5</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAA12009054HH</th>
    <th>BAA12009054HH</th>
    <td>5</td>
    <td>227,273</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAC12012036</th>
    <th>BAC12012036</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMA12009051GC</th>
    <th>CMA12009051GC</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMA12010029HH</th>
    <th>CMA12010029HH</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMC12101053</th>
    <th>CMC12101053</th>
    <td>3</td>
    <td>270,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMS12012030</th>
    <th>CMS12012030</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDO12010052NM</th>
    <th>TDO12010052NM</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th rowspan="14" valign="top">
      Áo Sơ Mi
    </th>
    <th>Áo Sơ Mi AKI009</th>
    <th>AKI009</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi AKI012</th>
    <th>AKI012</th>
    <td>5</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM004</th>
    <th>ASM004</th>
    <td>4</td>
    <td>723,636</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM007</th>
    <th>ASM007</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM008</th>
    <th>ASM008</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM009</th>
    <th>ASM009</th>
    <td>4</td>
    <td>723,636</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ATH005</th>
    <th>ATH005</th>
    <td>4</td>
    <td>234,545</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAA12010038NM</th>
    <th>BAA12010038NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAC12011006NM</th>
    <th>BAC12011006NM</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BPC12005059NM</th>
    <th>BPC12005059NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMC12012016</th>
    <th>CMC12012016</th>
    <td>3</td>
    <td>270,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12009041NM</th>
    <th>CMS12009041NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12012034</th>
    <th>CMS12012034</th>
    <td>16</td>
    <td>2,668,182</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12101003</th>
    <th>CMS12101003</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th rowspan="22" valign="top">
      Áo Thun
    </th>
    <th>Áo Thun AKI011</th>
    <th>AKI011</th>
    <td>4</td>
    <td>351,818</td>
  </tr>
  <tr>
    <th>Áo Thun ATH001</th>
    <th>ATH001</th>
    <td>4</td>
    <td>347,273</td>
  </tr>
  <tr>
    <th>Áo Thun ATH003</th>
    <th>ATH003</th>
    <td>1</td>
    <td>117,273</td>
  </tr>
  <tr>
    <th>Áo Thun ATH004</th>
    <th>ATH004</th>
    <td>1</td>
    <td>117,273</td>
  </tr>
  <tr>
    <th>Áo Thun ATH006</th>
    <th>ATH006</th>
    <td>19</td>
    <td>2,066,636</td>
  </tr>
  <tr>
    <th>Áo Thun ATH007</th>
    <th>ATH007</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Thun ATH008</th>
    <th>ATH008</th>
    <td>2</td>
    <td>407,273</td>
  </tr>
  <tr>
    <th>Áo Thun BAA12010033HH</th>
    <th>BAA12010033HH</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101021</th>
    <th>BAC12101021</th>
    <td>6</td>
    <td>695,455</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101022</th>
    <th>BAC12101022</th>
    <td>8</td>
    <td>699,091</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101023</th>
    <th>BAC12101023</th>
    <td>6</td>
    <td>469,091</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101063</th>
    <th>BAC12101063</th>
    <td>14</td>
    <td>1,080,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101064</th>
    <th>BAC12101064</th>
    <td>18</td>
    <td>1,440,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAS12101048</th>
    <th>BAS12101048</th>
    <td>10</td>
    <td>1,172,727</td>
  </tr>
  <tr>
    <th>Áo Thun BAS12101049</th>
    <th>BAS12101049</th>
    <td>3</td>
    <td>351,818</td>
  </tr>
  <tr>
    <th>Áo Thun CMA12010032HH</th>
    <th>CMA12010032HH</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Thun CMC12010017HH</th>
    <th>CMC12010017HH</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo thun "Bánh mỳ Sài gòn"</th>
    <th>ATH013</th>
    <td>10</td>
    <td>451,818</td>
  </tr>
  <tr>
    <th>Áo thun "Chích rồi ôm tí"</th>
    <th>ATH012</th>
    <td>6</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo thun "Hành khỏe đẹp"</th>
    <th>ATH011</th>
    <td>10</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo thun "Hành trứng ấm êm"</th>
    <th>ATH014</th>
    <td>12</td>
    <td>406,364</td>
  </tr>
  <tr>
    <th>Áo thun "Top giàu sang"</th>
    <th>ATH010</th>
    <td>7</td>
    <td>270,909</td>
  </tr>
  <tr>
    <th rowspan="564" valign="top">
      3
    </th>
    <th rowspan="303" valign="top">
      Giày
    </th>
    <th rowspan="59" valign="top">
      Dép Guốc
    </th>
    <th>Dép lào thời trang Juno hoạ tiết cây kem DK011</th>
    <th>DK011</th>
    <td>13</td>
    <td>318,182</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu trắng DK005</th>
    <th>DK005</th>
    <td>32</td>
    <td>409,091</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu xanh DK003</th>
    <th>DK003</th>
    <td>10</td>
    <td>138,636</td>
  </tr>
  <tr>
    <th>Dép DE003</th>
    <th>DE003</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Dép Guốc DE004</th>
    <th>DE004</th>
    <td>1</td>
    <td>92,727</td>
  </tr>
  <tr>
    <th>Dép Guốc DK023</th>
    <th>DK023</th>
    <td>1</td>
    <td>45,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK030</th>
    <th>DK030</th>
    <td>6</td>
    <td>134,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK033</th>
    <th>DK033</th>
    <td>12</td>
    <td>484,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK034</th>
    <th>DK034</th>
    <td>10</td>
    <td>459,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK035</th>
    <th>DK035</th>
    <td>8</td>
    <td>308,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK036</th>
    <th>DK036</th>
    <td>10</td>
    <td>486,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK037</th>
    <th>DK037</th>
    <td>10</td>
    <td>458,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK038</th>
    <th>DK038</th>
    <td>2</td>
    <td>114,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK039</th>
    <th>DK039</th>
    <td>2</td>
    <td>114,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK040</th>
    <th>DK040</th>
    <td>3</td>
    <td>124,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK041</th>
    <th>DK041</th>
    <td>5</td>
    <td>245,000</td>
  </tr>
  <tr>
    <th>Dép Tông DK043</th>
    <th>DK043</th>
    <td>1</td>
    <td>60,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK044</th>
    <th>DK044</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK045</th>
    <th>DK045</th>
    <td>3</td>
    <td>181,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK046</th>
    <th>DK046</th>
    <td>5</td>
    <td>237,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK047</th>
    <th>DK047</th>
    <td>3</td>
    <td>184,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK048</th>
    <th>DK048</th>
    <td>3</td>
    <td>159,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK049</th>
    <th>DK049</th>
    <td>4</td>
    <td>181,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK050</th>
    <th>DK050</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK051</th>
    <th>DK051</th>
    <td>6</td>
    <td>305,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK053</th>
    <th>DK053</th>
    <td>6</td>
    <td>220,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK056</th>
    <th>DK056</th>
    <td>5</td>
    <td>274,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK057</th>
    <th>DK057</th>
    <td>4</td>
    <td>245,000</td>
  </tr>
  <tr>
    <th>Dép Tông DK059</th>
    <th>DK059</th>
    <td>11</td>
    <td>409,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK060</th>
    <th>DK060</th>
    <td>9</td>
    <td>474,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK061</th>
    <th>DK061</th>
    <td>5</td>
    <td>228,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK062</th>
    <th>DK062</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK063</th>
    <th>DK063</th>
    <td>7</td>
    <td>435,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK064</th>
    <th>DK064</th>
    <td>7</td>
    <td>417,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK065</th>
    <th>DK065</th>
    <td>5</td>
    <td>302,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK066</th>
    <th>DK066</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK067</th>
    <th>DK067</th>
    <td>17</td>
    <td>683,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK068</th>
    <th>DK068</th>
    <td>8</td>
    <td>380,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK069</th>
    <th>DK069</th>
    <td>13</td>
    <td>715,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK070</th>
    <th>DK070</th>
    <td>10</td>
    <td>534,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK071</th>
    <th>DK071</th>
    <td>3</td>
    <td>124,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK072</th>
    <th>DK072</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK073</th>
    <th>DK073</th>
    <td>13</td>
    <td>741,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK074</th>
    <th>DK074</th>
    <td>28</td>
    <td>1,648,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK075</th>
    <th>DK075</th>
    <td>39</td>
    <td>2,165,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK076</th>
    <th>DK076</th>
    <td>38</td>
    <td>2,157,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK077</th>
    <th>DK077</th>
    <td>29</td>
    <td>1,423,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK078</th>
    <th>DK078</th>
    <td>39</td>
    <td>2,329,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK079</th>
    <th>DK079</th>
    <td>37</td>
    <td>2,177,000</td>
  </tr>
  <tr>
    <th>Dép Tông DK080</th>
    <th>DK080</th>
    <td>40</td>
    <td>2,380,000</td>
  </tr>
  <tr>
    <th>Dép Tông DK081</th>
    <th>DK081</th>
    <td>30</td>
    <td>1,750,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK082</th>
    <th>DK082</th>
    <td>57</td>
    <td>3,358,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK083</th>
    <th>DK083</th>
    <td>33</td>
    <td>1,919,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK084</th>
    <th>DK084</th>
    <td>48</td>
    <td>2,816,545</td>
  </tr>
  <tr>
    <th>Dép lào Juno họa tiết biển vui nhộn DK019</th>
    <th>DK019</th>
    <td>1</td>
    <td>45,455</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno hoạ tiết trái thơm DK009</th>
    <th>DK009</th>
    <td>4</td>
    <td>175,000</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno họa tiết trái cây vui nhọn DK020</th>
    <th>DK020</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu đỏ DK002</th>
    <th>DK002</th>
    <td>4</td>
    <td>136,364</td>
  </tr>
  <tr>
    <th>Sản phẩm quà tặng QT015</th>
    <th>DK015</th>
    <td>18</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th rowspan="5" valign="top">
      Giày Boot
    </th>
    <th>Giày boot gót phối kim loại</th>
    <th>BO07017</th>
    <td>2</td>
    <td>635,455</td>
  </tr>
  <tr>
    <th>Giày boot gót vuông viền cổ chân</th>
    <th>BO09015</th>
    <td>30</td>
    <td>6,194,591</td>
  </tr>
  <tr>
    <th>Giày boot cổ cao gót vuông</th>
    <th>BO07018</th>
    <td>2</td>
    <td>543,636</td>
  </tr>
  <tr>
    <th>Giày boot dây kéo khoá tròn kim loại</th>
    <th>BO07016</th>
    <td>64</td>
    <td>14,953,636</td>
  </tr>
  <tr>
    <th>Giày boot trang trí kim loại viền mũi</th>
    <th>BO09014</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th rowspan="18" valign="top">
      Giày Búp Bê
    </th>
    <th>Giày búp bê dây mảnh đắp chéo phối vải lưới</th>
    <th>BB03068</th>
    <td>44</td>
    <td>10,654,554</td>
  </tr>
  <tr>
    <th>Giày búp bê gắn khóa trang trí</th>
    <th>BB03065</th>
    <td>118</td>
    <td>30,423,095</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn có nơ</th>
    <th>BB01134</th>
    <td>1</td>
    <td>232,235</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn gắn khóa trang trí</th>
    <th>BB03064</th>
    <td>200</td>
    <td>53,153,853</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn khoét eo BB03021</th>
    <th>BB03021</th>
    <td>112</td>
    <td>22,212,987</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày búp bê mũi nhọn khóa trang trí
    </th>
    <th>BB03058</th>
    <td>146</td>
    <td>26,549,386</td>
  </tr>
  <tr>
    <th>BB03062</th>
    <td>267</td>
    <td>74,469,789</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn quai chéo</th>
    <th>BB03070</th>
    <td>89</td>
    <td>21,958,468</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn quai hậu đắp chéo</th>
    <th>BB01139</th>
    <td>50</td>
    <td>7,940,879</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn slingback</th>
    <th>BB03061</th>
    <td>2</td>
    <td>540,000</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn trang trí dây kéo</th>
    <th>BB03048</th>
    <td>1</td>
    <td>359,091</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn đính khóa trang trí</th>
    <th>BB03066</th>
    <td>241</td>
    <td>61,911,511</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi vuông</th>
    <th>BB03047</th>
    <td>2</td>
    <td>359,091</td>
  </tr>
  <tr>
    <th>Giày mules gót ngọc trai</th>
    <th>BB03060</th>
    <td>3</td>
    <td>562,406</td>
  </tr>
  <tr>
    <th>Giày mules gót thấp in họa tiết graffiti</th>
    <th>BB03067</th>
    <td>138</td>
    <td>32,007,968</td>
  </tr>
  <tr>
    <th>Giày mules mũi nhọn khóa trang trí</th>
    <th>BB03059</th>
    <td>3</td>
    <td>553,492</td>
  </tr>
  <tr>
    <th>Giày mules quai khoá trang trí</th>
    <th>BB03069</th>
    <td>320</td>
    <td>89,373,699</td>
  </tr>
  <tr>
    <th>Waffle-Y-Cute</th>
    <th>BB03071</th>
    <td>118</td>
    <td>28,705,764</td>
  </tr>
  <tr>
    <th rowspan="85" valign="top">
      Giày Cao Gót
    </th>
    <th>Giày cao gót dây quai hậu</th>
    <th>CG09136</th>
    <td>72</td>
    <td>19,483,614</td>
  </tr>
  <tr>
    <th>Giày cao gót dây thắt nút</th>
    <th>CG07103</th>
    <td>147</td>
    <td>42,317,760</td>
  </tr>
  <tr>
    <th>Giày cao gót gót bọc trong suốt</th>
    <th>CG07098</th>
    <td>51</td>
    <td>8,907,395</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh mũi nhọn</th>
    <th>CG07092</th>
    <td>107</td>
    <td>29,254,639</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh phối si giả satin</th>
    <th>CG09138</th>
    <td>68</td>
    <td>18,990,321</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh quai chéo</th>
    <th>CG09119</th>
    <td>10</td>
    <td>632,727</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh quai cổ chân</th>
    <th>CG09120</th>
    <td>66</td>
    <td>7,417,788</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh trang trí đính đá</th>
    <th>CG09141</th>
    <td>65</td>
    <td>17,812,134</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh xi kim loại</th>
    <th>CG09123</th>
    <td>23</td>
    <td>6,710,049</td>
  </tr>
  <tr>
    <th>Giày cao gót gót trụ khóa trang trí</th>
    <th>CG05109</th>
    <td>145</td>
    <td>40,965,923</td>
  </tr>
  <tr>
    <th>Giày cao gót gót trụ trang trí đính đá</th>
    <th>CG05110</th>
    <td>80</td>
    <td>21,690,592</td>
  </tr>
  <tr>
    <th>Giày cao gót gắn khoá trang trí</th>
    <th>CG09135</th>
    <td>153</td>
    <td>44,194,080</td>
  </tr>
  <tr>
    <th>Giày cao gót hậu rút nhún</th>
    <th>CG05103</th>
    <td>213</td>
    <td>61,414,897</td>
  </tr>
  <tr>
    <th>Giày cao gót khóa gài</th>
    <th>CG09144</th>
    <td>218</td>
    <td>65,732,854</td>
  </tr>
  <tr>
    <th>Giày cao gót khóa trang trí họa tiết mê cung</th>
    <th>CG09143</th>
    <td>125</td>
    <td>35,928,392</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót chuyển màu</th>
    <th>CG07101</th>
    <td>38</td>
    <td>8,594,145</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót hình thang</th>
    <th>CG11007</th>
    <td>68</td>
    <td>18,474,535</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót khắc trụ</th>
    <th>CG05104</th>
    <td>216</td>
    <td>58,825,353</td>
  </tr>
  <tr>
    <th rowspan="5" valign="top">
      Giày cao gót mũi nhọn gót thanh
    </th>
    <th>CG05089</th>
    <td>6</td>
    <td>862,893</td>
  </tr>
  <tr>
    <th>CG05107</th>
    <td>232</td>
    <td>62,839,942</td>
  </tr>
  <tr>
    <th>CG09125</th>
    <td>40</td>
    <td>6,243,581</td>
  </tr>
  <tr>
    <th>CG09130</th>
    <td>53</td>
    <td>14,342,154</td>
  </tr>
  <tr>
    <th>CG09131</th>
    <td>74</td>
    <td>18,744,206</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót thanh ánh nhũ</th>
    <th>CG09126</th>
    <td>20</td>
    <td>3,743,592</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày cao gót mũi nhọn gót trụ
    </th>
    <th>CG05092</th>
    <td>2</td>
    <td>405,217</td>
  </tr>
  <tr>
    <th>CG05093</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>CG05112</th>
    <td>207</td>
    <td>56,224,877</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót xi kim loại</th>
    <th>CG09129</th>
    <td>217</td>
    <td>66,325,302</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gắn dây xích mảnh</th>
    <th>CG09145</th>
    <td>153</td>
    <td>45,512,562</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn slingback</th>
    <th>CG09127</th>
    <td>40</td>
    <td>11,286,467</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn đế đúp</th>
    <th>CG11008</th>
    <td>35</td>
    <td>8,087,849</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi tròn nơ trang trí</th>
    <th>CG05111</th>
    <td>25</td>
    <td>8,281,000</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi vuông gắn khóa trang trí</th>
    <th>CG07102</th>
    <td>121</td>
    <td>32,299,279</td>
  </tr>
  <tr>
    <th>Giày cao gót phối hai tone màu</th>
    <th>CG05100</th>
    <td>75</td>
    <td>10,618,172</td>
  </tr>
  <tr>
    <th>Giày cao gót pump gót thanh</th>
    <th>CG09128</th>
    <td>18</td>
    <td>5,026,248</td>
  </tr>
  <tr>
    <th>Giày cao gót pump gắn khóa trang trí</th>
    <th>CG05105</th>
    <td>117</td>
    <td>32,833,581</td>
  </tr>
  <tr>
    <th>Giày cao gót pump khóa trang trí</th>
    <th>CG09124</th>
    <td>78</td>
    <td>12,877,029</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi nhọn</th>
    <th>CG09146</th>
    <td>14</td>
    <td>4,148,773</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi nhọn gót thanh</th>
    <th>CG09140</th>
    <td>56</td>
    <td>7,877,955</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi vuông</th>
    <th>CG05096</th>
    <td>126</td>
    <td>32,583,898</td>
  </tr>
  <tr>
    <th>Giày cao gót pump quai xích trang trí cổ chân</th>
    <th>CG09133</th>
    <td>219</td>
    <td>64,075,298</td>
  </tr>
  <tr>
    <th>Giày cao gót pump đính khóa trang trí</th>
    <th>CG09134</th>
    <td>36</td>
    <td>9,120,306</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày cao gót quai ngang khoá trang trí
    </th>
    <th>CG05108</th>
    <td>209</td>
    <td>57,160,259</td>
  </tr>
  <tr>
    <th>CG07104</th>
    <td>194</td>
    <td>57,159,601</td>
  </tr>
  <tr>
    <th>Giày cao gót quai ngang khóa trang trí</th>
    <th>CG05101</th>
    <td>5</td>
    <td>1,193,399</td>
  </tr>
  <tr>
    <th>Giày cao gót si kim tuyến</th>
    <th>CG07100</th>
    <td>25</td>
    <td>6,359,397</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót 2 màu</th>
    <th>CG07105</th>
    <td>128</td>
    <td>36,847,790</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót chuyển màu</th>
    <th>CG05094</th>
    <td>99</td>
    <td>28,722,444</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót phối kim loại</th>
    <th>CG07097</th>
    <td>8</td>
    <td>1,787,523</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày cao gót slingback gót thanh
    </th>
    <th>CG07091</th>
    <td>2</td>
    <td>466,364</td>
  </tr>
  <tr>
    <th>CG09142</th>
    <td>87</td>
    <td>25,029,291</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót thanh đính khóa chữ j</th>
    <th>CG09121</th>
    <td>8</td>
    <td>1,826,613</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gắn khóa trang trí</th>
    <th>CG05106</th>
    <td>108</td>
    <td>29,429,666</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback in họa tiết vệt hoa</th>
    <th>CG09139</th>
    <td>62</td>
    <td>17,756,646</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback khoá trang trí</th>
    <th>CG05098</th>
    <td>1</td>
    <td>269,763</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày cao gót slingback mũi nhọn gót thanh
    </th>
    <th>CG07089</th>
    <td>15</td>
    <td>1,855,342</td>
  </tr>
  <tr>
    <th>CG07099</th>
    <td>29</td>
    <td>5,911,799</td>
  </tr>
  <tr>
    <th>CG09122</th>
    <td>18</td>
    <td>1,628,182</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi vuông gót trụ</th>
    <th>CG07096</th>
    <td>109</td>
    <td>18,253,419</td>
  </tr>
  <tr>
    <th>Giày Cao Gót Mũi Nhọn</th>
    <th>CG07086</th>
    <td>531</td>
    <td>143,091,633</td>
  </tr>
  <tr>
    <th>Giày Pump gót sơn ánh nhũ</th>
    <th>CG09132</th>
    <td>60</td>
    <td>13,951,089</td>
  </tr>
  <tr>
    <th>Giày cao gót 5cm mũi nhọn phối màu CG05075</th>
    <th>CG05075</th>
    <td>101</td>
    <td>28,307,886</td>
  </tr>
  <tr>
    <th>Giày cao gót 5cm mũi tròn phối nơ CG05059</th>
    <th>CG05059</th>
    <td>173</td>
    <td>47,201,214</td>
  </tr>
  <tr>
    <th>Giày cao gót 9cm mũi nhọn da bóng CG09104</th>
    <th>CG09104</th>
    <td>172</td>
    <td>41,154,355</td>
  </tr>
  <tr>
    <th>Giày cao gót gót trụ quai chéo gắn khóa</th>
    <th>CG05114</th>
    <td>199</td>
    <td>52,289,688</td>
  </tr>
  <tr>
    <th>Giày cao gót họa tiết monogram</th>
    <th>CG11006</th>
    <td>2</td>
    <td>286,267</td>
  </tr>
  <tr>
    <th>Giày cao gót kim tuyến khoá trang trí</th>
    <th>CG09118</th>
    <td>5</td>
    <td>1,442,727</td>
  </tr>
  <tr>
    <th>Giày cao gót mules mũi nhọn khóa trang trí</th>
    <th>CG05095</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn</th>
    <th>CG09116</th>
    <td>4</td>
    <td>1,078,419</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót lượn</th>
    <th>CG07083</th>
    <td>174</td>
    <td>44,446,769</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót thanh</th>
    <th>CG07080</th>
    <td>196</td>
    <td>56,366,819</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót trụ</th>
    <th>CG05099</th>
    <td>18</td>
    <td>2,455,455</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn khoét gót</th>
    <th>CG09111</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn khóa trang trí</th>
    <th>CG05081</th>
    <td>5</td>
    <td>1,289,777</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn phối lưới</th>
    <th>CG07087</th>
    <td>5</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn phối si tương phản</th>
    <th>CG09137</th>
    <td>90</td>
    <td>21,754,965</td>
  </tr>
  <tr>
    <th>Giày cao gót quai cổ chân</th>
    <th>CG09117</th>
    <td>2</td>
    <td>413,636</td>
  </tr>
  <tr>
    <th>Giày cao gót quai hậu xé dán</th>
    <th>CG07095</th>
    <td>23</td>
    <td>3,680,909</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback họa tiết monogram</th>
    <th>CG07081</th>
    <td>1</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback khóa trang trí lục giác j</th>
    <th>CG07093</th>
    <td>11</td>
    <td>1,374,308</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi nhọn</th>
    <th>CG07094</th>
    <td>17</td>
    <td>3,015,719</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi nhọn gót thanh</th>
    <th>CG09112</th>
    <td>52</td>
    <td>7,654,353</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback phối si dập ly</th>
    <th>CG05102</th>
    <td>4</td>
    <td>815,455</td>
  </tr>
  <tr>
    <th>Giày mules bít mũi khóa trang trí đan lát</th>
    <th>CG07106</th>
    <td>198</td>
    <td>57,517,577</td>
  </tr>
  <tr>
    <th>Giày mules mũi nhọn gót trụ</th>
    <th>CG05097</th>
    <td>415</td>
    <td>127,456,392</td>
  </tr>
  <tr>
    <th rowspan="110" valign="top">
      Giày Sandal
    </th>
    <th>Dép 3 dây quai ngang</th>
    <th>SD01100</th>
    <td>2</td>
    <td>369,644</td>
  </tr>
  <tr>
    <th>Dép bệt mũi vuông khóa trang trí</th>
    <th>SD01107</th>
    <td>93</td>
    <td>26,005,971</td>
  </tr>
  <tr>
    <th>Dép bệt quai trang trí họa tiết mê cung</th>
    <th>SD03044</th>
    <td>127</td>
    <td>34,457,925</td>
  </tr>
  <tr>
    <th>Dép bệt quai xoắn</th>
    <th>SD01104</th>
    <td>15</td>
    <td>2,532,227</td>
  </tr>
  <tr>
    <th>Dép bệt quai đắp chéo phối pvc</th>
    <th>SD01102</th>
    <td>28</td>
    <td>5,862,364</td>
  </tr>
  <tr>
    <th>Dép bệt xỏ ngón trang trí khóa pu</th>
    <th>SD01103</th>
    <td>18</td>
    <td>4,697,201</td>
  </tr>
  <tr>
    <th>Dép bệt đan khoen trang trí</th>
    <th>SD01111</th>
    <td>105</td>
    <td>31,829,312</td>
  </tr>
  <tr>
    <th>Dép mũi vuông khóa trang trí</th>
    <th>SD01101</th>
    <td>38</td>
    <td>11,463,772</td>
  </tr>
  <tr>
    <th>Dép quai ngang mũi vuông</th>
    <th>SD01095</th>
    <td>65</td>
    <td>10,726,873</td>
  </tr>
  <tr>
    <th>Dép quai ngang phối ánh kim</th>
    <th>SD01109</th>
    <td>132</td>
    <td>30,038,757</td>
  </tr>
  <tr>
    <th>Frozen Walk</th>
    <th>SD07066</th>
    <td>57</td>
    <td>17,519,826</td>
  </tr>
  <tr>
    <th>Giày Sandal Gót Thô Quai Ngang</th>
    <th>SD09072</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày mules mũi vuông gót phối kim loại</th>
    <th>SD07067</th>
    <td>203</td>
    <td>56,141,081</td>
  </tr>
  <tr>
    <th>Giày sandal bệt mũi vuông</th>
    <th>SD01105</th>
    <td>31</td>
    <td>6,263,797</td>
  </tr>
  <tr>
    <th>Giày sandal bệt quai chéo</th>
    <th>SD01110</th>
    <td>69</td>
    <td>16,655,483</td>
  </tr>
  <tr>
    <th>Giày sandal bệt quai ngang khóa trang trí</th>
    <th>SD01108</th>
    <td>93</td>
    <td>24,174,358</td>
  </tr>
  <tr>
    <th>Giày sandal bệt xỏ ngón quai chéo</th>
    <th>SD01106</th>
    <td>30</td>
    <td>5,124,599</td>
  </tr>
  <tr>
    <th>Giày sandal bệt đế giả gỗ</th>
    <th>SD01098</th>
    <td>43</td>
    <td>11,109,182</td>
  </tr>
  <tr>
    <th>Giày sandal dây bất đối xứng</th>
    <th>SD11010</th>
    <td>10</td>
    <td>1,962,583</td>
  </tr>
  <tr>
    <th>Giày sandal dây hậu đan chéo</th>
    <th>SD07053</th>
    <td>11</td>
    <td>1,341,841</td>
  </tr>
  <tr>
    <th>Giày sandal dây mảnh</th>
    <th>SD07063</th>
    <td>179</td>
    <td>52,342,639</td>
  </tr>
  <tr>
    <th>Giày sandal dây mảnh gót thanh</th>
    <th>SD09092</th>
    <td>64</td>
    <td>19,313,677</td>
  </tr>
  <tr>
    <th>Giày sandal gót chữ a</th>
    <th>SD07064</th>
    <td>131</td>
    <td>36,080,599</td>
  </tr>
  <tr>
    <th>Giày sandal gót cách điệu 2 màu</th>
    <th>SD07065</th>
    <td>81</td>
    <td>23,128,303</td>
  </tr>
  <tr>
    <th>Giày sandal gót hình học</th>
    <th>SD09074</th>
    <td>165</td>
    <td>45,250,945</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal gót sơn chuyển màu
    </th>
    <th>SD09094</th>
    <td>63</td>
    <td>16,940,091</td>
  </tr>
  <tr>
    <th>SD11013</th>
    <td>16</td>
    <td>3,958,088</td>
  </tr>
  <tr>
    <th>Giày sandal gót sơn ombre</th>
    <th>SD07040</th>
    <td>34</td>
    <td>4,800,113</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh gắn xích trang trí</th>
    <th>SD09085</th>
    <td>123</td>
    <td>37,099,528</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh phối si dập ly</th>
    <th>SD11011</th>
    <td>5</td>
    <td>1,189,569</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh quai mảnh bất đối xứng</th>
    <th>SD09097</th>
    <td>51</td>
    <td>14,862,882</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh đế đúp</th>
    <th>SD09078</th>
    <td>22</td>
    <td>3,513,636</td>
  </tr>
  <tr>
    <th>Giày sandal gót thô hoa trang trí</th>
    <th>SD09076</th>
    <td>1</td>
    <td>264,727</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong có màu</th>
    <th>SD07052</th>
    <td>5</td>
    <td>1,140,390</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong quai ngang</th>
    <th>SD09082</th>
    <td>3</td>
    <td>736,341</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong sơn ombre</th>
    <th>SD09090</th>
    <td>115</td>
    <td>32,184,673</td>
  </tr>
  <tr>
    <th>Giày sandal gót trụ phối trung tì kim tuyến</th>
    <th>SD09088</th>
    <td>19</td>
    <td>5,521,737</td>
  </tr>
  <tr>
    <th>Giày sandal gót trụ điêu khắc</th>
    <th>SD07055</th>
    <td>3</td>
    <td>543,636</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal gót vuông
    </th>
    <th>SD05048</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>SD09070</th>
    <td>18</td>
    <td>4,881,086</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông mũi vuông</th>
    <th>SD05055</th>
    <td>26</td>
    <td>2,957,273</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông chuyển màu</th>
    <th>SD09071</th>
    <td>178</td>
    <td>47,037,584</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông khóa sơn cùng màu</th>
    <th>SD09075</th>
    <td>1</td>
    <td>375,682</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông phối color block</th>
    <th>SD05056</th>
    <td>2</td>
    <td>549,091</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông phối si hologram</th>
    <th>SD07038</th>
    <td>2</td>
    <td>270,909</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông quai bất đối xứng</th>
    <th>SD05066</th>
    <td>133</td>
    <td>35,126,961</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông quai mặt bất đối xứng</th>
    <th>SD05044</th>
    <td>69</td>
    <td>10,500,182</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal gót vuông quai ngang
    </th>
    <th>SD07046</th>
    <td>16</td>
    <td>4,005,969</td>
  </tr>
  <tr>
    <th>SD09084</th>
    <td>4</td>
    <td>991,212</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông sơn lăn giả gỗ</th>
    <th>SD05062</th>
    <td>27</td>
    <td>5,403,041</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông viền kim loại</th>
    <th>SD07048</th>
    <td>3</td>
    <td>663,682</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông viền kim loại, quai mảnh</th>
    <th>SD05063</th>
    <td>97</td>
    <td>26,779,308</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông đính hoa trang trí</th>
    <th>SD05052</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông đính hạt trang trí</th>
    <th>SD09080</th>
    <td>20</td>
    <td>2,743,875</td>
  </tr>
  <tr>
    <th>Giày sandal gót ánh kim</th>
    <th>SD11007</th>
    <td>17</td>
    <td>4,607,846</td>
  </tr>
  <tr>
    <th>Giày sandal gót đá 4 màu</th>
    <th>SD05057</th>
    <td>3</td>
    <td>943,030</td>
  </tr>
  <tr>
    <th>Giày sandal họa tiết hoa khô</th>
    <th>SD09077</th>
    <td>7</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Giày sandal hở mũi có dây cổ chân</th>
    <th>SD01094</th>
    <td>5</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal khóa gài</th>
    <th>SD07068</th>
    <td>48</td>
    <td>15,028,377</td>
  </tr>
  <tr>
    <th>Giày sandal kim tuyến gót thô</th>
    <th>SD11012</th>
    <td>156</td>
    <td>48,309,388</td>
  </tr>
  <tr>
    <th>Giày sandal mũi tròn quai mảnh bất đối xứng</th>
    <th>SD03045</th>
    <td>113</td>
    <td>32,149,079</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông dây mảnh</th>
    <th>SD07036</th>
    <td>3</td>
    <td>406,364</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal mũi vuông gót thanh
    </th>
    <th>SD09086</th>
    <td>24</td>
    <td>7,662,785</td>
  </tr>
  <tr>
    <th>SD09091</th>
    <td>13</td>
    <td>4,490,807</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal mũi vuông gót vuông
    </th>
    <th>SD07039</th>
    <td>26</td>
    <td>2,907,148</td>
  </tr>
  <tr>
    <th>SD09083</th>
    <td>79</td>
    <td>20,879,285</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông gót vuông ánh kim loại</th>
    <th>SD07045</th>
    <td>4</td>
    <td>723,636</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông in họa tiết vệt hoa</th>
    <th>SD09089</th>
    <td>11</td>
    <td>2,984,460</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal mũi vuông khóa trang trí
    </th>
    <th>SD03040</th>
    <td>33</td>
    <td>3,554,886</td>
  </tr>
  <tr>
    <th>SD07044</th>
    <td>107</td>
    <td>30,273,024</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông phối si vân</th>
    <th>SD07060</th>
    <td>45</td>
    <td>11,181,198</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông quai bất đối xứng</th>
    <th>SD07062</th>
    <td>49</td>
    <td>14,372,981</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông quai ngang</th>
    <th>SD07047</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày sandal phối chuyển màu</th>
    <th>SD05045</th>
    <td>2</td>
    <td>454,744</td>
  </tr>
  <tr>
    <th>Giày sandal phối dây kim tuyến</th>
    <th>SD07059</th>
    <td>14</td>
    <td>3,211,751</td>
  </tr>
  <tr>
    <th>Giày sandal phối dây vải sọc</th>
    <th>SD07051</th>
    <td>72</td>
    <td>12,112,064</td>
  </tr>
  <tr>
    <th>Giày sandal phối pvc gót trong</th>
    <th>SD07056</th>
    <td>109</td>
    <td>33,264,107</td>
  </tr>
  <tr>
    <th>Giày sandal phối si kim tuyến</th>
    <th>SD09087</th>
    <td>23</td>
    <td>5,427,529</td>
  </tr>
  <tr>
    <th>Giày sandal quai 3 dây xé dán</th>
    <th>SD05065</th>
    <td>94</td>
    <td>25,255,003</td>
  </tr>
  <tr>
    <th>Giày sandal quai mũi ngang giả xé dán</th>
    <th>SD07050</th>
    <td>12</td>
    <td>2,351,605</td>
  </tr>
  <tr>
    <th>Giày sandal quai mũi đan khoen trang trí</th>
    <th>SD05067</th>
    <td>66</td>
    <td>19,642,546</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang dây chéo</th>
    <th>SD07042</th>
    <td>2</td>
    <td>480,717</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang gót trong sơn ánh nhũ</th>
    <th>SD09096</th>
    <td>69</td>
    <td>19,960,380</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khoá trang trí</th>
    <th>SD07061</th>
    <td>256</td>
    <td>74,024,634</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khoá xé dán</th>
    <th>SD03041</th>
    <td>204</td>
    <td>59,635,528</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khóa trang trí</th>
    <th>SD05064</th>
    <td>213</td>
    <td>58,285,670</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang phối hai chất liệu</th>
    <th>SD07049</th>
    <td>2</td>
    <td>526,149</td>
  </tr>
  <tr>
    <th>Giày sandal quai thắt nút</th>
    <th>SD07058</th>
    <td>22</td>
    <td>4,122,084</td>
  </tr>
  <tr>
    <th>Giày sandal thể thao 2 quai</th>
    <th>SD03039</th>
    <td>82</td>
    <td>23,917,697</td>
  </tr>
  <tr>
    <th>Giày sandal thể thao dây chéo</th>
    <th>SD03042</th>
    <td>113</td>
    <td>30,472,447</td>
  </tr>
  <tr>
    <th>Giày sandal xuồng quai mũi ngang phối pvc</th>
    <th>SD07054</th>
    <td>15</td>
    <td>3,295,748</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao</th>
    <th>SD05058</th>
    <td>1</td>
    <td>256,386</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao cắt laser</th>
    <th>SD05043</th>
    <td>2</td>
    <td>500,381</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao gắn khóa trang trí</th>
    <th>SD05061</th>
    <td>74</td>
    <td>21,060,495</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao quai chéo</th>
    <th>SD05059</th>
    <td>51</td>
    <td>12,417,867</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao quai mảnh</th>
    <th>SD05060</th>
    <td>204</td>
    <td>59,673,066</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao đan khoen trang trí</th>
    <th>SD05068</th>
    <td>192</td>
    <td>54,079,438</td>
  </tr>
  <tr>
    <th>Giày sandal đế xuồng đính hạt trang trí</th>
    <th>SD09079</th>
    <td>9</td>
    <td>1,264,545</td>
  </tr>
  <tr>
    <th>Giày sandal đế xuồng, quai chéo đối xứng</th>
    <th>SD07057</th>
    <td>26</td>
    <td>7,668,074</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp</th>
    <th>SD09073</th>
    <td>56</td>
    <td>15,491,994</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp bất đối xứng</th>
    <th>SD09081</th>
    <td>6</td>
    <td>1,715,455</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp gót thanh</th>
    <th>SD09093</th>
    <td>61</td>
    <td>17,927,867</td>
  </tr>
  <tr>
    <th>Giày xăng đan gót vuông 5cm quai ngang SD05003</th>
    <th>SD05003</th>
    <td>250</td>
    <td>54,848,528</td>
  </tr>
  <tr>
    <th>Giày xăng đan quai ngang trang trí khoá kim loại SD03012</th>
    <th>SD03012</th>
    <td>62</td>
    <td>14,821,273</td>
  </tr>
  <tr>
    <th>Giày xăng đan quai ngang đế thể thao SD05028</th>
    <th>SD05028</th>
    <td>151</td>
    <td>45,208,870</td>
  </tr>
  <tr>
    <th>Giày xăng đan đế bệt quai mảnh phối nơ SD01087</th>
    <th>SD01087</th>
    <td>1</td>
    <td>239,356</td>
  </tr>
  <tr>
    <th>Giày xăng đan đế bệt quai ngang SD01081</th>
    <th>SD01081</th>
    <td>130</td>
    <td>36,070,000</td>
  </tr>
  <tr>
    <th>Jumping Snowflakes</th>
    <th>SD03043</th>
    <td>161</td>
    <td>44,879,152</td>
  </tr>
  <tr>
    <th>Lady-like-ice</th>
    <th>SD09095</th>
    <td>51</td>
    <td>14,289,488</td>
  </tr>
  <tr>
    <th>Xăng đan LẠC QUAN SD07027 - BST Bước Tới Vì Sao</th>
    <th>SD07027</th>
    <td>87</td>
    <td>25,428,961</td>
  </tr>
  <tr>
    <th rowspan="23" valign="top">
      Giày Thể Thao
    </th>
    <th>Classic Side by Side</th>
    <th>TT03030</th>
    <td>290</td>
    <td>90,673,977</td>
  </tr>
  <tr>
    <th>Comfy Easy Lazy</th>
    <th>TT03031</th>
    <td>275</td>
    <td>82,403,578</td>
  </tr>
  <tr>
    <th>Comfy Moon Walk</th>
    <th>TT03029</th>
    <td>339</td>
    <td>107,681,622</td>
  </tr>
  <tr>
    <th>Giày Thể Thao Classic</th>
    <th>TT03028</th>
    <td>45</td>
    <td>11,983,786</td>
  </tr>
  <tr>
    <th>Giày Thể Thao Classic Stick with me</th>
    <th>TT03032</th>
    <td>115</td>
    <td>35,991,617</td>
  </tr>
  <tr>
    <th>Giày thể thao classic TT05004</th>
    <th>TT05004</th>
    <td>30</td>
    <td>9,605,992</td>
  </tr>
  <tr>
    <th>Giày thể thao phong cách oxford</th>
    <th>TT03027</th>
    <td>23</td>
    <td>4,485,841</td>
  </tr>
  <tr>
    <th>Giày thể thao đế cao</th>
    <th>TT05003</th>
    <td>53</td>
    <td>16,412,750</td>
  </tr>
  <tr>
    <th>Giày thể thao đế chunky</th>
    <th>TT07001</th>
    <td>46</td>
    <td>19,877,420</td>
  </tr>
  <tr>
    <th>Juno Active Starter 1</th>
    <th>TT03021</th>
    <td>165</td>
    <td>37,457,286</td>
  </tr>
  <tr>
    <th>Juno Active Starter 2</th>
    <th>TT03022</th>
    <td>1</td>
    <td>302,727</td>
  </tr>
  <tr>
    <th>Juno Sneakers Soft Model TT03020 - Knit Basic</th>
    <th>TT03020</th>
    <td>107</td>
    <td>28,850,159</td>
  </tr>
  <tr>
    <th>Juno active starter 3</th>
    <th>TT03023</th>
    <td>58</td>
    <td>17,338,499</td>
  </tr>
  <tr>
    <th>Juno active starter 4</th>
    <th>TT03024</th>
    <td>59</td>
    <td>13,288,000</td>
  </tr>
  <tr>
    <th>Juno active starter 6</th>
    <th>TT05002</th>
    <td>25</td>
    <td>9,849,491</td>
  </tr>
  <tr>
    <th>Sneaker jelly</th>
    <th>TT03033</th>
    <td>369</td>
    <td>124,894,977</td>
  </tr>
  <tr>
    <th>Sneakers Ocean Wave</th>
    <th>TT05007</th>
    <td>157</td>
    <td>65,988,707</td>
  </tr>
  <tr>
    <th>Sneakers Rush Crush</th>
    <th>TT03034</th>
    <td>562</td>
    <td>174,805,880</td>
  </tr>
  <tr>
    <th>Trendy Bridging Chunky</th>
    <th>TT07003</th>
    <td>62</td>
    <td>19,157,110</td>
  </tr>
  <tr>
    <th>Trendy Elevated Flow</th>
    <th>TT05005</th>
    <td>94</td>
    <td>33,636,414</td>
  </tr>
  <tr>
    <th>Trendy Illuminated Chunky</th>
    <th>TT07002</th>
    <td>94</td>
    <td>42,290,968</td>
  </tr>
  <tr>
    <th>Trendy Levitated Chunky</th>
    <th>TT05006</th>
    <td>159</td>
    <td>63,055,969</td>
  </tr>
  <tr>
    <th>juno active starter 5</th>
    <th>TT03026</th>
    <td>138</td>
    <td>26,193,403</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày Đế Xuồng
    </th>
    <th rowspan="2" valign="top">
      Giày đế xuồng giả gỗ
    </th>
    <th>DX07019</th>
    <td>11</td>
    <td>1,715,500</td>
  </tr>
  <tr>
    <th>DX07020</th>
    <td>24</td>
    <td>3,786,568</td>
  </tr>
  <tr>
    <th>Giày đế xuồng quai mảnh</th>
    <th>DX07018</th>
    <td>20</td>
    <td>3,306,631</td>
  </tr>
  <tr>
    <th rowspan="174" valign="top">
      Túi xách
    </th>
    <th rowspan="13" valign="top">
      Balo
    </th>
    <th>Balo 2 ngăn có túi nhỏ trang trí phía trước</th>
    <th>BL109</th>
    <td>30</td>
    <td>10,876,945</td>
  </tr>
  <tr>
    <th>Balo buckle phối si vân</th>
    <th>BL111</th>
    <td>18</td>
    <td>6,580,401</td>
  </tr>
  <tr>
    <th>Balo chần bông 3 tông màu</th>
    <th>BL110</th>
    <td>4</td>
    <td>1,345,750</td>
  </tr>
  <tr>
    <th>Balo gắn charm pixel</th>
    <th>BL116</th>
    <td>130</td>
    <td>52,165,039</td>
  </tr>
  <tr>
    <th>Balo khoá scrunchies</th>
    <th>BL120</th>
    <td>142</td>
    <td>62,687,643</td>
  </tr>
  <tr>
    <th>Balo mini trang trí dây xích</th>
    <th>BL113</th>
    <td>2</td>
    <td>954,045</td>
  </tr>
  <tr>
    <th>Balo nhiều ngăn</th>
    <th>BL118</th>
    <td>13</td>
    <td>6,267,941</td>
  </tr>
  <tr>
    <th>Balo phom đứng hoạ tiết 3D BL063</th>
    <th>BL063</th>
    <td>372</td>
    <td>139,731,482</td>
  </tr>
  <tr>
    <th>Balo phong cách minimal</th>
    <th>BL114</th>
    <td>25</td>
    <td>8,508,155</td>
  </tr>
  <tr>
    <th>Balo size mini năng động</th>
    <th>BL104</th>
    <td>212</td>
    <td>72,938,962</td>
  </tr>
  <tr>
    <th>Balo tay cầm nhún</th>
    <th>BL119</th>
    <td>335</td>
    <td>132,804,739</td>
  </tr>
  <tr>
    <th>Balo top handle</th>
    <th>BL117</th>
    <td>222</td>
    <td>90,848,835</td>
  </tr>
  <tr>
    <th>Balo tròn khóa bấm</th>
    <th>BL112</th>
    <td>40</td>
    <td>14,835,482</td>
  </tr>
  <tr>
    <th rowspan="9" valign="top">
      Túi xách lớn
    </th>
    <th>Túi Tote laser cut</th>
    <th>TXL055</th>
    <td>32</td>
    <td>12,395,318</td>
  </tr>
  <tr>
    <th>Túi cỡ lớn hoạ tiết 3D TXL025</th>
    <th>TXL025</th>
    <td>120</td>
    <td>45,827,666</td>
  </tr>
  <tr>
    <th>Túi xách lớn 2 ngăn</th>
    <th>TXL057</th>
    <td>5</td>
    <td>2,285,334</td>
  </tr>
  <tr>
    <th>Túi xách lớn top handle</th>
    <th>TXL060</th>
    <td>113</td>
    <td>49,116,443</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote gắn charm pixel</th>
    <th>TXL059</th>
    <td>26</td>
    <td>11,585,731</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote móc trang trí</th>
    <th>TXL047</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote ngăn lớn</th>
    <th>TXL064</th>
    <td>5</td>
    <td>2,074,227</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote thân xếp</th>
    <th>TXL063</th>
    <td>4</td>
    <td>2,182,636</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote tối giản</th>
    <th>TXL058</th>
    <td>281</td>
    <td>127,262,646</td>
  </tr>
  <tr>
    <th rowspan="123" valign="top">
      Túi xách nhỏ
    </th>
    <th>Freezing Fluffy</th>
    <th>TXN474</th>
    <td>10</td>
    <td>2,680,038</td>
  </tr>
  <tr>
    <th>Handy Sweetest Clutch</th>
    <th>TXN476</th>
    <td>14</td>
    <td>2,975,673</td>
  </tr>
  <tr>
    <th>Summer Cool Dessert</th>
    <th>TXN475</th>
    <td>33</td>
    <td>12,919,134</td>
  </tr>
  <tr>
    <th>TXN và Clutch 2in1 trang trí khóa kim loại</th>
    <th>TXN482</th>
    <td>24</td>
    <td>7,942,534</td>
  </tr>
  <tr>
    <th>Túi Mickey cơ bản</th>
    <th>TXN381</th>
    <td>7</td>
    <td>2,837,191</td>
  </tr>
  <tr>
    <th>Túi Mini Duffle gắn charm pixel</th>
    <th>TXN478</th>
    <td>61</td>
    <td>21,879,076</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ 1 quai phối xích trong suốt</th>
    <th>TXN408</th>
    <td>40</td>
    <td>11,099,127</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ 3 tone màu</th>
    <th>TXN366</th>
    <td>1</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ Minnie 2 nắp có hoa văn</th>
    <th>TXN447</th>
    <td>44</td>
    <td>17,683,502</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ Minnie chần bông có gắn nút</th>
    <th>TXN446</th>
    <td>12</td>
    <td>4,278,528</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ baguette đeo vai</th>
    <th>TXN355</th>
    <td>2</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ buckle flap
    </th>
    <th>TXN480</th>
    <td>135</td>
    <td>52,825,128</td>
  </tr>
  <tr>
    <th>TXN502</th>
    <td>10</td>
    <td>5,147,727</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ buckled trapeze</th>
    <th>TXN397</th>
    <td>39</td>
    <td>12,084,089</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ bán nguyệt dây đeo phối xích</th>
    <th>TXN421</th>
    <td>1</td>
    <td>369,083</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ camera cầm tay đính charm</th>
    <th>TXN400</th>
    <td>38</td>
    <td>10,936,906</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ chân bông 3 tông màu</th>
    <th>TXN430</th>
    <td>7</td>
    <td>2,697,081</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ chần bông dạng hộp 3 tông màu</th>
    <th>TXN429</th>
    <td>5</td>
    <td>1,455,771</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ chần bông nắp gập</th>
    <th>TXN296</th>
    <td>82</td>
    <td>27,603,105</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ clutch chần bông</th>
    <th>TXN379</th>
    <td>6</td>
    <td>710,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ cut-out khoá trang trí</th>
    <th>TXN427</th>
    <td>46</td>
    <td>16,678,449</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ có bản to cầm tay trên nắp</th>
    <th>TXN444</th>
    <td>3</td>
    <td>1,310,606</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ cạnh hông tam giác</th>
    <th>TXN497</th>
    <td>11</td>
    <td>6,541,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ cạnh hông xếp nhiều ngăn</th>
    <th>TXN423</th>
    <td>20</td>
    <td>7,656,505</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng accordion</th>
    <th>TXN419</th>
    <td>3</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng hộp dây xích cầm tay</th>
    <th>TXN405</th>
    <td>124</td>
    <td>46,960,868</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng hộp top-handle</th>
    <th>TXN470</th>
    <td>162</td>
    <td>64,785,443</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dây đan đeo vai khóa xoay</th>
    <th>TXN395</th>
    <td>57</td>
    <td>20,971,130</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dây đeo thắt nút</th>
    <th>TXN434</th>
    <td>1</td>
    <td>392,956</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dây đeo đầu chữ T</th>
    <th>TXN424</th>
    <td>11</td>
    <td>3,356,261</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp</th>
    <th>TXN467</th>
    <td>236</td>
    <td>98,200,528</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp chần bông trang trí xích</th>
    <th>TXN452</th>
    <td>10</td>
    <td>4,136,167</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp mini đeo chéo</th>
    <th>TXN390</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ dập hiệu ứng princess diamond
    </th>
    <th>TXN416</th>
    <td>61</td>
    <td>23,699,824</td>
  </tr>
  <tr>
    <th>TXN417</th>
    <td>410</td>
    <td>171,626,402</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dập nổi họa tiết đối xứng</th>
    <th>TXN443</th>
    <td>76</td>
    <td>31,419,795</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn charn pixel</th>
    <th>TXN477</th>
    <td>165</td>
    <td>65,767,750</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn dây xích trang trí</th>
    <th>TXN431</th>
    <td>1</td>
    <td>275,864</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn khóa trang trí</th>
    <th>TXN432</th>
    <td>52</td>
    <td>20,670,652</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hai ngăn treo charm trang trí</th>
    <th>TXN505</th>
    <td>7</td>
    <td>3,606,409</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hobo trang trí khóa kim loại</th>
    <th>TXN457</th>
    <td>25</td>
    <td>8,797,718</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hình hộp xích trang trí</th>
    <th>TXN498</th>
    <td>14</td>
    <td>7,254,864</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp top-handle</th>
    <th>TXN466</th>
    <td>49</td>
    <td>19,598,395</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp trang trí hiệu ứng dập nổi</th>
    <th>TXN463</th>
    <td>122</td>
    <td>48,663,989</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp trang trí khóa kim loại</th>
    <th>TXN455</th>
    <td>14</td>
    <td>4,661,707</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp trang trí khóa xoay</th>
    <th>TXN469</th>
    <td>7</td>
    <td>1,942,315</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá logo trang trí</th>
    <th>TXN435</th>
    <td>39</td>
    <td>15,623,672</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá marble</th>
    <th>TXN493</th>
    <td>73</td>
    <td>24,980,345</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá scrunchies</th>
    <th>TXN495</th>
    <td>121</td>
    <td>48,049,970</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá trang trí tròn</th>
    <th>TXN422</th>
    <td>1</td>
    <td>402,334</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá xoắn trang trí</th>
    <th>TXN433</th>
    <td>41</td>
    <td>14,379,532</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khóa trang trí in họa tiết graffiti</th>
    <th>TXN436</th>
    <td>16</td>
    <td>3,443,197</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khóa trượt</th>
    <th>TXN415</th>
    <td>21</td>
    <td>7,295,118</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ laser cut nhỏ</th>
    <th>TXN426</th>
    <td>22</td>
    <td>7,364,240</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ mini circle charm</th>
    <th>TXN496</th>
    <td>290</td>
    <td>109,241,527</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ mini tay cầm scrunchies</th>
    <th>TXN494</th>
    <td>56</td>
    <td>20,058,956</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ minnie hình hộp</th>
    <th>TXN448</th>
    <td>18</td>
    <td>5,509,972</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ multi-bag</th>
    <th>TXN506</th>
    <td>12</td>
    <td>6,685,227</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ multi-bag nắp tuck-in</th>
    <th>TXN460</th>
    <td>63</td>
    <td>21,779,734</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nhỏ nắp tuck-in</th>
    <th>TXN461</th>
    <td>46</td>
    <td>16,132,860</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp bất đối xứng</th>
    <th>TXN510</th>
    <td>14</td>
    <td>6,174,864</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp cách điệu trang trí khóa kim loại</th>
    <th>TXN481</th>
    <td>93</td>
    <td>36,355,172</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp dập nổi</th>
    <th>TXN425</th>
    <td>52</td>
    <td>18,097,994</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gấp nếp</th>
    <th>TXN440</th>
    <td>1</td>
    <td>445,003</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập</th>
    <th>TXN315</th>
    <td>1</td>
    <td>369,083</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập 2 tone màu</th>
    <th>TXN369</th>
    <td>11</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập dập nổi 3d</th>
    <th>TXN313</th>
    <td>198</td>
    <td>77,486,926</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập phối màu</th>
    <th>TXN508</th>
    <td>7</td>
    <td>3,876,409</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập treo charm trang trí</th>
    <th>TXN503</th>
    <td>8</td>
    <td>4,612,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp lượn</th>
    <th>TXN501</th>
    <td>7</td>
    <td>2,457,773</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp lượn khoá xoay</th>
    <th>TXN500</th>
    <td>4</td>
    <td>2,186,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp pixel-cut</th>
    <th>TXN479</th>
    <td>79</td>
    <td>29,359,141</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp xéo đường chỉ nổi</th>
    <th>TXN428</th>
    <td>30</td>
    <td>11,345,542</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp đắp lớp</th>
    <th>TXN439</th>
    <td>4</td>
    <td>845,261</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phom dài tay cầm kim loại TXN250</th>
    <th>TXN250</th>
    <td>7</td>
    <td>679,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phun hiệu ứng loang màu</th>
    <th>TXN459</th>
    <td>10</td>
    <td>3,604,192</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối 2 chất liệu khóa trang trí đính đá Princess</th>
    <th>TXN391</th>
    <td>1</td>
    <td>595,455</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối 2 màu</th>
    <th>TXN294</th>
    <td>22</td>
    <td>6,421,577</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối color block</th>
    <th>TXN386</th>
    <td>98</td>
    <td>24,972,732</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối hiệu ứng giả đan lát</th>
    <th>TXN472</th>
    <td>266</td>
    <td>105,394,795</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ phối khoá cùng màu
    </th>
    <th>TXN387</th>
    <td>73</td>
    <td>22,575,807</td>
  </tr>
  <tr>
    <th>TXN388</th>
    <td>3</td>
    <td>363,636</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối màu color-block</th>
    <th>TXN458</th>
    <td>19</td>
    <td>7,890,200</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối màu cách điệu</th>
    <th>TXN454</th>
    <td>49</td>
    <td>16,068,663</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm cách điệu</th>
    <th>TXN384</th>
    <td>104</td>
    <td>40,208,980</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm kim loại chữ d</th>
    <th>TXN374</th>
    <td>10</td>
    <td>1,200,887</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm nhún</th>
    <th>TXN492</th>
    <td>411</td>
    <td>165,471,869</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm rời</th>
    <th>TXN486</th>
    <td>199</td>
    <td>80,243,668</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm trong suốt</th>
    <th>TXN410</th>
    <td>4</td>
    <td>1,145,535</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ the maze</th>
    <th>TXN483</th>
    <td>32</td>
    <td>12,866,648</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ thân chữ a</th>
    <th>TXN453</th>
    <td>11</td>
    <td>3,200,267</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ thân xếp khối</th>
    <th>TXN442</th>
    <td>111</td>
    <td>44,698,696</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle</th>
    <th>TXN403</th>
    <td>143</td>
    <td>60,088,416</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle cách điệu</th>
    <th>TXN456</th>
    <td>2</td>
    <td>738,167</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle dáng hộp</th>
    <th>TXN491</th>
    <td>210</td>
    <td>81,944,648</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle khóa kim loại raw</th>
    <th>TXN487</th>
    <td>149</td>
    <td>60,860,537</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top-handle</th>
    <th>TXN398</th>
    <td>1</td>
    <td>350,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tope handle dập nổi viền</th>
    <th>TXN465</th>
    <td>63</td>
    <td>24,390,508</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tote nhỏ nắp cách điệu</th>
    <th>TXN451</th>
    <td>16</td>
    <td>6,115,452</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tote nhỏ treo charm trang trí</th>
    <th>TXN504</th>
    <td>2</td>
    <td>1,156,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí hiệu ứng dập nổi</th>
    <th>TXN464</th>
    <td>75</td>
    <td>30,120,113</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí khóa the maze</th>
    <th>TXN484</th>
    <td>106</td>
    <td>42,623,182</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí khóa xoay</th>
    <th>TXN468</th>
    <td>320</td>
    <td>131,078,749</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí logo cách điệu</th>
    <th>TXN471</th>
    <td>202</td>
    <td>80,353,781</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí viền</th>
    <th>TXN406</th>
    <td>11</td>
    <td>1,284,879</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ túi đeo chéo gắn charm trang trí</th>
    <th>TXN401</th>
    <td>69</td>
    <td>22,484,273</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ viền kim tuyến trên nắp</th>
    <th>TXN438</th>
    <td>3</td>
    <td>1,351,582</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ và clutch 2in1</th>
    <th>TXN485</th>
    <td>43</td>
    <td>14,728,331</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đan cạnh viền</th>
    <th>TXN490</th>
    <td>76</td>
    <td>29,537,615</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chiếu hiệu ứng đan lát</th>
    <th>TXN394</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo khóa bấm</th>
    <th>TXN414</th>
    <td>204</td>
    <td>78,840,561</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo khóa gài</th>
    <th>TXN413</th>
    <td>18</td>
    <td>1,365,116</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo nắp gập</th>
    <th>TXN420</th>
    <td>154</td>
    <td>53,634,199</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo nắp viền dây kim loại</th>
    <th>TXN488</th>
    <td>68</td>
    <td>27,600,264</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ đeo chéo tối giản
    </th>
    <th>TXN404</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>TXN450</th>
    <td>288</td>
    <td>117,086,371</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai</th>
    <th>TXN383</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai có 2 thiết kế dây đeo</th>
    <th>TXN441</th>
    <td>66</td>
    <td>27,169,895</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai hiệu ứng đẹp mắt</th>
    <th>TXN393</th>
    <td>41</td>
    <td>16,900,685</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai khoá gài thể thao</th>
    <th>TXN402</th>
    <td>1</td>
    <td>447,628</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai khóa mây</th>
    <th>TXN489</th>
    <td>165</td>
    <td>67,484,987</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai phun hiệu ứng loang màu</th>
    <th>TXN473</th>
    <td>16</td>
    <td>6,176,998</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đồng màu</th>
    <th>TXN317</th>
    <td>1</td>
    <td>361,133</td>
  </tr>
  <tr>
    <th rowspan="29" valign="top">
      Túi xách trung
    </th>
    <th>Túi dập hiệu ứng Princess Diamond</th>
    <th>TXT226</th>
    <td>231</td>
    <td>98,467,047</td>
  </tr>
  <tr>
    <th>Túi xách trung buckle flap</th>
    <th>TXT243</th>
    <td>56</td>
    <td>22,177,276</td>
  </tr>
  <tr>
    <th>Túi xách trung buckled tote</th>
    <th>TXT242</th>
    <td>194</td>
    <td>85,618,828</td>
  </tr>
  <tr>
    <th>Túi xách trung bukcle khoá trượt</th>
    <th>TXT249</th>
    <td>5</td>
    <td>3,259,500</td>
  </tr>
  <tr>
    <th>Túi xách trung double handle</th>
    <th>TXT221</th>
    <td>25</td>
    <td>5,793,783</td>
  </tr>
  <tr>
    <th>Túi xách trung dập hiệu ứng lập thể</th>
    <th>TXT238</th>
    <td>13</td>
    <td>5,867,154</td>
  </tr>
  <tr>
    <th>Túi xách trung dập sọc nổi</th>
    <th>TXT230</th>
    <td>118</td>
    <td>48,163,108</td>
  </tr>
  <tr>
    <th>Túi xách trung gắn khóa trang trí</th>
    <th>TXT231</th>
    <td>24</td>
    <td>9,997,550</td>
  </tr>
  <tr>
    <th>Túi xách trung hộp trang trí nút kim loại</th>
    <th>TXT233</th>
    <td>62</td>
    <td>24,859,800</td>
  </tr>
  <tr>
    <th>Túi xách trung khoá xoắn trang trí</th>
    <th>TXT232</th>
    <td>25</td>
    <td>9,146,118</td>
  </tr>
  <tr>
    <th>Túi xách trung multi-bag</th>
    <th>TXT237</th>
    <td>3</td>
    <td>1,440,090</td>
  </tr>
  <tr>
    <th>Túi xách trung nắp dập nổi</th>
    <th>TXT228</th>
    <td>17</td>
    <td>5,044,242</td>
  </tr>
  <tr>
    <th>Túi xách trung phun hiệu ứng loang màu</th>
    <th>TXT240</th>
    <td>19</td>
    <td>7,804,672</td>
  </tr>
  <tr>
    <th>Túi xách trung satchel dáng hộp</th>
    <th>TXT241</th>
    <td>77</td>
    <td>34,735,273</td>
  </tr>
  <tr>
    <th>Túi xách trung the maze</th>
    <th>TXT244</th>
    <td>82</td>
    <td>34,702,541</td>
  </tr>
  <tr>
    <th>Túi xách trung thân xếp khối</th>
    <th>TXT234</th>
    <td>60</td>
    <td>24,847,449</td>
  </tr>
  <tr>
    <th>Túi xách trung top handle</th>
    <th>TXT223</th>
    <td>99</td>
    <td>42,407,461</td>
  </tr>
  <tr>
    <th>Túi xách trung top handle nắp viền dây kim loại</th>
    <th>TXT246</th>
    <td>118</td>
    <td>53,942,462</td>
  </tr>
  <tr>
    <th>Túi xách trung tote charm trang trí phối 2 màu</th>
    <th>TXT220</th>
    <td>197</td>
    <td>80,429,000</td>
  </tr>
  <tr>
    <th>Túi xách trung tote double bag</th>
    <th>TXT222</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách trung tote trang trí belt</th>
    <th>TXT207</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách trung tote trung nắp cách điệu</th>
    <th>TXT235</th>
    <td>8</td>
    <td>3,282,688</td>
  </tr>
  <tr>
    <th>Túi xách trung tote trung phối khác chất liệu</th>
    <th>TXT236</th>
    <td>84</td>
    <td>35,139,463</td>
  </tr>
  <tr>
    <th>Túi xách trung trang trí khóa kim loại raw</th>
    <th>TXT245</th>
    <td>109</td>
    <td>41,826,950</td>
  </tr>
  <tr>
    <th>Túi xách trung treo charm trang trí</th>
    <th>TXT239</th>
    <td>117</td>
    <td>48,064,488</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách trung tối giản
    </th>
    <th>TXT201</th>
    <td>229</td>
    <td>98,479,029</td>
  </tr>
  <tr>
    <th>TXT204</th>
    <td>75</td>
    <td>22,883,234</td>
  </tr>
  <tr>
    <th>Túi xách trung đa ngăn</th>
    <th>TXT251</th>
    <td>16</td>
    <td>8,340,273</td>
  </tr>
  <tr>
    <th>Túi đeo vai gắn charm túi mini</th>
    <th>TXT247</th>
    <td>143</td>
    <td>60,127,507</td>
  </tr>
  <tr>
    <th rowspan="23" valign="top">
      Ví
    </th>
    <th rowspan="23" valign="top">
      Ví
    </th>
    <th>Clutch dập hiệu ứng Princess Diamond</th>
    <th>VI141</th>
    <td>590</td>
    <td>179,978,053</td>
  </tr>
  <tr>
    <th>Ví 2 thân logo cách điệu</th>
    <th>VI156</th>
    <td>353</td>
    <td>108,058,366</td>
  </tr>
  <tr>
    <th>Ví Mickey 2 nắp</th>
    <th>VI134</th>
    <td>1</td>
    <td>279,070</td>
  </tr>
  <tr>
    <th>Ví chữ nhật kèm dây đeo</th>
    <th>VI147</th>
    <td>61</td>
    <td>14,546,402</td>
  </tr>
  <tr>
    <th>Ví cầm tay có ngăn điện thoại</th>
    <th>VI137</th>
    <td>13</td>
    <td>907,273</td>
  </tr>
  <tr>
    <th>Ví cầm tay</th>
    <th>VI127</th>
    <td>5</td>
    <td>843,121</td>
  </tr>
  <tr>
    <th>Ví cầm tay hoạ tiết vân chìm VI052</th>
    <th>VI052</th>
    <td>43</td>
    <td>11,552,406</td>
  </tr>
  <tr>
    <th>Ví cầm tay màu trendy</th>
    <th>VI143</th>
    <td>1</td>
    <td>279,070</td>
  </tr>
  <tr>
    <th>Ví dài nhiều ngăn</th>
    <th>VI158</th>
    <td>79</td>
    <td>17,269,171</td>
  </tr>
  <tr>
    <th>Ví dài nắp pixel-cut</th>
    <th>VI157</th>
    <td>37</td>
    <td>10,668,788</td>
  </tr>
  <tr>
    <th>Ví dây kéo kim loại nhiều ngăn</th>
    <th>VI149</th>
    <td>146</td>
    <td>39,375,680</td>
  </tr>
  <tr>
    <th>Ví dập nổi họa tiết đối xứng</th>
    <th>VI151</th>
    <td>1</td>
    <td>302,727</td>
  </tr>
  <tr>
    <th>Ví khoá trang trí cùng màu</th>
    <th>VI136</th>
    <td>529</td>
    <td>163,412,615</td>
  </tr>
  <tr>
    <th>Ví kèm dây đeo hiệu ứng đan</th>
    <th>VI162</th>
    <td>526</td>
    <td>155,641,101</td>
  </tr>
  <tr>
    <th>Ví nắp dập nổi</th>
    <th>VI145</th>
    <td>32</td>
    <td>8,039,280</td>
  </tr>
  <tr>
    <th>Ví nắp gập khóa bấm</th>
    <th>VI153</th>
    <td>42</td>
    <td>13,231,905</td>
  </tr>
  <tr>
    <th>Ví phối hiệu ứng</th>
    <th>VI138</th>
    <td>93</td>
    <td>24,791,885</td>
  </tr>
  <tr>
    <th>Ví phối màu color-block</th>
    <th>VI155</th>
    <td>84</td>
    <td>24,552,136</td>
  </tr>
  <tr>
    <th>Ví the maze</th>
    <th>VI159</th>
    <td>130</td>
    <td>38,619,772</td>
  </tr>
  <tr>
    <th>Ví trang trí khóa kim loại</th>
    <th>VI154</th>
    <td>124</td>
    <td>35,824,638</td>
  </tr>
  <tr>
    <th>Ví trang trí khóa kim loại raw</th>
    <th>VI161</th>
    <td>268</td>
    <td>82,708,162</td>
  </tr>
  <tr>
    <th>Ví trang trí khóa the maze</th>
    <th>VI160</th>
    <td>207</td>
    <td>61,774,245</td>
  </tr>
  <tr>
    <th>Ví trang trí nút kim loại</th>
    <th>VI150</th>
    <td>1</td>
    <td>270,000</td>
  </tr>
  <tr>
    <th rowspan="64" valign="top">
      Áo
    </th>
    <th rowspan="25" valign="top">
      Áo Kiểu
    </th>
    <th>Áo Kiểu AKI004</th>
    <th>AKI004</th>
    <td>16</td>
    <td>2,532,727</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI006</th>
    <th>AKI006</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI010</th>
    <th>AKI010</th>
    <td>5</td>
    <td>586,364</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI013</th>
    <th>AKI013</th>
    <td>5</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI015</th>
    <th>AKI015</th>
    <td>8</td>
    <td>1,447,273</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI016</th>
    <th>AKI016</th>
    <td>10</td>
    <td>1,628,182</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI017</th>
    <th>AKI017</th>
    <td>5</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI018</th>
    <th>AKI018</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI019</th>
    <th>AKI019</th>
    <td>7</td>
    <td>1,508,318</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI020</th>
    <th>AKI020</th>
    <td>7</td>
    <td>1,375,591</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI021</th>
    <th>AKI021</th>
    <td>3</td>
    <td>351,818</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI022</th>
    <th>AKI022</th>
    <td>6</td>
    <td>1,085,455</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI023</th>
    <th>AKI023</th>
    <td>8</td>
    <td>1,447,273</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI025</th>
    <th>AKI025</th>
    <td>6</td>
    <td>1,249,136</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI026</th>
    <th>AKI026</th>
    <td>7</td>
    <td>1,266,364</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI027</th>
    <th>AKI027</th>
    <td>4</td>
    <td>1,214,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAC12012036</th>
    <th>BAC12012036</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAC12101045</th>
    <th>BAC12101045</th>
    <td>2</td>
    <td>270,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAS12011013NM</th>
    <th>BAS12011013NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAS12101061</th>
    <th>BAS12101061</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMA12009050GC</th>
    <th>CMA12009050GC</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMC12101053</th>
    <th>CMC12101053</th>
    <td>6</td>
    <td>540,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMS12011015GC</th>
    <th>CMS12011015GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDC12007002NM</th>
    <th>TDC12007002NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDO12010018GC</th>
    <th>TDO12010018GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th rowspan="18" valign="top">
      Áo Sơ Mi
    </th>
    <th>Áo Kiểu TDS12009044NM</th>
    <th>TDS12009044NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi AKI009</th>
    <th>AKI009</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi AKI012</th>
    <th>AKI012</th>
    <td>4</td>
    <td>965,455</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM004</th>
    <th>ASM004</th>
    <td>4</td>
    <td>723,636</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM006</th>
    <th>ASM006</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM007</th>
    <th>ASM007</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM008</th>
    <th>ASM008</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM009</th>
    <th>ASM009</th>
    <td>2</td>
    <td>543,636</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ATH005</th>
    <th>ATH005</th>
    <td>5</td>
    <td>469,091</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAC12011019HH</th>
    <th>BAC12011019HH</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAS12010023</th>
    <th>BAS12010023</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAS12012006NM</th>
    <th>BAS12012006NM</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMC12012016</th>
    <th>CMC12012016</th>
    <td>5</td>
    <td>450,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12004020NM</th>
    <th>CMS12004020NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12005060NM</th>
    <th>CMS12005060NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12009036NM</th>
    <th>CMS12009036NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12012034</th>
    <th>CMS12012034</th>
    <td>17</td>
    <td>3,484,545</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12012042</th>
    <th>CMS12012042</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th rowspan="21" valign="top">
      Áo Thun
    </th>
    <th>Áo Thun AKI011</th>
    <th>AKI011</th>
    <td>7</td>
    <td>930,000</td>
  </tr>
  <tr>
    <th>Áo Thun ATH001</th>
    <th>ATH001</th>
    <td>6</td>
    <td>695,455</td>
  </tr>
  <tr>
    <th>Áo Thun ATH003</th>
    <th>ATH003</th>
    <td>2</td>
    <td>234,545</td>
  </tr>
  <tr>
    <th>Áo Thun ATH004</th>
    <th>ATH004</th>
    <td>2</td>
    <td>117,273</td>
  </tr>
  <tr>
    <th>Áo Thun ATH006</th>
    <th>ATH006</th>
    <td>24</td>
    <td>2,382,727</td>
  </tr>
  <tr>
    <th>Áo Thun ATH007</th>
    <th>ATH007</th>
    <td>2</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Thun ATH008</th>
    <th>ATH008</th>
    <td>3</td>
    <td>407,273</td>
  </tr>
  <tr>
    <th>Áo Thun BAA12012023</th>
    <th>BAA12012023</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101021</th>
    <th>BAC12101021</th>
    <td>5</td>
    <td>426,955</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101022</th>
    <th>BAC12101022</th>
    <td>4</td>
    <td>469,091</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101023</th>
    <th>BAC12101023</th>
    <td>8</td>
    <td>820,909</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101063</th>
    <th>BAC12101063</th>
    <td>15</td>
    <td>1,350,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101064</th>
    <th>BAC12101064</th>
    <td>5</td>
    <td>450,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAS12101048</th>
    <th>BAS12101048</th>
    <td>7</td>
    <td>820,909</td>
  </tr>
  <tr>
    <th>Áo Thun BAS12101049</th>
    <th>BAS12101049</th>
    <td>5</td>
    <td>586,364</td>
  </tr>
  <tr>
    <th>Áo Thun BPC12005054HH</th>
    <th>BPC12005054HH</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo thun "Bánh mỳ Sài gòn"</th>
    <th>ATH013</th>
    <td>26</td>
    <td>1,085,636</td>
  </tr>
  <tr>
    <th>Áo thun "Chích rồi ôm tí"</th>
    <th>ATH012</th>
    <td>19</td>
    <td>1,175,455</td>
  </tr>
  <tr>
    <th>Áo thun "Hành khỏe đẹp"</th>
    <th>ATH011</th>
    <td>23</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo thun "Hành trứng ấm êm"</th>
    <th>ATH014</th>
    <td>13</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo thun "Top giàu sang"</th>
    <th>ATH010</th>
    <td>10</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th rowspan="563" valign="top">
      4
    </th>
    <th rowspan="294" valign="top">
      Giày
    </th>
    <th rowspan="58" valign="top">
      Dép Guốc
    </th>
    <th>Dép lào thời trang Juno hoạ tiết cây kem DK011</th>
    <th>DK011</th>
    <td>6</td>
    <td>272,727</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu trắng DK005</th>
    <th>DK005</th>
    <td>13</td>
    <td>227,273</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu xanh DK003</th>
    <th>DK003</th>
    <td>11</td>
    <td>336,818</td>
  </tr>
  <tr>
    <th>Dép DE003</th>
    <th>DE003</th>
    <td>2</td>
    <td>196,364</td>
  </tr>
  <tr>
    <th>Dép Guốc DK026</th>
    <th>DK026</th>
    <td>1</td>
    <td>45,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK025</th>
    <th>DK025</th>
    <td>2</td>
    <td>84,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK030</th>
    <th>DK030</th>
    <td>1</td>
    <td>45,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK032</th>
    <th>DK032</th>
    <td>4</td>
    <td>90,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK033</th>
    <th>DK033</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK034</th>
    <th>DK034</th>
    <td>4</td>
    <td>251,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK035</th>
    <th>DK035</th>
    <td>3</td>
    <td>169,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK036</th>
    <th>DK036</th>
    <td>14</td>
    <td>820,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK037</th>
    <th>DK037</th>
    <td>5</td>
    <td>299,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK038</th>
    <th>DK038</th>
    <td>2</td>
    <td>124,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK039</th>
    <th>DK039</th>
    <td>2</td>
    <td>105,000</td>
  </tr>
  <tr>
    <th>Dép Tông DK040</th>
    <th>DK040</th>
    <td>3</td>
    <td>187,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK041</th>
    <th>DK041</th>
    <td>6</td>
    <td>331,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK043</th>
    <th>DK043</th>
    <td>7</td>
    <td>354,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK044</th>
    <th>DK044</th>
    <td>1</td>
    <td>54,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK045</th>
    <th>DK045</th>
    <td>3</td>
    <td>172,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK046</th>
    <th>DK046</th>
    <td>2</td>
    <td>117,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK047</th>
    <th>DK047</th>
    <td>2</td>
    <td>108,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK048</th>
    <th>DK048</th>
    <td>2</td>
    <td>120,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK049</th>
    <th>DK049</th>
    <td>2</td>
    <td>124,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK050</th>
    <th>DK050</th>
    <td>1</td>
    <td>60,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK051</th>
    <th>DK051</th>
    <td>1</td>
    <td>54,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK053</th>
    <th>DK053</th>
    <td>7</td>
    <td>413,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK054</th>
    <th>DK054</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK056</th>
    <th>DK056</th>
    <td>4</td>
    <td>235,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK057</th>
    <th>DK057</th>
    <td>1</td>
    <td>54,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK059</th>
    <th>DK059</th>
    <td>4</td>
    <td>238,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK060</th>
    <th>DK060</th>
    <td>5</td>
    <td>292,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK061</th>
    <th>DK061</th>
    <td>8</td>
    <td>380,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK062</th>
    <th>DK062</th>
    <td>5</td>
    <td>308,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK063</th>
    <th>DK063</th>
    <td>5</td>
    <td>292,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK064</th>
    <th>DK064</th>
    <td>4</td>
    <td>232,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK065</th>
    <th>DK065</th>
    <td>4</td>
    <td>254,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK067</th>
    <th>DK067</th>
    <td>9</td>
    <td>531,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK068</th>
    <th>DK068</th>
    <td>8</td>
    <td>465,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK069</th>
    <th>DK069</th>
    <td>8</td>
    <td>467,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK070</th>
    <th>DK070</th>
    <td>4</td>
    <td>190,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK071</th>
    <th>DK071</th>
    <td>4</td>
    <td>124,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK072</th>
    <th>DK072</th>
    <td>3</td>
    <td>178,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK073</th>
    <th>DK073</th>
    <td>12</td>
    <td>687,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK074</th>
    <th>DK074</th>
    <td>20</td>
    <td>1,075,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK075</th>
    <th>DK075</th>
    <td>40</td>
    <td>2,259,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK076</th>
    <th>DK076</th>
    <td>29</td>
    <td>1,601,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK077</th>
    <th>DK077</th>
    <td>35</td>
    <td>1,935,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK078</th>
    <th>DK078</th>
    <td>38</td>
    <td>2,251,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK079</th>
    <th>DK079</th>
    <td>25</td>
    <td>1,400,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK080</th>
    <th>DK080</th>
    <td>26</td>
    <td>1,533,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK081</th>
    <th>DK081</th>
    <td>27</td>
    <td>1,584,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK082</th>
    <th>DK082</th>
    <td>44</td>
    <td>2,612,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK083</th>
    <th>DK083</th>
    <td>35</td>
    <td>1,982,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK084</th>
    <th>DK084</th>
    <td>44</td>
    <td>2,568,364</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno hoạ tiết trái thơm DK009</th>
    <th>DK009</th>
    <td>4</td>
    <td>165,909</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu đỏ DK002</th>
    <th>DK002</th>
    <td>5</td>
    <td>177,273</td>
  </tr>
  <tr>
    <th>Sản phẩm quà tặng QT015</th>
    <th>DK015</th>
    <td>15</td>
    <td>140,909</td>
  </tr>
  <tr>
    <th rowspan="6" valign="top">
      Giày Boot
    </th>
    <th>Giày boot gót phối kim loại</th>
    <th>BO07017</th>
    <td>1</td>
    <td>322,227</td>
  </tr>
  <tr>
    <th>Giày boot gót vuông viền cổ chân</th>
    <th>BO09015</th>
    <td>23</td>
    <td>7,371,672</td>
  </tr>
  <tr>
    <th>Giày Boot Cổ Thấp</th>
    <th>BO05009</th>
    <td>2</td>
    <td>899,136</td>
  </tr>
  <tr>
    <th>Giày boot cổ cao gót vuông</th>
    <th>BO07018</th>
    <td>2</td>
    <td>853,833</td>
  </tr>
  <tr>
    <th>Giày boot dây kéo khoá tròn kim loại</th>
    <th>BO07016</th>
    <td>49</td>
    <td>11,886,200</td>
  </tr>
  <tr>
    <th>Giày boot phối 2 chất liệu</th>
    <th>BO07014</th>
    <td>3</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th rowspan="20" valign="top">
      Giày Búp Bê
    </th>
    <th>Giày búp bê dây mảnh đắp chéo phối vải lưới</th>
    <th>BB03068</th>
    <td>47</td>
    <td>12,030,857</td>
  </tr>
  <tr>
    <th>Giày búp bê gắn khóa trang trí</th>
    <th>BB03065</th>
    <td>115</td>
    <td>31,971,738</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày búp bê mũi nhọn gắn khóa trang trí
    </th>
    <th>BB03057</th>
    <td>2</td>
    <td>569,058</td>
  </tr>
  <tr>
    <th>BB03064</th>
    <td>154</td>
    <td>43,011,464</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn khoét eo BB03021</th>
    <th>BB03021</th>
    <td>85</td>
    <td>19,723,740</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày búp bê mũi nhọn khóa trang trí
    </th>
    <th>BB03046</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>BB03058</th>
    <td>105</td>
    <td>19,710,514</td>
  </tr>
  <tr>
    <th>BB03062</th>
    <td>194</td>
    <td>54,285,238</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn quai chéo</th>
    <th>BB03070</th>
    <td>103</td>
    <td>27,077,526</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn quai hậu đắp chéo</th>
    <th>BB01139</th>
    <td>56</td>
    <td>9,113,401</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn trang trí dây kéo</th>
    <th>BB03048</th>
    <td>3</td>
    <td>544,045</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn đính khóa trang trí</th>
    <th>BB03066</th>
    <td>172</td>
    <td>46,034,750</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi vuông</th>
    <th>BB03047</th>
    <td>3</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày mules dây xích trang trí</th>
    <th>BB03063</th>
    <td>2</td>
    <td>472,406</td>
  </tr>
  <tr>
    <th>Giày mules gót ngọc trai</th>
    <th>BB03060</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày mules gót thấp in họa tiết graffiti</th>
    <th>BB03067</th>
    <td>144</td>
    <td>34,293,629</td>
  </tr>
  <tr>
    <th>Giày mules mũi nhọn khóa trang trí</th>
    <th>BB03059</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày mules mũi vuông</th>
    <th>BB03056</th>
    <td>4</td>
    <td>1,012,853</td>
  </tr>
  <tr>
    <th>Giày mules quai khoá trang trí</th>
    <th>BB03069</th>
    <td>249</td>
    <td>69,498,626</td>
  </tr>
  <tr>
    <th>Waffle-Y-Cute</th>
    <th>BB03071</th>
    <td>135</td>
    <td>41,054,285</td>
  </tr>
  <tr>
    <th rowspan="79" valign="top">
      Giày Cao Gót
    </th>
    <th>Giày cao gót dây quai hậu</th>
    <th>CG09136</th>
    <td>57</td>
    <td>16,446,476</td>
  </tr>
  <tr>
    <th>Giày cao gót dây thắt nút</th>
    <th>CG07103</th>
    <td>131</td>
    <td>37,473,692</td>
  </tr>
  <tr>
    <th>Giày cao gót gót bọc trong suốt</th>
    <th>CG07098</th>
    <td>30</td>
    <td>7,026,707</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh mũi nhọn</th>
    <th>CG07092</th>
    <td>82</td>
    <td>23,612,139</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh phối si giả satin</th>
    <th>CG09138</th>
    <td>77</td>
    <td>22,267,492</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh quai chéo</th>
    <th>CG09119</th>
    <td>11</td>
    <td>1,340,843</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh quai cổ chân</th>
    <th>CG09120</th>
    <td>38</td>
    <td>5,530,059</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh trang trí đính đá</th>
    <th>CG09141</th>
    <td>62</td>
    <td>17,888,679</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh xi kim loại</th>
    <th>CG09123</th>
    <td>21</td>
    <td>5,859,940</td>
  </tr>
  <tr>
    <th>Giày cao gót gót trụ khóa trang trí</th>
    <th>CG05109</th>
    <td>136</td>
    <td>37,668,263</td>
  </tr>
  <tr>
    <th>Giày cao gót gót trụ trang trí đính đá</th>
    <th>CG05110</th>
    <td>101</td>
    <td>29,114,189</td>
  </tr>
  <tr>
    <th>Giày cao gót gắn khoá trang trí</th>
    <th>CG09135</th>
    <td>108</td>
    <td>31,532,295</td>
  </tr>
  <tr>
    <th>Giày cao gót hậu rút nhún</th>
    <th>CG05103</th>
    <td>155</td>
    <td>45,813,656</td>
  </tr>
  <tr>
    <th>Giày cao gót khóa gài</th>
    <th>CG09144</th>
    <td>146</td>
    <td>44,995,422</td>
  </tr>
  <tr>
    <th>Giày cao gót khóa trang trí họa tiết mê cung</th>
    <th>CG09143</th>
    <td>102</td>
    <td>28,970,885</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót chuyển màu</th>
    <th>CG07101</th>
    <td>35</td>
    <td>6,972,525</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót hình thang</th>
    <th>CG11007</th>
    <td>78</td>
    <td>22,818,288</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót khắc trụ</th>
    <th>CG05104</th>
    <td>136</td>
    <td>36,848,266</td>
  </tr>
  <tr>
    <th rowspan="5" valign="top">
      Giày cao gót mũi nhọn gót thanh
    </th>
    <th>CG05089</th>
    <td>3</td>
    <td>624,288</td>
  </tr>
  <tr>
    <th>CG05107</th>
    <td>179</td>
    <td>48,674,903</td>
  </tr>
  <tr>
    <th>CG09125</th>
    <td>33</td>
    <td>5,465,289</td>
  </tr>
  <tr>
    <th>CG09130</th>
    <td>47</td>
    <td>12,790,420</td>
  </tr>
  <tr>
    <th>CG09131</th>
    <td>39</td>
    <td>11,459,125</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót thanh ánh nhũ</th>
    <th>CG09126</th>
    <td>22</td>
    <td>4,526,118</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày cao gót mũi nhọn gót trụ
    </th>
    <th>CG05092</th>
    <td>7</td>
    <td>920,321</td>
  </tr>
  <tr>
    <th>CG05112</th>
    <td>177</td>
    <td>48,905,257</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót xi kim loại</th>
    <th>CG09129</th>
    <td>106</td>
    <td>32,342,187</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gắn dây xích mảnh</th>
    <th>CG09145</th>
    <td>108</td>
    <td>32,130,251</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn slingback</th>
    <th>CG09127</th>
    <td>34</td>
    <td>10,223,905</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn đế đúp</th>
    <th>CG11008</th>
    <td>28</td>
    <td>6,681,706</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi tròn nơ trang trí</th>
    <th>CG05111</th>
    <td>18</td>
    <td>5,728,864</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi vuông gắn khóa trang trí</th>
    <th>CG07102</th>
    <td>119</td>
    <td>33,517,861</td>
  </tr>
  <tr>
    <th>Giày cao gót phối hai tone màu</th>
    <th>CG05100</th>
    <td>57</td>
    <td>10,878,204</td>
  </tr>
  <tr>
    <th>Giày cao gót pump gót thanh</th>
    <th>CG09128</th>
    <td>29</td>
    <td>9,698,724</td>
  </tr>
  <tr>
    <th>Giày cao gót pump gắn khóa trang trí</th>
    <th>CG05105</th>
    <td>91</td>
    <td>24,505,145</td>
  </tr>
  <tr>
    <th>Giày cao gót pump khóa trang trí</th>
    <th>CG09124</th>
    <td>68</td>
    <td>13,522,081</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi nhọn</th>
    <th>CG09146</th>
    <td>6</td>
    <td>2,036,704</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi nhọn gót thanh</th>
    <th>CG09140</th>
    <td>28</td>
    <td>5,009,292</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi vuông</th>
    <th>CG05096</th>
    <td>94</td>
    <td>24,209,009</td>
  </tr>
  <tr>
    <th>Giày cao gót pump quai xích trang trí cổ chân</th>
    <th>CG09133</th>
    <td>113</td>
    <td>33,366,371</td>
  </tr>
  <tr>
    <th>Giày cao gót pump đính khóa trang trí</th>
    <th>CG09134</th>
    <td>48</td>
    <td>13,677,947</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày cao gót quai ngang khoá trang trí
    </th>
    <th>CG05108</th>
    <td>143</td>
    <td>39,880,771</td>
  </tr>
  <tr>
    <th>CG07104</th>
    <td>115</td>
    <td>34,341,324</td>
  </tr>
  <tr>
    <th>Giày cao gót quai ngang khóa trang trí</th>
    <th>CG05101</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày cao gót si kim tuyến</th>
    <th>CG07100</th>
    <td>37</td>
    <td>10,094,958</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót 2 màu</th>
    <th>CG07105</th>
    <td>79</td>
    <td>22,804,679</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót chuyển màu</th>
    <th>CG05094</th>
    <td>67</td>
    <td>19,304,776</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót phối kim loại</th>
    <th>CG07097</th>
    <td>6</td>
    <td>1,414,999</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót thanh</th>
    <th>CG09142</th>
    <td>55</td>
    <td>15,178,284</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót thanh đính khóa chữ j</th>
    <th>CG09121</th>
    <td>18</td>
    <td>4,337,668</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gắn khóa trang trí</th>
    <th>CG05106</th>
    <td>130</td>
    <td>35,780,414</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback in họa tiết vệt hoa</th>
    <th>CG09139</th>
    <td>64</td>
    <td>17,668,035</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback khoá trang trí</th>
    <th>CG05098</th>
    <td>4</td>
    <td>825,311</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày cao gót slingback mũi nhọn gót thanh
    </th>
    <th>CG07089</th>
    <td>2</td>
    <td>257,206</td>
  </tr>
  <tr>
    <th>CG07099</th>
    <td>24</td>
    <td>6,296,026</td>
  </tr>
  <tr>
    <th>CG09122</th>
    <td>24</td>
    <td>4,720,771</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi vuông gót trụ</th>
    <th>CG07096</th>
    <td>100</td>
    <td>16,606,742</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày Cao Gót Mũi Nhọn
    </th>
    <th>CG07086</th>
    <td>314</td>
    <td>85,693,395</td>
  </tr>
  <tr>
    <th>CG09113</th>
    <td>1</td>
    <td>249,236</td>
  </tr>
  <tr>
    <th>Giày Pump gót sơn ánh nhũ</th>
    <th>CG09132</th>
    <td>37</td>
    <td>8,454,112</td>
  </tr>
  <tr>
    <th>Giày cao gót 5cm mũi nhọn phối màu CG05075</th>
    <th>CG05075</th>
    <td>130</td>
    <td>37,108,617</td>
  </tr>
  <tr>
    <th>Giày cao gót 5cm mũi tròn phối nơ CG05059</th>
    <th>CG05059</th>
    <td>183</td>
    <td>48,720,994</td>
  </tr>
  <tr>
    <th>Giày cao gót 9cm mũi nhọn da bóng CG09104</th>
    <th>CG09104</th>
    <td>157</td>
    <td>36,852,502</td>
  </tr>
  <tr>
    <th>Giày cao gót gót trụ quai chéo gắn khóa</th>
    <th>CG05114</th>
    <td>245</td>
    <td>69,737,045</td>
  </tr>
  <tr>
    <th>Giày cao gót họa tiết monogram</th>
    <th>CG11006</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày cao gót kim tuyến khoá trang trí</th>
    <th>CG09118</th>
    <td>2</td>
    <td>583,636</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót lượn</th>
    <th>CG07083</th>
    <td>118</td>
    <td>31,013,626</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót thanh</th>
    <th>CG07080</th>
    <td>156</td>
    <td>39,616,868</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót trụ</th>
    <th>CG05099</th>
    <td>6</td>
    <td>1,380,621</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn khóa trang trí</th>
    <th>CG05081</th>
    <td>2</td>
    <td>680,687</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn phối si tương phản</th>
    <th>CG09137</th>
    <td>65</td>
    <td>14,391,704</td>
  </tr>
  <tr>
    <th>Giày cao gót quai cổ chân</th>
    <th>CG09117</th>
    <td>2</td>
    <td>264,727</td>
  </tr>
  <tr>
    <th>Giày cao gót quai hậu xé dán</th>
    <th>CG07095</th>
    <td>12</td>
    <td>1,333,730</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback khóa trang trí lục giác j</th>
    <th>CG07093</th>
    <td>14</td>
    <td>2,561,680</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi nhọn</th>
    <th>CG07094</th>
    <td>13</td>
    <td>2,746,796</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi nhọn gót thanh</th>
    <th>CG09112</th>
    <td>48</td>
    <td>8,569,851</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback phối si dập ly</th>
    <th>CG05102</th>
    <td>3</td>
    <td>1,023,915</td>
  </tr>
  <tr>
    <th>Giày mules bít mũi khóa trang trí đan lát</th>
    <th>CG07106</th>
    <td>142</td>
    <td>41,376,721</td>
  </tr>
  <tr>
    <th>Giày mules mũi nhọn gót trụ</th>
    <th>CG05097</th>
    <td>380</td>
    <td>113,545,673</td>
  </tr>
  <tr>
    <th rowspan="106" valign="top">
      Giày Sandal
    </th>
    <th>Dép 3 dây quai ngang</th>
    <th>SD01100</th>
    <td>1</td>
    <td>291,497</td>
  </tr>
  <tr>
    <th>Dép bệt mũi vuông khóa trang trí</th>
    <th>SD01107</th>
    <td>174</td>
    <td>46,595,872</td>
  </tr>
  <tr>
    <th>Dép bệt quai trang trí họa tiết mê cung</th>
    <th>SD03044</th>
    <td>135</td>
    <td>36,459,279</td>
  </tr>
  <tr>
    <th>Dép bệt quai xoắn</th>
    <th>SD01104</th>
    <td>15</td>
    <td>3,030,670</td>
  </tr>
  <tr>
    <th>Dép bệt quai đắp chéo phối pvc</th>
    <th>SD01102</th>
    <td>53</td>
    <td>11,854,046</td>
  </tr>
  <tr>
    <th>Dép bệt xỏ ngón trang trí khóa pu</th>
    <th>SD01103</th>
    <td>43</td>
    <td>11,784,981</td>
  </tr>
  <tr>
    <th>Dép bệt đan khoen trang trí</th>
    <th>SD01111</th>
    <td>138</td>
    <td>38,170,980</td>
  </tr>
  <tr>
    <th>Dép mũi vuông khóa trang trí</th>
    <th>SD01101</th>
    <td>49</td>
    <td>11,572,207</td>
  </tr>
  <tr>
    <th>Dép quai ngang gót sơn giả gỗ</th>
    <th>SD01096</th>
    <td>4</td>
    <td>1,035,845</td>
  </tr>
  <tr>
    <th>Dép quai ngang mũi vuông</th>
    <th>SD01095</th>
    <td>34</td>
    <td>5,569,442</td>
  </tr>
  <tr>
    <th>Dép quai ngang phối ánh kim</th>
    <th>SD01109</th>
    <td>162</td>
    <td>40,585,076</td>
  </tr>
  <tr>
    <th>Frozen Walk</th>
    <th>SD07066</th>
    <td>72</td>
    <td>21,991,913</td>
  </tr>
  <tr>
    <th>Giày mules mũi vuông gót phối kim loại</th>
    <th>SD07067</th>
    <td>149</td>
    <td>42,567,534</td>
  </tr>
  <tr>
    <th>Giày sandal bệt mũi vuông</th>
    <th>SD01105</th>
    <td>24</td>
    <td>4,746,278</td>
  </tr>
  <tr>
    <th>Giày sandal bệt quai chéo</th>
    <th>SD01110</th>
    <td>67</td>
    <td>17,897,824</td>
  </tr>
  <tr>
    <th>Giày sandal bệt quai ngang khóa trang trí</th>
    <th>SD01108</th>
    <td>115</td>
    <td>31,500,679</td>
  </tr>
  <tr>
    <th>Giày sandal bệt xỏ ngón</th>
    <th>SD01099</th>
    <td>2</td>
    <td>426,952</td>
  </tr>
  <tr>
    <th>Giày sandal bệt xỏ ngón quai chéo</th>
    <th>SD01106</th>
    <td>14</td>
    <td>2,077,273</td>
  </tr>
  <tr>
    <th>Giày sandal bệt đế giả gỗ</th>
    <th>SD01098</th>
    <td>37</td>
    <td>8,244,447</td>
  </tr>
  <tr>
    <th>Giày sandal dây bất đối xứng</th>
    <th>SD11010</th>
    <td>2</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày sandal dây hậu đan chéo</th>
    <th>SD07053</th>
    <td>15</td>
    <td>1,760,909</td>
  </tr>
  <tr>
    <th>Giày sandal dây mảnh</th>
    <th>SD07063</th>
    <td>110</td>
    <td>28,365,485</td>
  </tr>
  <tr>
    <th>Giày sandal dây mảnh gót thanh</th>
    <th>SD09092</th>
    <td>61</td>
    <td>16,737,576</td>
  </tr>
  <tr>
    <th>Giày sandal gót chữ a</th>
    <th>SD07064</th>
    <td>112</td>
    <td>31,285,117</td>
  </tr>
  <tr>
    <th>Giày sandal gót cách điệu 2 màu</th>
    <th>SD07065</th>
    <td>47</td>
    <td>11,577,728</td>
  </tr>
  <tr>
    <th>Giày sandal gót hình học</th>
    <th>SD09074</th>
    <td>102</td>
    <td>29,592,055</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal gót sơn chuyển màu
    </th>
    <th>SD09094</th>
    <td>84</td>
    <td>26,210,447</td>
  </tr>
  <tr>
    <th>SD11013</th>
    <td>14</td>
    <td>4,068,808</td>
  </tr>
  <tr>
    <th>Giày sandal gót sơn ombre</th>
    <th>SD07040</th>
    <td>28</td>
    <td>4,058,038</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh gắn xích trang trí</th>
    <th>SD09085</th>
    <td>85</td>
    <td>26,041,942</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh phối si dập ly</th>
    <th>SD11011</th>
    <td>6</td>
    <td>1,569,558</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh quai mảnh bất đối xứng</th>
    <th>SD09097</th>
    <td>65</td>
    <td>18,414,272</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh đế đúp</th>
    <th>SD09078</th>
    <td>16</td>
    <td>3,114,390</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong có màu</th>
    <th>SD07052</th>
    <td>7</td>
    <td>1,364,115</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong quai ngang</th>
    <th>SD09082</th>
    <td>1</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong sơn ombre</th>
    <th>SD09090</th>
    <td>79</td>
    <td>23,716,398</td>
  </tr>
  <tr>
    <th>Giày sandal gót trụ phối trung tì kim tuyến</th>
    <th>SD09088</th>
    <td>16</td>
    <td>5,440,823</td>
  </tr>
  <tr>
    <th>Giày sandal gót trụ điêu khắc</th>
    <th>SD07055</th>
    <td>7</td>
    <td>1,661,881</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông</th>
    <th>SD09070</th>
    <td>21</td>
    <td>4,954,052</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông mũi vuông</th>
    <th>SD05055</th>
    <td>12</td>
    <td>1,534,460</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông chuyển màu</th>
    <th>SD09071</th>
    <td>173</td>
    <td>48,811,491</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông khóa sơn cùng màu</th>
    <th>SD09075</th>
    <td>1</td>
    <td>395,455</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông quai bất đối xứng</th>
    <th>SD05066</th>
    <td>178</td>
    <td>51,666,256</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông quai mặt bất đối xứng</th>
    <th>SD05044</th>
    <td>24</td>
    <td>4,598,279</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal gót vuông quai ngang
    </th>
    <th>SD07046</th>
    <td>12</td>
    <td>3,177,943</td>
  </tr>
  <tr>
    <th>SD09084</th>
    <td>8</td>
    <td>2,050,684</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông sơn lăn giả gỗ</th>
    <th>SD05062</th>
    <td>7</td>
    <td>1,891,597</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông viền kim loại</th>
    <th>SD07048</th>
    <td>9</td>
    <td>1,836,968</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông viền kim loại, quai mảnh</th>
    <th>SD05063</th>
    <td>118</td>
    <td>32,878,225</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông đính hoa trang trí</th>
    <th>SD05052</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông đính hạt trang trí</th>
    <th>SD09080</th>
    <td>15</td>
    <td>2,499,836</td>
  </tr>
  <tr>
    <th>Giày sandal gót ánh kim</th>
    <th>SD11007</th>
    <td>11</td>
    <td>3,159,213</td>
  </tr>
  <tr>
    <th>Giày sandal gót đá 4 màu</th>
    <th>SD05057</th>
    <td>1</td>
    <td>348,876</td>
  </tr>
  <tr>
    <th>Giày sandal họa tiết hoa khô</th>
    <th>SD09077</th>
    <td>8</td>
    <td>1,417,013</td>
  </tr>
  <tr>
    <th>Giày sandal khóa gài</th>
    <th>SD07068</th>
    <td>29</td>
    <td>8,340,815</td>
  </tr>
  <tr>
    <th>Giày sandal kim tuyến gót thô</th>
    <th>SD11012</th>
    <td>87</td>
    <td>27,986,459</td>
  </tr>
  <tr>
    <th>Giày sandal mũi tròn quai mảnh bất đối xứng</th>
    <th>SD03045</th>
    <td>168</td>
    <td>47,042,859</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông dây mảnh</th>
    <th>SD07036</th>
    <td>1</td>
    <td>262,470</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal mũi vuông gót thanh
    </th>
    <th>SD09086</th>
    <td>38</td>
    <td>10,621,161</td>
  </tr>
  <tr>
    <th>SD09091</th>
    <td>17</td>
    <td>4,641,451</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal mũi vuông gót vuông
    </th>
    <th>SD07039</th>
    <td>13</td>
    <td>1,490,000</td>
  </tr>
  <tr>
    <th>SD09083</th>
    <td>87</td>
    <td>25,736,325</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông gót vuông ánh kim loại</th>
    <th>SD07045</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông in họa tiết vệt hoa</th>
    <th>SD09089</th>
    <td>19</td>
    <td>5,515,368</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal mũi vuông khóa trang trí
    </th>
    <th>SD03040</th>
    <td>22</td>
    <td>3,033,255</td>
  </tr>
  <tr>
    <th>SD07044</th>
    <td>175</td>
    <td>49,791,185</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông phối si vân</th>
    <th>SD07060</th>
    <td>86</td>
    <td>23,101,756</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông quai bất đối xứng</th>
    <th>SD07062</th>
    <td>81</td>
    <td>24,370,463</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông quai ngang</th>
    <th>SD07047</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Giày sandal phối chuyển màu</th>
    <th>SD05045</th>
    <td>1</td>
    <td>328,936</td>
  </tr>
  <tr>
    <th>Giày sandal phối dây kim tuyến</th>
    <th>SD07059</th>
    <td>17</td>
    <td>4,195,694</td>
  </tr>
  <tr>
    <th>Giày sandal phối dây vải sọc</th>
    <th>SD07051</th>
    <td>47</td>
    <td>7,372,252</td>
  </tr>
  <tr>
    <th>Giày sandal phối pvc gót trong</th>
    <th>SD07056</th>
    <td>87</td>
    <td>24,571,906</td>
  </tr>
  <tr>
    <th>Giày sandal phối si kim tuyến</th>
    <th>SD09087</th>
    <td>19</td>
    <td>3,466,323</td>
  </tr>
  <tr>
    <th>Giày sandal quai 3 dây xé dán</th>
    <th>SD05065</th>
    <td>83</td>
    <td>23,253,610</td>
  </tr>
  <tr>
    <th>Giày sandal quai mũi ngang giả xé dán</th>
    <th>SD07050</th>
    <td>29</td>
    <td>5,646,013</td>
  </tr>
  <tr>
    <th>Giày sandal quai mũi đan khoen trang trí</th>
    <th>SD05067</th>
    <td>69</td>
    <td>22,010,856</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang dây chéo</th>
    <th>SD07042</th>
    <td>1</td>
    <td>320,684</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang gót trong sơn ánh nhũ</th>
    <th>SD09096</th>
    <td>55</td>
    <td>15,946,484</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khoá trang trí</th>
    <th>SD07061</th>
    <td>126</td>
    <td>37,576,478</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khoá xé dán</th>
    <th>SD03041</th>
    <td>130</td>
    <td>39,245,217</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khóa trang trí</th>
    <th>SD05064</th>
    <td>255</td>
    <td>73,161,170</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang phối hai chất liệu</th>
    <th>SD07049</th>
    <td>7</td>
    <td>1,422,378</td>
  </tr>
  <tr>
    <th>Giày sandal quai thắt nút</th>
    <th>SD07058</th>
    <td>9</td>
    <td>2,564,797</td>
  </tr>
  <tr>
    <th>Giày sandal sandal mũi vuông gót vuông</th>
    <th>SD07043</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày sandal thể thao 2 quai</th>
    <th>SD03039</th>
    <td>61</td>
    <td>18,167,157</td>
  </tr>
  <tr>
    <th>Giày sandal thể thao dây chéo</th>
    <th>SD03042</th>
    <td>86</td>
    <td>24,051,119</td>
  </tr>
  <tr>
    <th>Giày sandal xuồng quai mũi ngang phối pvc</th>
    <th>SD07054</th>
    <td>18</td>
    <td>4,351,669</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao</th>
    <th>SD05058</th>
    <td>3</td>
    <td>714,881</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao gắn khóa trang trí</th>
    <th>SD05061</th>
    <td>41</td>
    <td>11,910,272</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao quai chéo</th>
    <th>SD05059</th>
    <td>43</td>
    <td>10,629,674</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao quai mảnh</th>
    <th>SD05060</th>
    <td>140</td>
    <td>38,726,836</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao đan khoen trang trí</th>
    <th>SD05068</th>
    <td>141</td>
    <td>40,832,810</td>
  </tr>
  <tr>
    <th>Giày sandal đế xuồng đính hạt trang trí</th>
    <th>SD09079</th>
    <td>10</td>
    <td>1,812,360</td>
  </tr>
  <tr>
    <th>Giày sandal đế xuồng, quai chéo đối xứng</th>
    <th>SD07057</th>
    <td>48</td>
    <td>13,687,059</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp</th>
    <th>SD09073</th>
    <td>23</td>
    <td>7,174,103</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp bất đối xứng</th>
    <th>SD09081</th>
    <td>9</td>
    <td>1,759,091</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp gót thanh</th>
    <th>SD09093</th>
    <td>56</td>
    <td>16,319,903</td>
  </tr>
  <tr>
    <th>Giày xăng đan gót vuông 5cm quai ngang SD05003</th>
    <th>SD05003</th>
    <td>237</td>
    <td>54,298,528</td>
  </tr>
  <tr>
    <th>Giày xăng đan quai ngang trang trí khoá kim loại SD03012</th>
    <th>SD03012</th>
    <td>51</td>
    <td>13,605,295</td>
  </tr>
  <tr>
    <th>Giày xăng đan quai ngang đế thể thao SD05028</th>
    <th>SD05028</th>
    <td>129</td>
    <td>36,408,754</td>
  </tr>
  <tr>
    <th>Giày xăng đan đế bệt quai mảnh SD01078</th>
    <th>SD01078</th>
    <td>2</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày xăng đan đế bệt quai ngang SD01081</th>
    <th>SD01081</th>
    <td>113</td>
    <td>29,920,935</td>
  </tr>
  <tr>
    <th>Jumping Snowflakes</th>
    <th>SD03043</th>
    <td>110</td>
    <td>30,571,237</td>
  </tr>
  <tr>
    <th>Lady-like-ice</th>
    <th>SD09095</th>
    <td>89</td>
    <td>29,948,260</td>
  </tr>
  <tr>
    <th>Xăng đan LẠC QUAN SD07027 - BST Bước Tới Vì Sao</th>
    <th>SD07027</th>
    <td>61</td>
    <td>18,820,747</td>
  </tr>
  <tr>
    <th rowspan="22" valign="top">
      Giày Thể Thao
    </th>
    <th>Classic Side by Side</th>
    <th>TT03030</th>
    <td>140</td>
    <td>46,927,471</td>
  </tr>
  <tr>
    <th>Comfy Easy Lazy</th>
    <th>TT03031</th>
    <td>144</td>
    <td>45,084,753</td>
  </tr>
  <tr>
    <th>Comfy Moon Walk</th>
    <th>TT03029</th>
    <td>157</td>
    <td>51,407,106</td>
  </tr>
  <tr>
    <th>Giày Thể Thao Classic</th>
    <th>TT03028</th>
    <td>13</td>
    <td>2,868,508</td>
  </tr>
  <tr>
    <th>Giày Thể Thao Classic Stick with me</th>
    <th>TT03032</th>
    <td>66</td>
    <td>20,547,885</td>
  </tr>
  <tr>
    <th>Giày thể thao classic TT05004</th>
    <th>TT05004</th>
    <td>40</td>
    <td>16,453,547</td>
  </tr>
  <tr>
    <th>Giày thể thao phong cách oxford</th>
    <th>TT03027</th>
    <td>8</td>
    <td>2,473,742</td>
  </tr>
  <tr>
    <th>Giày thể thao đế cao</th>
    <th>TT05003</th>
    <td>74</td>
    <td>25,739,146</td>
  </tr>
  <tr>
    <th>Giày thể thao đế chunky</th>
    <th>TT07001</th>
    <td>99</td>
    <td>45,536,430</td>
  </tr>
  <tr>
    <th>Juno Active Starter 1</th>
    <th>TT03021</th>
    <td>76</td>
    <td>16,769,564</td>
  </tr>
  <tr>
    <th>Juno Sneakers Soft Model TT03020 - Knit Basic</th>
    <th>TT03020</th>
    <td>57</td>
    <td>16,839,662</td>
  </tr>
  <tr>
    <th>Juno active starter 3</th>
    <th>TT03023</th>
    <td>34</td>
    <td>10,257,773</td>
  </tr>
  <tr>
    <th>Juno active starter 4</th>
    <th>TT03024</th>
    <td>21</td>
    <td>5,097,739</td>
  </tr>
  <tr>
    <th>Juno active starter 6</th>
    <th>TT05002</th>
    <td>40</td>
    <td>15,319,791</td>
  </tr>
  <tr>
    <th>Sneaker jelly</th>
    <th>TT03033</th>
    <td>203</td>
    <td>73,278,615</td>
  </tr>
  <tr>
    <th>Sneakers Ocean Wave</th>
    <th>TT05007</th>
    <td>230</td>
    <td>101,325,697</td>
  </tr>
  <tr>
    <th>Sneakers Rush Crush</th>
    <th>TT03034</th>
    <td>331</td>
    <td>107,338,734</td>
  </tr>
  <tr>
    <th>Trendy Bridging Chunky</th>
    <th>TT07003</th>
    <td>103</td>
    <td>36,735,302</td>
  </tr>
  <tr>
    <th>Trendy Elevated Flow</th>
    <th>TT05005</th>
    <td>125</td>
    <td>48,354,300</td>
  </tr>
  <tr>
    <th>Trendy Illuminated Chunky</th>
    <th>TT07002</th>
    <td>153</td>
    <td>67,808,239</td>
  </tr>
  <tr>
    <th>Trendy Levitated Chunky</th>
    <th>TT05006</th>
    <td>284</td>
    <td>125,280,326</td>
  </tr>
  <tr>
    <th>juno active starter 5</th>
    <th>TT03026</th>
    <td>79</td>
    <td>15,298,659</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày Đế Xuồng
    </th>
    <th rowspan="2" valign="top">
      Giày đế xuồng giả gỗ
    </th>
    <th>DX07019</th>
    <td>4</td>
    <td>967,972</td>
  </tr>
  <tr>
    <th>DX07020</th>
    <td>10</td>
    <td>1,532,727</td>
  </tr>
  <tr>
    <th>Giày đế xuồng quai mảnh</th>
    <th>DX07018</th>
    <td>7</td>
    <td>1,525,308</td>
  </tr>
  <tr>
    <th rowspan="174" valign="top">
      Túi xách
    </th>
    <th rowspan="16" valign="top">
      Balo
    </th>
    <th>Balo 2 ngăn có túi nhỏ trang trí phía trước</th>
    <th>BL109</th>
    <td>51</td>
    <td>21,613,686</td>
  </tr>
  <tr>
    <th>Balo Balo nút bấm nắp gập</th>
    <th>BL090</th>
    <td>3</td>
    <td>271,818</td>
  </tr>
  <tr>
    <th>Balo buckle phối si vân</th>
    <th>BL111</th>
    <td>30</td>
    <td>11,218,890</td>
  </tr>
  <tr>
    <th>Balo chần bông 3 tông màu</th>
    <th>BL110</th>
    <td>2</td>
    <td>441,006</td>
  </tr>
  <tr>
    <th>Balo gắn charm pixel</th>
    <th>BL116</th>
    <td>192</td>
    <td>82,465,443</td>
  </tr>
  <tr>
    <th>Balo khoá scrunchies</th>
    <th>BL120</th>
    <td>135</td>
    <td>63,780,814</td>
  </tr>
  <tr>
    <th>Balo mini trang trí dây xích</th>
    <th>BL113</th>
    <td>3</td>
    <td>1,240,492</td>
  </tr>
  <tr>
    <th>Balo nhiều ngăn</th>
    <th>BL118</th>
    <td>6</td>
    <td>3,303,052</td>
  </tr>
  <tr>
    <th>Balo phom đứng hoạ tiết 3D BL063</th>
    <th>BL063</th>
    <td>366</td>
    <td>150,440,991</td>
  </tr>
  <tr>
    <th>Balo phong cách minimal</th>
    <th>BL114</th>
    <td>43</td>
    <td>17,137,220</td>
  </tr>
  <tr>
    <th>Balo size mini năng động</th>
    <th>BL104</th>
    <td>195</td>
    <td>76,179,675</td>
  </tr>
  <tr>
    <th>Balo sơn viền 2 kiểu đeo</th>
    <th>BL108</th>
    <td>1</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Balo tay cầm nhún</th>
    <th>BL119</th>
    <td>326</td>
    <td>140,603,717</td>
  </tr>
  <tr>
    <th>Balo top handle</th>
    <th>BL117</th>
    <td>206</td>
    <td>88,543,472</td>
  </tr>
  <tr>
    <th>Balo tròn khóa bấm</th>
    <th>BL112</th>
    <td>27</td>
    <td>12,212,546</td>
  </tr>
  <tr>
    <th>Frost Bites Crumbly</th>
    <th>BL115</th>
    <td>1</td>
    <td>368,591</td>
  </tr>
  <tr>
    <th rowspan="9" valign="top">
      Túi xách lớn
    </th>
    <th>Túi Tote laser cut</th>
    <th>TXL055</th>
    <td>26</td>
    <td>9,870,935</td>
  </tr>
  <tr>
    <th>Túi cỡ lớn hoạ tiết 3D TXL025</th>
    <th>TXL025</th>
    <td>189</td>
    <td>80,079,650</td>
  </tr>
  <tr>
    <th>Túi xách lớn 2 ngăn</th>
    <th>TXL057</th>
    <td>4</td>
    <td>1,779,549</td>
  </tr>
  <tr>
    <th>Túi xách lớn top handle</th>
    <th>TXL060</th>
    <td>122</td>
    <td>56,561,729</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote gắn charm pixel</th>
    <th>TXL059</th>
    <td>29</td>
    <td>12,894,442</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote ngăn lớn</th>
    <th>TXL064</th>
    <td>7</td>
    <td>3,216,136</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote thân xếp</th>
    <th>TXL063</th>
    <td>2</td>
    <td>1,337,045</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote tối giản</th>
    <th>TXL058</th>
    <td>283</td>
    <td>128,121,528</td>
  </tr>
  <tr>
    <th>Túi xách lớn trapeze phối hai chất liệu</th>
    <th>TXL062</th>
    <td>2</td>
    <td>1,627,273</td>
  </tr>
  <tr>
    <th rowspan="120" valign="top">
      Túi xách nhỏ
    </th>
    <th>Freezing Fluffy</th>
    <th>TXN474</th>
    <td>12</td>
    <td>4,570,969</td>
  </tr>
  <tr>
    <th>Handy Sweetest Clutch</th>
    <th>TXN476</th>
    <td>7</td>
    <td>2,440,009</td>
  </tr>
  <tr>
    <th>Summer Cool Dessert</th>
    <th>TXN475</th>
    <td>33</td>
    <td>15,287,328</td>
  </tr>
  <tr>
    <th>TXN và Clutch 2in1 trang trí khóa kim loại</th>
    <th>TXN482</th>
    <td>73</td>
    <td>29,331,637</td>
  </tr>
  <tr>
    <th>Túi Mickey cơ bản</th>
    <th>TXN381</th>
    <td>2</td>
    <td>888,953</td>
  </tr>
  <tr>
    <th>Túi Mini Duffle gắn charm pixel</th>
    <th>TXN478</th>
    <td>69</td>
    <td>29,174,624</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ 1 quai phối xích trong suốt</th>
    <th>TXN408</th>
    <td>18</td>
    <td>7,233,198</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ Minnie 2 nắp có hoa văn</th>
    <th>TXN447</th>
    <td>34</td>
    <td>15,579,532</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ Minnie chần bông có gắn nút</th>
    <th>TXN446</th>
    <td>16</td>
    <td>5,976,713</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ baguette đeo vai</th>
    <th>TXN355</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ buckle flap
    </th>
    <th>TXN480</th>
    <td>130</td>
    <td>55,158,645</td>
  </tr>
  <tr>
    <th>TXN502</th>
    <td>5</td>
    <td>2,724,864</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ buckled trapeze</th>
    <th>TXN397</th>
    <td>34</td>
    <td>11,650,654</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ bán nguyệt dây đeo phối xích</th>
    <th>TXN421</th>
    <td>1</td>
    <td>388,340</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ camera cầm tay đính charm</th>
    <th>TXN400</th>
    <td>55</td>
    <td>16,977,176</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ chân bông 3 tông màu</th>
    <th>TXN430</th>
    <td>6</td>
    <td>2,354,287</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ chần bông dạng hộp 3 tông màu</th>
    <th>TXN429</th>
    <td>3</td>
    <td>1,393,002</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ chần bông nắp gập</th>
    <th>TXN296</th>
    <td>94</td>
    <td>33,446,041</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ clutch chần bông
    </th>
    <th>TXN371</th>
    <td>1</td>
    <td>302,727</td>
  </tr>
  <tr>
    <th>TXN379</th>
    <td>1</td>
    <td>454,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ cut-out khoá trang trí</th>
    <th>TXN427</th>
    <td>34</td>
    <td>12,709,038</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ có bản to cầm tay trên nắp</th>
    <th>TXN444</th>
    <td>6</td>
    <td>1,464,455</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ cạnh hông tam giác</th>
    <th>TXN497</th>
    <td>1</td>
    <td>549,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ cạnh hông xếp nhiều ngăn</th>
    <th>TXN423</th>
    <td>19</td>
    <td>7,176,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng hộp dây xích cầm tay</th>
    <th>TXN405</th>
    <td>84</td>
    <td>35,969,496</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng hộp top-handle</th>
    <th>TXN470</th>
    <td>189</td>
    <td>81,004,483</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dây đan đeo vai khóa xoay</th>
    <th>TXN395</th>
    <td>57</td>
    <td>23,280,911</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dây đeo đầu chữ T</th>
    <th>TXN424</th>
    <td>5</td>
    <td>1,975,957</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp</th>
    <th>TXN467</th>
    <td>233</td>
    <td>100,151,567</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp chần bông trang trí xích</th>
    <th>TXN452</th>
    <td>9</td>
    <td>3,675,160</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp mini đeo chéo</th>
    <th>TXN390</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ dập hiệu ứng princess diamond
    </th>
    <th>TXN416</th>
    <td>74</td>
    <td>33,134,596</td>
  </tr>
  <tr>
    <th>TXN417</th>
    <td>420</td>
    <td>187,609,613</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dập nổi họa tiết đối xứng</th>
    <th>TXN443</th>
    <td>62</td>
    <td>26,327,194</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn charn pixel</th>
    <th>TXN477</th>
    <td>169</td>
    <td>71,330,047</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn khóa trang trí</th>
    <th>TXN432</th>
    <td>46</td>
    <td>20,716,164</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hai ngăn treo charm trang trí</th>
    <th>TXN505</th>
    <td>4</td>
    <td>1,597,227</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hobo trang trí khóa kim loại</th>
    <th>TXN457</th>
    <td>52</td>
    <td>19,090,047</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hình hộp xích trang trí</th>
    <th>TXN498</th>
    <td>7</td>
    <td>3,383,773</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ họa tiết hoa khô</th>
    <th>TXN363</th>
    <td>1</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp top-handle</th>
    <th>TXN466</th>
    <td>64</td>
    <td>26,985,220</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp trang trí hiệu ứng dập nổi</th>
    <th>TXN463</th>
    <td>103</td>
    <td>43,511,833</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp trang trí khóa kim loại</th>
    <th>TXN455</th>
    <td>6</td>
    <td>2,741,335</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp trang trí khóa xoay</th>
    <th>TXN469</th>
    <td>5</td>
    <td>2,391,292</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ in họa tiết graffiti</th>
    <th>TXN437</th>
    <td>1</td>
    <td>340,909</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá logo trang trí</th>
    <th>TXN435</th>
    <td>41</td>
    <td>18,331,868</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá marble</th>
    <th>TXN493</th>
    <td>114</td>
    <td>47,268,517</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá scrunchies</th>
    <th>TXN495</th>
    <td>100</td>
    <td>41,621,124</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá xoắn trang trí</th>
    <th>TXN433</th>
    <td>38</td>
    <td>16,979,927</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khóa trang trí in họa tiết graffiti</th>
    <th>TXN436</th>
    <td>9</td>
    <td>3,579,469</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khóa trượt</th>
    <th>TXN415</th>
    <td>24</td>
    <td>8,473,170</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ laser cut nhỏ</th>
    <th>TXN426</th>
    <td>19</td>
    <td>7,690,883</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ mini chữ d</th>
    <th>TXN378</th>
    <td>1</td>
    <td>422,308</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ mini circle charm</th>
    <th>TXN496</th>
    <td>265</td>
    <td>108,532,268</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ mini tay cầm scrunchies</th>
    <th>TXN494</th>
    <td>80</td>
    <td>32,925,696</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ minnie hình hộp</th>
    <th>TXN448</th>
    <td>32</td>
    <td>11,716,066</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ multi-bag</th>
    <th>TXN506</th>
    <td>10</td>
    <td>6,193,773</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ multi-bag nắp tuck-in</th>
    <th>TXN460</th>
    <td>62</td>
    <td>22,627,542</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nhỏ nắp tuck-in</th>
    <th>TXN461</th>
    <td>25</td>
    <td>9,217,281</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp bất đối xứng</th>
    <th>TXN510</th>
    <td>11</td>
    <td>4,882,727</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp cách điệu trang trí khóa kim loại</th>
    <th>TXN481</th>
    <td>95</td>
    <td>40,211,348</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp dập nổi</th>
    <th>TXN425</th>
    <td>26</td>
    <td>11,360,270</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gấp nếp</th>
    <th>TXN440</th>
    <td>2</td>
    <td>1,182,324</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập 2 tone màu</th>
    <th>TXN369</th>
    <td>4</td>
    <td>798,998</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập dập nổi 3d</th>
    <th>TXN313</th>
    <td>277</td>
    <td>121,255,993</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập phối màu</th>
    <th>TXN508</th>
    <td>3</td>
    <td>1,494,591</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập treo charm trang trí</th>
    <th>TXN503</th>
    <td>4</td>
    <td>1,935,545</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập đeo vai</th>
    <th>TXN368</th>
    <td>2</td>
    <td>662,227</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp pixel-cut</th>
    <th>TXN479</th>
    <td>53</td>
    <td>22,509,479</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp xéo đường chỉ nổi</th>
    <th>TXN428</th>
    <td>28</td>
    <td>10,254,423</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp đắp lớp</th>
    <th>TXN439</th>
    <td>8</td>
    <td>3,507,610</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phun hiệu ứng loang màu</th>
    <th>TXN459</th>
    <td>14</td>
    <td>4,542,212</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối 2 chất liệu khóa trang trí đính đá Princess</th>
    <th>TXN391</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối 2 màu</th>
    <th>TXN294</th>
    <td>30</td>
    <td>8,430,962</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối animal print</th>
    <th>TXN353</th>
    <td>4</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối color block</th>
    <th>TXN386</th>
    <td>46</td>
    <td>17,047,854</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối hiệu ứng giả đan lát</th>
    <th>TXN472</th>
    <td>264</td>
    <td>114,821,304</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối khoá cùng màu</th>
    <th>TXN387</th>
    <td>60</td>
    <td>22,246,194</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối màu color-block</th>
    <th>TXN458</th>
    <td>26</td>
    <td>10,086,663</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối màu cách điệu</th>
    <th>TXN454</th>
    <td>51</td>
    <td>18,537,950</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm cách điệu</th>
    <th>TXN384</th>
    <td>77</td>
    <td>31,868,103</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm kim loại chữ d</th>
    <th>TXN374</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm nhún</th>
    <th>TXN492</th>
    <td>371</td>
    <td>153,113,531</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm rời</th>
    <th>TXN486</th>
    <td>145</td>
    <td>62,587,031</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm trong suốt</th>
    <th>TXN410</th>
    <td>2</td>
    <td>794,294</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ the maze</th>
    <th>TXN483</th>
    <td>31</td>
    <td>12,611,511</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ thân chữ a</th>
    <th>TXN453</th>
    <td>5</td>
    <td>2,281,316</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ thân xếp khối</th>
    <th>TXN442</th>
    <td>102</td>
    <td>44,228,700</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle</th>
    <th>TXN403</th>
    <td>147</td>
    <td>65,358,299</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle cách điệu</th>
    <th>TXN456</th>
    <td>3</td>
    <td>1,029,672</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle dáng hộp</th>
    <th>TXN491</th>
    <td>171</td>
    <td>78,366,688</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle khóa kim loại raw</th>
    <th>TXN487</th>
    <td>121</td>
    <td>53,876,185</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top-handle</th>
    <th>TXN398</th>
    <td>1</td>
    <td>425,432</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tope handle dập nổi viền</th>
    <th>TXN465</th>
    <td>55</td>
    <td>23,829,060</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tote nhỏ nắp cách điệu</th>
    <th>TXN451</th>
    <td>19</td>
    <td>7,897,636</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tote nhỏ treo charm trang trí</th>
    <th>TXN504</th>
    <td>5</td>
    <td>2,160,955</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí hiệu ứng dập nổi</th>
    <th>TXN464</th>
    <td>82</td>
    <td>32,561,131</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí khóa the maze</th>
    <th>TXN484</th>
    <td>73</td>
    <td>31,855,360</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí khóa xoay</th>
    <th>TXN468</th>
    <td>271</td>
    <td>120,439,935</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí logo cách điệu</th>
    <th>TXN471</th>
    <td>227</td>
    <td>97,716,905</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí viền</th>
    <th>TXN406</th>
    <td>11</td>
    <td>988,794</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí đinh tán cá tính TXN239</th>
    <th>TXN239</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ túi đeo chéo gắn charm trang trí</th>
    <th>TXN401</th>
    <td>49</td>
    <td>18,636,712</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ viền kim tuyến trên nắp</th>
    <th>TXN438</th>
    <td>6</td>
    <td>2,288,711</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ và clutch 2in1</th>
    <th>TXN485</th>
    <td>27</td>
    <td>9,531,572</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đan cạnh viền</th>
    <th>TXN490</th>
    <td>87</td>
    <td>36,047,020</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chiếu hiệu ứng đan lát</th>
    <th>TXN394</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo khóa bấm</th>
    <th>TXN414</th>
    <td>202</td>
    <td>83,526,433</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo khóa gài</th>
    <th>TXN413</th>
    <td>10</td>
    <td>1,321,818</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo nắp gập</th>
    <th>TXN420</th>
    <td>153</td>
    <td>59,851,369</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo nắp viền dây kim loại</th>
    <th>TXN488</th>
    <td>57</td>
    <td>25,210,441</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ đeo chéo tối giản
    </th>
    <th>TXN404</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>TXN450</th>
    <td>257</td>
    <td>113,333,164</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai có 2 thiết kế dây đeo</th>
    <th>TXN441</th>
    <td>73</td>
    <td>32,079,743</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai hiệu ứng đẹp mắt</th>
    <th>TXN393</th>
    <td>46</td>
    <td>18,539,591</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai khoá gài thể thao</th>
    <th>TXN402</th>
    <td>1</td>
    <td>454,545</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai khóa mây</th>
    <th>TXN489</th>
    <td>116</td>
    <td>49,782,666</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai phun hiệu ứng loang màu</th>
    <th>TXN473</th>
    <td>16</td>
    <td>6,577,599</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đồng màu</th>
    <th>TXN317</th>
    <td>3</td>
    <td>225,455</td>
  </tr>
  <tr>
    <th>Túi đeo vai hiệu ứng Pleat dáng lạ</th>
    <th>TXN412</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th rowspan="29" valign="top">
      Túi xách trung
    </th>
    <th>Túi Xách Trung Nắp Gập</th>
    <th>TXT206</th>
    <td>1</td>
    <td>631,818</td>
  </tr>
  <tr>
    <th>Túi dập hiệu ứng Princess Diamond</th>
    <th>TXT226</th>
    <td>369</td>
    <td>167,512,855</td>
  </tr>
  <tr>
    <th>Túi xách trung buckle flap</th>
    <th>TXT243</th>
    <td>62</td>
    <td>27,654,470</td>
  </tr>
  <tr>
    <th>Túi xách trung buckled tote</th>
    <th>TXT242</th>
    <td>164</td>
    <td>77,549,162</td>
  </tr>
  <tr>
    <th>Túi xách trung bukcle khoá trượt</th>
    <th>TXT249</th>
    <td>3</td>
    <td>1,105,773</td>
  </tr>
  <tr>
    <th>Túi xách trung double handle</th>
    <th>TXT221</th>
    <td>11</td>
    <td>3,734,916</td>
  </tr>
  <tr>
    <th>Túi xách trung dập hiệu ứng lập thể</th>
    <th>TXT238</th>
    <td>22</td>
    <td>10,003,764</td>
  </tr>
  <tr>
    <th>Túi xách trung dập sọc nổi</th>
    <th>TXT230</th>
    <td>147</td>
    <td>68,149,288</td>
  </tr>
  <tr>
    <th>Túi xách trung gắn khóa trang trí</th>
    <th>TXT231</th>
    <td>75</td>
    <td>35,381,993</td>
  </tr>
  <tr>
    <th>Túi xách trung hộp trang trí nút kim loại</th>
    <th>TXT233</th>
    <td>61</td>
    <td>26,552,377</td>
  </tr>
  <tr>
    <th>Túi xách trung khoá xoắn trang trí</th>
    <th>TXT232</th>
    <td>57</td>
    <td>25,036,873</td>
  </tr>
  <tr>
    <th>Túi xách trung multi-bag</th>
    <th>TXT237</th>
    <td>3</td>
    <td>1,458,915</td>
  </tr>
  <tr>
    <th>Túi xách trung nắp dập nổi</th>
    <th>TXT228</th>
    <td>8</td>
    <td>4,251,577</td>
  </tr>
  <tr>
    <th>Túi xách trung phun hiệu ứng loang màu</th>
    <th>TXT240</th>
    <td>19</td>
    <td>9,123,272</td>
  </tr>
  <tr>
    <th>Túi xách trung satchel dáng hộp</th>
    <th>TXT241</th>
    <td>69</td>
    <td>33,738,392</td>
  </tr>
  <tr>
    <th>Túi xách trung the maze</th>
    <th>TXT244</th>
    <td>73</td>
    <td>34,517,720</td>
  </tr>
  <tr>
    <th>Túi xách trung thân xếp khối</th>
    <th>TXT234</th>
    <td>48</td>
    <td>21,496,332</td>
  </tr>
  <tr>
    <th>Túi xách trung top handle</th>
    <th>TXT223</th>
    <td>119</td>
    <td>57,150,914</td>
  </tr>
  <tr>
    <th>Túi xách trung top handle nắp viền dây kim loại</th>
    <th>TXT246</th>
    <td>107</td>
    <td>52,592,782</td>
  </tr>
  <tr>
    <th>Túi xách trung tote charm trang trí phối 2 màu</th>
    <th>TXT220</th>
    <td>338</td>
    <td>156,150,381</td>
  </tr>
  <tr>
    <th>Túi xách trung tote double bag</th>
    <th>TXT222</th>
    <td>1</td>
    <td>363,636</td>
  </tr>
  <tr>
    <th>Túi xách trung tote trung nắp cách điệu</th>
    <th>TXT235</th>
    <td>4</td>
    <td>1,403,078</td>
  </tr>
  <tr>
    <th>Túi xách trung tote trung phối khác chất liệu</th>
    <th>TXT236</th>
    <td>78</td>
    <td>36,568,036</td>
  </tr>
  <tr>
    <th>Túi xách trung trang trí khóa kim loại raw</th>
    <th>TXT245</th>
    <td>61</td>
    <td>24,937,334</td>
  </tr>
  <tr>
    <th>Túi xách trung treo charm trang trí</th>
    <th>TXT239</th>
    <td>199</td>
    <td>88,086,743</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách trung tối giản
    </th>
    <th>TXT201</th>
    <td>280</td>
    <td>128,013,358</td>
  </tr>
  <tr>
    <th>TXT204</th>
    <td>84</td>
    <td>35,879,032</td>
  </tr>
  <tr>
    <th>Túi xách trung đa ngăn</th>
    <th>TXT251</th>
    <td>6</td>
    <td>2,775,273</td>
  </tr>
  <tr>
    <th>Túi đeo vai gắn charm túi mini</th>
    <th>TXT247</th>
    <td>116</td>
    <td>49,627,967</td>
  </tr>
  <tr>
    <th rowspan="24" valign="top">
      Ví
    </th>
    <th rowspan="24" valign="top">
      Ví
    </th>
    <th>Clutch dập hiệu ứng Princess Diamond</th>
    <th>VI141</th>
    <td>336</td>
    <td>107,275,032</td>
  </tr>
  <tr>
    <th>Ví 2 thân logo cách điệu</th>
    <th>VI156</th>
    <td>166</td>
    <td>50,280,228</td>
  </tr>
  <tr>
    <th>Ví chữ nhật kèm dây đeo</th>
    <th>VI147</th>
    <td>28</td>
    <td>6,928,615</td>
  </tr>
  <tr>
    <th>Ví cầm tay có ngăn điện thoại</th>
    <th>VI137</th>
    <td>9</td>
    <td>279,484</td>
  </tr>
  <tr>
    <th>Ví cầm tay</th>
    <th>VI127</th>
    <td>10</td>
    <td>2,757,579</td>
  </tr>
  <tr>
    <th>Ví cầm tay hoạ tiết vân chìm VI052</th>
    <th>VI052</th>
    <td>34</td>
    <td>8,763,593</td>
  </tr>
  <tr>
    <th>Ví cầm tay viền trang trí chấm bi</th>
    <th>VI118</th>
    <td>1</td>
    <td>265,852</td>
  </tr>
  <tr>
    <th>Ví dài nhiều ngăn</th>
    <th>VI158</th>
    <td>48</td>
    <td>11,032,992</td>
  </tr>
  <tr>
    <th>Ví dài nắp pixel-cut</th>
    <th>VI157</th>
    <td>19</td>
    <td>5,495,461</td>
  </tr>
  <tr>
    <th>Ví dây kéo kim loại nhiều ngăn</th>
    <th>VI149</th>
    <td>206</td>
    <td>51,423,444</td>
  </tr>
  <tr>
    <th>Ví dập nổi họa tiết đối xứng</th>
    <th>VI151</th>
    <td>3</td>
    <td>936,279</td>
  </tr>
  <tr>
    <th>Ví khoá trang trí cùng màu</th>
    <th>VI136</th>
    <td>261</td>
    <td>88,173,669</td>
  </tr>
  <tr>
    <th>Ví kèm dây đeo hiệu ứng đan</th>
    <th>VI162</th>
    <td>291</td>
    <td>90,308,393</td>
  </tr>
  <tr>
    <th>Ví mini Accordion có dây đeo vai</th>
    <th>VI142</th>
    <td>1</td>
    <td>234,190</td>
  </tr>
  <tr>
    <th>Ví nắp dập nổi</th>
    <th>VI145</th>
    <td>29</td>
    <td>7,975,168</td>
  </tr>
  <tr>
    <th>Ví nắp gập khóa bấm</th>
    <th>VI153</th>
    <td>23</td>
    <td>6,480,007</td>
  </tr>
  <tr>
    <th>Ví phối hiệu ứng</th>
    <th>VI138</th>
    <td>57</td>
    <td>15,740,588</td>
  </tr>
  <tr>
    <th>Ví phối màu color-block</th>
    <th>VI155</th>
    <td>52</td>
    <td>15,756,582</td>
  </tr>
  <tr>
    <th>Ví tay cầm nhiều ngăn</th>
    <th>VI133</th>
    <td>1</td>
    <td>313,636</td>
  </tr>
  <tr>
    <th>Ví the maze</th>
    <th>VI159</th>
    <td>65</td>
    <td>21,574,440</td>
  </tr>
  <tr>
    <th>Ví trang trí khóa kim loại</th>
    <th>VI154</th>
    <td>62</td>
    <td>16,301,328</td>
  </tr>
  <tr>
    <th>Ví trang trí khóa kim loại raw</th>
    <th>VI161</th>
    <td>171</td>
    <td>55,456,417</td>
  </tr>
  <tr>
    <th>Ví trang trí khóa the maze</th>
    <th>VI160</th>
    <td>112</td>
    <td>37,111,137</td>
  </tr>
  <tr>
    <th>Ví trang trí nút kim loại</th>
    <th>VI150</th>
    <td>2</td>
    <td>629,363</td>
  </tr>
  <tr>
    <th rowspan="71" valign="top">
      Áo
    </th>
    <th rowspan="28" valign="top">
      Áo Kiểu
    </th>
    <th>Áo Kiểu AKI004</th>
    <th>AKI004</th>
    <td>17</td>
    <td>2,351,818</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI010</th>
    <th>AKI010</th>
    <td>14</td>
    <td>1,796,364</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI013</th>
    <th>AKI013</th>
    <td>7</td>
    <td>1,280,909</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI015</th>
    <th>AKI015</th>
    <td>10</td>
    <td>1,809,091</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI016</th>
    <th>AKI016</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI017</th>
    <th>AKI017</th>
    <td>5</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI018</th>
    <th>AKI018</th>
    <td>5</td>
    <td>1,050,091</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI019</th>
    <th>AKI019</th>
    <td>6</td>
    <td>1,067,273</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI020</th>
    <th>AKI020</th>
    <td>7</td>
    <td>1,811,818</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI021</th>
    <th>AKI021</th>
    <td>2</td>
    <td>234,545</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI022</th>
    <th>AKI022</th>
    <td>8</td>
    <td>1,447,273</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI023</th>
    <th>AKI023</th>
    <td>8</td>
    <td>1,447,273</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI025</th>
    <th>AKI025</th>
    <td>5</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI026</th>
    <th>AKI026</th>
    <td>5</td>
    <td>1,031,955</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI027</th>
    <th>AKI027</th>
    <td>4</td>
    <td>1,178,182</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAC12012036</th>
    <th>BAC12012036</th>
    <td>3</td>
    <td>270,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAC12012037</th>
    <th>BAC12012037</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAC12101045</th>
    <th>BAC12101045</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAS12011013NM</th>
    <th>BAS12011013NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMA12009050GC</th>
    <th>CMA12009050GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMA12009051GC</th>
    <th>CMA12009051GC</th>
    <td>5</td>
    <td>450,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMA12010029HH</th>
    <th>CMA12010029HH</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMC12101053</th>
    <th>CMC12101053</th>
    <td>4</td>
    <td>360,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMO12010048NM</th>
    <th>CMO12010048NM</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMS12009040NM</th>
    <th>CMS12009040NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMS12011015GC</th>
    <th>CMS12011015GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDA12009063GC</th>
    <th>TDA12009063GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDO12010005GC</th>
    <th>TDO12010005GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th rowspan="19" valign="top">
      Áo Sơ Mi
    </th>
    <th>Áo Sơ Mi AKI009</th>
    <th>AKI009</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi AKI012</th>
    <th>AKI012</th>
    <td>5</td>
    <td>919,091</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM004</th>
    <th>ASM004</th>
    <td>6</td>
    <td>1,085,455</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM006</th>
    <th>ASM006</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM007</th>
    <th>ASM007</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM008</th>
    <th>ASM008</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM009</th>
    <th>ASM009</th>
    <td>8</td>
    <td>1,447,273</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ATH005</th>
    <th>ATH005</th>
    <td>4</td>
    <td>469,091</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAA12010038NM</th>
    <th>BAA12010038NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAC12011006NM</th>
    <th>BAC12011006NM</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAS12012006NM</th>
    <th>BAS12012006NM</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BPC12005059NM</th>
    <th>BPC12005059NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMA12012007GC</th>
    <th>CMA12012007GC</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12004020NM</th>
    <th>CMS12004020NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12009036NM</th>
    <th>CMS12009036NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12012034</th>
    <th>CMS12012034</th>
    <td>18</td>
    <td>3,175,545</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12012038</th>
    <th>CMS12012038</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12101014</th>
    <th>CMS12101014</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi TDS12101024</th>
    <th>TDS12101024</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th rowspan="24" valign="top">
      Áo Thun
    </th>
    <th>Áo Thun AKI011</th>
    <th>AKI011</th>
    <td>10</td>
    <td>1,168,182</td>
  </tr>
  <tr>
    <th>Áo Thun ATH001</th>
    <th>ATH001</th>
    <td>7</td>
    <td>820,909</td>
  </tr>
  <tr>
    <th>Áo Thun ATH003</th>
    <th>ATH003</th>
    <td>1</td>
    <td>117,273</td>
  </tr>
  <tr>
    <th>Áo Thun ATH004</th>
    <th>ATH004</th>
    <td>1</td>
    <td>117,273</td>
  </tr>
  <tr>
    <th>Áo Thun ATH006</th>
    <th>ATH006</th>
    <td>29</td>
    <td>2,693,773</td>
  </tr>
  <tr>
    <th>Áo Thun ATH007</th>
    <th>ATH007</th>
    <td>2</td>
    <td>343,636</td>
  </tr>
  <tr>
    <th>Áo Thun ATH008</th>
    <th>ATH008</th>
    <td>4</td>
    <td>769,091</td>
  </tr>
  <tr>
    <th>Áo Thun BAA12012022</th>
    <th>BAA12012022</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12008065NM</th>
    <th>BAC12008065NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12008066HH</th>
    <th>BAC12008066HH</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101006</th>
    <th>BAC12101006</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101021</th>
    <th>BAC12101021</th>
    <td>5</td>
    <td>581,818</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101022</th>
    <th>BAC12101022</th>
    <td>14</td>
    <td>1,797,455</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101023</th>
    <th>BAC12101023</th>
    <td>9</td>
    <td>1,050,909</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101063</th>
    <th>BAC12101063</th>
    <td>8</td>
    <td>630,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101064</th>
    <th>BAC12101064</th>
    <td>4</td>
    <td>360,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAS12101048</th>
    <th>BAS12101048</th>
    <td>14</td>
    <td>1,641,818</td>
  </tr>
  <tr>
    <th>Áo Thun BAS12101049</th>
    <th>BAS12101049</th>
    <td>8</td>
    <td>1,009,091</td>
  </tr>
  <tr>
    <th>Áo Thun BPC12005054HH</th>
    <th>BPC12005054HH</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo thun "Bánh mỳ Sài gòn"</th>
    <th>ATH013</th>
    <td>10</td>
    <td>633,636</td>
  </tr>
  <tr>
    <th>Áo thun "Chích rồi ôm tí"</th>
    <th>ATH012</th>
    <td>7</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo thun "Hành khỏe đẹp"</th>
    <th>ATH011</th>
    <td>13</td>
    <td>452,727</td>
  </tr>
  <tr>
    <th>Áo thun "Hành trứng ấm êm"</th>
    <th>ATH014</th>
    <td>14</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Áo thun "Top giàu sang"</th>
    <th>ATH010</th>
    <td>8</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th rowspan="610" valign="top">
      5
    </th>
    <th rowspan="318" valign="top">
      Giày
    </th>
    <th rowspan="64" valign="top">
      Dép Guốc
    </th>
    <th>Dép lào thời trang Juno hoạ tiết cây kem DK011</th>
    <th>DK011</th>
    <td>6</td>
    <td>265,909</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu trắng DK005</th>
    <th>DK005</th>
    <td>11</td>
    <td>259,091</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu tím DK001</th>
    <th>DK001</th>
    <td>3</td>
    <td>136,364</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu xanh DK003</th>
    <th>DK003</th>
    <td>3</td>
    <td>100,909</td>
  </tr>
  <tr>
    <th>Dép lào thời trang họa tiết con chim DK013</th>
    <th>DK013</th>
    <td>1</td>
    <td>45,455</td>
  </tr>
  <tr>
    <th>Dép DE003</th>
    <th>DE003</th>
    <td>2</td>
    <td>201,818</td>
  </tr>
  <tr>
    <th>Dép Guốc DK023</th>
    <th>DK023</th>
    <td>1</td>
    <td>38,636</td>
  </tr>
  <tr>
    <th>Dép Guốc DK026</th>
    <th>DK026</th>
    <td>1</td>
    <td>45,455</td>
  </tr>
  <tr>
    <th>Dép Guốc DK028</th>
    <th>DK028</th>
    <td>1</td>
    <td>40,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK025</th>
    <th>DK025</th>
    <td>2</td>
    <td>81,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK027</th>
    <th>DK027</th>
    <td>1</td>
    <td>38,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK033</th>
    <th>DK033</th>
    <td>6</td>
    <td>327,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK034</th>
    <th>DK034</th>
    <td>5</td>
    <td>283,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK035</th>
    <th>DK035</th>
    <td>4</td>
    <td>229,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK036</th>
    <th>DK036</th>
    <td>14</td>
    <td>792,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK037</th>
    <th>DK037</th>
    <td>15</td>
    <td>786,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK038</th>
    <th>DK038</th>
    <td>9</td>
    <td>211,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK039</th>
    <th>DK039</th>
    <td>8</td>
    <td>337,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK040</th>
    <th>DK040</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK041</th>
    <th>DK041</th>
    <td>6</td>
    <td>334,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK042</th>
    <th>DK042</th>
    <td>4</td>
    <td>204,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK043</th>
    <th>DK043</th>
    <td>4</td>
    <td>225,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK044</th>
    <th>DK044</th>
    <td>6</td>
    <td>289,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK045</th>
    <th>DK045</th>
    <td>2</td>
    <td>111,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK046</th>
    <th>DK046</th>
    <td>4</td>
    <td>225,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK048</th>
    <th>DK048</th>
    <td>2</td>
    <td>114,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK049</th>
    <th>DK049</th>
    <td>3</td>
    <td>181,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK050</th>
    <th>DK050</th>
    <td>5</td>
    <td>187,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK051</th>
    <th>DK051</th>
    <td>5</td>
    <td>171,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK052</th>
    <th>DK052</th>
    <td>4</td>
    <td>117,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK053</th>
    <th>DK053</th>
    <td>7</td>
    <td>322,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK056</th>
    <th>DK056</th>
    <td>5</td>
    <td>225,909</td>
  </tr>
  <tr>
    <th>Dép Tông DK057</th>
    <th>DK057</th>
    <td>1</td>
    <td>60,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK058</th>
    <th>DK058</th>
    <td>1</td>
    <td>63,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK059</th>
    <th>DK059</th>
    <td>7</td>
    <td>379,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK060</th>
    <th>DK060</th>
    <td>5</td>
    <td>292,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK061</th>
    <th>DK061</th>
    <td>6</td>
    <td>356,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK062</th>
    <th>DK062</th>
    <td>8</td>
    <td>404,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK063</th>
    <th>DK063</th>
    <td>10</td>
    <td>480,455</td>
  </tr>
  <tr>
    <th>Dép Tông DK064</th>
    <th>DK064</th>
    <td>10</td>
    <td>588,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK065</th>
    <th>DK065</th>
    <td>7</td>
    <td>308,636</td>
  </tr>
  <tr>
    <th>Dép Tông DK066</th>
    <th>DK066</th>
    <td>1</td>
    <td>54,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK067</th>
    <th>DK067</th>
    <td>10</td>
    <td>579,091</td>
  </tr>
  <tr>
    <th>Dép Tông DK068</th>
    <th>DK068</th>
    <td>10</td>
    <td>569,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK069</th>
    <th>DK069</th>
    <td>7</td>
    <td>394,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK070</th>
    <th>DK070</th>
    <td>5</td>
    <td>245,000</td>
  </tr>
  <tr>
    <th>Dép Tông DK071</th>
    <th>DK071</th>
    <td>3</td>
    <td>117,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK072</th>
    <th>DK072</th>
    <td>2</td>
    <td>111,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK073</th>
    <th>DK073</th>
    <td>10</td>
    <td>566,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK074</th>
    <th>DK074</th>
    <td>45</td>
    <td>2,523,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK075</th>
    <th>DK075</th>
    <td>39</td>
    <td>2,095,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK076</th>
    <th>DK076</th>
    <td>29</td>
    <td>1,629,727</td>
  </tr>
  <tr>
    <th>Dép Tông DK077</th>
    <th>DK077</th>
    <td>40</td>
    <td>2,091,364</td>
  </tr>
  <tr>
    <th>Dép Tông DK078</th>
    <th>DK078</th>
    <td>36</td>
    <td>2,005,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK079</th>
    <th>DK079</th>
    <td>47</td>
    <td>2,313,182</td>
  </tr>
  <tr>
    <th>Dép Tông DK080</th>
    <th>DK080</th>
    <td>37</td>
    <td>2,061,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK081</th>
    <th>DK081</th>
    <td>27</td>
    <td>1,549,545</td>
  </tr>
  <tr>
    <th>Dép Tông DK082</th>
    <th>DK082</th>
    <td>70</td>
    <td>3,881,818</td>
  </tr>
  <tr>
    <th>Dép Tông DK083</th>
    <th>DK083</th>
    <td>52</td>
    <td>2,962,273</td>
  </tr>
  <tr>
    <th>Dép Tông DK084</th>
    <th>DK084</th>
    <td>74</td>
    <td>4,044,091</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno hoạ tiết trái thơm DK009</th>
    <th>DK009</th>
    <td>4</td>
    <td>161,364</td>
  </tr>
  <tr>
    <th>Dép lào thời trang Juno màu đỏ DK002</th>
    <th>DK002</th>
    <td>6</td>
    <td>181,818</td>
  </tr>
  <tr>
    <th>Sản phẩm quà tặng QT014</th>
    <th>DK014</th>
    <td>4</td>
    <td>159,091</td>
  </tr>
  <tr>
    <th>Sản phẩm quà tặng QT015</th>
    <th>DK015</th>
    <td>6</td>
    <td>75,455</td>
  </tr>
  <tr>
    <th rowspan="5" valign="top">
      Giày Boot
    </th>
    <th>Giày boot gót phối kim loại</th>
    <th>BO07017</th>
    <td>2</td>
    <td>594,045</td>
  </tr>
  <tr>
    <th>Giày boot gót vuông viền cổ chân</th>
    <th>BO09015</th>
    <td>25</td>
    <td>6,554,163</td>
  </tr>
  <tr>
    <th>Giày boot cổ cao gót vuông</th>
    <th>BO07018</th>
    <td>1</td>
    <td>271,818</td>
  </tr>
  <tr>
    <th>Giày boot dây kéo khoá tròn kim loại</th>
    <th>BO07016</th>
    <td>43</td>
    <td>11,669,053</td>
  </tr>
  <tr>
    <th>Giày boot trang trí kim loại viền mũi</th>
    <th>BO09014</th>
    <td>3</td>
    <td>171,818</td>
  </tr>
  <tr>
    <th rowspan="20" valign="top">
      Giày Búp Bê
    </th>
    <th>Giày búp bê dây mảnh đắp chéo phối vải lưới</th>
    <th>BB03068</th>
    <td>31</td>
    <td>8,583,768</td>
  </tr>
  <tr>
    <th>Giày búp bê gắn khóa trang trí</th>
    <th>BB03065</th>
    <td>90</td>
    <td>23,272,435</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn gắn khóa trang trí</th>
    <th>BB03064</th>
    <td>119</td>
    <td>32,974,659</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn khoét eo BB03021</th>
    <th>BB03021</th>
    <td>65</td>
    <td>13,843,813</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày búp bê mũi nhọn khóa trang trí
    </th>
    <th>BB03046</th>
    <td>2</td>
    <td>533,063</td>
  </tr>
  <tr>
    <th>BB03058</th>
    <td>74</td>
    <td>13,183,623</td>
  </tr>
  <tr>
    <th>BB03062</th>
    <td>153</td>
    <td>40,444,717</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn quai chéo</th>
    <th>BB03070</th>
    <td>91</td>
    <td>22,186,650</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn quai hậu đắp chéo</th>
    <th>BB01139</th>
    <td>31</td>
    <td>5,383,436</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn slingback</th>
    <th>BB03061</th>
    <td>1</td>
    <td>291,497</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn đính khóa trang trí</th>
    <th>BB03066</th>
    <td>126</td>
    <td>31,499,676</td>
  </tr>
  <tr>
    <th>Giày búp bê mũi nhọn đính kim loại BB03017</th>
    <th>BB03017</th>
    <td>1</td>
    <td>255,288</td>
  </tr>
  <tr>
    <th>Giày búp bê slingback</th>
    <th>BB03054</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày mules dây xích trang trí</th>
    <th>BB03063</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Giày mules gót ngọc trai</th>
    <th>BB03060</th>
    <td>5</td>
    <td>882,955</td>
  </tr>
  <tr>
    <th>Giày mules gót thấp in họa tiết graffiti</th>
    <th>BB03067</th>
    <td>138</td>
    <td>32,300,050</td>
  </tr>
  <tr>
    <th>Giày mules mũi nhọn khóa trang trí</th>
    <th>BB03059</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày mules mũi vuông</th>
    <th>BB03056</th>
    <td>1</td>
    <td>305,227</td>
  </tr>
  <tr>
    <th>Giày mules quai khoá trang trí</th>
    <th>BB03069</th>
    <td>235</td>
    <td>64,865,321</td>
  </tr>
  <tr>
    <th>Waffle-Y-Cute</th>
    <th>BB03071</th>
    <td>123</td>
    <td>34,457,072</td>
  </tr>
  <tr>
    <th rowspan="89" valign="top">
      Giày Cao Gót
    </th>
    <th>Giày cao gót dây quai hậu</th>
    <th>CG09136</th>
    <td>52</td>
    <td>14,779,905</td>
  </tr>
  <tr>
    <th>Giày cao gót dây thắt nút</th>
    <th>CG07103</th>
    <td>85</td>
    <td>25,743,927</td>
  </tr>
  <tr>
    <th>Giày cao gót gót bọc trong suốt</th>
    <th>CG07098</th>
    <td>26</td>
    <td>6,691,482</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh mũi nhọn</th>
    <th>CG07092</th>
    <td>82</td>
    <td>23,231,503</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh phối si giả satin</th>
    <th>CG09138</th>
    <td>58</td>
    <td>16,861,369</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh quai chéo</th>
    <th>CG09119</th>
    <td>9</td>
    <td>406,364</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh quai cổ chân</th>
    <th>CG09120</th>
    <td>50</td>
    <td>7,629,971</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh trang trí đính đá</th>
    <th>CG09141</th>
    <td>64</td>
    <td>19,433,972</td>
  </tr>
  <tr>
    <th>Giày cao gót gót thanh xi kim loại</th>
    <th>CG09123</th>
    <td>28</td>
    <td>8,241,724</td>
  </tr>
  <tr>
    <th>Giày cao gót gót trụ khóa trang trí</th>
    <th>CG05109</th>
    <td>85</td>
    <td>24,946,861</td>
  </tr>
  <tr>
    <th>Giày cao gót gót trụ trang trí đính đá</th>
    <th>CG05110</th>
    <td>107</td>
    <td>29,332,305</td>
  </tr>
  <tr>
    <th>Giày cao gót gắn khoá trang trí</th>
    <th>CG09135</th>
    <td>104</td>
    <td>31,350,565</td>
  </tr>
  <tr>
    <th>Giày cao gót hậu rút nhún</th>
    <th>CG05103</th>
    <td>127</td>
    <td>38,288,342</td>
  </tr>
  <tr>
    <th>Giày cao gót khóa gài</th>
    <th>CG09144</th>
    <td>169</td>
    <td>53,752,048</td>
  </tr>
  <tr>
    <th>Giày cao gót khóa trang trí họa tiết mê cung</th>
    <th>CG09143</th>
    <td>90</td>
    <td>26,539,626</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót chuyển màu</th>
    <th>CG07101</th>
    <td>18</td>
    <td>4,962,197</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót hình thang</th>
    <th>CG11007</th>
    <td>47</td>
    <td>14,228,479</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót khắc trụ</th>
    <th>CG05104</th>
    <td>91</td>
    <td>26,012,851</td>
  </tr>
  <tr>
    <th rowspan="5" valign="top">
      Giày cao gót mũi nhọn gót thanh
    </th>
    <th>CG05089</th>
    <td>3</td>
    <td>270,909</td>
  </tr>
  <tr>
    <th>CG05107</th>
    <td>130</td>
    <td>35,734,828</td>
  </tr>
  <tr>
    <th>CG09125</th>
    <td>26</td>
    <td>3,989,568</td>
  </tr>
  <tr>
    <th>CG09130</th>
    <td>41</td>
    <td>12,107,486</td>
  </tr>
  <tr>
    <th>CG09131</th>
    <td>42</td>
    <td>11,178,761</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót thanh ánh nhũ</th>
    <th>CG09126</th>
    <td>15</td>
    <td>2,656,217</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày cao gót mũi nhọn gót trụ
    </th>
    <th>CG05092</th>
    <td>10</td>
    <td>869,462</td>
  </tr>
  <tr>
    <th>CG05093</th>
    <td>2</td>
    <td>494,545</td>
  </tr>
  <tr>
    <th>CG05112</th>
    <td>135</td>
    <td>38,079,630</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót xi kim loại</th>
    <th>CG09129</th>
    <td>130</td>
    <td>39,144,898</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gắn dây xích mảnh</th>
    <th>CG09145</th>
    <td>126</td>
    <td>38,163,032</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn slingback</th>
    <th>CG09127</th>
    <td>41</td>
    <td>12,984,209</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn đế đúp</th>
    <th>CG11008</th>
    <td>24</td>
    <td>6,484,992</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi tròn nơ trang trí</th>
    <th>CG05111</th>
    <td>15</td>
    <td>4,988,455</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi vuông gắn khóa trang trí</th>
    <th>CG07102</th>
    <td>86</td>
    <td>25,422,127</td>
  </tr>
  <tr>
    <th>Giày cao gót phối hai tone màu</th>
    <th>CG05100</th>
    <td>22</td>
    <td>4,203,479</td>
  </tr>
  <tr>
    <th>Giày cao gót pump gót thanh</th>
    <th>CG09128</th>
    <td>37</td>
    <td>10,531,036</td>
  </tr>
  <tr>
    <th>Giày cao gót pump gắn khóa trang trí</th>
    <th>CG05105</th>
    <td>84</td>
    <td>24,009,158</td>
  </tr>
  <tr>
    <th>Giày cao gót pump khóa trang trí</th>
    <th>CG09124</th>
    <td>68</td>
    <td>12,700,786</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi nhọn</th>
    <th>CG09146</th>
    <td>12</td>
    <td>4,140,500</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi nhọn gót thanh</th>
    <th>CG09140</th>
    <td>14</td>
    <td>2,417,587</td>
  </tr>
  <tr>
    <th>Giày cao gót pump mũi vuông</th>
    <th>CG05096</th>
    <td>66</td>
    <td>17,527,803</td>
  </tr>
  <tr>
    <th>Giày cao gót pump quai xích trang trí cổ chân</th>
    <th>CG09133</th>
    <td>155</td>
    <td>47,384,481</td>
  </tr>
  <tr>
    <th>Giày cao gót pump đính khóa trang trí</th>
    <th>CG09134</th>
    <td>41</td>
    <td>11,521,482</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày cao gót quai ngang khoá trang trí
    </th>
    <th>CG05108</th>
    <td>134</td>
    <td>35,623,671</td>
  </tr>
  <tr>
    <th>CG07104</th>
    <td>99</td>
    <td>30,618,516</td>
  </tr>
  <tr>
    <th>Giày cao gót quai ngang khóa trang trí</th>
    <th>CG05101</th>
    <td>6</td>
    <td>1,710,445</td>
  </tr>
  <tr>
    <th>Giày cao gót si kim tuyến</th>
    <th>CG07100</th>
    <td>23</td>
    <td>6,446,438</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót 2 màu</th>
    <th>CG07105</th>
    <td>91</td>
    <td>26,379,304</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót chuyển màu</th>
    <th>CG05094</th>
    <td>55</td>
    <td>16,335,664</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót phối kim loại</th>
    <th>CG07097</th>
    <td>9</td>
    <td>1,428,777</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày cao gót slingback gót thanh
    </th>
    <th>CG07091</th>
    <td>2</td>
    <td>614,067</td>
  </tr>
  <tr>
    <th>CG09142</th>
    <td>67</td>
    <td>19,668,108</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gót thanh đính khóa chữ j</th>
    <th>CG09121</th>
    <td>8</td>
    <td>2,108,876</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback gắn khóa trang trí</th>
    <th>CG05106</th>
    <td>121</td>
    <td>34,949,228</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback in họa tiết vệt hoa</th>
    <th>CG09139</th>
    <td>45</td>
    <td>12,754,942</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback khoá trang trí</th>
    <th>CG05098</th>
    <td>7</td>
    <td>2,028,920</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày cao gót slingback mũi nhọn gót thanh
    </th>
    <th>CG07089</th>
    <td>13</td>
    <td>1,710,819</td>
  </tr>
  <tr>
    <th>CG07099</th>
    <td>30</td>
    <td>5,434,901</td>
  </tr>
  <tr>
    <th>CG09122</th>
    <td>16</td>
    <td>2,481,632</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi vuông gót trụ</th>
    <th>CG07096</th>
    <td>65</td>
    <td>10,590,269</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày Cao Gót Mũi Nhọn
    </th>
    <th>CG07086</th>
    <td>287</td>
    <td>79,485,920</td>
  </tr>
  <tr>
    <th>CG09113</th>
    <td>1</td>
    <td>257,206</td>
  </tr>
  <tr>
    <th>CG09115</th>
    <td>3</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày Pump gót sơn ánh nhũ</th>
    <th>CG09132</th>
    <td>27</td>
    <td>6,794,431</td>
  </tr>
  <tr>
    <th>Giày cao gót 5cm mũi nhọn CG05067</th>
    <th>CG05067</th>
    <td>1</td>
    <td>228,157</td>
  </tr>
  <tr>
    <th>Giày cao gót 5cm mũi nhọn phối màu CG05075</th>
    <th>CG05075</th>
    <td>82</td>
    <td>23,571,825</td>
  </tr>
  <tr>
    <th>Giày cao gót 5cm mũi tròn phối nơ CG05059</th>
    <th>CG05059</th>
    <td>106</td>
    <td>27,865,801</td>
  </tr>
  <tr>
    <th>Giày cao gót 7cm mũi nhọn CG07066</th>
    <th>CG07066</th>
    <td>1</td>
    <td>390,909</td>
  </tr>
  <tr>
    <th>Giày cao gót 7cm mũi nhọn gót vuông CG07056</th>
    <th>CG07056</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót 9cm mũi nhọn da bóng CG09104</th>
    <th>CG09104</th>
    <td>155</td>
    <td>39,371,389</td>
  </tr>
  <tr>
    <th>Giày cao gót 9cm mũi nhọn viền chỉ nổi thời trang CG09052</th>
    <th>CG09052</th>
    <td>1</td>
    <td>354,545</td>
  </tr>
  <tr>
    <th>Giày cao gót gót trụ quai chéo gắn khóa</th>
    <th>CG05114</th>
    <td>182</td>
    <td>52,074,078</td>
  </tr>
  <tr>
    <th>Giày cao gót họa tiết monogram</th>
    <th>CG11006</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót kim tuyến khoá trang trí</th>
    <th>CG09118</th>
    <td>6</td>
    <td>1,498,987</td>
  </tr>
  <tr>
    <th>Giày cao gót mules mũi nhọn khóa trang trí</th>
    <th>CG05095</th>
    <td>3</td>
    <td>718,854</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn</th>
    <th>CG09116</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót lượn</th>
    <th>CG07083</th>
    <td>86</td>
    <td>21,783,950</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót thanh</th>
    <th>CG07080</th>
    <td>108</td>
    <td>29,252,954</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn gót trụ</th>
    <th>CG05099</th>
    <td>7</td>
    <td>1,378,204</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn phối lưới</th>
    <th>CG07087</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi nhọn phối si tương phản</th>
    <th>CG09137</th>
    <td>57</td>
    <td>12,723,622</td>
  </tr>
  <tr>
    <th>Giày cao gót mũi tròn gót vuông</th>
    <th>CG05078</th>
    <td>1</td>
    <td>271,596</td>
  </tr>
  <tr>
    <th>Giày cao gót quai hậu xé dán</th>
    <th>CG07095</th>
    <td>5</td>
    <td>812,727</td>
  </tr>
  <tr>
    <th>Giày cao gót sling back mũi nhọn</th>
    <th>CG05082</th>
    <td>2</td>
    <td>332,273</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback khóa trang trí lục giác j</th>
    <th>CG07093</th>
    <td>10</td>
    <td>1,832,249</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi nhọn</th>
    <th>CG07094</th>
    <td>18</td>
    <td>2,882,556</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback mũi nhọn gót thanh</th>
    <th>CG09112</th>
    <td>36</td>
    <td>6,163,666</td>
  </tr>
  <tr>
    <th>Giày cao gót slingback phối si dập ly</th>
    <th>CG05102</th>
    <td>3</td>
    <td>901,591</td>
  </tr>
  <tr>
    <th>Giày mules bít mũi khóa trang trí đan lát</th>
    <th>CG07106</th>
    <td>141</td>
    <td>41,791,139</td>
  </tr>
  <tr>
    <th>Giày mules mũi nhọn gót trụ</th>
    <th>CG05097</th>
    <td>282</td>
    <td>84,270,274</td>
  </tr>
  <tr>
    <th rowspan="113" valign="top">
      Giày Sandal
    </th>
    <th>Dép 3 dây quai ngang</th>
    <th>SD01100</th>
    <td>3</td>
    <td>675,409</td>
  </tr>
  <tr>
    <th>Dép bệt mũi vuông khóa trang trí</th>
    <th>SD01107</th>
    <td>176</td>
    <td>47,151,774</td>
  </tr>
  <tr>
    <th>Dép bệt quai trang trí họa tiết mê cung</th>
    <th>SD03044</th>
    <td>154</td>
    <td>42,894,696</td>
  </tr>
  <tr>
    <th>Dép bệt quai xoắn</th>
    <th>SD01104</th>
    <td>15</td>
    <td>3,500,760</td>
  </tr>
  <tr>
    <th>Dép bệt quai đắp chéo phối pvc</th>
    <th>SD01102</th>
    <td>45</td>
    <td>9,844,520</td>
  </tr>
  <tr>
    <th>Dép bệt xỏ ngón trang trí khóa pu</th>
    <th>SD01103</th>
    <td>28</td>
    <td>7,015,580</td>
  </tr>
  <tr>
    <th>Dép bệt đan khoen trang trí</th>
    <th>SD01111</th>
    <td>143</td>
    <td>41,582,635</td>
  </tr>
  <tr>
    <th>Dép mũi vuông khóa trang trí</th>
    <th>SD01101</th>
    <td>65</td>
    <td>17,724,767</td>
  </tr>
  <tr>
    <th>Dép quai ngang gót sơn giả gỗ</th>
    <th>SD01096</th>
    <td>2</td>
    <td>472,406</td>
  </tr>
  <tr>
    <th>Dép quai ngang mũi vuông</th>
    <th>SD01095</th>
    <td>26</td>
    <td>4,524,007</td>
  </tr>
  <tr>
    <th>Dép quai ngang phối ánh kim</th>
    <th>SD01109</th>
    <td>145</td>
    <td>35,871,438</td>
  </tr>
  <tr>
    <th>Frozen Walk</th>
    <th>SD07066</th>
    <td>76</td>
    <td>24,037,435</td>
  </tr>
  <tr>
    <th>Giày mules mũi vuông gót phối kim loại</th>
    <th>SD07067</th>
    <td>187</td>
    <td>51,160,185</td>
  </tr>
  <tr>
    <th>Giày sandal bệt gắn khóa trang trí</th>
    <th>SD01097</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày sandal bệt mũi vuông</th>
    <th>SD01105</th>
    <td>15</td>
    <td>3,314,982</td>
  </tr>
  <tr>
    <th>Giày sandal bệt quai chéo</th>
    <th>SD01110</th>
    <td>38</td>
    <td>10,350,097</td>
  </tr>
  <tr>
    <th>Giày sandal bệt quai ngang khóa trang trí</th>
    <th>SD01108</th>
    <td>111</td>
    <td>31,104,700</td>
  </tr>
  <tr>
    <th>Giày sandal bệt xỏ ngón</th>
    <th>SD01099</th>
    <td>2</td>
    <td>634,359</td>
  </tr>
  <tr>
    <th>Giày sandal bệt xỏ ngón quai chéo</th>
    <th>SD01106</th>
    <td>7</td>
    <td>1,122,727</td>
  </tr>
  <tr>
    <th>Giày sandal bệt đế giả gỗ</th>
    <th>SD01098</th>
    <td>29</td>
    <td>7,541,619</td>
  </tr>
  <tr>
    <th>Giày sandal dây bất đối xứng</th>
    <th>SD11010</th>
    <td>11</td>
    <td>1,295,909</td>
  </tr>
  <tr>
    <th>Giày sandal dây hậu đan chéo</th>
    <th>SD07053</th>
    <td>16</td>
    <td>2,194,591</td>
  </tr>
  <tr>
    <th>Giày sandal dây mảnh</th>
    <th>SD07063</th>
    <td>98</td>
    <td>29,600,105</td>
  </tr>
  <tr>
    <th>Giày sandal dây mảnh gót thanh</th>
    <th>SD09092</th>
    <td>55</td>
    <td>16,888,218</td>
  </tr>
  <tr>
    <th>Giày sandal gót chữ a</th>
    <th>SD07064</th>
    <td>94</td>
    <td>28,672,610</td>
  </tr>
  <tr>
    <th>Giày sandal gót cách điệu 2 màu</th>
    <th>SD07065</th>
    <td>41</td>
    <td>10,923,970</td>
  </tr>
  <tr>
    <th>Giày sandal gót hình học</th>
    <th>SD09074</th>
    <td>116</td>
    <td>33,597,166</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal gót sơn chuyển màu
    </th>
    <th>SD09094</th>
    <td>65</td>
    <td>20,458,416</td>
  </tr>
  <tr>
    <th>SD11013</th>
    <td>7</td>
    <td>2,034,919</td>
  </tr>
  <tr>
    <th>Giày sandal gót sơn giả gỗ</th>
    <th>SD11009</th>
    <td>1</td>
    <td>313,636</td>
  </tr>
  <tr>
    <th>Giày sandal gót sơn ombre</th>
    <th>SD07040</th>
    <td>25</td>
    <td>2,665,455</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh gắn xích trang trí</th>
    <th>SD09085</th>
    <td>78</td>
    <td>24,137,254</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh phối si dập ly</th>
    <th>SD11011</th>
    <td>1</td>
    <td>280,303</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh quai mảnh bất đối xứng</th>
    <th>SD09097</th>
    <td>54</td>
    <td>16,334,344</td>
  </tr>
  <tr>
    <th>Giày sandal gót thanh đế đúp</th>
    <th>SD09078</th>
    <td>20</td>
    <td>3,749,665</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong có màu</th>
    <th>SD07052</th>
    <td>4</td>
    <td>785,533</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong quai ngang</th>
    <th>SD09082</th>
    <td>2</td>
    <td>499,500</td>
  </tr>
  <tr>
    <th>Giày sandal gót trong sơn ombre</th>
    <th>SD09090</th>
    <td>55</td>
    <td>16,102,452</td>
  </tr>
  <tr>
    <th>Giày sandal gót trụ phối trung tì kim tuyến</th>
    <th>SD09088</th>
    <td>23</td>
    <td>7,777,796</td>
  </tr>
  <tr>
    <th>Giày sandal gót trụ sơn giả gỗ</th>
    <th>SD07041</th>
    <td>1</td>
    <td>320,381</td>
  </tr>
  <tr>
    <th>Giày sandal gót trụ điêu khắc</th>
    <th>SD07055</th>
    <td>2</td>
    <td>543,636</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông</th>
    <th>SD09070</th>
    <td>14</td>
    <td>3,886,412</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông mũi vuông</th>
    <th>SD05055</th>
    <td>10</td>
    <td>1,809,763</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông chuyển màu</th>
    <th>SD09071</th>
    <td>127</td>
    <td>36,153,593</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông khóa sơn cùng màu</th>
    <th>SD09075</th>
    <td>1</td>
    <td>262,470</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông phối si hologram</th>
    <th>SD07038</th>
    <td>1</td>
    <td>326,842</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông quai bất đối xứng</th>
    <th>SD05066</th>
    <td>103</td>
    <td>32,533,894</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông quai mặt bất đối xứng</th>
    <th>SD05044</th>
    <td>24</td>
    <td>4,631,348</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal gót vuông quai ngang
    </th>
    <th>SD07046</th>
    <td>16</td>
    <td>3,374,014</td>
  </tr>
  <tr>
    <th>SD09084</th>
    <td>2</td>
    <td>575,240</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông sơn lăn giả gỗ</th>
    <th>SD05062</th>
    <td>7</td>
    <td>1,139,091</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông viền kim loại, quai mảnh</th>
    <th>SD05063</th>
    <td>84</td>
    <td>25,325,063</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông đính hoa trang trí</th>
    <th>SD05052</th>
    <td>4</td>
    <td>638,860</td>
  </tr>
  <tr>
    <th>Giày sandal gót vuông đính hạt trang trí</th>
    <th>SD09080</th>
    <td>12</td>
    <td>2,320,324</td>
  </tr>
  <tr>
    <th>Giày sandal gót ánh kim</th>
    <th>SD11007</th>
    <td>24</td>
    <td>5,477,840</td>
  </tr>
  <tr>
    <th>Giày sandal gót đá 4 màu</th>
    <th>SD05057</th>
    <td>2</td>
    <td>706,570</td>
  </tr>
  <tr>
    <th>Giày sandal họa tiết hoa khô</th>
    <th>SD09077</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Giày sandal hở mũi có dây cổ chân</th>
    <th>SD01094</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Giày sandal khóa gài</th>
    <th>SD07068</th>
    <td>37</td>
    <td>11,657,942</td>
  </tr>
  <tr>
    <th>Giày sandal kim tuyến gót thô</th>
    <th>SD11012</th>
    <td>82</td>
    <td>26,312,363</td>
  </tr>
  <tr>
    <th>Giày sandal mũi tròn quai mảnh bất đối xứng</th>
    <th>SD03045</th>
    <td>138</td>
    <td>39,186,497</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông dây mảnh</th>
    <th>SD07036</th>
    <td>2</td>
    <td>508,270</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal mũi vuông gót thanh
    </th>
    <th>SD09086</th>
    <td>35</td>
    <td>10,144,965</td>
  </tr>
  <tr>
    <th>SD09091</th>
    <td>12</td>
    <td>3,516,403</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal mũi vuông gót vuông
    </th>
    <th>SD07039</th>
    <td>13</td>
    <td>1,303,818</td>
  </tr>
  <tr>
    <th>SD09083</th>
    <td>56</td>
    <td>16,554,176</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông gót vuông ánh kim loại</th>
    <th>SD07045</th>
    <td>6</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông in họa tiết vệt hoa</th>
    <th>SD09089</th>
    <td>15</td>
    <td>4,518,790</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Giày sandal mũi vuông khóa trang trí
    </th>
    <th>SD03040</th>
    <td>8</td>
    <td>824,401</td>
  </tr>
  <tr>
    <th>SD07044</th>
    <td>169</td>
    <td>48,767,812</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông phối si vân</th>
    <th>SD07060</th>
    <td>103</td>
    <td>25,321,371</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông quai bất đối xứng</th>
    <th>SD07062</th>
    <td>50</td>
    <td>13,936,025</td>
  </tr>
  <tr>
    <th>Giày sandal mũi vuông quai ngang</th>
    <th>SD07047</th>
    <td>7</td>
    <td>970,227</td>
  </tr>
  <tr>
    <th>Giày sandal phối chuyển màu</th>
    <th>SD05045</th>
    <td>1</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày sandal phối dây kim tuyến</th>
    <th>SD07059</th>
    <td>15</td>
    <td>3,780,680</td>
  </tr>
  <tr>
    <th>Giày sandal phối dây vải sọc</th>
    <th>SD07051</th>
    <td>38</td>
    <td>6,608,311</td>
  </tr>
  <tr>
    <th>Giày sandal phối pvc gót trong</th>
    <th>SD07056</th>
    <td>70</td>
    <td>21,717,197</td>
  </tr>
  <tr>
    <th>Giày sandal phối si kim tuyến</th>
    <th>SD09087</th>
    <td>11</td>
    <td>3,128,791</td>
  </tr>
  <tr>
    <th>Giày sandal quai 3 dây xé dán</th>
    <th>SD05065</th>
    <td>81</td>
    <td>23,580,134</td>
  </tr>
  <tr>
    <th>Giày sandal quai mũi ngang giả xé dán</th>
    <th>SD07050</th>
    <td>24</td>
    <td>3,551,939</td>
  </tr>
  <tr>
    <th>Giày sandal quai mũi đan khoen trang trí</th>
    <th>SD05067</th>
    <td>91</td>
    <td>29,869,822</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang dây chéo</th>
    <th>SD07042</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang gót trong sơn ánh nhũ</th>
    <th>SD09096</th>
    <td>72</td>
    <td>20,168,699</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khoá trang trí</th>
    <th>SD07061</th>
    <td>134</td>
    <td>39,251,592</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khoá xé dán</th>
    <th>SD03041</th>
    <td>100</td>
    <td>30,801,086</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang khóa trang trí</th>
    <th>SD05064</th>
    <td>195</td>
    <td>55,774,730</td>
  </tr>
  <tr>
    <th>Giày sandal quai ngang phối hai chất liệu</th>
    <th>SD07049</th>
    <td>3</td>
    <td>818,204</td>
  </tr>
  <tr>
    <th>Giày sandal quai thắt nút</th>
    <th>SD07058</th>
    <td>11</td>
    <td>2,357,585</td>
  </tr>
  <tr>
    <th>Giày sandal sandal mũi vuông gót vuông</th>
    <th>SD07043</th>
    <td>1</td>
    <td>269,763</td>
  </tr>
  <tr>
    <th>Giày sandal thể thao 2 quai</th>
    <th>SD03039</th>
    <td>60</td>
    <td>18,014,531</td>
  </tr>
  <tr>
    <th>Giày sandal thể thao dây chéo</th>
    <th>SD03042</th>
    <td>75</td>
    <td>22,215,971</td>
  </tr>
  <tr>
    <th>Giày sandal xuồng quai mũi ngang phối pvc</th>
    <th>SD07054</th>
    <td>13</td>
    <td>2,711,304</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao</th>
    <th>SD05058</th>
    <td>1</td>
    <td>135,455</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao gắn khóa trang trí</th>
    <th>SD05061</th>
    <td>63</td>
    <td>19,560,558</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao phối pvc trong</th>
    <th>SD05054</th>
    <td>2</td>
    <td>507,751</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao quai chéo</th>
    <th>SD05059</th>
    <td>57</td>
    <td>12,291,829</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao quai mảnh</th>
    <th>SD05060</th>
    <td>137</td>
    <td>40,211,179</td>
  </tr>
  <tr>
    <th>Giày sandal đế thể thao đan khoen trang trí</th>
    <th>SD05068</th>
    <td>175</td>
    <td>51,396,387</td>
  </tr>
  <tr>
    <th>Giày sandal đế xuồng đính hạt trang trí</th>
    <th>SD09079</th>
    <td>12</td>
    <td>1,936,869</td>
  </tr>
  <tr>
    <th>Giày sandal đế xuồng, quai chéo đối xứng</th>
    <th>SD07057</th>
    <td>38</td>
    <td>11,399,102</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp</th>
    <th>SD09073</th>
    <td>21</td>
    <td>6,052,641</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp bất đối xứng</th>
    <th>SD09081</th>
    <td>3</td>
    <td>807,273</td>
  </tr>
  <tr>
    <th>Giày sandal đế đúp gót thanh</th>
    <th>SD09093</th>
    <td>42</td>
    <td>13,597,277</td>
  </tr>
  <tr>
    <th>Giày xăng đan gót trụ 5cm phối nhũ SD05032</th>
    <th>SD05032</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Giày xăng đan gót trụ trong suốt 5cm SD05030</th>
    <th>SD05030</th>
    <td>1</td>
    <td>268,346</td>
  </tr>
  <tr>
    <th>Giày xăng đan gót vuông 5cm quai ngang SD05003</th>
    <th>SD05003</th>
    <td>166</td>
    <td>38,652,643</td>
  </tr>
  <tr>
    <th>Giày xăng đan gót vuông hoạ tiết dệt gấm "Ngẫu Hứng Thời Gian" - SD05033</th>
    <th>SD05033</th>
    <td>1</td>
    <td>279,035</td>
  </tr>
  <tr>
    <th>Giày xăng đan quai ngang trang trí khoá kim loại SD03012</th>
    <th>SD03012</th>
    <td>47</td>
    <td>11,224,714</td>
  </tr>
  <tr>
    <th>Giày xăng đan quai ngang đế thể thao SD05028</th>
    <th>SD05028</th>
    <td>122</td>
    <td>35,817,900</td>
  </tr>
  <tr>
    <th>Giày xăng đan đế bệt quai ngang SD01081</th>
    <th>SD01081</th>
    <td>85</td>
    <td>22,883,522</td>
  </tr>
  <tr>
    <th>Jumping Snowflakes</th>
    <th>SD03043</th>
    <td>97</td>
    <td>29,132,079</td>
  </tr>
  <tr>
    <th>Lady-like-ice</th>
    <th>SD09095</th>
    <td>102</td>
    <td>32,968,366</td>
  </tr>
  <tr>
    <th>Xăng đan LẠC QUAN SD07027 - BST Bước Tới Vì Sao</th>
    <th>SD07027</th>
    <td>55</td>
    <td>15,955,792</td>
  </tr>
  <tr>
    <th rowspan="24" valign="top">
      Giày Thể Thao
    </th>
    <th>Classic Side by Side</th>
    <th>TT03030</th>
    <td>125</td>
    <td>39,610,094</td>
  </tr>
  <tr>
    <th>Comfy Easy Lazy</th>
    <th>TT03031</th>
    <td>168</td>
    <td>52,457,511</td>
  </tr>
  <tr>
    <th>Comfy Moon Walk</th>
    <th>TT03029</th>
    <td>122</td>
    <td>38,688,875</td>
  </tr>
  <tr>
    <th>Giày Thể Thao Classic</th>
    <th>TT03028</th>
    <td>18</td>
    <td>5,263,289</td>
  </tr>
  <tr>
    <th>Giày Thể Thao Classic Stick with me</th>
    <th>TT03032</th>
    <td>43</td>
    <td>14,959,382</td>
  </tr>
  <tr>
    <th>Giày thể thao classic TT05004</th>
    <th>TT05004</th>
    <td>33</td>
    <td>14,279,334</td>
  </tr>
  <tr>
    <th>Giày thể thao phong cách oxford</th>
    <th>TT03027</th>
    <td>9</td>
    <td>2,539,719</td>
  </tr>
  <tr>
    <th>Giày thể thao đế cao</th>
    <th>TT05003</th>
    <td>46</td>
    <td>18,504,501</td>
  </tr>
  <tr>
    <th>Giày thể thao đế chunky</th>
    <th>TT07001</th>
    <td>86</td>
    <td>39,335,963</td>
  </tr>
  <tr>
    <th>Juno Active Starter 1</th>
    <th>TT03021</th>
    <td>45</td>
    <td>9,304,907</td>
  </tr>
  <tr>
    <th>Juno Sneakers Active Model TT03008 - Mesh Basic</th>
    <th>TT03008</th>
    <td>2</td>
    <td>806,513</td>
  </tr>
  <tr>
    <th>Juno Sneakers Active Model TT03016 - Mesh Basic</th>
    <th>TT03016</th>
    <td>1</td>
    <td>500,000</td>
  </tr>
  <tr>
    <th>Juno Sneakers Soft Model TT03020 - Knit Basic</th>
    <th>TT03020</th>
    <td>61</td>
    <td>18,906,365</td>
  </tr>
  <tr>
    <th>Juno active starter 3</th>
    <th>TT03023</th>
    <td>35</td>
    <td>9,958,428</td>
  </tr>
  <tr>
    <th>Juno active starter 4</th>
    <th>TT03024</th>
    <td>24</td>
    <td>6,222,579</td>
  </tr>
  <tr>
    <th>Juno active starter 6</th>
    <th>TT05002</th>
    <td>26</td>
    <td>10,046,557</td>
  </tr>
  <tr>
    <th>Sneaker jelly</th>
    <th>TT03033</th>
    <td>136</td>
    <td>49,234,473</td>
  </tr>
  <tr>
    <th>Sneakers Ocean Wave</th>
    <th>TT05007</th>
    <td>154</td>
    <td>69,899,394</td>
  </tr>
  <tr>
    <th>Sneakers Rush Crush</th>
    <th>TT03034</th>
    <td>260</td>
    <td>86,388,198</td>
  </tr>
  <tr>
    <th>Trendy Bridging Chunky</th>
    <th>TT07003</th>
    <td>70</td>
    <td>27,074,421</td>
  </tr>
  <tr>
    <th>Trendy Elevated Flow</th>
    <th>TT05005</th>
    <td>104</td>
    <td>41,255,414</td>
  </tr>
  <tr>
    <th>Trendy Illuminated Chunky</th>
    <th>TT07002</th>
    <td>116</td>
    <td>54,509,559</td>
  </tr>
  <tr>
    <th>Trendy Levitated Chunky</th>
    <th>TT05006</th>
    <td>192</td>
    <td>83,760,916</td>
  </tr>
  <tr>
    <th>juno active starter 5</th>
    <th>TT03026</th>
    <td>48</td>
    <td>9,423,575</td>
  </tr>
  <tr>
    <th rowspan="3" valign="top">
      Giày Đế Xuồng
    </th>
    <th rowspan="2" valign="top">
      Giày đế xuồng giả gỗ
    </th>
    <th>DX07019</th>
    <td>3</td>
    <td>449,091</td>
  </tr>
  <tr>
    <th>DX07020</th>
    <td>22</td>
    <td>3,507,058</td>
  </tr>
  <tr>
    <th>Giày đế xuồng quai mảnh</th>
    <th>DX07018</th>
    <td>7</td>
    <td>1,252,944</td>
  </tr>
  <tr>
    <th rowspan="189" valign="top">
      Túi xách
    </th>
    <th rowspan="18" valign="top">
      Balo
    </th>
    <th>Balo 2 ngăn có túi nhỏ trang trí phía trước</th>
    <th>BL109</th>
    <td>43</td>
    <td>16,046,976</td>
  </tr>
  <tr>
    <th>Balo bucket quai đeo vai</th>
    <th>BL101</th>
    <td>1</td>
    <td>489,782</td>
  </tr>
  <tr>
    <th>Balo buckle phối si vân</th>
    <th>BL111</th>
    <td>16</td>
    <td>6,679,437</td>
  </tr>
  <tr>
    <th>Balo chần bông 3 tông màu</th>
    <th>BL110</th>
    <td>10</td>
    <td>3,212,075</td>
  </tr>
  <tr>
    <th>Balo gắn charm pixel</th>
    <th>BL116</th>
    <td>158</td>
    <td>67,758,114</td>
  </tr>
  <tr>
    <th>Balo khoá scrunchies</th>
    <th>BL120</th>
    <td>126</td>
    <td>60,145,858</td>
  </tr>
  <tr>
    <th>Balo mini trang trí dây xích</th>
    <th>BL113</th>
    <td>2</td>
    <td>757,964</td>
  </tr>
  <tr>
    <th>Balo monogram</th>
    <th>BL095</th>
    <td>1</td>
    <td>442,815</td>
  </tr>
  <tr>
    <th>Balo nhiều ngăn</th>
    <th>BL118</th>
    <td>18</td>
    <td>10,407,011</td>
  </tr>
  <tr>
    <th>Balo phom đứng hoạ tiết 3D BL063</th>
    <th>BL063</th>
    <td>288</td>
    <td>118,252,693</td>
  </tr>
  <tr>
    <th>Balo phong cách minimal</th>
    <th>BL114</th>
    <td>29</td>
    <td>11,572,458</td>
  </tr>
  <tr>
    <th>Balo size mini năng động</th>
    <th>BL104</th>
    <td>197</td>
    <td>77,332,874</td>
  </tr>
  <tr>
    <th>Balo sơn viền 2 kiểu đeo</th>
    <th>BL108</th>
    <td>1</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Balo tay cầm nhún</th>
    <th>BL119</th>
    <td>296</td>
    <td>128,673,196</td>
  </tr>
  <tr>
    <th>Balo thời trang Juno hoạ tiết đinh tán BL008</th>
    <th>BL008</th>
    <td>1</td>
    <td>718,182</td>
  </tr>
  <tr>
    <th>Balo top handle</th>
    <th>BL117</th>
    <td>214</td>
    <td>93,519,342</td>
  </tr>
  <tr>
    <th>Balo tròn khóa bấm</th>
    <th>BL112</th>
    <td>26</td>
    <td>9,644,334</td>
  </tr>
  <tr>
    <th>Frost Bites Crumbly</th>
    <th>BL115</th>
    <td>2</td>
    <td>838,657</td>
  </tr>
  <tr>
    <th rowspan="10" valign="top">
      Túi xách lớn
    </th>
    <th>Túi Tote laser cut</th>
    <th>TXL055</th>
    <td>33</td>
    <td>12,428,144</td>
  </tr>
  <tr>
    <th>Túi cỡ lớn hoạ tiết 3D TXL025</th>
    <th>TXL025</th>
    <td>155</td>
    <td>64,798,732</td>
  </tr>
  <tr>
    <th>Túi xách cỡ lớn trang trí phụ kiện tua rua TXL006</th>
    <th>TXL006</th>
    <td>1</td>
    <td>681,818</td>
  </tr>
  <tr>
    <th>Túi xách lớn 2 ngăn</th>
    <th>TXL057</th>
    <td>3</td>
    <td>1,331,427</td>
  </tr>
  <tr>
    <th>Túi xách lớn top handle</th>
    <th>TXL060</th>
    <td>128</td>
    <td>59,367,776</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote gắn charm pixel</th>
    <th>TXL059</th>
    <td>31</td>
    <td>14,611,425</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote ngăn lớn</th>
    <th>TXL064</th>
    <td>5</td>
    <td>2,796,955</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote thân xếp</th>
    <th>TXL063</th>
    <td>2</td>
    <td>737,182</td>
  </tr>
  <tr>
    <th>Túi xách lớn tote tối giản</th>
    <th>TXL058</th>
    <td>272</td>
    <td>123,822,223</td>
  </tr>
  <tr>
    <th>Túi xách lớn trapeze phối hai chất liệu</th>
    <th>TXL062</th>
    <td>2</td>
    <td>908,067</td>
  </tr>
  <tr>
    <th rowspan="132" valign="top">
      Túi xách nhỏ
    </th>
    <th>Freezing Fluffy</th>
    <th>TXN474</th>
    <td>21</td>
    <td>7,731,705</td>
  </tr>
  <tr>
    <th>Handy Sweetest Clutch</th>
    <th>TXN476</th>
    <td>13</td>
    <td>3,000,043</td>
  </tr>
  <tr>
    <th>Summer Cool Dessert</th>
    <th>TXN475</th>
    <td>24</td>
    <td>10,500,086</td>
  </tr>
  <tr>
    <th>TXN và Clutch 2in1 trang trí khóa kim loại</th>
    <th>TXN482</th>
    <td>58</td>
    <td>23,222,726</td>
  </tr>
  <tr>
    <th>Túi Mickey cơ bản</th>
    <th>TXN381</th>
    <td>4</td>
    <td>1,248,832</td>
  </tr>
  <tr>
    <th>Túi Mini Duffle gắn charm pixel</th>
    <th>TXN478</th>
    <td>71</td>
    <td>29,417,384</td>
  </tr>
  <tr>
    <th>Túi hộp Mickey đeo chéo</th>
    <th>TXN380</th>
    <td>1</td>
    <td>425,432</td>
  </tr>
  <tr>
    <th>Túi nhỏ nắp gập phối khoá kim loại TXN217</th>
    <th>TXN217</th>
    <td>2</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ 1 quai phối xích trong suốt</th>
    <th>TXN408</th>
    <td>18</td>
    <td>5,647,462</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ Minnie 2 nắp có hoa văn</th>
    <th>TXN447</th>
    <td>24</td>
    <td>10,996,968</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ Minnie chần bông có gắn nút</th>
    <th>TXN446</th>
    <td>14</td>
    <td>6,405,635</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ Minnie gắn charm minnie</th>
    <th>TXN445</th>
    <td>2</td>
    <td>802,227</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ buckle flap
    </th>
    <th>TXN480</th>
    <td>115</td>
    <td>50,056,939</td>
  </tr>
  <tr>
    <th>TXN502</th>
    <td>9</td>
    <td>4,523,136</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ buckled trapeze</th>
    <th>TXN397</th>
    <td>21</td>
    <td>8,527,676</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ bán nguyệt dây đeo phối xích</th>
    <th>TXN421</th>
    <td>1</td>
    <td>402,334</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ camera cầm tay đính charm</th>
    <th>TXN400</th>
    <td>61</td>
    <td>17,903,850</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ chân bông 3 tông màu</th>
    <th>TXN430</th>
    <td>6</td>
    <td>2,367,945</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ chần bông dạng hộp 3 tông màu</th>
    <th>TXN429</th>
    <td>9</td>
    <td>2,069,990</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ chần bông nắp gập</th>
    <th>TXN296</th>
    <td>79</td>
    <td>29,451,118</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ clutch chần bông
    </th>
    <th>TXN371</th>
    <td>1</td>
    <td>403,636</td>
  </tr>
  <tr>
    <th>TXN379</th>
    <td>11</td>
    <td>1,430,234</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ clutch có 2 thiết kế dây đeo</th>
    <th>TXN449</th>
    <td>2</td>
    <td>827,659</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ cut-out khoá trang trí</th>
    <th>TXN427</th>
    <td>22</td>
    <td>9,817,898</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ có bản to cầm tay trên nắp</th>
    <th>TXN444</th>
    <td>2</td>
    <td>802,227</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ có khóa trang trí trong suốt</th>
    <th>TXN409</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ cạnh hông tam giác</th>
    <th>TXN497</th>
    <td>3</td>
    <td>1,887,500</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ cạnh hông xếp nhiều ngăn</th>
    <th>TXN423</th>
    <td>12</td>
    <td>4,932,715</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng accordion</th>
    <th>TXN419</th>
    <td>2</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng hộp dây xích cầm tay</th>
    <th>TXN405</th>
    <td>67</td>
    <td>29,963,352</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng hộp top-handle</th>
    <th>TXN470</th>
    <td>172</td>
    <td>73,081,198</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dáng vuông TXN219</th>
    <th>TXN219</th>
    <td>1</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dây đan đeo vai khóa xoay</th>
    <th>TXN395</th>
    <td>40</td>
    <td>14,709,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dây đeo đầu chữ T</th>
    <th>TXN424</th>
    <td>14</td>
    <td>5,709,683</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp</th>
    <th>TXN467</th>
    <td>203</td>
    <td>89,586,514</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp chần bông trang trí xích</th>
    <th>TXN452</th>
    <td>4</td>
    <td>1,580,252</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dạng hộp mini đeo chéo</th>
    <th>TXN390</th>
    <td>4</td>
    <td>963,245</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ dập hiệu ứng princess diamond
    </th>
    <th>TXN416</th>
    <td>76</td>
    <td>33,043,076</td>
  </tr>
  <tr>
    <th>TXN417</th>
    <td>354</td>
    <td>160,326,809</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ dập nổi họa tiết đối xứng</th>
    <th>TXN443</th>
    <td>64</td>
    <td>28,612,975</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn charn pixel</th>
    <th>TXN477</th>
    <td>120</td>
    <td>51,526,314</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn dây xích trang trí</th>
    <th>TXN431</th>
    <td>1</td>
    <td>386,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ gắn khóa trang trí</th>
    <th>TXN432</th>
    <td>43</td>
    <td>19,428,651</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hai ngăn treo charm trang trí</th>
    <th>TXN505</th>
    <td>9</td>
    <td>4,936,227</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ half-moon phối micro-pleat</th>
    <th>TXN418</th>
    <td>2</td>
    <td>623,724</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hobo trang trí khóa kim loại</th>
    <th>TXN457</th>
    <td>49</td>
    <td>18,730,676</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hình hộp xích trang trí</th>
    <th>TXN498</th>
    <td>6</td>
    <td>2,834,682</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp top-handle</th>
    <th>TXN466</th>
    <td>46</td>
    <td>20,275,621</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp trang trí hiệu ứng dập nổi</th>
    <th>TXN463</th>
    <td>105</td>
    <td>47,049,707</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp trang trí khóa kim loại</th>
    <th>TXN455</th>
    <td>7</td>
    <td>3,167,952</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ hộp trang trí khóa xoay</th>
    <th>TXN469</th>
    <td>13</td>
    <td>3,779,487</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá logo trang trí</th>
    <th>TXN435</th>
    <td>39</td>
    <td>17,703,481</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá marble</th>
    <th>TXN493</th>
    <td>96</td>
    <td>38,077,514</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá scrunchies</th>
    <th>TXN495</th>
    <td>77</td>
    <td>34,477,264</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá trang trí lục giác</th>
    <th>TXN411</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá trang trí tròn</th>
    <th>TXN422</th>
    <td>1</td>
    <td>410,658</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khoá xoắn trang trí</th>
    <th>TXN433</th>
    <td>32</td>
    <td>12,808,990</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khóa trang trí in họa tiết graffiti</th>
    <th>TXN436</th>
    <td>13</td>
    <td>4,369,691</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ khóa trượt</th>
    <th>TXN415</th>
    <td>12</td>
    <td>4,294,291</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ laser cut nhỏ</th>
    <th>TXN426</th>
    <td>10</td>
    <td>4,166,283</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ mini circle charm</th>
    <th>TXN496</th>
    <td>269</td>
    <td>103,463,326</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ mini tay cầm scrunchies</th>
    <th>TXN494</th>
    <td>83</td>
    <td>33,080,606</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ mini trang trí hiệu ứng dập nổi</th>
    <th>TXN462</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ minnie hình hộp</th>
    <th>TXN448</th>
    <td>29</td>
    <td>9,194,436</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ multi-bag</th>
    <th>TXN506</th>
    <td>9</td>
    <td>5,434,909</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ multi-bag nắp tuck-in</th>
    <th>TXN460</th>
    <td>73</td>
    <td>26,688,804</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nhỏ nắp tuck-in</th>
    <th>TXN461</th>
    <td>23</td>
    <td>9,843,323</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp bất đối xứng</th>
    <th>TXN510</th>
    <td>10</td>
    <td>5,388,864</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp cách điệu trang trí khóa kim loại</th>
    <th>TXN481</th>
    <td>67</td>
    <td>29,811,815</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp dập nổi</th>
    <th>TXN425</th>
    <td>29</td>
    <td>12,117,816</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gấp nếp</th>
    <th>TXN440</th>
    <td>1</td>
    <td>777,273</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập 2 tone màu</th>
    <th>TXN369</th>
    <td>5</td>
    <td>808,698</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập dập nổi 3d</th>
    <th>TXN313</th>
    <td>169</td>
    <td>72,365,579</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập phối màu</th>
    <th>TXN508</th>
    <td>7</td>
    <td>3,400,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập treo charm trang trí</th>
    <th>TXN503</th>
    <td>4</td>
    <td>2,271,864</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp gập đeo vai</th>
    <th>TXN368</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp lượn</th>
    <th>TXN501</th>
    <td>1</td>
    <td>537,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp lượn khoá xoay</th>
    <th>TXN500</th>
    <td>1</td>
    <td>537,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp pixel-cut</th>
    <th>TXN479</th>
    <td>65</td>
    <td>27,677,122</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp xéo đường chỉ nổi</th>
    <th>TXN428</th>
    <td>21</td>
    <td>8,633,813</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ nắp đắp lớp</th>
    <th>TXN439</th>
    <td>5</td>
    <td>2,465,795</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phun hiệu ứng loang màu</th>
    <th>TXN459</th>
    <td>16</td>
    <td>6,312,983</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối màu colorblock</th>
    <th>TXN396</th>
    <td>1</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối 2 chất liệu khóa trang trí đính đá Baguette</th>
    <th>TXN392</th>
    <td>1</td>
    <td>402,334</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối 2 chất liệu khóa trang trí đính đá Princess</th>
    <th>TXN391</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối 2 màu</th>
    <th>TXN294</th>
    <td>12</td>
    <td>4,468,627</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối color block</th>
    <th>TXN386</th>
    <td>51</td>
    <td>11,768,335</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối hiệu ứng giả đan lát</th>
    <th>TXN472</th>
    <td>267</td>
    <td>115,829,797</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ phối khoá cùng màu
    </th>
    <th>TXN387</th>
    <td>30</td>
    <td>10,843,783</td>
  </tr>
  <tr>
    <th>TXN388</th>
    <td>3</td>
    <td>499,091</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối màu color-block</th>
    <th>TXN458</th>
    <td>30</td>
    <td>11,628,333</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ phối màu cách điệu</th>
    <th>TXN454</th>
    <td>26</td>
    <td>9,133,236</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm cách điệu</th>
    <th>TXN384</th>
    <td>67</td>
    <td>26,294,726</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm kim loại chữ d</th>
    <th>TXN374</th>
    <td>3</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm nhún</th>
    <th>TXN492</th>
    <td>333</td>
    <td>145,286,792</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm rời</th>
    <th>TXN486</th>
    <td>136</td>
    <td>60,320,136</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay cầm trong suốt</th>
    <th>TXN410</th>
    <td>5</td>
    <td>2,182,288</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tay c��m cách điệu</th>
    <th>TXN376</th>
    <td>1</td>
    <td>404,155</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ the maze</th>
    <th>TXN483</th>
    <td>31</td>
    <td>12,365,839</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ thân chữ a</th>
    <th>TXN453</th>
    <td>10</td>
    <td>4,335,468</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ thân xếp khối</th>
    <th>TXN442</th>
    <td>91</td>
    <td>36,912,569</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle</th>
    <th>TXN403</th>
    <td>118</td>
    <td>52,392,620</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle cách điệu</th>
    <th>TXN456</th>
    <td>8</td>
    <td>3,012,451</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle dáng hộp</th>
    <th>TXN491</th>
    <td>179</td>
    <td>75,130,198</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ top handle khóa kim loại raw</th>
    <th>TXN487</th>
    <td>101</td>
    <td>44,772,030</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tope handle dập nổi viền</th>
    <th>TXN465</th>
    <td>44</td>
    <td>18,795,907</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tote nhỏ nắp cách điệu</th>
    <th>TXN451</th>
    <td>12</td>
    <td>5,191,762</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ tote nhỏ treo charm trang trí</th>
    <th>TXN504</th>
    <td>3</td>
    <td>1,669,500</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí hiệu ứng dập nổi</th>
    <th>TXN464</th>
    <td>73</td>
    <td>32,191,373</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí khóa the maze</th>
    <th>TXN484</th>
    <td>55</td>
    <td>25,209,929</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí khóa xoay</th>
    <th>TXN468</th>
    <td>242</td>
    <td>107,438,083</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí logo cách điệu</th>
    <th>TXN471</th>
    <td>167</td>
    <td>71,166,768</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ trang trí viền</th>
    <th>TXN406</th>
    <td>12</td>
    <td>2,201,630</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ túi đeo chéo gắn charm trang trí</th>
    <th>TXN401</th>
    <td>57</td>
    <td>20,060,721</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ viền kim tuyến trên nắp</th>
    <th>TXN438</th>
    <td>3</td>
    <td>747,427</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ và clutch 2in1</th>
    <th>TXN485</th>
    <td>38</td>
    <td>14,016,863</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đan cạnh viền</th>
    <th>TXN490</th>
    <td>102</td>
    <td>43,203,812</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chiếu hiệu ứng đan lát</th>
    <th>TXN394</th>
    <td>7</td>
    <td>723,636</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo khóa bấm</th>
    <th>TXN414</th>
    <td>193</td>
    <td>83,427,977</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo khóa gài</th>
    <th>TXN413</th>
    <td>28</td>
    <td>3,948,003</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo nắp gập</th>
    <th>TXN420</th>
    <td>128</td>
    <td>48,939,538</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo nắp viền dây kim loại</th>
    <th>TXN488</th>
    <td>47</td>
    <td>19,617,587</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo chéo phối pvc màu</th>
    <th>TXN407</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách nhỏ đeo chéo tối giản
    </th>
    <th>TXN404</th>
    <td>7</td>
    <td>1,196,991</td>
  </tr>
  <tr>
    <th>TXN450</th>
    <td>179</td>
    <td>79,337,001</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai có 2 thiết kế dây đeo</th>
    <th>TXN441</th>
    <td>65</td>
    <td>28,128,079</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai hiệu ứng đẹp mắt</th>
    <th>TXN393</th>
    <td>28</td>
    <td>11,418,045</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai khoá gài thể thao</th>
    <th>TXN402</th>
    <td>2</td>
    <td>452,727</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai khóa mây</th>
    <th>TXN489</th>
    <td>133</td>
    <td>57,391,272</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đeo vai phun hiệu ứng loang màu</th>
    <th>TXN473</th>
    <td>15</td>
    <td>6,486,818</td>
  </tr>
  <tr>
    <th>Túi xách nhỏ đép chéo khóa gài</th>
    <th>TXN389</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Túi đeo vai hiệu ứng Pleat dáng lạ</th>
    <th>TXN412</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th rowspan="29" valign="top">
      Túi xách trung
    </th>
    <th>Túi dập hiệu ứng Princess Diamond</th>
    <th>TXT226</th>
    <td>313</td>
    <td>148,682,181</td>
  </tr>
  <tr>
    <th>Túi xách trung buckle flap</th>
    <th>TXT243</th>
    <td>50</td>
    <td>22,116,630</td>
  </tr>
  <tr>
    <th>Túi xách trung buckled tote</th>
    <th>TXT242</th>
    <td>169</td>
    <td>80,395,254</td>
  </tr>
  <tr>
    <th>Túi xách trung bukcle khoá trượt</th>
    <th>TXT249</th>
    <td>7</td>
    <td>3,779,864</td>
  </tr>
  <tr>
    <th>Túi xách trung double handle</th>
    <th>TXT221</th>
    <td>16</td>
    <td>2,692,405</td>
  </tr>
  <tr>
    <th>Túi xách trung dập hiệu ứng lập thể</th>
    <th>TXT238</th>
    <td>17</td>
    <td>7,913,688</td>
  </tr>
  <tr>
    <th>Túi xách trung dập sọc nổi</th>
    <th>TXT230</th>
    <td>137</td>
    <td>64,192,300</td>
  </tr>
  <tr>
    <th>Túi xách trung gắn khóa trang trí</th>
    <th>TXT231</th>
    <td>59</td>
    <td>26,791,236</td>
  </tr>
  <tr>
    <th>Túi xách trung hộp trang trí nút kim loại</th>
    <th>TXT233</th>
    <td>56</td>
    <td>25,849,965</td>
  </tr>
  <tr>
    <th>Túi xách trung khoá xoắn trang trí</th>
    <th>TXT232</th>
    <td>37</td>
    <td>15,632,940</td>
  </tr>
  <tr>
    <th>Túi xách trung multi-bag</th>
    <th>TXT237</th>
    <td>1</td>
    <td>474,670</td>
  </tr>
  <tr>
    <th>Túi xách trung nắp dập nổi</th>
    <th>TXT228</th>
    <td>6</td>
    <td>1,729,838</td>
  </tr>
  <tr>
    <th>Túi xách trung phun hiệu ứng loang màu</th>
    <th>TXT240</th>
    <td>27</td>
    <td>12,550,181</td>
  </tr>
  <tr>
    <th>Túi xách trung satchel dáng hộp</th>
    <th>TXT241</th>
    <td>89</td>
    <td>44,838,919</td>
  </tr>
  <tr>
    <th>Túi xách trung the maze</th>
    <th>TXT244</th>
    <td>80</td>
    <td>38,609,557</td>
  </tr>
  <tr>
    <th>Túi xách trung thân xếp khối</th>
    <th>TXT234</th>
    <td>48</td>
    <td>22,543,119</td>
  </tr>
  <tr>
    <th>Túi xách trung top handle</th>
    <th>TXT223</th>
    <td>80</td>
    <td>39,708,868</td>
  </tr>
  <tr>
    <th>Túi xách trung top handle dáng accordion</th>
    <th>TXT227</th>
    <td>3</td>
    <td>867,500</td>
  </tr>
  <tr>
    <th>Túi xách trung top handle nắp viền dây kim loại</th>
    <th>TXT246</th>
    <td>104</td>
    <td>51,820,544</td>
  </tr>
  <tr>
    <th>Túi xách trung tote charm trang trí phối 2 màu</th>
    <th>TXT220</th>
    <td>268</td>
    <td>123,085,064</td>
  </tr>
  <tr>
    <th>Túi xách trung tote double bag</th>
    <th>TXT222</th>
    <td>3</td>
    <td>1,297,412</td>
  </tr>
  <tr>
    <th>Túi xách trung tote trung nắp cách điệu</th>
    <th>TXT235</th>
    <td>1</td>
    <td>396,409</td>
  </tr>
  <tr>
    <th>Túi xách trung tote trung phối khác chất liệu</th>
    <th>TXT236</th>
    <td>59</td>
    <td>25,541,310</td>
  </tr>
  <tr>
    <th>Túi xách trung trang trí khóa kim loại raw</th>
    <th>TXT245</th>
    <td>86</td>
    <td>32,665,242</td>
  </tr>
  <tr>
    <th>Túi xách trung treo charm trang trí</th>
    <th>TXT239</th>
    <td>166</td>
    <td>75,402,690</td>
  </tr>
  <tr>
    <th rowspan="2" valign="top">
      Túi xách trung tối giản
    </th>
    <th>TXT201</th>
    <td>222</td>
    <td>100,874,669</td>
  </tr>
  <tr>
    <th>TXT204</th>
    <td>44</td>
    <td>15,719,357</td>
  </tr>
  <tr>
    <th>Túi xách trung đa ngăn</th>
    <th>TXT251</th>
    <td>8</td>
    <td>4,097,864</td>
  </tr>
  <tr>
    <th>Túi đeo vai gắn charm túi mini</th>
    <th>TXT247</th>
    <td>135</td>
    <td>59,129,885</td>
  </tr>
  <tr>
    <th rowspan="24" valign="top">
      Ví
    </th>
    <th rowspan="24" valign="top">
      Ví
    </th>
    <th>Clutch dập hiệu ứng Princess Diamond</th>
    <th>VI141</th>
    <td>296</td>
    <td>94,041,677</td>
  </tr>
  <tr>
    <th>Ví 2 thân logo cách điệu</th>
    <th>VI156</th>
    <td>172</td>
    <td>53,909,546</td>
  </tr>
  <tr>
    <th>Ví Mickey 2 nắp</th>
    <th>VI134</th>
    <td>1</td>
    <td>279,070</td>
  </tr>
  <tr>
    <th>Ví chữ nhật kèm dây đeo</th>
    <th>VI147</th>
    <td>37</td>
    <td>9,744,306</td>
  </tr>
  <tr>
    <th>Ví cầm tay có ngăn điện thoại</th>
    <th>VI137</th>
    <td>16</td>
    <td>641,302</td>
  </tr>
  <tr>
    <th>Ví cầm tay</th>
    <th>VI127</th>
    <td>10</td>
    <td>2,233,410</td>
  </tr>
  <tr>
    <th>Ví cầm tay hoạ tiết vân chìm VI052</th>
    <th>VI052</th>
    <td>34</td>
    <td>6,325,609</td>
  </tr>
  <tr>
    <th>Ví cầm tay trang trí đường chỉ chữ x</th>
    <th>VI117</th>
    <td>1</td>
    <td>330,744</td>
  </tr>
  <tr>
    <th>Ví dài nhiều ngăn</th>
    <th>VI158</th>
    <td>51</td>
    <td>10,157,936</td>
  </tr>
  <tr>
    <th>Ví dài nắp pixel-cut</th>
    <th>VI157</th>
    <td>22</td>
    <td>6,265,469</td>
  </tr>
  <tr>
    <th>Ví dây kéo kim loại nhiều ngăn</th>
    <th>VI149</th>
    <td>140</td>
    <td>36,403,566</td>
  </tr>
  <tr>
    <th>Ví dập nổi họa tiết đối xứng</th>
    <th>VI151</th>
    <td>1</td>
    <td>226,364</td>
  </tr>
  <tr>
    <th>Ví gập nhiều ngăn</th>
    <th>VI139</th>
    <td>2</td>
    <td>670,023</td>
  </tr>
  <tr>
    <th>Ví khoá trang trí cùng màu</th>
    <th>VI136</th>
    <td>228</td>
    <td>74,884,571</td>
  </tr>
  <tr>
    <th>Ví kèm dây đeo hiệu ứng đan</th>
    <th>VI162</th>
    <td>283</td>
    <td>86,262,192</td>
  </tr>
  <tr>
    <th>Ví nắp dập nổi</th>
    <th>VI145</th>
    <td>27</td>
    <td>6,843,581</td>
  </tr>
  <tr>
    <th>Ví nắp gập khóa bấm</th>
    <th>VI153</th>
    <td>8</td>
    <td>2,536,954</td>
  </tr>
  <tr>
    <th>Ví phối hiệu ứng</th>
    <th>VI138</th>
    <td>65</td>
    <td>18,203,472</td>
  </tr>
  <tr>
    <th>Ví phối màu color-block</th>
    <th>VI155</th>
    <td>56</td>
    <td>16,739,438</td>
  </tr>
  <tr>
    <th>Ví the maze</th>
    <th>VI159</th>
    <td>72</td>
    <td>23,345,195</td>
  </tr>
  <tr>
    <th>Ví trang trí khóa kim loại</th>
    <th>VI154</th>
    <td>68</td>
    <td>19,287,252</td>
  </tr>
  <tr>
    <th>Ví trang trí khóa kim loại raw</th>
    <th>VI161</th>
    <td>148</td>
    <td>48,491,151</td>
  </tr>
  <tr>
    <th>Ví trang trí khóa the maze</th>
    <th>VI160</th>
    <td>151</td>
    <td>49,062,778</td>
  </tr>
  <tr>
    <th>Ví trang trí nút kim loại</th>
    <th>VI150</th>
    <td>3</td>
    <td>942,385</td>
  </tr>
  <tr>
    <th rowspan="79" valign="top">
      Áo
    </th>
    <th rowspan="32" valign="top">
      Áo Kiểu
    </th>
    <th>Áo Kiểu AKI003</th>
    <th>AKI003</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI004</th>
    <th>AKI004</th>
    <td>30</td>
    <td>5,246,364</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI010</th>
    <th>AKI010</th>
    <td>14</td>
    <td>1,983,136</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI013</th>
    <th>AKI013</th>
    <td>10</td>
    <td>2,196,545</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI015</th>
    <th>AKI015</th>
    <td>5</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI016</th>
    <th>AKI016</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI017</th>
    <th>AKI017</th>
    <td>5</td>
    <td>904,545</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI018</th>
    <th>AKI018</th>
    <td>7</td>
    <td>1,448,182</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI019</th>
    <th>AKI019</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI020</th>
    <th>AKI020</th>
    <td>14</td>
    <td>2,951,182</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI021</th>
    <th>AKI021</th>
    <td>3</td>
    <td>379,091</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI022</th>
    <th>AKI022</th>
    <td>7</td>
    <td>1,266,364</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI023</th>
    <th>AKI023</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI025</th>
    <th>AKI025</th>
    <td>6</td>
    <td>1,085,455</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI026</th>
    <th>AKI026</th>
    <td>8</td>
    <td>1,448,182</td>
  </tr>
  <tr>
    <th>Áo Kiểu AKI027</th>
    <th>AKI027</th>
    <td>6</td>
    <td>1,794,591</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAC12012036</th>
    <th>BAC12012036</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAC12012037</th>
    <th>BAC12012037</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAS12012012</th>
    <th>BAS12012012</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu BAS12101061</th>
    <th>BAS12101061</th>
    <td>1</td>
    <td>162,727</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMA12009050GC</th>
    <th>CMA12009050GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMA12009051GC</th>
    <th>CMA12009051GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMC12101053</th>
    <th>CMC12101053</th>
    <td>4</td>
    <td>360,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMO12005024</th>
    <th>CMO12005024</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMO12010047NM</th>
    <th>CMO12010047NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu CMO12010049NM</th>
    <th>CMO12010049NM</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDA12009063GC</th>
    <th>TDA12009063GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDO12009069</th>
    <th>TDO12009069</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDO12010005GC</th>
    <th>TDO12010005GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDO12010018GC</th>
    <th>TDO12010018GC</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDO12010052NM</th>
    <th>TDO12010052NM</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Kiểu TDS12008060GC</th>
    <th>TDS12008060GC</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th rowspan="27" valign="top">
      Áo Sơ Mi
    </th>
    <th>Áo Kiểu TDS12009044NM</th>
    <th>TDS12009044NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi AKI009</th>
    <th>AKI009</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi AKI012</th>
    <th>AKI012</th>
    <td>7</td>
    <td>1,280,909</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM004</th>
    <th>ASM004</th>
    <td>7</td>
    <td>1,266,364</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM006</th>
    <th>ASM006</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM007</th>
    <th>ASM007</th>
    <td>7</td>
    <td>1,411,909</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM008</th>
    <th>ASM008</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ASM009</th>
    <th>ASM009</th>
    <td>4</td>
    <td>723,636</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi ATH005</th>
    <th>ATH005</th>
    <td>7</td>
    <td>820,909</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAA12010038NM</th>
    <th>BAA12010038NM</th>
    <td>0</td>
    <td>0</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAC12011006NM</th>
    <th>BAC12011006NM</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAS12001021NM</th>
    <th>BAS12001021NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAS12010023</th>
    <th>BAS12010023</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi BAS12012006NM</th>
    <th>BAS12012006NM</th>
    <td>3</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMC12012016</th>
    <th>CMC12012016</th>
    <td>2</td>
    <td>270,909</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12004020NM</th>
    <th>CMS12004020NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12005060NM</th>
    <th>CMS12005060NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12009036NM</th>
    <th>CMS12009036NM</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12009041NM</th>
    <th>CMS12009041NM</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12010044</th>
    <th>CMS12010044</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12012034</th>
    <th>CMS12012034</th>
    <td>21</td>
    <td>3,527,273</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12012038</th>
    <th>CMS12012038</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12012042</th>
    <th>CMS12012042</th>
    <td>1</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12101003</th>
    <th>CMS12101003</th>
    <td>2</td>
    <td>180,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi CMS12101014</th>
    <th>CMS12101014</th>
    <td>2</td>
    <td>361,818</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi SMB2001017</th>
    <th>SMB2001017</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Sơ Mi TDS12101024</th>
    <th>TDS12101024</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th rowspan="20" valign="top">
      Áo Thun
    </th>
    <th>Áo Thun AKI011</th>
    <th>AKI011</th>
    <td>7</td>
    <td>694,545</td>
  </tr>
  <tr>
    <th>Áo Thun ATH001</th>
    <th>ATH001</th>
    <td>3</td>
    <td>351,818</td>
  </tr>
  <tr>
    <th>Áo Thun ATH004</th>
    <th>ATH004</th>
    <td>3</td>
    <td>234,545</td>
  </tr>
  <tr>
    <th>Áo Thun ATH006</th>
    <th>ATH006</th>
    <td>19</td>
    <td>2,222,727</td>
  </tr>
  <tr>
    <th>Áo Thun ATH007</th>
    <th>ATH007</th>
    <td>13</td>
    <td>2,315,455</td>
  </tr>
  <tr>
    <th>Áo Thun ATH008</th>
    <th>ATH008</th>
    <td>7</td>
    <td>1,266,364</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12008066HH</th>
    <th>BAC12008066HH</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101021</th>
    <th>BAC12101021</th>
    <td>9</td>
    <td>933,636</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101022</th>
    <th>BAC12101022</th>
    <td>9</td>
    <td>1,273,636</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101023</th>
    <th>BAC12101023</th>
    <td>12</td>
    <td>1,247,864</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101063</th>
    <th>BAC12101063</th>
    <td>8</td>
    <td>720,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAC12101064</th>
    <th>BAC12101064</th>
    <td>10</td>
    <td>900,000</td>
  </tr>
  <tr>
    <th>Áo Thun BAS12101048</th>
    <th>BAS12101048</th>
    <td>3</td>
    <td>351,818</td>
  </tr>
  <tr>
    <th>Áo Thun BAS12101049</th>
    <th>BAS12101049</th>
    <td>4</td>
    <td>469,091</td>
  </tr>
  <tr>
    <th>Áo Thun BPC12005054HH</th>
    <th>BPC12005054HH</th>
    <td>1</td>
    <td>90,000</td>
  </tr>
  <tr>
    <th>Áo thun "Bánh mỳ Sài gòn"</th>
    <th>ATH013</th>
    <td>3</td>
    <td>180,909</td>
  </tr>
  <tr>
    <th>Áo thun "Chích rồi ôm tí"</th>
    <th>ATH012</th>
    <td>11</td>
    <td>542,727</td>
  </tr>
  <tr>
    <th>Áo thun "Hành khỏe đẹp"</th>
    <th>ATH011</th>
    <td>11</td>
    <td>723,636</td>
  </tr>
  <tr>
    <th>Áo thun "Hành trứng ấm êm"</th>
    <th>ATH014</th>
    <td>10</td>
    <td>813,636</td>
  </tr>
  <tr>
    <th>Áo thun "Top giàu sang"</th>
    <th>ATH010</th>
    <td>11</td>
    <td>1,266,364</td>
  </tr>
</tbody>
</table>
`

export default string
