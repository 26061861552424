import FiltersHeader from 'Pages/Analytics/Components/FiltersHeader'
import Latency from 'Pages/Analytics/Components/Latency'
import React, { useState, useEffect } from 'react'

import Body from './Body'

const Overview = () => {
  return (
    <div className="AnalyticsWrapper">
      <div>
        <FiltersHeader />
      </div>
      <div className="mt-4 mb-4">
        <Latency />
      </div>

      <Body />
    </div>
  )
}

export default Overview
