import React from 'react'
import BarChartTemplate from '../../Components/BarChart.Template'

import { getSegment360Charts } from 'Services/Analytics/segment-analytics'

const ChartRow4 = () => {
  return (
    <>
      <div className="p-4 col-span-3 shadow-div_b">
        <div className="mb-4 font-semibold">Sale Channel</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ height: 300 }}>
          <BarChartTemplate
            chartCode="sale_channel_by_time"
            handleDataFunction={getSegment360Charts}
            isStacked
          />
        </div>
      </div>

      <div className="p-4 col-span-1 shadow-div_r">
        <div className="mb-4 font-semibold">Sale channel x CLC</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ height: 300 }}>
          <BarChartTemplate
            chartCode="sale_channel_x_clc"
            handleDataFunction={getSegment360Charts}
            isStacked
            isHorizontal
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_r">
        <div className="mb-4 font-semibold">Sale channel x LTV</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ height: 300 }}>
          <BarChartTemplate
            chartCode="sale_channel_x_ltv"
            handleDataFunction={getSegment360Charts}
            isStacked
            isHorizontal
          />
        </div>
      </div>
      <div className="p-4 col-span-1">
        <div className="mb-4 font-semibold">Sale channel x Cluster</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ height: 300 }}>
          <BarChartTemplate
            chartCode="sale_channel_x_cluster"
            handleDataFunction={getSegment360Charts}
            isStacked
            isHorizontal
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow4
