import React from 'react'

import MacOS from './MacOS'
import Window from './Window'
import Android from './Android'

import './index.scss'

const WebPushPreview = ({ data, platform }) => {
  return (
    <div className="WebPushPreviewComponent">
      {platform === 'mac_os' && <MacOS data={data} />}
      {platform === 'window' && <Window data={data} />}
      {platform === 'android' && <Android data={data} />}
    </div>
  )
}

export default WebPushPreview
