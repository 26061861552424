import React, { useEffect, useState } from 'react'
import './index.scss'
import { connect } from 'react-redux'

const MenuItem = ({ menu, itemStyle, x, y, width, height, onClick, CJStatus }) => {
  const [style, setStyle] = useState(itemStyle)

  const isDisabled = () => {
    if (CJStatus == 'running' || ['settings', 'change', 'duplicate'].includes(menu.key))
      return { pointerEvents: 'none', opacity: 0.5 }

    return null
  }

  useEffect(() => {
    setTimeout(
      () =>
        setStyle({
          ...itemStyle,
          pointerEvents: 'auto',
          opacity: 1,
          right: `${x}px`,
          top: `${y}px`,
        }),
      100
    )

    return () => setStyle(itemStyle)
  }, [])

  return (
    <div
      onClick={onClick}
      className={`NodeMenuComponent ${menu.key}`}
      style={{ ...style, width, height, ...isDisabled() }}
    >
      {menu.icon}
    </div>
  )
}

export default connect((state) => ({
  CJStatus: state.customerJourney.status,
}))(MenuItem)
