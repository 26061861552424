import * as yup from 'yup'

export const sendgridEmailValidation = yup.object().shape({
  subject: yup.string().required('Subject is required'),
  utm_campaign: yup.string().required('UTM Campaing is required'),
  utm_medium: yup.string().required('UTM Medium is required'),
  utm_source: yup.string().required('UTM Source is required'),
})

export const karibaEmailValidation = yup.object().shape({
  subject: yup.string().required('Subject is required'),
  utm_campaign: yup.string().required('UTM Campaing is required'),
  utm_medium: yup.string().required('UTM Medium is required'),
  utm_source: yup.string().required('UTM Source is required'),
  // image_item: yup.string().required('Image is required'),
  // image_item_url: yup.string().required('Image url is required'),
  // image_item_1: yup.string().required('Image is required'),
  // image_item_1_url: yup.string().required('Image url is required'),
  // image_item_2: yup.string().required('Image is required'),
  // image_item_2_url: yup.string().required('Image url is required'),
})
