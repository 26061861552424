import React, { useContext, useEffect, useState } from 'react'
import { Button, Select } from 'antd'
import CommonInputs from 'Containers/DimensionFilters/Inputs/CommonInputs'
import { SAMPLE_DATA, EXPRESSIONS, CRITERIA_GROUP } from './constants'
import { ReactComponent as DeleteIcon } from 'Assets/images/icons/IconDel.svg'
import { dimensionPopupContext } from 'Containers/DimensionFilters/Dimensions.Popup'
import { getDimensions } from 'Services/dimensions'

const Segment = (options) => {
  const [conditionValue, setConditionValue] = useState<string>('')
  const [typeValue, setTypeValue] = useState<string>('')
  const { setDimensionsDraft, editingDimension, setEditingDimension, dimensionsDraft } =
    useContext(dimensionPopupContext)
  const [crigroup, setCrigroup] = useState<any>(dimensionsDraft)
  useEffect(() => {
    crigroup.length <= 1 && addGroup()
  }, [])

  const findNameWithCode = (code, data) => {
    const result = data?.find((item) => item?.value === code)
    return result?.label
  }

  const temp = {
    condition: '',
    segments: [
      {
        type: 'SEGMENT',
        value: '',
        condition: '',
      },
    ],
  }

  const addGroup = () => {
    setCrigroup([...crigroup, temp])
  }
  const deleteGroup = (index) => {
    setCrigroup(crigroup?.filter((item, i) => i !== index))
  }

  const updateGroupCondition = (value, index) => {
    setCrigroup(
      crigroup?.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            condition: value,
            segments: [
              {
                ...item?.segments[0],
                condition: value,
              },
            ],
          }
        }
        return item
      })
    )
  }

  const updateGroupDimension = (value, name, index) => {
    setCrigroup(
      crigroup.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            condition: crigroup[i]?.condition,
            segments: crigroup[i]?.segments?.map((item, i) => {
              return { ...item, value: value, operator: 'EQUAL', type: 'SEGMENT', name: name }
            }),
          }
        }
        return item
      })
    )
  }

  const getValueList = (data) => {
    const tempArr: any = []
    data &&
      data.map((item) => {
        tempArr.push(item?.segments[0]?.value)
      })
    return tempArr
  }

  return (
    <div id="Segment ">
      <div className="flex mt-6" style={{ marginLeft: 'var(--sp_lv_8)' }}>
        <div> {'<'} </div>
        <div className="ml-2"> Segment </div>
      </div>
      <div className="mt-4" style={{ marginLeft: 'var(--sp_lv_8)', width: 363 }}>
        {crigroup?.map((gr, index) => {
          if (index === 0) {
            return (
              <div className="flex" style={{ width: 414 }}>
                <div style={{ width: 363 }} className="mt-4">
                  <CommonInputs
                    placeholder="Select segment"
                    value={crigroup[0]?.segments[0]?.name}
                    options={options?.options}
                    type="SINGLE_SELECT"
                    onChange={(value) => {
                      updateGroupDimension(value, findNameWithCode(value, options?.options), 0)
                    }}
                  />
                </div>
                <Button
                  disabled={
                    !(
                      crigroup?.length > 1 &&
                      crigroup[1]?.condition != '' &&
                      crigroup[1]?.segments[0]?.value != '' &&
                      getValueList(crigroup)?.filter(
                        (item, index) => getValueList(crigroup)?.indexOf(item) != index
                      )?.length === 0
                    )
                  }
                  type="text"
                  className="font-semibold text-brand_primary cursor-pointer p-0 ml-6"
                  style={{ alignSelf: 'end' }}
                  onClick={() => {
                    setDimensionsDraft({
                      type: 'addSegment',
                      payload: crigroup,
                    })
                  }}
                >
                  Add
                </Button>
              </div>
            )
          } else {
            return (
              <div>
                <div className="flex justify-between mt-4">
                  <div style={{ width: 124 }}>
                    <CommonInputs
                      placeholder="Operator"
                      value={crigroup[index]?.condition}
                      options={EXPRESSIONS?.operator?.map((op) => ({
                        label: op.label,
                        value: op.value,
                        key: op.value,
                      }))}
                      type="SINGLE_SELECT"
                      onChange={(value) => {
                        updateGroupCondition(value, index)
                        setConditionValue(value?.toString())
                      }}
                    />
                  </div>
                  {index > 1 && (
                    <DeleteIcon
                      style={{ width: 24, height: 24, alignSelf: 'center' }}
                      onClick={() => deleteGroup(index)}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                <div style={{ width: 363 }} className="mt-4">
                  <CommonInputs
                    placeholder="Select segment"
                    value={crigroup[index]?.segments[0]?.name || ''}
                    options={options?.options}
                    type="SINGLE_SELECT"
                    onChange={(value) => {
                      findNameWithCode(value, options?.options)
                      updateGroupDimension(value, findNameWithCode(value, options?.options), index)
                      setTypeValue(value.toString())
                    }}
                  />
                </div>
              </div>
            )
          }
        })}

        <div onClick={addGroup} className="bg-gray_1 mt-4">
          <span className="text-brand_primary font-semibold cursor-pointer">
            + New Condition Group
          </span>
        </div>
      </div>
    </div>
  )
}

export default Segment
