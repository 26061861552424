import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { message, Typography, Tooltip } from 'antd'
import { useStoreActions } from 'easy-peasy'
import { BarChartOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import Table from 'Components/Table2.0'

import { deleteCampaign } from 'Services/campaigns'
import { formatErrors } from 'Utils'
import moment from 'moment'
export const COLUMNS = [
  {
    title: 'Campaign',
    dataIndex: 'name',
    sorter: true,
    align: 'left',
    render: (campaign) => (
      <Tooltip title={campaign}>
        <Typography.Text className="campaign-title" strong>
          {campaign}
        </Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 100,
    sorter: true,
  },
  {
    title: 'Views',
    dataIndex: ['analytic_key_metrics', 'views'],
    width: 100,
    render: (value) => value?.toLocaleString(),
  },
  {
    title: 'CPC',
    dataIndex: ['analytic_key_metrics', 'cpc'],
    width: 50,
    render: (value) => value?.toLocaleString(),
  },
  {
    title: 'Clicks',
    dataIndex: ['analytic_key_metrics', 'clicks'],
    width: 50,
    render: (value) => value?.toLocaleString(),
  },
  {
    title: 'CTR',
    dataIndex: ['analytic_key_metrics', 'ctr'],
    width: 50,
    render: (value) => {
      if (typeof value === 'number') return (value * 100).toLocaleString() + '%'
      else return 'N/A'
    },
  },
  {
    title: 'Performance',
    dataIndex: 'performance',
    width: 100,
    sorter: true,
  },
  {
    title: 'Updated on',
    dataIndex: 'updated_at',
    sorter: true,
    render: (text) => moment(text).format('DD-MM-YYYY'),
    width: 150,
  },
]

const { Title } = Typography

const CampaignList = () => {
  const history = useHistory()
  const { setBreadCrumb } = useStoreActions((actions: any) => actions.breadcrumb)

  const [filters, setFilters] = useState<any>({
    placement: ['hrs_store_farming'],
  })
  const [searchTerm, setSearchTerm] = useState('')

  const handleCreate = async () => {
    history.push('./campaigns/new')
  }

  const handleEdit = async (record) => {
    history.push(`./campaigns/${record.id}`)
  }

  const handleStatistics = async (record) => {
    history.push(`./campaigns/${record.id}/performance`)
  }

  const handleTableChange = (tablePagination, _, sorter) => {
    setFilters({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.order
        ? sorter.order === 'ascend'
          ? sorter.field
          : `-${sorter.field}`
        : null,
    })
  }

  useEffect(() => {
    setBreadCrumb([{ title: 'Campaigns', path: '/campaigns', isLastItem: true }])
  }, [setBreadCrumb])

  return (
    <Table
      dropdown={{
        fields: [],
        value: filters,
      }}
      fetchFrom={{ url: '/campaigns/', key: 'results' }}
      search={{ keyword: searchTerm, onSearch: setSearchTerm }}
      columns={COLUMNS}
      handleTableChange={handleTableChange}
    />
  )
}

export default CampaignList
