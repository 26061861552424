import React, { useState } from 'react'
import { Typography, Select, Skeleton } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'

import iosMessagesIcon from 'Assets/images/ios-messages-icon.png'
import androidMessagesIcon from 'Assets/images/android-sms-icon.png'
import './index.scss'
import { brandVars } from 'Utils/brandVars'

const { Option } = Select
const { Title } = Typography

const platforms = [
  { label: 'Android', value: 'android' },
  { label: 'iOS', value: 'ios' },
]

const SMS = ({ initData }) => {
  const { message_content } = initData
  const [device, setDevice] = useState(platforms[0].value)
  const [loading, setLoading] = useState(false)

  const onChangeDevice = async (value) => {
    setLoading(true)
    await new Promise((resolve) =>
      setTimeout(() => {
        resolve(setDevice(value))
      }, 1000)
    )
    setLoading(false)
  }

  return (
    <div className="SMSPreviewComponent">
      <div className="SMSScreen">
        <Skeleton active loading={loading}>
          {device == 'ios' && (
            <div className={`${device}-preview`}>
              <div className="SMSHeader">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="SMS-icon">
                    <img src={iosMessagesIcon} alt="ios-message-icon" />
                  </div>
                  <Title className="SMS-title">MESSAGES</Title>
                </div>
                <span>now</span>
              </div>
              <div className="SMSContent">
                <Title className="sender">JUNO</Title>
                <div className="content">{message_content}</div>
              </div>
            </div>
          )}
          {device == 'android' && (
            <div className={`${device}-preview`}>
              <div className="SMSHeader">
                <div className="SMS-icon">
                  <img src={androidMessagesIcon} alt="android-message-icon" />
                </div>
                <span>Voice •</span>
                <span>now</span>
              </div>
              <div className="SMSContent">
                <div className="sender-logo">
                  <img src={brandVars().logo} alt="sender logo" />
                </div>
                <div>
                  <Title className="sender">JUNO</Title>
                  <div className="content">{message_content}</div>
                </div>
              </div>
              <div className="SMSFooter">
                <span>Reply</span>
              </div>
            </div>
          )}
        </Skeleton>
      </div>
      <div className="select-preview-device">
        <Select value={device} onChange={(value) => onChangeDevice(value)}>
          {platforms.map((platform) => (
            <Option value={platform.value}>{platform.label}</Option>
          ))}
        </Select>
        {/* <Title level={5}>Send to test device</Title> */}
      </div>
    </div>
  )
}

export default SMS
