import React from 'react'
import { Menu, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'
import MENU from './Menu.constants'
import { hasPermission } from 'Utils'
const { Item, SubMenu } = Menu

const MenuList = ({ selectedKeys, openKeys, menuCollapse, onClick, handleOpenChange }) => {
  const roles = useStoreState((state) => state.auth.roles)

  const onGoTo = (route) => {
    if (route.includes('analytics')) {
      return `${route}${window.location.search}`
    }

    return route
  }

  if (!roles) return <></>
  return (
    <Menu
      mode="inline"
      selectedKeys={selectedKeys}
      onClick={onClick}
      openKeys={openKeys}
      onOpenChange={handleOpenChange}
      inlineCollapsed={menuCollapse}
    >
      {MENU.map((item) => {
        if (item.subMenus.length) {
          const hasPerm = hasPermission(roles, item.allowRoles)
          if (!hasPerm) return <></>
          return (
            <SubMenu key={item.id} icon={<item.icon />} title={item.name} className="sub-menu">
              {item.subMenus.map((submenu) => {
                const hasPerm = hasPermission(roles, submenu.allowRoles)
                if (!hasPerm) return <></>
                return (
                  <Item key={submenu.id}>
                    <Link to={onGoTo(submenu.route)}>{submenu.name}</Link>
                  </Item>
                )
              })}
            </SubMenu>
          )
        }
        const hasPerm = hasPermission(roles, item.allowRoles)
        if (!hasPerm) return <></>
        return (
          <Item key={item.id} icon={<item.icon />}>
            <Link to={item.route}>{item.name}</Link>
          </Item>
        )
      })}
    </Menu>
  )
}

export default MenuList
