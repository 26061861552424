import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import FiltersHeader from '../../Components/FiltersHeader'
import Body from './Body'
import { useInView } from 'react-intersection-observer'

const Overview = () => {
  return (
    <div className="AnalyticsWrapper">
      <div>
        <FiltersHeader />
      </div>

      <Body />
    </div>
  )
}

export default Overview
