import React, { useEffect, useState } from 'react'
import { Dropdown, Input } from 'antd'

import { getTemplateMessages, getMessageParams } from 'Services/store-farming'

import './DynamicContent.scss'
export type ParamType = { parameter_code: string }

export type ParametersType = {
  group_name: string
  group_code: string
  parameters: ParamType[]
}
const DynamicContent = ({ value, onChange, placeholder, defaultValue = '' }) => {
  const [params, setParams] = useState<ParametersType[]>([])
  const [templates, setTemplates] = useState<
    { id: number; created_at: string; updated_at: string; content: string }[]
  >([])
  const [isTemplateDropdownVisible, setIsTemplateDropdownVisible] = useState(false)

  const handleFetchData = async () => {
    const paramsResp = await getMessageParams()
    const message_templates = await getTemplateMessages()
    const paramData: ParametersType[] = paramsResp.data

    setParams(paramData)
    setTemplates(message_templates.data.template_messages)
  }

  useEffect(() => {
    handleFetchData()
  }, [])

  const renderParamDropdown = (
    <div
      className="p-2 bg-gray_1 grid gap-10 grid-cols-4 gap-4 shadow-lg"
      style={{ borderRadius: 4 }}
    >
      {params?.map((param) => (
        <div key={param.group_code}>
          <h2 className="font-bold">{param.group_name}</h2>
          <div>
            {param.parameters.map((item) => (
              <div
                className="cursor-pointer hover:text-brand_primary my-2"
                onClick={() => {
                  onChange(`${value || ''} ${item.parameter_code}`)
                }}
                key={item.parameter_code}
              >
                {item.parameter_code}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )

  const renderTemplateDropdown = (
    <div className="template-message-wrapper">
      <div>
        {templates.map((message) => (
          <div
            onClick={() => {
              setIsTemplateDropdownVisible(!isTemplateDropdownVisible)
              onChange(message.content)
            }}
            className="template-message-item"
            key={message.id}
          >
            {message.content}
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <div className="DynamicContentComponent">
      <div className="dropdown-wrapper">
        <div className="__action-btn add-param">
          <Dropdown
            overlayClassName="shadow-bot_2 border rounded"
            overlay={renderParamDropdown}
            trigger={['click']}
          >
            <div>Add parameter</div>
          </Dropdown>
        </div>
        <div className="text-brand_primary">|</div>
        <div className="__action-btn use-template">
          <Dropdown
            visible={isTemplateDropdownVisible}
            overlay={renderTemplateDropdown}
            trigger={['click']}
          >
            <div onClick={() => setIsTemplateDropdownVisible(!isTemplateDropdownVisible)}>
              Use template
            </div>
          </Dropdown>
        </div>
      </div>
      <Input.TextArea
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}

export default DynamicContent
