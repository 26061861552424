import React from 'react'
import CommonTitle from './CommonTitle'
import { Field, ErrorMessage } from 'formik'
import { Alert, Tooltip } from 'antd'

const FieldWrapper = (props) => {
  const {
    name,
    label,
    displayLabel,
    inputType,
    onChange,
    setFieldValue,
    onFieldChange,
    children,
    isRequired,
    cancelFieldChange = false,
    tooltip,
  } = props
  const renderLabel = () => {
    if (!displayLabel || inputType === 'hidden') return
    return (
      <CommonTitle isRequired={isRequired} label={label || name} tooltip={tooltip}></CommonTitle>
    )
  }

  const handleChange = (name, value) => {
    setFieldValue(name, value)
    onFieldChange(name, value)
  }

  return (
    <div className="form-item">
      <div className="title-content-wrapper">
        {name == 'button_deeplink' ? '' : renderLabel()}
        <Field name={name} onChange={handleChange}>
          {(fieldProps) => children({ ...fieldProps, onChange: onChange })}
        </Field>
      </div>
      <ErrorMessage
        name={name}
        component={(props) => (
          <Alert message={props.children} type="error" className="field-error mt-2" showIcon />
        )}
      />
    </div>
  )
}

FieldWrapper.defaultProps = {
  displayLabel: true,
}

export default FieldWrapper
