import React, { useState, memo } from 'react'
import { HaraSocialValues } from 'HaraSocialType'
import { Typography } from 'antd'
import { connect } from 'react-redux'

import Icon from 'Components/ExternalComponents/Icon'
import CopyToClipboard from 'Components/ExternalComponents/CopyToClipboard'
import CreativeContent from 'Containers/CreativeContent'
import { haraSocialVariables, haraSocialSchemas, haraSocialInitialValues } from '../index.constants'
import { haraSocialValidation } from '../index.validation'

const { Title, Text } = Typography

export type Props = {
  onSubmit: (val: HaraSocialValues) => void
  modal?: boolean
  value?: HaraSocialValues
  initData: HaraSocialValues
}
const HaraSocial: React.FC<Props> = ({ onSubmit, initData, value }) => {
  const [fields, setFields] = useState(() => {
    const val = value || initData
    if (val.type === 'image')
      return [
        ...haraSocialSchemas,
        {
          name: 'image',
          type: 'image',
          label: 'Image',
          isRequired: true,
        },
      ]
    else if (val.type === 'text') {
      return [
        ...haraSocialSchemas,
        {
          name: 'content',
          type: 'text_area',
          label: 'Content',
          isRequired: true,
        },
      ]
    } else if (val.type === 'product') {
      return [
        ...haraSocialSchemas,
        {
          name: 'product_ids',
          type: 'product_selects',
          label: 'Products',
          isRequired: true,
        },
      ]
    } else return haraSocialSchemas
  })

  const handleSubmit = (val: HaraSocialValues) => {
    if (val['type'] === 'image') {
      delete val.product_ids
    } else {
      delete val.image
    }
    onSubmit(val)
  }

  const onTypeChange = (type: string, value: any) => {
    if (type !== 'type') return
    if (value === 'image') {
      setFields([
        ...haraSocialSchemas,
        {
          name: 'image',
          type: 'image',
          label: 'Image',
          isRequired: true,
        },
      ])
    } else if (value === 'text') {
      setFields([
        ...haraSocialSchemas,
        {
          name: 'content',
          type: 'text_area',
          label: 'Content',
          isRequired: true,
        },
      ])
    } else if (value === 'product') {
      setFields([
        ...haraSocialSchemas,
        {
          name: 'product_ids',
          type: 'product_selects',
          label: 'Products',
          isRequired: true,
        },
      ])
    } else {
      setFields(haraSocialSchemas)
    }
  }

  return (
    <div className="HaraSocialComponent">
      <div className="HaraSocial-variable" style={{ width: 200 }}>
        <Title level={3}>Variable</Title>

        {haraSocialVariables.map((variable, i) => (
          <div key={i} className="flex">
            <Text className="flex-1 leading-loose	">{variable || 'null'}</Text>

            <CopyToClipboard textToCopy={variable || 'null'}>
              <Icon name="copy" color="white" />
            </CopyToClipboard>
          </div>
        ))}
      </div>
      <div className="HaraSocial-create-content">
        <CreativeContent
          onSubmit={handleSubmit}
          submitText="Save"
          initValues={value || initData || haraSocialInitialValues}
          validationSchema={haraSocialValidation}
          onChange={onTypeChange}
          fields={fields}
        />
      </div>
    </div>
  )
}

export default connect((state: any) => ({
  initData: state.campaign.creativeContents.previewData,
}))(
  memo(HaraSocial, (props, nextProps): boolean => {
    if (props.initData.image !== nextProps.initData.image) return true
    return false
  })
)
