import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { City } from 'CustomerSegmentTypes'
import { useDebounce } from 'Hooks'
import { searchDimensions } from 'Services/dimensions'
import { formatErrors } from 'Utils'

const { Option } = Select

const Locations = () => {
  const { setState } = useStoreActions((actions: any) => actions.customerSegments)
  const { cities } = useStoreState((state: any) => state.customerSegments)

  const [searchInput, setSearchInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState<City[]>(cities)
  const [errors, setErrors] = useState('')
  const debounceSearch = useDebounce(searchInput, 1000)

  useEffect(() => {
    const handleSearch = async () => {
      setIsLoading(true)
      try {
        const resp = await searchDimensions({
          type: 'facebook',
          query:
            'location_types=[%22city%22]&country_code=VN&type=adgeolocation&fields=key,name&limit=100',
          keyword: searchInput,
        })

        setOptions(resp.data.map((item) => ({ value: item.key, label: item.name })))
      } catch (err: any) {
        setErrors(formatErrors(err.response?.data)?.message || 'Something went wrong')
      }
      setIsLoading(false)
    }
    handleSearch()
  }, [debounceSearch])

  return (
    <div>
      <Select
        className={errors && 'errors'}
        loading={isLoading}
        showSearch
        value={cities.map((city: City) => city.value)}
        mode="multiple"
        size={'large'}
        placeholder="Select"
        onSelect={(value, option) => {
          setState({ key: 'need_insights_refresh', value: true })
          setState({
            key: 'cities',
            value: [...cities, { value: option.value, label: option.label }],
          })
        }}
        onDeselect={(value) => {
          setState({ key: 'need_insights_refresh', value: true })
          setState({
            key: 'cities',
            value: cities.filter((city: City) => city.value !== value),
          })
        }}
        onSearch={(input) => setSearchInput(input)}
        style={{ width: '100%' }}
        options={options}
        filterOption={false}
      >
        {options.map((option) => (
          <Option label={option.label} value={option.value} key={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
      {errors && <div className="text-negative">{errors}</div>}
    </div>
  )
}

export default Locations
