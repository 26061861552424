import React from 'react'
import { Tabs, Input, Button, Typography, Spin } from 'antd'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons'

import { FilterType } from '../index.d'

import DatePicker from 'Components/DatePicker'
import FilterDropdown from './Dropdown'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { ReactComponent as Download } from 'Assets/images/icons/download_dark.svg'
import axios from 'axios'
import { CustomerListContext } from 'Pages/Analytics/Customers/CustomerList'

const { TabPane } = Tabs
const { Title } = Typography

export type props = {
  title?: string
  dropdown?: { fields?: FilterType[]; value: any }
  search?: { keyword: string; onSearch: (value: string) => void }
  datePicker?: {
    from_date: string
    to_date: string
    onChange: ({ from_date, to_date }: { from_date?: string; to_date?: string }) => void
  }

  primaryButton?: any
}

const TableHeader: React.FC<props> = ({ search, datePicker, primaryButton, dropdown, title }) => {
  const history = useHistory()
  const queryObject = queryString.parse(history.location.search)
  const TAB: FilterType | undefined = dropdown?.fields?.find((filter) => filter.type === 'TAB')
  const currentPage = window.location.href.split('/')[4] || window.location.href.split('/')[3]
  const CustomerListDownload = React.useContext(CustomerListContext)
  const onTabClick = (tab: string) => {
    history.push({ search: queryString.stringify({ ...queryObject, tab }) })
  }
  const DROPDOWN: FilterType[] =
    dropdown?.fields?.filter((filter) => filter.type === 'DROPDOWN') || []

  const renderTabs = () => {
    if (!TAB) return
    return (
      <div className="mb-4 mt-3">
        <Tabs
          activeKey={queryObject?.tab?.toString()}
          onChange={(tab) => TAB?.onClick({ key: TAB?.value, value: tab })}
        >
          {TAB?.options.map((option) => (
            <TabPane
              key={option.value}
              tab={
                <h3 className="text-lg" onClick={() => onTabClick(option.value)}>
                  {option.label}
                </h3>
              }
            ></TabPane>
          ))}
        </Tabs>
      </div>
    )
  }

  const renderPrimaryButton = () => {
    if (!primaryButton) return <></>
    if (typeof primaryButton === 'function') return primaryButton()
    return (
      <Button
        className={'create-button'}
        type={'primary'}
        icon={<PlusOutlined className="create-icon" />}
        onClick={primaryButton.onClick}
      >
        {primaryButton.title}
      </Button>
    )
  }
  return (
    <>
      <div className="flex justify-between items-center">
        {title && (
          <Title className="page-title font-semibold text-3xl text-primary_text mb-4" level={3}>
            {title}
          </Title>
        )}
        {renderPrimaryButton()}
      </div>
      {renderTabs()}

      <div className="flex justify-between items-center">
        {(search || dropdown?.fields) && (
          <div className="flex items-center gap-4 flex-wrap">
            {search && (
              <Input
                style={{ width: 300, height: 40, borderRadius: 4 }}
                prefix={<SearchOutlined />}
                placeholder="Search table..."
                className="search-bar"
                value={search?.keyword}
                onChange={(e) => search?.onSearch(e.target.value)}
              />
            )}
            {dropdown?.fields &&
              DROPDOWN.map((item) => (
                <FilterDropdown
                  overlay={item.options}
                  key={item.value}
                  title={item.label}
                  onChange={(value) => {
                    item.onClick({ key: item.value, value })
                  }}
                  value={dropdown.value[item.value] || []}
                />
              ))}
          </div>
        )}
        {(currentPage == 'customers?tab=customer_listing' ||
          currentPage == '?tab=customer_listing') && (
          <div className="cursor-pointer" onClick={() => CustomerListDownload?.downloadOn()}>
            {CustomerListDownload?.isDownload == true ? (
              <Spin></Spin>
            ) : (
              <Download className="download-icon" />
            )}
          </div>
        )}
        {datePicker && (
          <div>
            <DatePicker
              onChange={datePicker.onChange}
              value={{ from_date: datePicker.from_date || '', to_date: datePicker.to_date || '' }}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default TableHeader
