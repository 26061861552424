import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Interests as InterestsType } from 'CustomerSegmentTypes'

import { useDebounce } from 'Hooks'
import { searchDimensions } from 'Services/dimensions'
import { formatErrors } from 'Utils'

const { Option } = Select

const Interests = () => {
  const { setState } = useStoreActions((actions: any) => actions.customerSegments)
  const { interests } = useStoreState((state: any) => state.customerSegments)

  const [searchInput, setSearchInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState<InterestsType[]>(interests)
  const [errors, setErrors] = useState('')
  const debounceSearch = useDebounce(searchInput, 1000)

  useEffect(() => {
    const handleSearch = async () => {
      setIsLoading(true)
      try {
        const resp = await searchDimensions({
          type: 'facebook',
          query: 'type=adinterest&fields=id,name,audience_size&limit=100',
          keyword: searchInput,
        })

        setOptions(resp.data.map((item) => ({ value: item.id, label: item.name })))
      } catch (err: any) {
        setErrors(formatErrors(err.response?.data)?.message || 'Something went wrong')
      }
      setIsLoading(false)
    }
    handleSearch()
  }, [debounceSearch])

  useEffect(() => {
    setErrors('')
  }, [searchInput])

  return (
    <div>
      <Select
        showSearch
        className={`${errors && 'errors'}`}
        loading={isLoading}
        size={'large'}
        mode="multiple"
        placeholder="Select"
        value={interests.map((Interests: InterestsType) => Interests.value)}
        onSearch={setSearchInput}
        style={{ width: '100%' }}
        filterOption={false}
        onSelect={(value, option) => {
          setState({ key: 'need_insights_refresh', value: true })
          setState({
            key: 'interests',
            value: [...interests, { value: option.value, label: option.label }],
          })
        }}
        onDeselect={(value) => {
          setState({ key: 'need_insights_refresh', value: true })
          setState({
            key: 'interests',
            value: interests.filter((Interests: InterestsType) => Interests.value !== value),
          })
        }}
      >
        {options.map((option) => (
          <Option label={option.label} value={option.value} key={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
      {errors && <div className="text-negative">{errors}</div>}
    </div>
  )
}

export default Interests
