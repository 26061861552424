import React, { useEffect, useState } from 'react'
import { Dropdown, Select } from 'antd'

import FormItem from '../FormItem'

import { getTemplateMessages, getMessageParams } from 'Services/store-farming'

import './index.scss'

export type ParamType = { parameter_code: string }

export type ParametersType = {
  group_name: string
  group_code: string
  parameters: ParamType[]
}

const Message = ({ value, onChange }) => {
  const [params, setParams] = useState<ParametersType[]>([])
  const [templates, setTemplates] = useState<
    { id: number; created_at: string; updated_at: string; content: string }[]
  >([])
  const [isTemplateDropdownVisible, setIsTemplateDropdownVisible] = useState(false)

  //   const [selectedTemplateType, setSelectedTemplateType] = ['']
  //   const [scenarios, setScenarios] = useState([])

  const handleFetchData = async () => {
    const paramsResp = await getMessageParams()
    const message_templates = await getTemplateMessages()
    const paramData: ParametersType[] = paramsResp.data

    setParams(paramData)
    setTemplates([
      {
        id: 2147483647,
        created_at: '2021-07-14T16:03:25.321Z',
        updated_at: '2021-07-14T16:03:25.321Z',
        content: 'Hi {{name}}, bạn cần giúp gì không ạ?',
      },
    ])
  }

  useEffect(() => {
    handleFetchData()
  }, [])

  const ParamDropdown = (
    <div
      className="p-2 bg-gray_1 grid gap-10 grid-cols-4 gap-4 shadow-lg"
      style={{ borderRadius: 4 }}
    >
      {params?.map((param) => (
        <div key={param.group_code}>
          <h2 className="font-bold">{param.group_name}</h2>
          <div>
            {param.parameters.map((item) => (
              <div
                className="cursor-pointer hover:text-brand_primary my-2"
                onClick={() => {
                  onChange('message', `${value || ''} ${item.parameter_code}`)
                }}
                key={item.parameter_code}
              >
                {item.parameter_code}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )

  const TemplateDropdown = (
    <div className="template-message-wrapper">
      <div className="filter">
        <Select placeholder="Select type" options={[{ value: 'campaign', label: 'Campaign' }]} />
        <Select placeholder="Select scenario" options={[{ value: 'Giúp đỡ', label: 'Giúp đỡ' }]} />
      </div>
      <div>
        {templates.map((message) => (
          <div
            onClick={() => {
              setIsTemplateDropdownVisible(!isTemplateDropdownVisible)
              onChange('message', message.content)
            }}
            className="template-message-item"
            key={message.id}
          >
            {message.content}
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <div className="MessageFieldComponent">
      <div className="dropdown-wrapper">
        <div className="__action-btn add-param">
          <Dropdown
            overlayClassName="border shadow-bot_1"
            overlay={ParamDropdown}
            trigger={['click']}
          >
            <div>Add parameter</div>
          </Dropdown>
        </div>
        <div className="text-brand_primary">|</div>
        <div className="__action-btn use-template">
          <Dropdown
            overlayClassName="border shadow-bot_1"
            visible={isTemplateDropdownVisible}
            overlay={TemplateDropdown}
            trigger={['click']}
          >
            <div onClick={() => setIsTemplateDropdownVisible(!isTemplateDropdownVisible)}>
              Use template
            </div>
          </Dropdown>
        </div>
      </div>
      <FormItem
        name={'message'}
        placeholder={`Input message`}
        type={'text_area'}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export default Message
