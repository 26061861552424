import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Tabs, Typography } from 'antd'
import { useStoreActions } from 'easy-peasy'
import Table from 'Components/Table'
import { COLUMNS } from '../../Customers/List/columns'
import AnalyticsContainer from 'Containers/Analytics'

import { ANALYTIC_METRICS_CODES, ANALYTIC_CHARTS_CODES } from 'Utils/constants'
const { Title } = Typography
const CJAnalytics = ({
  CJStates,
  getFilters,
  CJAnalyticsStates,
  match,
  filterList,
  getCJ,
  getCJAnalytics,
  setCJAnalyticsStates,
  resetCJState,
  resetCJAnalyticsState,
}) => {
  const { name, id } = CJStates
  const { setBreadCrumb } = useStoreActions((actions) => actions.breadcrumb)

  const getData = async (filter) => {
    setCJAnalyticsStates({ key: 'cj_metrics', value: [] })
    setCJAnalyticsStates({ key: 'cj_charts', value: [] })
    for (const code of ANALYTIC_CHARTS_CODES) {
      getCJAnalytics({ id: match.params.id, code, type: 'cj_charts', filter })
    }
    for (const code of ANALYTIC_METRICS_CODES) {
      getCJAnalytics({ id: match.params.id, code, type: 'cj_metrics', filter })
    }
  }

  React.useEffect(() => {
    setBreadCrumb([
      { title: 'Customer Journeys', path: '/customer-journeys' },
      { title: 'Analytic', path: '', isLastItem: true },
    ])
  }, [setBreadCrumb])

  useEffect(() => {
    getFilters()
    getCJ(match.params.id)

    return () => {
      resetCJState()
      resetCJAnalyticsState()
    }
  }, [])

  return (
    <div className="w-full px-8 py-6">
      <div className="analytics-titles">
        <Title className="page-title font-semibold text-3xl text-primary_text mb-4" level={3}>
          Customer Journey Analytics
        </Title>
        <Title className="page-sub-title mb-4" level={3}>
          {name}
        </Title>
      </div>
      <Tabs className="margin-content-6" defaultActiveKey="analytics">
        <Tabs.TabPane tab="Analytics" key="analytics">
          <AnalyticsContainer
            filterList={filterList}
            fields={{
              metrics: [
                'reached',
                'passed',
                'in_progressing',
                'drop_off',
                ...ANALYTIC_METRICS_CODES,
              ],
              charts: ANALYTIC_CHARTS_CODES,
            }}
            data={{ metrics: CJAnalyticsStates.cj_metrics, charts: CJAnalyticsStates.cj_charts }}
            fetchAnalytics={getData}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Customers list" key="customers">
          <Table
            tableColumns={COLUMNS}
            fetchFrom={{ url: `/customer-journeys/${id}/users?type_action=`, key: 'results' }}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
export default connect(
  (state) => ({
    CJAnalyticsStates: state.CJAnalytics,
    CJStates: state.customerJourney,
    filterList: state.audienceAnalytics.filterList,
  }),
  (dispatch) => ({
    getCJ: dispatch.customerJourney.getCJ,
    getCJAnalytics: dispatch.CJAnalytics.getCJAnalytics,
    setCJAnalyticsStates: dispatch.CJAnalytics.setCJAnalyticsStates,
    getFilters: dispatch.audienceAnalytics.getFilters,
    resetCJState: dispatch.customerJourney.resetState,
    resetCJAnalyticsState: dispatch.CJAnalytics.resetStates,
    // getNodeAnalytics: dispatch.CJAnalytics.getNodeAnalytics,
  })
)(CJAnalytics)
