import axios from 'Utils/axios'
import { message } from 'antd'
import { toCamel } from 'Utils'

const SAMPLE = [
  {
    id: 'string',
    title: 'Creative content email',
    image: '/KaribaJunoEmailTemplate.png',
  },
]

export const fetchSendGridEmails = async (page: number, perPage: number) => {
  return await axios
    .get('/utils/sendgrid_email_templates', { params: { page, per_page: perPage } })
    .then((resp) => {
      if (resp.data) {
        const emails = resp.data.results.map((d: any) => {
          const { name: title, thumbnail_url: image, id } = d
          return { id, title, image }
        })
        let { count, page } = resp.data
        return { emails, totalItem: count, page }
      }
    })
    .catch((err) => {
      message.error(err)
      return { error: err.detail }
    })
}

export const getEmailTemplate = async (id: string) => {
  const resp = await axios.get(`/utils/sendgrid_email_templates/${id}`)
  if (resp.status === 200) return resp.data.html_content
  else {
    message.error(resp)
    return { error: 'Can not fetch template' }
  }
}

export const fetchKaribaEmails = async () => {
  if (process.env.REACT_APP_COMPANY_NAME === 'juno') return SAMPLE
  return []
}
