import React, { useState, useEffect } from 'react'
import { Table, Input, Button, Modal, Empty } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'

import LoadingIcon from 'Components/LoadingIcon'
import { useFetchTables } from 'Hooks'
import Actions from './Actions'
import Comparisons from 'Components/Comparisons'

let CancelRequestTokens = []

const CustomTable = React.memo((props) => {
  const {
    tableColumns,
    fetchFrom,
    rowActions,
    hideRowSelection,
    comparisons,
    hideSearchBar,
    headButtons,
    queryObject,
  } = props
  const { data, loading, params, total, setParams, setSearchTerm, setData, cancelToken } =
    useFetchTables(fetchFrom)

  CancelRequestTokens.push(cancelToken)

  useEffect(() => {
    if (!queryObject || !Object.keys(queryObject).length) return
    setParams({ ...params, per_page: 20, page: 1, ...queryObject })
    CancelRequestTokens.forEach((cancelToken) => {
      if (cancelToken && cancelToken.cancel) cancelToken.cancel({ type: 'client_cancel' })
    })
    CancelRequestTokens = []
  }, [queryObject])

  const [selectedRows, setSelectedRows] = useState([])
  const [isComparisonVisible, setIsComparisonVisible] = useState(false)

  const renderActions = () => {
    if (!rowActions?.actions) return []
    return [
      {
        title: 'Actions',
        dataIndex: 'action',
        key: 'x',
        width: 100,
        render: (text, record) => {
          return (
            rowActions?.actions && (
              <Actions rows={data} setRows={setData} record={record} actions={rowActions.actions} />
            )
          )
        },
      },
    ]
  }

  const renderHeadButtons = () => {
    const buttons = headButtons.map((btn, index) => {
      if (typeof btn === 'object')
        return (
          <Button
            key={index}
            className={btn.className || 'create-button'}
            type={btn.type || 'primary'}
            icon={btn.icon || <PlusOutlined className="create-icon" />}
            onClick={btn.onClick || (() => {})}
          >
            {btn.name}
          </Button>
        )
      else {
        return btn(index)
      }
    })
    return <div className="head-button-wrap">{buttons}</div>
  }

  const renderTitle = () => {
    const url = new URL(window.location.href)
    const keyword = url.searchParams.get('keyword')

    return (
      <>
        <div className="title-container">
          {!hideSearchBar && (
            <Input
              disabled={loading}
              suffix={<SearchOutlined />}
              placeholder="Search table..."
              className="search-bar"
              defaultValue={keyword}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          )}

          {renderHeadButtons()}
        </div>
        <div className="checkbox-actions">
          {comparisons && selectedRows.length === 1 && (
            <div className="checkbox-action" onClick={() => setIsComparisonVisible(true)}>
              Compare last 30 days
            </div>
          )}
          {comparisons && selectedRows.length >= 2 && (
            <div className="checkbox-action" onClick={() => setIsComparisonVisible(true)}>
              Compare selected rows
            </div>
          )}
        </div>
      </>
    )
  }

  const handleTableChange = (tablePagination, filters, sorter) => {
    setParams({
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.order
        ? sorter.order === 'ascend'
          ? sorter.field
          : `-${sorter.field}`
        : null,
      ...queryObject,
    })
  }

  return (
    <>
      <Table
        title={renderTitle}
        className="TableComponent"
        dataSource={data}
        locale={{ emptyText: loading ? ' ' : <Empty></Empty> }}
        columns={[...tableColumns, ...renderActions()]}
        pagination={{
          pageSizeOptions: ['5', '10', '20', '30', '50'],
          showSizeChanger: true,
          pageSize: params.per_page,
          total: total,
          current: params.page,
        }}
        loading={{
          spinning: loading,
          indicator: <LoadingIcon style={{ position: 'absolute', top: '80%', width: '50px' }} />,
        }}
        rowKey={fetchFrom.recordKey || 'id'}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        rowClassName={(record, i) => (i % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        onRow={(record, rowIndex) => ({
          onDoubleClick: () => rowActions?.onDoubleClick && rowActions.onDoubleClick(record),
        })}
        rowSelection={
          !hideRowSelection && {
            type: 'checkbox',
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRows(selectedRows)
            },
          }
        }
      />
      <Modal
        footer={() => {}}
        onCancel={() => setIsComparisonVisible(false)}
        className="comparisons-modal"
        visible={isComparisonVisible}
        // visible={true}
      >
        <Comparisons
          compareKeys={comparisons?.compareKeys}
          analyticsFunction={comparisons?.analyticsFunction}
          visible={isComparisonVisible}
          selectedRows={selectedRows}
        />
      </Modal>
    </>
  )
})

CustomTable.defaultProps = {
  headButtons: [],
}
export default CustomTable
