import React, { useState } from 'react'
import { Card, Button, Menu, Dropdown } from 'antd'
import { RightOutlined, PlusOutlined } from '@ant-design/icons'
import { dataOptions, MOY, DOW, DOM } from './constant'
import FrequencyBody from './FrequencyBody'
import moment from 'moment'

export type TimeFormats = 'DD/MM/YYYY, HH:mm' | 'DD/MM/YYYY'
export type TimeDate = { fromTime: string; toTime: string }

export type valueTimeDate = { key: string; data: TimeDate[] }

export type valueInputDate = { key: string; data: any[] }

export type props = {
  setIsVisible: (value: true | false) => void
  value: valueInputDate | valueTimeDate
  onChange: ({ key, data }: { key?: string; data?: string[] | TimeDate[] }) => void
}

const Frequency: React.FC<props> = ({ value, onChange, setIsVisible }) => {
  const [active, setActive] = useState(false)
  const [data, setData] = useState<valueInputDate | valueTimeDate>(
    value.key === 'HOD'
      ? {
          key: 'HOD',
          data: value.data.map((time) => ({
            fromTime: time.split(' - ').length && time.split(' - ')[0],
            toTime: time.split(' - ').length && time.split(' - ')[1],
          })),
        }
      : value
  )

  const handleApply = () => {
    onChange(data)
    setIsVisible(false)
    setActive(false)
  }

  const handleChooseDate = ({ value, keyItem }) => {
    if (data.data.length > 0) {
      const check = data.data.some((item) => item == value)
      if (!check) {
        setData({ key: keyItem, data: [...data.data, value] })
      } else {
        setData({ ...data, data: data.data.filter((item) => item != value) })
      }
    } else {
      setData({ key: keyItem, data: [value] })
    }
  }

  const isActive = (value) => {
    return data?.data.some((item) => item == value)
  }

  const handleOnChangeFromDate = (dateString, ind) => {
    setData({
      ...data,
      data: data.data.map((item, index) =>
        index == ind ? { ...item, fromTime: dateString } : item
      ),
    })
  }

  const handleOnChangeToDate = (dateString, ind) => {
    setData({
      ...data,
      data: data.data.map((item, index) => (index == ind ? { ...item, toTime: dateString } : item)),
    })
  }

  const handleAddTime = () => {
    setData({ ...data, data: [...data.data, { fromTime: undefined, toTime: undefined }] })
  }

  const handleRemove = (indexItem) => {
    setData({ ...data, data: data.data.filter((item, index) => index != indexItem) })
  }

  const renderHover = (dataKey) => {
    const handleChangeType = ({ key }) => {
      key == 'HOD'
        ? setData({ key, data: [{ fromTime: undefined, toTime: undefined }] })
        : setData({ key, data: [] })

      setActive(!active)
    }

    return (
      <div
        className={`hover-frequency absolute p-2 ${!active && 'hidden'}`}
        onClick={() => setActive(false)}
        style={{ width: 200, height: 168 }}
      >
        {dataOptions.map((item) => (
          <div
            onClick={() => handleChangeType({ key: item.key })}
            className={`frequency-item ${dataKey == item.key && 'active'} py-2 px-3 cursor-pointer`}
            key={item.key}
          >
            {item.title}
          </div>
        ))}
      </div>
    )
  }
  const renderBtn = () => {
    return (
      <div style={{ borderTop: '1px solid var(--border)' }} className="text-right py-2 px-3">
        <Button
          onClick={() => handleApply()}
          type="primary"
          style={{ color: 'var(--gray_1)', fontWeight: 600 }}
          size="small"
        >
          Apply
        </Button>
      </div>
    )
  }

  const renderFrequency = () => {
    return (
      <div>
        <div className="frequency--container relative">
          <div
            onClick={() => setActive(!active)}
            className={`frequency--head ${
              active && 'active'
            } flex justify-between items-center p-2`}
            style={{}}
          >
            {dataOptions.map(
              (item) => item.key == data.key && <div key={item.key}>{item.title}</div>
            )}
            <RightOutlined />
          </div>

          <FrequencyBody
            data={data}
            keyValue={data.key}
            handleChooseDate={({ value, keyItem }) => handleChooseDate({ value, keyItem })}
            isActive={isActive}
            handleOnChangeFromDate={handleOnChangeFromDate}
            handleOnChangeToDate={handleOnChangeToDate}
            handleRemove={handleRemove}
            handleAddTime={handleAddTime}
          />
          {renderBtn()}
        </div>
        {renderHover(data.key)}
      </div>
    )
  }
  return <div className="Frequency">{renderFrequency()}</div>
}

export default Frequency
