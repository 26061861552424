import React from 'react'

type props = {
  value: number
  label: string
}

const Cores: React.FC<props> = ({ value, label }) => {
  const computeCordinate = (input: number) => {
    const initValue: number = input
    const xx: number = 24.5
    const yy: number = 23
    if (initValue <= 0.5) {
      const angle = (initValue * 90) / 0.5
      return {
        x: 23.5 - Math.abs(Math.cos((angle * Math.PI) / 180)) * 20,
        y: 23 - Math.abs(Math.sin((angle * Math.PI) / 180)) * 20,
      }
    } else if (initValue > 0.5) {
      const angle = 180 - (initValue * 90) / 0.5
      return {
        x: 24.5 + Math.abs(Math.cos((angle * Math.PI) / 180)) * 20,
        y: 23 - Math.abs(Math.sin((angle * Math.PI) / 180)) * 20,
      }
    }
  }
  const computeColor = (input: number) => {
    if (input <= 0.5) {
      return '#F5222D'
    } else if (input > 0.5 && input <= 0.7) {
      return '#FAAD14'
    } else if (input > 0.7 && input <= 1) {
      return '#52C41A'
    }
  }
  return (
    <svg viewBox="0 0 48 26" width={120} height={60}>
      <path
        id="arc"
        d="M3 24 A20 20,0, 0 1, 45 24"
        stroke="#52C41A"
        strokeWidth="1"
        fill="none"
        style={{
          strokeDasharray: '42.2 2 13 1.4',
          strokeDashoffset: '10',
          strokeLinecap: 'round',
        }}
      ></path>
      <path
        id="arc"
        d="M3 24 A20 20,0, 0 1, 45 24"
        stroke="#FAAD14"
        strokeWidth="1"
        fill="none"
        style={{
          strokeDasharray: '41.7 2 13.5 30',
          strokeDashoffset: '10',
          strokeLinecap: 'round',
        }}
      ></path>

      <path
        id="arc"
        d="M3 24 A20 20,0, 0 1, 45 24"
        stroke="#F5222D"
        strokeWidth="1"
        fill="none"
        style={{
          strokeDasharray: '42.2 0 0',
          strokeDashoffset: '10',
          strokeLinecap: 'round',
        }}
      ></path>
      <g id="points" fill="white">
        <circle cx={computeCordinate(value)?.x} cy={computeCordinate(value)?.y} r="2" />
      </g>
      <g id="points" fill={computeColor(value)}>
        <circle cx={computeCordinate(value)?.x} cy={computeCordinate(value)?.y} r="1.5" />
      </g>
      <text x="50%" dx="0" dy="-9" y="90%" textAnchor="middle" fontSize="8px">
        {value}
      </text>
      <text x="50%" y="94%" textAnchor="middle" fontSize="6px">
        {label}
      </text>
    </svg>
  )
}

export default Cores
