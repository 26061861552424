import React from 'react'
import BarChartTemplate from '../../../Components/BarChart.Template'
import MultiDoughnutChartTemplate from '../../../Components/MultiDoughnutChart.Template'
import {
  getCustomerOverviewCharts,
  getCustomer360Charts,
} from 'Services/Analytics/customer-analytics'
import { getProduct360Test, getProductOverviewCharts } from 'Services/Analytics/product-analytics'

import DoughnutChartTemplate from '../../../Components/DoughnutChart.Template'

import { CUSTOMER_DIMENSION, CUSTOMER_METRICS } from 'Pages/Analytics/constants'
import PivotTableTemplate from '../../../Components/PivotTable.Template'
import { CustomerPivotTypes, ProductPivotTypes } from 'Services/Analytics/Pivot.service'
import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'

const Chart_Pivot = () => {
  const currentPage = window?.location?.href?.split('/')[4]?.split('?')[0]
  return (
    <>
      <div className="p-4 col-span-3 shadow-div_rb">
        <div className="flex justify-between items-center">
          <div className="font-semibold">Channel Insight</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">Refresh all</div>
            <div>Download all</div>
          </div> */}
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Channel Insight</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="channel_insight"
            handleDataFunction={getProductOverviewCharts}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Product Quantity by Channel </div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden doughnutChart">
          <DoughnutChartTemplate
            chartCode="product_quantity_by_channel_insight"
            handleDataFunction={getProductOverviewCharts}
            unit="Product"
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Profit by Channel</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden doughnutChart">
          <DoughnutChartTemplate
            chartCode="profit_by_channel_insight"
            handleDataFunction={getProductOverviewCharts}
            unit="Profit"
          />
        </div>
      </div>
      <div className="p-4 col-span-3">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Product sold in Sale Campaign</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <PivotTableTemplate
          currentPage={currentPage}
          type={ProductPivotTypes.ProductSoldInSaleCampaign}
          defaultDimensions={[
            {
              code: 'category',
              name: 'Category',
            },
            {
              code: 'sub_category',
              name: 'Sub Category',
            },
            {
              code: 'product_name',
              name: 'Product Name',
            },
            {
              code: 'sku',
              name: 'SKU',
            },
            {
              code: 'image',
              name: 'Image',
            },
          ]}
          defaultMetrics={[
            { code: 'revenue', aggregate: 'sum' },
            { code: 'quantity', aggregate: 'sum' },
          ]}
          defaultColumns={[
            { code: 'membership', name: 'Membership' },
            { code: 'ltv', name: 'LTV' },
          ]}
        />
      </div>
      <div className="p-4 col-span-3">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Product sold in Sale Channel</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <PivotTableTemplate
          currentPage={currentPage}
          type={ProductPivotTypes.ProductSoldInSaleChannel}
          defaultDimensions={[
            {
              code: 'category',
              name: 'Category',
            },
            {
              code: 'sub_category',
              name: 'Sub Category',
            },
            {
              code: 'product_name',
              name: 'Product Name',
            },
            {
              code: 'sku',
              name: 'SKU',
            },
            {
              code: 'image',
              name: 'Image',
            },
          ]}
          defaultMetrics={[
            { code: 'revenue', aggregate: 'sum' },
            { code: 'quantity', aggregate: 'sum' },
          ]}
          defaultColumns={[{ code: 'sale_channel', name: 'Sale Channel' }]}
        />
      </div>
    </>
  )
}

export default Chart_Pivot
