import React, { Component } from 'react'
import { Skeleton, List } from 'antd'
import KeyMetricItemComponent from './Item'

import './index.scss'

class KeyMetrics extends Component {
  render() {
    const { loading, descriptions, data, fields = data.map((item) => item.key) } = this.props

    const dataSoruce = fields.map((field) => {
      let item = data.find((d) => d.key === field)
      return {
        key: field,
        label: field,
        value: item?.value,
      }
    })

    return (
      <div className="KeyMetricsComponent">
        <List
          className="key-metrics-list"
          itemLayout="horizontal"
          dataSource={dataSoruce}
          renderItem={({ key, value, label }) => (
            <KeyMetricItemComponent
              key={key}
              data={value}
              title={label}
              loading={value === undefined}
              description={descriptions ? descriptions[label] : null}
            />
          )}
        />
      </div>
    )
  }
}

export default KeyMetrics
