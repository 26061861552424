import React, { useState, useEffect } from 'react'
import { Button, Input, message } from 'antd'
import { useHistory } from 'react-router-dom'

import axios from 'Utils/axios'

import './index.scss'
import OverlayLoading from 'Components/OverlayLoading'
import { formatErrors } from 'Utils'
import { LoadingWithOverlay } from 'Components/LoadingIcon'
import copyTextToClipboard from 'Utils/copy'

const queryString = require('query-string')
type LinkType = {
  id?: number
  title?: string | undefined
  shorten_link?: string
}

type EditLinkPaneProps = {
  link: LinkType
  onClosePanel?: () => void
}

const EditLinkPane = (props: EditLinkPaneProps) => {
  const history = useHistory()
  const [link, setLink] = useState<LinkType | null>(null)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const { link_id } = queryString.parse(history.location.search)
        const resp = await axios.get(`/social-selling/${link_id}`)
        setLink(resp.data)
      } catch (error: any) {
        message.error(formatErrors(error?.response?.data))
      }
    })()
  }, [])

  const handleSave = async () => {
    setSaving(true)
    try {
      const reps = await axios.put(`/social-selling/${link?.id}`, { title: link?.title })
      message.success('Save success!')
      window.location.href = '/social-selling'
    } catch (error: any) {
      message.error(formatErrors(error?.response?.data))
    }
    setSaving(false)
  }

  const handleCopy = () => {
    message.success('Copied link to clipboard')
    copyTextToClipboard(link?.shorten_link || '')
  }

  if (!link) return <LoadingWithOverlay></LoadingWithOverlay>
  return (
    <div className="EditLinkPaneComponent h-full flex flex-col">
      <div className="title-wrapper p-3 shadow-div_b flex items-center">
        <h2 className="__title text-xl font-semibold ">Edit Link</h2>
      </div>
      <div className="edit-link p-3">
        <div className="form-wrapper">
          <div className="mt-4 pb-4 shadow-div_b">
            <a href="bitly.ad/3j7icdo" target="_self" className="text-brand_primary">
              {link.shorten_link}
            </a>
          </div>
          <div className="flex gap-2 shadow-div_b py-3">
            <div className="action-button font-semibold text-primary_text" onClick={handleCopy}>
              Copy
            </div>
            {/* <div className="action-button">Share</div> */}
          </div>
          <div className="mt-3 mb-2">
            <h4 className="mb-2">Tiêu đề</h4>
            <Input
              value={link.title}
              className="sidebar-input"
              onChange={(e) => {
                setLink({ ...link, title: e.target.value })
              }}
            />
          </div>
        </div>
      </div>
      <div className="pane-footer flex mt-auto shadow-div_t p-3 justify-end">
        <Button className="mr-2" onClick={props.onClosePanel}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleSave} loading={saving}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default EditLinkPane
