import React, { useEffect, useState, useMemo } from 'react'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Empty } from 'antd'
import moment from 'moment'
import axios from 'axios'
import { isEmpty } from 'lodash'

import { BarChartPlaceholder } from 'Components/Placeholders/Charts'
import ReactPlaceholder from 'react-placeholder'
import BarChart from 'Components/Charts/NewBarChart'
import useFilters from './FiltersHeader/useFilters'
import { MultiDoughnutChartType, FiltersType } from 'AnalyticsTypes'

const BarChartWrapper = ({
  handleDataFunction,
  chartCode,
  barcode,
  isStacked,
  isHorizontal,
  hideLegends,
  max = isHorizontal ? 100 : 'auto',
  isPercentage = max === 100 ? true : false,
}: {
  handleDataFunction: (filters: FiltersType) => Promise<any>
  chartCode: string
  barcode?: string
  isStacked?: boolean
  isHorizontal?: boolean
  max?: number | 'auto'
  hideLegends?: boolean
  isPercentage?: boolean
}) => {
  const history = useHistory()
  const { id } = useParams()
  const { filters: defaultFilters } = useFilters()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [chart, setChart] = useState<MultiDoughnutChartType[] | undefined>(undefined)
  const [labels, setLabels] = useState<string[] | any>(null)
  const queryObject = queryString.parse(history.location.search)

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()
    const fetchData = async () => {
      setIsLoading(true)
      try {
        setError('')
        // setChart(CHART)
        const resp = await handleDataFunction({
          barcode,
          id,
          from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
          frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
          type: chartCode,
          dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
          cancelTokenSource,
        })

        let formattedData = resp?.data?.data?.data?.map((item) => {
          const isValidDate = moment(item.label, 'MMM DD', true).isValid()

          return {
            ...item,
            label: isValidDate ? moment(item.label, 'MMM DD').format('MMM DD') : item.label,
          }
        })

        // format horizontal bar chart chart
        if (isHorizontal && !isStacked && formattedData) {
          if (!isEmpty(formattedData[0]?.values)) {
            formattedData = Object.keys(formattedData[0].values)?.map((key, index) => ({
              label: key,
              values: { [key]: formattedData[0].values[key] },
            }))
          }

          if (!isEmpty(formattedData[0]?.value)) {
            formattedData = formattedData.map((item, index) => ({
              label: item.label,
              values: { [item.label]: item.value },
            }))
          }
        }

        setChart(formattedData)
        setLabels(resp?.data?.data?.labels || [])
      } catch (err: any) {
        console.log(err)
        if (err.message === 'cancelled') return
        const errorMessage: string =
          typeof err?.response?.data?.detail === 'string'
            ? err?.response?.data?.detail
            : 'No data available'
        setError(errorMessage)
      }
      setIsLoading(false)
    }

    fetchData()
    return () => {
      setChart(undefined)
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [
    barcode,
    id,
    queryObject.from_date,
    queryObject.to_date,
    queryObject.frequency,
    queryObject.dimensions,
    chartCode,
    handleDataFunction,
  ])

  const renderChart = useMemo(() => {
    if (!chart?.length) return <Empty />

    return (
      <BarChart
        data={chart}
        labels={labels}
        hideLegends={hideLegends}
        type={isHorizontal ? 'horizontalBar' : 'bar'}
        isStacked={isStacked}
        max={max}
        isPercentage={isPercentage}
      />
    )
  }, [chart, labels, hideLegends, isHorizontal, isStacked, max, isPercentage])

  if (error) return <Alert message={error} type="error" />

  return (
    <ReactPlaceholder
      className="w-full"
      showLoadingAnimation
      ready={!isLoading}
      customPlaceholder={<BarChartPlaceholder isHorizontal={isHorizontal} />}
    >
      <div className="w-full h-full">{renderChart}</div>
    </ReactPlaceholder>
  )
}

export default BarChartWrapper
