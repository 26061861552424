import React from 'react'
import './index.scss'

const PopupPreview = ({ initData }) => {
  const {
    headline,
    promo_title,
    promo_content,
    go_back_button_label,
    go_back_button_url,
  } = initData

  const parseMd = (md) => {
    if (!md) return null
    return md.replace(
      /[\{]{2}([^\{]+)[\}]{2}/g,
      `<span
      style="
        background: #f4cd7a;
        border-radius: 2px;
        padding: 2px;
        color: white;
        font-weight: 700"
      >$1</span>`
    )
  }

  return (
    <div style={styles.PopupPreviewComponent} className="PopupPreviewComponent">
      {/* <span class="close-icon">&times;</span> */}
      <h1 style={styles.headline} className="headline">
        {headline}
      </h1>
      <div className="promo-content">
        <h2 style={styles.title} className="title">
          {promo_title}
        </h2>
        <p
          style={styles.description}
          className="description"
          dangerouslySetInnerHTML={{ __html: parseMd(promo_content) }}
        ></p>
      </div>
      <div className="promo-footer">
        <a style={styles.goBackBtn} href={go_back_button_url} className="go-back-btn">
          {go_back_button_label}
        </a>
      </div>
    </div>
  )
}

const styles = {
  PopupPreviewComponent: {
    backdropFilter: 'blur(30px)',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'spaceBetween',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid #ffff',
    boxShadow: '0 20px 15px rgba(0, 0, 0, 0.1)',
    background: 'white',
    textAlign: 'center',
    height: 'fit-content',
    minWidth: '100%',
  },

  headline: {
    fontSize: '16px',
    fontWeight: '700',
  },

  title: {
    fontSize: '40px',
    margin: '5px',
    color: '#de4862',
    textShadow: '-3px 3px 5px rgba(0, 0, 0, 0.05)',
  },

  description: {
    fontSize: '14px',
    lineHeight: '20px',
  },

  promoCode: {
    background: '#f4cd7a',
    borderRadius: '2px',
    padding: '2px',
    color: 'white',
    fontWeight: '700',
  },

  goBackBtn: {
    fontSize: '12px',
    padding: '8px',
    fontWeight: '700',
    background: 'linear-gradient(450deg, #de4862 0, rgb(255, 112, 129) 100%)',
    minWidth: '100px',
    minHeight: '30px',
    boxShadow: '0 0 10px #de4862',
    display: 'flex',
    justifyContent: 'center',
    alignUtems: 'center',
    color: 'white',
    boxSizing: 'border-box',
    // -moz-box-sizing: "border-box",
    // -webkit-box-sizing: "border-box",
    margin: '10px',
    cursor: 'pointer',
  },
}

export default PopupPreview
