import React, { useState } from 'react'
import { Badge } from 'antd'
import { colors } from 'DesignSystem'

import { Frequency } from 'Components/DatePicker'
import DatePicker from 'Components/DatePicker'
import DimensionFilters from 'Containers/DimensionFilters'
import { FilterIcon } from 'Components/Icon'
import { Dimension, Criteria } from 'DimensionsFiltersTypes'
import useFilters from './useFilters'

import './index.scss'
import moment from 'moment'

const FiltersHeader = ({ hideDimensions = false }: { hideDimensions?: boolean }) => {
  const { filters, onChangeFilters } = useFilters()
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  const fromDimensionsToCriteria = (dimensions: Dimension[]): Criteria[] => {
    return dimensions.map((dimension) => ({
      dimension: { ...dimension, condition: 'AND' },
      condition: dimension.condition,
    }))
  }

  const fromCriteriaToDimensions = (criteria: Criteria[]): Dimension[] => {
    return criteria.map((criterion) => ({ ...criterion.dimension, condition: criterion.condition }))
  }

  return (
    <div className="AnalyticsHeader mb-4">
      {/* <div className="mb-4"> */}
      {/* <Latency /> */}
      {/* </div> */}
      <div className="flex items-center justify-end gap-2">
        <div style={{ width: 132, height: 40 }}>
          <Frequency
            isDisabled={true}
            onChange={(value) =>
              onChangeFilters({
                frequency: value,
              })
            }
            value={filters.frequency}
          />
        </div>
        <div className="Product360-FilterHeader-DatePicker">
          <DatePicker
            onChange={({ from_date, to_date }) => {
              onChangeFilters({
                from_date: from_date,
                to_date: to_date,
              })
            }}
            value={{
              from_date: moment().subtract(1, 'year').format('YYYY-MM-DD'),
              to_date: moment().format('YYYY-MM-DD'),
            }}
            //isDisabled={true}
          />
        </div>
      </div>
      {!hideDimensions && (
        <div
          className="bg-white p-4 mt-4"
          style={{
            display: JSON.parse(filters?.dimensions?.toString() || '[]')?.length ? 'block' : 'none',
          }}
        >
          <DimensionFilters
            showEditButton
            list={{ customer: { page: 'analytic' } }}
            setIsPopupVisible={setIsPopupVisible}
            isPopupVisible={isPopupVisible}
            dimensions={
              filters?.dimensions
                ? fromCriteriaToDimensions(JSON.parse(filters?.dimensions?.toString()))
                : []
            }
            onSaveDimensions={(updatedDimensions: Dimension[]) => {
              onChangeFilters({
                dimensions: JSON.stringify(fromDimensionsToCriteria(updatedDimensions)),
              })
            }}
          />
        </div>
      )}
    </div>
  )
}

export default FiltersHeader
