import React from 'react'
import { Button } from 'antd'

import { PanTool as PanToolOutlined } from '@styled-icons/material-outlined/PanTool'
import { Pointer as CursorOutlined } from '@styled-icons/boxicons-regular/Pointer'
import { ZoomIn as ZoomInOutlined } from '@styled-icons/boxicons-regular/ZoomIn'
import { ZoomOut as ZoomOutOutlined } from '@styled-icons/boxicons-regular/ZoomOut'

import classNames from 'classnames'

const VIEW_TOOLS = [
  // { name: 'none', icon: CursorOutlined },
  // { name: 'pan', icon: PanToolOutlined },
  { name: 'zoom-in', icon: ZoomInOutlined },
  { name: 'zoom-out', icon: ZoomOutOutlined },
]

const CustomTools = ({ Viewer, tool, width, height }) => {
  const renderViewTools = () => {
    return VIEW_TOOLS.map((item) => {
      const Icon = item.icon
      return (
        <span
          key={item.name}
          onMouseDown={() =>
            Viewer.zoom(width / 2, height / 2, item.name === 'zoom-in' ? 1.1 : 0.9)
          } // zoom(SVGPointX, SVGPointY, scaleFactor)
          className="tool"
        >
          <Icon />
        </span>
      )
    })
  }

  return (
    <div className="CustomToolsComponent">
      {/* <div className="edit-actions">
        <UndoOutlined />
        <RedoOutlined />
      </div> */}
      <div className="view-actions">{renderViewTools()}</div>
    </div>
  )
}

export default CustomTools
