import React from 'react'
import { Tooltip } from 'antd'
import { MIN_WIDTH } from './constants'

const Bar = ({ item, index, displayPercent, onBarClick }) => {
  let percent = item.percent

  if (!item.percent) percent = 0

  const renderValue = () => {
    if (!percent) return <></>
    return (
      <span className="value">
        {Math.round(percent)}
        {displayPercent && '%'}
      </span>
    )
  }

  const computeWidth = () => {
    let width = Math.round(percent)
    if (Math.round(percent) < MIN_WIDTH) {
      if (Math.round(percent) === 0) width = 0
      else width = MIN_WIDTH
    }
    return `${width}%`
  }

  return (
    <span
      style={{ width: computeWidth() }}
      className={`block block-${index} ${onBarClick ? '__clickable' : ''}`}
      title={`${item.label}: ${percent}%`}
      onClick={() => onBarClick && onBarClick(item)}
    >
      {onBarClick ? (
        <Tooltip title="Add new condition with this stat">{renderValue()}</Tooltip>
      ) : (
        renderValue()
      )}
    </span>
  )
}

export default Bar
