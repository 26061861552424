import React from 'react'
import { Tooltip, Button } from 'antd'
import { ActionType, RecordType } from '../index.d'
import '../index.scss'

const Actions: React.FC<{
  actions: ActionType[]
  record: RecordType
  setRows: (data: any[]) => void
  rows: any[]
}> = ({ actions, record, setRows, rows }) => {
  return (
    <div className="TableActionsComponent">
      {actions.map((action, index) => {
        if (action.component) {
          return <div key={index}>{action.component(record)} </div>
        } else if (action.attribute && action.icon && action.onClick)
          return (
            <Tooltip key={index} title={action.attribute.label}>
              <Button
                className={action.attribute.type || 'default'}
                onClick={() => action.onClick && action.onClick(record, setRows, rows)}
              >
                {action.icon}
              </Button>
            </Tooltip>
          )
      })}
    </div>
  )
}

export default Actions
