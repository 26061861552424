import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'

const AnalyticsLatency = () => {
  return (
    <div className="w-full bg-brand_light border border-brand_primary px-4 py-2 rounded flex items-center">
      <InfoCircleOutlined className="text-brand_primary text-xl" />
      <span className="ml-3">Your data is being late for 1 day</span>
    </div>
  )
}

export default AnalyticsLatency
