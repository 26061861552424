import React, { useState, useEffect } from 'react'
import { Switch, Dropdown, DatePicker as DatePickerComponent } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import moment from 'moment'
import clsx from 'clsx'

import { renderRangeOptions } from './constants'

type props = {
  isTimeOptionsDisabled?: boolean
  value: { from_date: string; to_date: string }
  onChange: ({ from_date, to_date }: { from_date?: string; to_date?: string }) => void
}

const TimeRange: React.FC<props> = ({ value, onChange, isTimeOptionsDisabled }) => {
  const [endDateTrigger, setEndDateTrigger] = useState(false)
  const [includeTimeTrigger, setIncludeTimeTrigger] = useState(
    !!new RegExp(/^^\d{2}-\d{2}-\d{4},\d{2}:\d{2}$/).test(value.from_date)
  )

  const format = includeTimeTrigger === true ? 'DD-MM-YYYY,HH:mm' : 'DD-MM-YYYY'

  const renderWithTime = (value) => {
    return moment(value, 'DD-MM-YYYY,HH:mm').format('DD-MM-YYYY,HH:mm').toString()
  }
  const renderWithoutTime = (value) => {
    return moment(value, 'DD-MM-YYYY').format('DD-MM-YYYY').toString()
  }

  const disabledDateToDate = (valuePicker) => {
    return (
      (valuePicker && valuePicker < moment(value.from_date, format)) ||
      (valuePicker && valuePicker > Date.now())
    )
  }

  const disabledDateFromDate = (valuePicker) => {
    return (valuePicker && valuePicker > moment(value.to_date, format)) || valuePicker > Date.now()
  }

  return (
    <div className="flex flex-col bg-gray_1 shadow-bot_1 pt-2" style={{ minWidth: 300 }}>
      <div className="flex gap-2 timeRange">
        <DatePickerComponent
          className="flex-1"
          onChange={(date, dateString) => {
            onChange({
              ...value,
              from_date: moment(dateString, format).format(format).toString(),
            })
          }}
          onOk={(date) =>
            onChange({
              ...value,
              from_date: moment(date, format).format(format).toString(),
            })
          }
          showTime={includeTimeTrigger && { format: 'HH:mm' }}
          showNow={false}
          showToday={false}
          format={format}
          allowClear={false}
          value={value.from_date ? moment(value.from_date, format) : null}
          dropdownClassName="disable-use-click-outside"
          placeholder="From date"
          disabledDate={disabledDateFromDate}
          getPopupContainer={(trigger: any) => trigger.parentNode}
          renderExtraFooter={() => (
            <div>
              <div className="flex justify-between items-center">
                <div>End date</div>
                <Switch
                  disabled={isTimeOptionsDisabled}
                  defaultChecked
                  onChange={() => {
                    setEndDateTrigger(!endDateTrigger)
                    onChange({
                      ...value,
                      to_date: '',
                    })
                  }}
                ></Switch>
              </div>
              <div className="flex justify-between items-center">
                <div>Include time</div>
                <Switch
                  checked={includeTimeTrigger}
                  onChange={() => {
                    setIncludeTimeTrigger(!includeTimeTrigger)
                    onChange({
                      from_date: !includeTimeTrigger
                        ? renderWithTime(value.from_date)
                        : renderWithoutTime(value.from_date),
                      to_date: value.to_date
                        ? !includeTimeTrigger
                          ? renderWithTime(value.to_date)
                          : renderWithoutTime(value.to_date)
                        : '',
                    })
                  }}
                ></Switch>
              </div>
            </div>
          )}
        />

        <DatePickerComponent
          className="flex-1"
          onChange={(date, dateString) => {
            onChange({ ...value, to_date: moment(dateString, format).format(format).toString() })
          }}
          allowClear={false}
          onOk={(date) => {
            onChange({ ...value, to_date: moment(date, format).format(format).toString() })
          }}
          value={value.to_date ? moment(value.to_date, format) : null}
          showNow={false}
          showTime={includeTimeTrigger && { format: 'HH:mm' }}
          format={`${includeTimeTrigger == true ? 'DD-MM-YYYY, HH:mm' : 'DD-MM-YYYY'}`}
          showToday={false}
          dropdownClassName="disable-use-click-outside"
          placeholder="To date"
          disabledDate={disabledDateToDate}
          getPopupContainer={(trigger: any) => trigger.parentNode}
          disabled={isTimeOptionsDisabled || endDateTrigger}
          renderExtraFooter={() => (
            <div className="flex justify-between items-center">
              <div>Include time</div>
              <Switch
                checked={includeTimeTrigger}
                onChange={() => {
                  setIncludeTimeTrigger(!includeTimeTrigger)
                  onChange({
                    from_date: value.from_date
                      ? !includeTimeTrigger
                        ? renderWithTime(value.from_date)
                        : renderWithoutTime(value.from_date)
                      : '',
                    to_date: value.to_date
                      ? !includeTimeTrigger
                        ? renderWithTime(value.to_date)
                        : renderWithoutTime(value.to_date)
                      : '',
                  })
                }}
              ></Switch>
            </div>
          )}
        />
      </div>
      <div className="pt-2">
        {isTimeOptionsDisabled
          ? null
          : renderRangeOptions(format).map((option) => (
              <div
                key={option.label}
                onClick={() => {
                  onChange(
                    !endDateTrigger
                      ? {
                          from_date: option.value.from_date,
                          to_date: option.value.to_date,
                        }
                      : { from_date: option.value.from_date }
                  )
                }}
                className={clsx(
                  'hover:bg-hover_overlay hover:text-brand_primary py-1 px-3 mb-1 cursor-pointer rounded',
                  option.value.from_date === value.from_date &&
                    option.value.to_date === value.to_date &&
                    'font-semibold text-brand_primary'
                )}
              >
                {option.label}
              </div>
            ))}
      </div>
    </div>
  )
}

export default TimeRange
