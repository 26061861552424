import React, { useMemo } from 'react'
import { Select } from 'antd'
import CommonTitle from '../CommonTitle'
import { Field, Form } from 'formik'
import FieldWrapper from '../FieldWrapper'
import { connect } from 'react-redux'
import './index.scss'

const { Option } = Select

const MixSelectField = (props) => {
  const { multi, options, disabled, name, values, onChange } = props

  function handleChangeSelect(value) {
    onChange(name, value)
    props.setFieldValue(name, value)
  }

  const select = () => (
    <div
      className="Mixtype-Select-Input flex flex-col"
      style={{
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderTop:
          !values?.button_nav_screen && !values?.button_deeplink ? '1px solid red' : ' none',
        borderRight:
          !values?.button_nav_screen && !values?.button_deeplink ? '1px solid red' : 'none',
        borderLeft:
          !values?.button_nav_screen && !values?.button_deeplink ? '1px solid red' : 'none',
      }}
    >
      <Select
        mode={multi ? 'multiple' : ''}
        disabled={values?.button_deeplink ? true : false}
        style={{ width: '100%' }}
        placeholder="Select an option"
        name={name}
        value={values[name]}
        onChange={handleChangeSelect}
        allowClear
      >
        {options.map(({ name, code }) => (
          <Option key={code}>{name}</Option>
        ))}
      </Select>
    </div>
  )

  return <FieldWrapper {...props}>{(fieldProps) => select()}</FieldWrapper>
}

MixSelectField.defaultProps = {
  multi: false,
  disabled: false,
  options: [
    { name: 'Option 1', code: 'Op1' },
    { name: 'Option 2', code: 'Op2' },
    { name: 'Option 3', code: 'Op3' },
  ],
  codes: null,
  handleChange: () => {},
}

export default connect(null, (dispatch) => ({
  setPreviewData: dispatch.campaign.creativeContents.setPreviewData,
}))(MixSelectField)
