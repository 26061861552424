import React, { useState, useEffect } from 'react'

import { systemColor } from 'DesignSystem/Colors'
import LoadingIcon from 'Components/LoadingIcon'
import { Tooltip } from 'antd'
export interface PercentCustomerProps {
  count: number
  total?: number
  className?: string
  isLoading: boolean
  label: string
}

const radius = '100'

const PercentCustomer: React.FC<PercentCustomerProps> = ({
  count,
  total,
  className,
  label,
  isLoading,
}) => {
  const [percent, setPercent] = useState<number>(100)
  const circumference = parseInt(radius) * 2 * Math.PI
  const percentCircum = (percent * circumference) / 100

  const computeColor = () => {
    if (percent === 0) return systemColor.gray_9
    if (percent < 50) return systemColor.red_6
    if (percent < 80) return systemColor.yellow_6
    return systemColor.blue_6
  }

  useEffect(() => {
    if (total == 0 && count == 0) {
      setPercent(100)
    }
    if (total) {
      setPercent(0)
    }
    if (total && count) {
      const customerPercent = Number((count / total).toFixed(4)) * 100
      setPercent(customerPercent)

      // if (percent !== customerPercent) {
      //   const isPositive = customerPercent - percent > 0
      //   setTimeout(() => setPercent(isPositive ? percent + 1 : percent - 1), 10)
      // }
    }
  }, [count, total])

  if (isLoading)
    return (
      <div
        style={{ width: 200, height: 200 }}
        className="m-auto flex w-full justify-center items-center p-5"
      >
        <LoadingIcon style={{ width: 40, top: 0 }} />
      </div>
    )

  return (
    <Tooltip
      overlayClassName="PercentCustomerTooltip "
      placement="right"
      title={<div>Total Audience = Web visitor + App visitor + Customer in last 12 months</div>}
    >
      <div className="percent-customer">
        <div className="percent-customer-wrapper ">
          <svg
            style={{ '--percent-customer': `${percent * 3.6}deg` } as React.CSSProperties}
            className={`percent-customer-ring  ${percent !== 100 ? 'set-percent' : ''}`}
            width="200"
            height="200"
          >
            <circle
              className="percent-customer-ring__circle"
              stroke="#F5F5F5"
              strokeWidth="20"
              fill="transparent"
              r={radius}
              cx="100"
              cy="100"
            />
            <circle
              style={{
                // strokeDashoffset: circumference - (percent / 100) * circumference,
                // strokeDasharray: `${circumference}, ${circumference}`,
                strokeDasharray: `${percentCircum},${circumference - percentCircum}`,
              }}
              className="percent-customer-ring__circle"
              stroke={computeColor()}
              strokeWidth="200"
              fill="transparent"
              r={radius}
              cx="100"
              cy="100"
            />
          </svg>

          <div className="percent-customer-text" style={{ color: computeColor() }}>
            <div className="percent-customer-text-wrapper">
              <div className="text-2xl font-bold">
                {/* {Math.ceil((percent * total) / 100).toLocaleString()} */}
                {count?.toLocaleString()}
              </div>
              {total ? (
                <>
                  <div className="whitespace-nowrap text-gray_8">
                    / {total?.toLocaleString()} {label}
                  </div>
                  <div className="mt-4 font-bold">{percent.toFixed(2) || 100}%</div>
                </>
              ) : (
                <div className="whitespace-nowrap text-gray_8">{label} </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  )
}

export default PercentCustomer
