import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useStoreState, useStoreActions, createTypedHooks } from 'easy-peasy'
import { ModelTypes } from 'Models/index.types'
import queryString from 'query-string'
import { Modal, Empty, message } from 'antd'
import HRWUserItem from './Components/HRWUserItem'
import isEmpty from 'lodash/isEmpty'
import originalAxios from 'axios'
import axios from 'Utils/axios'
import LoadingIcon from 'Components/LoadingIcon'

const typedHooks = createTypedHooks<ModelTypes>()

const API_HOST = (process as any).env.REACT_APP_API_HOST + process.env.REACT_APP_API_VERSION

const HaravanLoginSuccess = () => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [confirmInfoError, setConfirmInfoError] = useState('')
  const [selectedHRWUserId, setSelectedInfo] = useState<null | string>(null)
  const setToken = typedHooks.useStoreActions((actions) => actions.auth.setToken)
  const setUserInfo = typedHooks.useStoreActions((actions) => actions.auth.setUserInfo)
  const userInfo = typedHooks.useStoreState((state) => state.auth.userInfo)

  const history = useHistory()
  const location = useLocation()
  const params = queryString.parse(location.search)

  const navigateWhenSynced = () => {
    if (params.source === 'kariba') history.push('/')
    else if (params.source === 'harasocial')
      window.location.href = `${process.env.REACT_APP_HARASOCIAL_URL}/inbox`
  }

  useEffect(() => {
    const checkUserInfo = async () => {
      const { token, token_type, is_synced_with_hrw, source } = params
      if (is_synced_with_hrw == 'false') setVisibleModal(true)
      setToken({ token: token, tokenType: token_type })
      let config = { headers: { Authorization: `${token_type} ${token}` } }
      let resp = await originalAxios.get(`${API_HOST}/agents/me`, config)
      if (resp.status === 401) message.error(resp.data.detail)
      setUserInfo(resp.data)
    }
    checkUserInfo()
  }, [])

  useEffect(() => {
    const { is_synced_with_hrw, provider } = userInfo
    if (provider === 'kariba') history.push('/')
    else if (is_synced_with_hrw && provider === 'haravan') {
      setVisibleModal(false)
      navigateWhenSynced()
    }
  }, [userInfo])

  const handleOk = () => {
    setIsConfirming(true)
    axios
      .post('/agents/merge_haraworks', { selected_employee_id: selectedHRWUserId })
      .then((rs) => {
        const { access_token, token_type } = rs.data
        setToken({ token: access_token, tokenType: token_type })
        navigateWhenSynced()
      })
      .catch((err) => {
        setConfirmInfoError(err.response.detail)
        setIsConfirming(false)
      })
  }

  const handleCancel = () => {
    history.push('/login/haravan/fail')
  }

  const modalContent = () => {
    if (isEmpty(userInfo))
      return (
        <div className="flex content-center justify-center">
          <LoadingIcon></LoadingIcon>
        </div>
      )
    if (!Array.isArray(userInfo.hrw_info) || !userInfo.hrw_info.length)
      return <Empty description="Không tìm thấy thông tin xác thực."></Empty>

    const UserItems = userInfo.hrw_info.map((hrwUser) => (
      <HRWUserItem
        key={hrwUser.employee_id}
        selectedHRWUserId={selectedHRWUserId}
        onCardSelect={setSelectedInfo}
        user={{
          employeeId: hrwUser.employee_id,
          fullName: hrwUser.full_name,
          phone: hrwUser.phone,
          jobs: hrwUser.job,
        }}
      ></HRWUserItem>
    ))

    return (
      <div>
        {UserItems}
        {confirmInfoError && (
          <p className="text-red-400 text-lg font-semibold">{confirmInfoError}</p>
        )}
      </div>
    )
  }

  return (
    <div className="HaravanLoginSuccessPage">
      <Modal
        title="Xác nhận thông tin"
        visible={visibleModal}
        closable={false}
        cancelText="Thông tin xác nhận không chính xác"
        okText="Xác nhận"
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ htmlType: 'button' }}
        okButtonProps={{ disabled: !selectedHRWUserId, loading: isConfirming }}
      >
        {modalContent()}
      </Modal>
    </div>
  )
}

export default HaravanLoginSuccess
