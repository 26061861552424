import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import { COLOR_PALLETTE } from 'Utils/constants'
import DoughnutTextInsidePlugin from '../Plugins/DoughnutTextInside'
import { colors } from 'DesignSystem'
import kFormatter from 'Utils/kFormatter'
import { isEmpty } from 'lodash'

export const drawChart = (data, pieChartRef, { centerText }) => {
  Chart.register(ChartDataLabels)
  if (!pieChartRef || !pieChartRef.current || !data || isEmpty(data)) return

  let target = pieChartRef.current as unknown as HTMLCanvasElement // todo: cheat, find better later
  /* tslint:disable-next-line */
  let ctx: any = target?.getContext('2d')
  /* tslint:disable-next-line */

  var seriesData = Object.keys(data).map((key) => data[key] || null)
  var total = seriesData.reduce((a, v) => a + v)
  var inPercent = seriesData.map((v) => Math.max((v / total) * 100, 1))

  return new Chart(ctx, {
    type: 'doughnut',
    plugins: [
      {
        id: 'DoughnutTextInsidePlugin',
        beforeDraw: DoughnutTextInsidePlugin,
      },
    ],
    data: {
      datasets: [
        {
          data: inPercent,
          backgroundColor: COLOR_PALLETTE,
          borderWidth: 2,
        },
      ],
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: Object.keys(data).map((key) => key),
    },
    options: {
      responsive: true,
      rotation: 180,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
        // rotation: true,
      },
      cutout: '60%',
      plugins: {
        datalabels: {
          // display: false,
          formatter: function (value, ctx) {
            let itemData: any = seriesData[ctx.dataIndex]
            let percent = ((itemData / total) * 100).toFixed(1) + '%'
            if (parseInt(percent) < 5) {
              if (ctx.active) return percent
              return ''
            }
            return percent
          },
          color: 'black',
          labels: {
            title: {
              font: {
                size: 9,
                weight: 500,
              },
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        // @ts-ignore
        tooltip: {
          enabled: true,
          callbacks: {
            // @ts-ignore
            afterLabel: function (item, data) {
              let itemData = seriesData[item.dataIndex]
              let percent = ((itemData / total) * 100).toFixed(1) + '%'
              return `${kFormatter(itemData)} - ${percent}`
            },
            // @ts-ignore
            label: function (context) {
              return context.label
            },
          },
          borderWidth: 1,
          borderColor: colors.productColor.brand_primary,
          bodyColor: '#000',
          backgroundColor: '#FFF',
          // titleFontSize: 16,
          // bodyFontColor: '#000',
          // bodyFontSize: 14,
        },
      },
      elements: {
        // @ts-ignore
        center: {
          text: centerText,
          color: colors.productColor.black, // Default is #000000
          padding: 18,
          fontStyle: 'Arial', // Default is Arial
          sidePadding: 20, // Default is 20 (as a percentage)
          minFontSize: 14, // Default is 20 (in px), set to false and text will not wrap.
          lineHeight: 20, // Default is 25 (in px), used for when text wraps
        },
      },
    },
  })
}
