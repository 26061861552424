import JunoLogo from 'Assets/images/brand_juno_logo.png'
import TCHLogo from 'Assets/images/brand_tch_logo.png'

type BrandVars = {
  logo: React.ElementRef<any>
  url: string
  color: string
}

export const brandVars = (): BrandVars => {
  let junoVars = {
    logo: JunoLogo,
    url: 'www.juno.vn',
    color: '#e11c22',
  }
  switch (process.env.REACT_APP_COMPANY_NAME) {
    case 'juno':
      return junoVars
    case 'thecoffeehouse':
      return {
        logo: TCHLogo,
        url: `${process.env.REACT_APP_COMPANY_WEBSITE}`,
        color: '#ea7f23',
      }
    default:
      return junoVars
  }
}
