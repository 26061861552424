import React from 'react'

import CheckPermission from 'Components/CheckPermission'
import List from './List/index'
import { New, Edit } from 'Containers/Campaign/MainFlow'

import './index.scss'

const allowRoles = ['owner']

const CampaignList = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
}

const CampaignNew = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={New} {...props} />
}

const CampaignDetail = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={Edit} {...props} />
}

export { CampaignList, CampaignNew, CampaignDetail }
