import { action, thunk } from 'easy-peasy'
import axios from 'Utils/axios'
import { cleanStorage } from 'Utils'
const initState = { userInfo: {}, roles: [], token: null, tokenType: null }

export default {
  ...initState,
  setToken: action((state, { token, tokenType }) => {
    state.token = `${tokenType} ${token}`
    localStorage.setItem('Authorization', `${tokenType} ${token}`)
  }),
  setUserInfo: action((state, userInfo) => {
    // id: undefined, name: '', email: '', roles: [],
    state.userInfo = userInfo
  }),
  register: thunk(async (actions, payload) => {
    try {
      const resp = await axios.post(
        '/agents/register',
        {
          ...payload,
          captcha_token: undefined,
        },
        { params: { captcha_token: payload.captcha_token } }
      )
      return resp
    } catch (error) {
      return { errors: error.response.data }
    }
  }),
  fetchMe: thunk(async (actions, payload) => {
    const resp = await axios.get('/agents/me', payload)
    if (resp.status === 200) return actions.setUserInfo(resp.data)
  }),
  forgotPassword: thunk(async (actions, payload) => {
    const resp = await axios.post(
      '/agents/forgot-password',
      {
        ...payload,
        captcha_token: undefined,
      },
      { params: { captcha_token: payload.captcha_token } }
    )
    if (resp.status === 200) return resp
    return { errors: resp.response }
  }),
  resetPassword: thunk(async (actions, payload) => {
    const resp = await axios.post(
      '/agents/reset-password',
      {
        ...payload,
        captcha_token: undefined,
      },
      { params: { captcha_token: payload.captcha_token } }
    )
    if (resp.status === 200) return resp
    return { errors: resp.response }
  }),
  login: thunk(async (actions, payload) => {
    const resp = await axios.post(
      '/agents/login',
      {
        ...payload,
        captcha_token: undefined,
      },
      { params: { captcha_token: payload.captcha_token } }
    )

    if (resp.status === 200)
      actions.setToken({ token: resp.data.access_token, tokenType: resp.data.token_type })
    return { errors: resp.response }
  }),
  logOut: action((state, _) => {
    state = { ...initState }
    cleanStorage()
    window.location.href = '/login'
  }),
}
