import React from 'react'
import { Card } from 'antd'
import icon_fb from 'Assets/images/icon-fb.png'

const { Meta } = Card

const PostEngagement = ({ image_url }) => {
  return (
    <Card
      className="preview-info bg-white"
      cover={
        <img
          alt="example"
          src={image_url || 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'}
        />
      }
      actions={[
        // <span><LikeOutlined key="like"/> Like</span>,
        <span className="action-items">
          <span
            className="item like-action"
            style={{
              background: `url(${icon_fb}) no-repeat`,
            }}
          ></span>
          &nbsp;
          <strong className="item-text">Like</strong>
        </span>,
        <span className="action-items">
          <span
            className="item comment-action"
            style={{
              background: `url(${icon_fb}) no-repeat`,
            }}
          ></span>
          &nbsp;
          <strong className="item-text">Comment</strong>
        </span>,
        <span className="action-items">
          <span
            className="item share-action"
            style={{
              background: `url(${icon_fb}) no-repeat`,
            }}
          ></span>
          &nbsp;
          <strong className="item-text">Share</strong>
        </span>,
      ]}
    >
      <Meta
        description={
          <>
            <div className="preview-description__postEngagement">
              {/* <div className="emote-list">
              <Avatar className="emote-item" size={22} src={like_emote}></Avatar>&nbsp;
              <Avatar className="emote-item" size={22} src={love_emote}></Avatar>&nbsp;
              <Avatar className="emote-item" size={22} src={angry_emote}></Avatar>&nbsp;
              <span>Tamhong...</span>&nbsp;
            </div>
            <strong className="action-text">12 Comments</strong>&nbsp;
            <strong className="action-text">16 Shares</strong> */}
            </div>
            <div className="horizon-line_bg">
              <div className="horizon-line_content"></div>
            </div>
          </>
        }
      />
    </Card>
  )
}

export default PostEngagement
