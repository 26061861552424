import React, { useEffect, useMemo, useState } from 'react'
import './index.scss'
import { Alert, Card, Empty, Skeleton } from 'antd'
import ProductTag from './Tag'
import Product from 'Assets/images/product_img.png'
import { useParams } from 'react-router-dom'
import { ProductInfosCard } from 'Product360Types'
import { getProduct360DetailInfos } from 'Services/Analytics/product-analytics'
import { productColor } from 'DesignSystem/Colors'
import kFormatter from 'Utils/kFormatter'

const Product360Info = () => {
  const { id } = useParams()
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<ProductInfosCard>()

  useEffect(() => {
    const handleData = async () => {
      try {
        setError('')
        const resp = await getProduct360DetailInfos(id)
        //console.log('Product360 info resp: ', resp)
        setData(resp.data)
        // setData(CONTENT_2)
      } catch (err: any) {
        setError(err?.response?.data?.detail || 'Something went wrong')
      }
      setIsLoading(false)
    }
    handleData()
  }, [id])
  //console.log('Product360 info data: ', data)
  const renderCard = useMemo(() => {
    if (!data) return <Empty />
    return (
      <Card style={{ minHeight: 160 }} className="Product360-body-info">
        <div
          className="product360-info-item-img"
          style={{ width: 128, height: 128, backgroundColor: '#000' }}
        >
          <img src={data?.image} style={{ width: 128, height: 128 }}></img>
        </div>
        <div className="flex flex-col justify-between" style={{ width: '78%' }}>
          <div className="product360-info-item-textfield">
            <div className="product360-info-item-title">
              <h3>{data?.product_name}</h3>
              <div className="flex flex-row text-lg text-color-light">
                <div>SKU: </div>
                <div className="ml-1"> {data?.sku}</div>
              </div>
            </div>

            <div className="product360-info-item-detail">
              <div className="flex flex-row">
                <div>Expiration Date: </div>
                <div className="text-color-light ml-1">{data?.expiredation_date}</div>
              </div>
              <div className="flex flex-row">
                <div>Selling Price: </div>
                <div className="text-color-light ml-1"> {kFormatter(data?.selling_price)} đ</div>
              </div>
              <div className="flex flex-row">
                <div>Unit Price: </div>
                <div className="text-color-light ml-1"> {kFormatter(data?.unit_price)} đ</div>
              </div>
            </div>
            <div className="product360-info-item-supplier">
              <div className="flex flex-row">
                <div>Suppiler: </div>
                <div className="text-color-light ml-1"> {data?.supplier}</div>
              </div>
              <div className="flex flex-row">
                <div>Occasion: </div>
                <div className="text-color-light ml-1"> {data?.occasion}</div>
              </div>
            </div>
          </div>
          <ProductTag
            mix_category_name={data?.mix_category_name}
            psi_status={data?.psi_status}
            sale_status={data?.sale_status}
          ></ProductTag>
        </div>
        <div
          className="product360-info-item-ranking"
          style={{ backgroundColor: `var(--${data?.ranking?.toLocaleLowerCase()}_7)` }}
        >
          Ranking: {data?.ranking}
        </div>
      </Card>
    )
  }, [data])
  if (error) return <Alert message={error} type="error" />

  return (
    <Skeleton loading={isLoading} active className="p-4">
      <div>{renderCard}</div>
    </Skeleton>
  )
}

export default Product360Info
