import React, { useState, useEffect } from 'react'
import { message, Modal, Typography } from 'antd'
import { filtersType } from 'CustomerSegmentTypes'
import { useHistory } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import { BarChartOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { ReactComponent as Duplicate } from 'Assets/images/icons/duplicate.svg'
import { ReactComponent as Edit } from 'Assets/images/icons/edit.svg'
import { ReactComponent as Analytic } from 'Assets/images/icons/analytic.svg'
import { ReactComponent as Delete } from 'Assets/images/icons/deleteAudience.svg'

import { formatErrors } from 'Utils'
import { delAudienceSegment, getCreators } from 'Services/customer-segments'

import Table from 'Components/Table2.0'
import { COLUMNS, FILTER_TABS, STATUS } from './constants'
import '../index.scss'

import { setSegmentData } from '../Detail/helpers'
import { getAudienceSegment } from 'Services/customer-segments'

const CustomerSegments = () => {
  const history = useHistory()
  const { setState } = useStoreActions((action: any) => action.customerSegments)

  const [filters, setFilters] = useState<filtersType>({
    user_source: [],
    used_in: [],
    created_by: [],
    segment_status: window.location.href.split('tab=')[1] || 'published',
  })
  const setBreadCrumb = useStoreActions((actions: any) => actions.breadcrumb.setBreadCrumb)

  const [searchTerm, setSearchTerm] = useState('')
  const [creators, setCreators] = useState([])

  const handleDelete = async (record, setRows, rows) => {
    Modal.confirm({
      title: (
        <div className="action-prompt-popup">
          <Typography.Title level={4}>Are you sure to delete {record.name}?</Typography.Title>
        </div>
      ),
      onOk: async () => {
        try {
          await delAudienceSegment(record.id)
          message.success('Deleted customer segment')
          setRows(rows.filter((row) => row.id !== record.id))
        } catch (err: any) {
          message.error(formatErrors(err))
          throw new Error(err)
        }
      },
      okText: 'Delete',
      okType: 'danger',
      centered: true,
    })
  }

  useEffect(() => {
    setBreadCrumb([{ title: 'Segments', path: `/customers/segments` }])
  }, [history])

  useEffect(() => {
    const fetchSegmentCreators = async () => {
      try {
        const resp = await getCreators()
        setCreators(
          resp.data.map((creator) => ({
            label: `${creator.first_name} ${creator.last_name}`,
            value: creator.id.toString(),
          }))
        )
      } catch (err: any) {
        message.error(err.message)
      }
    }
    fetchSegmentCreators()
  }, [])

  const handleCreate = async () => {
    history.push('./segments/new')
  }

  const handleEdit = async (record) => {
    history.push(`./segments/${record.id}`)
  }

  const handleDuplicate = async (record) => {
    setFilters({
      ...filters,
      isLoading: true,
    })

    try {
      const resp = await getAudienceSegment(record.id)
      var dataDuplicate = { ...resp.data, name: '[Copy] ' + resp.data.name }

      setSegmentData(dataDuplicate, setState)

      if (resp.data.custom_sql) {
        history.push({ pathname: `./segments/new`, search: `?screen=CUSTOM_SQL` })
      } else {
        history.push({ pathname: `./segments/new`, search: `?screen=BLANK` })
      }
    } catch (err: any) {}

    setFilters({
      ...filters,
      isLoading: false,
    })
  }
  const handleStatistics = async (record) => {
    window.open(`/analytics/segment360/${record.id}`, '_self')
  }

  const handleTableChange = (tablePagination, _, sorter) => {
    setFilters({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.order
        ? sorter.order === 'ascend'
          ? sorter.field
          : `-${sorter.field}`
        : null,
    })
  }

  return (
    <Table
      className="Customer-Segments-table px-8 py-7"
      dropdown={{
        fields: FILTER_TABS(creators, filters, setFilters),
        value: filters,
      }}
      search={{ keyword: searchTerm, onSearch: setSearchTerm }}
      columns={COLUMNS}
      title="Customer Segments"
      fetchFrom={{ url: '/audience-segments/', key: 'results' }}
      handleTableChange={handleTableChange}
      rowActions={{
        onDoubleClick: handleEdit,
        actions: [
          {
            icon: <Analytic />,
            attribute: { type: 'highlight', label: 'Performance' },
            onClick: handleStatistics,
          },
          { icon: <Edit />, attribute: { label: 'Edit' }, onClick: handleEdit },
          {
            icon: <Duplicate />,
            attribute: { label: 'Duplicate' },
            onClick: handleDuplicate,
          },
          {
            icon: <Delete />,
            attribute: { label: 'Delete' },
            onClick: handleDelete,
          },
        ],
      }}
      primaryButton={{ title: 'Create segment', onClick: handleCreate }}
    />
  )
}
export default CustomerSegments
