import React, { useEffect, useState } from 'react'
import { Avatar } from 'antd'
import antdMessage from 'antd/lib/message'
import { EllipsisOutlined } from '@ant-design/icons'
import { MdPublic } from 'react-icons/md'
import { useStoreState } from 'easy-peasy'
import axios from 'Utils/axios'
// import icon_fb from 'Assets/images/icon-fb.png'
// import like_emote from 'Assets/images/like-emote-fb.png'
// import love_emote from 'Assets/images/love-emote-fb.png'
// import angry_emote from 'Assets/images/angry-emotion-fb.svg'

import PostEngagement from './PostEngagement'
import LinkClicks from './LinkClicks'

import '../index.scss'
import { formatErrors } from 'Utils'
const { error } = antdMessage
export default function FBPreview({ currentPlacement, initData }) {
  const options = useStoreState((state) => state.campaign.creativeContents.options)
  const { call_to_action, page_id, message } = initData
  const [page, setPage] = useState({})
  const [callToAction, setCallToAction] = useState('')

  useEffect(() => {
    async function callPages(page_id) {
      const pageArray = await axios
        .get('/agents/connection-profiles/facebook/pages')
        .then((res) => res.data.data)
        .catch((err) => {
          // error(formatErrors(err?.response?.data?.detail) || 'Connect Facebook Profile fail!')
        })
      if (!pageArray) return
      const avatar = await axios
        .get(`/agents/connection-profiles/facebook/pages/${page_id}/avatar`)
        .then((res) => res.data)
        .catch((err) => {
          // error(formatErrors(err?.response?.data?.detail) || 'Connect Facebook Profile fail!')
        })
      //
      const page_item = pageArray?.filter((ele) => ele.id === page_id)[0]?.name
      setPage({ ...page, name: page_item, avatar: avatar })
    }
    callPages(page_id)
    //set call to action name
    const callToAction_item = options?.filter((ele) => ele.code === call_to_action)[0]?.name
    setCallToAction(callToAction_item)
  }, [page_id, call_to_action])

  //link_clicks
  //post_engagement
  // check current placement to display
  const checkRenderBody = (code) => {
    switch (code) {
      case 'link_clicks':
        return <LinkClicks {...initData} callToAction={callToAction} />
      case 'post_engagement':
        return <PostEngagement {...initData} />

      default:
        return null
    }
  }

  return (
    <div className="FBPreviewComponent">
      <header className="preview__header">
        <Avatar src={page?.avatar} className="preview__header--avatar" size={42} />
        <span>
          {/* <strong>{page_id}</strong>{' '} */}
          <strong>{page?.name}</strong>{' '}
          <p>
            Sponsored <MdPublic className="anticon" />
          </p>
        </span>
        <EllipsisOutlined className="preview__header--options" />
      </header>
      <article>{message}</article>
      {checkRenderBody(currentPlacement)}
    </div>
  )
}
