import React from 'react'
import { Empty, message, Tabs } from 'antd'
import ProductItem from './ProductItem'
import { ProductProps } from 'ProductModule'
import { useStoreState } from 'easy-peasy'
import { sendProductToCustomer } from 'Pages/Harasiocial/CustomerPortrait/Services'

const RecommendedProductTabs = ({ topViewedProducts, recentViewedProducts, purchasedProducts }) => {
  const customerId = useStoreState((state) => state.harasocial.currentCustomerId)

  const handleOnSendProduct = (product) => {
    sendProductToCustomer(product.productId, customerId)
  }

  return (
    <div id="ProductTabs">
      <Tabs className="ant-tabs--mini" type="card">
        <Tabs.TabPane key="top_viewed" tab={'Xem nhiều nhất'}>
          <div className="p-2 pt-0">
            <div className="product-list">
              {topViewedProducts.length ? (
                topViewedProducts.map((product: ProductProps, index) => (
                  <ProductItem
                    key={index}
                    product={product}
                    layout="horizontal"
                    onSend={handleOnSendProduct}
                  />
                ))
              ) : (
                <Empty description={false}></Empty>
              )}
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane key="recent_viewed" tab={'Xem gần đây'}>
          <div className="p-2 pt-0">
            <div className="product-list">
              {recentViewedProducts.length ? (
                recentViewedProducts.map((product: ProductProps, index) => (
                  <ProductItem
                    key={index}
                    product={product}
                    layout="horizontal"
                    onSend={handleOnSendProduct}
                  />
                ))
              ) : (
                <Empty description={false}></Empty>
              )}
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane key="purchased" tab={'Mua gần đây'}>
          <div className="p-2 pt-0">
            <div className="product-list">
              {purchasedProducts.length ? (
                purchasedProducts.map((product: ProductProps, index) => (
                  <ProductItem
                    key={index}
                    product={product}
                    layout="horizontal"
                    onSend={handleOnSendProduct}
                  />
                ))
              ) : (
                <Empty description={false}></Empty>
              )}
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

RecommendedProductTabs.defaultProps = {
  topViewedProducts: [],
  recentViewedProducts: [],
  purchasedProducts: [],
}

export default RecommendedProductTabs
