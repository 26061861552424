const harasocial = [
  {
    name: 'content',
    type: 'text_area',
    label: 'Content',
  },
  {
    name: 'image',
    type: 'image',
    label: 'Image',
  },
  {
    name: 'products',
    type: 'product_selects',
    label: 'Products',
  },
  {
    name: 'utm_source',
    type: 'string',
    label: 'UTM Source',
  },
  {
    name: 'utm_medium',
    type: 'string',
    label: 'UTM Medium',
  },
  {
    name: 'utm_campaign',
    type: 'string',
    label: 'UTM Campaign',
  },
]

export default harasocial
