import React, { useEffect, useMemo } from 'react'
import { Select, Input, Checkbox } from 'antd'

import DependentSelects from './DependentSelects/index'
import ImageField from './Image'
import ProductField from './StoreFarmingForm/Product'
import DynamicContent from './DynamicContent'

const { TextArea } = Input

export type InputType =
  | 'image'
  | 'text_area'
  | 'string'
  | 'select'
  | 'dependent_selects'
  | 'mix_input'
  | 'mix_select'
  | string

export type FormItemType = {
  content?: any
  name?: string
  value: any
  defaultValue?: string
  onChange: (name: string, value?: any) => void
  options?: any[]
  placeholder?: string
  type: InputType
}

const FormItem: React.FC<FormItemType> = ({
  content,
  name,
  value,
  onChange,
  type,
  options,
  placeholder,
  defaultValue,
}) => {
  const handleChange = (val) => {
    if (name) onChange(name, val)
    else onChange(val)
  }
  const [inputValue, setInputValue] = React.useState()
  // console.log('value', value)
  // console.log('inputValue', inputValue)
  // console.log(name, value)

  return useMemo(() => {
    switch (type) {
      case 'string':
        return (
          <Input
            defaultValue={defaultValue}
            placeholder={placeholder}
            value={value}
            allowClear
            onChange={(e) => handleChange(e.target.value)}
          />
        )
      case 'text_area':
        return (
          <TextArea
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            onChange={(e) => handleChange(e.target.value)}
          />
        )
      case 'image':
        return <ImageField onChange={handleChange} value={value} />
      case 'select':
        return (
          <Select
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            options={options?.map((option) => ({ value: option.code, label: option.name }))}
          />
        )
      case 'mix_select':
        return (
          <div
            className="Mixtype-Select-Input flex flex-col"
            style={{
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              borderTop:
                !content?.button_nav_screen && !content?.button_deeplink
                  ? '1px solid red'
                  : ' none',
              borderRight:
                !content?.button_nav_screen && !content?.button_deeplink ? '1px solid red' : 'none',
              borderLeft:
                !content?.button_nav_screen && !content?.button_deeplink ? '1px solid red' : 'none',
            }}
          >
            <Select
              disabled={content?.button_deeplink ? true : false}
              allowClear
              value={content?.button_deeplink ? undefined : value}
              placeholder={placeholder}
              onChange={handleChange}
              onSelect={(val) => setInputValue(val)}
              options={options?.map((option) => ({ value: option.code, label: option.name }))}
            />
          </div>
        )
      case 'mix_input':
        return (
          <div
            className="Mixtype-Select-Input flex flex-col"
            style={{ transform: 'translateY(-24px)' }}
          >
            <Input
              style={{
                borderColor:
                  !content?.button_nav_screen && !content?.button_deeplink ? 'red' : '#d9d9d9',
              }}
              defaultValue={defaultValue}
              placeholder={'Paste URL link'}
              disabled={content?.button_nav_screen ? true : false}
              value={content?.button_nav_screen ? undefined : value}
              allowClear
              onChange={(e) => handleChange(e.target.value)}
            />
            {!content?.button_nav_screen && !content?.button_deeplink && (
              <div style={{ color: 'red' }}>URL is required</div>
            )}
          </div>
        )
      case 'checkbox':
        return (
          <Checkbox checked={!!value} defaultChecked={!!defaultValue} onChange={handleChange} />
        )
      case 'dependent_selects':
        const formattedOptions =
          options?.map((option) => ({
            value: option.type,
            label: option.label,
            tooltip: option.tooltip,
            sub_options: option.sub_options?.map((sub_option) => ({
              value: sub_option.code,
              label: sub_option.name,
            })),
          })) || []
        return (
          <DependentSelects
            title="Button"
            value={value || [{ option: formattedOptions[0].value, sub_option: '' }]}
            options={formattedOptions}
            onChange={(value) => handleChange(value)}
          />
        )

      case 'product_selects':
        return (
          <ProductField
            onAddProduct={(product) => {
              handleChange([...(value || []), product])
            }}
            onRemoveProduct={(product) => {
              handleChange(value.filter((item) => item.ProductId !== product.ProductId))
            }}
            value={value || []}
          />
        )
      case 'dynamic_content':
        return (
          <DynamicContent
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
          />
        )
      default:
        return <Input type="hidden" />
    }
  }, [name, options, type, value, onChange, placeholder])
}

export default FormItem
