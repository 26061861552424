import React, { useState } from 'react'
import classNames from 'classnames'
import { Skeleton } from 'antd'

import { UpOutlined } from '@ant-design/icons'

type Data = {
  title: string
  counter: string
  children?: Data[]
}

interface CustomerPerStoreItemProps {
  data: Data[]
  total: number
  isLoading?: boolean
  isChildren?: boolean
}

function RenderCustomerPerStoreItem({ data, total, isChildren = false }) {
  const [isShowChildren, setIsShowChildren] = useState<boolean>(false)

  return (
    <div className={classNames('cursor-pointer', { 'pl-4': isChildren })}>
      <div
        className="flex justify-between mb-3 hover:underline"
        onClick={() => setIsShowChildren(!isShowChildren)}
      >
        <span
          className={classNames('flex-1', 'text-black', 'text-base', {
            'font-semibold': !isChildren,
          })}
        >
          {data.title}
        </span>
        <div className="flex items-center">
          <span className="ml-2">
            {parseFloat(data.counter).toLocaleString()} /{' '}
            {((parseFloat(data.counter) / total) * 100).toFixed(2) == 'NaN'
              ? '0'
              : ((parseFloat(data.counter) / total) * 100).toFixed(2)}
            %
          </span>
          <div className="ml-2">
            {data?.children && (
              <UpOutlined
                className={classNames('transition-all', 'transform', ' origin-center', {
                  'rotate-180': isShowChildren,
                })}
              />
            )}
          </div>
        </div>
      </div>

      <div
        className="overflow-hidden transition-all"
        style={{ maxHeight: isShowChildren ? 2000 : 0 }}
      >
        {data?.children && <CustomerPerStoreItem isChildren data={data.children} total={total} />}
      </div>
    </div>
  )
}

function CustomerPerStoreItem({
  data,
  total,
  isLoading = false,
  isChildren = false,
}: CustomerPerStoreItemProps): JSX.Element {
  return (
    <Skeleton paragraph={{ rows: 6 }} active loading={isLoading}>
      {data.map((store, i) => (
        <RenderCustomerPerStoreItem isChildren={isChildren} key={i} data={store} total={total} />
      ))}
    </Skeleton>
  )
}

export default CustomerPerStoreItem
