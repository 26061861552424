import React, { useEffect } from 'react'

import Form from './Form'
import Preview from 'Components/PreviewChannels/Preview'
import { useStoreState, useStoreActions } from 'easy-peasy'
import EmailTabs from 'Components/CJChannels/Email'

import './index.scss'

export type EmailType = 'kariba' | 'sendgrid'

const Content = () => {
  const { selected_placement, content } = useStoreState((state) => state.campaignModule)
  const { setContent } = useStoreActions((action: any) => action.campaignModule)
  const { clearContent } = useStoreActions((action: any) => action.campaignModule)
  useEffect(() => {
    selected_placement.code === 'web_push' &&
      setContent({
        key: 'link',
        value: 'https://' + process.env.REACT_APP_COMPANY_WEBSITE,
      })
  }, [selected_placement])
  if (selected_placement.code === 'email')
    return (
      <EmailTabs
        values={content}
        isCampaign={true}
        withModal={false}
        btnSubmit={false}
        breadcrumbVisble={false}
        clearValue={clearContent}
        setValue={(name, value) => {
          setContent({ key: name, value })
        }}
      ></EmailTabs>
    )
  return (
    <div className="w-full flex gap-4" id="CampaignContent">
      <div className="w-1/2 bg-gray_1 p-4 rouded">
        <Form />
      </div>
      <div className="w-1/2 bg-gray_1 p-4 rouded">
        <Preview />
      </div>
    </div>
  )
}

export default Content
