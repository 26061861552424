import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Skeleton } from 'antd'
import axios from 'axios'

import useFilters from './FiltersHeader/useFilters'
import MetricCard from 'Components/MetricCard'
import { FiltersType } from 'AnalyticsTypes'

const KeyMetrics = ({
  handleDataFunction,
}: {
  handleDataFunction: (filters: FiltersType) => Promise<any>
}) => {
  const history = useHistory()
  const { id } = useParams()
  const { filters: defaultFilters } = useFilters()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')

  const [metrics, setMetrics] = useState<
    {
      value: number
      unit: string
      title: string
      display_name?: string
      subvalue?: string | null
    }[]
  >([])

  const queryObject = queryString.parse(history.location.search)

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()

    const handleKeyMetrics = async () => {
      setIsLoading(true)
      try {
        setError('')
        const resp = await handleDataFunction({
          store_id: id,
          from_date: queryObject?.from_date?.toString() || defaultFilters.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters.to_date,
          dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
          cancelTokenSource,
        })

        setMetrics(resp.data.data)
      } catch (err: any) {
        if (err.message === 'cancelled') {
          return
        }

        setError(err?.response?.data?.detail || 'Some thing went wrong')
      }
      setIsLoading(false)
    }

    handleKeyMetrics()
    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [id, queryObject.from_date, queryObject.to_date, handleDataFunction, queryObject.dimensions])

  if (error) return <Alert message={error} type="error" />

  return (
    <Skeleton loading={isLoading} active>
      <div className="metric-cards-wrapper">
        {metrics.map((m, index) => (
          <MetricCard
            key={index}
            className={`box-${index + 1}`}
            title={m.display_name ? m.display_name : m.title}
            unit={m.unit}
            value={m.value || '0'}
          ></MetricCard>
        ))}
      </div>
    </Skeleton>
  )
}

export default KeyMetrics
