import React, { useMemo } from 'react'
import { ProductProps } from 'ProductModule'
import { useStoreState } from 'easy-peasy'
import classNames from 'classnames'
import Product from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/StoreFarmingForm/Product'
import kFormatter from 'Utils/kFormatter'
import { numberWithCommas } from 'Utils'

type props = {
  product: ProductProps
  layout?: 'vertical' | 'horizontal' | 'image_only'
  onSend?: (any) => void
  opts?: any
  className?: string
  subtitleMode?: 'currency' | 'color_size'
  horizontalClassName?: string
}

export const ProductItem: React.FC<props> = ({
  product,
  subtitleMode = 'currency',
  opts,
  onSend,
  className,
  horizontalClassName,
}) => {
  const harasocialActions = useStoreState((state) => state.harasocial.harasocialActions)
  const handleSendClick = () => {
    if (onSend && typeof onSend === 'function') return onSend(product)
    else if (typeof harasocialActions?.sendProduct !== 'function') return
    /* eslint-disable @typescript-eslint/no-unused-expressions */
    harasocialActions?.sendProduct({
      title: product.title,
      subtitle: product.price,
      imageUrl: product.image,
      productUrl: product.slug,
    })
    /* eslint-enable */
  }

  const renderSubtitle = () => {
    if (subtitleMode === 'currency')
      return (
        <>
          <span className="price text-sm text-disabled_text mr-2">
            {numberWithCommas(product?.price)}đ
          </span>
          {product?.originalPrice && (
            <span className="price-original text-sm text-disabled_text line-through">
              {numberWithCommas(product?.originalPrice)}đ
            </span>
          )}
        </>
      )
    else if (subtitleMode === 'color_size')
      return (
        <span className="price text-sm text-disabled_text mr-2">
          {product?.color} - {product?.size}
        </span>
      )
  }

  return (
    <div
      className={classNames(
        `HRSProductItemComponent shadow-bot_1 hover:cursor-pointer ${className}`,
        'HRSProductItemComponent--state-product-horizontal'
      )}
    >
      <div className={`flex overflow-hidden items-center p-2 ${horizontalClassName}`}>
        <img className="mr-2" alt="product item" src={product.image} />
        <div className="flex flex-col justify-between w-230">
          <span className="font-medium truncate pr-2">{product.title}</span>
          <div className="subtitle">
            {renderSubtitle()}
            {product?.quantity && (
              <span className="price-original text-sm text-disabled_text ml-3">
                Tồn kho: {product.quantity}
              </span>
            )}
          </div>
        </div>
        {opts.sendBtn && (
          <p className="text-blue-600	mb-0 font-normal cursor-pointer" onClick={handleSendClick}>
            Gửi
          </p>
        )}
      </div>
    </div>
  )
}

ProductItem.defaultProps = {
  opts: {
    sendBtn: true,
  },
  onSend: () => {},
  className: '',
  horizontalClassName: '',
}

export default ProductItem
