import React, { useState, useMemo } from 'react'
import axios from 'Utils/axios'
import { message } from 'antd'
import { formatErrors } from 'Utils/'

import { useDebounce } from './index'
const useFetchTables = ({ url, recordKey, key }) => {
  const [state, setState] = useState({
    data: [],
    loading: false,
    errors: null,
    total: '',
  })
  const [params, setParams] = useState({
    page: 1,
    per_page: 20,
  })

  const [searchTerm, setSearchTerm] = useState('')

  const debouncedSearchTerm = useDebounce(searchTerm, 700)
  // let cancelToken = axios.CancelToken.source()

  useMemo(async () => {
    setState((prev) => ({ ...prev, loading: true, errors: null }))
    try {
      const result = await axios.get(url, {
        params: {
          ...params,
          order_by: params.order_by,
          query: debouncedSearchTerm,
        },
        // cancelToken: cancelToken.token,
      })
      let records = key ? result.data[key] : result.data

      setState((prev) => ({
        ...prev,
        data: records,
        loading: false,
        total: result.data.count,
      }))
    } catch (error) {
      if (error?.message?.type === 'client_cancel') return
      const err = error.response ? formatErrors(error.response.data) : 'Request Timeout'
      setState((prev) => ({
        ...prev,
        loading: false,
        errors: err,
      }))
      // if (!error.response) return message.error('Request Timeout')
      message.error(err)
    }
  }, [params, debouncedSearchTerm])
  return {
    ...state,
    params,
    setParams,
    searchTerm,
    setSearchTerm,
    setData: (data) => setState({ ...state, data }),
  }
}

export default useFetchTables
