import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import AudienceSelect from 'Components/AudienceSelect'
import Header from '../../CJHeader'

import '../index.scss'
import { CurrentType } from 'CustomerJourneyModule'

interface Props {
  onUpdateNode: (arg1: { audience_segment_id: number | null }) => void
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  isPopoverVisible: boolean
  value: { audience_segment_id: number }
  current: CurrentType
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
}

const SelectAudience: React.FC<Props> = (props) => {
  const { onUpdateNode, setIsPopoverVisible, isPopoverVisible, value } = props
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedAudienceId, setSelectedAudienceId] = useState<number>(NaN)

  // const isFirstRun = useRef(true)

  const onCancel = () => {
    setIsModalVisible(false)
    setIsPopoverVisible(false)
    setSelectedAudienceId(NaN)
  }

  const onOk = () => {
    onUpdateNode({ audience_segment_id: selectedAudienceId })
    setIsModalVisible(false)
    setIsPopoverVisible(false)
  }

  useEffect(() => {
    if (isPopoverVisible) setIsModalVisible(true)
  }, [isPopoverVisible])

  return (
    <Modal
      destroyOnClose
      onOk={onOk}
      onCancel={onCancel}
      visible={isModalVisible}
      maskClosable
      title={<Header {...props} />}
      width={1200}
      closable={false}
      className="ChannelsModal"
    >
      <div className="SelectAudienceComponent">
        <AudienceSelect
          selectedAudienceId={selectedAudienceId || value.audience_segment_id}
          onSelectAudienceId={(id) => setSelectedAudienceId(id)}
        />
      </div>
    </Modal>
  )
}

export default SelectAudience
