import React from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import HaravanLogo from 'Assets/images/haravan.logo.png'
import logo from 'Assets/images/logo.svg'
import { getHaravanLoginUrl } from 'Utils'
const { Title } = Typography
const { Password } = Input

export default React.memo(function LoginView(props) {
  const {
    // loginHarawork,
    handleChange,
    values,
    isSubmitting,
    isValidating,
    handleSubmit,
    serverError,
  } = props

  const CustomError = (props) => {
    return <Alert message={props.children} type="error" className="field-error" showIcon />
  }
  const loadingCondition = isSubmitting || isValidating

  const handleHaraAccount = () => {
    const haravanLoginURL = getHaravanLoginUrl('kariba')
    window.location.href = haravanLoginURL
  }

  return (
    <Form id="login_form" className="login-form-wrapper" onSubmit={handleSubmit}>
      <div className="form-logo">
        <img src={logo} alt="form logo" width={150} />
      </div>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="form-item">
        <Title className="field-title">Email</Title>
        <Field
          as={Input}
          name="email"
          placeholder="Eg. Username"
          className="email-field"
          size="large"
          prefix={<UserOutlined style={{ marginRight: 5 }} />}
          disabled={loadingCondition}
          value={values.email}
          onChange={handleChange}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="email" />
      </div>

      <div className="form-item">
        <Title className="field-title">Password</Title>
        <Field
          as={Password}
          name="password"
          placeholder="Your password"
          className="password-field"
          size="large"
          prefix={<LockOutlined style={{ marginRight: 5 }} />}
          value={values.password}
          onChange={handleChange}
          disabled={loadingCondition}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="password" />
      </div>
      <a href="/forgot-password">Forgot password?</a>
      <Button type="primary" htmlType="submit" className="submit-button" loading={loadingCondition}>
        Login
      </Button>
      {/* <Button
        type="secondary"
        className="submit-button submit-button--haravan-btn"
        onClick={handleHaraAccount}
        icon={<img className="inline-block ml-1" width="90" fill="white" src={HaravanLogo}></img>}
      >
        Login with
      </Button> */}
      {/* <Button
      type="primary"
      onClick={() => loginHarawork(props.values)}
      className="submit-button"
      loading={loadingCondition}
    >
      Harawork
    </Button> */}
      {/* <div style={{ display: 'flex', marginTop: 15 }}>
        <span>Don't have a account?&nbsp;</span>
        <a href="/register">Register now</a>
      </div> */}
    </Form>
  )
})
