import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

const LoginFail = () => {
  const location = useLocation()
  const params = queryString.parse(location.search)
  const { error_message } = params
  useEffect(() => {
    localStorage.setItem('Authorization', '')
  }, [error_message])

  return <div>{error_message}</div>
}

export default LoginFail
