export const SAMPLE_DATA = {
  values: [
    {
      value: 'test_segment_1',
      label: 'Test Segment 1',
    },
    {
      value: 'test_segment_2',
      label: 'Test Segment 2',
    },
    {
      value: 'test_segment_3',
      label: 'Test Segment 3',
    },
    {
      value: 'test_segment_4',
      label: 'Test Segment 4',
    },
    {
      value: 'test_segment_5',
      label: 'Test Segment 5',
    },
  ],
}

export const EXPRESSIONS = {
  operator: [
    {
      value: 'IN',
      label: 'IN',
    },
    {
      value: 'NOT IN',
      label: 'NOT_IN',
    },
    {
      value: 'UNION',
      label: 'UNION',
    },
    {
      value: 'INTERSECT',
      label: 'INTERSECT',
    },
  ],
}

export const CRITERIA_GROUP = [
  {
    condition: '',
    segments: [
      {
        type: '',
        condition: '',
      },
    ],
  },
]
