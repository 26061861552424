import React from 'react'
import HarworkLogo from 'Assets/images/harawork.logo.png'
import './index.scss'
import { Button } from 'antd'
import { getHaravanLoginUrl } from 'Utils'
const SyncHarawork = () => {
  const handleSyncHRWButton = () => {
    const haravanLoginURL = getHaravanLoginUrl('harasocial')
    window.open(haravanLoginURL, '_self')
  }
  return (
    <div className="SyncHaraworkComponent">
      <img src={HarworkLogo} className="mb-4"></img>
      <Button className="sync-hrw" type="primary" onClick={handleSyncHRWButton}>
        Sync with Harawork
      </Button>
    </div>
  )
}

export default SyncHarawork
