import React, { useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { resetPassword } from 'Validation'
import { message } from 'antd'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import ResetPasswordView from './ResetPassword.view'
import { formatErrors } from '../../Utils'
import './index.scss'

const { success } = message
const initialValues = {}

export default function ResetPassword(props) {
  const history = useHistory()
  const token = queryString.parse(history.location.search).token

  const [serverError, setServerError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const resetPasswordAction = useStoreActions((actions) => actions.auth.resetPassword)

  const handleResetPassword = async (values) => {
    setIsSubmitting(true)
    const captcha_token = await executeRecaptcha('login_page')

    if (!captcha_token) return setServerError('reCaptcha went wrong')
    const resp = await resetPasswordAction({ new_password: values.password, token, captcha_token })

    if (resp.errors) {
      setIsSubmitting(false)
      setServerError(formatErrors(resp.errors.data))
      return
    }

    await success('Password changed successfully', 5)
    history.push('./login')
  }

  return (
    <div className="reset-page">
      <Formik
        validationSchema={resetPassword}
        initialValues={initialValues}
        onSubmit={handleResetPassword}
        children={(props) => {
          return (
            <ResetPasswordView
              serverError={serverError}
              isValidating={false}
              isSubmitting={isSubmitting}
              {...props}
            />
          )
        }}
      />
    </div>
  )
}
