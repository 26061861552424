import React from 'react'
import { BarChartOutlined } from '@ant-design/icons'

import { COLUMNS, SOURCE, USED_IN } from './constants'
import Table from 'Components/Table2.0'
import { RecordType } from 'Components/Table2.0/index.d'
import { Filters } from './index'
import { RiGroupLine } from 'react-icons/ri'

export type props = {
  createdByList: { label: string; value: string }[]
  setFilters: (filters: React.SetStateAction<Filters>) => void
  filters: Filters
  handleTableChange: (tablePagination: any, _: any, sorter: any) => void
  handleStatistics: (record: RecordType) => void
  handleCustomerListing: (record: RecordType) => void
}

const SegmentListView: React.FC<props> = ({
  createdByList,
  setFilters,
  filters,
  handleTableChange,
  handleStatistics,
  handleCustomerListing,
}) => {
  return (
    <Table
      dropdown={{
        fields: [
          {
            label: 'User source',
            value: 'type',
            options: SOURCE,
            type: 'DROPDOWN',
            onClick: ({ key, value }) =>
              setFilters({ ...filters, page: 1, [key]: JSON.stringify(value) }),
          },
          {
            label: 'Used in',
            value: 'used_in',
            options: USED_IN,
            type: 'DROPDOWN',
            onClick: ({ key, value }) =>
              setFilters({ ...filters, page: 1, [key]: JSON.stringify(value) }),
          },
          {
            label: 'Created by',
            value: 'created_by',
            options: createdByList,
            type: 'DROPDOWN',
            onClick: ({ key, value }) =>
              setFilters({ ...filters, page: 1, [key]: JSON.stringify(value) }),
          },
        ],
        value: {
          ...filters,
          type: JSON.parse(filters.type),
          used_in: JSON.parse(filters.used_in),
          created_by: JSON.parse(filters.created_by),
        },
      }}
      fetchFrom={{ url: '/audience-segments/', key: 'results' }}
      search={{
        keyword: filters.query,
        onSearch: (query) => {
          setFilters((prev) => ({ ...prev, query, page: 1 }))
        },
      }}
      columns={COLUMNS}
      handleTableChange={handleTableChange}
      rowActions={{
        fixed: 'right',
        actions: [
          {
            icon: <BarChartOutlined />,
            attribute: { type: 'highlight', label: 'Analytics' },
            onClick: handleStatistics,
          },
          {
            icon: <RiGroupLine />,
            attribute: { label: 'User' },
            onClick: handleCustomerListing,
          },
        ],
      }}
    />
  )
}

export default SegmentListView
