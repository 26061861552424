import React from 'react'
import ChartLine from '../../Components/ChartLine'
import { getSaleChannelOverviewCharts } from 'Services/Analytics/saleChannel-analytics'

const ChartRow6 = () => {
  return (
    <>
      <div className="bg-white p-4 col-span-1">
        <div className="mb-4 font-semibold"> Gross Profit</div>
        <div className="w-full overflow-auto">
          <ChartLine chartCode="gross_profit" handleDataFunction={getSaleChannelOverviewCharts} />
        </div>
      </div>
      <div className="bg-white p-4 col-span-1">
        <div className="mb-4 font-semibold"> % Gross Profit</div>
        <div className="w-full overflow-auto">
          <ChartLine
            chartCode="gross_profit_ratio"
            handleDataFunction={getSaleChannelOverviewCharts}
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow6
