import React from 'react'
import { Card } from 'antd'
import icon_fb from 'Assets/images/icon-fb.png'
import './index.scss'
import { brandVars } from 'Utils/brandVars'

const { Meta } = Card

const PostEngagement = ({ data }) => {
  const { image_url, primary_text, link_to_page, call_to_action } = data
  const vars = brandVars()
  return (
    <Card
      className="PostEngagement"
      cover={<img alt="example" src={image_url || vars.logo} />}
      actions={[
        // <span><LikeOutlined key="like"/> Like</span>,
        <span className="action-items">
          <span
            className="item like-action"
            style={{
              background: `url(${icon_fb}) no-repeat`,
            }}
          ></span>
          &nbsp;
          <strong className="item-text">Like</strong>
        </span>,
        <span className="action-items">
          <span
            className="item comment-action"
            style={{
              background: `url(${icon_fb}) no-repeat`,
            }}
          ></span>
          &nbsp;
          <strong className="item-text">Comment</strong>
        </span>,
        <span className="action-items">
          <span
            className="item share-action"
            style={{
              background: `url(${icon_fb}) no-repeat`,
            }}
          ></span>
          &nbsp;
          <strong className="item-text">Share</strong>
        </span>,
      ]}
    >
      <Meta
        description={
          <>
            <div className="preview-description__postEngagement">
              <div>
                {!!link_to_page && link_to_page.toUpperCase()}

                <h2>{primary_text}</h2>
              </div>

              {!!call_to_action && (
                <button>{call_to_action.toUpperCase().replace('_', ' ')}</button>
              )}
            </div>
            <div className="horizon-line_bg">
              <br></br>
              <div className="horizon-line_content"></div>
            </div>
          </>
        }
      />
    </Card>
  )
}

export default PostEngagement
