import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Form, message, Skeleton } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import JunoTemplate from '../JunoTemplate'

import CreativeContent from 'Containers/CreativeContent'
import { karibaEmailValidation, sendgridEmailValidation } from './index.validation'
import {
  karibaEmailSchemas,
  karibaInitialValues,
  sendGridInitialValues,
  sendgridEmailSchemas,
} from './index.constants'
import { getEmailTemplate as getEmailTemplateApi } from '../index.services'

import './index.scss'
import FormItem from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/FormItem'
import cloneDeep from 'lodash/cloneDeep'

const EmailEditContentForm = ({
  onBack,
  onSubmit, // for CJ
  values,
  seletedEmail,
  isCampaign = false,
  emailType,
  setValue, // for campaign // Todo: fix later
  setCreativeContentsState,
  breadcrumbVisble,
}: any) => {
  const validation = emailType === 'kariba' ? karibaEmailValidation : sendgridEmailValidation
  const [loading, setLoading] = useState(false)
  const [emailContent, setEmailContent] = useState(() => {
    return { ...values, template_id: seletedEmail?.id || values.template_id }
  })

  const [schema, setSchema] = useState(
    emailType === 'kariba' ? karibaEmailSchemas : sendgridEmailSchemas
  )

  useEffect(() => {
    if (!isEmpty(values)) {
      setEmailContent({ ...values, template_id: seletedEmail?.id || values.template_id })
      setCreativeContentsState({ key: 'previewData', value: values })
    }
  }, [setCreativeContentsState, values, seletedEmail])

  useEffect(() => {
    if (emailType === 'kariba') return
    const getEmailTemplate = async () => {
      // Load sendgrid email template
      setLoading(true)
      try {
        const res = await getEmailTemplateApi(emailContent.template_id)
        let count = 1
        const newSchema: any = cloneDeep(sendgridEmailSchemas)
        while (true) {
          if (String(res).includes(`image_item_${count}`)) {
            newSchema.splice(6 + count * 2, 0, {
              name: `image_item_${count}`,
              type: 'image',
              label: `Image (image_item_${count})`,
            })
            newSchema.splice(7 + count * 2, 0, {
              name: `image_item_${count}_url`,
              type: 'string',
              label: `Image (image_item_${count}_url)`,
            })
            count++
            continue
          }
          break
        }
        setSchema(newSchema)
      } catch (err: any) {
        message.error('Failed to load preview')
      }
      setLoading(false)
    }
    getEmailTemplate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.template_id, seletedEmail])

  const handleCJSubmit = (values: any) => {
    onSubmit({ ...values, template_id: seletedEmail?.id || values.template_id })
  }

  const handleEmailCampaingChange = (name, value) => {
    //Set values for content - campaign models
    if (setValue && typeof setValue === 'function') {
      setValue(name, value)
    }
  }

  const handleFieldChange = (name, value) => {
    if (isCampaign) {
      handleEmailCampaingChange(name, value)
      setEmailContent({ ...emailContent, [name]: value })
    } else {
    }
  }

  const renderCampaignForm = React.useCallback(() => {
    return (
      <Form
        initialValues={emailContent}
        onFinish={handleCJSubmit}
        className="bg-white p-4 w-1/2"
        layout="vertical"
      >
        {schema.map((schema, index) => (
          <Form.Item
            className={schema?.type === 'hidden' ? 'hidden' : ''}
            name={schema?.name}
            key={index}
            label={schema.label}
            hasFeedback
            rules={[{ required: schema.isRequired, message: `${schema.label} is required` }]}
          >
            <FormItem
              defaultValue={emailContent && emailContent[schema?.name]}
              type={schema?.type}
              value={emailContent && emailContent[schema?.name]}
              options={schema.options}
              onChange={(value) => {
                handleFieldChange(schema?.name, value)
              }}
            />
          </Form.Item>
        ))}
        {!isCampaign && (
          <div className="flex justify-end">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        )}
      </Form>
    )
  }, [schema])

  const renderForm = () => {
    if (loading) return <Skeleton></Skeleton>
    return renderCampaignForm()
  }
  return (
    <div className="EmailEditContentFormComponent">
      {breadcrumbVisble && (
        <Breadcrumb>
          <Breadcrumb.Item className="template-breadcrumb" onClick={onBack}>
            Templates
          </Breadcrumb.Item>
          <Breadcrumb.Item>Edit {emailType} email content</Breadcrumb.Item>
        </Breadcrumb>
      )}

      <div className="input-fields">
        {/* Form */}
        {renderForm()}
        {/* Preview  */}
        {emailType === 'kariba' && <JunoTemplate />}
      </div>
    </div>
  )
}
export default connect(
  (state: any) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
    clearPreviewData: dispatch.campaign.creativeContents.clearPreviewData,
  })
)(EmailEditContentForm)
