import React, { useEffect, useState, useMemo } from 'react'
import { Table, Empty, Alert } from 'antd'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { FullScreenMaximize } from '@styled-icons/fluentui-system-filled/FullScreenMaximize'
import { FullScreenMinimize } from '@styled-icons/fluentui-system-filled/FullScreenMinimize'

import LoadingIcon from 'Components/LoadingIcon'
import useTable from 'Components/Table2.0/Components/useTable'

const Result = ({ isRunQuery, setIsRunQuery }) => {
  const { custom_sql } = useStoreState((state: any) => state.customerSegments)

  const [columns, setColumns] = useState<any[]>([])
  const [isShowFullScreenButton, setIsShowFullScreenButton] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const { data, loading, total, setParams, params, setData, errors }: any = useTable({
    url: '/audience-dimensions/custom-sql',
    key: 'results',
  })

  useEffect(() => {
    if (isRunQuery && custom_sql) {
      setParams((prevState) => ({
        ...prevState,
        custom_sql: custom_sql.replace(/\n/g, ' '),
        page: 1,
        per_page: 20,
      }))
    }
  }, [isRunQuery])

  useEffect(() => {
    if (data?.length) {
      const cols: any[] = Object.keys(data[0]).map((key) => ({
        title: key,
        dataIndex: key,
        key: key,
      }))
      setColumns(cols)
    }
  }, [data])

  useEffect(() => {
    if (!loading) setIsRunQuery(false)
  }, [loading])

  const handleTableChange = (tablePagination, _, sorter) => {
    setParams({
      ...params,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      // order_by: sorter.order
      //   ? sorter.order === 'ascend'
      //     ? sorter.field
      //     : `-${sorter.field}`
      //   : null,
    })
  }

  const FullScreenBtn = ({ className, style }: { className?: string; style?: any }) => {
    return (
      <>
        {isFullScreen ? (
          <div
            onClick={() => {
              setIsFullScreen(!isFullScreen)
            }}
            className={`bg-white rounded flex items-center justify-center border cursor-pointer opacity-70 hover:opacity-100 transition ${className}`}
            style={{ minWidth: 40, height: 40, ...style, top: -50 }}
          >
            <span className="px-2">
              <FullScreenMinimize style={{ width: 20, height: 20, marginRight: 10 }} />
              Exit Fullscreen
            </span>
          </div>
        ) : (
          <div
            onClick={() => {
              setIsFullScreen(!isFullScreen)
            }}
            className={`bg-white rounded flex items-center justify-center border cursor-pointer opacity-70 hover:opacity-100 transition ${className}`}
            style={{ minWidth: 40, height: 40, ...style }}
          >
            <FullScreenMaximize style={{ width: 20, height: 20 }} />
          </div>
        )}
      </>
    )
  }

  const renderTable = useMemo(() => {
    return (
      <Table
        className={`w-full ${isFullScreen && 'activeFullScreen'}`}
        dataSource={data}
        locale={{ emptyText: loading ? ' ' : <Empty></Empty> }}
        columns={columns}
        pagination={{
          pageSizeOptions: ['5', '10', '20', '30', '50'],
          showSizeChanger: true,
          pageSize: params?.per_page || 10,
          total: total || undefined,
          current: params?.page || 1,
        }}
        loading={{
          spinning: loading,
          indicator: <LoadingIcon />,
        }}
        rowKey={'id'}
        onChange={handleTableChange}
      />
    )
  }, [params, loading, data, total, columns, isFullScreen])

  if (!params)
    return <div className="text-disabled_text mt-2">Select a table or Run a query to see</div>

  return (
    <div className="SQLResult">
      {errors ? (
        <Alert message={errors} type="error" />
      ) : (
        <div className={`result-content  ${isFullScreen ? 'full-screen' : ''}`}>
          {data.length ? (
            <div className="my-3">Query Completed. {total.toLocaleString()} results</div>
          ) : null}

          <div
            className="relative"
            onMouseEnter={() => {
              setIsShowFullScreenButton(true)
            }}
            onMouseLeave={() => {
              !isFullScreen && setIsShowFullScreenButton(false)
            }}
          >
            {isShowFullScreenButton && (
              <FullScreenBtn
                style={{ top: 10, right: 10, zIndex: 10000 }}
                className="drop-shadow absolute right-0"
              />
            )}
            {renderTable}
          </div>
        </div>
      )}
    </div>
  )
}

export default Result
