import React from 'react'
import { Select } from 'antd'
import { AggregateSelectProps, SQL_AGGREGATE } from '../index.constants'
const AggregateSelect = ({
  className,
  placeholder,
  defaultValue,
  onChange,
}: AggregateSelectProps) => {
  return (
    <Select
      className={`AggregateSelectComponent ${className}`}
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
    >
      {SQL_AGGREGATE.map((agg) => (
        <Select.Option key={agg} value={agg}>
          {agg}
        </Select.Option>
      ))}
    </Select>
  )
}

export default AggregateSelect
