import React, { useState } from 'react'
import KaipassPlaceholder from 'Assets/images/kaipass_placeholder.png'

import './index.scss'
import { brandVars } from 'Utils/brandVars'

const AppPushPreview = ({ data }) => {
  const { title, image, content, button_name } = data
  const vars = brandVars()
  return (
    <div className="AppPushPreviewComponent">
      <div className={`AppPushPreviewScreen`}>
        <div className="window-preview">
          <div className="image-wrapper" style={{ backgroundColor: vars.color }}>
            <img alt="brand-logo" className="image" src={image || vars.logo}></img>
          </div>
          <div className="content-wrapper">
            <div className="content">
              <p className="title">{title || 'Title'}</p>
              <p className="description">{content || 'Description'}</p>
            </div>
          </div>
          <div className="action-buttons-wrapper">
            <div className="action-button" style={{ backgroundColor: vars.color }}>
              {button_name || 'Button'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppPushPreview
