import React from 'react'
import { Tooltip, Typography } from 'antd'
import { capitalizeFirstLetter } from 'Utils'
import { CUSTOMER_360_COLUMNS } from 'Utils/constants'
import StatusTag from 'Components/StatusTag'
import MemberShipBadge from 'Components/MemberShipBadge'
const { Text } = Typography

const computeTagColor = (tag) => {
  switch (tag) {
    case 'Khách hàng vàng':
      return 'yellow'
    case 'Khách hàng bạc':
      return 'light-gray'
    case 'Khách hàng thành viên':
      return 'blue'
    case 'Khách hàng bạch kim':
      return 'green'
    case 'Khách hàng kim cương':
      return 'red'
    default:
      return 'gray'
  }
}

const computeMemberShipName = (membership) => {
  if (!membership) return null
  return membership.replace('Khách hàng', '')
}

export const COLUMNS = [
  ...CUSTOMER_360_COLUMNS.map((column) => {
    if (column === 'name') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        sorter: true,
        align: 'left',
        render: (customer) => (
          <Tooltip title={customer}>
            <Text className="customer-title" strong>
              {customer}
            </Text>
          </Tooltip>
        ),
      }
    }
    if (column === 'membership') {
      return {
        title: capitalizeFirstLetter(column),
        dataIndex: column,
        width: 100,
        sorter: true,
        render: (tag) => (
          <MemberShipBadge membership={tag} className="justify-center"></MemberShipBadge>
        ),
      }
    }

    return {
      title: capitalizeFirstLetter(column),
      dataIndex: column,
      width: 10,
      sorter: true,
    }
  }),
]
