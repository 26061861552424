import React, { useMemo } from 'react'
import { Input } from 'antd'
import FieldWrapper from '../FieldWrapper'
import { capitalizeFirstLetter } from 'Utils'
import { connect } from 'react-redux'
import './index.scss'

const MixInputField = (props) => {
  const { name, onChange, values, placeholder, disabled, initialValues, inputType } = props
  const input = () => {
    return (
      <div
        className="Mixtype-Select-Input flex flex-col"
        style={{ transform: 'translateY(-24px)' }}
      >
        <Input
          style={{
            borderColor: !values?.button_nav_screen && !values?.button_deeplink ? 'red' : '#d9d9d9',
          }}
          allowClear
          //   style={{ display: inputType }}
          name={'button_deeplink'}
          type={inputType || 'text'}
          disabled={values?.button_nav_screen ? true : false}
          placeholder={'Paste URL link'}
          value={values[name]}
          onChange={(e) => {
            // todo: for preview data, remove later
            onChange(name, e.target.value)
            props.setFieldValue(name, e.target.value)
          }}
        />
        {!values?.button_nav_screen && !values?.button_deeplink && (
          <div style={{ color: 'red' }}>URL is required</div>
        )}
      </div>
    )
  }

  return <FieldWrapper {...props}>{(fieldProps) => input()}</FieldWrapper>
}

export default connect(null, (dispatch) => ({
  setPreviewData: dispatch.campaign.creativeContents.setPreviewData,
}))(MixInputField)
