import React, { useState, useEffect } from 'react'
import { Tabs, DatePicker } from 'antd'
import { useHistory, Link, useParams } from 'react-router-dom'

import General from '../Components/Overview'
import LeaderBoard from '../Components/LeaderBoards'
import { TABS } from './constants'
import moment from 'moment'
import './index.scss'

const { TabPane } = Tabs
const { RangePicker } = DatePicker
const queryString = require('query-string')

const PersonalPerformance = () => {
  const history = useHistory()
  const [dateFilter, setDateFilter] = useState([moment().subtract(1, 'months'), moment()])
  const { type } = queryString.parse(history.location.search)

  const queryObject = queryString.parse(history.location.search)

  const onChangeTab = (tab) => {
    const params = queryString.stringify({ ...queryObject, type: tab })
    history.push({ search: params })
  }

  useEffect(() => {
    if (!type) {
      const params = queryString.stringify({ ...queryObject, type: 'general' })
      history.push({ search: params })
    }
  }, [history, type, queryObject])

  return (
    <div className="PersonalPerformance">
      <div className="bg-gray_1 relative flex items-center w-full header">
        <Tabs className="ant-tabs--white-mode" activeKey={type} onChange={onChangeTab}>
          {TABS?.map((option) => (
            <TabPane
              key={option.value}
              tab={<span className="font-semibold">{option.label}</span>}
            ></TabPane>
          ))}
        </Tabs>
        <div className="absolute right-3">
          <RangePicker
            defaultValue={[moment(dateFilter[0]), moment(dateFilter[1])]}
            onChange={(date) => {
              // @ts-ignore: Object is possibly 'null'.
              setDateFilter(date)
            }}
          />
        </div>
      </div>
      {type === 'general' && (
        <General fromDate={dateFilter[0]} toDate={dateFilter[1]} type="personal" />
      )}
      {type === 'leader-board' && <LeaderBoard dateFilter={dateFilter} />}
    </div>
  )
}

export default PersonalPerformance
