import React, { useCallback, useState, useContext, useEffect } from 'react'
import { useStoreState } from 'easy-peasy'
import { Button, Popover, Alert, Tabs, Space, Modal } from 'antd'
import { StoreProps } from 'Models/dimensions'
import { CustomerItem } from 'DimensionsFiltersTypes'

import { computeCustomerNestedGroup } from '../../helpers'
import SegmentItem from './Customer.SegmentItem'
import SegmentScreen from './Customer.SegmentScreen'
import { dimensionPopupContext } from '../../Dimensions.Popup'
import { InfoCircleOutlined, LeftOutlined } from '@ant-design/icons'
import '../../index.scss'
import Link from 'antd/lib/typography/Link'

const { TabPane } = Tabs

const DimensionsCustomer = () => {
  const { setDimensionsDraft, editingDimension, setEditingDimension, dimensionsDraft } =
    useContext(dimensionPopupContext)

  const { criteria_groups } = useStoreState((state: any) => state.customerSegments)

  const { customerGroup } = useStoreState((state: { dimensions: StoreProps }) => state.dimensions)
  const [activeSegment, setActiveSegment] = useState<CustomerItem | undefined>(undefined)
  const isSegment = window.location.href.split('/')[4] === 'segments'
  const [isModalVisible, setIsModalVisible] = useState(false)

  const dimensionContainAudience = dimensionsDraft?.find(
    (item) =>
      item?.segments[0]?.audience_Type === 'audience' ||
      item?.segments[0]?.audience_Type === 'customer' ||
      item?.segments[0]?.audience_Type === 'visitor'
  )
  const critGroupsContainAudience = criteria_groups?.find(
    (item) =>
      item?.dimension?.segments[0]?.audience_Type === 'audience' ||
      item?.dimension?.segments[0]?.audience_Type === 'customer' ||
      item?.dimension?.segments[0]?.audience_Type === 'visitor'
  )
  const critGroupsForCheck = criteria_groups?.filter(
    (item) =>
      item?.dimension?.segments[0]?.audience_Type === 'audience' ||
      item?.dimension?.segments[0]?.audience_Type === 'customer' ||
      item?.dimension?.segments[0]?.audience_Type === 'visitor'
  )

  const [audienceType, setAudienceType] = useState(
    dimensionContainAudience?.segments[0]?.audience_Type ||
      critGroupsContainAudience?.dimension?.segments[0]?.audience_Type ||
      'audience'
  )
  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const renderCustomerGroup = useCallback((dim, clickable) => {
    // cloning array
    var segments = dim?.dimensions.slice()

    return computeCustomerNestedGroup(segments).map((segment) => {
      // render nested groups
      if (segment.nestedGroups)
        return (
          <Popover
            overlayClassName="DimensionGroups"
            key={segment?.code}
            placement="right"
            content={
              <div>
                {segment.nestedGroups?.map((item) => (
                  <SegmentItem
                    disableClick={clickable}
                    key={item?.code}
                    segment={item}
                    onClick={() => setActiveSegment(item)}
                  />
                ))}
              </div>
            }
          >
            <div>
              <SegmentItem segment={segment} disableClick={clickable} />
            </div>
          </Popover>
        )

      return (
        <SegmentItem
          disableClick={clickable}
          key={segment?.code}
          segment={segment}
          onClick={() => setActiveSegment(segment)}
        />
      )
    })
  }, [])

  useEffect(() => {
    if (editingDimension) {
      let screen: CustomerItem | undefined

      // eslint-disable-next-line  @typescript-eslint/no-unused-expressions
      customerGroup?.forEach((group) => {
        if (screen) return
        screen = group?.dimensions?.find(
          (dimension) => dimension.code === editingDimension?.segments[0]?.type
        )
      }, {})

      return setActiveSegment(screen)
    }
  }, [editingDimension])

  const renderAlert = () => {
    return (
      <Alert
        closeText={<Link>Dismiss</Link>}
        message={false}
        description={
          <div className="flex items-center">
            <InfoCircleOutlined style={{ color: 'var(--blue_5)', fontSize: 18 }} />
            <div className="ml-3">Some metrics will be delayed for 1 day</div>
          </div>
        }
        type="info"
        action={
          <Space direction="horizontal">
            <Link onClick={showModal}>View List</Link>
          </Space>
        }
      />
    )
  }

  const renderVisitorTabContent = (clickable) => {
    const tempArr = customerGroup?.slice(2, 4)

    return (
      <>
        {renderAlert()}

        <div className="flex grid grid-cols-4 gap-4 p-4 ml-4">
          {tempArr?.map((dim) => (
            <div key={dim.group_code}>
              <h2 className="pl-3 font-bold">{dim.group_name}</h2>
              <div>{renderCustomerGroup(dim, clickable)}</div>
            </div>
          ))}
        </div>
      </>
    )
  }

  const renderFullGroups = (clickable) => {
    return (
      <>
        {renderAlert()}
        <Modal
          title={
            <div
              className="cursor-pointer"
              style={{ fontSize: 14, width: 56 }}
              onClick={handleCancel}
            >
              {' '}
              <LeftOutlined /> Back
            </div>
          }
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={<div style={{ display: 'none' }}></div>}
          footer={null}
          className="DimensionGroups-modal"
        >
          <div className="pl-4" style={{ fontSize: 16, fontWeight: 600 }}>
            These following metrics will be delayed for 1 days
          </div>
          <div
            className="flex grid grid-cols-6 gap-4 p-4 ml-4 overflow-y-scroll"
            style={{ width: 'fit-content', height: '60vh' }}
          >
            {customerGroup?.map((dim) => (
              <div key={dim.group_code} style={{ maxWidth: 200 }}>
                <h2 className="font-bold">{dim.group_name}</h2>
                <div>{renderCustomerGroup(dim, true)}</div>
              </div>
            ))}
          </div>
        </Modal>

        <div className="flex grid grid-cols-4 gap-4 p-4 ml-4">
          {customerGroup?.map((dim) => (
            <div key={dim.group_code}>
              <h2 className="pl-3 font-bold">{dim.group_name}</h2>
              <div>{renderCustomerGroup(dim, false)}</div>
            </div>
          ))}
        </div>
      </>
    )
  }

  if (activeSegment)
    return (
      <SegmentScreen
        onOk={({ audience_Type, type, operator, value, time_operator, time_value }) => {
          if (!!editingDimension) {
            setDimensionsDraft({
              type: 'update',
              payload: {
                index: editingDimension.index,
                newDimension: {
                  segments: [
                    {
                      audience_Type: audienceType,
                      type,
                      operator,
                      value,
                      time_operator,
                      time_value,
                    },
                  ],
                  condition: dimensionsDraft[1]?.condition,
                },
              },
            })
          } else {
            setDimensionsDraft({
              type: 'add',
              payload: {
                dimension: {
                  segments: [
                    {
                      audience_Type: audienceType,
                      type,
                      operator,
                      value,
                      time_operator,
                      time_value,
                    },
                  ],
                  condition: dimensionsDraft[1]?.condition || 'AND',
                },
              },
            })
          }

          setActiveSegment(undefined)
          setEditingDimension(null)
        }}
        onCancel={() => {
          setActiveSegment(undefined)
          setEditingDimension(null)
        }}
        segment={activeSegment}
        initialValues={editingDimension?.segments[0]}
        isEditing={!!editingDimension}
      />
    )

  return (
    <>
      {/* {isSegment ? (
        <Tabs
          defaultActiveKey={
            dimensionContainAudience?.segments[0].audience_Type ||
            critGroupsContainAudience?.dimension?.segments[0].audience_Type
          }
          onTabClick={(value) => setAudienceType(value)}
          //activeKey={`${audienceType}`}
        >
          <TabPane
            tab="Audience"
            key="audience"
            disabled={
              !!criteria_groups?.length && !!dimensionsDraft.length
                ? !!dimensionsDraft.length
                : critGroupsContainAudience?.dimension?.segments[0].audience_Type == 'customer' ||
                  critGroupsContainAudience?.dimension?.segments[0].audience_Type == 'visitor'
            }
          >
            {renderFullGroups(false)}
          </TabPane>
          <TabPane
            tab="Customer"
            key="customer"
            disabled={
              !!criteria_groups?.length && !!dimensionsDraft.length
                ? !!dimensionsDraft.length
                : critGroupsContainAudience?.dimension?.segments[0].audience_Type == 'audience' ||
                  critGroupsContainAudience?.dimension?.segments[0].audience_Type == 'visitor'
            }
          >
            {renderFullGroups(false)}
          </TabPane>
          <TabPane
            tab="Visitor"
            key="visitor"
            disabled={
              !!criteria_groups?.length && !!dimensionsDraft.length
                ? !!dimensionsDraft.length
                : critGroupsContainAudience?.dimension?.segments[0].audience_Type == 'audience' ||
                  critGroupsContainAudience?.dimension?.segments[0].audience_Type == 'customer'
            }
          >
            {renderVisitorTabContent(false)}
          </TabPane>
        </Tabs>
      ) : (
        <div className="flex grid grid-cols-4 gap-4 p-4 ml-4">
          {customerGroup?.map((dim) => (
            <div key={dim.group_code}>
              <h2 className="pl-3 font-bold">{dim.group_name}</h2>
              <div>{renderCustomerGroup(dim, false)}</div>
            </div>
          ))}
        </div>
      )} */}
      <div className="flex grid grid-cols-4 gap-4 p-4 ml-4">
        {customerGroup?.map((dim) => (
          <div key={dim.group_code}>
            <h2 className="pl-3 font-bold">{dim.group_name}</h2>
            <div>{renderCustomerGroup(dim, false)}</div>
          </div>
        ))}
      </div>
    </>
  )
}

export default DimensionsCustomer
