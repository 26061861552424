import { dataOptions, MOY, DOW, DOM } from './Frequency/constant'

export const computeFrequencyValue = (
  value
): {
  key: string
  data: string[]
} => {
  const list = value.split(',').filter((item) => item) || []
  // Jan,Feb
  if (MOY.some((month) => list.includes(month.key))) {
    return { key: 'MOY', data: list }
  }

  // Mon,Tue
  if (DOW.some((day) => list.includes(day.key))) {
    return { key: 'DOW', data: list }
  }

  // 1,2,3
  if (DOM.some((date) => list.includes(date.key.toString()))) {
    return { key: 'DOM', data: list }
  }

  // 01:00 - 05:00,03:00 - 07:00
  if (new RegExp(/^\d{2}:\d{2}/).test(list[0])) {
    return { key: 'HOD', data: list }
  }

  return {
    key: 'MOY',
    data: [],
  }
}

export const computeTimeRangeValue = (value): { from_date: string; to_date: string } => {
  // 18-12-1998,22:00 - 31-12-1998,23:00
  const timeRange = new RegExp(/^\d{2}-\d{2}-\d{4}/).test(value) ? value.split(' - ') : undefined
  if (!!timeRange) {
    return { from_date: timeRange[0], to_date: timeRange[1] }
  }

  return { from_date: '', to_date: '' }
}
export const onChangeWithFormattedValue = (tab, value, callback) => {
  const formatFrequency = ({ key, data }) => {
    var content: string[] = []
    if (key == 'HOD') {
      data.map((item) => {
        content.push(`${item.fromTime || ''} ${item.toTime ? '-' : ''} ${item.toTime || ''}`)
      })
      callback(content.toString())
    } else {
      callback(data.toString())
    }
  }

  const formatTimeRange = ({ from_date, to_date }) => {
    const fromDateSplit = from_date?.split(',')
    const toDateSplit = to_date?.split(',')

    var from_date_value = ''
    var to_date_value = ''

    if (fromDateSplit && fromDateSplit[1]) {
      from_date_value = `${fromDateSplit[0] || ''},${fromDateSplit[1] || ''}`
    } else {
      from_date_value = from_date
    }

    if (toDateSplit && toDateSplit[1]) {
      to_date_value = `${toDateSplit[0] || ''},${toDateSplit[1] || ''}`
    } else {
      to_date_value = to_date
    }

    const value = `${from_date_value || ''} ${to_date_value ? '-' : ''} ${to_date_value || ''}`

    callback(value)
  }

  switch (tab) {
    case 'Frequency':
      return formatFrequency(value)

    case 'TimeRange':
      return formatTimeRange(value)
  }
}
