import React from 'react'
import { message } from 'antd'

import Tooltip from 'Components/ExternalComponents/Tooltip'
import copyTextToClipboard from 'Utils/copy'

interface Props {
  children?: JSX.Element
  textToCopy: string
  tooltipText?: string
  onCopyEnd?: () => void
}

const CopyToClipboard: React.FC<Props> = ({
  children,
  textToCopy,
  tooltipText = 'Copy to clipboard',
  onCopyEnd = () => {},
}) => {
  const onCopyToClipboard = () => {
    copyTextToClipboard(textToCopy)
      .then(() => {
        message.success('Copied to clipboard!')
      })
      .catch(() => {
        message.error('Sorry, your browser does not support copying. Use Ctrl / Cmd + C!')
      })
      .finally(() => {
        onCopyEnd()
      })
  }

  const getDecoratedCopyNode = () => {
    const copyNode = children ? children : <span>Copy to clipboard</span>
    return React.cloneElement(copyNode, {
      style: { cursor: 'pointer' },
      onClick: onCopyToClipboard,
    })
  }
  return (
    <Tooltip placement="top" style={{ cursor: 'pointer' }} title={tooltipText} trigger={['hover']}>
      {getDecoratedCopyNode()}
    </Tooltip>
  )
}

export default CopyToClipboard
