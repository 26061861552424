function hexToRGBA(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')'
  }
}

const systemColor = {
  volcano_1: '#FFF2E8',
  volcano_6: '#FA541C',

  // Red Color
  red_1: '#FFF1F0',
  red_2: '#FFCCC7',
  red_3: '#FFA39E',
  red_4: '#FF7875',
  red_5: '#FF4D4F',
  red_6: '#F5222D',
  red_7: '#CF1322',
  red_8: '#A8071A',
  red_9: '#820014',
  red_10: '#5C0011',

  // Blue Color
  blue_1: '#E6F7FF',
  blue_2: '#BAE7FF',
  blue_3: '#91D5FF',
  blue_4: '#69C0FF',
  blue_5: '#40A9FF',
  blue_6: '#1890FF',
  blue_7: '#096DD9',
  blue_8: '#0050B3',
  blue_9: '#003A8C',
  blue_10: '#002766',

  // green Color
  green_1: '#F6FFED',
  green_2: '#D9F7BE',
  green_3: '#B7EB8F',
  green_4: '#95DE64',
  green_5: '#73D13D',
  green_6: '#52C41A',
  green_7: '#389E0D',
  green_8: '#237804',
  green_9: '#135200',
  green_10: '#092B00',

  // yellow Color
  yellow_1: '#FEFFE6',
  yellow_2: '#FFFFB8',
  yellow_3: '#FFFB8F',
  yellow_4: '#FFF566',
  yellow_5: '#FFEC3D',
  yellow_6: '#FADB14',
  yellow_7: '#D4B106',
  yellow_8: '#AD8B00',
  yellow_9: '#876800',
  yellow_10: '#614700',

  // orange Color
  orange_1: '#FFF7E6',
  orange_2: '#FFE7BA',
  orange_3: '#FFD591',
  orange_4: '#FFC069',
  orange_5: '#FFA940',
  orange_6: '#FA8C16',
  orange_7: '#D46B08',
  orange_8: '#AD4E00',
  orange_9: '#873800',
  orange_10: '#612500',

  // gold Color
  gold_1: '#FFFBE6',
  gold_2: '#FFF1B8',
  gold_3: '#FFE58F',
  gold_4: '#FFD666',
  gold_5: '#FFC53D',
  gold_6: '#FAAD14',
  gold_7: '#D48806',
  gold_8: '#AD6800',
  gold_9: '#874D00',
  gold_10: '#613400',

  // lime Color
  lime_1: '#FCFFE6',
  lime_2: '#F4FFB8',
  lime_3: '#EAFF8F',
  lime_4: '#D3F261',
  lime_5: '#BAE637',
  lime_6: '#A0D911',
  lime_7: '#7CB305',
  lime_8: '#5B8C00',
  lime_9: '#3F6600',
  lime_10: '#254000',

  // Gray colors
  gray_1: '#FFFFFF',
  gray_2: '#FAFAFA',
  gray_3: '#F5F5F5',
  gray_4: '#F0F0F0',
  gray_5: '#D9D9D9',
  gray_6: '#BFBFBF',
  gray_7: '#8C8C8C',
  gray_8: '#595959',
  gray_9: '#434343',
  gray_10: '#262626',
  gray_11: '#1F1F1F',
  gray_12: '#141414',
  gray_13: '#000000',

  // purple Color
  purple_1: '#F9F0FF',
  purple_2: '#EFDBFF',
  purple_3: '#D3ADF7',
  purple_4: '#B37FEB',
  purple_5: '#9254DE',
  purple_6: '#722ED1',
  purple_7: '#531DAB',
  purple_8: '#391085',
  purple_9: '#22075E',
  purple_10: '#120338',

  // cyan Color
  cyan_1: '#E6FFFB',
  cyan_2: '#B5F5EC',
  cyan_3: '#87E8DE',
  cyan_4: '#5CDBD3',
  cyan_5: '#36CFC9',
  cyan_6: '#13C2C2',
  cyan_7: '#08979C',
  cyan_8: '#006D75',
  cyan_9: '#00474F',
  cyan_10: '#002329',

  // platinum Color
  platinum_1: '#F8FAFC',
  platinum_2: '#F1F5F9',
  platinum_3: '#E2E8F0',
  platinum_4: '#CBD5E1',
  platinum_5: '#94A3B8',
  platinum_6: '#64748B',
  platinum_7: '#475569',
  platinum_8: '#334155',
  platinum_9: '#1E293B',
  platinum_10: '#0F172A',

  // magenta Color
  magenta_1: '#FFF0F6',
  magenta_2: '#FFD6E7',
  magenta_3: '#FFADD2',
  magenta_4: '#FF85C0',
  magenta_5: '#F759AB',
  magenta_6: '#EB2F96',
  magenta_7: '#C41D7F',
  magenta_8: '#9E1068',
  magenta_9: '#520339',
  magenta_10: '#520339',

  // greekBlue Color
  greekBlue_1: '#F0F5FF',
  greekBlue_2: '#D6E4FF',
  greekBlue_3: '#ADC6FF',
  greekBlue_4: '#85A5FF',
  greekBlue_5: '#597EF7',
  greekBlue_6: '#2F54EB',
  greekBlue_7: '#1D39C4',
  greekBlue_8: '#10239E',
  greekBlue_9: '#061178',
  greekBlue_10: '#030852',
}

const productColor = {
  brand_primary: systemColor.blue_6,
  brand_dark: systemColor.blue_7,
  brand_accent: systemColor.blue_5,
  brand_light: systemColor.blue_1,
  black: systemColor.gray_13,
  white: systemColor.gray_1,

  positive: systemColor.green_6,
  negative: systemColor.red_6,
  warning: systemColor.gold_6,

  primary_text: hexToRGBA(systemColor.gray_13, 0.85),
  secondary_text: hexToRGBA(systemColor.gray_13, 0.6),
  divider: hexToRGBA(systemColor.gray_13, 0.06),
  disabled_text: hexToRGBA(systemColor.gray_13, 0.45),
  border: hexToRGBA(systemColor.gray_13, 0.15),
  background_disabled: systemColor.gray_4,
  hover_overlay: hexToRGBA(systemColor.blue_1, 0.5),
  table_header: systemColor.gray_2,
  background_opaque: systemColor.gray_3,
  // association
  fb_color: '#3b5988',
  gg_color: '#ea4335',
  haravan_color: '#2C3C8A',
}

module.exports = { systemColor, productColor }
