import React from 'react'
import { Select } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'

const Source: React.FC = () => {
  const { source } = useStoreState((state) => state.customerSegments)
  const { setState } = useStoreActions((action: any) => action.customerSegments)
  return (
    <Select
      value={source || undefined}
      placeholder="Select"
      className="w-full font-semibold user-dest-input"
      onChange={(value) => {
        setState({ key: 'need_insights_refresh', value: true })
        setState({ key: 'source', value })
      }}
    >
      <Select.Option value="kariba">Kariba</Select.Option>
      {/* <Select.Option value="facebook">Facebook</Select.Option> */}
    </Select>
  )
}

export default Source
