import React from 'react'
import { Typography, Tooltip } from 'antd'
import moment from 'moment'

const { Text } = Typography

export const DASHBOARD_TABS: { label: string; value: string }[] = [
  { label: 'Tổng quan', value: 'general' },
  { label: 'Chi tiết', value: 'detail' },
]

export const METRICS = [
  { label: 'Sessions', value: 123 },
  { label: 'AOV', value: 750000 },
  { label: 'AOV', value: 750000 },
  { label: 'AOV', value: 750000 },
  { label: 'AOV', value: 750000 },
]

export const COLUMNS = [
  {
    title: 'Campaign',
    dataIndex: 'name',
    sorter: true,
    align: 'left',
    render: (campaign) => (
      <Tooltip title={campaign}>
        <Text className="campaign-title" strong>
          {campaign}
        </Text>
      </Tooltip>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 100,
    sorter: true,
  },
  {
    title: 'Views',
    dataIndex: ['analytic_key_metrics', 'views'],
    width: 100,
    render: (value) => value?.toLocaleString(),
  },
  {
    title: 'CPC',
    dataIndex: ['analytic_key_metrics', 'cpc'],
    width: 50,
    render: (value) => value?.toLocaleString(),
  },
  {
    title: 'Clicks',
    dataIndex: ['analytic_key_metrics', 'clicks'],
    width: 50,
    render: (value) => value?.toLocaleString(),
  },
  {
    title: 'CTR',
    dataIndex: ['analytic_key_metrics', 'ctr'],
    width: 50,
    render: (value) => {
      if (typeof value === 'number') return (value * 100).toLocaleString() + '%'
      else return 'N/A'
    },
  },
  {
    title: 'Performance',
    dataIndex: 'performance',
    width: 100,
    sorter: true,
  },
  {
    title: 'Updated on',
    dataIndex: 'updated_at',
    sorter: true,
    render: (text) => moment(text).format('DD-MM-YYYY'),
    width: 150,
  },
]

export const IS_ACTIVE = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]
