import React from 'react'
import { Popover } from 'antd'
import { useStoreState } from 'easy-peasy'
import MetricPopoverModal from './MetricPopoverModal'
import { MetricElementProps } from '../index.constants'

const MetricElement = ({
  index,
  column,
  aggregate = 'sum',
  onAdd,
  onChange,
  options,
}: MetricElementProps) => {
  const label = options.find((col) => col.code === column)?.name || column
  const [visible, setVisible] = React.useState(false)
  const handleChange = (columnValue, aggregateValue) => {
    onChange(columnValue, aggregateValue, index as number)
    setVisible(false)
  }
  return (
    <Popover
      className="MetricElementComponentPopover"
      trigger="click"
      visible={visible}
      destroyTooltipOnHide
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      onVisibleChange={() => setVisible(!visible)}
      content={
        <MetricPopoverModal
          options={options}
          onAdd={onAdd}
          index={index}
          onChange={handleChange}
          defaultColumnValue={column}
          defaultAggregateValue={aggregate}
        ></MetricPopoverModal>
      }
    >
      <div
        className="MetricElementComponent"
        onClick={() => setVisible(!visible)}
      >{`${aggregate.toUpperCase()}(${label})`}</div>
    </Popover>
  )
}

export default MetricElement
