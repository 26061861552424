import React from 'react'
import FiltersHeader from '../../Components/FiltersHeader'
import SaleChannelOverviewBody from './Body/Body'

const Overview = () => {
  return (
    <div className="AnalyticsWrapper relative">
      <div>
        <FiltersHeader />
      </div>
      <SaleChannelOverviewBody />
    </div>
  )
}

export default Overview
