import React, { useState, useEffect } from 'react'
import { Button, Collapse } from 'antd'
import { useStoreActions } from 'easy-peasy'
import { LeftOutlined } from '@ant-design/icons'

import { getAudienceSegments } from 'Services/customer-segments'
import LoadingIcon from 'Components/LoadingIcon'
import Default from './Default'
import { setSegmentData } from './helpers'

interface CustomerSegmentInfo {
  id: number
  created_at: string
  updated_at: string
  name: string
  description: string
  store_ids: number[]
  predictive_segment: string
  audience_type: string
  dimensions: {
    segments: {
      type: string
      value: string
      operator: string
    }[]
    condition: null
  }[]
}

interface props {
  onSelectScreen: (type: string) => void
}

const activeTemplateClasses = 'text-brand_primary bg-blue-100'

const CATEGORIES = ['AI Prediction', 'Attributes', 'Interactions', 'Channels', 'Products']

const Template: React.FC<props> = ({ onSelectScreen }) => {
  const { setState, resetState } = useStoreActions((action: any) => action.customerSegments)

  const [isLoading, setIsLoading] = useState(false)
  const [templates, setTemplates] = useState<CustomerSegmentInfo[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<CustomerSegmentInfo | null>(null)
  const [errors, setErrors] = useState('')

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const resp = await getAudienceSegments({
        params: { is_template: true, per_page: 20, type: 'kariba' },
      })
      setErrors('')
      setTemplates(resp.data)
      setSelectedTemplate(resp.data[0])
    } catch (err: any) {
      setErrors(err.message || 'Something went wrong')
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!selectedTemplate) return
    setSegmentData(selectedTemplate, setState)
    setState({ key: 'need_insights_refresh', value: true })
  }, [selectedTemplate, setState])

  if (isLoading)
    return (
      <div className="flex w-full justify-center p-5">
        <LoadingIcon style={{ width: 40 }} />
      </div>
    )

  const renderTemplates = () => {
    return (
      <div className="template-menu shadow-bot_1 h-full">
        <div className="p-4  shadow-div_b">
          <Button
            disabled={!selectedTemplate}
            type="primary"
            className="bg-brand_primary w-full"
            onClick={() => {
              onSelectScreen('DRAFT')
            }}
          >
            Choose this template
          </Button>
        </div>
        <div className="p-4  shadow-div_b">
          <h2 className="text-gray_13 font-semibold text-lg mb-2">Recently used</h2>
          {templates.map((template) => (
            <div
              key={template.id}
              onClick={() => setSelectedTemplate(template)}
              className={`w-full rounded cursor-pointer px-4 py-2 mb-1 ${
                template.id === selectedTemplate?.id ? 'bg-hover_overlay' : ''
              }`}
            >
              {template.name}
            </div>
          ))}
        </div>
        <div className="p-4">
          <h2 className="text-gray_13 font-bold text-lg">All templates</h2>
          <Collapse expandIconPosition="right">
            {CATEGORIES.map((cat) => (
              <Collapse.Panel key={cat} header={`By ${cat}`}></Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </div>
    )
  }

  const handleBack = () => {
    resetState()
    onSelectScreen('')
  }
  const renderMeat = () => {
    if (selectedTemplate) return <Default></Default>
    else return <div className="p-8 text-xl font-semibold">Please select a template</div>
  }
  return (
    <div className="template-screen w-full">
      {errors && <div className="bg-red-500 p-2 my-3 w-full text-white">{errors}</div>}
      <div className="flex w-full justify-between bg-white">
        <div className="w-full">
          <div className="header-wrapper flex flex-col w-full px-8 pt-4 pb-6">
            <div className="flex items-center cursor-pointer" onClick={handleBack}>
              <span className="mr-4">
                <LeftOutlined />
              </span>
              <span className="font-semibold">Templates</span>
            </div>
            <span className="text-2xl font-semibold mt-10">
              {selectedTemplate?.name || 'Untitled'}
            </span>
          </div>
          <div className="meat-wrapper bg-background_opaque">{renderMeat()}</div>
        </div>
        <div className="template-wrapper bg-gray_1">{renderTemplates()}</div>
      </div>
    </div>
  )
}

export default Template
