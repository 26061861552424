import {
  CampaignPivotTypes,
  CustomerPivotTypes,
  ProductPivotTypes,
} from 'Services/Analytics/Pivot.service'

export type SQL_TYPE = 'TEXT' | 'NUMBER'
export type SQL_AGGREGATE_TYPE = 'avg' | 'count' | 'count_distinct' | 'max' | 'min' | 'sum'
export const SQL_AGGREGATE: SQL_AGGREGATE_TYPE[] = [
  'avg',
  'count',
  'count_distinct',
  'max',
  'min',
  'sum',
]

export type PivotTableObject = 'campaing' | string

export type PivotCustomHook = {
  itemRef: React.RefObject<HTMLInputElement>
  index: number
  onDropped: (dragIndex: number, dropIndex: number) => any
  onDragging?: (dragIndex: number, dropIndex: number) => any
  type: string
  acceptTypes?: string[]
}

export enum DRAG_TYPES {
  METRIC = 'METRIC',
  COLUMN = 'COLUMN',
  DIMENSION = 'DIMENSION',
}

export type SelectOptions = {
  name?: string
  code: string
  display_name?: string
  disabled?: boolean
  type: string
}

export type ColumnSelectProps = {
  defaultValue: any
  placeholder?: string
  className?: string
  autoFocus?: boolean
  onChange: (code: string) => any
  options: SelectOptions[]
}

export type Element = {
  name?: string
  code: string
  aggregate?: string
}

export type PivotElementListProps = {
  title: string
  options: SelectOptions[]
  elements: Element[]
  className?: string
  isMetric?: boolean
  onChange: (elements: Element[]) => any
}

export type PivotElementProps = {
  name?: string
  code: string
  index: number
  aggregate?: any
  onDropItem: (dragIndex: number, dropIndex: number) => any
  isMetric?: boolean
  onRemove: (index: number) => any
  onDragging: (dragIndex: number, dropIndex: number) => any
  onChange: (value: string, index?: any) => any
  onMetricChange: (value: string, aggregate: number, index?: any) => any
  dragType: DRAG_TYPES
}

export type PivotTableContainerProps = {
  isRanking?: boolean
  className?: string
  defaultMetrics: Element[]
  defaultDimensions: Element[]
  defaultColumns?: Element[]
  object?: PivotTableObject
  objectId?: string | number
  dateRange: [string, string] | []
  type?: CampaignPivotTypes | CustomerPivotTypes | ProductPivotTypes
  isCustomer?: boolean
  hideDimensionSelects?: boolean
  hideMetricSelects?: boolean
  hideColumnSelects?: boolean
  currentPage?: string | undefined
  dimension_segments?: any
  barcode?: string
}

export type AggregateSelectProps = {
  className?: string
  placeholder?: string
  defaultValue?: string
  onChange?: (value: string) => any
}

export type MetricPopoverModalProps = {
  defaultColumnValue?: any
  defaultAggregateValue?: any
  className?: string
  index?: number
  onAdd?: (column: any, aggregate: any) => any
  onChange: (column: any, aggregate: any, index: number) => any
  options: SelectOptions[]
}

export type MetricElementProps = {
  index?: number
  column: string
  aggregate: string
  onAdd?: (column: any, aggregate: any) => any
  onChange: (column: any, aggregate: any, index: number) => any
  options: SelectOptions[]
}

export type PivotTableProps = {
  dimensions: Element[]
  metrics: Element[]
  columns?: Element[]
  object?: PivotTableObject
  objectId?: string | number
  dateRange: [string, string] | []
  type?: CampaignPivotTypes | CustomerPivotTypes | ProductPivotTypes
  isCustomer?: boolean
  currentPage?: string | undefined
  dimension_segments?: any
  isRanking?: boolean
  barcode?: string
}
