import React, { useState, useRef, useEffect } from 'react'
import { Tabs, Select, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import { onChangeWithFormattedValue, computeTimeRangeValue, computeFrequencyValue } from './helpers'
import { useClickOutside, useKeyPress } from 'Hooks'
import TimeRange from './TimeRange'
import Frequency from './Frequency'

import './index.scss'

export type props = {
  isFrequencyDisabled?: boolean
  isTimeOptionsDisabled?: boolean
  value: string // 18-12-1998 - 31-12-1998 // 22:00 - 23:00,
  onChange: (value: any) => void
}

const TimeValue: React.FC<props> = ({
  value: valueInput,
  onChange,
  isFrequencyDisabled,
  isTimeOptionsDisabled,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const ref = useRef(null)
  const dropdownRef = useRef(null)

  useClickOutside(ref, setIsVisible, dropdownRef)
  useKeyPress('Enter', (isKeyPressed: boolean) => setIsVisible(!isKeyPressed))
  useKeyPress('Escape', (isKeyPressed: boolean) => setIsVisible(!isKeyPressed))
  // console.log('whyyyy:', computeFrequencyValue(valueInput).data.length)
  // console.log('frequency', isFrequencyDisabled)
  // console.log('time', isTimeOptionsDisabled)
  const computeActiveKey = () => {
    if (
      (isFrequencyDisabled == true && isTimeOptionsDisabled == false) ||
      (isFrequencyDisabled == true && isTimeOptionsDisabled == true)
    ) {
      return '1'
    } else if (isFrequencyDisabled == false && isTimeOptionsDisabled == true) {
      return '2'
    } else {
      return undefined
    }
  }

  const DROPDOWN = (
    <div
      ref={dropdownRef}
      className="bg-gray_1 border p-2 overflow-visible"
      style={{
        width: 400,
        borderRadius: 'var(--br_DEFAULT)',
        backgroundColor: 'var(--white)',
        boxShadow: 'var(--bot_3)',
      }}
    >
      <Tabs
        centered
        activeKey={computeActiveKey()}
        defaultActiveKey={computeFrequencyValue(valueInput).data.length ? '2' : '1'}
      >
        <Tabs.TabPane disabled={isTimeOptionsDisabled} tab="Time Range" key="1">
          <TimeRange
            isTimeOptionsDisabled={isTimeOptionsDisabled}
            value={computeTimeRangeValue(valueInput)}
            onChange={({ from_date, to_date }) => {
              onChangeWithFormattedValue(
                'TimeRange',
                isTimeOptionsDisabled ? { from_date } : { from_date, to_date },
                onChange
              )
            }}
          ></TimeRange>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Frequency" key="2" disabled={isFrequencyDisabled}>
          <Frequency
            setIsVisible={setIsVisible}
            value={computeFrequencyValue(valueInput)}
            onChange={(value) => {
              onChangeWithFormattedValue('Frequency', value, onChange)
            }}
          ></Frequency>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )

  return (
    <div
      ref={ref}
      className="TimeInputs w-full border-gray_5 transition hover:border-brand_primary border cursor-pointer relative"
      style={{ height: 40, borderRadius: 4, float: 'right' }}
    >
      <Dropdown
        placement={'bottomRight'}
        visible={isVisible}
        trigger={['click']}
        overlay={DROPDOWN}
        getPopupContainer={(trigger: any) => trigger.parentNode}
      >
        <div
          onClick={() => setIsVisible(!isVisible)}
          className="py-2 px-4 flex justify-between items-center"
        >
          <div className="flex text-primary_text truncate">
            <div className="truncate">
              {valueInput || <span className="text-disabled_text">Select Date</span>}
            </div>
          </div>
          <div className="ml-2 text-xs">
            <DownOutlined />
          </div>
        </div>
      </Dropdown>
    </div>
  )
}

export default TimeValue
