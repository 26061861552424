import { action, thunk, debug } from 'easy-peasy'
import axios from 'Utils/axios'

const initState = {
  id: null,
  name: 'a',
  description: 'a',
  type: 'welcome_push',
  channel: 'toast',
}
export default {
  ...initState,
  resetState: action((state) => ({ ...initState })),
  setActionTriggerStates: action((state, { key, value }) => {
    state[key] = value
  }),

  createActionTrigger: thunk(async (actions, { htmlTemplate }, { getState, getStoreState }) => {
    actions.setActionTriggerStates({ key: 'isLoading', value: true })
    const state = getState()
    const storeState = getStoreState()
    try {
      const resp = await axios.post('/action-triggers/', {
        name: state.name,
        description: state.description,
        type: state.type,
        channel: state.channel,
        status: state.status,
        value: JSON.stringify({
          ...storeState.campaign.creativeContents.previewData,
          htmlTemplate,
        }),
      })
      actions.resetState()
      actions.setActionTriggerStates({ key: 'id', value: resp.data.id })

      return { ok: 'created', id: resp.data.id }
    } catch (err) {
      return Promise.reject({ errors: err.response.data })
    } finally {
      actions.setActionTriggerStates({ key: 'isLoading', value: false })
    }
  }),

  getActionTrigger: thunk(async (actions, _, { getState, getStoreState, getStoreActions }) => {
    const storeActions = getStoreActions()
    actions.setActionTriggerStates({ key: 'isLoading', value: true })

    // get first item that has toast channel (cheating lol)
    let id
    const actionTriggerListResp = await axios.get(`/action-triggers/`)
    if (actionTriggerListResp.status === 200) {
      if (!actionTriggerListResp.data.results.length) {
        actions.setActionTriggerStates({ key: 'isLoading', value: false })
        return { id: null }
      }
      id = actionTriggerListResp.data.results.filter((item) => item.channel === 'toast')[0].id
    }

    const resp = await axios.get(`action-triggers/${id}`)
    if (resp.status === 200) {
      actions.setActionTriggerStates({ key: 'id', value: resp.data.id })
      actions.setActionTriggerStates({ key: 'name', value: resp.data.name })
      actions.setActionTriggerStates({ key: 'description', value: resp.data.description })
      actions.setActionTriggerStates({ key: 'type', value: resp.data.type })
      actions.setActionTriggerStates({ key: 'channel', value: resp.data.channel })
      actions.setActionTriggerStates({ key: 'status', value: resp.data.status })
      storeActions.campaign.creativeContents.setState({
        key: 'previewData',
        value: resp.data.value,
      })
    }

    actions.setActionTriggerStates({ key: 'isLoading', value: false })
    return { id }
  }),
  updateActionTrigger: thunk(async (actions, { htmlTemplate }, { getState, getStoreState }) => {
    const state = getState()
    const storeState = getStoreState()
    actions.setActionTriggerStates({ key: 'isLoading', value: true })
    try {
      const values = storeState.campaign.creativeContents.previewData
      await axios.put(`/action-triggers/${state.id}`, {
        name: state.name,
        description: state.description,
        type: state.type,
        channel: state.channel,
        status: state.status,
        value: JSON.stringify({
          ...values,
          htmlTemplate,
        }),
      })

      return { ok: 'updated', id: state.id }
    } catch (err) {
      return Promise.reject({ errors: err.response.data })
    } finally {
      actions.setActionTriggerStates({ key: 'isLoading', value: false })
    }
  }),
  deleteActionTrigger: thunk(async (actions, id) => {
    const resp = await axios.delete(`action-triggers/${id}`)

    if (resp.status === 200) {
      return { ok: true }
    }

    return { errors: resp.response.data }
  }),
  setActionTriggerStatus: thunk(async (actions, { id, action }) => {
    const resp = await axios.post(`action-triggers/${id}/action?action=${action}`)

    if (resp.status === 200) {
      actions.setActionTriggerStates({ key: 'status', value: resp.data.status })
      return { ok: true, status: resp.data.status }
    }
    return { errors: resp.response.data }
  }),
}
