import React from 'react'
import { Checkbox } from 'antd'
import FieldWrapper from '../FieldWrapper'

const CheckBox = (props: any) => {
  const { name, values, disabled, onChange } = props

  return (
    <FieldWrapper {...props}>
      {(fieldProps: any) => (
        <div className="CheckBoxComponent">
          <Checkbox
            name={name}
            value={values[name]}
            checked={values[name]}
            disabled={disabled}
            onChange={(e) => {
              onChange(name, e.target.checked)
              props.setFieldValue(name, e.target.checked)
            }}
          />
        </div>
      )}
    </FieldWrapper>
  )
}

export default CheckBox
