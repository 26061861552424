import React from 'react'
import TestPivot from 'Pages/TestPivot'
import PivotTableTemplate from 'Pages/Analytics/Components/PivotTable.Template'
import { ProductPivotTypes } from 'Services/Analytics/Pivot.service'
import SaleChannelPivotTableTemplate from '../../Components/PivotTable.Template'
const PivotRow1 = () => {
  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <div className="font-semibold">Customer x Sale Channels</div>
        {/* <div className="flex text-brand_primary">
        <div className="mr-4">
          <RefreshIcon />
        </div>
        <div>
          <DownIcon />
        </div>
      </div> */}
      </div>
      <SaleChannelPivotTableTemplate analytic_type={'customer_x_sale_channel'} isStore={false} />
    </div>
  )
}

export default PivotRow1
