import React from 'react'
import { CUSTOMER_DIMENSION, CUSTOMER_METRICS } from 'Pages/Analytics/constants'
import PivotTableTemplate from '../../../Components/PivotTable.Template'
import { CustomerPivotTypes, ProductPivotTypes } from 'Services/Analytics/Pivot.service'
import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'
import SaleChannelPivotTableTemplate from '../../Components/PivotTable.Template'

const PivotRow2 = () => {
  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <div className="font-semibold">Top Sellers on Sale channels</div>
        {/* <div className="flex text-brand_primary">
          <div className="mr-4">
            <RefreshIcon />
          </div>
          <div>
            <DownIcon />
          </div>
        </div> */}
      </div>
      <SaleChannelPivotTableTemplate
        analytic_type={'top_sellers_on_sale_channel'}
        isStore={false}
      />
    </div>
  )
}

export default PivotRow2
