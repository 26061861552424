import { Popover } from 'antd'
import CJPopover from 'Components/CJPopover'
import React, { Fragment, useState } from 'react'
import {
  CJ_NODE_MARGIN,
  CJ_NODE_WIDTH,
  CJ_NODE_HEIGHT,
} from 'Containers/CustomerJourneyDesign/constants'

const VirtualNode = ({
  isAddPopoverVisible,
  setIsAddPopoverVisible,
  onAddNewNode,
  nodeType,
  bgColor,
  parentNode,
  node_attribute,
  isRunning,
}) => {
  const RADIUS = 10
  const lineStyle = { stroke: bgColor, strokeWidth: 3, fill: bgColor }
  return (
    <Fragment>
      <Popover
        placement="right"
        visible={isAddPopoverVisible}
        overlayClassName="CJPopover"
        trigger="click"
        onVisibleChange={(visible) => (isRunning ? false : setIsAddPopoverVisible(visible))}
        content={
          <CJPopover
            onAddNewNode={onAddNewNode}
            nodeType={nodeType}
            isPopoverVisible={isAddPopoverVisible}
            setIsPopoverVisible={setIsAddPopoverVisible}
            type="default"
            parentNode={parentNode}
            node_attribute={node_attribute}
          />
        }
      >
        <g transform={`translate(${CJ_NODE_WIDTH / 2}, 11)`}>
          {!isRunning ? (
            <>
              <circle cx={0} cy={0} r={RADIUS} style={lineStyle}></circle>
              <line
                x1={0}
                y1={-6}
                x2={0}
                y2={6}
                style={{ ...lineStyle, strokeWidth: 2, stroke: '#ffff' }}
              ></line>
              <line
                x1={-6}
                y1={0}
                x2={6}
                y2={0}
                style={{ ...lineStyle, strokeWidth: 2, stroke: '#ffff' }}
              ></line>
            </>
          ) : (
            <>
              <circle cx={0} cy={0} r={18} style={lineStyle}></circle>
              <text fill="#FFFF" stroke={1} y={5} x={-12}>
                End
              </text>
            </>
          )}
        </g>
      </Popover>
    </Fragment>
  )
}
export default VirtualNode
