import React, { useState, useEffect } from 'react'
import { BarLineChart } from 'Components/Charts/MixCharts'
import axios from 'Utils/axios'
import MetricCard from 'Components/MetricCard'
import { Empty, Skeleton } from 'antd'
import { CES_COLORS } from 'Containers/Customer360/data'
import { COLOR_PALLETTE } from 'Utils/constants'
import { colors } from 'DesignSystem'
import { Moment } from 'moment'
import renderChart from 'Components/Charts/MixCharts/BarLineChart/renderChart'
const COLORS = {
  revenue: colors.productColor.brand_primary,
  orders: colors.systemColor.orange_4,
  sessions: colors.systemColor.cyan_4,
}

type OverviewProps = {
  fromDate: Moment
  toDate: Moment
  type: 'personal' | 'dashboard'
}

const Overview = ({ fromDate, toDate, type }: OverviewProps) => {
  const [metrics, setMetrics] = useState(null)
  const [chart, setChart] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      try {
        let fDate = fromDate.format('YYYY-MM-DD')
        let tDate = toDate.format('YYYY-MM-DD')
        let url = '/social-selling/personal-performance/overview'
        if (type === 'dashboard') url = '/social-selling/dashboard/overview'
        const resp = await axios.get(
          `${url}?from_date=${fDate}&to_date=${tDate}&company=${process.env.REACT_APP_COMPANY_NAME}&force=true`
        )
        setMetrics(resp.data.overview)
        setChart(resp.data.chart)
      } catch (error: any) {}
      setLoading(false)
    })()
  }, [fromDate, toDate])

  const renderMetricCards = () => {
    if (loading)
      return (
        <div className="w-full p-3 bg-white grid-full-top">
          <Skeleton></Skeleton>
        </div>
      )
    if (!metrics)
      return (
        <div className="w-full p-3 bg-white grid-full-top">
          <Empty></Empty>
        </div>
      )
    // @ts-ignore: Object is possibly 'null'.
    return metrics.map((m, index) => (
      <MetricCard
        key={index}
        className={`box-${index + 1}`}
        title={m.title}
        unit={m.unit}
        value={m.value}
      ></MetricCard>
    ))
  }

  const renderChart = () => {
    if (loading)
      return (
        <div className="w-full bg-white grid-full-top">
          <Skeleton></Skeleton>
        </div>
      )
    if (!chart)
      return (
        <div className="w-full bg-white grid-full-top">
          <Empty></Empty>
        </div>
      )
    return <BarLineChart data={chart} opts={{ colors: COLORS }}></BarLineChart>
  }

  return (
    <div className={`OverviewComponent mt-4 ${type}`}>
      <div className="metric-cards-wrapper">{renderMetricCards()}</div>
      <div className="statistical-wrapper mt-4 bg-white p-3">{renderChart()}</div>
    </div>
  )
}

export default Overview
