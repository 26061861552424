import React from 'react'
import { Typography, Tooltip } from 'antd'
import StatusTag from 'Components/StatusTag'
import moment from 'moment'
import { capitalizeFirstLetter } from 'Utils'
import InUsedCampaign from 'Pages/Audiences/InUsedCampaign'

const { Text } = Typography

export const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    align: 'left',
    render: (audience) => {
      if (audience)
        return (
          <Text className="audience-title font-bold" strong>
            {audience}
          </Text>
        )
      else return 'N/A'
    },
  },
  {
    title: 'Type',
    dataIndex: 'audience_type',
    width: 100,
    sorter: true,
    render: (tag) => (tag ? capitalizeFirstLetter(tag) : 'N/A'),
  },
  {
    title: 'Last updated',
    dataIndex: 'updated_at',
    sorter: true,
    render: (text) => (text ? moment(text).format('DD-MM-YYYY') : 'N/A'),
    width: 150,
  },
  {
    title: 'Created by',
    dataIndex: 'created_by_detail',
    width: 200,
    render: (creator) =>
      creator ? `${creator?.first_name || ''} ${creator?.last_name || ''}` : 'N/A',
  },
  {
    title: 'Used in',
    dataIndex: 'used_in_detail',
    align: 'left',
    width: 300,
    render: (used_in_detail) =>
      used_in_detail ? <InUsedCampaign used_in_detail={used_in_detail}></InUsedCampaign> : 'N/A',
  },
]

export const STATUS = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'draft' },
]

export const SOURCE = [
  { label: 'Kariba', value: 'kariba' },
  // { label: 'Facebook', value: 'facebook' },
]

export const USED_IN = [
  { label: 'Active campaign', value: 'active' },
  { label: 'Inactive campaign', value: 'inactive' },
  { label: 'Not in use', value: 'not_in_use' },
]

export const FILTER_TABS = (creators, filters, setFilters) => {
  return [
    {
      label: 'Status',
      value: 'segment_status',
      options: STATUS,
      type: 'TAB' as const,
      onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
    },
    {
      label: 'User source',
      value: 'user_source',
      options: SOURCE,
      type: 'DROPDOWN' as const,
      onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
    },
    {
      label: 'Used in',
      value: 'used_in',
      options: USED_IN,
      type: 'DROPDOWN' as const,
      onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
    },
    {
      label: 'Created by',
      value: 'created_by',
      options: creators,
      type: 'DROPDOWN' as const,
      onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
    },
  ]
}
