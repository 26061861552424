import React from 'react'
import { AimOutlined } from '@ant-design/icons'
import { Empty } from 'antd'
import { ReactComponent as IconCheckBoxCircleFill } from 'Assets/images/icons/checkboxcirclefill.svg'
import { computeDescription, computeIcon } from './Constants'

const PlacementList = ({ placements, onSelectPlacement, selectedPlacement }) => {
  if (!placements.length) return <Empty></Empty>
  return (
    <div className="PlacementListComponent">
      <h3 className="mt-6 mb-2">Placement</h3>
      <div className="w-full flex gap-5 flex-wrap">
        {placements.map((plm) => (
          <div
            style={{ pointerEvents: selectedPlacement == plm ? 'none' : 'inherit' }}
            key={plm.code}
            onClick={() => onSelectPlacement(plm)}
            className={`placement-card ${selectedPlacement?.code === plm?.code && 'selected'}`}
          >
            {/* {selectedPlacement?.code === plm?.code ? ( */}
            {computeIcon(plm?.code)}

            <div>
              <div className="placement-card__title">{plm.name}</div>
              <span className="text-secondary_text">
                {plm?.description ||
                  'The quickest way to send push notifications to all your users. '}
              </span>
              <div className="flex justify-end">
                <div
                  className="rounded px-2"
                  style={{
                    backgroundColor: '#E6F7FF',
                    color: '#1890FF',
                    width: 'max-content',
                    fontWeight: 600,
                  }}
                >
                  {plm?.name}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PlacementList
