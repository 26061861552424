import React, { useState, useEffect } from 'react'
import { Typography, Skeleton } from 'antd'
import { COLOR_PALLETTE } from 'Utils/constants'
import PieChart from 'Components/Charts/PieChart'
import BarChart from 'Components/Charts/BarCharts'
import { capitalizeFirstLetter } from 'Utils'
import moment from 'moment'
import flatten from 'lodash/flatten'
import { Tooltip } from 'antd'

import './index.scss'

const { Title } = Typography

// const PIE_CHART_CODES = ['total_bills', 'transactions', 'cr']
// const BAR_CHART_CODES = [
//   ['number_of_users', 'clicks', 'total_items'],
//   ['aov', 'revenue'],
//   ['aov', 'revenue'],
// ]

const emptyStates = {
  labels: [],
  pie: [1, 2, 3],
  bar: [1, 2, 3],
}

const Comparisons = ({ selectedRows, visible, compareKeys, analyticsFunction }) => {
  const [states, setStates] = useState(emptyStates)

  let comparedItems = selectedRows

  const handlePrevMonth = async () => {
    let this_month_result = {}
    let last_month_result = {}
    const analytic_key_metrics_codes = flatten([...compareKeys.bar, ...compareKeys.pie])
    // const analytic_key_metrics_codes = ['total_customer', 'total_revenue']
    for (let i in analytic_key_metrics_codes) {
      const last_month_resp = await analyticsFunction[0]({
        id: selectedRows[0].id,
        code: analytic_key_metrics_codes[i],
        type: analyticsFunction[1],
        is_key_metric: true,
        filter: {
          from_date: moment().subtract(2, 'months').format('YYYY-MM-DD'),
          to_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        },
      })
      const this_month_resp = await analyticsFunction[0]({
        id: selectedRows[0].id,
        code: analytic_key_metrics_codes[i],
        type: analyticsFunction[1],
        is_key_metric: true,
        filter: {
          from_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          to_date: moment().format('YYYY-MM-DD'),
        },
      })

      // results[analytic_key_metrics_codes[i]] = resp
      this_month_result[analytic_key_metrics_codes[i]] = last_month_resp
      last_month_result[analytic_key_metrics_codes[i]] = this_month_resp
    }

    comparedItems = [
      {
        name: `${comparedItems[0].name} ( 
          ${moment().subtract(2, 'months').format('DD/MM/YYYY')} -
          ${moment().subtract(1, 'months').format('DD/MM/YYYY')} )`,
        analytic_key_metrics: {
          ...last_month_result,
        },
      },
      {
        name: `${comparedItems[0].name} ( 
            ${moment().subtract(1, 'months').format('DD/MM/YYYY')} -
            ${moment().format('DD/MM/YYYY')} )`,
        analytic_key_metrics: {
          ...this_month_result,
        },
      },
    ]
  }

  const onSetStates = async () => {
    // use for the "compare itself" data
    if (comparedItems.length == 1) {
      await handlePrevMonth(comparedItems)
    }

    setStates({
      labels: comparedItems.map((row) => row.name),
      pie: compareKeys.pie.map((key) => ({
        key,
        value: comparedItems.map((row) => row.analytic_key_metrics[key]),
      })),

      // bar: compareKeys.bar

      bar: compareKeys.bar.map((data) =>
        data.map((key) => ({
          key,
          value: comparedItems.map((row) => row.analytic_key_metrics[key]),
        }))
      ),
    })
  }

  useEffect(() => {
    if (!visible) return setStates(emptyStates)

    onSetStates()
  }, [visible])

  return (
    <div className="ComparisonsComponent">
      <Title className="page-title font-semibold text-3xl my-7 text-primary_text">
        Comparisons
      </Title>
      <div className="legend-container">
        <Title level={5} className="legend-title">
          Legends
        </Title>
        <div className="legend-wrapper">
          {states.labels?.map((item, index) => (
            <Tooltip title={item}>
              <div key={index} className="legend">
                <div className="label" style={{ background: COLOR_PALLETTE[index] }}></div>
                <div className="name">{item}</div>
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
      <div className="charts-wrapper">
        <div className="bar-charts">
          {states.bar.map((chart, index) => (
            <div className="box bar" key={index}>
              <>
                <BarChart
                  type="bar"
                  data={
                    Array.isArray(chart) &&
                    chart.map((code, index) => ({
                      key: capitalizeFirstLetter(code.key),
                      values: code.value,
                    }))
                  }
                />
              </>
            </div>
          ))}
        </div>
        <div className="pie-charts">
          {states.pie.map((chart, index) => (
            <div key={index} className={`box pie pie-${index}`}>
              <>
                {chart.key && <Title level={5}>{capitalizeFirstLetter(chart.key)}</Title>}
                <PieChart
                  hideLegends={true}
                  data={
                    chart.key &&
                    states.labels.map((item, index) => ({
                      key: item,
                      value: chart.value[index],
                    }))
                  }
                />
              </>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Comparisons
