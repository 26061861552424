import React from 'react'

const HarvanLoginFail = () => {
  return (
    <div className="HarvanLoginFailPage">
      Thông tin Haravan Account không xác thực với Harawork. Vui lòng liên hệ Admin!
    </div>
  )
}

export default HarvanLoginFail
