import React, { useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import ChromeIcon from 'Assets/images/chrome_icon.ico'
import './index.scss'
import { brandVars } from 'Utils/brandVars'

const WebPushPreview = ({ data }) => {
  const { url, title, image, body, actions, logo } = data
  const vars = brandVars()
  return (
    <div className="mac_os-preview">
      <div>
        <span>Default</span>
        <div className="default">
          <div className="header">
            <img alt="brand-logo" className="browser" src={ChromeIcon}></img>
            <span>GOOGLE CHROME</span>
            <DownOutlined
              style={{ position: 'absolute', right: 10, transform: 'rotate(-90deg)' }}
            />
          </div>
          <div className="content-wrapper">
            <div className="content">
              <p className="title common-ellipsis">{title}</p>
              <p className="subtitle common-ellipsis">{vars.logo}</p>
              <p className="description common-ellipsis">{body}</p>
            </div>
          </div>
          {/* <div className="option-btn">
            <span>Options</span>
            <DownOutlined
              style={{ position: 'absolute', right: 10, transform: 'rotate(-90deg)', fontSize: 10 }}
            />
          </div> */}
          <img alt="brand-logo" className="image" src={logo || vars.logo}></img>
        </div>
      </div>

      <div>
        <span>Detail</span>
        <div className="detail">
          <div className="header">
            <img alt="brand-logo" className="browser" src={ChromeIcon}></img>
            <span>GOOGLE CHROME</span>
            <DownOutlined style={{ position: 'absolute', right: 10 }} />
          </div>
          <img alt="brand-logo" className="image" src={logo || vars.logo}></img>
          <div className="content-wrapper">
            <div className="content">
              <p className="title common-ellipsis">{title}</p>
              <p className="subtitle common-ellipsis">{vars.url}</p>
              <p className="description common-ellipsis">{body}</p>
            </div>
          </div>
          {actions?.length ? (
            <div className="action-buttons-wrapper">
              {actions?.map((action) => (
                <div key={action.id} className="action-button">
                  {action.title}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default WebPushPreview
