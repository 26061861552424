import React, { useState, useEffect } from 'react'
import { Input, Divider } from 'antd'
import FieldWrapper from '../FieldWrapper'
import { useStoreActions } from 'easy-peasy'
import { DeleteFilled } from '@ant-design/icons'
import { merge, uniqBy } from 'lodash'

import { connect } from 'react-redux'
import DependentSelects from 'Components/DependentSelects'

const Field = ({ options, index, values, id, onChange, onFieldChange, name }) => {
  const currentField = values[name].find((item) => item.id == id)

  const handleChangeSelect = (value) => {
    onFieldChange({ type: 'type', value, id })
  }

  const handleSubOptionChange = ({ type, title, value, name }) => {
    onFieldChange({ type: type, title, value, name, id })
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4>Button {index + 1}</h4>
        <DeleteFilled
          onClick={() => {
            onChange(
              name,
              values[name].filter((select) => select.id !== id)
            )
          }}
        />
      </div>
      <div>
        <h5>Title</h5>
        <Input
          value={currentField?.title}
          onChange={(e) => handleSubOptionChange({ title: e.target.value })}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <DependentSelects
          data={options}
          onOptionChange={handleChangeSelect}
          onSubOptionChange={handleSubOptionChange}
          values={{
            optionSelect: currentField?.type,
            subOptionSelects: currentField?.subtype || currentField?.url,
          }}
          labels={{ optionSelect: 'Type', subOptionSelects: ['Url'] }}
        />
      </div>
      <Divider />
    </div>
  )
}

const DependentSelectsField = (props) => {
  const { name, values, onChange } = props

  const field = values[name] || []
  const onFieldChange = ({ type, id, value, title }) => {
    onChange(
      name,
      uniqBy(
        [
          ...field,
          merge(field.find((select) => select.id == id) || {}, {
            [type]: value,
            title,
          }),
        ],
        'id'
      )
    )
  }

  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <div style={{}}>
          {field?.map(({ id }, index) => (
            <Field key={id} index={index} {...props} id={id} onFieldChange={onFieldChange} />
          ))}
          {field.length < 2 ? (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onChange(name, [...field, { id: field.length, title: 'Button' }])
              }}
            >
              + More action button
            </div>
          ) : null}
        </div>
      )}
    </FieldWrapper>
  )
}

export default DependentSelectsField
