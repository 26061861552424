import React from 'react'

import { ReactComponent as Paper } from '../../Assets/images/icons/paper.svg'
import { ReactComponent as Tags } from '../../Assets/images/icons/tags.svg'
import { ReactComponent as Cup } from '../../Assets/images/icons/cup.svg'
import { ReactComponent as Filter } from '../../Assets/images/icons/filter.svg'
import { ReactComponent as Zalo } from '../../Assets/images/icons/zalo.svg'

interface IconProps {
  children: any
  size?: number
  color?: string
}

interface props {
  size?: number
  color?: string
}

const Icon: React.FC<IconProps> = ({ children, size = 20, color }) => {
  const Component = children
  return (
    <Component
      className="kariba-icon"
      fill="currentColor"
      style={{
        width: size,
        height: size,
        color,
      }}
    />
  )
}

export const PaperIcon: React.FC<props> = (props) => {
  return <Icon {...props}>{Paper}</Icon>
}
export const TagsIcon: React.FC<props> = (props) => {
  return <Icon {...props}>{Tags}</Icon>
}
export const CupIcon: React.FC<props> = (props) => {
  return <Icon {...props}>{Cup}</Icon>
}
export const FilterIcon: React.FC<props> = (props) => {
  return <Icon {...props}>{Filter}</Icon>
}
export const ZaloIcon: React.FC<props> = (props) => {
  return <Icon {...props}>{Zalo}</Icon>
}

// export default {
//   PaperIcon: <Icon>{Paper}</Icon>,
//   TagsIcon: <Icon>{Tags}</Icon>,
//   CupIcon: <Icon>{Cup}</Icon>,
//   FilterIcon: () => <Icon>{Filter}</Icon>,
// }
