import React, { useEffect, useState, useMemo } from 'react'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Empty, Skeleton } from 'antd'
import moment from 'moment'
import axios from 'axios'
import { isEmpty } from 'lodash'
import BarChart from './BarChart.template'
import { BarChartPlaceholder } from 'Components/Placeholders/Charts'
import ReactPlaceholder from 'react-placeholder'
import { MultiDoughnutChartType, FiltersType } from 'AnalyticsTypes'
import useFilters from 'Pages/Analytics/Components/FiltersHeader/useFilters'
import { FormatDataLineChart, DATA_LINE_CHART } from 'Pages/Analytics/SaleAnalytics/Constant'

const BarChartTemplate = ({
  handleDataFunction,
  chartCode,
  barcode,
  isStacked,
  isHorizontal,
  hideLegends,
  max = isHorizontal ? 100 : 'auto',
  isPercentage = max === 100 ? true : false,
}: {
  handleDataFunction: (filters: FiltersType) => Promise<any>
  chartCode: string
  barcode?: string
  isStacked?: boolean
  isHorizontal?: boolean
  max?: number | 'auto'
  hideLegends?: boolean
  isPercentage?: boolean
}) => {
  const history = useHistory()
  const { id } = useParams()
  const { filters: defaultFilters } = useFilters()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [chart, setChart] = useState<
    | {
        code: string
        labels: string[]
        data: MultiDoughnutChartType[]
      }
    | undefined
  >(undefined)
  const [labels, setLabels] = useState<string[] | any>(null)
  const queryObject = queryString.parse(history.location.search)

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()
    const fetchData = async () => {
      setIsLoading(true)
      try {
        setError('')
        // setChart(CHART)
        const resp = await handleDataFunction({
          barcode,
          id,
          from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
          frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
          type: chartCode,
          dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
          cancelTokenSource,
        })

        // let formattedData = resp?.data?.data?.data?.map((item) => {
        //   const isValidDate = moment(item.label, 'MMM DD', true).isValid()

        //   return {
        //     ...item,
        //     label: isValidDate ? moment(item.label, 'MMM DD').format('MMM DD') : item.label,
        //   }
        // })

        // // format horizontal bar chart chart
        // if (isHorizontal && !isStacked && formattedData) {
        //   if (!isEmpty(formattedData[0]?.values)) {
        //     formattedData = Object.keys(formattedData[0].values)?.map((key, index) => ({
        //       label: key,
        //       values: { [key]: formattedData[0].values[key] },
        //     }))
        //   }

        //   if (!isEmpty(formattedData[0]?.value)) {
        //     formattedData = formattedData.map((item, index) => ({
        //       label: item.label,
        //       values: { [item.label]: item.value },
        //     }))
        //   }
        // }

        setChart(resp.data)
        // setLabels(resp?.data?.data?.labels || [])
      } catch (err: any) {
        // console.log(err)
        if (err.message === 'cancelled') return
        const errorMessage: string =
          typeof err?.response?.data?.detail === 'string'
            ? err?.response?.data?.detail
            : 'No data available'
        setError(errorMessage)
      }
      setIsLoading(false)
    }

    fetchData()
    return () => {
      setChart(undefined)
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [
    barcode,
    id,
    queryObject.from_date,
    queryObject.to_date,
    queryObject.frequency,
    queryObject.dimensions,
    chartCode,
    handleDataFunction,
  ])

  const renderChart = useMemo(() => {
    if (!chart) return <Empty />
    const { dataSource, lineLabels } = FormatDataLineChart(chart)
    return <BarChart dataSource={dataSource} lineLabels={lineLabels} />
  }, [chart, labels, hideLegends, isHorizontal, isStacked, max, isPercentage])

  if (error) return <Alert message={error} type="error" />

  return (
    <div className="w-full h-full">
      <Skeleton loading={isLoading} active>
        {renderChart}
      </Skeleton>
    </div>
  )
}

export default BarChartTemplate
