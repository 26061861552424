import axios from 'Utils/axios'
import { CancelTokenSource } from 'axios'

const ENDPOINT = '/audience-segments'

export async function getSegment360KeyMetrics({
  id,
  from_date,
  to_date,
  cancelTokenSource,
}: {
  id?: number
  from_date?: string
  to_date?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`${ENDPOINT}/${id}/analytics/key-metrics`, {
    params: { from_date, to_date },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getSegment360Charts({
  id,
  type,
  frequency,
  from_date,
  to_date,
  cancelTokenSource,
}: {
  id?: number
  type?: string
  frequency?: string
  from_date?: string
  to_date?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`${ENDPOINT}/${id}/analytics/charts`, {
    params: { analytic_type: type, frequency, from_date, to_date },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
