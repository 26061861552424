import React from 'react'
import { useStoreState } from 'easy-peasy'
import { Redirect } from 'react-router-dom'

const CheckPermission = (props) => {
  const { rolesPermission = [], component: Component, ...rest } = props
  const roles = useStoreState((state) => state.auth.roles)
  const isSuperAdmin = useStoreState((state) => state.auth.is_super_admin)

  let isHasPermission = false

  roles.forEach((role) => {
    if (rolesPermission.includes(role)) {
      isHasPermission = true
    }
  })

  if (isSuperAdmin) {
    return <Component {...rest} />
    // } else if (isHasPermission) {
  } else if (true) {
    return <Component {...rest} />
  } else {
    return <Redirect to="/forbidden" />
  }
}

export default CheckPermission
