import React from 'react'
import { Form, ErrorMessage, Field, Formik } from 'formik'
import { Input, Button, Alert, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import logo from 'Assets/images/logo.svg'

const { Title } = Typography

export default React.memo(function ActivateAccount(props) {
  const { handleChange, values, isSubmitting, isValidating, handleSubmit, serverError } = props
  const loadingCondition = isSubmitting || isValidating

  const CustomError = (props) => {
    return <Alert message={props.children} type="error" className="field-error" showIcon />
  }

  return (
    <Form id="activate_form" className="activate-form-wrapper" onSubmit={handleSubmit}>
      <div className="form-logo">
        <img src={logo} alt="form logo" width={150} />
      </div>
      {serverError && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="form-item">
        <Title className="field-title">Email</Title>
        <Field
          as={Input}
          name="email"
          placeholder="Eg. abc@email.com"
          className="email-field"
          size="large"
          prefix={<UserOutlined style={{ marginRight: 5 }} />}
          disabled={loadingCondition}
          value={values.email}
          onChange={handleChange}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="email" />
      </div>
      <Button type="primary" htmlType="submit" className="submit-button" loading={loadingCondition}>
        Submit
      </Button>
    </Form>
  )
})
