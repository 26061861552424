import React from 'react'
import { Form, ErrorMessage, Field } from 'formik'
import { Input, Button, Alert, Typography } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import logo from 'Assets/images/logo.svg'

const { Title } = Typography
const { Password } = Input

export default React.memo(function RegisterView(props) {
  const { handleChange, values, isSubmitting, isValidating, handleSubmit, serverError } = props

  const CustomError = (props) => {
    //
    return <Alert message={props.children} type="error" className="field-error" showIcon />
  }
  const loadingCondition = isSubmitting || isValidating

  return (
    <Form id="register_form" className="register-form-wrapper" onSubmit={handleSubmit}>
      <div className="form-logo">
        <img src={logo} alt="form logo" width={150} />
      </div>
      {serverError &&
        typeof serverError === 'object' &&
        Object.keys(serverError).map((error) => (
          <Alert message={serverError[error]} type="error" className="server-error" showIcon />
        ))}

      {serverError && typeof serverError === 'string' && (
        <Alert message={serverError} type="error" className="server-error" showIcon />
      )}
      <div className="form-item">
        <Title className="field-title">First Name</Title>
        <Field
          as={Input}
          name="first_name"
          placeholder="Eg. John"
          size="large"
          prefix={<UserOutlined style={{ marginRight: 5 }} />}
          disabled={loadingCondition}
          value={values.firstName}
          onChange={handleChange}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="first_name" />
      </div>
      <div className="form-item">
        <Title className="field-title">Last Name</Title>
        <Field
          as={Input}
          name="last_name"
          placeholder="Eg. Henry"
          className="email-field"
          size="large"
          prefix={<UserOutlined style={{ marginRight: 5 }} />}
          disabled={loadingCondition}
          value={values.lastName}
          onChange={handleChange}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="last_name" />
      </div>
      <div className="form-item">
        <Title className="field-title">Email</Title>
        <Field
          as={Input}
          name="email"
          placeholder="Eg. john@sc.com"
          className="email-field"
          size="large"
          prefix={<UserOutlined style={{ marginRight: 5 }} />}
          disabled={loadingCondition}
          value={values.email}
          onChange={handleChange}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="email" />
      </div>
      <div className="form-item">
        <Title className="field-title">Password</Title>
        <Field
          as={Password}
          name="password"
          placeholder="Your password"
          className="password-field"
          size="large"
          prefix={<LockOutlined style={{ marginRight: 5 }} />}
          value={values.password}
          onChange={handleChange}
          disabled={loadingCondition}
        />
        <ErrorMessage component={(props) => <CustomError {...props} />} name="password" />
      </div>
      <a href="/forgot-password">Forgot password?</a>
      <Button type="primary" htmlType="submit" className="submit-button" loading={loadingCondition}>
        Register
      </Button>
      <div style={{ display: 'flex', marginTop: 15 }}>
        <span>Already have a account?&nbsp;</span>
        <a href="/login">Login now</a>
      </div>
    </Form>
  )
})
