import React, { useContext } from 'react'
import { Select } from 'antd'

import { dimensionPopupContext } from '../Dimensions.Popup'

export type props = {
  isEditable: boolean
  index: number
  condition: string
  className?: string
  isDisabled?: boolean
  type?: string
}

const OPTIONS = [
  { value: 'AND', label: 'And' },
  { value: 'OR', label: 'Or' },
]

const SEGMENT_OPTIONS = [
  { value: 'IN', label: 'In' },
  { value: 'NOT IN', label: 'Not in' },
  { value: 'UNION', label: 'Union' },
  { value: 'INTERSECT', label: 'Intersect' },
]

const DimensionInterfaceCondition: React.FC<props> = ({
  condition,
  index,
  isEditable,
  className,
  isDisabled,
  type,
}) => {
  const { dimensionsDraft: dimensions, setDimensionsDraft } = useContext(dimensionPopupContext)
  const onChangeCondition = (val) => {
    for (let i = 1; i < dimensions.length; i++) {
      const newDimension = dimensions[index]
      newDimension.condition = val
      setDimensionsDraft({ type: 'update', payload: { newDimension } })
    }
  }
  const onSegmentConditionChange = (val) => {
    for (let i = 1; i < dimensions.length; i++) {
      if (i === index) {
        const newDimension = dimensions[i]
        newDimension.condition = val
        newDimension.segments[0].condition = val
        setDimensionsDraft({ type: 'update', payload: { newDimension } })
      }
    }
  }

  if (!condition) return null

  if (isEditable)
    return (
      <Select
        disabled={type == 'SEGMENT' ? false : isDisabled}
        value={condition}
        className="dimension-condition-select mr-2"
        onChange={type == 'SEGMENT' ? onSegmentConditionChange : onChangeCondition}
      >
        {(type == 'SEGMENT' ? SEGMENT_OPTIONS : OPTIONS).map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    )

  const option = OPTIONS.find((option) => option.value === condition) || {
    label: condition,
    value: condition,
  }
  return <div className={className}>{option?.label}</div>
}

export default DimensionInterfaceCondition
