import React from 'react'
import CatePivotTemplate from '../../Components/CatePivotTemplate'
const CatePivotRow = () => {
  return (
    <div>
      <CatePivotTemplate></CatePivotTemplate>
    </div>
  )
}

export default CatePivotRow
