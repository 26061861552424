import React from 'react'

import CheckPermission from 'Components/CheckPermission'
import List from './List'
import Form from './Form'

import './index.scss'

const allowRoles = ['owner']

const CreativeContentList = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
}

const CreativeContentForm = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={Form} {...props} />
}

export { CreativeContentList, CreativeContentForm }
