import { thunk } from 'easy-peasy'
import { message } from 'antd'
import axios from 'Utils/axios'
import moment from 'moment'

// todo: REMOVE this file
export default {
  getCampaignDetails: thunk(async (actions, id, { getStoreState, getStoreActions }) => {
    const storeActions = getStoreActions()

    const resp = await axios.get(`/campaigns/${id}`)
    // setting step
    const { name, mkt_obj, daily_budget, bid_amount, kpi, code } = resp.data
    storeActions.campaign.setting.setSettingStates({ key: 'campaignId', value: id })
    storeActions.campaign.setting.setSettingStates({ key: 'campaignCode', value: code })
    storeActions.campaign.setting.setSettingStates({ key: 'name', value: name })
    storeActions.campaign.setting.setSettingStates({ key: 'objective', value: mkt_obj })
    storeActions.campaign.setting.setSettingStates({ key: 'daily_budget', value: daily_budget })
    storeActions.campaign.setting.setSettingStates({ key: 'bid_amount', value: bid_amount })
    storeActions.campaign.setting.setSettingStates({ key: 'kpi', value: kpi })

    // placement step
    // Todo: remove placementIds
    setTimeout(() => {
      const productCC = resp.data.product_creative_content_objs[0]

      if (!productCC) {
        message.error('product_creative_content not found !')
        return
      }

      const {
        placement_id: id,
        campaign_id: campaignId,
        name,
        content,
      } = resp.data.product_creative_content_objs[0]
      storeActions.campaign.placements.addPlacements({
        placementIds: [id],
      })
      storeActions.campaign.placements.setPlacement({ id, campaignId, name, content })
    }, 200)

    // audience step
    resp.data.audience_segment_obj &&
      (await storeActions.campaign.audience.getAudience(resp.data.audience_segment_obj.id))

    //products step
    const campaignProducts = resp.data.product_creative_content_objs
      .filter((obj) => obj.product_id != null)
      .map((obj) => obj.product_id)
    await storeActions.campaign.products.setSelectedProducts(campaignProducts)

    // creative content step
    storeActions.campaign.creativeContents.setState({
      key: 'previewData',
      value: resp.data.product_creative_content_objs[0]?.content || {},
    })
    storeActions.campaign.creativeContents.setState({
      key: 'creativeContentInfo',
      value: resp.data.product_creative_content_objs[0] || null,
    })

    // schedule step
    if (resp.data.schedule_obj) {
      await storeActions.campaign.schedule.setDate({
        date: [
          resp.data.schedule_obj.start_time || moment().format('YYYY-MM-DD HH:mm:ss'),
          resp.data.schedule_obj.end_time || moment().format('YYYY-MM-DD HH:mm:ss'),
        ],
      })
      await storeActions.campaign.schedule.setDaysOfWeek({
        daysOfWeek: resp.data.schedule_obj.days,
      })
      await storeActions.campaign.schedule.setMode({
        mode: resp.data.schedule_obj.mode,
      })
      await storeActions.campaign.schedule.setStartAt({
        start_at: resp.data.schedule_obj.start_at || moment().format('HH:mm:ss'),
      })
    } else {
      await storeActions.campaign.schedule.setIsInit()
    }
  }),
}
