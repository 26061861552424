import axios from 'Utils/axios'

import { Criteria } from 'DimensionsFiltersTypes'
import { CancelTokenSource } from 'axios'
//const ENDPOINT = '/customer-profiles'

// Sale Channel Overview

export async function getCategoryList({
  category_id,
  cancelTokenSource,
}: {
  category_id?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/sale-channels/overview/categories?company=kingfood`, {
    params: { category_id },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getPivotCateList({
  category_id,
  keyword,
  cancelTokenSource,
}: {
  category_id?: string
  keyword?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/sale-channels/overview/products`, {
    params: { category_id, keyword },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getSaleChannelOverviewKeyMetrics({
  from_date,
  to_date,
  dimensions = [],
  cancelTokenSource,
}: {
  from_date?: string
  to_date?: string
  dimensions?: Criteria[]
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/sale-channels/overview/analytics/key-metrics`, {
    params: { from_date, to_date, dimensions: JSON.stringify(dimensions) },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getSaleChannelOverviewCharts({
  from_date,
  to_date,
  type,
  dimensions = [],
  frequency = 'daily',
  cancelTokenSource,
}: {
  from_date?: string
  to_date?: string
  type?: string
  dimensions?: Criteria[]
  frequency?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/sale-channels/overview/analytics/charts`, {
    params: {
      from_date,
      to_date,
      analytic_type: type,
      frequency,
      dimensions: JSON.stringify(dimensions),
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

// Store Listing

export async function getTreeCate({
  keyword,
  cancelTokenSource,
}: {
  keyword?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/sale-channels/stores/categories`, {
    params: { keyword },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

// Store360

export async function getStore360KeyMetrics({
  store_id,
  from_date,
  to_date,
  dimensions = [],
  cancelTokenSource,
}: {
  store_id?: string
  from_date?: string
  to_date?: string
  dimensions?: Criteria[]
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/sale-channels/store360/analytics/key-metrics`, {
    params: { store_id, from_date, to_date, dimensions: JSON.stringify(dimensions) },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getStore360DetailInfos({
  store_id,
  from_date,
  to_date,
  dimensions = [],
  cancelTokenSource,
}: {
  store_id?: string
  from_date?: string
  to_date?: string
  dimensions?: Criteria[]
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/sale-channels/store360/analytics/infos`, {
    params: { store_id, from_date, to_date, dimensions: JSON.stringify(dimensions) },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getStore360Charts({
  store_id,
  from_date,
  to_date,
  type,
  dimensions = [],
  frequency = 'daily',
  cancelTokenSource,
}: {
  store_id?: string
  from_date?: string
  to_date?: string
  type?: string
  dimensions?: Criteria[]
  frequency?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/sale-channels/store360/analytics/charts`, {
    params: {
      store_id,
      from_date,
      to_date,
      analytic_type: type,
      frequency,
      dimensions: JSON.stringify(dimensions),
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

// export async function getBrandFilterList() {
//   const resp = await axios.get('/products/analytics/filters')
//   if (!resp.data) {
//     throw resp
//   }

//   return { data: resp.data }
// }

// export async function getCategoryList() {
//   const resp = await axios.get('/products/analytics/categories?company=kingfood')
//   if (!resp.data) {
//     throw resp
//   }

//   return { data: resp.data }
// }

// export async function getProduct360Test({
//   id,
//   type,
//   frequency,
//   cancelTokenSource,
// }: {
//   id?: number
//   type?: string
//   frequency?: string
//   cancelTokenSource?: CancelTokenSource
// }) {
//   const resp = await axios.get(`${ENDPOINT}/124198/analytics/charts`, {
//     params: { analytic_type: type, frequency },
//     cancelToken: cancelTokenSource?.token,
//   })

//   // error handling
//   if (!resp.data) {
//     throw resp
//   }

//   return { data: resp.data }
// }

export async function getProduct360KeyMetrics({
  from_date,
  to_date,
  dimensions = [],
  cancelTokenSource,
}: {
  from_date?: string
  to_date?: string
  dimensions?: Criteria[]
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/product360/analytics/key-metrics`, {
    params: { from_date, to_date, dimensions: JSON.stringify(dimensions) },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProductDetailList(
  category_id?: any,
  page?: number,
  per_page?: number,
  limit?: number,
  brands?: any,
  suppliers?: any,
  from_date?: any,
  to_date?: any,
  keyword?: string,
  order_by?: string,
  cancelTokenSource?: CancelTokenSource
) {
  const resp = await axios.get(`/products/analytics`, {
    params: {
      category_id,
      page,
      per_page,
      limit,
      brands,
      suppliers,
      from_date,
      to_date,
      keyword,
      order_by,
    },
    cancelToken: cancelTokenSource?.token,
  })
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360DetailInfos(barcode) {
  const resp = await axios.get(`/sale-channels/store360/analytics/infos?barcode=${barcode}`)
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360Metrics(barcode) {
  const resp = await axios.get(`/products/product360/analytics/key-metrics?barcode=${barcode}`)
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360Charts({
  barcode,
  type,
  cancelTokenSource,
}: {
  barcode?: string
  type?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/product360/analytics/charts`, {
    params: {
      barcode,
      analytic_type: type,
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360FunnelData({
  barcode,
  cancelTokenSource,
}: {
  barcode?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/product360/analytics/funnels`, {
    params: {
      barcode,
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360ProductsList({
  barcode,
  id,
  type,
  cancelTokenSource,
}: {
  barcode?: string
  id?: number
  type?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/product360/analytics/recommends`, {
    params: { analytic_type: type, barcode: barcode },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
