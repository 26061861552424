import React from 'react'
import { action, thunk, Action, createStore } from 'easy-peasy'
import moment from 'moment'
import axios from 'Utils/axios'
import { message } from 'antd'
import { DEFAULT_NODE } from 'Containers/CustomerJourneyDesign/constants'
import _, { isNull } from 'lodash'
import { HarasocialModel } from '../index.types'

const initStates = {
  harasocialApp: null,
  harasocialToken: null,
  harasocialActions: [],
  currentCustomerId: null,
}

const store = {
  ...initStates,

  setHarasocialToken: action((state, token) => {
    state.harasocialToken = token
  }),
  setCurrentCustomerId: action((state, id) => {
    state.currentCustomerId = id
  }),
  initHarasocialApp: action((state, app) => {
    state.harasocialApp = app
  }),
  initHarasocialAction: action((state, actions) => {
    state.harasocialActions = actions
  }),
}

export default store
