import { capitalizeFirstLetter } from 'Utils'

const DoughnutTextInside = (chart) => {
  if (chart?.config?.options?.elements?.center) {
    // Get ctx from string
    let ctx = chart.ctx

    // Get options from the center object in options
    let centerConfig = chart.config.options.elements.center
    let fontStyle = centerConfig.fontStyle || 'Arial'
    let txt = centerConfig.text
    let color = centerConfig.color || '#000'
    let maxFontSize = centerConfig.maxFontSize || 75
    let sidePadding = centerConfig.sidePadding || 20
    const innerRadius = chart._metasets[chart._metasets.length - 1].data[0]?.innerRadius || 0
    let sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2)

    // Start with a base font of 30px
    ctx.font = '14 ' + fontStyle

    // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
    let stringWidth = ctx.measureText(txt).width
    let elementWidth = innerRadius * 2 - sidePaddingCalculated

    // Find out how much the font can grow in width.
    let widthRatio = elementWidth / stringWidth
    let newFontSize = Math.floor(30 * widthRatio)
    let elementHeight = innerRadius * 2

    // Pick a new font size so it will not be larger than the height of label.
    let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize)
    let minFontSize = centerConfig.minFontSize
    let lineHeight = centerConfig.lineHeight || 25
    let wrapText = true

    if (minFontSize === undefined) {
      minFontSize = 14
    }

    if (minFontSize && fontSizeToUse < minFontSize) {
      fontSizeToUse = minFontSize
      wrapText = true
    }

    // Set font settings to draw it correctly.
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    let centerX = (chart.chartArea.left + chart.chartArea.right) / 2
    let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
    // ctx.font = fontSizeToUse + 'px ' + fontStyle // todo: fix later
    ctx.font = 14 + 'px ' + fontStyle
    ctx.fillStyle = color

    if (!wrapText) {
      ctx.fillText(txt, centerX, centerY)
      return
    }

    let words = txt.split(' ')
    words[1] = words[1] ? capitalizeFirstLetter(words[1]) : ''

    let line = ''
    let lines = [] as string[]

    // Break words up into multiple lines if necessary
    // for (let n = 0; n < words.length; n++) {
    //   let testLine = line + words[n] + ' '
    //   let metrics = ctx.measureText(testLine)
    //   let testWidth = metrics.width
    //   if (testWidth > elementWidth && n > 0) {
    //     lines.push(line)
    //     line = words[n] + ' '
    //   } else {
    //     line = testLine
    //   }
    // }

    for (let n = 0; n < words.length; n++) {
      if (n === 0) ctx.font = `22px ${fontStyle}`
      else ctx.font = 12 + 'px ' + fontStyle
      ctx.fillText(words[n], centerX, centerY)

      centerY += lineHeight
    }
    //Draw text in center
    ctx.fillText(line, centerX, centerY)
  }
}

export default DoughnutTextInside
