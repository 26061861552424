import React, { useState } from 'react'
import { Divider, Modal, Table, List, Typography } from 'antd'
import ItemCampaigns from './ItemCampaigns'

const InUsedCampaign = ({ used_in_detail }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = (used_in_detail) => {
    if (!used_in_detail?.active_campaign?.length && !used_in_detail?.pass_campaign?.length) {
      return false
    } else {
      setIsModalVisible(true)
    }
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const columnCampaignActive = [
    {
      title: 'Campaign Active',
      dataIndex: 'name',
      key: 'campaign_active',
      render: (record) => {
        return <span>{record}</span>
      },
    },
  ]

  const columnCampaignPast = [
    {
      title: 'Campaign Past',
      dataIndex: 'name',
      key: 'campaign_past',
      render: (record) => <span>{record}</span>,
    },
  ]

  return (
    <>
      <a
        className="text-brand_primary font-semibold flex flex-col my-2"
        onClick={() => showModal(used_in_detail)}
      >
        <span
          className={`${
            !used_in_detail?.active_campaign?.length ? 'text-disabled_text pointer-events-none' : ''
          }`}
        >
          {used_in_detail?.active_campaign?.length || 0} active campaigns
        </span>
        <span
          className={`${
            !used_in_detail?.pass_campaign?.length ? 'text-disabled_text pointer-events-none' : ''
          }`}
        >
          {used_in_detail?.pass_campaign?.length || 0} past campaigns
        </span>
      </a>

      {isModalVisible && (
        <Modal title="Campaigns" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <ItemCampaigns
            title="Campaign Active"
            data={used_in_detail.active_campaign}
          ></ItemCampaigns>
          <ItemCampaigns title="Campaign Past" data={used_in_detail.pass_campaign}></ItemCampaigns>
        </Modal>
      )}
    </>
  )
}

export default InUsedCampaign
