import axios from 'axios'
import { toCamel } from 'Utils'
const API_HOST = process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_VERSION

const defaultOption = { '401Redirect': true, '401ClearStorage': true, response: { toCamel: false } }

export const createAxios = (option = {}) => {
  const opt = { ...defaultOption, ...option }
  const instance = axios.create({
    baseURL: API_HOST,
    withCredentials: false,
  })

  instance.CancelToken = axios.CancelToken

  const requestInterceptor = (config) => {
    let token = localStorage.getItem('Authorization')
    let configHeaders = config.headers
    config.headers = {
      ...configHeaders,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      Authorization: token,
    }
    return config
  }
  const requestInterceptorError = (error) => {
    return error
  }

  const responseInterceptor = (response) => {
    if (opt.response.toCamel) {
      let data = toCamel(response.data)
      response.data = data
      return response
    }
    return response
  }

  const responseInterceptorError = (error) => {
    /* eslint-disable no-console */

    /* eslint-disable no-console */
    if (error.response && error.response.status === 401) {
      if (opt['401ClearStorage']) {
        // localStorage.clear()
        // sessionStorage.clear()
        console.clear()
      }
      if (opt['401Redirect']) window.location = '/login'
    } else if (error.response && error.response.status === 403) {
      window.location = '/forbidden'
    }
    return Promise.reject(error)
  }

  instance.interceptors.request.use(requestInterceptor, requestInterceptorError)

  instance.interceptors.response.use(responseInterceptor, responseInterceptorError)

  return instance
}

let instance = createAxios()

export default instance
