const divideInset = {
  div_t: 'inset 0px 1px 0px rgba(0, 0, 0, 0.06)',
  div_r: 'inset -1px 0px 0px rgba(0, 0, 0, 0.06)',
  div_b: 'inset 0px -1px 0px rgba(0, 0, 0, 0.06)',
  div_l: 'inset 1px 0px 0px rgba(0, 0, 0, 0.06)',
}

module.exports = {
  bot_1: '0px 2px 8px rgba(0, 0, 0, 0.12)',
  bot_2: '0px 4px 16px rgba(0, 0, 0, 0.12)',
  bot_3: '0px 8px 36px rgba(0, 0, 0, 0.16)',
  bot_4: '0px 16px 48px rgba(0, 0, 0, 0.24)',
  top_1: '0px -2px 8px rgba(0, 0, 0, 0.12)',
  top_2: '0px -4px 16px rgba(0, 0, 0, 0.12)',
  top_3: '0px -8px 36px rgba(0, 0, 0, 0.16)',
  top_4: '0px -16px 48px rgba(0, 0, 0, 0.24)',
  none: 'unset',
  div_t: divideInset.div_t,
  div_r: divideInset.div_r,
  div_b: divideInset.div_b,
  div_l: divideInset.div_l,
  div_tr: `${divideInset.div_t}, ${divideInset.div_r}`,
  div_tb: `${divideInset.div_t}, ${divideInset.div_b}`,
  div_tl: `${divideInset.div_t}, ${divideInset.div_l}`,
  div_rb: `${divideInset.div_r}, ${divideInset.div_b}`,
  div_rl: `${divideInset.div_r}, ${divideInset.div_l}`,
  div_bt: `${divideInset.div_b}, ${divideInset.div_t}`,
}
