import React from 'react'
import { LinkType } from 'CustomerPortraitTypes'
import axios from 'Utils/axios'
import { message, Skeleton } from 'antd'

const LOGO = 'https://file.hstatic.net/1000003969/file/logo-svg.svg'

type props = {
  url: string
  className?: string
}

let timeoutId

export const LinkItem: React.FC<props> = ({ url, className = '' }) => {
  const [metadata, setMetadata] = React.useState<any>({})
  const [loading, setLoading] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(true)

  React.useEffect(() => {
    setIsUpdating(true)
    setLoading(true)
    timeoutId = setTimeout(() => {
      setIsUpdating(false)
      return () => clearTimeout(timeoutId)
    }, 1200)
  }, [url])

  React.useEffect(() => {
    ;(async () => {
      if (isUpdating) return
      setLoading(true)
      try {
        const resp = await axios.get(`/utils/page-metadata-parser?url=${url}`)
        setMetadata(resp.data)
      } catch (error) {
        message.error('Can not fetch metadata from url!')
      }
      setLoading(false)
    })()
  }, [isUpdating])

  if (loading)
    return <Skeleton.Input className="mt-1" style={{ width: 300, height: 56 }}></Skeleton.Input>
  return (
    <div className={`LinkItemComponent overflow-hidden ${className}`}>
      <img src={metadata?.image} alt="link" />
      <div className="pt-1 flex flex-col">
        <span className="font-semibold line-clamp-2">{metadata?.title}</span>
        <div className="flex flex-wrap items-center">
          <img className="logo mr-1" src={LOGO} alt="logo" />
          <span className="text-xs pr-2 overflow-hidden line-clamp-1">{metadata?.url}</span>
        </div>
      </div>
    </div>
  )
}

export default LinkItem
