import { action } from 'easy-peasy'

const initState = {
  items: [],
}

export default {
  ...initState,
  setBreadCrumb: action((state, items) => {
    state.items = items
  }),
}
