import React, { useMemo } from 'react'
import SaleChannelPivotTable from 'Components/Charts/SaleChannelPivotTable'

const SaleChannelPivotTableTemplate = ({
  analytic_type,
  cate_id,
  isStore,
  location,
  store_id,
}: {
  analytic_type?: string
  cate_id?: string
  isStore?: boolean
  location?: string
  store_id?: string
}) => {
  const renderPivotTable = useMemo(() => {
    return (
      <>
        <SaleChannelPivotTable
          isStore={isStore}
          analytic_type={analytic_type}
          cate_id={cate_id}
          className="w-full"
          location={location}
          store_id={store_id}
        />
      </>
    )
  }, [cate_id])

  return renderPivotTable
}

export default SaleChannelPivotTableTemplate
