import * as yup from 'yup'

import { loginSchema, forgotPasswordSchema, resetPassword, registerSchema } from './userWorkFlow'
import {
  campaignFacebookSchema,
  campaignAudienceSchema,
  campaignFBAudienceSchema,
  campaignCommunitySchema,
} from './campaignWorkflow'
import { fbPostSchema, fbClickLinksSchema, webPushSchema, kaipassSchema } from './CreativeContent'

const actionTriggerSchema = yup.object().shape({
  name: yup.string().required('name is required'),
  type: yup.string().required('type is required'),
  channel: yup.string().required('channel is required'),
})

export {
  registerSchema,
  loginSchema,
  forgotPasswordSchema,
  resetPassword,
  campaignFacebookSchema,
  campaignAudienceSchema,
  campaignFBAudienceSchema,
  fbPostSchema,
  fbClickLinksSchema,
  actionTriggerSchema,
  webPushSchema,
  kaipassSchema,
  campaignCommunitySchema,
}
