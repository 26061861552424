import React from 'react'
import { PlayFill } from '@styled-icons/bootstrap/PlayFill'
import { Pause } from '@styled-icons/boxicons-regular/Pause'
import { Stop } from '@styled-icons/boxicons-regular/Stop'
import { Publish } from '@styled-icons/material/Publish'

export const computeActions = (status) => {
  switch (status) {
    case 'init':
      return ['publish']
    case 'running':
      return ['pause', 'stop']
    case 'waiting':
      return ['start']
    case 'stopped':
      return ['start']
    case 'paused':
      return ['start']
    default:
      return false
  }
}

export const actionIcons = {
  start: <PlayFill />,
  pause: <Pause />,
  stop: <Stop />,
  publish: <Publish />,
}

export const computeTagColor = (tag) => {
  switch (tag) {
    case 'waiting':
      return 'yellow'
    case 'init':
      return 'light-gray'
    case 'paused':
      return 'brown'
    case 'completed':
      return 'blue'
    case 'running':
      return 'green'
    default:
      return 'gray'
  }
}

export const computeActionColor = (action) => {
  switch (action) {
    case 'publish':
      return 'yellow'
    case 'pause':
      return 'brown'
    case 'stop':
      return 'red'
    case 'start':
      return 'green'
    default:
      return 'gray'
  }
}
