export const linkClicks = [
  {
    name: 'image_url',
    type: 'image',
    label: 'Image URL',
    isRequired: true,
  },
  {
    name: 'primary_text',
    type: 'text_area',
    label: 'Primary Text',
    isRequired: true,
  },
  {
    name: 'headline',
    type: 'string',
    label: 'Headline (Optional)',
    isRequired: false,
  },
  {
    name: 'description',
    type: 'string',
    label: 'Link Description (Optional)',
    isRequired: false,
  },
  {
    name: 'call_to_action',
    type: 'select',
    label: 'Call to Action',
    options: [
      {
        code: 'NO_BUTTON',
        name: 'No Button',
      },
      {
        code: 'SHOP_NOW',
        name: 'Shop Now',
      },
      {
        code: 'CONTACT_US',
        name: 'Contact Us',
      },
      {
        code: 'LEARN_MORE',
        name: 'Learn More',
      },
    ],
    isRequired: false,
  },
  {
    name: 'link_to_page',
    type: 'string',
    label: 'Link to Page',
    isRequired: true,
  },
  {
    name: 'utm_source',
    type: 'string',
    label: 'UTM Source',
    isRequired: true,
  },
  {
    name: 'utm_medium',
    type: 'string',
    label: 'UTM Medium',
    isRequired: true,
  },
  {
    name: 'utm_campaign',
    type: 'string',
    label: 'UTM Campaign',
    isRequired: true,
  },
]

export default linkClicks
