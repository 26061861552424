import React, { useState, useEffect, useMemo } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import Default from './Default'
import CustomSQL from './CustomSQL'
import Template from './Template'
import Header from './Header'
import NewSegmentNav from 'Components/AudienceSelect/NewSegmentNavigation'
import { getUUId } from 'Utils'

const NewSegment = () => {
  const history = useHistory()

  const queryObject = queryString.parse(history.location.search)
  const selectedScreen = queryObject?.screen

  const { setBreadCrumb } = useStoreActions((actions: any) => actions.breadcrumb)

  const onSelectScreen = (value) => {
    history.push({ search: `?screen=${value}` })
  }

  const renderScreen = useMemo(() => {
    switch (selectedScreen) {
      case 'BLANK':
        return (
          <div className="m-auto">
            <Default onSelectScreen={onSelectScreen} />
          </div>
        )
      case 'TEMPLATE':
        return (
          <div className="shadow-bot_2 m-auto">
            <Template onSelectScreen={onSelectScreen} />
          </div>
        )
      case 'CUSTOM_SQL':
        return (
          <div className="m-auto">
            <CustomSQL onSelectScreen={onSelectScreen} />
          </div>
        )
      default:
        return (
          <div className="m-8">
            <NewSegmentNav onSelectScreen={onSelectScreen} />
          </div>
          // <CustomSQL />
        )
    }
  }, [selectedScreen])

  useEffect(() => {
    setBreadCrumb([
      { title: 'Customer Segment List', path: `/customers/segments` },
      { title: 'New', path: '/customers/segments/new' },
    ])
  }, [setBreadCrumb])

  return (
    <div className="w-full">
      {selectedScreen !== 'TEMPLATE' && <Header isNew />}
      {renderScreen}
    </div>
  )
}

export default NewSegment
