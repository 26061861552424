export const SS_TABS = [
  {
    roles: ['all'],
    label: 'Links',
    key: 'links',
    breadCrumbs: [{ title: 'Social Selling', path: '/social-selling' }],
  },
  {
    roles: ['all'],
    label: 'Personal Performance',
    key: 'performance',
    breadCrumbs: [{ title: 'Social Selling', path: '/social-selling' }],
  },
  {
    roles: ['admin', 'asm', 'sm', 'rd'],
    label: 'Dashboard',
    key: 'dashboard',
    breadCrumbs: [{ title: 'Social Selling', path: '/social-selling' }],
  },
]
