import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import menu from './Menu.constants'
import MenuList from './MenuList'
import bigLogo from 'Assets/images/kariba_logo.svg'
import classNames from 'classnames'

const LeftSiderMenu = ({ menuCollapse, setMenuCollapse }) => {
  const location = useLocation()
  const [selectedKey, setSelectedKey] = useState([])
  const [openKeys, setOpenKeys] = useState(menuCollapse ? [] : [menu[0].id, menu[1].id, menu[2].id])

  useEffect(() => {
    menu.map((item) => {
      if (location.pathname === item.route) return setSelectedKey([item.id])

      item.subMenus.map((subItem) => {
        if (location.pathname.includes(subItem.route)) return setSelectedKey([subItem.id])
      })
    })
  }, [location.pathname])

  const handleMenuClick = (menu) => {
    const mainPath = location.pathname.split('/')[1]
    // if (menu.key === mainPath) setSelectedKey(menu.key)
    // else if (menu.keyPath.length > 1 && menu.keyPath[0] === mainPath) setOpenKeys([menu.keyPath[0]])
  }

  return (
    <div className={classNames('left-side-menu', { collapsed: menuCollapse })}>
      <div className="main-logo-wrapper">
        <Link className="logo" to="/">
          {<img src={bigLogo} alt="MCM logo" />}
        </Link>
        {menuCollapse ? (
          <MenuUnfoldOutlined size="22" className="trigger" onClick={() => setMenuCollapse()} />
        ) : (
          <MenuFoldOutlined size="22" className="trigger" onClick={() => setMenuCollapse()} />
        )}
      </div>
      <MenuList
        className="menu-wrapper"
        openKeys={openKeys}
        selectedKeys={selectedKey}
        menuCollapse={menuCollapse}
        onClick={handleMenuClick}
        handleOpenChange={(keys) => {
          setOpenKeys(keys)
        }}
      ></MenuList>
    </div>
  )
}

export default LeftSiderMenu
