import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Skeleton, message } from 'antd'
import { Formik } from 'formik'
import * as yup from 'yup'

import axios from 'Utils/axios'
import { formatErrors } from 'Utils'
import ActionTriggerView from './ActionTrigger.view'
import { actionTriggerSchema } from 'Validation'
import './index.scss'

const welcomePushSchema = [
  { name: 'image_url', type: 'image', label: 'Image', isRequired: true },
  { name: 'url', type: 'string', label: 'Landing Page', isRequired: true },
  { name: 'title', type: 'string', label: 'Title', isRequired: true },
  { name: 'description', type: 'text_area', label: 'Description', isRequired: true },
]

export const welcomePushValidation = yup.object().shape({})

const ActionTrigger = (props) => {
  const {
    getActionTrigger,
    setActionTriggerStatus,
    actionTriggerStates,
    createActionTrigger,
    updateActionTrigger,
    previewData,
  } = props

  const [isStatusLoading, setIsStatusLoading] = useState(false)
  const [id, setId] = useState()

  useEffect(() => {
    handleGetActionTrigger()
  }, [])

  const handleGetActionTrigger = async () => {
    const resp = await getActionTrigger()
    if (resp.id) setId(resp.id)
  }

  const handleToggleActivate = async () => {
    setIsStatusLoading(true)
    try {
      await setActionTriggerStatus({
        id,
        action: actionTriggerStates.status === 'inactive' ? 'active' : 'deactive',
      })

      return message.success('Status updated')
    } catch (err) {
      return message.error(formatErrors(err.errors))
    } finally {
      setIsStatusLoading(false)
    }
  }

  const handleSubmit = async (values, abc) => {
    const welcomePushElement = document.querySelector('#welcompush_preview_template')
    welcomePushElement.setAttribute('onclick', `location.href='${previewData.url}'`)
    const htmlTemplate = welcomePushElement.outerHTML
    try {
      const resp = id
        ? await updateActionTrigger({ htmlTemplate })
        : await createActionTrigger({ htmlTemplate })
      if (resp.ok) {
        setId(resp.id)
        return message.success(`Trigger action has been ${resp.ok}`)
      }
    } catch (err) {
      return message.error(formatErrors(err.errors))
    }
  }

  return (
    <div className="ActionTriggerComponent bg-white">
      <Skeleton paragraph={{ rows: 10 }} active loading={actionTriggerStates.isLoading}>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={actionTriggerSchema}
          initialValues={actionTriggerStates}
          enableReinitialize
          children={(formProps) => (
            <ActionTriggerView
              {...formProps}
              {...props}
              welcomePushSchema={welcomePushSchema}
              handleToggleActivate={handleToggleActivate}
              isStatusLoading={isStatusLoading}
              welcomePushValidation={welcomePushValidation}
            />
          )}
        ></Formik>
      </Skeleton>
    </div>
  )
}

export default connect(
  (state) => ({
    actionTriggerStates: state.actionTrigger,
    previewData: state.campaign.creativeContents.previewData,
  }),
  (dispatch) => ({
    getActionTrigger: dispatch.actionTrigger.getActionTrigger,
    createActionTrigger: dispatch.actionTrigger.createActionTrigger,
    updateActionTrigger: dispatch.actionTrigger.updateActionTrigger,
    setActionTriggerStates: dispatch.actionTrigger.setActionTriggerStates,
    setActionTriggerStatus: dispatch.actionTrigger.setActionTriggerStatus,
  })
)(ActionTrigger)
