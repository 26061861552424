import { action, Action } from 'easy-peasy'
import {
  CampaignRequestPayload,
  CampaignStatus,
  CampaignScheduleMode,
  Channel,
  CampaignPlacement,
} from 'CampaignTypes'

export interface States extends CampaignRequestPayload<{}> {
  current_step: number
  channels: Channel[]
  placements: CampaignPlacement[]
  selected_channel: Channel | undefined
}

export interface StoreProps extends States {
  setState: Action<StoreProps, { key: string; value: string | string[] }>
  setContent: Action<StoreProps, { key: string; value: string | string[] }>
  clearContent: Action<StoreProps, { key: string; value: string | string[] }>
  resetState: Action<StoreProps, null>
}

const status: CampaignStatus = 'active'
const mode: CampaignScheduleMode = 'oneoff'
const initState = {
  id: undefined,
  name: '',
  description: '',
  status,
  campaign_code: '',
  selected_placement: undefined,
  audience_id: undefined,
  start_date: '',
  start_time: '',
  end_date: undefined,
  end_time: undefined,
  days: [],
  repeat_every_weeks: undefined,
  mode,

  content: {},

  isContentValidated: true,
  current_step: 0,
  channels: [],
  placements: [],
  selected_channel: undefined,
  //campaign_tag_ids: [],
  campaign_tag_objs: [],
}

const store: StoreProps = {
  ...initState,

  setState: action((state, { key, value }) => {
    state[key] = value
  }),
  clearContent: action((state, payload) => {
    state.content = {}
  }),
  setContent: action((state, { key, value }) => {
    state.content = { ...state.content, [key]: value }
  }),

  resetState: action((state) => ({ ...initState })),
}

export default store
