import React from 'react'
import { Route, Link } from 'react-router-dom'
import { Layout } from 'antd'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import contentImage from 'Assets/images/background2.png'
import backgroundImage from 'Assets/images/background.svg'

import './index.scss'

const { Content, Footer } = Layout

const AuthenticationLayout = (props) => {
  return (
    <Layout id="login_layout">
      <img src={backgroundImage} alt="background" className="layout-background-image" />
      <Content className="login-layout-content">
        <div className="authentication-layout-wrapper">
          <div className="left-content">
            <img src={contentImage} alt="background" className="layout-content-image" />
          </div>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SIDE_KEY}>
            <div className="right-content">{props.children}</div>
          </GoogleReCaptchaProvider>
        </div>
      </Content>
      <Footer className="f" style={{ textAlign: 'center' }}>
        <div>MCM ©2021 Powered by Seedcom</div>
        <Link className="text-sm" to="/policies">
          Privacy Policy
        </Link>
      </Footer>
    </Layout>
  )
}

const AuthenticationRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthenticationLayout>
          <Component {...props} />
        </AuthenticationLayout>
      )}
    />
  )
}

export { AuthenticationLayout, AuthenticationRoute }
