import React, { useState, useEffect } from 'react'
import { Input, Button, message } from 'antd'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useParams, useHistory } from 'react-router-dom'

import { formatErrors } from 'Utils'
import {
  setSegmentData,
  formatCitiesForAPIs,
  formatInterestsForAPIs,
  formatValueType,
} from '../helpers'
import { updateAudienceSegment, createAudienceSegment } from 'Services/customer-segments'
import { KaribaRequestBody, FacebookRequestBody, FacebookDimensions } from 'CustomerSegmentTypes'
import { States } from 'Models/customer-segments'
import HeaderView from './Header.view'

const Header = ({
  isNew = false,
  showButtons = ['save', 'publish'],
  actionCallback,
}: {
  isNew?: boolean
  actionCallback?: () => void
  showButtons?: ('save' | 'publish')[]
}) => {
  const { id }: { id: number } = useParams()
  const history = useHistory()
  const {
    name,
    source,
    criteria_groups,
    predefined_segment,
    predictive_segment,
    store_ids,
    description,
    age_min,
    age_max,
    cities,
    interests,
    genders,
    custom_sql,
  } = useStoreState((state: { customerSegments: States }) => state.customerSegments)
  const { segment_temporary_id } = useStoreState((state: any) => state.customerSegments)
  const { setState } = useStoreActions((action: any) => action.customerSegments)

  const [errors, setErrors] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const isCustomerSegmentPage = history.location.pathname.includes('/customers/segments/')
  const critGroupsContainAudience = criteria_groups?.find(
    (item) =>
      item?.dimension?.segments[0]?.audience_Type === 'audience' ||
      item?.dimension?.segments[0]?.audience_Type === 'customer' ||
      item?.dimension?.segments[0]?.audience_Type === 'visitor'
  )

  const onRedirect = () => {
    if (isCustomerSegmentPage) {
      history.push('/customers/segments')
      return
    }

    actionCallback && actionCallback()
  }

  let body: KaribaRequestBody | FacebookRequestBody
  const temp_criteria_groups = criteria_groups.map((group) => {
    return {
      condition: group.condition,
      dimension: {
        condition: group.dimension.condition,
        segments: group.dimension.segments.map((segment) => {
          return {
            ...segment,
            time_operator: segment?.time_operator?.name || segment?.time_operator,
            time_value: formatValueType(segment.time_value),
          }
        }),
      },
    }
  })

  if (source === 'kariba') {
    body = {
      name,
      audience_type: source,
      dimensions: criteria_groups[0]?.dimension?.segments.length
        ? JSON.stringify(temp_criteria_groups)
        : !predictive_segment && !predefined_segment
        ? '[]'
        : null,
      predictive_segment: predictive_segment || null,
      store_ids: JSON.stringify(store_ids),
      description,
      predefined_segment: predefined_segment || null,
      custom_sql: '',
      segmentation_type: critGroupsContainAudience?.dimension?.segments[0]?.audience_Type || null,
      //segment_temporary_id: segment_temporary_id.split('_')[0] + '_' + Date.now() || '',
    }
  }

  if (source === 'facebook') {
    const fbDimensions: FacebookDimensions = {
      age_min,
      age_max,
      genders,
      geo_locations: { cities: formatCitiesForAPIs(cities) },
      interests: formatInterestsForAPIs(interests),
      internal_data: { cities, interests },
    }
    body = {
      name,
      audience_type: source,
      description,
      dimensions: JSON.stringify(fbDimensions),
      store_ids: JSON.stringify(store_ids),
    }
  }

  if (
    custom_sql &&
    !criteria_groups[0]?.dimension?.segments.length &&
    !predictive_segment &&
    !predefined_segment
  ) {
    body = {
      name,
      audience_type: source,
      description,
      custom_sql: custom_sql.replace(/\n/g, ' '),
      predictive_segment: null,
      predefined_segment: null,
    }
  }

  const handleCreateCustomerSegment = async () => {
    setIsSubmitting(true)
    try {
      const resp = await createAudienceSegment({ body })
      setSegmentData(resp.data, setState)
      message.success('Created customer segment successfully')
      onRedirect()
    } catch (err: any) {
      setErrors(formatErrors(err.response?.data) || 'Something went wrong')
    }
    setIsSubmitting(false)
  }

  const handleUpdateCustomerSegment = async () => {
    setIsSubmitting(true)
    try {
      const resp = await updateAudienceSegment({ id, body, action: 'save' })
      setSegmentData(resp.data, setState)
      message.success('Updated customer segment successfully')
      onRedirect()
    } catch (err: any) {
      setErrors(formatErrors(err.response?.data) || 'Something went wrong')
    }
    setIsSubmitting(false)
  }

  const handlePublishCustomerSegment = async () => {
    setIsSubmitting(true)
    try {
      let audience_id = isCustomerSegmentPage && id
      if (!audience_id) audience_id = await (await createAudienceSegment({ body })).data.id

      const resp = await updateAudienceSegment({ id: audience_id, body, action: 'publish' })
      setSegmentData(resp.data, setState)
      message.success('Publish customer segment successfully')
      onRedirect()
    } catch (err: any) {
      setErrors(formatErrors(err.response?.data) || 'Something went wrong')
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    if (isSubmitting) setErrors('')
  }, [isSubmitting])

  return (
    <HeaderView
      isNew={isNew}
      isSubmitting={isSubmitting}
      errors={errors}
      handlePublishCustomerSegment={handlePublishCustomerSegment}
      handleUpdateCustomerSegment={handleUpdateCustomerSegment}
      handleCreateCustomerSegment={handleCreateCustomerSegment}
      showButtons={showButtons}
    />
  )
}

export default Header
