import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'antd'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { isEmpty } from 'lodash'

import HarasocialSFPlacement from './HaraSocialSFPlacement'
import { HaraSocialValues } from 'HaraSocialType'
import Header from 'Components/CJHeader'
import { CurrentType } from 'CustomerJourneyModule'

interface Props {
  current: CurrentType
  value: HaraSocialValues
  isPopoverVisible: boolean
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  onAddNewNode: (type: string, values: HaraSocialValues) => void
  onUpdateNode: (values: HaraSocialValues) => void
}

const INIT_VALUE = {
  store_codes: [],
  action_id: null,
  products: [],
  link: [],
  message: null, // message_id
  image: [],
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
}

const HaraSocialModal: React.FC<Props> = (props) => {
  const { content } = useStoreState((state: any) => state.campaignModule)
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const { onUpdateNode, onAddNewNode, isPopoverVisible, setIsPopoverVisible, value } = props

  const [isModalVisible, setIsModalVisible] = useState<boolean>(true)

  const onSubmit = async (values: HaraSocialValues) => {
    onAddNewNode && (await onAddNewNode('hrs_store_farming', values))
    onUpdateNode && (await onUpdateNode(values))
    setIsModalVisible(false)
  }

  useEffect(() => {
    setIsPopoverVisible(false)
    setIsModalVisible(true)
    if (isEmpty(content)) setState({ key: 'content', value: value || INIT_VALUE })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  return (
    <Modal
      width={1000}
      visible={isModalVisible}
      title={<Header {...props} />}
      onCancel={() => setIsModalVisible(false)}
      onOk={() => {
        onSubmit(content)
      }}
      closable={false}
      className="ChannelsModal "
    >
      <div className="harasocial-modal">
        <HarasocialSFPlacement value={content} />
        {/* <HaraSocial value={value} onSubmit={(val: HaraSocialValues) => onSubmit(val)} /> */}
      </div>
    </Modal>
  )
}

export default HaraSocialModal
