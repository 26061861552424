import React, { useState, useEffect, useRef } from 'react'
import { useStoreActions } from 'easy-peasy'

import { Dimension } from 'DimensionsFiltersTypes'

import { Alert } from 'antd'
import LoadingIcon from 'Components/LoadingIcon'
import DimensionsPopup from './Dimensions.Popup'
import DimensionInterface from './DimensionInterface'
import './index.scss'

export type props = {
  onSaveDimensions: (updatedDimensions: Dimension[]) => void
  dimensions: Dimension[]
  isPopupVisible: boolean
  setIsPopupVisible: (isPopupVisible: boolean) => void
  list: { [name: string]: { page?: string } }
  emptyText?: React.ReactNode | string
  showEditButton?: boolean
  syncedConditions?: boolean
  activeDimension?: string
  segmentOptions?: []
}

const DimensionsFilters: React.FC<props> = ({
  onSaveDimensions,
  dimensions,
  setIsPopupVisible,
  isPopupVisible,
  list,
  emptyText,
  showEditButton,
  syncedConditions = false,
  activeDimension,
  segmentOptions,
}) => {
  const { getDimensionGroups } = useStoreActions((action: any) => action.dimensions)

  const [isSeeAll, setSeeAll] = useState(true)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const handleDimensionGroups = async () => {
      setIsLoading(true)
      try {
        await getDimensionGroups(list)
      } catch (err: any) {
        setError(err?.response?.data?.detail || 'Something went wrong')
      }
      setIsLoading(false)
    }
    handleDimensionGroups()
  }, [getDimensionGroups])

  if (isLoading)
    return (
      <div className="w-full flex justify-center">
        <LoadingIcon />
      </div>
    )

  if (error) return <Alert type="error" message={error} />

  return (
    <div
      style={{ height: isSeeAll ? 'fit-content' : 30 }}
      className="DimensionsFiltersContainer overflow-hidden flex w-full"
    >
      <div
        className="flex flex-wrap w-4/5 gap-2"
        onClick={() => {
          setIsPopupVisible(!isPopupVisible)
        }}
      >
        <DimensionInterface isEditable={false} value={dimensions} emptyText={emptyText} />
      </div>
      <div className="w-1/5 flex gap-3 justify-end py-1">
        {dimensions.length > 2 ? (
          <span onClick={() => setSeeAll(!isSeeAll)} className="text-brand_primary cursor-pointer">
            {isSeeAll ? 'Fewer' : 'See all'}
          </span>
        ) : null}
        {showEditButton && dimensions.length ? (
          <span
            onClick={() => {
              setIsPopupVisible(!isPopupVisible)
            }}
            className="text-brand_primary cursor-pointer"
          >
            Edit
          </span>
        ) : null}
      </div>

      <DimensionsPopup
        segmentOptions={segmentOptions}
        activeDimension={activeDimension}
        isPopupVisible={isPopupVisible}
        setIsPopupVisible={setIsPopupVisible}
        value={dimensions}
        onSave={onSaveDimensions}
        syncedConditions={syncedConditions}
      />
    </div>
  )
}

export default DimensionsFilters
