import React from 'react'
import { Typography } from 'antd'

import List from './List'
import Detail from './Detail'
import CheckPermission from 'Components/CheckPermission'
// import './index.scss'

const { Title } = Typography

const allowRoles = ['owner']

const CustomerList = (props) => {
  return <CheckPermission rolesPermission={allowRoles} component={List} {...props} />
}

const CustomerAnalytics = (props) => {
  return (
    <div style={{ width: '100%' }} className="Customer360Page">
      <CheckPermission rolesPermission={allowRoles} component={Detail} {...props} />
    </div>
  )
}

export { CustomerList, CustomerAnalytics }
