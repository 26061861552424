import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useStoreState, useStoreActions, createTypedHooks } from 'easy-peasy'
import queryString from 'query-string'
import { ModelTypes } from 'Models/index.types'
import originalAxios from 'axios'
import { Modal, Empty, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import LoadingIcon from 'Components/LoadingIcon'

const API_HOST = (process as any).env.REACT_APP_API_HOST + process.env.REACT_APP_API_VERSION
// const API_HOST = 'http://localhost:8000/api/v1'
const typedHooks = createTypedHooks<ModelTypes>()

const IAMLogin = () => {
  const history = useHistory()
  const location = useLocation()
  const params = queryString.parse(location.search)
  const setToken = typedHooks.useStoreActions((actions) => actions.auth.setToken)
  const setUserInfo = typedHooks.useStoreActions((actions) => actions.auth.setUserInfo)
  const userInfo = typedHooks.useStoreState((state) => state.auth.userInfo)
  const redirectPage = (page) => {
    switch (page) {
      case 'analyticsCustomersOverview':
        history.push('/analytics/customers?tab=overview')
        break
      case 'analyticsCustomersSegment':
        history.push('/analytics/customers?tab=segment_listing')
        break
      case 'analyticsCustomersListing':
        history.push('/analytics/customers?tab=customer_listing')
        break
      case 'customerSegments':
        history.push('/customers/segments?tab=published')
        break
      case 'campaign':
        history.push('/campaigns?tab=')
        break
      case 'product':
        history.push('/analytics/products?tab=overview')
        break
      case 'productListing':
        history.push('/analytics/products?tab=product_listing')
        break
      default:
        history.push('/')
        break
    }
  }
  useEffect(() => {
    const checkUserInfo = async () => {
      const { token, token_type } = params
      //   if (is_synced_with_hrw == 'false') setVisibleModal(true)
      setToken({ token: token, tokenType: token_type })
      let config = { headers: { Authorization: `${token_type} ${token}` } }

      let resp = await originalAxios.get(`${API_HOST}/agents/me`, config)
      if (resp.status === 401) message.error(resp.data.detail)
      setUserInfo(resp.data)
    }
    checkUserInfo()
  }, [])

  const handleRendering = () => {
    if (isEmpty(userInfo))
      return (
        <div className="flex content-center justify-center">
          <LoadingIcon></LoadingIcon>
        </div>
      )
    else {
      if (!params?.page) {
        history.push('/')
      } else {
        redirectPage(params?.page)
      }
    }
  }
  return <div>{handleRendering()}</div>
}

export default IAMLogin
