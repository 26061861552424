import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import { useHistory } from 'react-router-dom'

import LoadingIcon from 'Components/LoadingIcon'
import Default from './Default'
import CustomSQL from './CustomSQL'
import Header from './Header'
import { getAudienceSegment } from 'Services/customer-segments'
import { setSegmentData } from './helpers'
import { getUUId } from 'Utils'

const EditSegment = () => {
  const { id } = useParams()
  const { setState } = useStoreActions((action: any) => action.customerSegments)
  const setBreadCrumb = useStoreActions((actions: any) => actions.breadcrumb.setBreadCrumb)

  const [isLoading, setIsLoading] = useState(true)
  const [errors, setErrors] = useState('')

  const [isCustomSQL, setIsCustomSQL] = useState(false)

  const renderScreen = useMemo(() => {
    if (isCustomSQL) return <CustomSQL />

    return <Default />
  }, [isCustomSQL])

  useEffect(() => {
    setBreadCrumb([
      { title: 'Customer Segment List', path: `/customers/segments` },
      { title: id, path: `/customers/segments/${id}` },
    ])

    async function getCustomerSegment() {
      try {
        const resp = await getAudienceSegment(id)
        setSegmentData(resp.data, setState)
        if (resp.data.custom_sql) setIsCustomSQL(true)
      } catch (err: any) {
        setErrors(err.message || 'Something went wrong')
      }
      setIsLoading(false)
    }
    getCustomerSegment()
  }, [id, setState, setBreadCrumb])

  if (isLoading)
    return (
      <div className="w-full m-5 flex justify-center">
        <LoadingIcon />
      </div>
    )

  return (
    <div className="w-full">
      {errors && <div className="bg-negative p-2 w-full text-white">{errors}</div>}
      <Header />
      <div className="m-auto">{renderScreen}</div>
    </div>
  )
}

export default EditSegment
