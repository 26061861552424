import { action, thunk, debug } from 'easy-peasy'
import axios from 'Utils/axios'

const initState = {
  selectedAudienceId: null,
  audienceList: [],
  audienceType: 'kariba',
  name: '',
  description: '',
  stores: [],
  loading: false,
  counterLoading: true,
  audienceSizeValue: 0,
  audienceSizeMax: 0,
}
export default {
  ...initState,
  toggleLoading: action((state, boolean) => {
    state.loading = boolean
  }),
  resetState: action((state) => ({ ...initState })),
  setAudienceStates: action((state, { key, value }) => {
    state[key] = value
  }),
  fetchAudienceList: thunk(async (actions) => {
    const resp = await axios.get(`/audience-segments/?per_page=1000&page=1`)
    if (resp.status === 200) {
      return { data: resp.data.results }
    }
  }),
  fetchStoreTree: thunk(async (actions, { company }) => {
    const resp = await axios.get(`/stores/`, {
      params: { company },
    })
    if (resp.status === 200) return { data: resp.data.tree_store }
    return { errors: resp.response }
  }),
  fetchAllStores: thunk(async (actions, { company }) => {
    const resp = await axios.get(`/stores/flat`, {
      params: { company },
    })
    if (resp.status === 200) {
      const formattedResp = resp.data.stores.map((store) => {
        return {
          label: store.store_name,
          value: store.store_id,
          fb_location_id: store.fb_location_id,
          fb_location_name: store.fb_location_name,
        }
      })
      return { data: formattedResp }
    }
    return { errors: resp.response }
  }),

  createAudience: thunk(async (actions, _, { getState, getStoreState }) => {
    const state = getState()
    const storeState = getStoreState()
    try {
      const resp = await axios.post('/audience-segments/', {
        name: state.name,
        description: state.description,
        store_ids: JSON.stringify(state.stores),
        dimensions: JSON.stringify(storeState.segmentation.data),
        audience_type: state.audienceType,
      })

      actions.resetState()
      actions.setAudienceStates({ key: 'selectedAudienceId', value: resp.data.id })
      return { ok: true, id: resp.data.id }
    } catch (err) {
      return Promise.reject({ errors: err.response.data })
    }
  }),

  getAudience: thunk(async (actions, id, { getState, getStoreState, getStoreActions }) => {
    const storeActions = getStoreActions()
    const resp = await axios.get(`audience-segments/${id}`)

    if (resp.status === 200) {
      await actions.setAudienceStates({ key: 'selectedAudienceId', value: resp.data.id })
      await actions.setAudienceStates({ key: 'audienceType', value: resp.data.audience_type })
      await actions.setAudienceStates({ key: 'name', value: resp.data.name })
      await actions.setAudienceStates({ key: 'description', value: resp.data.description })
      await actions.setAudienceStates({ key: 'stores', value: resp.data.store_ids })

      if (resp.data.audience_type === 'facebook') {
        await storeActions.fbAudience.setFBAudienceStates({
          key: 'genders',
          value: resp.data.dimensions.genders,
        })
        await storeActions.fbAudience.setFBAudienceStates({
          key: 'ages',
          value: [resp.data.dimensions.age_min, resp.data.dimensions.age_max],
        })
        await storeActions.fbAudience.setCities(resp.data.dimensions.internal_data.cities)
        await storeActions.fbAudience.setInterests(resp.data.dimensions.internal_data.interests)
      }

      if (resp.data.audience_type === 'kariba') {
        await storeActions.segmentation.fetchDimensions({ type: 'kariba' })
        await storeActions.segmentation.setState({ key: 'data', value: resp.data.dimensions })
      }
    }
  }),
  selectAudience: thunk(async (actions, _, { getState, getStoreState }) => {
    const state = getState()
    const storeState = getStoreState()
    try {
      await axios.put(`/campaigns/${storeState.campaign.setting.campaignId}`, {
        audience_segment_id: state.selectedAudienceId,
      })

      return { ok: true }
    } catch (err) {
      return Promise.reject({ errors: err.response.data })
    }
  }),
  updateAudience: thunk(async (actions, _, { getState, getStoreState }) => {
    const state = getState()
    const storeState = getStoreState()
    try {
      await axios.put(`/audience-segments/${state.selectedAudienceId}`, {
        name: state.name,
        description: state.description,
        store_ids: JSON.stringify(state.stores),
        dimensions: JSON.stringify(storeState.segmentation.data),
        audience_type: state.audienceType,
      })

      return { ok: true, id: state.selectedAudienceId }
    } catch (err) {
      return Promise.reject({ errors: err.response.data })
    }
  }),
  deleteAudience: thunk(async (actions, id) => {
    try {
      await axios.delete(`audience-segments/${id}`)
      return { ok: true }
    } catch (err) {
      return Promise.reject({ errors: err.response.data })
    }
  }),
  setCounterLoading: action((state, loadingState) => {
    state.counterLoading = loadingState
  }),
  countAudience: thunk(async (actions, segmentation = []) => {
    actions.setCounterLoading(true)
    if (!segmentation || !Array.isArray(segmentation)) return 0
    // Stringify Array then repace null value to None string, final convert to bases64 string.
    // unescape && encodeURIComponent for removing Latin char
    const segmentationBased64 = window.btoa(
      unescape(encodeURIComponent(JSON.stringify(segmentation))).replace(/null/g, 'None')
    )
    let resp
    if (segmentation.length)
      resp = await axios.get('audience-dimensions/counter', {
        params: {
          dimensions: segmentationBased64,
        },
      })
    else resp = await axios.get('audience-dimensions/counter?dimensions=')
    actions.setCounterLoading(false)
    return resp.data?.counter
  }),
}
