import React, { useEffect, useState } from 'react'

import StackBar from 'Components/StackBar'
import 'Pages/Audiences/Detail/Components/Insights/index.scss'

import axios from 'Utils/axios'
import { Empty, message, Skeleton } from 'antd'
import { formatErrors } from 'Utils'
const TasksDailyReport = () => {
  const [data, setData] = useState(null)
  useEffect(() => {
    ;(async () => {
      try {
        const resp = await axios.get('/store-farming/daily-progress')
        setData(resp.data)
      } catch (error: any) {
        message.error(formatErrors(error?.response?.data))
      }
    })()
  }, [])

  if (!data) return <Skeleton></Skeleton>
  return (
    <div className="TasksDailyReportComponent">
      <div className="mb-3">
        {(data as any).length ? (
          <StackBar
            computeRest
            displayPercent={true}
            className="small-mode"
            title={'Công việc hôm nay'}
            showLegend={false}
            isLoading={false}
            data={data}
          />
        ) : (
          <Empty></Empty>
        )}
      </div>
      {/* // Todo: uncomment after have API */}
      {/* <div className="">
        <TasksReport className="small-mode"></TasksReport>
      </div> */}
    </div>
  )
}

export default TasksDailyReport
