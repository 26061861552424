import React, { useRef, useEffect, useState, useMemo } from 'react'
import { Chart } from 'chart.js'

import { COLOR_PALLETTE } from 'Utils/constants'
import { Skeleton, Empty } from 'antd'
import classNames from 'classnames'
import './index.scss'
import DoughnutTextInsidePlugin from '../Plugins/DoughnutTextInside'
import { colors } from 'DesignSystem'

const PieChart = ({ data, showLegend = true, isVertical, className = '', centerText = '' }) => {
  const pieChart = useRef<HTMLCanvasElement>(null)
  const [customizedLegends, setCustomizedLegends] = useState()

  const drawChart = (data) => {
    if (!pieChart || !pieChart.current || !data) return

    let target = pieChart.current as unknown as HTMLCanvasElement // todo: cheat, find better later
    /* tslint:disable-next-line */
    let ctx: any = target?.getContext('2d')

    let myChart = new Chart(ctx, {
      type: 'doughnut',
      plugins: [
        {
          id: 'DoughnutTextInsidePlugin',
          beforeDraw: DoughnutTextInsidePlugin,
        },
      ],
      data: {
        datasets: [
          {
            data: data.map((d) => d[Object.keys(d)[1]] || 'N/A'),
            backgroundColor: COLOR_PALLETTE,
            borderWidth: 2,
          },
        ],
        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: data.map((d) => d[Object.keys(d)[0]]),
      },
      options: {
        // responsive: true,
        rotation: 180,
        maintainAspectRatio: false,
        animation: {
          duration: 0,
          // rotation: true,
        },
        // @ts-ignore
        cutoutPercentage: 60,
        legend: false,
        legendCallback: function (chart) {
          //
          let legends = [] as string[]
          for (let i = 0; i < chart.data.datasets[0].data.length; i++) {
            legends.push(
              `<div class="legend">
                <div class='rectangle' style="background-color:${chart.data.datasets[0].backgroundColor[i]}"></div>`
            )
            if (chart.data.labels[i]) {
              legends.push(`
              <div class='label'>
                <span class="name">${chart.data.labels[i]}</span>
                <!-- <span class="figure">${chart.data.datasets[0].data[i]}</span> -->
              </div>`)
            }

            legends.push('</div>')
          }
          return legends.join('')
        },
        tooltips: {
          callbacks: {
            afterLabel: function (tooltipItems, data) {
              const add = (a, b) => {
                return a + b
              }
              let sum = data.datasets[0].data.reduce(add, 0)
              let percent =
                ((data.datasets[0].data[tooltipItems.index] / sum) * 100).toFixed(2) + '%'
              return `${data.datasets[0].data[tooltipItems.index]} - ${percent}`
            },
            label: function (tooltipItems, data) {
              return data.labels[tooltipItems.index]
            },
          },
          borderWidth: 1,
          borderColor: colors.productColor.brand_primary,
          backgroundColor: '#FFF',
          titleFontSize: 16,
          bodyFontColor: '#000',
          bodyFontSize: 14,
        },
        elements: {
          // center: {
          //   text: centerText,
          //   color: colors.productColor.black, // Default is #000000
          //   padding: 18,
          //   fontStyle: 'Arial', // Default is Arial
          //   sidePadding: 20, // Default is 20 (as a percentage)
          //   minFontSize: 14, // Default is 20 (in px), set to false and text will not wrap.
          //   lineHeight: 20, // Default is 25 (in px), used for when text wraps
          // },
        },
      },
    })

    // @ts-ignore
    setCustomizedLegends(myChart.generateLegend())
  }

  useEffect(() => {
    drawChart(data)
  }, [data])

  const PieChart = useMemo(() => {
    if (!data || !data.length) return <Empty description={false}></Empty>
    return (
      <div
        className={classNames('PieChartComponent', className)}
        style={{ flexDirection: isVertical && 'column-reverse' }}
      >
        <Skeleton active loading={!data} paragraph={{ rows: 4 }}>
          <>
            {showLegend && (
              <div
                style={{ height: isVertical && '40%' }}
                className="legends"
                dangerouslySetInnerHTML={{ __html: customizedLegends as unknown as string }}
              ></div>
            )}
            <div style={{ height: isVertical && showLegend && '50%' }} className="chart-content">
              <canvas ref={pieChart} className="chart"></canvas>
            </div>
          </>
        </Skeleton>
      </div>
    )
  }, [data, customizedLegends])

  return PieChart
}

export default PieChart
