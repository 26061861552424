import { action, thunk } from 'easy-peasy'
import axios from 'Utils/axios'

const initState = {
  data: {},
}

export default {
  ...initState,
  setCustomerAnalyticsStates: action((state, { key, value }) => {
    state[key] = value
  }),
  pushAnalytics: action((state, { key, value }) => {
    state[key].push(value)
  }),
  resetStates: action((state) => ({ ...initState })),
  getFilters: thunk(async (actions, _) => {
    // const resp = await axios.get(`customer-segments/analytics/filters`)
    const resp = await axios.get(`audience-segments/analytics/filters`)

    if (resp.errors) return { error: resp.response.data }

    return actions.setCustomerAnalyticsStates({ key: 'filterList', value: resp.data })
  }),
  getCustomerAnalytics: thunk(async (actions, id, { getState, getStoreState }) => {
    try {
      const analyticsResp = await axios.get(`customer-profiles/${id}`)
      if (analyticsResp.response) {
        return Promise.reject(analyticsResp.response.data)
      }

      actions.setCustomerAnalyticsStates({ key: 'data', value: analyticsResp.data })
    } catch (err) {
      return Promise.reject(err)
    }
  }),
}
