import React, { useState, useEffect, useRef } from 'react'
import { Dropdown, Menu, Checkbox } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'

import { useClickOutside, useKeyPress } from 'Hooks'

interface props {
  overlay: { label: string; value: string | number }[]
  onChange: (value: string[]) => void
  value: string[]
  title: string
}

const FilterDropdown: React.FC<props> = ({ overlay, onChange, value, title }) => {
  const dropdownRef = useRef(null)
  const ref = useRef(null)

  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(true)
  const [isVisible, setIsVisible] = useState(false)

  useClickOutside(ref, setIsVisible, dropdownRef)
  useKeyPress('Enter', (isKeyPressed: boolean) => setIsVisible(!isKeyPressed))
  useKeyPress('Escape', (isKeyPressed: boolean) => setIsVisible(!isKeyPressed))

  useEffect(() => {
    setIndeterminate(!!value.length && value.length < overlay.length)
    setCheckAll(value.length === overlay.length)
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  const onCheckAllChange = (e) => {
    onChange(e.target.checked ? overlay.map((option) => option.value.toString()) : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const computeLabel = (value: string[]) => {
    if (!value?.length) return 'All'
    if (value.length === 1) return overlay.find((item) => item.value === value[0])?.label
    if (value.length === overlay.length) return 'All'
    return (
      <div
        style={{ width: 20, height: 20 }}
        className="bg-brand_primary rounded-full flex items-center justify-center text-white"
      >
        {value.length}
      </div>
    )
  }

  const MENU = (
    <div
      ref={dropdownRef}
      style={{ maxHeight: 300 }}
      className="flex flex-col bg-gray_1 p-3 customer-segments-filter-dropdown overflow-scroll"
    >
      <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate} checked={checkAll}>
        All
      </Checkbox>
      <Checkbox.Group
        value={value}
        style={{ display: 'flex', flexDirection: 'column' }}
        options={overlay}
        defaultValue={value}
        onChange={(values: CheckboxValueType[]) =>
          onChange(values.map((value) => value.toString()))
        }
      />
    </div>
  )

  return (
    <div
      ref={ref}
      className="bg-gray_1 border border-gray_5 cursor-pointer transition hover:border-brand_primary relative"
      onClick={() => setIsVisible(true)}
      style={{ minWidth: 200, height: 40, borderRadius: 4 }}
    >
      <Dropdown
        getPopupContainer={(trigger: any) => trigger.parentNode}
        visible={isVisible}
        trigger={['click']}
        overlay={MENU}
      >
        <div className="py-2 px-3  flex justify-between items-center">
          <span className="flex">
            {title}: <span className="font-semibold px-2 flex">{computeLabel(value)}</span>
          </span>
          <DownOutlined />
        </div>
      </Dropdown>
    </div>
  )
}

export default FilterDropdown
