import React from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'

export type ProductType = {
  ProductId: string
  Title: string
  Price: string
  OriginalPrice: string
  Slug: string
  VariantId: string
  CategoryId: string
  Sku: string
  Color: string
  Size: string
  CategoryType: string
  Image: string
  ImageWithPerson: string
}

const ProductItem: React.FC<{
  product: ProductType
  imageOnly?: boolean
  onDelete?: (product: ProductType) => void
  onClick: (product: ProductType) => void
}> = ({ product, onDelete, onClick, imageOnly }) => {
  const renderImageItem = () => {
    if (imageOnly)
      return (
        <div className="product-photo">
          <img src={product.Image} alt="product" />
        </div>
      )

    return (
      <>
        <div className="flex items-center">
          <div className="product-photo mr-3">
            <img src={product.Image} alt="product" />
          </div>
          <div className="product-title-wrapper">
            <div className="__titl line-clamp-1">{product.Title}</div>
            <span className="__attribute">
              {product.Color && <div className="__color">{product.Color} -</div>}
              {product.Size && <div className="__size">&nbsp;Size {product.Size}</div>}
            </span>
          </div>
        </div>
        <div className="flex items-center price-wrapper">
          <span className="__price">{Number(product.Price).toLocaleString()} đ</span>
          {onDelete && (
            <span
              className="cursor-pointer mx-2"
              onClick={(e) => {
                e.stopPropagation()
                onDelete(product)
              }}
            >
              <CloseCircleOutlined />
            </span>
          )}
        </div>
      </>
    )
  }

  return (
    <div onClick={() => onClick(product)} className="sf-form-product-item">
      {renderImageItem()}
    </div>
  )
}

export default ProductItem
