import React, { useCallback, useEffect, useState } from 'react'
import { Tree, Table, Alert, Button, message, Empty } from 'antd'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import axios from 'axios'

import TableHeader from 'Components/Table2.0/Components/Header'
import useTable from 'Components/Table2.0/Components/useTable'
import LoadingIcon from 'Components/LoadingIcon'
import { CATEGORIES, COLUMNS, PRODUCT_TAGS, BRAND, SUPPLIER, SAMPLE_ITEM_DATA } from './constants'
import { Category, ProductItem as ProductItemType } from 'ProductListingTypes'
import DatePicker from 'Components/DatePicker'
import { ReactComponent as IconGrid } from 'Assets/images/icons/grid.svg'
import { ReactComponent as IconListView } from 'Assets/images/icons/listView.svg'
import { ReactComponent as IconSort } from 'Assets/images/icons/sorter.svg'

import ProductItem from './ProductItem'
import {
  getBrandFilterList,
  getCategoryList,
  getProductDetailList,
} from 'Services/Analytics/product-analytics'
import moment from 'moment'
import { debounce, values } from 'lodash'

const ProductListing = () => {
  const handleMTD = (format: string) => {
    const arrDate = format.split('-')
    const formatDate = `${arrDate[0]}-${arrDate[1]}-01`
    return moment().format(formatDate)
  }
  const [view, setChangeView] = useState('grid')
  const [sampleGridData, setSampleGridData] = useState<ProductItemType[]>(SAMPLE_ITEM_DATA)
  const [brandFilterList, setBrandFilterList] = useState<{ label: string; value: string }[]>([])
  const [categoryList, setCategoryList] = useState<Category[]>([])
  const [productDetailList, setProductDetailList] = useState<ProductItemType[]>(SAMPLE_ITEM_DATA)
  const [isLoading, setIsLoading] = useState(false)
  const [cateId, setCateId] = useState<any>('root')
  const [isProductListLoading, setIsProductListLoading] = useState(true)
  const [page, setPage] = useState(2)
  const [testPage, setTestPage] = useState(1)
  const [cateIdChanged, setCateIdChanged] = useState('')
  const [brand, setBrand] = useState<any>('')
  const [supplier, setSupplier] = useState<any>('')
  const [date, setDate] = useState<any>({
    from_date: handleMTD('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
  })
  const [keyWord, setKeyword] = useState('')
  const [isQuerying, setIsQuerying] = useState(false)
  const [perpage, setPerpage] = useState(100)
  const [test, setTest] = useState(false)
  const [disable, setDisable] = useState(false)

  const handleBrandData = async () => {
    try {
      const resp = await getBrandFilterList()
      const tempData = resp.data[0].data.map((item: any) => ({
        label: item.name,
        value: item.id,
      }))
      setBrandFilterList(tempData)
    } catch (err: any) {
      message.error('Something went wrong')
    }
  }

  const handleCateData = async () => {
    setIsLoading(true)

    try {
      const resp = await getCategoryList()
      //console.log('testing: ', resp.data.node)
      const tempData = resp.data.node.map((item: any) => ({
        title: item.name,
        key: item.category_id,
        children: item.node.map((child: any) => ({
          title: child.name,
          key: child.category_id,
          children: child.node.map((grandChild: any) => ({
            title: grandChild.name,
            key: grandChild.category_id,
            children: grandChild.node.map((greatGrandChild: any) => ({
              title: greatGrandChild.name,
              key: greatGrandChild.category_id,
              children: greatGrandChild.node.map((greatGreatGrandChild: any) => ({
                title: greatGreatGrandChild.name,
                key: greatGreatGrandChild.category_id,
                children: greatGreatGrandChild.node.map((greatGreatGreatGrandChild: any) => ({
                  title: greatGreatGreatGrandChild.name,
                  key: greatGreatGreatGrandChild.category_id,
                  children: greatGreatGreatGrandChild.node.map(
                    (greatGreatGreatGreatGrandChild: any) => ({
                      title: greatGreatGreatGreatGrandChild.name,
                      key: greatGreatGreatGreatGrandChild.category_id,
                      children: greatGreatGreatGreatGrandChild.node.map(
                        (greatGreatGreatGreatGreatGrandChild: any) => ({
                          title: greatGreatGreatGreatGreatGrandChild.name,
                          key: greatGreatGreatGreatGreatGrandChild.category_id,
                          children: greatGreatGreatGreatGreatGrandChild.node,
                        })
                      ),
                    })
                  ),
                })),
              })),
            })),
          })),
        })),
      }))

      //console.log('converted data: ', tempData)
      setCategoryList(tempData)
      setIsLoading(false)

      //setBrandFilterList(tempData)
    } catch (err: any) {
      message.error('Something went wrong')
    }
  }
  useEffect(() => {
    handleBrandData()
    handleCateData()
  }, [])

  useEffect(() => {
    setIsProductListLoading(false)
    setIsQuerying(true)
    let cancelTokenSource = axios.CancelToken.source()
    setTest(false)

    const handleProductListData = async () => {
      try {
        const resp = await getProductDetailList(
          cateId,
          1,
          perpage,
          100,
          brand,
          supplier,
          date.from_date,
          date.to_date,
          keyWord,
          '',
          cancelTokenSource
        )
        const data = await resp.data.results
        setProductDetailList(data)
        data?.length == 0 ? setIsProductListLoading(true) : setIsProductListLoading(false)
        setCateIdChanged('')
        setIsQuerying(false)
      } catch (err: any) {
        //message.error('Something went wrong')
      }
    }
    handleProductListData()
    return () => {
      cancelTokenSource.cancel('cancelled')
    }
  }, [cateId, keyWord, brand, supplier, date, perpage])

  const debounceDropDown = useCallback(
    debounce((nextValue) => {
      setKeyword(nextValue)
    }, 1000),
    []
  )

  const fetchProducts = async (p, order) => {
    //console.log('fetch products called')
    const resp = await getProductDetailList(
      cateId,
      p,
      perpage,
      19000,
      brand,
      supplier,
      date.from_date,
      date.to_date,
      keyWord,
      order
    )
    const data = await resp.data.results
    return data
  }

  const { params, setParams, data, loading, errors, total } = useTable({
    url: 'products/analytics',
    key: 'results',
  })

  const onLoad = async () => {
    setDisable(true)
    const productLists = await fetchProducts(page, '')
    setProductDetailList([...productDetailList, ...productLists])
    setPage(page + 1)
    productLists && setDisable(false)
  }
  const onTableChange = async (tablePagination, _, sorter) => {
    setIsQuerying(true)
    setPerpage(tablePagination.pageSize)
    setTestPage(tablePagination.current)
    const productLists = await fetchProducts(tablePagination.current, sorter.field)
    setProductDetailList(productLists)
    productLists && setIsQuerying(false)
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: false,
    hasNextPage: true,
    onLoadMore: onLoad,
    disabled: disable,
    rootMargin: '0px 0px 400px 0px',
  })

  return (
    <div className="ProductListing">
      {errors && <Alert message={errors} type="error" />}
      <div className="mb-4 flex justify-between items-center">
        <div className="">
          <TableHeader
            search={{
              keyword: test ? '' : params?.query,
              onSearch: (v) => {
                //setParams({ ...params, query: v })
                //setKeyword(v)

                debounceDropDown(v)
              },
            }}
            dropdown={{
              fields: [
                {
                  label: 'Brand',
                  value: 'brands',
                  options: brandFilterList,
                  type: 'DROPDOWN',
                  onClick: ({ key, value }) => {
                    setParams({ ...params, [key]: value })
                    setBrand(value.toString())
                  },
                },
                {
                  label: 'Supplier',
                  value: 'suppliers',
                  options: brandFilterList,
                  type: 'DROPDOWN',
                  onClick: ({ key, value }) => {
                    setParams({ ...params, [key]: value })
                    setSupplier(value.toString())
                  },
                },
              ],
              value: {
                brands: params?.brands,
                suppliers: params?.suppliers,
              },
            }}
          />
        </div>
        <div className="flex items-center">
          <div className={`iconProductListing ${view == 'list' && 'active'}`}>
            <div onClick={() => setChangeView('list')} className="p-2 rounded ml-2">
              <IconListView />
            </div>
          </div>
          <div className={`iconProductListing ${view == 'grid' && 'active'}`}>
            <div onClick={() => setChangeView('grid')} className="p-2 rounded mr-2 ">
              <IconGrid />
            </div>
          </div>
          <div>
            <DatePicker
              onChange={({ from_date, to_date }) => {
                // console.log({ from_date, to_date })
                setDate({ from_date: from_date, to_date: to_date })
              }}
              value={{ from_date: date?.from_date, to_date: date?.to_date }}
            />
          </div>
        </div>
      </div>
      <div className="flex shadow-full rounded  mr-1">
        <div className="bg-white left-corner-radius  relative" style={{ width: 304 }}>
          <div
            style={{ height: 50, background: 'var(--table_header)' }}
            className="p-4 border-b top-left-corner-radius flex text-sm text-secondary_text"
          >
            Category
            <IconSort className="ml-2 cursor-pointer"></IconSort>
          </div>

          <Tree
            treeData={categoryList}
            onSelect={(checkedKeys) => {
              //setParams({ ...params, category_id: checkedKeys[0] })
              setCateId(checkedKeys[0])
              setCateIdChanged('changed')
              setKeyword('')
              setTest(true)
              //setProductDetailList(data)
            }}
          />
        </div>

        {view === 'grid' ? (
          isQuerying === false ? (
            !isProductListLoading ? (
              <div className="flex-1 overflow-scroll Products-grid-container" ref={rootRef}>
                <div className="Products-grid-title">Product Info</div>
                <div className="flex-1 grid Products-grid">
                  {productDetailList.map((item, index) => {
                    return <ProductItem product={item} key={index}></ProductItem>
                  })}
                </div>

                <div ref={sentryRef}>
                  <LoadingIcon style={{ margin: 'auto' }} />
                </div>
              </div>
            ) : (
              <Empty style={{ width: '100%' }}></Empty>
            )
          ) : (
            <LoadingIcon style={{ margin: 'auto', marginBottom: '50%' }}></LoadingIcon>
          )
        ) : isQuerying === false ? (
          <Table
            pagination={{
              pageSizeOptions: ['100'],
              showSizeChanger: true,
              pageSize: perpage || 100,
              total: 19000 || undefined,
              current: testPage || 1,
            }}
            loading={{
              spinning: false,
              indicator: <LoadingIcon style={{ margin: 'auto', marginLeft: '50%' }} />,
            }}
            columns={COLUMNS}
            dataSource={productDetailList}
            onChange={onTableChange}
            onRow={(record: ProductItemType) => {
              return {
                onClick: () => {
                  window.open(`/analytics/product360/${record?.barcode}`, '_self')
                },
              }
            }}
            scroll={{ x: 2300 }}
          />
        ) : (
          <LoadingIcon style={{ margin: 'auto', marginBottom: '50%' }}></LoadingIcon>
        )}
      </div>
    </div>
  )
}
export default ProductListing
