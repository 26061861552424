export const MONTH_ARRAY = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
export const DAY_ARRAY = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
]
export const DATE_ARRAY = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sar', 'Sun']
export const setSegmentData = (resp, handler) => {
  if (resp.audience_type === 'kariba') {
    resp.dimensions &&
      handler({
        key: 'criteria_groups',
        value: resp.dimensions,
      })
    handler({ key: 'predictive_segment', value: resp.predictive_segment })
  }
  if (resp.audience_type === 'facebook') {
    handler({
      key: 'age_max',
      value: resp.dimensions.age_max,
    })
    handler({
      key: 'age_min',
      value: resp.dimensions.age_min,
    })
    handler({
      key: 'genders',
      value: resp.dimensions.genders,
    })
    handler({
      key: 'cities',
      value:
        resp.dimensions.geo_locations.cities.map((city) =>
          resp.dimensions.internal_data.cities.find((item) => item.value === city.key)
        ) || [],
    })
    handler({
      key: 'interests',
      value:
        resp.dimensions.interests.map((city) =>
          resp.dimensions.internal_data.interests.find((item) => item.value === city.id)
        ) || [],
    })
  }

  handler({ key: 'id', value: resp.id })
  handler({ key: 'source', value: resp.audience_type })
  handler({ key: 'store_ids', value: resp.store_ids || [] })
  handler({ key: 'name', value: resp.name })
  handler({ key: 'description', value: resp.description })
  handler({ key: 'predefined_segment', value: resp.predefined_segment })
  handler({ key: 'custom_sql', value: resp.custom_sql })
}

export const formatCitiesForAPIs = (cities) => {
  return cities.map((city) => ({
    key: city.value,
    radius: '40',
    distance_unit: 'kilometer',
  }))
}

export const formatInterestsForAPIs = (interests) => {
  return interests.map((interest) => ({ id: interest.value }))
}

export const formatValueType = (input) => {
  if (input?.split(',')[0].length <= 3) {
    if (input?.split(',').filter((e) => MONTH_ARRAY.includes(e)).length > 0) {
      return 'month(' + input + ')'
    } else if (input?.split(',').filter((e) => DATE_ARRAY.includes(e)).length > 0) {
      return 'dayofweek(' + input + ')'
    } else if (input?.split(',').filter((e) => DAY_ARRAY.includes(e)).length > 0) {
      return 'day(' + input + ')'
    } else {
      return ''
    }
  } else if (input?.split(',')[0].length == 13) {
    return 'hour(' + input + ')'
  } else {
    return input
  }
}

export const formatRespTimeType = (respTime) => {
  if (respTime?.split('(').length > 1) {
    const temp = respTime?.split('(')[1]
    return temp?.split(')')[0]
  }
  return respTime
}
