import React from 'react'

import MultiDoughnutChartTemplate from '../../../Components/MultiDoughnutChart.Template'
import BarChartTemplate from '../../../Components/BarChart.Template'

import { getCustomerOverviewCharts } from 'Services/Analytics/customer-analytics'

const ChartRow1 = () => {
  return (
    <>
      <div className="bg-white p-4 col-span-2">
        <div className="mb-4 font-semibold">New vs Return</div>
        <div className="flex h-auto items-center">
          <div style={{ height: 300 }} className="overflow-y-scroll overflow-x-hidden w-1/2">
            <MultiDoughnutChartTemplate
              chartCode="new_return_doughnut"
              handleDataFunction={getCustomerOverviewCharts}
            />
          </div>
          <div style={{ height: 300 }} className="overflow-y-scroll overflow-x-hidden w-1/2">
            <BarChartTemplate
              chartCode="new_return_bar"
              handleDataFunction={getCustomerOverviewCharts}
              isStacked
            />
          </div>
        </div>
      </div>
      <div className="bg-white p-4 col-span-1">
        <div className="mb-4 font-semibold">Membership</div>
        <div style={{ height: 300 }} className="overflow-y-scroll overflow-x-hidden w-full">
          <MultiDoughnutChartTemplate
            chartCode="membership"
            handleDataFunction={getCustomerOverviewCharts}
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow1
