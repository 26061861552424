import React, { useState, useEffect } from 'react'
import { Modal, Skeleton, message } from 'antd'
import { connect } from 'react-redux'

import { kaipassSchema } from 'Validation'
import { formatErrors } from 'Utils'
import { INIT_VALUES, ValuesKaipass } from './constants'
import Axios from 'Utils/axios'
import Header from 'Components/CJHeader'
import CreativeContent from 'Containers/CreativeContent'
import Preview from 'Components/CreativeContentsPreview'
import { CurrentType } from 'CustomerJourneyModule'
import './index.scss'

interface props {
  isPopoverVisible: boolean
  value: ValuesKaipass
  initData: any
  isRunning: boolean
  current: CurrentType
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
  onAddNewNode: (type: string, values: ValuesKaipass) => void
  onUpdateNode: (values: ValuesKaipass) => void
  setCreativeContentsState: React.Dispatch<React.SetStateAction<any>>
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  setPreviewData: React.Dispatch<React.SetStateAction<{ key: string; value: ValuesKaipass }>>
}

const Kaipass: React.FC<props> = (props) => {
  const {
    setIsPopoverVisible,
    isPopoverVisible,
    value,
    onAddNewNode,
    onUpdateNode,
    setCreativeContentsState,
    initData,
    setPreviewData,
  } = props
  const [isModalVisible, setIsModalVisible] = useState(true)
  const [fields, setFields] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onCloseModal = () => {
    setIsModalVisible(false)
  }

  const onSubmit = async (values: ValuesKaipass) => {
    onAddNewNode && (await onAddNewNode('kaipass', values))
    onUpdateNode && (await onUpdateNode(values))
    setIsModalVisible(false)
  }

  useEffect(() => {
    setCreativeContentsState({ key: 'previewData', value: value || INIT_VALUES })
    setIsPopoverVisible(false)
    setIsModalVisible(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  useEffect(() => {
    const handleFetchSchemas = async () => {
      try {
        setIsLoading(true)
        const resp = await Axios.get('/placements/kaipass')

        setFields(resp.data?.schemas)
      } catch (err: any) {
        message.error(formatErrors(err?.response?.data))
      } finally {
        setIsLoading(false)
      }
    }
    handleFetchSchemas()
  }, [])

  return (
    <Modal
      {...props}
      onOk={onCloseModal}
      onCancel={() => setIsModalVisible(!false)}
      visible={isModalVisible}
      maskClosable
      title={<Header {...props} />}
      footer={[]}
      width={800}
      closable={false}
      className="ChannelsModal"
    >
      <div className={`KaipassComponent ${props.isRunning ? 'is-running' : ''}`}>
        <Skeleton loading={isLoading} active>
          <CreativeContent
            onSubmit={onSubmit}
            submitText="Save"
            initValues={initData}
            validationSchema={kaipassSchema}
            onChange={(name: string, value: ValuesKaipass) =>
              setPreviewData({
                key: name,
                value: value,
              })
            }
            fields={fields}
          />
          <Preview isPreview={true} type={'kaipass'}></Preview>
        </Skeleton>
      </div>
    </Modal>
  )
}

interface StateProps {
  campaign: any
}

export default connect(
  (state: StateProps) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
    setPreviewData: dispatch.campaign.creativeContents.setPreviewData,
  })
)(Kaipass)
