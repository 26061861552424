import { generateColor, hexToRgbA } from 'Utils'
import { COLOR_PALLETTE } from 'Utils/constants'

export const getDataFrom = (label: string, data: any[]) => {
  const result: string[] = []
  data.forEach((item: any) => {
    try {
      if (label in item.values) {
        result.push(item?.values[label])
      }
    } catch (exp) {
      throw exp
    }
  })

  return result
}

export const buildDataset = (rawData, fill?, stack?) => {
  const formatData: any[] = []
  // const labels = rawData.map((item, index) => rawData.labels.push(item.label))
  rawData.labels.forEach((labelItem, index) => {
    const formatItem: { label: string; data: string[]; fill: boolean; stack: boolean } = {
      label: labelItem,
      // @ts-ignore
      backgroundColor: hexToRgbA(COLOR_PALLETTE[index], 0.8),
      data: [],
      fill: fill,
      stack: stack,
      borderWidth: 0,
    }

    // check and load formatItem data
    formatItem.data = getDataFrom(labelItem, rawData.data)

    // add to formatData
    formatData.push(formatItem)
  })

  return formatData
}
