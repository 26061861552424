import React from 'react'
import HSPlacement from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/StoreFarmingForm'
import TargetingItem from 'Pages/Harasiocial/CustomerPortrait/Components/Customer/TargetingItem'
import './index.scss'
import { toCamel } from 'Utils'

const HaraSocialPlacement = ({ value }) => {
  return (
    <div className="flex gap-2 HarasocialPlacement">
      <HSPlacement />

      <div className="w-full" id="ContentPreview">
        <div className="flex justify-between items-center">
          <span className="font-semibold text-lg">Preview</span>
        </div>
        <div className="preview-screen">
          <TargetingItem task={toCamel(value)} mode="preview" expanded />
        </div>
      </div>
    </div>
  )
}

export default HaraSocialPlacement
