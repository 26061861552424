const imageItem = (data, imagePlaceholder) => `<div
    class="img-container center autowidth"
    align="center"
    style="padding-right: 0px; padding-left: 0px; margin-bottom: 3px;"
  >
    <a href="javascript:void(0)" style="outline: none; pointer-events: none;" tabindex="-1"
      ><img
        class="center autowidth"
        align="center"
        border="0"
        src=${data.image || imagePlaceholder}
        alt=""
        title=""
        style="
          text-decoration: none;
          -ms-interpolation-mode: bicubic;
          height: auto;
          border: 0;
          width: 100%;
          max-width: 550px;
          display: block;
        "
        width="650"
    /></a>
  </div>`

export const template = (
  imageItems,
  imagePlaceholder
) => `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html
    xmlns="http://www.w3.org/1999/xhtml"
    xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
  >
    <head>
      <!--[if gte mso 9
        ]><xml
          ><o:OfficeDocumentSettings
            ><o:AllowPNG /><o:PixelsPerInch
              >96</o:PixelsPerInch
            ></o:OfficeDocumentSettings
          ></xml
        ><!
      [endif]-->
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width" />
      <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <!--<![endif]-->
      <title></title>
      <!--[if !mso]><!-->
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Roboto"
        rel="stylesheet"
        type="text/css"
      />
      <!--<![endif]-->
      <style type="text/css">
        body {
          margin: 0;
          padding: 0;
        }
  
        table,
        td,
        tr {
          vertical-align: top;
          border-collapse: collapse;
        }
  
        * {
          line-height: inherit;
        }
  
        a[x-apple-data-detectors="true"] {
          color: inherit !important;
          text-decoration: none !important;
        }
      </style>
      <style type="text/css" id="media-query">
          .block-grid,
          .col {
            min-width: 320px !important;
            max-width: 100% !important;
            display: block !important;
          }
  
          .block-grid {
            width: 100% !important;
          }
  
          .col {
            width: 100% !important;
          }
  
          .col_cont {
            margin: 0 auto;
          }
  
          img.fullwidth,
          img.fullwidthOnMobile {
            max-width: 100% !important;
          }
  
          .no-stack .col {
            min-width: 0 !important;
            display: table-cell !important;
          }
  
          .no-stack.two-up .col {
            width: 50% !important;
          }
  
          .no-stack .col.num2 {
            width: 16.6% !important;
          }
  
          .no-stack .col.num3 {
            width: 25% !important;
          }
  
          .no-stack .col.num4 {
            width: 33% !important;
          }
  
          .no-stack .col.num5 {
            width: 41.6% !important;
          }
  
          .no-stack .col.num6 {
            width: 50% !important;
          }
  
          .no-stack .col.num7 {
            width: 58.3% !important;
          }
  
          .no-stack .col.num8 {
            width: 66.6% !important;
          }
  
          .no-stack .col.num9 {
            width: 75% !important;
          }
  
          .no-stack .col.num10 {
            width: 83.3% !important;
          }
  
          .video-block {
            max-width: none !important;
          }
  
          .mobile_hide {
            min-height: 0px;
            max-height: 0px;
            max-width: 0px;
            display: none;
            overflow: hidden;
            font-size: 0px;
          }
  
          .desktop_hide {
            display: block !important;
            max-height: none !important;
          }
        
      </style>
    </head>
  
    <body
      class="clean-body"
      style="
        margin: 0;
        padding: 0;
        -webkit-text-size-adjust: 100%;
        background-color: var(--white);
      "
    >
      <!--[if IE]><div class="ie-browser"><![endif]-->
      <table
        class="nl-container"
        style="
          table-layout: fixed;
          vertical-align: top;
          min-width: 320px;
          border-spacing: 0;
          border-collapse: collapse;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          background-color: var(--white);
          width: 100%;
        "
        cellpadding="0"
        cellspacing="0"
        role="presentation"
        width="100%"
        bgcolor="#FFFFFF"
        valign="top"
      >
        <tbody>
          <tr style="vertical-align: top" valign="top">
            <td style="word-break: break-word; vertical-align: top" valign="top">
              <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color:#FFFFFF"><![endif]-->
              <div style="background-color: transparent">
                <div
                  class="block-grid"
                  style="
                    min-width: 320px;
                    max-width: 550px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    margin: 0 auto;
                    background-color: transparent;
                  "
                >
                  <div
                    style="
                      border-collapse: collapse;
                      display: table;
                      width: 100%;
                      background-color: transparent;
                    "
                  >
                    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:650px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                    <!--[if (mso)|(IE)]><td align="center" width="650" style="background-color:transparent;width:650px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
                    <div
                      class="col num12"
                      style="
                        min-width: 320px;
                        max-width: 550px;
                        display: table-cell;
                        vertical-align: top;
                        width: 650px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 0px solid transparent;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-right: 10px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Tahoma, Verdana, sans-serif"><![endif]-->
                          <div
                            style="
                              color: #555555;
                              font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                sans-serif;
                              line-height: 1.5;
                              padding-top: 0px;
                              padding-right: 0px;
                              padding-bottom: 0px;
                              padding-left: 0px;
                            "
                          >
                            <div
                              class="txtTinyMce-wrapper"
                              style="
                                line-height: 1.5;
                                font-size: 12px;
                                font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                  sans-serif;
                                color: #555555;
                                mso-line-height-alt: 18px;
                              "
                            >
                              <p
                                style="
                                  font-size: 12px;
                                  line-height: 1.5;
                                  word-break: break-word;
                                  text-align: center;
                                  font-family: Roboto, Tahoma, Verdana, Segoe,
                                    sans-serif;
                                  mso-line-height-alt: 18px;
                                  margin: 0;
                                "
                              >
                                <span style="font-size: 12px; color: #a3a3a3"
                                  ><em
                                    >Freeship cho hóa đơn trên 300,000đ</em
                                  ></span
                                >
                              </p>
                            </div>
                          </div>
                          <!--[if mso]></td></tr></table><![endif]-->
                          <table
                            class="divider"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            width="100%"
                            style="
                              table-layout: fixed;
                              vertical-align: top;
                              border-spacing: 0;
                              border-collapse: collapse;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              min-width: 100%;
                              -ms-text-size-adjust: 100%;
                              -webkit-text-size-adjust: 100%;
                            "
                            role="presentation"
                            valign="top"
                          >
                            <tbody>
                              <tr style="vertical-align: top" valign="top">
                                <td
                                  class="divider_inner"
                                  style="
                                    word-break: break-word;
                                    vertical-align: top;
                                    min-width: 100%;
                                    -ms-text-size-adjust: 100%;
                                    -webkit-text-size-adjust: 100%;
                                    padding-top: 5px;
                                    padding-right: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 5px;
                                  "
                                  valign="top"
                                >
                                  <table
                                    class="divider_content"
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      table-layout: fixed;
                                      vertical-align: top;
                                      border-spacing: 0;
                                      border-collapse: collapse;
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-top: 1px solid #d2d2d2;
                                      width: 100%;
                                    "
                                    align="center"
                                    role="presentation"
                                    valign="top"
                                  >
                                    <tbody>
                                      <tr
                                        style="vertical-align: top"
                                        valign="top"
                                      >
                                        <td
                                          style="
                                            word-break: break-word;
                                            vertical-align: top;
                                            -ms-text-size-adjust: 100%;
                                            -webkit-text-size-adjust: 100%;
                                          "
                                          valign="top"
                                        >
                                          <span></span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                  </div>
                </div>
              </div>
              <div style="background-color: transparent">
                <div
                  class="block-grid mixed-two-up"
                  style="
                    min-width: 320px;
                    max-width: 550px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    margin: 0 auto;
                    background-color: transparent;
                  "
                >
                  <div
                    style="
                      border-collapse: collapse;
                      display: table;
                      width: 100%;
                      background-color: transparent;
                    "
                  >
                    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:650px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                    <!--[if (mso)|(IE)]><td align="center" width="216" style="background-color:transparent;width:216px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
                    <div
                      class="num4"
                      style="
                        display: table-cell;
                        vertical-align: top;
                        max-width: 320px;
                        min-width: 216px;
                        width: 216px;
                      "
                    >
                      <div class="col_cont">
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 0px solid transparent;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <div
                            class="img-container center fixedwidth"
                            align="center"
                            style="padding-right: 15px; padding-left: 15px"
                          >
                            <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 15px;padding-left: 15px;" align="center"><![endif]-->
                            <div style="font-size: 1px; line-height: 15px">
                              &nbsp;
                            </div>
                            <a
                              href="https://juno.vn?utm_source={{ utm_source }}&amp;utm_medium={{ utm_medium }}&amp;utm_campaign={{ utm_campaign }}&amp;krb_cj_id={{ cj_id }}"
                              target="_blank"
                              style="outline: none"
                              tabindex="-1"
                              ><img
                                class="center fixedwidth"
                                align="center"
                                border="0"
                                src="https://email-beeplugin-images.s3.amazonaws.com/images/insider-bee-client-juno-production/logo-svg.png"
                                alt="Alternate text"
                                title="Alternate text"
                                style="
                                  text-decoration: none;
                                  -ms-interpolation-mode: bicubic;
                                  height: auto;
                                  border: 0;
                                  max-width: 173px;
                                "
                                width="173"
                            /></a>
                            <div style="font-size: 1px; line-height: 15px">
                              &nbsp;
                            </div>
                            <!--[if mso]></td></tr></table><![endif]-->
                          </div>
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td><td align="center" width="433" style="background-color:transparent;width:433px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
                    <div
                      class="col num8"
                      style="
                        display: table-cell;
                        vertical-align: top;
                        max-width: 320px;
                   
                      "
                    >
                      <div class="col_cont">
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 0px solid transparent;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 25px; padding-left: 15px; padding-top: 15px; padding-bottom: 15px; font-family: Tahoma, Verdana, sans-serif"><![endif]-->
                          <div
                            style="
                              color: #555555;
                              font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                sans-serif;
                              line-height: 2;
                              padding-top: 15px;
                              padding-right: 25px;
                              padding-bottom: 15px;
                              padding-left: 15px;
                            "
                          >
                            <div
                              class="txtTinyMce-wrapper"
                              style="
                                line-height: 2;
                                font-size: 12px;
                                font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                  sans-serif;
                                color: #555555;
                                mso-line-height-alt: 24px;
                              "
                            >
                              <p
                                style="
                                  font-size: 17px;
                                  line-height: 2;
                                  word-break: break-word;
                                  text-align: right;
                                  font-family: Roboto, Tahoma, Verdana, Segoe,
                                    sans-serif;
                                  mso-line-height-alt: 34px;
                                  mso-ansi-font-size: 18px;
                                  margin: 0;
                                "
                              >
                                <span
                                  style="
                                    font-size: 17px;
                                    color: var(--black);
                                    mso-ansi-font-size: 18px;
                                  "
                                  ><a
                                    style="text-decoration: none; color: #000000"
                                    href="https://juno.vn/collections/giay?utm_source={{ utm_source }}&amp;utm_medium={{ utm_medium }}&amp;utm_campaign={{ utm_campaign }}&amp;krb_cj_id={{ cj_id }}"
                                    target="_blank"
                                    rel="noopener"
                                    ><strong>Giày</strong></a
                                  ><strong>
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                    <a
                                      style="
                                        text-decoration: none;
                                        color: var(--black);
                                      "
                                      href="https://juno.vn/collections/tui-xach?utm_source={{ utm_source }}&amp;utm_medium={{ utm_medium }}&amp;utm_campaign={{ utm_campaign }}&amp;krb_cj_id={{ cj_id }}"
                                      target="_blank"
                                      rel="noopener"
                                      >Túi - Ví</a
                                    >&nbsp; &nbsp; &nbsp; &nbsp;
                                    <a
                                      style="
                                        text-decoration: none;
                                        color: var(--black);
                                      "
                                      href="https://juno.vn/collections/list-collections?utm_source={{ utm_source }}&amp;utm_medium={{ utm_medium }}&amp;utm_campaign={{ utm_campaign }}&amp;krb_cj_id={{ cj_id }}"
                                      target="_blank"
                                      rel="noopener"
                                      >Bộ Sưu Tập</a
                                    ></strong
                                  ></span
                                >
                              </p>
                            </div>
                          </div>
                          <!--[if mso]></td></tr></table><![endif]-->
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                  </div>
                </div>
              </div>
              <div style="background-color: transparent">
                <div
                  class="block-grid"
                  style="
                    min-width: 320px;
                    max-width: 550px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    margin: 0 auto;
                    background-color: transparent;
                  "
                >
                  <div
                    style="
                      border-collapse: collapse;
                      display: table;
                      width: 100%;
                      background-color: transparent;
                    "
                  >
                    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:650px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                    <!--[if (mso)|(IE)]><td align="center" width="650" style="background-color:transparent;width:650px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
                    <div
                      class="col num12"
                      style="
                        min-width: 320px;
                        max-width: 550px;
                        display: table-cell;
                        vertical-align: top;
                        width: 650px;
                      "
                    >
                      <div class="col_cont">
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 0px solid transparent;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          ${imageItems.map((data) => imageItem(data, imagePlaceholder)).join('')}
                          <table
                            class="divider"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            width="100%"
                            style="
                              table-layout: fixed;
                              vertical-align: top;
                              border-spacing: 0;
                              border-collapse: collapse;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              min-width: 100%;
                              -ms-text-size-adjust: 100%;
                              -webkit-text-size-adjust: 100%;
                            "
                            role="presentation"
                            valign="top"
                          >
                            <tbody>
                              <tr style="vertical-align: top" valign="top">
                                <td
                                  class="divider_inner"
                                  style="
                                    word-break: break-word;
                                    vertical-align: top;
                                    min-width: 100%;
                                    -ms-text-size-adjust: 100%;
                                    -webkit-text-size-adjust: 100%;
                                    padding-top: 0px;
                                    padding-right: 0px;
                                    padding-bottom: 0px;
                                    padding-left: 0px;
                                  "
                                  valign="top"
                                >
                                  <table
                                    class="divider_content"
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      table-layout: fixed;
                                      vertical-align: top;
                                      border-spacing: 0;
                                      border-collapse: collapse;
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-top: 1px solid #bbbbbb;
                                      width: 100%;
                                    "
                                    align="center"
                                    role="presentation"
                                    valign="top"
                                  >
                                    <tbody>
                                      <tr
                                        style="vertical-align: top"
                                        valign="top"
                                      >
                                        <td
                                          style="
                                            word-break: break-word;
                                            vertical-align: top;
                                            -ms-text-size-adjust: 100%;
                                            -webkit-text-size-adjust: 100%;
                                          "
                                          valign="top"
                                        >
                                          <span></span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                  </div>
                </div>
              </div>
              <div style="background-color: transparent">
                <div
                  class="block-grid two-up no-stack"
                  style="
                    min-width: 320px;
                    max-width: 550px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    margin: 0 auto;
                    background-color: transparent;
                  "
                >
                  <div
                    style="
                      border-collapse: collapse;
                      display: table;
                      width: 100%;
                      background-color: transparent;
                    "
                  >
                    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:650px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                    <!--[if (mso)|(IE)]><td align="center" width="325" style="background-color:transparent;width:325px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 1px solid #AAAAAA;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;"><![endif]-->
                    <div
                      class="col num6"
                      style="
                        display: table-cell;
                        vertical-align: top;
                        max-width: 320px;
                        min-width: 324px;
                        width: 324px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 1px solid #aaaaaa;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Tahoma, Verdana, sans-serif"><![endif]-->
                          <div
                            style="
                              color: #555555;
                              font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                sans-serif;
                              line-height: 1.5;
                              padding-top: 10px;
                              padding-right: 10px;
                              padding-bottom: 10px;
                              padding-left: 10px;
                            "
                          >
                            <div
                              class="txtTinyMce-wrapper"
                              style="
                                line-height: 1.5;
                                font-size: 12px;
                                font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                  sans-serif;
                                color: #555555;
                                mso-line-height-alt: 18px;
                              "
                            >
                              <p
                                style="
                                  font-size: 15px;
                                  line-height: 1.5;
                                  word-break: break-word;
                                  text-align: right;
                                  font-family: Roboto, Tahoma, Verdana, Segoe,
                                    sans-serif;
                                  mso-line-height-alt: 23px;
                                  margin: 0;
                                "
                              >
                                <span style="font-size: 15px"
                                  ><a
                                    style="text-decoration: none; color: #555555"
                                    href="https://juno.vn/collections/san-pham-moi-nhat?utm_source={{ utm_source }}&amp;utm_medium={{ utm_medium }}&amp;utm_campaign={{ utm_campaign }}&amp;krb_cj_id={{ cj_id }}"
                                    target="_blank"
                                    rel="noopener"
                                    >New Arrival</a
                                  ></span
                                >
                              </p>
                            </div>
                          </div>
                          <!--[if mso]></td></tr></table><![endif]-->
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td><td align="center" width="325" style="background-color:transparent;width:325px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;"><![endif]-->
                    <div
                      class="col num6"
                      style="
                        display: table-cell;
                        vertical-align: top;
                        max-width: 320px;
                        min-width: 324px;
                        width: 325px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 0px solid transparent;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Tahoma, Verdana, sans-serif"><![endif]-->
                          <div
                            style="
                              color: #555555;
                              font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                sans-serif;
                              line-height: 1.5;
                              padding-top: 10px;
                              padding-right: 10px;
                              padding-bottom: 10px;
                              padding-left: 10px;
                            "
                          >
                            <div
                              class="txtTinyMce-wrapper"
                              style="
                                line-height: 1.5;
                                font-size: 12px;
                                font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                  sans-serif;
                                color: #555555;
                                mso-line-height-alt: 18px;
                              "
                            >
                              <p
                                style="
                                  font-size: 15px;
                                  line-height: 1.5;
                                  word-break: break-word;
                                  font-family: Roboto, Tahoma, Verdana, Segoe,
                                    sans-serif;
                                  mso-line-height-alt: 23px;
                                  margin: 0;
                                "
                              >
                                <span style="font-size: 15px"
                                  ><a
                                    style="text-decoration: none; color: #555555"
                                    href="https://juno.vn/collections/giay-cao-got?utm_source={{ utm_source }}&amp;utm_medium={{ utm_medium }}&amp;utm_campaign={{ utm_campaign }}&amp;krb_cj_id={{ cj_id }}"
                                    target="_blank"
                                    rel="noopener"
                                    >Giày Cao Gót</a
                                  ></span
                                >
                              </p>
                            </div>
                          </div>
                          <!--[if mso]></td></tr></table><![endif]-->
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                  </div>
                </div>
              </div>
              <div style="background-color: transparent">
                <div
                  class="block-grid"
                  style="
                    min-width: 320px;
                    max-width: 550px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    margin: 0 auto;
                    background-color: transparent;
                  "
                >
                  <div
                    style="
                      border-collapse: collapse;
                      display: table;
                      width: 100%;
                      background-color: transparent;
                    "
                  >
                    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:650px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                    <!--[if (mso)|(IE)]><td align="center" width="650" style="background-color:transparent;width:650px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
                    <div
                      class="col num12"
                      style="
                        min-width: 320px;
                        max-width: 550px;
                        display: table-cell;
                        vertical-align: top;
                        width: 650px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 0px solid transparent;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <table
                            class="divider"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            width="100%"
                            style="
                              table-layout: fixed;
                              vertical-align: top;
                              border-spacing: 0;
                              border-collapse: collapse;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              min-width: 100%;
                              -ms-text-size-adjust: 100%;
                              -webkit-text-size-adjust: 100%;
                            "
                            role="presentation"
                            valign="top"
                          >
                            <tbody>
                              <tr style="vertical-align: top" valign="top">
                                <td
                                  class="divider_inner"
                                  style="
                                    word-break: break-word;
                                    vertical-align: top;
                                    min-width: 100%;
                                    -ms-text-size-adjust: 100%;
                                    -webkit-text-size-adjust: 100%;
                                    padding-top: 0px;
                                    padding-right: 0px;
                                    padding-bottom: 0px;
                                    padding-left: 0px;
                                  "
                                  valign="top"
                                >
                                  <table
                                    class="divider_content"
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      table-layout: fixed;
                                      vertical-align: top;
                                      border-spacing: 0;
                                      border-collapse: collapse;
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-top: 1px solid #bbbbbb;
                                      width: 100%;
                                    "
                                    align="center"
                                    role="presentation"
                                    valign="top"
                                  >
                                    <tbody>
                                      <tr
                                        style="vertical-align: top"
                                        valign="top"
                                      >
                                        <td
                                          style="
                                            word-break: break-word;
                                            vertical-align: top;
                                            -ms-text-size-adjust: 100%;
                                            -webkit-text-size-adjust: 100%;
                                          "
                                          valign="top"
                                        >
                                          <span></span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                  </div>
                </div>
              </div>
              <div style="background-color: transparent">
                <div
                  class="block-grid two-up no-stack"
                  style="
                    min-width: 320px;
                    max-width: 550px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    margin: 0 auto;
                    background-color: transparent;
                  "
                >
                  <div
                    style="
                      border-collapse: collapse;
                      display: table;
                      width: 100%;
                      background-color: transparent;
                    "
                  >
                    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:650px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                    <!--[if (mso)|(IE)]><td align="center" width="325" style="background-color:transparent;width:325px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 1px solid #AAAAAA;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;"><![endif]-->
                    <div
                      class="col num6"
                      style="
                        display: table-cell;
                        vertical-align: top;
                        max-width: 320px;
                        min-width: 324px;
                        width: 324px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 1px solid #aaaaaa;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Tahoma, Verdana, sans-serif"><![endif]-->
                          <div
                            style="
                              color: #555555;
                              font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                sans-serif;
                              line-height: 1.5;
                              padding-top: 10px;
                              padding-right: 10px;
                              padding-bottom: 10px;
                              padding-left: 10px;
                            "
                          >
                            <div
                              class="txtTinyMce-wrapper"
                              style="
                                line-height: 1.5;
                                font-size: 12px;
                                font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                  sans-serif;
                                color: #555555;
                                mso-line-height-alt: 18px;
                              "
                            >
                              <p
                                style="
                                  font-size: 15px;
                                  line-height: 1.5;
                                  word-break: break-word;
                                  text-align: right;
                                  font-family: Roboto, Tahoma, Verdana, Segoe,
                                    sans-serif;
                                  mso-line-height-alt: 23px;
                                  margin: 0;
                                "
                              >
                                <span style="font-size: 15px"
                                  ><a
                                    style="text-decoration: none; color: #555555"
                                    href="https://juno.vn/collections/giay-xang-dan?utm_source={{ utm_source }}&amp;utm_medium={{ utm_medium }}&amp;utm_campaign={{ utm_campaign }}&amp;krb_cj_id={{ cj_id }}"
                                    target="_blank"
                                    rel="noopener"
                                    >Sandals</a
                                  ></span
                                >
                              </p>
                            </div>
                          </div>
                          <!--[if mso]></td></tr></table><![endif]-->
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td><td align="center" width="325" style="background-color:transparent;width:325px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;"><![endif]-->
                    <div
                      class="col num6"
                      style="
                        display: table-cell;
                        vertical-align: top;
                        max-width: 320px;
                        min-width: 324px;
                        width: 325px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 0px solid transparent;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Tahoma, Verdana, sans-serif"><![endif]-->
                          <div
                            style="
                              color: #555555;
                              font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                sans-serif;
                              line-height: 1.5;
                              padding-top: 10px;
                              padding-right: 10px;
                              padding-bottom: 10px;
                              padding-left: 10px;
                            "
                          >
                            <div
                              class="txtTinyMce-wrapper"
                              style="
                                line-height: 1.5;
                                font-size: 12px;
                                font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                  sans-serif;
                                color: #555555;
                                mso-line-height-alt: 18px;
                              "
                            >
                              <p
                                style="
                                  font-size: 15px;
                                  line-height: 1.5;
                                  word-break: break-word;
                                  font-family: Roboto, Tahoma, Verdana, Segoe,
                                    sans-serif;
                                  mso-line-height-alt: 23px;
                                  margin: 0;
                                "
                              >
                                <span style="font-size: 15px"
                                  ><a
                                    style="text-decoration: none; color: #555555"
                                    href="https://juno.vn/collections/tui-xach-co-nho?utm_source={{ utm_source }}&amp;utm_medium={{ utm_medium }}&amp;utm_campaign={{ utm_campaign }}&amp;krb_cj_id={{ cj_id }}"
                                    target="_blank"
                                    rel="noopener"
                                    >Túi Xách Nhỏ</a
                                  ></span
                                >
                              </p>
                            </div>
                          </div>
                          <!--[if mso]></td></tr></table><![endif]-->
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                  </div>
                </div>
              </div>
              <div style="background-color: transparent">
                <div
                  class="block-grid"
                  style="
                    min-width: 320px;
                    max-width: 550px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    margin: 0 auto;
                    background-color: transparent;
                  "
                >
                  <div
                    style="
                      border-collapse: collapse;
                      display: table;
                      width: 100%;
                      background-color: transparent;
                    "
                  >
                    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:650px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                    <!--[if (mso)|(IE)]><td align="center" width="650" style="background-color:transparent;width:650px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
                    <div
                      class="col num12"
                      style="
                        min-width: 320px;
                        max-width: 550px;
                        display: table-cell;
                        vertical-align: top;
                        width: 650px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 0px solid transparent;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <table
                            class="divider"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            width="100%"
                            style="
                              table-layout: fixed;
                              vertical-align: top;
                              border-spacing: 0;
                              border-collapse: collapse;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              min-width: 100%;
                              -ms-text-size-adjust: 100%;
                              -webkit-text-size-adjust: 100%;
                            "
                            role="presentation"
                            valign="top"
                          >
                            <tbody>
                              <tr style="vertical-align: top" valign="top">
                                <td
                                  class="divider_inner"
                                  style="
                                    word-break: break-word;
                                    vertical-align: top;
                                    min-width: 100%;
                                    -ms-text-size-adjust: 100%;
                                    -webkit-text-size-adjust: 100%;
                                    padding-top: 0px;
                                    padding-right: 0px;
                                    padding-bottom: 0px;
                                    padding-left: 0px;
                                  "
                                  valign="top"
                                >
                                  <table
                                    class="divider_content"
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    style="
                                      table-layout: fixed;
                                      vertical-align: top;
                                      border-spacing: 0;
                                      border-collapse: collapse;
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-top: 1px solid #bbbbbb;
                                      width: 100%;
                                    "
                                    align="center"
                                    role="presentation"
                                    valign="top"
                                  >
                                    <tbody>
                                      <tr
                                        style="vertical-align: top"
                                        valign="top"
                                      >
                                        <td
                                          style="
                                            word-break: break-word;
                                            vertical-align: top;
                                            -ms-text-size-adjust: 100%;
                                            -webkit-text-size-adjust: 100%;
                                          "
                                          valign="top"
                                        >
                                          <span></span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                  </div>
                </div>
              </div>
              <div style="background-color: transparent">
                <div
                  class="block-grid two-up no-stack"
                  style="
                    min-width: 320px;
                    max-width: 550px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    margin: 0 auto;
                    background-color: transparent;
                  "
                >
                  <div
                    style="
                      border-collapse: collapse;
                      display: table;
                      width: 100%;
                      background-color: transparent;
                    "
                  >
                    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:650px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                    <!--[if (mso)|(IE)]><td align="center" width="325" style="background-color:transparent;width:325px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 1px solid #AAAAAA;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;"><![endif]-->
                    <div
                      class="col num6"
                      style="
                        display: table-cell;
                        vertical-align: top;
                        max-width: 320px;
                        min-width: 324px;
                        width: 324px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 1px solid #aaaaaa;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Tahoma, Verdana, sans-serif"><![endif]-->
                          <div
                            style="
                              color: #555555;
                              font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                sans-serif;
                              line-height: 1.5;
                              padding-top: 10px;
                              padding-right: 10px;
                              padding-bottom: 10px;
                              padding-left: 10px;
                            "
                          >
                            <div
                              class="txtTinyMce-wrapper"
                              style="
                                line-height: 1.5;
                                font-size: 12px;
                                font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                  sans-serif;
                                color: #555555;
                                mso-line-height-alt: 18px;
                              "
                            >
                              <p
                                style="
                                  font-size: 15px;
                                  line-height: 1.5;
                                  word-break: break-word;
                                  text-align: right;
                                  font-family: Roboto, Tahoma, Verdana, Segoe,
                                    sans-serif;
                                  mso-line-height-alt: 23px;
                                  margin: 0;
                                "
                              >
                                <span style="font-size: 15px"
                                  ><a
                                    style="text-decoration: none; color: #555555"
                                    href="https://juno.vn/collections/ba-lo-thoi-trang?utm_source={{ utm_source }}&amp;utm_medium={{ utm_medium }}&amp;utm_campaign={{ utm_campaign }}&amp;krb_cj_id={{ cj_id }}"
                                    target="_blank"
                                    rel="noopener"
                                    >Balo</a
                                  ></span
                                >
                              </p>
                            </div>
                          </div>
                          <!--[if mso]></td></tr></table><![endif]-->
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td><td align="center" width="325" style="background-color:transparent;width:325px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;"><![endif]-->
                    <div
                      class="col num6"
                      style="
                        display: table-cell;
                        vertical-align: top;
                        max-width: 320px;
                        min-width: 324px;
                        width: 325px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 0px solid transparent;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Tahoma, Verdana, sans-serif"><![endif]-->
                          <div
                            style="
                              color: #555555;
                              font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                sans-serif;
                              line-height: 1.5;
                              padding-top: 10px;
                              padding-right: 10px;
                              padding-bottom: 10px;
                              padding-left: 10px;
                            "
                          >
                            <div
                              class="txtTinyMce-wrapper"
                              style="
                                line-height: 1.5;
                                font-size: 12px;
                                font-family: 'Roboto', Tahoma, Verdana, Segoe,
                                  sans-serif;
                                color: #555555;
                                mso-line-height-alt: 18px;
                              "
                            >
                              <p
                                style="
                                  font-size: 15px;
                                  line-height: 1.5;
                                  word-break: break-word;
                                  font-family: Roboto, Tahoma, Verdana, Segoe,
                                    sans-serif;
                                  mso-line-height-alt: 23px;
                                  margin: 0;
                                "
                              >
                                <span style="font-size: 15px"
                                  ><a
                                    style="text-decoration: none; color: #555555"
                                    href="https://juno.vn/collections/giay-sneaker?utm_source={{ utm_source }}&amp;utm_medium={{ utm_medium }}&amp;utm_campaign={{ utm_campaign }}&amp;krb_cj_id={{ cj_id }}"
                                    target="_blank"
                                    rel="noopener"
                                    >Sneakers</a
                                  ></span
                                >
                              </p>
                            </div>
                          </div>
                          <!--[if mso]></td></tr></table><![endif]-->
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                  </div>
                </div>
              </div>
              <div style="background-color: transparent">
                <div
                  class="block-grid"
                  style="
                    min-width: 320px;
                    max-width: 550px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    margin: 0 auto;
                    background-color: transparent;
                  "
                >
                  <div
                    style="
                      border-collapse: collapse;
                      display: table;
                      width: 100%;
                      background-color: transparent;
                    "
                  >
                    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:650px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                    <!--[if (mso)|(IE)]><td align="center" width="650" style="background-color:transparent;width:650px; border-top: 1px solid #555555; border-left: 1px solid #555555; border-bottom: 1px solid #555555; border-right: 1px solid #555555;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
                    <div
                      class="col num12"
                      style="
                        min-width: 320px;
                        max-width: 550px;
                        display: table-cell;
                        vertical-align: top;
                        width: 648px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 1px solid #555555;
                            border-left: 1px solid #555555;
                            border-bottom: 1px solid #555555;
                            border-right: 1px solid #555555;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
                          <div
                            style="
                              color: #555555;
                              font-family: Arial, Helvetica Neue, Helvetica,
                                sans-serif;
                              line-height: 1.2;
                              padding-top: 10px;
                              padding-right: 10px;
                              padding-bottom: 10px;
                              padding-left: 10px;
                            "
                          >
                            <div
                              class="txtTinyMce-wrapper"
                              style="
                                line-height: 1.2;
                                font-size: 12px;
                                color: #555555;
                                font-family: Arial, Helvetica Neue, Helvetica,
                                  sans-serif;
                                mso-line-height-alt: 14px;
                              "
                            >
                              <p
                                style="
                                  font-size: 14px;
                                  line-height: 1.2;
                                  word-break: break-word;
                                  text-align: center;
                                  mso-line-height-alt: 17px;
                                  margin: 0;
                                "
                              >
                                <strong
                                  >HỆ THỐNG 80+ CỬA HÀNG TRÊN TOÀN QUỐC</strong
                                >
                              </p>
                            </div>
                          </div>
                          <!--[if mso]></td></tr></table><![endif]-->
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                  </div>
                </div>
              </div>
              <div style="background-color: transparent">
                <div
                  class="block-grid mixed-two-up no-stack"
                  style="
                    min-width: 320px;
                    max-width: 550px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    margin: 0 auto;
                    background-color: transparent;
                  "
                >
                  <div
                    style="
                      border-collapse: collapse;
                      display: table;
                      width: 100%;
                      background-color: transparent;
                    "
                  >
                    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:650px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                    <!--[if (mso)|(IE)]><td align="center" width="216" style="background-color:transparent;width:216px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:15px; padding-bottom:0px;"><![endif]-->
                    <div
                      class="col num4"
                      style="
                        display: table-cell;
                        vertical-align: top;
                        max-width: 320px;
                        min-width: 216px;
                        width: 216px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 0px solid transparent;
                            padding-top: 15px;
                            padding-bottom: 0px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
                          <div
                            style="
                              color: #555555;
                              font-family: Arial, Helvetica Neue, Helvetica,
                                sans-serif;
                              line-height: 2;
                              padding-top: 10px;
                              padding-right: 10px;
                              padding-bottom: 10px;
                              padding-left: 10px;
                            "
                          >
                            <div
                              class="txtTinyMce-wrapper"
                              style="
                                line-height: 2;
                                font-size: 12px;
                                color: #555555;
                                font-family: Arial, Helvetica Neue, Helvetica,
                                  sans-serif;
                                mso-line-height-alt: 24px;
                              "
                            >
                              <p
                                style="
                                  font-size: 14px;
                                  line-height: 2;
                                  word-break: break-word;
                                  text-align: right;
                                  mso-line-height-alt: 28px;
                                  margin: 0;
                                "
                              >
                                <strong>FOLLOW US</strong>
                              </p>
                            </div>
                          </div>
                          <!--[if mso]></td></tr></table><![endif]-->
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td><td align="center" width="433" style="background-color:transparent;width:433px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:15px; padding-bottom:0px;"><![endif]-->
                    <div
                      class="col num8"
                      style="
                        display: table-cell;
                        vertical-align: top;
                        max-width: 320px;
                        min-width: 432px;
                        width: 433px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 0px solid transparent;
                            padding-top: 15px;
                            padding-bottom: 0px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <table
                            class="social_icons"
                            cellpadding="0"
                            cellspacing="0"
                            width="100%"
                            role="presentation"
                            style="
                              table-layout: fixed;
                              vertical-align: top;
                              border-spacing: 0;
                              border-collapse: collapse;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                            "
                            valign="top"
                          >
                            <tbody>
                              <tr style="vertical-align: top" valign="top">
                                <td
                                  style="
                                    word-break: break-word;
                                    vertical-align: top;
                                    padding-top: 10px;
                                    padding-right: 10px;
                                    padding-bottom: 10px;
                                    padding-left: 10px;
                                  "
                                  valign="top"
                                >
                                  <table
                                    class="social_table"
                                    align="center"
                                    cellpadding="0"
                                    cellspacing="0"
                                    role="presentation"
                                    style="
                                      table-layout: fixed;
                                      vertical-align: top;
                                      border-spacing: 0;
                                      border-collapse: collapse;
                                      mso-table-tspace: 0;
                                      mso-table-rspace: 0;
                                      mso-table-bspace: 0;
                                      mso-table-lspace: 0;
                                    "
                                    valign="top"
                                  >
                                    <tbody>
                                      <tr
                                        style="
                                          vertical-align: top;
                                          display: inline-block;
                                          text-align: center;
                                        "
                                        align="center"
                                        valign="top"
                                      >
                                        <td
                                          style="
                                            word-break: break-word;
                                            vertical-align: top;
                                            padding-bottom: 0;
                                            padding-right: 10px;
                                            padding-left: 10px;
                                          "
                                          valign="top"
                                        >
                                          <a
                                            href="https://www.facebook.com/giayjuno"
                                            target="_blank"
                                            ><img
                                              width="32"
                                              height="32"
                                              src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/circle-color/facebook@2x.png"
                                              alt="Facebook"
                                              title="Facebook"
                                              style="
                                                text-decoration: none;
                                                -ms-interpolation-mode: bicubic;
                                                height: auto;
                                                border: 0;
                                                display: block;
                                              "
                                          /></a>
                                        </td>
                                        <td
                                          style="
                                            word-break: break-word;
                                            vertical-align: top;
                                            padding-bottom: 0;
                                            padding-right: 10px;
                                            padding-left: 10px;
                                          "
                                          valign="top"
                                        >
                                          <a
                                            href="https://www.instagram.com/juno.vn/"
                                            target="_blank"
                                            ><img
                                              width="32"
                                              height="32"
                                              src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/circle-color/instagram@2x.png"
                                              alt="Instagram"
                                              title="Instagram"
                                              style="
                                                text-decoration: none;
                                                -ms-interpolation-mode: bicubic;
                                                height: auto;
                                                border: 0;
                                                display: block;
                                              "
                                          /></a>
                                        </td>
                                        <td
                                          style="
                                            word-break: break-word;
                                            vertical-align: top;
                                            padding-bottom: 0;
                                            padding-right: 10px;
                                            padding-left: 10px;
                                          "
                                          valign="top"
                                        >
                                          <a
                                            href="https://www.youtube.com/user/JunoShoesVn"
                                            target="_blank"
                                            ><img
                                              width="32"
                                              height="32"
                                              src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/circle-color/youtube@2x.png"
                                              alt="YouTube"
                                              title="YouTube"
                                              style="
                                                text-decoration: none;
                                                -ms-interpolation-mode: bicubic;
                                                height: auto;
                                                border: 0;
                                                display: block;
                                              "
                                          /></a>
                                        </td>
                                        <td
                                          style="
                                            word-break: break-word;
                                            vertical-align: top;
                                            padding-bottom: 0;
                                            padding-right: 10px;
                                            padding-left: 10px;
                                          "
                                          valign="top"
                                        >
                                          <a
                                            href="https://zalo.me/giayjuno"
                                            target="_blank"
                                            ><img
                                              width="32"
                                              height="32"
                                              src="https://email-beeplugin-images.s3.amazonaws.com/images/insider-bee-client-juno-production/zalo-icon.png"
                                              alt="Zalo Shop"
                                              title="Custom"
                                              style="
                                                text-decoration: none;
                                                -ms-interpolation-mode: bicubic;
                                                height: auto;
                                                border: 0;
                                                display: block;
                                              "
                                          /></a>
                                        </td>
                                        <td
                                          style="
                                            word-break: break-word;
                                            vertical-align: top;
                                            padding-bottom: 0;
                                            padding-right: 10px;
                                            padding-left: 10px;
                                          "
                                          valign="top"
                                        >
                                          <a
                                            href="https://www.tiktok.com/@giaytuijuno?lang=en"
                                            target="_blank"
                                            ><img
                                              width="32"
                                              height="32"
                                              src="https://email-beeplugin-images.s3.amazonaws.com/images/insider-bee-client-juno-production/tiktok.png"
                                              alt="Custom"
                                              title="Custom"
                                              style="
                                                text-decoration: none;
                                                -ms-interpolation-mode: bicubic;
                                                height: auto;
                                                border: 0;
                                                display: block;
                                              "
                                          /></a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                  </div>
                </div>
              </div>
              <div style="background-color: transparent">
                <div
                  class="block-grid"
                  style="
                    min-width: 320px;
                    max-width: 550px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    margin: 0 auto;
                    background-color: transparent;
                  "
                >
                  <div
                    style="
                      border-collapse: collapse;
                      display: table;
                      width: 100%;
                      background-color: transparent;
                    "
                  >
                    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:650px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                    <!--[if (mso)|(IE)]><td align="center" width="650" style="background-color:transparent;width:650px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
                    <div
                      class="col num12"
                      style="
                        min-width: 320px;
                        max-width: 550px;
                        display: table-cell;
                        vertical-align: top;
                        width: 650px;
                      "
                    >
                      <div class="col_cont"  >
                        <!--[if (!mso)&(!IE)]><!-->
                        <div
                          style="
                            border-top: 0px solid transparent;
                            border-left: 0px solid transparent;
                            border-bottom: 0px solid transparent;
                            border-right: 0px solid transparent;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-right: 0px;
                            padding-left: 0px;
                          "
                        >
                          <!--<![endif]-->
                          <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
                          <div
                            style="
                              color: #555555;
                              font-family: Arial, Helvetica Neue, Helvetica,
                                sans-serif;
                              line-height: 1.2;
                              padding-top: 10px;
                              padding-right: 10px;
                              padding-bottom: 10px;
                              padding-left: 10px;
                            "
                          >
                            <div
                              class="txtTinyMce-wrapper"
                              style="
                                line-height: 1.2;
                                font-size: 12px;
                                color: #555555;
                                font-family: Arial, Helvetica Neue, Helvetica,
                                  sans-serif;
                                mso-line-height-alt: 14px;
                              "
                            >
                              <p
                                style="
                                  font-size: 8px;
                                  line-height: 1.2;
                                  word-break: break-word;
                                  mso-line-height-alt: 10px;
                                  margin: 0;
                                "
                              >
                                <span style="font-size: 8px"
                                  >Để ngừng nhận tin từ Juno, vui lòng bấm vào
                                  <a
                                    style="
                                      text-decoration: underline;
                                      color: #7c4b96;
                                    "
                                    href="<%unsub%>"
                                    target="_blank"
                                    rel="noopener"
                                    >đây</a
                                  ></span
                                >
                              </p>
                            </div>
                          </div>
                          <!--[if mso]></td></tr></table><![endif]-->
                          <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                      </div>
                    </div>
                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                    <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                  </div>
                </div>
              </div>
              <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
      <!--[if (IE)]></div><![endif]-->
    </body>
  </html>`
