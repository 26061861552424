import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import * as yup from 'yup'
import { connect } from 'react-redux'
import { useStoreActions } from 'easy-peasy'

import Header from 'Components/CJHeader'
import CreativeContent from 'Containers/CreativeContent'
import Preview from 'Components/CreativeContentsPreview'
import { CurrentType } from 'CustomerJourneyModule'
import ChannelSchemas from 'Constants/ChannelSchemas'
import { AppPushParams } from 'Constants/ChannelSchemas/AppPush.schema'

import './index.scss'

export const webPushSchema = yup.object().shape({
  title: yup.string().required(),
  content: yup.string().required(),
  image: yup.string().required(),
  button_name: yup.string().required(),
  button_nav_screen: yup.string(),
  button_deeplink: yup.string().url(),
  utm_source: yup.string().required(),
  utm_medium: yup.string().required(),
  utm_campaign: yup.string().required(),
})

const initialValues: AppPushParams = {
  title: '',
  content: '',
  image: '',
  coupon_code: '',
  button_name: '',
  button_deeplink: '',
  button_nav_screen: '',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
}

// const platforms = [
//   { label: 'Android', value: 'android' },
//   { label: 'iOS', value: 'ios' },
// ]

interface Props {
  isPopoverVisible: boolean
  value: AppPushParams
  initData: any
  current: CurrentType
  isRunning: boolean
  onAddNewNode: (type: string, values: AppPushParams) => void
  onUpdateNode: (values: AppPushParams) => void
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  setCreativeContentsState: React.Dispatch<React.SetStateAction<any>>
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
}

const AppPushChanel: React.FC<Props> = (props) => {
  const {
    setIsPopoverVisible,
    isPopoverVisible,
    value,
    initData,
    onAddNewNode,
    onUpdateNode,
    setCreativeContentsState,
  } = props
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true)

  const { setPreviewData } = useStoreActions((action: any) => action.campaign.creativeContents)

  useEffect(() => {
    setCreativeContentsState({ key: 'previewData', value: value || initialValues })
    setIsPopoverVisible(false)
    setIsModalVisible(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  const onCloseModal = () => {
    setIsModalVisible(false)
  }

  const onSubmit = async (values: AppPushParams) => {
    onAddNewNode && (await onAddNewNode('tch_app', values))
    onUpdateNode && (await onUpdateNode(values))
    setIsModalVisible(false)
  }

  return (
    <Modal
      {...props}
      onOk={onCloseModal}
      onCancel={() => setIsModalVisible(false)}
      visible={isModalVisible}
      maskClosable
      title={<Header {...props} />}
      footer={[]}
      width={800}
      closable={false}
      className="ChannelsModal"
    >
      <div className={`AppPushComponent ${props.isRunning ? 'is-running' : ''}`}>
        <div className="form-wrapper">
          <CreativeContent
            onSubmit={onSubmit}
            submitText="Save"
            initValues={value || initialValues}
            validationSchema={webPushSchema}
            fields={ChannelSchemas['tch_app']}
            onChange={(name: string, value: string) =>
              setPreviewData({
                key: name,
                value: value,
              })
            }
          />
        </div>
        <Preview isPreview={true} type={'tch_app'}></Preview>
      </div>
    </Modal>
  )
}

interface StateProps {
  campaign: any
}

export default connect(
  (state: StateProps) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
  })
)(AppPushChanel)
