import React, { useState, useEffect } from 'react'
import { Empty, message, Skeleton } from 'antd'
import axios from 'Utils/axios'
import CurrentRanking from './Components/CurrentRanking'
import LeaderBoard from './Components/LeaderBoard'
import Performance from './Components/TasksDailyReport'
import { formatErrors } from 'Utils'

import './index.scss'
import './Components/index.scss'

type LeaderBoardResponse = {
  leaderboard: any[]
  current_rank: any
}

export default function Ranking() {
  const [data, setData] = useState<LeaderBoardResponse | null>(null)
  useEffect(() => {
    ;(async () => {
      try {
        const resp = await axios.get(
          '/store-farming/leaderboard?company=${process.env.REACT_APP_COMPANY_NAME}&force=true'
        )
        setData(resp.data)
      } catch (error: any) {
        message.error(formatErrors(error?.response?.data))
      }
    })()
  }, [])

  const renderLeaderBoard = () => {
    if (!data) return <Skeleton></Skeleton>
    if (!data.current_rank || data.leaderboard || data.leaderboard[0]) return <Empty></Empty>
    return <CurrentRanking myRanking={data.current_rank} topRanking={data?.leaderboard[0]} />
  }
  return (
    <div id="Ranking" className="p-3">
      <div className="section-wrapper section-wrapper--yellow-bg p-3 mb-4">
        {renderLeaderBoard()}
      </div>
      <div className="section-wrapper mb-4">
        <div className="board-header flex justify-between align-items p-2">
          <span className="text-black font-semibold text-lg">Bảng xếp hạng</span>
          <span className="text-disabled">Doanh thu / Đơn hàng</span>
        </div>
        <div className="pt-2 bg-white">
          {data ? (
            <LeaderBoard data={data.leaderboard} myRanking={data.current_rank} />
          ) : (
            <Skeleton></Skeleton>
          )}
        </div>
      </div>
      <div className="section-wrapper">
        <div className="p-2">
          <span className="text-black font-semibold text-lg">Tiến độ công việc</span>
        </div>
        <div className="p-2 bg-white">
          <Performance />
        </div>
      </div>
    </div>
  )
}
