import React, { useState, useEffect } from 'react'
import './index.scss'
import { useInView } from 'react-intersection-observer'
import { getCustomerOverviewKeyMetrics } from 'Services/Analytics/customer-analytics'
import { useHistory } from 'react-router-dom'

import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'
import { getProductOverviewKeyMetrics } from 'Services/Analytics/product-analytics'
import PivotTableTemplate from 'Pages/Analytics/Components/PivotTable.Template'
import { ProductPivotTypes } from 'Services/Analytics/Pivot.service'
import ChartRow1 from './ChartRow1'
import ChartRow2 from './ChartRow2'
import ChartRow3 from './ChartRow3'
import ChartRow4 from './ChartRow4'
import ChartRow5 from './ChartRow5'
import ChartRow6 from './ChartRow6'
import ChartRow7 from './ChartRow7'
import CatePivotRow from './CatePivotRow'
import PivotRow1 from './PivotRow1'
import PivotRow2 from './PivotRow2'
import KeyMetric from '../../Components/KeyMetric/KeyMetric'
import { getSaleChannelOverviewKeyMetrics } from 'Services/Analytics/saleChannel-analytics'

const SaleChannelOverviewBody = () => {
  const history = useHistory()
  const [isResetting, setIsResetting] = useState(false)
  const { ref: ref1, inView: ref1InView } = useInView({ triggerOnce: true })
  const { ref: ref2, inView: ref2InView } = useInView({ triggerOnce: true })
  const { ref: ref3, inView: ref3InView } = useInView({ triggerOnce: true })
  const { ref: ref4, inView: ref4InView } = useInView({ triggerOnce: true })
  const { ref: ref5, inView: ref5InView } = useInView({ triggerOnce: true })
  const { ref: ref6, inView: ref6InView } = useInView({ triggerOnce: true })
  const { ref: ref7, inView: ref7InView } = useInView({ triggerOnce: true })
  const { ref: ref8, inView: ref8InView } = useInView({ triggerOnce: true })
  const { ref: ref9, inView: ref9InView } = useInView({ triggerOnce: true })
  const { ref: ref10, inView: ref10InView } = useInView({ triggerOnce: true })
  const { ref: ref11, inView: ref11InView } = useInView({ triggerOnce: true })
  const { ref: ref12, inView: ref12InView } = useInView({ triggerOnce: true })

  useEffect(() => {
    setIsResetting(true)

    setTimeout(() => {
      setIsResetting(false)
    }, 10)
  }, [history.location.search])

  if (isResetting) return <div className="w-full" style={{ height: 1000 }}></div>

  return (
    <div className="my-4">
      <div style={{ minHeight: 200 }} className="bg-white" ref={ref1}>
        <div className="mb-4 flex justify-between items-center">
          {/* <div className="font-semibold">Key Metric</div> */}
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        {ref1InView && (
          <KeyMetric handleDataFunction={getSaleChannelOverviewKeyMetrics}></KeyMetric>
        )}
      </div>
      <div style={{ minHeight: 300 }} ref={ref2} className="bg-white gap-4 mt-4">
        {ref2InView && <ChartRow1 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref3} className="bg-white gap-4 mt-4">
        {ref3InView && <ChartRow2 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref4} className="bg-white gap-4 mt-4">
        {ref4InView && <ChartRow3 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref5} className="grid grid-cols-2 gap-4 mt-4">
        {ref5InView && <ChartRow4 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref6} className="grid grid-cols-2 gap-4 mt-4">
        {ref6InView && <ChartRow5 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref7} className="grid grid-cols-2 gap-4 mt-4">
        {ref7InView && <ChartRow6 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref8} className="grid grid-cols-2 gap-4 mt-4">
        {ref8InView && <ChartRow7 />}
      </div>
      <div ref={ref9} style={{ minHeight: 500 }} className="bg-white p-4 mt-4">
        {ref9InView && <CatePivotRow />}
      </div>
      <div ref={ref10} style={{ minHeight: 500 }} className="bg-white p-4 mt-4">
        {ref10InView && <PivotRow1 />}
      </div>
      <div ref={ref11} style={{ minHeight: 500 }} className="bg-white p-4 mt-4">
        {ref11InView && <PivotRow2 />}
      </div>

      {/* <div style={{ minHeight: 182 }} ref={ref12} className="bg-white p-4 mt-4 ">
        <div className="mb-4 font-semibold">Customize Section</div>
        {ref12InView && (
          <PivotTableTemplate
            type={ProductPivotTypes.ProductCustomizeSection}
            currentPage={window?.location?.href?.split('/')[4]?.split('?')[0]}
          />
        )}
      </div> */}
    </div>
  )
}

export default SaleChannelOverviewBody
