import React from 'react'
import { COLOR_PALLETTE } from 'Utils/constants'

const Legends = ({
  legends = [],
  className,
}: {
  className?: string
  legends: { label: string; color: string; shape?: 'rounded' | 'rectangle' | 'line' | string }[]
}) => {
  const renderShape = (legend) => {
    switch (legend.shape) {
      case 'rounded':
        return (
          <span
            className="rounded-full"
            style={{ background: legend.color, width: 14, height: 14 }}
          ></span>
        )
      case 'rectangle':
        return <span style={{ background: legend.color, width: 40, height: 12 }}></span>
      case 'line':
        return <span style={{ background: legend.color, width: 40, height: 2 }}></span>
    }
  }

  return (
    <div className={`LegendsComponent flex items-center flex-wrap gap-2 ${className}`}>
      {legends.map((legend, index) => {
        if (!legend.label && !legend.color && !legend.shape)
          return <div key={index} className="w-full"></div>
        return (
          <div key={index} title={legend.label} className="flex items-center">
            {renderShape(legend)}
            <span className="ml-1 text-sm">{legend.label}</span>
          </div>
        )
      })}
    </div>
  )
}

export default Legends
