import React from 'react'
import ChartLineTemplate from '../../Components/ChartLine'
import { getSaleChannelOverviewCharts } from 'Services/Analytics/saleChannel-analytics'

const ChartRow1 = () => {
  return (
    <div>
      <div className="p-4 bg-white">
        <div className="pb-4 text-base font-semibold text-primary_text text-body">Net Revenue</div>

        <ChartLineTemplate
          chartCode="net_revenue"
          handleDataFunction={getSaleChannelOverviewCharts}
        />
      </div>
    </div>
  )
}

export default ChartRow1
