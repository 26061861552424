import React, { useState, useEffect, useRef, useMemo } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { isEmpty } from 'lodash'

import { FiltersType } from 'AnalyticsTypes'

export const DATE_FORMAT = 'YYYY-MM-DD'

export const DEFAULT_VALUES = {
  frequency: 'daily',
  from_date: moment().subtract(1, 'month').format(DATE_FORMAT),
  to_date: moment().format(DATE_FORMAT),
}

const useFilters = () => {
  const history = useHistory()

  const queryObject: FiltersType = useMemo(() => {
    return queryString.parse(history.location.search)
  }, [history.location.search])

  const [filters, setFilters] = useState<any>(DEFAULT_VALUES)

  const onChangeFilters = (query) => {
    history.push({ search: queryString.stringify({ ...filters, ...query }) })
  }

  useEffect(() => {
    if (isEmpty(queryObject)) {
      setFilters(DEFAULT_VALUES)
    } else {
      setFilters({ ...DEFAULT_VALUES, ...queryObject })
    }
  }, [queryObject])

  return {
    filters,
    onChangeFilters,
  }
}

export default useFilters
