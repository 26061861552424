import React, { useState } from 'react'
import { Input, Button } from 'antd'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useParams } from 'react-router-dom'

import { States } from 'Models/customer-segments'
import LoadingIcon from 'Components/LoadingIcon'
import '../index.scss'

interface props {
  errors: string
  isNew?: boolean
  isSubmitting: boolean
  handleUpdateCustomerSegment: () => void
  handleCreateCustomerSegment: () => void
  handlePublishCustomerSegment: () => void
  showButtons?: ('save' | 'publish')[]
}

const Header: React.FC<props> = ({
  isNew = false,
  errors,
  isSubmitting,
  handleUpdateCustomerSegment,
  handleCreateCustomerSegment,
  handlePublishCustomerSegment,
  showButtons,
}) => {
  const { id }: { id: number } = useParams()

  const { name, description } = useStoreState(
    (state: { customerSegments: States }) => state.customerSegments
  )
  const { setState } = useStoreActions((action: any) => action.customerSegments)

  const renderSecondaryBtn = () => {
    if (!showButtons?.includes('save')) return null

    if (id)
      return (
        <Button
          onClick={handleUpdateCustomerSegment}
          disabled={isSubmitting}
          style={{ border: '1px solid rgb(230 230 230)' }}
        >
          Save
        </Button>
      )

    return (
      <Button
        onClick={handleCreateCustomerSegment}
        disabled={!name || isSubmitting}
        style={{ border: '1px solid rgb(230 230 230)' }}
      >
        Save as draft
      </Button>
    )
  }

  return (
    <>
      {errors && <div className="p-2 w-full text-gray_1 bg-negative font-bold">{errors}</div>}
      <div className="HeaderComponent w-full bg-white px-8 py-6 shadow-div_b flex justify-between">
        <div className="w-2/3">
          <Input
            maxLength={255}
            value={name || undefined}
            onChange={(e) => setState({ key: 'name', value: e.target.value })}
            className="text-3xl p-0 shadow-none border-none mb-2 font-semibold"
            placeholder="Enter segment name"
          />
          <Input
            value={description || undefined}
            onChange={(e) => setState({ key: 'description', value: e.target.value })}
            className="p-0 shadow-none border-none"
            placeholder="Enter description..."
          />
        </div>
        <div className="flex w-1/3 gap-2 justify-end">
          {isSubmitting && (
            <div className="pr-2">
              <LoadingIcon style={{ width: 20, top: 0 }} />
            </div>
          )}
          {renderSecondaryBtn()}
          {showButtons?.includes('publish') && (
            <Button
              style={{ opacity: 1 }}
              onClick={handlePublishCustomerSegment}
              disabled={!name || isSubmitting}
              type="primary"
            >
              Publish
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default Header
