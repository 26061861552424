import React from 'react'
import ToggleAction from './ToggleAction'
import ClickAction from './ClickAction'
import '../index.scss'
const Actions = (props) => {
  return (
    <div className="TableActionsComponent">
      {props.actions.map((action, index) => {
        return action.type === 'toggle' ? (
          <ToggleAction key={index} action={action} {...props} />
        ) : (
          <ClickAction key={index} action={action} {...props} />
        )
      })}
    </div>
  )
}

export default Actions
